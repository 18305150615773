import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import FaqPagesSectionTypeEnum from './FaqPagesSectionEnum';

export const FaqPageModel = types.model('FaqPageModel', {
  id: types.identifierNumber,
  section: types.maybe(FaqPagesSectionTypeEnum),
  title: types.maybe(types.string),
  description: types.maybe(types.string),
  main_video_embedding: types.maybe(types.string),
  second_video_embedding: types.maybe(types.string),
  third_video_embedding: types.maybe(types.string),
  page_content_body: types.maybe(types.string),
  teaser_url: types.maybe(types.string),
  comments: CommentsMapModel,
  comment_count: types.maybe(types.number),
  sorting: types.maybe(types.number)
});

export const createFaqPageModel = (data?: any): FaqPageModelType => {
  return FaqPageModel.create({
    id: mandatoryId(data?.id),
    section: FaqPagesSectionTypeEnum.is(data.section)
      ? data.section
      : undefined,
    title: data?.title || undefined,
    description: data?.description || undefined,
    main_video_embedding: data?.main_video_embedding || undefined,
    second_video_embedding: data?.second_video_embedding || undefined,
    third_video_embedding: data?.third_video_embedding || undefined,
    page_content_body: data.page_content_body || undefined,
    teaser_url: data.teaser_url || undefined,
    comments: createCommentsMap(data?.comments),
    comment_count: data?.comment_count || undefined,
    sorting: data?.sorting || undefined
  });
};

export type FaqPageModelType = typeof FaqPageModel.Type;
export default FaqPageModel;
