import { types } from 'mobx-state-tree';

export enum ElementType {
  Benchmark = 'Benchmark',
  Briefing = 'Briefing',
  Hypothesis = 'Hypothesis',
  Organization = 'Organization',
  Painpoint = 'Painpoint',
  Project = 'Project',
  Prototype = 'Prototype',
  PrototypeScreen = 'PrototypeScreen',
  Learning = 'Learning',
  User = 'User',
  AssistantSession = 'AssistantSession',
  AssistantSessionSolution = 'AssistantSessionSolution',
  AssistantSessionBenchmark = 'AssistantSessionBenchmark',
  FaqPage = 'FaqPage',
}

export const ApiElementTypeEnum = types.enumeration(Object.values(ElementType));

export type ApiElementTypeEnumType = typeof ApiElementTypeEnum.Type;
export default ApiElementTypeEnum;
