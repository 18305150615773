import { useEffect, useState } from 'react';

const sentences = [
  'Die Innovation nimmt Fahrt auf',
  'Bereit machen für kreative Eruptionen',
  'Die Bühne wird vorbereitet',
  'Ideen werden geschmiedet',
  'Noch einen Moment bis zur Innovation',
  'Die Kreativität entfaltet sich',
  'Die Zukunft nimmt Gestalt an',
  'Ein kleiner Moment der Vorbereitung',
  "Gleich geht's los mit bahnbrechenden Ideen",
  'Die Bühne für Disruption wird bereitet'
];

export default function TextSpinner() {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSentenceIndex(
        (prevIndex) => (prevIndex + 1) % sentences.length
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return <span>{sentences[currentSentenceIndex]}…</span>;
}
