import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import CompanyLogo from 'components/CompanyLogo';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import ProjectWrapper from 'components/Layout/ProjectWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import ToggleSwitch from 'components/ToggleSwitch';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { ROUTE_CUSTOMERS_BASE, ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface CreateCustomerScreenState {}
interface CreateCustomerScreenProps
  extends HistoryProps,
    WrappedComponentProps {
  organizationsStore: OrganizationsStoreType;
  dataStore: DataStoreType;
  form: FormType;
}

@inject('dataStore', 'organizationsStore')
@observer
class CreateCustomerScreen extends React.Component<
  CreateCustomerScreenProps,
  CreateCustomerScreenState
> {
  async addCustomer() {
    const { form, organizationsStore, dataStore, history } = this.props;
    if (!dataStore.currentOrganization) {
      return;
    }

    const customer = {
      name: form.values.name || '',
      sector: form.values.sector || '',
      network_enabled: form.values['network enabled'] || false
    };
    try {
      const newCustomer = await organizationsStore.createCustomer(
        dataStore.currentOrganizationId!,
        customer
      );

      if (!organizationsStore.isItemSaveError) {
        if (newCustomer && newCustomer.id) {
          history.replace(ROUTE_LAB + '/' + newCustomer.id);
        } else {
          history.replace(ROUTE_LAB + '/' + dataStore.currentOrganizationId);
        }
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  renderPage(content: any) {
    const { dataStore, intl, history } = this.props;

    let header;

    const org = dataStore.currentOrganization;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    return (
      <AppLayoutReduced active="lab" withSettings={true}>
        <PageHeader
          reduced={true}
          titleId="Our projects(titleId)"
          logoHeader={header}
        />
        {content}
        <OverlayWrapper topRight={true}>
          <CloseButton
            label={intl.formatMessage({ id: 'Close' })}
            iconName="cross"
            onClick={() => {
              history.push(ROUTE_CUSTOMERS_BASE + '/' + dataStore.currentOrganizationId);
            }}
          />
        </OverlayWrapper>
      </AppLayoutReduced>
    );
  }

  renderError() {
    return this.renderPage(<ErrorMessage state="save_error" />);
  }

  render() {
    const { organizationsStore, dataStore, form } = this.props;

    const org = dataStore.currentOrganization;
    if (!org) {
      return this.renderError();
    }

    return this.renderPage(
      <>
        <Form loading={form.loading} onSubmit={() => this.addCustomer()}>
          <ProjectWrapper>
            <FormTagList form={form} field="tags" allowAdd={true} />

            <ColumnWrapper gap="2em">
              {organizationsStore.isItemSaveError && (
                <ErrorMessage
                  state={organizationsStore.itemLoadingState}
                  onRetry={() => this.addCustomer()}
                />
              )}

              <CardWrapper>
                <Input
                  name="name"
                  label={<FormattedMessage id="Customer name" />}
                  autoFocus={true}
                  maxLength={30}
                  {...form.bindInput('name')}
                />
              </CardWrapper>

              <CardWrapper>
                <Input
                  name="sector"
                  label={<FormattedMessage id="Sector" />}
                  maxLength={30}
                  {...form.bindInput('sector')}
                />
              </CardWrapper>

              <PositionWrapper center={true}>
                <RowWrapper gap="1em">
                  <ToggleSwitch
                    label={<FormattedMessage id="Customer network enabled" />}
                    {...form.bindCheckbox('network enabled')}
                  />
                  <span aria-hidden="true">
                    <FormattedMessage id="Project network enabled" />
                  </span>
                </RowWrapper>
              </PositionWrapper>

              <PositionWrapper center={true}>
                <MainButton type="submit">
                  <FormattedMessage id="Customer create" />
                </MainButton>
              </PositionWrapper>
            </ColumnWrapper>
          </ProjectWrapper>
        </Form>

        <CallToAction />
      </>
    );
  }
}

export default injectIntl((props: CreateCustomerScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <CreateCustomerScreen {...props} form={form} />;
});
