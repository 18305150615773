import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import HypothesisModel, { HypothesisModelType } from './HypothesisModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PublishStateEnum, { createPublishState } from './PublishStateEnum';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const LearningModel = types
  .model('LearningModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    author: types.maybe(FluidUserModel),
    hypothesis: types.maybe(
      types.reference(types.late((): IAnyModelType => HypothesisModel))
    ),
    headline: types.maybe(types.string),
    description: types.maybe(types.string),
    result: types.maybe(types.string),
    publish_state: types.maybe(PublishStateEnum),
    created_at: types.maybe(types.string),
    published_at: types.maybe(types.string),
    average_target_group_relevance: types.maybe(types.number),
    average_revenue_potential: types.maybe(types.number),
    average_cost_efficiency: types.maybe(types.number),
    average_differentiation_degree: types.maybe(types.number),
    tags: types.maybe(TagListModel),
    comments_count: types.maybe(types.number),
    comments: CommentsMapModel,
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number)
  })
  .actions(self => {
    const setHypothesis = (hypothesis?: HypothesisModelType) => {
      self.hypothesis = hypothesis;
    };

    return {
      setHypothesis
    };
  });

export const createLearningModel = (data?: any): LearningModelType => {
  return LearningModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    author: createFluidUserModel(data?.author),
    hypothesis: undefined,
    headline: data?.headline || undefined,
    description: data?.description || undefined,
    result: data?.result || undefined,
    publish_state: createPublishState(data?.publish_state),
    created_at: data?.created_at || undefined,
    published_at: data?.published_at || data?.created_at || undefined,
    average_target_group_relevance: numberOrUndefined(
      data?.average_target_group_relevance
    ),
    average_revenue_potential: numberOrUndefined(
      data?.average_revenue_potential
    ),
    average_cost_efficiency: numberOrUndefined(data?.average_cost_efficiency),
    average_differentiation_degree: numberOrUndefined(
      data?.average_differentiation_degree
    ),
    tags: createTagList(data?.tags),
    comments_count: numberOrUndefined(data?.ratings_count),
    comments: createCommentsMap(data?.ratings),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count)
  });
};

export type LearningModelType = typeof LearningModel.Type;
export default LearningModel;
