import { types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

import OrgAccessLevelEnumModel, {
  createOrgAccessLevelEnumModel
} from './OrgAccessLevelEnumModel';
import UserModel, { UserModelType } from './UserModel';

export const OrgMemberModel = types
  .model('OrgMemberModel', {
    id: types.identifierNumber,
    access_level: types.maybe(OrgAccessLevelEnumModel),
    user: types.maybe(
      types.reference(types.late(() => UserModel))
    )
  })
  .actions(self => {
    const setUser = (user?: UserModelType) => {
      self.user = user;
    };

    return {
      setUser
    };
  });

export const createOrgMemberModel = (data?: any): OrgMemberModelType | undefined => {
  if (!data?.user?.id) {
    return undefined;
  }

  return OrgMemberModel.create({
    id: mandatoryId(data.user.id),
    access_level: createOrgAccessLevelEnumModel(data.access_level),
    user: undefined
  });
};

export type OrgMemberModelType = typeof OrgMemberModel.Type;
export default OrgMemberModel;
