import { inject, observer } from 'mobx-react';
import React from 'react';

import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import BenchmarkFormComponent, {
  MAX_BENCHMARKS
} from './BenchmarkFormComponent';

interface BenchmarkScreenProps {
  assistantStore: AssistantStoreType;
}

@inject('assistantStore')
@observer
class BenchmarkScreen extends React.Component<
  BenchmarkScreenProps & HistoryProps
> {
  componentDidMount() {
    this.redirectIfFinished();
  }

  componentDidUpdate(prevProps: HistoryProps) {
    if (this.props.location?.key !== prevProps.location?.key) {
      this.redirectIfFinished();
    }
  }

  private getIndex() {
    const { location } = this.props;

    const index = parseInt(location.query?.sessionBenchmark || '0', 10);
    if (isNaN(index) || index < 1 || index > MAX_BENCHMARKS) {
      return undefined;
    }

    return index - 1;
  }

  private hasValidIndex() {
    return this.getIndex() !== undefined;
  }

  private isFinished() {
    const {
      assistantStore: { currentItem }
    } = this.props;

    if (this.hasValidIndex()) {
      // edit mode
      return false;
    }

    return (
      (currentItem?.progress || 0) >=
        ASSISTANTS.benchmarks_sprint.step2.progress &&
      (currentItem?.activeSessionBenchmarksCount || 0) >= MAX_BENCHMARKS
    );
  }

  private redirectIfFinished() {
    if (this.isFinished()) {
      const { currentId } = this.props.assistantStore;
      if (!currentId) {
        return;
      }

      this.props.history.push(
        ROUTE_ASSISTANT + '/' + currentId + '/session3/done'
      );
    }
  }

  render() {
    if (this.isFinished()) {
      // redirect will be triggered anyway, so do not render form
      return null;
    }

    const { location } = this.props;

    const indexFromUrl = this.getIndex();
    if (indexFromUrl !== undefined) {
      // index present in location (edit mode)
      return (
        <BenchmarkFormComponent
          key={'edit-' + indexFromUrl}
          index={indexFromUrl}
          location={location}
          editMode={true}
        />
      );
    }

    const {
      assistantStore: { currentItem }
    } = this.props;

    if (!currentItem) {
      // cannot do anything without session
      return null;
    }

    // always display last benchmark if no index is present in location (new/draft mode)
    let currentBenchmarkIndex = currentItem.activeSessionBenchmarksCount;
    if (currentBenchmarkIndex >= MAX_BENCHMARKS) {
      currentBenchmarkIndex = MAX_BENCHMARKS - 1;
    }

    return (
      <BenchmarkFormComponent
        key={'new-' + currentBenchmarkIndex}
        index={currentBenchmarkIndex}
        location={location}
      />
    );
  }
}

export default BenchmarkScreen;
