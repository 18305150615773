import classNames from 'classnames';
import { forwardRef } from 'react';

import InputInterface, { cleanupInputProps } from './InputInterface';
import InputStateContainer from './InputStateContainer';

type HtmlTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface TextAreaInterface {
  predefined_text?: React.ReactChild;
  noAutoHeight?: boolean;
  lines?: number;
  largeFont?: boolean;
  slim?: boolean;
  slimPadding?: boolean;
  normalFont?: boolean;
}

export default forwardRef(
  (props: HtmlTextareaProps & InputInterface & TextAreaInterface, ref: any) => {
    const textareaClasses = classNames('edit-textarea__text', {
      'edit-textarea__text--gap': props.gap,
      'edit-textarea__text--slider': props.slider,
      'edit-textarea__text--large-font': props.largeFont,
      'edit-textarea__text--normal-font': props.normalFont,
      'edit-textarea__text--slim': props.slim,
      'edit-textarea__text--slim-padding': props.slimPadding
    });

    const id = props.id ? props.id : '__formfield_' + props.name;

    if (props.predefined_text) {
      return (
        <div className="edit-textarea">
          {props.label && <label htmlFor={id}>{props.label}</label>}
          <InputStateContainer error={props.error} success={props.success}>
            <div
              className="edit-textarea__wrapper edit-textarea__wrapper--focused"
              style={
                props.lines
                  ? {
                      height: `calc(2.56em * ${props.lines + 1} + 0.6em)`
                    }
                  : {}
              }
            >
              {props.predefined_text && (
                <span className={'edit-textarea__predefined-text'}>
                  {props.predefined_text}
                </span>
              )}
              <textarea
                id={id}
                ref={ref}
                style={
                  props.lines
                    ? {
                        height: 'auto',
                        minHeight: `calc(2em * ${props.lines})`
                      }
                    : {}
                }
                onInput={
                  props.noAutoHeight
                    ? undefined
                    : ({ target }) => {
                        if (target instanceof HTMLTextAreaElement) {
                          target.style.height = `auto`;
                          target.style.height = `${target.scrollHeight}px`;
                        }
                      }
                }
                className={textareaClasses}
                {...cleanupInputProps(props)}
              />
              <div className="edit-textarea__outline" />
            </div>
          </InputStateContainer>
        </div>
      );
    } else {
      return (
        <div className="edit-textarea">
          {props.label && <label htmlFor={id}>{props.label}</label>}
          <InputStateContainer error={props.error} success={props.success}>
            <textarea
              id={id}
              ref={ref}
              style={
                props.lines && {
                  height: `calc(1.857em * ${props.lines} + 1.5em)`
                }
              }
              className={textareaClasses}
              {...cleanupInputProps(props)}
            />
          </InputStateContainer>
        </div>
      );
    }
  }
);
