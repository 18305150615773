import { inject, observer } from 'mobx-react';
import React from 'react';

import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';

interface PublicOrganizationContainerProps {
  orgId: number;
  loaderComponent?: React.ComponentType<any>;
  errorComponent?: React.ComponentType<any>;
  children?: React.ReactNode;
}

interface OrganizationContainerProps {
  organizationsStore: OrganizationsStoreType;
  dataStore: DataStoreType;
}

@inject('organizationsStore', 'dataStore')
@observer
class OrganizationContainer extends React.Component<
  OrganizationContainerProps & PublicOrganizationContainerProps
> {
  convertId(id: any): number {
    if (!id) {
      return 0;
    }

    return typeof id === 'number' ? id : parseInt(id, 10);
  }

  getOrgId(): number {
    return this.convertId(this.props.orgId);
  }

  async selectOrganization(orgId: number) {
    const { dataStore } = this.props;

    if (dataStore.organization?.id === orgId) {
      // organization has been loaded already
      return;
    }

    const orgFromStore = dataStore.organizations.get(orgId.toString());
    if (orgFromStore) {
      // TODO We might refresh organization info at this point in the future. For now organizations
      // do not carry any information that is expected to change often so we can rely on cached data.
      dataStore.setOrganization(orgFromStore);
      return;
    }

    const org = await this.props.organizationsStore.getOrganization(orgId);

    // we need to select this org for the current context manually
    // TODO create organizationsStore.getOrganizationForContext() to handle all the context org related stuff?
    if (org) {
      dataStore.setOrganization(org);
    }
  }

  componentDidMount() {
    const orgId = this.getOrgId();

    if (!orgId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error('OrganizationContainer mounted without organization ID!');
      return;
    }

    this.selectOrganization(orgId);
  }

  componentDidUpdate(
    prevProps: OrganizationContainerProps & PublicOrganizationContainerProps
  ) {
    const orgId = this.getOrgId();

    if (orgId === this.convertId(prevProps.orgId)) {
      return;
    }

    if (!orgId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error('OrganizationContainer updated without organization ID!');
      return;
    }

    this.selectOrganization(orgId);
  }

  renderOrgNotPresent(orgId: number) {
    const { organizationsStore, errorComponent, loaderComponent } = this.props;

    if (!orgId || organizationsStore.isItemLoadError) {
      if (errorComponent) {
        return React.createElement(errorComponent, {
          orgId,
          error: !orgId ? 'invalid_id' : organizationsStore.itemLoadingState,
          onRetryClick: () => this.selectOrganization(orgId)
        });
      }

      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable-next-line: no-console
        console.error(
          'Warning: OrganizationContainer has no errorComponent prop!'
        );
      }
      return null;
    }

    if (loaderComponent) {
      return React.createElement(loaderComponent);
    }

    if (process.env.NODE_ENV !== 'production') {
      // tslint:disable-next-line: no-console
      console.error(
        'Warning: OrganizationContainer has no loaderComponent prop!'
      );
    }
    return null;
  }

  render() {
    const {
      dataStore: { organization }
    } = this.props;
    const orgId = this.getOrgId();

    if (!orgId || !organization || organization.id !== orgId) {
      return this.renderOrgNotPresent(orgId);
    }

    return this.props.children;
  }
}

export default (props: PublicOrganizationContainerProps) => (
  // @ts-ignore
  <OrganizationContainer {...props} />
);
