import { Route, Router, Switch } from 'react-router-dom';

import { history } from 'utils/history';

import FlashMessage from 'components/FlashMessage/FlashMessage';
import OverlaysContainer from 'containers/OverlaysContainer';
import ActivateScreen from 'screens/ActivateScreen';
import HomeScreen from 'screens/HomeScreen';
import JoinScreen from 'screens/JoinScreen';
import { UnauthorizedNotFoundRoute } from 'screens/NotFoundScreen';
import PasswordResetScreen from 'screens/PasswordResetScreen';
import SharedContentScreen from 'screens/SharedContentScreen';
import Styleguides from 'styleguides';
import { ROUTE_AUTH, ROUTE_SHARED } from 'utils/constants/routes';
import AuthenticationRouter from './AuthenticationRouter';

const AppRouter = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/" exact={true} component={HomeScreen} />
          <Route path="/reset/:token" component={PasswordResetScreen} />
          <Route path="/join/:token" component={JoinScreen} />
          <Route path="/verify/:token" component={ActivateScreen} />

          <Route path={ROUTE_SHARED} component={SharedContentScreen} />

          <Route path={ROUTE_AUTH} component={AuthenticationRouter} />

          {process.env.NODE_ENV === 'development' && (
            <Switch>
              <Route path="/styleguides" component={Styleguides} />
              <UnauthorizedNotFoundRoute />
            </Switch>
          )}

          <UnauthorizedNotFoundRoute />
        </Switch>
        <OverlaysContainer />
      </Router>

      <FlashMessage />
    </>
  );
};

export default AppRouter;
