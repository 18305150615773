export type SidebarMarkerType =
  | 'end'
  | 'material'
  | 'invitation'
  | 'briefing'
  | 'painpoint_description'
  | 'painpoint_question'
  | 'benchmarks'
  | 'solutions'
  | 'hypothesis'
  | 'userflow'
  | 'prototype_description'
  | 'hypothesis_details';

export const SidebarMarker = ({
  type,
  children
}: {
  type: SidebarMarkerType;
  children?: any;
}) => (
  <>
    <div
      id={'assistantScrollContainer_' + type}
      style={{ height: '1px', margin: 0, padding: 0 }}
    />
    {children}
  </>
);

export default SidebarMarker;
