import { inject, observer } from 'mobx-react';
import React from 'react';

import { DataStoreType } from 'models/DataStore';
import ProjectLayout, { ProjectLayoutProps } from './ProjectLayout';

interface PublicContextAwareProjectLayoutProps {
  active?: ProjectLayoutProps['active'];
  children?: ProjectLayoutProps['children'];
}

interface ContextAwareProjectLayoutProps {
  dataStore: DataStoreType;
}

@inject('dataStore')
@observer
class ContextAwareProjectLayout extends React.Component<
  ContextAwareProjectLayoutProps & PublicContextAwareProjectLayoutProps
> {
  render() {
    const { dataStore, active, children } = this.props;

    if (!dataStore.currentProject) {
      return children;
    }

    return (
      <ProjectLayout
        project={dataStore.currentProject}
        org={dataStore.currentOrganization}
        active={active}
      >
        {children}
      </ProjectLayout>
    );
  }
}

export default (props: PublicContextAwareProjectLayoutProps) => (
  // @ts-ignore
  <ContextAwareProjectLayout {...props} />
);
