
import CompanyLogo from 'components/CompanyLogo';
import { OrganizationModelType } from 'models/OrganizationModel';
import CardTop from './CardTop';

export default ({ org }: { org?: OrganizationModelType }) => {
  if (!org) {
    return null;
  }

  const logo = org.attachments.firstOfType('logo');

  return (
    <CardTop
      title={org.name}
      subtitle={org.sector}
      logo={
        logo?.resource_urls && (
          <CompanyLogo imgUrl={logo.resource_urls.small} alt="" />
        )
      }
    />
  );
};
