import { injectIntl, WrappedComponentProps } from 'react-intl';

import AutoGrid from 'components/Layout/AutoGrid';
import SearchForm from 'components/SearchForm/SelectPopupSearchForm';
import SimplePopup from 'components/SimplePopup';
import PainpointsListContainer from 'containers/PainpointsListContainer';
import useForm from 'utils/hooks/useForm';

import NoneFoundInPopupComponent from './NoneFoundInPopupComponent';
import PainpointSelectPopupItem from './PainpointSelectPopupItem';

export default injectIntl(
  ({
    onAbort,
    onSelect,
    intl
  }: {
    onAbort: () => void;
    onSelect: (id: number) => void;
  } & WrappedComponentProps) => {
    const form = useForm({ filter: '' });

    return (
      <SimplePopup onAbort={onAbort} headlineTextId="Select painpoint">
        <SearchForm
          name="filter"
          placeholder={intl.formatMessage({ id: 'Painpoints search' })}
          {...form.bindInput('filter')}
        />
        <div className="simple-popup__accent-body simple-popup__scrollable-body">
          <PainpointsListContainer
            itemType={PainpointSelectPopupItem}
            itemContainer={AutoGrid}
            emptyComponent={NoneFoundInPopupComponent}
            onItemClick={onSelect}
            filter={(form.values.filter || '').toString().trim()}
          />
        </div>
      </SimplePopup>
    );
  }
);
