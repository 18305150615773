import { Route, Switch } from 'react-router';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import UserContainer from 'containers/UserContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import BookmarksListScreen from 'screens/profile/BookmarksListScreen';
import UserBenchmarksListScreen from 'screens/profile/UserBenchmarksListScreen';
import UserBriefingsListScreen from 'screens/profile/UserBriefingsListScreen';
import UserEditScreen from 'screens/profile/UserEditScreen';
import UserHypothesesListScreen from 'screens/profile/UserHypothesesListScreen';
import UserLearningsListScreen from 'screens/profile/UserLearningsListScreen';
import UserOverviewScreen from 'screens/profile/UserOverviewScreen';
import UserPainpointsListScreen from 'screens/profile/UserPainpointsListScreen';
import UserPrototypesListScreen from 'screens/profile/UserPrototypesListScreen';
import {
  ROUTE_USER_PROFILE_BENCHMARKS,
  ROUTE_USER_PROFILE_BOOKMARKS,
  ROUTE_USER_PROFILE_BRIEFINGS,
  ROUTE_USER_PROFILE_DRAFTS,
  ROUTE_USER_PROFILE_HYPOTHESES,
  ROUTE_USER_PROFILE_LEARNINGS,
  ROUTE_USER_PROFILE_OVERVIEW,
  ROUTE_USER_PROFILE_PAINPOINTS,
  ROUTE_USER_PROFILE_PROTOTYPES,
  ROUTE_USER_PROFILE_SETTINGS
} from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';

const ProfileErrorComponent = ({ _projectId, error, onRetry }: any) => (
  <ErrorMessage state={error} onRetry={onRetry} />
);
const ProfileLoaderComponent = () => <Loading />;

export default ({ match }: HistoryProps) => (
  <UserContainer
    userId={idFromMatch(match) || NaN}
    errorComponent={ProfileErrorComponent}
    loaderComponent={ProfileLoaderComponent}
  >
    <Switch>
      <Route
        path={ROUTE_USER_PROFILE_SETTINGS}
        exact={true}
        component={UserEditScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_BOOKMARKS}
        exact={true}
        component={BookmarksListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_DRAFTS}
        exact={true}
        component={UserEditScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_OVERVIEW}
        exact={true}
        component={UserOverviewScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_BRIEFINGS}
        exact={true}
        component={UserBriefingsListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_BENCHMARKS}
        exact={true}
        component={UserBenchmarksListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_PAINPOINTS}
        exact={true}
        component={UserPainpointsListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_HYPOTHESES}
        exact={true}
        component={UserHypothesesListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_PROTOTYPES}
        exact={true}
        component={UserPrototypesListScreen}
      />
      <Route
        path={ROUTE_USER_PROFILE_LEARNINGS}
        exact={true}
        component={UserLearningsListScreen}
      />

      <NotFoundRoute />
    </Switch>
  </UserContainer>
);
