
import Icon from 'components/Icon';

export interface EditButtonProps {
  label: string;
  iconName?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

export default function EditButton({
  label,
  iconName,
  onClick,
  onBlur
}: EditButtonProps) {
  return (
    <button
      className="edit-button"
      aria-label={label}
      onClick={onClick}
      onBlur={onBlur}
    >
      <Icon name={iconName || 'pen'} />
    </button>
  );
}
