import AppLayout from 'components/AppLayout';
import ErrorMessage from 'components/ErrorMessage';
import Icon from 'components/Icon';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FaqPageModelType } from 'models/FaqPageModel';
import { FaqPagesStoreType } from 'models/FaqPagesStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { iconBySection } from 'utils/faq/icon_by_section';
import { HistoryProps, idFromMatch } from 'utils/history';

interface FaqPagesDetailScreenProps {
  applicationStore: ApplicationStoreType;
  faqPagesStore: FaqPagesStoreType;
}

@inject('applicationStore', 'faqPagesStore')
@observer
class FaqPagesDetailScreen extends React.Component<
  FaqPagesDetailScreenProps & HistoryProps
> {
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { faqPagesStore, match } = this.props;
    const id = idFromMatch(match);

    if (!id) {
      return;
    }

    await faqPagesStore.getFaqPage(id);
  }

  replaceVideos(content: string, item: FaqPageModelType): string {
    if (content.includes('{main_video}')) {
      const main_video = item.main_video_embedding;
      content = content.replace('{main_video}', `${main_video}`);
    }

    if (content.includes('{second_video}')) {
      const second_video = item.second_video_embedding;
      content = content.replace('{second_video}', `${second_video}`);
    }

    if (content.includes('{third_video}')) {
      const third_video = item.third_video_embedding;
      content = content.replace('{third_video}', `${third_video}`);
    }

    return content;
  }

  renderFaqPages() {
    const {
      faqPagesStore: { faqPageItem }
    } = this.props;

    if (!faqPageItem || !faqPageItem.page_content_body) {
      return this.renderPage(
        <h2>
          <FormattedMessage id="faq page content missing" />
        </h2>
      );
    }

    const content = this.replaceVideos(
      faqPageItem.page_content_body,
      faqPageItem
    );

    return this.renderPage(
      <div className="faq_detail">
        <h2 className="faq_detail__subheadline">
          {faqPageItem.description || ''}
        </h2>

        <div dangerouslySetInnerHTML={{ __html: content }} />

        <CommentsContainer
          elementType={ElementType.FaqPage}
          element={faqPageItem}
        />
      </div>
    );
  }

  renderPage(content: any) {
    const { faqPagesStore } = this.props;
    const { faqPageItem } = faqPagesStore;

    if (!faqPageItem) {
      return null;
    }

    const icon = iconBySection(faqPageItem.section);

    return (
      <AppLayout active="faq-pages" reduced={true}>
        <PageHeader
          titleId="FAQ(titleId)"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              logo={
                <span className="faq_detail__header_logo">
                  <Icon name={icon} size="large" />
                </span>
              }
              title={faqPageItem.title}
              subtitle={faqPageItem.section}
            />
          }
        />
        <ContentWrapper>{content}</ContentWrapper>
      </AppLayout>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  renderError() {
    const { faqPagesStore } = this.props;
    return this.renderPage(
      <ErrorMessage
        state={faqPagesStore.loadingState}
        onRetry={() => this.loadData()}
      />
    );
  }

  render() {
    const { faqPagesStore } = this.props;

    if (faqPagesStore.isLoading) {
      return this.renderLoading();
    }

    if (faqPagesStore.isError) {
      return this.renderError();
    }

    return this.renderFaqPages();
  }
}

export default FaqPagesDetailScreen;
