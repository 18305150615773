import { inject, observer } from 'mobx-react';
import React from 'react';

import AssistantSessionContainer from 'containers/AssistantSessionContainer';
import { AssistantStoreType } from 'models/AssistantStore';
import { goToNextStep } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface EnterSessionScreenProps {
  assistantStore?: AssistantStoreType;
}

@inject('assistantStore')
@observer
class EnterSessionScreen extends React.Component<
  EnterSessionScreenProps & HistoryProps
> {
  componentDidMount() {
    const { assistantStore, history } = this.props;
    const { currentId, currentProgress, currentAssistantType } =
      assistantStore!;

    if (
      !currentId ||
      !currentAssistantType ||
      !goToNextStep(currentId, currentProgress, currentAssistantType, true)
    ) {
      history.replace(ROUTE_ASSISTANT);
    }
  }

  render() {
    return null;
  }
}

export default (props: HistoryProps) => (
  <AssistantSessionContainer
    history={props.history}
    assistantSessionId={props.match.params?.id}
    assistantType="any"
  >
    <EnterSessionScreen {...props} />
  </AssistantSessionContainer>
);
