import Form from 'components/Form';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import SimplePopup from 'components/SimplePopup';
import { inject, observer } from 'mobx-react';
import { PainpointModelType } from 'models/PainpointModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicPainpointCreateContainerProps extends WrappedComponentProps {
  painpoint?: PainpointModelType;
  clusterId?: number;
  onClose?: () => void;
}

interface PainpointCreateContainerProps
  extends PublicPainpointCreateContainerProps {
  painpointsStore: PainpointsStoreType;
  form: FormType;
}

@inject('dataStore', 'painpointsStore')
@observer
class PainpointCreateContainer extends React.Component<
  PainpointCreateContainerProps & HistoryProps
> {
  componentDidMount() {
    const { painpoint, form } = this.props;

    let clusterId;
    if (painpoint) {
      // edit mode
      clusterId = painpoint.cluster?.id;
      form.setField('question', painpoint.question || '');
    } else {
      // create mode
      clusterId = this.props.clusterId;
    }

    if (!!clusterId) {
      form.setField('cluster_id', clusterId);
    } else {
      form.setField(
        'cluster_id',
        this.props.painpointsStore.clustersByName[0].id
      );
    }
  }

  async save() {
    const { painpointsStore, form, painpoint } = this.props;

    const patch: any = {
      ...form.values,
      publish_state: 'active'
    };

    if (!patch.cluster_id || patch.cluster_id === -1) {
      const clusters = painpointsStore.clustersByName;
      if (clusters.length > 0) {
        patch.cluster_id = clusters[0].id;
      }
    }

    try {
      if (painpoint) {
        await painpointsStore.updatePainpoint(painpoint.id, patch);
      } else {
        await painpointsStore.createPainpoint(patch, true);
      }
    } catch (error: any) {
      if (handleFormError(form, error)) {
        return;
      }
    }

    if (!painpointsStore.isItemSaveError) {
      this.props.onClose && this.props.onClose();
    }
  }

  renderForm() {
    const { form, painpointsStore }: PainpointCreateContainerProps = this.props;

    return (
      <>
        <Select
          name="cluster_id"
          normalFont={true}
          label={<FormattedMessage id="Cluster" />}
          {...form.bindInput('cluster_id')}
        >
          {painpointsStore.clustersByName.map((cluster) => (
            <option value={cluster.id} key={cluster.id}>
              {cluster.name?.toString().toUpperCase() || '-'}
            </option>
          ))}
        </Select>
        <p>&nbsp;</p>

        <Textarea
          autoFocus={true}
          lines={3}
          normalFont={true}
          label={<FormattedMessage id="painpoint question" />}
          predefined_text={<FormattedMessage id="painpoint question prefix" />}
          name="question"
          {...form.bindInput('question')}
          onKeyPress={(e: any) => {
            if (e.charCode === 13) {
              e.preventDefault();
              this.save();
            }
          }}
        />
        {painpointsStore.isItemSaveError && (
          <FormattedMessage id={'save error'} />
        )}
      </>
    );
  }

  render() {
    const { onClose, painpointsStore }: PainpointCreateContainerProps =
      this.props;
    const submitTextId = painpointsStore.isItemSaveError ? 'Try again' : 'Save';
    return (
      <Form disabled={painpointsStore.isItemSaving}>
        <SimplePopup
          onSubmit={() => this.save()}
          submitTextId={submitTextId}
          onAbort={onClose}
          isLoading={painpointsStore.isItemSaving}
        >
          {this.renderForm()}
        </SimplePopup>
      </Form>
    );
  }
}

export default injectIntl((props: PublicPainpointCreateContainerProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <PainpointCreateContainer {...props} form={form} />;
});
