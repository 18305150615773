
import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import ProjectMembersContainer from 'containers/ProjectMembersContainer';

export default () => (
  <ContextAwareProjectLayout active="settings">
    <PageHeader titleId="Settings" />

    <ContentWrapper small={true}>
      <ProjectMembersContainer />
    </ContentWrapper>
  </ContextAwareProjectLayout>
);
