import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';

interface SimplePopupProps {
  onAbort?: () => void;
  onSubmit?: () => void;
  onSecondaryClick?: () => void;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  headlineTextId?: string;
  submitTextId?: string;
  secondaryTextId?: string;
  children?: React.ReactNode;
  reduced?: boolean;
  noPadding?: boolean;
}

export default ({
  onAbort,
  onSubmit,
  onSecondaryClick,
  isLoading,
  isSubmitDisabled,
  headlineTextId,
  submitTextId,
  secondaryTextId,
  children,
  reduced,
  noPadding
}: SimplePopupProps) => {
  let dialogElement: HTMLDivElement | null = null;
  // Focus dialog element initially
  useEffect(() => {
    if (dialogElement) {
      dialogElement.focus();
    }
  }, []);
  // Handle escape key
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const { activeElement } = document;
      if (
        onAbort &&
        event.key === 'Escape' &&
        (!activeElement ||
          !['INPUT', 'TEXTAREA', 'SELECT'].includes(activeElement.tagName))
      ) {
        onAbort();
      }
    };
    document.documentElement.addEventListener('keyup', handler);
    return () => {
      document.documentElement.removeEventListener('keyup', handler);
    };
  }, [onAbort]);
  return (
    <>
      <OverlayWrapper
        disabled={true}
        background="dark"
        center={true}
        reduced={reduced ? true : false}
        noPadding={noPadding}
      >
        <CardWrapper>
          <div
            ref={(el) => (dialogElement = el)}
            className="simple-popup"
            role="dialog"
            aria-modal="true"
            tabIndex={0}
          >
            {onAbort && <DestroyButton onClick={onAbort} />}

            {headlineTextId && (
              <h1 className="simple-popup__headline">
                <FormattedMessage id={headlineTextId} />
              </h1>
            )}

            {children && (
              <div className="simple-popup__children">{children}</div>
            )}

            {onSubmit && (
              <div className="simple-popup__submit-wrapper">
                <MainButton
                  disabled={isLoading || isSubmitDisabled}
                  type="submit"
                  onClick={onSubmit}
                >
                  <FormattedMessage id={submitTextId || 'Save'} />
                </MainButton>
                {onSecondaryClick && (
                  <MainButton
                    disabled={isLoading}
                    secondary={true}
                    type="button"
                    onClick={onSecondaryClick}
                  >
                    <FormattedMessage id={secondaryTextId || 'Cancel'} />
                  </MainButton>
                )}
              </div>
            )}
          </div>
        </CardWrapper>
      </OverlayWrapper>
      {isLoading && <Loading />}
    </>
  );
};
