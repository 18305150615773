import AIResponseCounter from 'components/AIResponseCounter/AIResponseCounter';
import ActionCableComponent, {
  CableElementType
} from 'components/ActionCableComponent/ActionCableComponent';
import AssistantStats from 'components/AssistantStats';
import CallToAction from 'components/CallToAction';
import CompanyLogo from 'components/CompanyLogo';
import CompanyNavItem from 'components/CompanyNavItem';
import Icon from 'components/Icon';
import ContentLayout from 'components/Layout/ContentLayout';
import AiLoading from 'components/Loading/AiLoading';
import AiUnavailable from 'components/Loading/AiUnavailable';
import SubNav from 'components/SubNav';
import SubNavCategory from 'components/SubNavCategory';
import SubNavItem from 'components/SubNavItem';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantType } from 'models/AssistantSessionModel';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { OrganizationModelType } from 'models/OrganizationModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { ProjectModelType } from 'models/ProjectModel';
import { ProjectsStoreType } from 'models/ProjectsStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTE_LAB, ROUTE_NETWORK } from 'utils/constants/routes';
import simplifyOrgAccessLevel from 'utils/misc/simplify-org-access-level';

export interface ProjectLayoutProps {
  active?:
    | 'briefing'
    | 'painpoints'
    | 'benchmarks'
    | 'hypotheses'
    | 'prototypes'
    | 'learnings'
    | 'settings'
    | 'members'
    | AssistantType;
  children?: React.ReactNode;
  org?: OrganizationModelType;
  project: ProjectModelType;
  dataStore?: DataStoreType;
  applicationStore?: ApplicationStoreType;
  painpointsStore?: PainpointsStoreType;
  benchmarksStore?: BenchmarksStoreType;
  hypothesesStore?: HypothesesStoreType;
  projectsStore?: ProjectsStoreType;
}

type ItemState = 'off' | 'inactive' | 'on';

function stateHelper(
  network: boolean,
  admin: boolean,
  project: ProjectModelType,
  type: 'painpoints' | 'benchmarks' | 'hypotheses' | 'prototypes' | 'learnings'
): ItemState {
  const value = network
    ? project[`network_${type}_state`]
    : project[`${type}_state`];

  return value === 'on' ? 'on' : admin ? 'inactive' : 'off';
}

// tslint:disable: jsx-wrap-multiline
const ProjectLayout = inject(
  'applicationStore',
  'dataStore',
  'painpointsStore',
  'benchmarksStore',
  'hypothesesStore',
  'projectsStore'
)(
  observer(
    ({
      dataStore,
      applicationStore,
      painpointsStore,
      benchmarksStore,
      hypothesesStore,
      projectsStore,
      active,
      children,
      project,
      org
    }: ProjectLayoutProps) => {
      const labPath =
        ROUTE_LAB + '/' + project.organization_id + '/' + project.id;
      const basePath = labPath + '/';
      const accessLevel = simplifyOrgAccessLevel(org);
      const network = accessLevel === 'network';
      const admin = accessLevel === 'admin';
      const mayEdit = dataStore!.mayEdit(dataStore!.briefing?.author);

      const settingsState: ItemState = admin || mayEdit ? 'on' : 'off';
      const painpointsState = stateHelper(
        network,
        admin,
        project,
        'painpoints'
      );
      const benchmarksState = stateHelper(
        network,
        admin,
        project,
        'benchmarks'
      );
      const hypothesesState = stateHelper(
        network,
        admin,
        project,
        'hypotheses'
      );
      const prototypesState = stateHelper(
        network,
        admin,
        project,
        'prototypes'
      );
      const learningsState = stateHelper(network, admin, project, 'learnings'); // sets up the painpoints and benchmarks for websocket updates
      if (
        !dataStore?.painpointsList &&
        !window.location.pathname.includes('painpoints')
      ) {
        painpointsStore?.getPainpoints();
      }
      if (!dataStore?.benchmarksList) {
        benchmarksStore?.getBenchmarks();
      }
      // only get hypotheses if the list is not already populated and we are not in the hypothesis details view
      if (
        !dataStore?.hypothesesList &&
        !window.location.pathname.includes('hypotheses')
      ) {
        hypothesesStore?.getHypotheses();
      }

      if (dataStore && !dataStore.gptRequestCounterList) {
        projectsStore?.getGPTRequestCounter();
      }

      function handleCableUpdate(elementType: CableElementType, element: any) {
        switch (elementType) {
          case 'painpoint':
            dataStore!.updatePainpointFromCable(element.id, element);
            break;

          case 'cluster':
            dataStore!.updateClusterFromCable(element.id, element);
            break;

          case 'benchmark':
            dataStore!.updateBenchmarkFromCable(element.id, element);
            break;

          case 'hypothesis':
            dataStore!.updateHypothesisFromCable(element.id, element);
            break;

          case 'prototype':
            dataStore!.updatePrototypeFromCable(element.id, element);
            break;

          case 'gptRequestCounter':
            dataStore!.updateGPTRequestCounterFromCable(element.id, element);
            break;

          default:
        }
      }

      function handleCableDelete(
        elementType: CableElementType,
        elementId: number
      ) {
        switch (elementType) {
          case 'painpoint':
            dataStore!.deletePainpoint(elementId);
            break;

          case 'cluster':
            dataStore!.deleteCluster(elementId);
            break;

          case 'benchmark':
            dataStore!.deleteBenchmark(elementId);
            break;

          case 'hypothesis':
            dataStore!.deleteHypothesis(elementId);
            break;

          case 'prototype':
            dataStore!.deletePrototype(elementId);
            break;

          default:
        }
      }

      let company;
      if (network) {
        const logo = org?.attachments.firstOfType('logo');
        company = (
          <>
            <CompanyNavItem
              header={true}
              title={org?.name}
              subtitle={org?.sector}
              logo={
                logo && (
                  <CompanyLogo imgUrl={logo.resource_urls?.small} alt="" />
                )
              }
              to={ROUTE_LAB + '/' + org?.id}
            />

            <SubNav.BackLink to={ROUTE_NETWORK} />
          </>
        );
      }

      const settingsAvailable = settingsState !== 'off';

      const ProjectLineWrapper = (
        props: React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement>
      ) => {
        const { children } = props;

        if (settingsAvailable) {
          return (
            <Link to={basePath + 'settings'} {...props}>
              {children}
            </Link>
          );
        }
        return <div {...props}>{children}</div>;
      };

      return (
        <ContentLayout
          subNav={
            <SubNav>
              {company}

              <ProjectLineWrapper className="main-nav__anchor l-item-stack-horizontal l-item-stack-horizontal--space-between l-item-stack-horizontal--at-top">
                <>
                  <SubNav.Header>{project.topic}</SubNav.Header>
                  {settingsAvailable && <Icon name="cogwheel" size="medium" />}
                </>
              </ProjectLineWrapper>

              <SubNavCategory title={false}>
                <SubNavItem
                  title={
                    <>
                      <FormattedMessage id="Briefing" />
                      {project.briefing?.comments?.hasAny
                        ? ` (${project.briefing.comments.all.length})`
                        : null}
                    </>
                  }
                  iconName="envelope"
                  active={active === 'briefing'}
                  to={labPath}
                />
                <SubNavItem
                  title={<FormattedMessage id="Learnings" />}
                  iconName="learning" // TODO
                  active={active === 'learnings'}
                  to={basePath + 'learnings'}
                  inactive={learningsState === 'inactive'}
                  disabled={learningsState === 'off'}
                  itemNumber={project.learnings_count}
                />
              </SubNavCategory>

              <SubNavCategory title={<FormattedMessage id="Collections" />}>
                <SubNavItem
                  title={<FormattedMessage id="Painpoints" />}
                  iconName="clipboard"
                  active={active === 'painpoints'}
                  to={basePath + 'painpoints'}
                  inactive={painpointsState === 'inactive'}
                  disabled={painpointsState === 'off'}
                  itemNumber={project.painpoints_count}
                  itemLimit={org!.painpoint_limit}
                />
                <SubNavItem
                  title={<FormattedMessage id="Benchmarks" />}
                  iconName="detail"
                  active={active === 'benchmarks'}
                  to={basePath + 'benchmarks'}
                  inactive={benchmarksState === 'inactive'}
                  disabled={benchmarksState === 'off'}
                  itemNumber={project.benchmarks_count}
                  itemLimit={org!.benchmark_limit}
                />
                <SubNavItem
                  title={<FormattedMessage id="Hypotheses" />}
                  iconName="bulb"
                  active={active === 'hypotheses'}
                  to={basePath + 'hypotheses'}
                  inactive={hypothesesState === 'inactive'}
                  disabled={hypothesesState === 'off'}
                  itemNumber={project.hypotheses_count}
                  itemLimit={org!.hypothesis_limit}
                />
                <SubNavItem
                  title={<FormattedMessage id="Prototypes" />}
                  iconName="strategy"
                  active={active === 'prototypes'}
                  to={basePath + 'prototypes'}
                  inactive={prototypesState === 'inactive'}
                  disabled={prototypesState === 'off'}
                  itemNumber={project.prototypes_count}
                  itemLimit={org!.prototype_limit}
                />
              </SubNavCategory>

              <AssistantStats
                key={project.id}
                basePath={basePath}
                active={active}
              />
            </SubNav>
          }
        >
          {dataStore?.project?.ai_at_work && <AiLoading />}
          {applicationStore?.aiUnavailable && <AiUnavailable />}
          <AIResponseCounter />
          {children}

          <CallToAction
            showAdd={true}
            showInvite={true}
            showAI={dataStore?.organization?.ai_enabled}
          />
          {project.id && (
            <ActionCableComponent
              channel="ProjectChannel"
              projectId={project.id}
              onUpdated={(elementType, element) =>
                handleCableUpdate(elementType, element)
              }
              onDeleted={(elementType, elementId) =>
                handleCableDelete(elementType, elementId)
              }
            />
          )}
        </ContentLayout>
      );
    }
  )
);

export default ProjectLayout;
