import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { FormattedMessage } from 'react-intl';

import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import shortenString from 'utils/misc/shorten-string';
import SidebarCard from '../SidebarCard';
import SidebarItem from '../SidebarItem';
import SidebarItemContent from '../SidebarItemContent';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';
import { generateBenchmarkLink } from './SidebarBenchmarks';

interface SidebarHypothesisLinksProps extends SidebarItemProps {
  dataStore?: DataStoreType;
  currentPath?: string;
  currentPathWithSearch?: string;
}

export const SidebarHypothesisLinks = ({
  currentPath,
  currentPathWithSearch,
  session,
  dataStore
}: SidebarHypothesisLinksProps) => {
  return (
    <SidebarMarker type="hypothesis_details">
      <SidebarItem>
        <SidebarItemHeader>
          <FormattedMessage id="assistant sidebar show painpoint" />
        </SidebarItemHeader>

        <SidebarItemContent>
          <SidebarCard
            to={{
              pathname: currentPath,
              search: `painpoint=${dataStore?.hypothesisItem?.painpoint?.id}`,
              state: {
                canGoBack: true
              }
            }}
            header={<FormattedMessage id="painpoint question prefix" />}
            text={shortenString(
              dataStore?.hypothesisItem?.painpoint?.question,
              LIST_ITEM_TEXT_LENGTH
            )}
            isQuestion={true}
          />
        </SidebarItemContent>
      </SidebarItem>
      <SidebarItem>
        <SidebarItemHeader>
          <FormattedMessage id="assistant sidebar benchmark link" />
        </SidebarItemHeader>

        {dataStore?.hypothesisItem?.benchmarks?.length
          ? dataStore?.hypothesisItem.benchmarks.map((benchmark) => {
              const attachment = benchmark.attachments.firstOfType('screen');
              const logo = benchmark.attachments.firstOfType('logo');
              const baseLink =
                ROUTE_ASSISTANT + '/' + session!.id + '/benchmark/';

              return (
                <SidebarItemContent key={benchmark.id}>
                  <RainbowCardCompact
                    to={generateBenchmarkLink(
                      baseLink,
                      benchmark.id,
                      currentPathWithSearch
                    )}
                    simple={true}
                    company={benchmark.company_name}
                    title={benchmark.headline}
                    src={attachment?.resource_urls?.small}
                    logo={
                      logo?.resource_urls?.small && (
                        <CompanyLogo
                          imgUrl={logo.resource_urls.small}
                          alt="Logo"
                        />
                      )
                    }
                  />
                </SidebarItemContent>
              );
            })
          : null}
      </SidebarItem>
    </SidebarMarker>
  );
};

export default inject('dataStore')(observer(SidebarHypothesisLinks));
