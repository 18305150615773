import classNames from 'classnames';

export const TabButton = ({
  title,
  active,
  onClick
}: {
  title: string;
  active: boolean;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className={classNames('tab-container__button', {
      'tab-container__button--active': active
    })}
  >
    {title}
  </button>
);
export default TabButton;
