import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';

import UserAvatar from 'components/Avatar/UserAvatar';
import Bookmark from 'components/Bookmark';
import { BookmarkRemovedEvent } from 'components/Bookmark/Bookmark';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import DestroyButton from 'components/DestroyButton';
import HashtagsList from 'components/HashtagsList';
import IconCounter from 'components/IconCounter';
import RainbowCard from 'components/RainbowCard';
import RaisedHand from 'components/RaisedHand/RaisedHand';
import Typography from 'components/Typography';
import { HypothesisModelType } from 'models/HypothesisModel';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_PROFILE } from 'utils/constants/routes';
import { isDefined } from 'utils/misc/is-defined';
import shortenString from 'utils/misc/shorten-string';
import HypothesesListItemMenu, {
  HypothesissListItemMenuProps
} from './HypothesesListItemMenu';

interface HypothesesListItemProps extends HypothesissListItemMenuProps {
  hypothesis: HypothesisModelType;
  showDestroyButton?: boolean;

  readOnly?: boolean;
  noLink?: boolean;
  fromList?: boolean;
  noMenu?: boolean;
  contextUri?: string;
  top?: React.ReactNode;

  checked?: boolean;
  onChange?: (checked: boolean) => void;

  onClick?: (e: any) => void;
  onBigRemoveClick?: () => void;
  onBookmarkRemoved?: BookmarkRemovedEvent;
}

export default injectIntl(
  ({
    hypothesis,
    checked,
    onChange,
    intl,
    contextUri,
    readOnly,
    fromList,
    showDestroyButton,
    onBookmarkRemoved,
    top,
    noMenu,
    ...menuProps
  }: HypothesesListItemProps & WrappedComponentProps) => {
    const attachment = hypothesis.attachment;

    const isDraft = hypothesis.publish_state === 'draft';

    const to: LinkProps['to'] = {
      pathname: `${
        isDefined(hypothesis.project)
          ? `/app/lab/${hypothesis.project.organization_id}/${hypothesis.project.id}/`
          : contextUri
          ? contextUri + '/'
          : ''
      }hypotheses/${hypothesis.id}${isDraft ? '/edit' : ''}`
    };
    if (fromList) {
      to.state = {
        switch: true
      };
    }

    return (
      <RainbowCard
        to={to}
        src={attachment?.resource_urls?.screen}
        alt=""
        checked={checked}
        onCheckboxChange={onChange}
        sideLabel={isDraft ? intl.formatMessage({ id: 'Draft' }) : undefined}
        top={top}
      >
        {showDestroyButton && menuProps.onRemoveClick && (
          <DestroyButton onClick={menuProps.onRemoveClick} />
        )}
        <CardHeader
          title={
            hypothesis.headline || intl.formatMessage({ id: 'no headline' })
          }
        />

        <Typography size="small">
          {shortenString(hypothesis.description, LIST_ITEM_TEXT_LENGTH)}
        </Typography>

        {hypothesis.requesting_user && (
          <RaisedHand
            label={hypothesis.requesting_user.initials}
            name={hypothesis.requesting_user.fullName}
          />
        )}

        <HashtagsList tags={hypothesis.tags} />

        {!noMenu && (
          <CardMenu avatar={
            <UserAvatar
              user={hypothesis.author}
              isAiRobot={hypothesis.created_by_ai}
            />}
          >
            <Bookmark
              elementType="Hypothesis"
              elementId={hypothesis.id}
              count={hypothesis.bookmarks_count}
              id={hypothesis.bookmark_id}
              disabled={readOnly}
              onRemoved={onBookmarkRemoved}
            />
            <IconCounter
              value={hypothesis.comments_count || 0}
              label={intl.formatMessage({ id: 'Comments' })}
              iconName="comment"
            />

            <HypothesesListItemMenu hypothesis={hypothesis} {...menuProps} />

            {hypothesis.created_by_ai && hypothesis.author &&
              <span className='ai-author'>
                Generated by <Link to={ROUTE_PROFILE.replace(':id', hypothesis.author.id!.toString())}>
                  <span className='underline'>{hypothesis.author.fullName}</span>
                </Link>
              </span>
            }
          </CardMenu>
        )}
      </RainbowCard>
    );
  }
);
