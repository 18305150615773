
import Tag from 'components/Tag';
import TagList from 'components/TagList';
import { FormType } from 'utils/hooks/useForm';
import arrUnique from 'utils/misc/arr-unique';
import sanitizeTag from 'utils/tags/sanitizeTag';

interface FormTagListProps {
  form: FormType;
  field: string;
  allowAdd?: boolean;
  onChanged?: (newTagsList?: string[]) => void;
}

export default ({ form, field, onChanged, allowAdd }: FormTagListProps) => {
  let list = form.values[field];
  if (list) {
    list = list.map((tag: string) => sanitizeTag(tag, false));
  }

  return (
    <TagList>
      {list?.map &&
        list.map((tag: string, idx: number) => (
          <Tag
            key={'tag-' + idx + '-' + tag}
            value={tag}
            changeOnBlur={true}
            onChange={(v) => {
              let newTags: string[] = list.concat([]);
              newTags.splice(idx, 1);

              const trimmed = v.toString().trim();
              if (trimmed === '') {
                form.setField(field, newTags);
                onChanged && onChanged(newTags);
                return;
              }

              const valueTags = trimmed
                .split(' ')
                .map((t) => sanitizeTag(t, false))
                .filter((t) => t !== '');

              if (!valueTags.length) {
                return;
              }

              newTags = arrUnique(newTags.concat(valueTags));

              form.setField(field, newTags);
              onChanged && onChanged(newTags);
            }}
          />
        ))}

      {allowAdd && (
        <Tag
          changeOnBlur={true}
          isAdd={true}
          onChange={(v) => {
            const trimmed = v.toString().trim();
            if (trimmed === '') {
              return;
            }

            const valueTags = trimmed
              .split(' ')
              .map((t) => sanitizeTag(t, false))
              .filter((t) => t !== '');

            if (!valueTags.length) {
              return;
            }

            const newTags = arrUnique(
              !list ? valueTags : list.concat(valueTags)
            );

            form.setField(field, newTags);
            onChanged && onChanged(newTags);
          }}
        />
      )}
    </TagList>
  );
};
