import classNames from 'classnames';

interface ContentWrapperProps {
  children: React.ReactNode;
  small?: boolean;
  big?: boolean;
  wide?: boolean;
  ultra?: boolean;
}

export default function ContentWrapper({
  children,
  small,
  big,
  wide,
  ultra
}: ContentWrapperProps) {
  const contentWrapperClasses = classNames('l-content-wrapper', {
    'l-content-wrapper--small': small,
    'l-content-wrapper--big': big,
    'l-content-wrapper--wide': wide,
    'l-content-wrapper--ultra': ultra
  });

  return <div className={contentWrapperClasses}>{children}</div>;
}
