import { createBrowserHistory } from 'history';

import { queryStringListener, transformQueryString } from './query-string';
import scrollToTop from './scroll-to-anchor-on-push';

export const history = createBrowserHistory();

// enable transforming of query strings
queryStringListener(history);
// transform query strin in initial route
transformQueryString(history.location);

// make document to scroll to top (or anchor if set) on route push
scrollToTop(history);

export function isPush(historyObject: any): boolean {
  return historyObject && historyObject.action === 'PUSH' ? true : false;
}

export function idFromMatch(match: any, fieldName: string = 'id'): number | null {
  if (!match || !match.params || !match.params[fieldName]) {
    return null;
  }
  return parseInt(match.params[fieldName], 10);
}

export function paramFromMatch(match: any, fieldName: string): string | null {
  if (!match || !match.params || !match.params[fieldName]) {
    return null;
  }
  return match.params[fieldName];
}

export interface HistoryProps {
  history: any;
  match: any;
  location: any;
}

export default history;
