import React from 'react';

interface RatingSliderProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: React.ReactNode;
}

export default React.forwardRef(
  ({ label, onChange, value, name }: RatingSliderProps, ref: any) => {
    if (!value) {
      value = 0;
    } else {
      value = parseInt(value.toString(), 10);
    }

    // TODO label drag error
    return (
      <div className="rating-slider">
        <label className="rating-slider__title">{label}</label>
        <div
          className="rating-slider__meter"
          style={{ width: (value / 8) * 100 + '%' }}
        />
        <input
          className="rating-slider__handle"
          type="range"
          min="0"
          max="8"
          value={value}
          name={name}
          readOnly={!onChange}
          onChange={onChange}
          ref={ref}
        />
      </div>
    );
  }
);
