import classNames from 'classnames';
import CardSlider from 'components/CardSlider';
import { Component } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import SectionHeadline from 'components/SectionHeadline';

interface Entry {
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  logo?: React.ReactNode;
  linkTo?: LinkProps['to'];
}

interface Properties {
  large?: boolean;
  entries: Entry[];
  headline?: React.ReactNode | string;
  spacing?: boolean;
}

interface State {
  slides: Entry[][];
  itemsPerSlide: number;
}

interface Config {
  itemsPerSlide: number;
}

interface ResponsiveConfig {
  [key: string]: Config;
}

const responsiveConfig: ResponsiveConfig = {
  all: {
    itemsPerSlide: 3
  },
  '(min-width: 43.0625em)': {
    itemsPerSlide: 6
  },
  '(min-width: 58.8125em)': {
    itemsPerSlide: 9
  }
};

export default class NetworkCompanySlider extends Component<Properties, State> {
  private mediaQueryLists: Array<{
    mediaQueryList: MediaQueryList;
    listener: (event: MediaQueryListEvent) => void;
  }> = [];

  private initResponsiveConfig(): Config {
    this.mediaQueryLists.forEach(({ mediaQueryList, listener }) =>
      mediaQueryList.removeListener(listener)
    );

    const configBuffer: any[] = [];

    const mergeConfig = (buffer: any[]): any =>
      buffer
        .filter((config) => !!config)
        .reduce(
          (carry: any, config: any) => ({ ...(carry || {}), ...config }),
          {}
        );

    Object.entries(responsiveConfig).forEach(
      ([mediaQueryString, mediaQueryConfig], mediaQueryIndex) => {
        const mediaQueryList = matchMedia(mediaQueryString);

        configBuffer[mediaQueryIndex] = mediaQueryList.matches
          ? mediaQueryConfig
          : null;

        const listener = (event: MediaQueryListEvent) => {
          configBuffer[mediaQueryIndex] = event.matches
            ? mediaQueryConfig
            : null;

          const config = mergeConfig(configBuffer);

          const { itemsPerSlide } = config;

          this.setState({
            itemsPerSlide,
            slides: this.calculateSlides(itemsPerSlide)
          });
        };

        mediaQueryList.addListener(listener);
        this.mediaQueryLists.push({ mediaQueryList, listener });
      }
    );

    return mergeConfig(configBuffer);
  }

  private calculateSlides(entriesPerSlide: number): Entry[][] {
    const { entries } = this.props;

    return Array.apply(null, Array(Math.ceil(entries.length / entriesPerSlide)))
      .map((x, i) => i)
      .map((x, i) =>
        entries.slice(
          i * entriesPerSlide,
          i * entriesPerSlide + entriesPerSlide
        )
      );
  }

  constructor(props: Properties) {
    super(props);

    const { itemsPerSlide } = this.initResponsiveConfig();

    this.state = {
      itemsPerSlide,
      slides: this.calculateSlides(itemsPerSlide)
    };
  }

  public componentDidUpdate(prevProps: Properties): void {
    if (prevProps.entries !== this.props.entries) {
      this.setState({
        slides: this.calculateSlides(this.state.itemsPerSlide)
      });
    }
  }

  public componentWillUnmount(): void {
    this.mediaQueryLists.forEach(({ mediaQueryList, listener }) =>
      mediaQueryList.removeListener(listener)
    );
  }

  public render(): JSX.Element {
    const { large, headline, spacing } = this.props;
    const { slides } = this.state;

    const companySliderClasses = classNames('network-company-slider', {
      'network-company-slider--spacing': spacing
    });

    return (
      <article className={companySliderClasses}>
        <SectionHeadline noPadding={true} title={headline} />
        <CardSlider large={large}>
          {slides &&
            slides.map((slide, slideIndex) => (
              <div
                key={'s-' + slideIndex}
                className="network-company-slider__slide"
              >
                {slide.map((entry, entryIndex) =>
                  entry.linkTo ? (
                    <Link key={'l-' + entryIndex} to={entry.linkTo}>
                      <div className="network-company-slider__entry">
                        {entry.logo}
                        {(entry.title || entry.subtitle) && (
                          <div className="network-company-slider__entry-inner">
                            {entry.title && (
                              <h1 className="network-company-slider__entry-title">
                                {entry.title}
                              </h1>
                            )}
                            {entry.subtitle && (
                              <h2 className="network-company-slider__entry-subtitle">
                                {entry.subtitle}
                              </h2>
                            )}
                          </div>
                        )}
                      </div>
                    </Link>
                  ) : (
                    <div
                      key={'d-' + entryIndex}
                      className="network-company-slider__entry"
                    >
                      {entry.logo}
                      {(entry.title || entry.subtitle) && (
                        <div className="network-company-slider__entry-inner">
                          {entry.title && (
                            <h1 className="network-company-slider__entry-title">
                              {entry.title}
                            </h1>
                          )}
                          {entry.subtitle && (
                            <h2 className="network-company-slider__entry-subtitle">
                              {entry.subtitle}
                            </h2>
                          )}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            ))}
        </CardSlider>
      </article>
    );
  }
}
