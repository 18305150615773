import { inject, observer } from 'mobx-react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import IconCounter from 'components/IconCounter';
import { BookmarksStoreType } from 'models/BookmarksStore';
import { BookmarkableEnumType } from 'models/DataStore';
import { Component } from 'react';

export type BookmarkRemovedEvent = (
  elementType: BookmarkableEnumType,
  elementId: number
) => void;

interface PublicBookmarkProps extends WrappedComponentProps {
  elementType: BookmarkableEnumType;
  elementId: number;
  id?: number;
  count?: number;
  disabled?: boolean;
  onRemoved?: BookmarkRemovedEvent;
}

interface BookmarkProps extends PublicBookmarkProps {
  bookmarksStore: BookmarksStoreType;
}

@inject('bookmarksStore', 'dataStore')
@observer
class Bookmark extends Component<BookmarkProps> {
  async add() {
    const { bookmarksStore, elementType, elementId } = this.props;

    try {
      await bookmarksStore.createBookmark(elementType, elementId);
    } catch (e) {
      // ignore for now
    }
  }

  async remove() {
    const { bookmarksStore, id, elementType, elementId, onRemoved } =
      this.props;

    try {
      await bookmarksStore.removeBookmark(id!, elementType, elementId);
    } catch (e) {
      // ignore for now
      return;
    }

    onRemoved && onRemoved(elementType, elementId);
  }

  click() {
    if (this.props.id) {
      this.remove();
    } else {
      this.add();
    }
  }

  render() {
    const { intl, id, count, disabled } = this.props;

    return (
      <IconCounter
        value={count || 0}
        label={intl.formatMessage({ id: 'Bookmarks' })}
        iconName={!id ? 'flag-empty' : 'flag-full'}
        onClick={!disabled ? () => this.click() : undefined}
      />
    );
  }
}

export default injectIntl((props: PublicBookmarkProps) => {
  // @ts-ignore
  return <Bookmark {...props} />;
});
