import { DisrooptiveApi } from 'api/DisrooptiveApi';
import config from 'config';
import Cookies from 'js-cookie';
import { flow, getEnv, types } from 'mobx-state-tree';
import {
  ROUTE_AUTH_ERROR,
  ROUTE_LAB,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_LOGOUT_ERROR
} from 'utils/constants/routes';
import { history } from 'utils/history';

import { AttachmentModelType } from './AttachmentModel';
import AuthStateModel, { createAuthStateModel } from './AuthStateModel';
import { DataStoreType } from './DataStore';
import {
  isLoadError,
  isSaveError,
  ItemLoadingStateEnum
} from './LoadingStateEnums';
import { ProjectModelType } from './ProjectModel';
import { createUserModel, UserModelType } from './UserModel';

interface ApplicationStoreEnvType {
  client: DisrooptiveApi;
  dataStore: DataStoreType;
}

type AppErrorEnum = 'unauthorized' | 'access_denied';
export type LanguageEnum = 'en' | 'de';

const FlashMessageEnum = types.maybe(
  types.enumeration(['default', 'warning', 'error', 'inlineError'])
);
type FlashMessageEnumType = typeof FlashMessageEnum.Type;

const ApplicationStore = types
  .model('ApplicationStore', {
    // authorization
    authState: AuthStateModel,
    userLoadingState: types.maybe(ItemLoadingStateEnum),
    // flash message
    flashMessage: types.maybe(types.string),
    flashMessageHidden: types.maybe(types.boolean),
    flashMessageType: FlashMessageEnum,
    // layout
    menuVisible: types.maybe(types.boolean),
    // overlays
    showCreateSprintOverlay: types.optional(types.boolean, false),
    // loading states
    isPrefillingPainPointClusters: types.optional(types.boolean, false),
    isPreffilingBenchmarks: types.optional(types.boolean, false),
    // ai unavailable message
    aiUnavailable: types.maybe(types.boolean)
  })
  .actions((self) => {
    const setAiUnavailable = (state: boolean) => {
      self.aiUnavailable = state;
    };
    const setIsPrefillingPainPointClusters = (state: boolean) => {
      self.isPrefillingPainPointClusters = state;
    };
    const setIsPrefillingBenchmarks = (state: boolean) => {
      self.isPreffilingBenchmarks = state;
    };
    const setOrgContext = (organizations: any) => {
      const { dataStore }: ApplicationStoreEnvType = getEnv(self);

      if (Array.isArray(organizations)) {
        dataStore.setOrganizations(organizations);

        if (organizations.length === 1 && organizations[0].id) {
          const onlyOrg = dataStore.organizations.get(
            organizations[0].id.toString()
          );
          if (onlyOrg) {
            dataStore.setOrganization(onlyOrg);
          }
        } else {
          // @TODO: This should be implemented, otherwise you running in issues.
        }
      }
    };

    const getUser = flow(function* (id: number) {
      const { client, dataStore }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.userLoadingState = 'loading';

        const user = createUserModel(yield client.getUser(id));

        if (!user || user.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addUser(user);

        self.userLoadingState = undefined;
        return dataStore.users.get(user.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ApplicationStore | getUser', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.userLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.userLoadingState = 'access_denied';
          return;
        }

        if (handleAppError(error)) {
          self.userLoadingState = undefined;
          return;
        }

        self.userLoadingState = 'load_error';
      }
    });

    const refreshCurrentUser = flow(function* () {
      const { dataStore }: ApplicationStoreEnvType = getEnv(self);

      if (!dataStore.currentUserId) {
        return;
      }

      return yield getUser(dataStore.currentUserId);
    });

    const updateUser = flow(function* (
      userId: number,
      patch: {
        email?: string;
        first_name?: string;
        last_name?: string;
        password?: string;
        password_confirmation?: string;
      },
      currentPassword?: string
    ) {
      const { client, dataStore }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.userLoadingState = 'saving';
        const result: any = yield client.updateUser(
          userId,
          patch,
          currentPassword
        );

        if (!result) {
          throw new Error('No valid response from server.');
        }

        dataStore.setUser(createUserModel(result));

        self.userLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error('ApplicationStore | updateUser', error);
        }

        if (client.isFormError(error)) {
          self.userLoadingState = undefined;
          throw error;
        }

        if (handleAppError(error)) {
          self.userLoadingState = undefined;
          return;
        }

        // other error - something went wrong
        self.userLoadingState = 'save_error';
      }
    });

    const resetUserLoadingState = () => {
      self.userLoadingState = undefined;
    };

    const removeUserAttachment = (id: number) => {
      const { dataStore }: ApplicationStoreEnvType = getEnv(self);
      const { user } = dataStore;

      if (!user) {
        return;
      }

      user.attachments.delete(id);
    };

    const addUserAttachment = (attachment: AttachmentModelType) => {
      const { dataStore }: ApplicationStoreEnvType = getEnv(self);
      const { user } = dataStore;

      if (!user) {
        return;
      }

      user.attachments.put(attachment);
    };

    const login = flow(function* (
      email: string,
      password: string,
      remember: boolean = false
    ) {
      const { client, dataStore }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'login'
        });

        const result: any = yield client.login(email, password, remember);

        if (!result || !result.user) {
          throw new Error('No valid response from server.');
        }

        dataStore.clearStore();
        dataStore.setUser(createUserModel(result.user));
        setOrgContext(result.organizations);

        // history.replace(ROUTE_DASHBOARD);
        history.replace(ROUTE_LAB);

        self.authState.loading = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error('ApplicationStore | login', error);
        }

        if (client.isFormError(error)) {
          self.authState.loading = undefined;
          throw error;
        }

        if (client.isUnauthorized(error)) {
          self.authState.error = 'login_failed';
          self.authState.loading = undefined;
          return;
        }

        // other error - something went wrong
        self.authState.error = 'login_error';
        self.authState.loading = undefined;
      }
    });

    const logout = flow(function* (redirectAfterLogout: boolean = true) {
      const { client, dataStore }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'logout'
        });

        yield client.logout();

        dataStore.clearStore();
        self.authState = createAuthStateModel();

        // tslint:disable-next-line: no-use-before-declare
        // clearFlashMessage();

        if (redirectAfterLogout) {
          history.push(ROUTE_LOGOUT);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error('ApplicationStore | logout', error);
        }

        self.authState = createAuthStateModel({
          error: 'logout_error'
        });

        if (redirectAfterLogout) {
          history.push(ROUTE_LOGOUT_ERROR);
        }
      }
    });

    const checkAuthenticated = flow(function* (
      redirectToLogin: boolean = false,
      redirectToDashboard: boolean = false
    ) {
      const { client, dataStore }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState = createAuthStateModel({
          loading: 'check'
        });

        const result: any = yield client.checkAuthenticated();

        if (!result) {
          // no user authenticated
          dataStore.clearStore();
          self.authState.loading = undefined;

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return;
        }

        dataStore.setUser(createUserModel(result.user));
        setOrgContext(result.organizations);

        if (redirectToDashboard) {
          // history.push(ROUTE_DASHBOARD);
          history.push(ROUTE_LAB);
        }

        self.authState.loading = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | checkAuthenticated',
            error,
            error.response
          );
        }

        if (client.isUnauthorized(error)) {
          // no user authenticated
          dataStore.clearStore();
          self.authState = createAuthStateModel();

          if (redirectToLogin) {
            history.push(ROUTE_LOGIN);
          }
          return;
        }

        // TODO Are there errors (e.g. unconfirmed profile) which need to be handled here?

        self.authState = createAuthStateModel({
          error: 'check_error'
        });

        if (redirectToLogin) {
          history.push(ROUTE_AUTH_ERROR);
        }
      }
    });

    const enterPasswordReset = () => {
      self.authState.resetState = undefined;
      self.authState.resetActive = true;
    };

    const exitPasswordReset = () => {
      self.authState.resetActive = undefined;
    };

    const initPasswordReset = flow(function* (email: string) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.resetState = 'loading';

        yield client.initPasswordReset(email);

        self.authState.resetState = 'token_requested';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | initPasswordReset',
            error,
            error.response
          );
        }

        if (client.isFormError(error)) {
          self.authState.resetState = undefined;
          throw error;
        }

        self.authState.resetState = client.isNotFound(error)
          ? 'user_not_found'
          : 'error';
      }
    });

    const verifyPasswordResetToken = flow(function* (token: string) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.resetState = 'loading';

        const result: any = yield client.verifyPasswordResetToken(token);

        self.authState.resetState = undefined;

        return result?.user_email;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | verifyPasswordResetToken',
            error,
            error.response
          );
        }

        // ignore other errors to allow user to proceed anyway
        self.authState.resetState = client.isNotFound(error)
          ? 'token_invalid'
          : undefined;
      }
    });

    const finishPasswordReset = flow(function* (
      token: string,
      newPassword: string
    ) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.resetState = 'loading';

        yield client.finishPasswordReset(token, newPassword);

        self.authState.resetState = 'finished';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | finishPasswordReset',
            error,
            error.response
          );
        }

        if (client.isFormError(error)) {
          self.authState.resetState = undefined;
          throw error;
        }

        self.authState.resetState = client.isNotFound(error)
          ? 'token_invalid'
          : 'error';
      }
    });

    const verifyInvitation = flow(function* (token: string) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.joinState = 'loading';

        const result: any = yield client.verifyInvitation(token);

        self.authState.joinState = undefined;

        return result?.email;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | verifyInvitation',
            error,
            error.response
          );
        }

        // ignore other errors to allow user to proceed anyway
        self.authState.joinState = client.isNotFound(error)
          ? 'token_invalid'
          : undefined;
      }
    });

    const join = flow(function* (
      token: string,
      newPassword: string,
      firstName?: string,
      lastName?: string
    ) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.joinState = 'loading';

        yield client.join(token, newPassword, firstName, lastName);

        self.authState.joinState = 'finished';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error('ApplicationStore | join', error, error.response);
        }

        if (client.isFormError(error)) {
          self.authState.joinState = undefined;
          throw error;
        }

        self.authState.joinState = client.isNotFound(error)
          ? 'token_invalid'
          : 'error';
      }
    });

    const verifyEmailAddress = flow(function* (token: string) {
      const { client }: ApplicationStoreEnvType = getEnv(self);
      try {
        self.authState.verifyEmailState = 'loading';

        yield client.verifyAddress(token);

        self.authState.verifyEmailState = 'finished';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | verifyEmailAddress',
            error,
            error.response
          );
        }

        self.authState.verifyEmailState = client.isNotFound(error)
          ? 'token_invalid'
          : 'error';
      }
    });

    const handleAppError = (
      error: any,
      handleAccessDenied: boolean = true
    ): AppErrorEnum | null => {
      const { client } = getEnv(self);

      if (client.isUnauthorized(error)) {
        history.push(ROUTE_LOGIN);
        return 'unauthorized';
      }

      if (handleAccessDenied && client.isAccessDenied(error)) {
        // TODO is there some general action we want to perform here, e.g. display a message, navigate back etc.?
        return 'access_denied';
      }

      return null;
    };

    const setFlashMessage = (message?: string, type?: FlashMessageEnumType) => {
      self.flashMessageHidden = undefined;
      self.flashMessage = message || undefined;
      self.flashMessageType = type || undefined;
    };

    const hideFlashMessage = () => {
      self.flashMessageHidden = true;
    };

    const clearFlashMessage = () => {
      setFlashMessage();
    };

    const setMenuVisible = (visible?: boolean) => {
      self.menuVisible = !!visible;
    };

    const toggleMenuVisible = () => {
      self.menuVisible = !self.menuVisible;
    };

    const toggleCreateSprintOverlay = () => {
      self.showCreateSprintOverlay = !self.showCreateSprintOverlay;
    };

    const changeLanguage = flow(function* (lang: LanguageEnum) {
      const { client } = getEnv(self);

      try {
        // TODO create loading state for this?
        yield client.changeLanguage(lang);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console          // tslint:disable-next-line
          // tslint:disable-next-line
          console.error(
            'ApplicationStore | changeLanguage',
            error,
            error.response
          );
        }

        throw error;
      }

      // Cookies.set(config.langCookieName, lang);
      window.location.reload();
    });

    const getCurrentLanguage = (): LanguageEnum => {
      return Cookies.get(config.langCookieName) === 'en' ? 'en' : 'de';
    };

    return {
      setAiUnavailable,
      setIsPrefillingPainPointClusters,
      setIsPrefillingBenchmarks,
      getUser,
      refreshCurrentUser,
      updateUser,
      resetUserLoadingState,
      addUserAttachment,
      removeUserAttachment,
      login,
      logout,
      checkAuthenticated,
      enterPasswordReset,
      exitPasswordReset,
      initPasswordReset,
      verifyPasswordResetToken,
      finishPasswordReset,
      verifyInvitation,
      join,
      verifyEmailAddress,
      handleAppError,
      setFlashMessage,
      hideFlashMessage,
      clearFlashMessage,
      setMenuVisible,
      toggleMenuVisible,
      toggleCreateSprintOverlay,
      changeLanguage,
      getCurrentLanguage
    };
  })
  .views((self) => {
    const { dataStore }: ApplicationStoreEnvType = getEnv(self);
    return {
      get isAuthenticating(): boolean {
        const {
          authState: { loading }
        } = self;
        return loading === 'check' || loading === 'logout' ? true : false;
      },
      get isLoggingIn(): boolean {
        const {
          authState: { loading }
        } = self;
        return loading === 'login' ? true : false;
      },
      get isLoggingOut(): boolean {
        const {
          authState: { loading }
        } = self;
        return loading === 'logout' ? true : false;
      },
      get isAuthenticated(): boolean {
        return !dataStore.user ? false : true;
      },
      get currentUser(): UserModelType | undefined {
        return dataStore.user;
      },
      applyContext(
        organizationId?: number,
        projectId?: number
      ): { organizationId: number; projectId: number } {
        return dataStore.applyContext(organizationId, projectId);
      },
      get currentProject(): ProjectModelType | undefined {
        return dataStore.currentProject;
      },

      get isUserLoading(): boolean {
        return self.userLoadingState === 'loading';
      },
      get isUserSaving(): boolean {
        return self.userLoadingState === 'saving';
      },
      get isUserLoadError(): boolean {
        return isLoadError(self.userLoadingState);
      },
      get isUserSaveError(): boolean {
        return isSaveError(self.userLoadingState);
      }
    };
  });

export type ApplicationStoreType = typeof ApplicationStore.Type;
export default ApplicationStore;
