import DestroyButton from 'components/DestroyButton';
import Icon from 'components/Icon';
import { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import sanitizeTag from 'utils/tags/sanitizeTag';

interface TagProps {
  value?: string;
  isAdd?: boolean;
  changeOnBlur?: boolean;
  onChange?: (value: string) => string | null | undefined | void;
}

export default injectIntl(
  ({
    value,
    isAdd,
    changeOnBlur,
    onChange,
    intl
  }: TagProps & WrappedComponentProps) => {
    if (!onChange || (isAdd && !onChange)) {
      return (
        <li className="tag-list__list-item">
          <span className="tag-list__tag">{sanitizeTag(value)}</span>
        </li>
      );
    }

    const [state, setState] = useState<any>();
    const [input, setInput] = useState<any>();

    if (state === 'edit') {
      return (
        <li className="tag-list__list-item">
          <input
            className="tag-list__tag tag-list__tag--input"
            type="text"
            placeholder={intl.formatMessage({ id: 'Enter tag' })}
            autoFocus={true}
            value={input}
            onChange={(e: any) => setInput(e.target.value)}
            onBlur={() => {
              if (changeOnBlur) {
                setState(undefined);
                onChange(input);
              } else {
                setState(undefined);
              }
            }}
            onFocus={(e: any) => e.target.select()}
            onKeyUp={(e: any) => {
              if (e.keyCode === 27) {
                setState(undefined);
                setInput(undefined);
              }
            }}
            onKeyPress={(e: any) => {
              if (e.charCode === 13) {
                setState(undefined);
                onChange(input);
              }
            }}
          />
        </li>
      );
    }

    if (isAdd) {
      return (
        <li className="tag-list__list-item">
          <button
            type="button"
            className="tag-list__tag tag-list__tag--add"
            onClick={() => {
              setInput('');
              setState('edit');
            }}
          >
            <Icon name="plus" /> {intl.formatMessage({ id: 'Add tag' })}
          </button>
        </li>
      );
    }

    return (
      <li className="tag-list__list-item">
        <button
          type="button"
          className="tag-list__tag"
          onClick={() => {
            if (!input) {
              setInput(value);
            }
            setState('edit');
          }}
        >
          {sanitizeTag(!input ? value : input)}
        </button>

        <DestroyButton
          label={intl.formatMessage({ id: 'Remove' })}
          iconName="cross"
          onClick={() => onChange('')}
        />
      </li>
    );
  }
);
