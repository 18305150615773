import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import CompanyLogo from 'components/CompanyLogo';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import ProjectWrapper from 'components/Layout/ProjectWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import ProjectType from 'components/ProjectType/ProjectType';
import ToggleSwitch from 'components/ToggleSwitch';
import Headline from 'components/headline/headline';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { createServerTagList } from 'models/TagListModel';
import React from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';
import { ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import InnerLinkHelper from './InnerLinkHelper';

interface CreateProjectScreenState {
  addProjectPopupVisible: boolean;
  strategyTypes: any[];
  selectedStrategyType: string;
  error: boolean;
}
interface CreateProjectScreenProps extends HistoryProps, WrappedComponentProps {
  projectsStore: ProjectsStoreType;
  dataStore: DataStoreType;
  organizationsStore: OrganizationsStoreType;
  form: FormType;
}

@inject('dataStore', 'projectsStore', 'organizationsStore')
@observer
class CreateProjectScreen extends React.Component<
  CreateProjectScreenProps,
  CreateProjectScreenState
> {
  state = {
    addProjectPopupVisible: false,
    strategyTypes: [
      { id: '', type: '', description: '', icon: '', sorting: 99999 }
    ],
    selectedStrategyType: '',
    error: false
  };

  async componentDidMount() {
    const strategyTypes = await this.strategyTypes();
    this.props.organizationsStore!.getTargetGroups();
    this.props.organizationsStore!.getServices();
    this.setState({ strategyTypes });
  }

  async addProject() {
    const { form, projectsStore, dataStore, history } = this.props;
    const { selectedStrategyType } = this.state;
    if (!dataStore.currentOrganizationId) {
      return;
    }

    const project = {
      topic: form.values.topic || '',
      private: form.values.private || false,
      gpt_setting_id: selectedStrategyType || '',
      briefing_attributes: {
        question: form.values.question || '',
        description: form.values.description || '',
        ai_target_group_description:
          form.values.ai_target_group_description || '',
        ai_service_description: form.values.ai_service_description || '',
        ai_business_target_description:
          form.values.ai_business_target_description || '',
        ai_characteristic_description:
          form.values.ai_characteristic_description || '',
        tags_attributes: form.values.tags
          ? createServerTagList(form.values.tags)
          : undefined
      }
    };
    try {
      const newProject = await projectsStore.createProject(
        project,
        dataStore.currentOrganizationId
      );

      if (!projectsStore.isItemSaveError) {
        if (newProject && newProject.id) {
          history.replace(
            ROUTE_LAB +
              '/' +
              dataStore.currentOrganizationId +
              '/' +
              newProject.id
          );
        } else {
          history.replace(ROUTE_LAB + '/' + dataStore.currentOrganizationId);
        }
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  async strategyTypes() {
    const { projectsStore } = this.props;
    const response = await projectsStore.getStrategyTypes();

    return response;
  }

  renderPage(content: any) {
    const { dataStore, intl, history } = this.props;
    const { selectedStrategyType } = this.state;

    let header;

    const org = dataStore.currentOrganization;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    return (
      <AppLayoutReduced active="customers" withSettings={true}>
        <PageHeader
          reduced={true}
          titleId="Our projects(titleId)"
          logoHeader={header}
        />
        {content}
        <OverlayWrapper topRight={true}>
          <CloseButton
            label={intl.formatMessage({ id: 'Close' })}
            iconName="cross"
            onClick={() => {
              if (selectedStrategyType !== '') {
                this.setState({ selectedStrategyType: '' });
              } else {
                history.push(ROUTE_LAB + '/' + dataStore.currentOrganizationId);
              }
            }}
          />
        </OverlayWrapper>
      </AppLayoutReduced>
    );
  }

  renderError() {
    return this.renderPage(<ErrorMessage state="save_error" />);
  }

  render() {
    const { projectsStore, dataStore, form, intl } = this.props;
    const { selectedStrategyType, strategyTypes, error } = this.state;

    const org = dataStore.currentOrganization;
    if (!org) {
      return this.renderError();
    }

    const loading = projectsStore.isListLoading && !projectsStore.hasAny;

    const sortedStrategyTypes = strategyTypes.sort(
      (a, b) => a.sorting - b.sorting
    );

    const targetGroups = [...dataStore.orgTargetGroups.values()];
    const services = [...dataStore.orgServices.values()];

    if (selectedStrategyType === '') {
      return this.renderPage(
        <Form
          loading={form.loading}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            if (!!e.currentTarget.selectedStrategyType.value) {
              this.setState({
                selectedStrategyType:
                  e.currentTarget.selectedStrategyType.value,
                error: false
              });
            } else {
              this.setState({ error: true });
            }
          }}
        >
          <ProjectWrapper>
            <Headline tag="h3" size="L" center={true}>
              <FormattedMessage id="Project type choose" />
            </Headline>

            {loading && <Loading />}

            <ColumnWrapper gap="2em">
              {projectsStore.isItemSaveError && (
                <ErrorMessage
                  state={projectsStore.itemLoadingState}
                  onRetry={() => this.addProject()}
                />
              )}
              {error && (
                <div
                  style={{ display: 'block', textAlign: 'center' }}
                  className="input-state__error"
                >
                  {intl.formatMessage({ id: 'Project type choose error' })}
                </div>
              )}

              <ProjectType strategyTypes={sortedStrategyTypes}></ProjectType>
              <PositionWrapper center={true}>
                <MainButton type="submit">
                  <FormattedMessage id="Next" />
                </MainButton>
              </PositionWrapper>
            </ColumnWrapper>
          </ProjectWrapper>
        </Form>
      );
    }

    return this.renderPage(
      <>
        <Form loading={form.loading} onSubmit={() => this.addProject()}>
          <ProjectWrapper>
            <FormTagList form={form} field="tags" allowAdd={true} />

            {loading && <Loading />}

            <ColumnWrapper gap="2em">
              {projectsStore.isItemSaveError && (
                <ErrorMessage
                  state={projectsStore.itemLoadingState}
                  onRetry={() => this.addProject()}
                />
              )}

              <CardWrapper>
                <Input
                  name="topic"
                  label={<FormattedMessage id="Project topic" />}
                  autoFocus={true}
                  maxLength={30}
                  normalFont={true}
                  {...form.bindInput('topic')}
                />
              </CardWrapper>

              <CardWrapper>
                <Textarea
                  lines={6}
                  largeFont={true}
                  label={<FormattedMessage id="briefing question" />}
                  placeholder={intl.formatMessage({
                    id: 'briefing question placeholder'
                  })}
                  name="question"
                  normalFont={true}
                  {...form.bindInput('question')}
                  onKeyDown={(e: any) => {
                    if (e.charCode === 13) {
                      e.preventDefault();
                      this.addProject();
                    }
                  }}
                />
              </CardWrapper>

              <CardWrapper>
                <Textarea
                  name="description"
                  label={<FormattedMessage id="Project short description" />}
                  normalFont={true}
                  {...form.bindInput('description')}
                />
              </CardWrapper>

              <CardWrapper>
                <InnerLinkHelper
                  href={
                    ROUTE_LAB +
                    '/' +
                    dataStore.currentOrganizationId +
                    '/settings/target-groups'
                  }
                  label={intl.formatMessage({ id: 'Manage target groups' })}
                />
                <Select
                  name="ai_target_group_description"
                  label={<FormattedMessage id="Target group" />}
                  normalFont={true}
                  onFocus={() => {
                    this.props.organizationsStore!.getTargetGroups();
                  }}
                  {...form.bindInput('ai_target_group_description')}
                >
                  <option value="">
                    <FormattedMessage
                      id={
                        targetGroups.length === 0
                          ? 'No target groups'
                          : 'Select target group'
                      }
                    />
                  </option>
                  {targetGroups.map((targetGroup) => (
                    <option key={targetGroup.id} value={targetGroup.content!}>
                      {targetGroup.name}
                    </option>
                  ))}
                </Select>
              </CardWrapper>

              <CardWrapper>
                <InnerLinkHelper
                  href={
                    ROUTE_LAB +
                    '/' +
                    dataStore.currentOrganizationId +
                    '/settings/services'
                  }
                  label={intl.formatMessage({ id: 'Manage services' })}
                />
                <Select
                  name="ai_service_description"
                  label={<FormattedMessage id="Service" />}
                  normalFont={true}
                  onFocus={() => {
                    this.props.organizationsStore!.getServices();
                  }}
                  {...form.bindInput('ai_service_description')}
                >
                  <option value="">
                    <FormattedMessage
                      id={
                        services.length === 0 ? 'No services' : 'Select service'
                      }
                    />
                  </option>
                  {services.map((service) => (
                    <option key={service.id} value={service.content!}>
                      {service.name}
                    </option>
                  ))}
                </Select>
              </CardWrapper>

              <CardWrapper>
                <Input
                  name="ai_business_target_description"
                  label={<FormattedMessage id="Business target" />}
                  normalFont={true}
                  {...form.bindInput('ai_business_target_description')}
                />
              </CardWrapper>

              <CardWrapper>
                <Input
                  name="ai_characteristic_description"
                  label={<FormattedMessage id="Characteristic" />}
                  normalFont={true}
                  {...form.bindInput('ai_characteristic_description')}
                />
              </CardWrapper>

              <PositionWrapper center={true}>
                <RowWrapper gap="1em">
                  <ToggleSwitch
                    label={<FormattedMessage id="Project private" />}
                    {...form.bindCheckbox('private')}
                  />
                  <span aria-hidden="true">
                    <FormattedMessage id="Project private" />
                  </span>
                </RowWrapper>
              </PositionWrapper>

              <PositionWrapper center={true}>
                <MainButton type="submit">
                  <FormattedMessage id="Create project" />
                </MainButton>
              </PositionWrapper>
            </ColumnWrapper>
          </ProjectWrapper>
        </Form>

        <CallToAction />
      </>
    );
  }
}

export default injectIntl((props: CreateProjectScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <CreateProjectScreen {...props} form={form} />;
});
