import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import AutoGrid from 'components/Layout/AutoGrid';
import Icon from 'components/Icon';
import SimplePopup from 'components/SimplePopup';
import CounterLabel from 'components/CounterLabel';
import DestroyButton from 'components/DestroyButton';
import SelectCounter from 'components/SelectCounter';
import {
  ActionsStoreType,
  ElementWithContextModelType,
  SelectableElementType
} from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { DataStoreType } from 'models/DataStore';

import BenchmarkSelectPopupItem from 'components/BenchmarkSelectPopup/BenchmarkSelectPopupItem';
import HypothesisSelectPopupItem from 'components/HypothesisSelectPopup/HypothesisSelectPopupItem';
import PainpointSelectPopupItem from 'components/PainpointSelectPopup/PainpointSelectPopupItem';
import PrototypeSelectPopupItem from 'components/PrototypeSelectPopup/PrototypeSelectPopupItem';
import { PainpointModelType } from 'models/PainpointModel';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { HypothesisModelType } from 'models/HypothesisModel';
import { PrototypeModelType } from 'models/PrototypeModel';

interface SelectTopProps {
  actionsStore?: ActionsStoreType;
  dataStore?: DataStoreType;
}

type SelectedTopOverlayItem = {
  item: ElementWithContextModelType;
  checked: boolean;
};
interface ISelectedElements {
  [key: string]: Array<SelectedTopOverlayItem>;
}

function SelectTop({ actionsStore, dataStore }: SelectTopProps) {
  const selectCountTypesWithLabels: Array<[SelectableElementType, string]> = [
    [ElementType.Painpoint, 'Painpoints'],
    [ElementType.Benchmark, 'Benchmarks'],
    [ElementType.Hypothesis, 'Hypotheses'],
    [ElementType.Prototype, 'Prototypes'],
    [ElementType.Learning, 'Learnings']
  ];
  const [showSelectedItems, setShowSelectedItems] = useState(false);

  const [selectedElements, setSelectedElements] = useState<ISelectedElements>({
    Painpoint: [],
    Benchmark: [],
    Hypothesis: [],
    Prototype: [],
    Learning: []
  });

  const mapStoreValues = (values: Iterable<ElementWithContextModelType>) => {
    return Array.from(values).map((item) => ({
      item,
      checked: true
    }));
  };

  useEffect(() => {
    setSelectedElements({
      Painpoint: mapStoreValues(actionsStore!.painpoints.values()),
      Benchmark: mapStoreValues(actionsStore!.benchmarks.values()),
      Hypothesis: mapStoreValues(actionsStore!.hypotheses.values()),
      Prototype: mapStoreValues(actionsStore!.prototypes.values()),
      Learning: []
    });
  }, [actionsStore?.selectedElements]);

  const filteredSelectCountTypesWithLabels = selectCountTypesWithLabels.filter(
    ([elementType]: [SelectableElementType, string]) =>
      actionsStore!.selectedCount(elementType) > 0
  );

  const handleSetSelection = (
    elementType: SelectableElementType,
    element: any
  ) => {
    setSelectedElements({
      ...selectedElements,
      [elementType]: selectedElements[elementType].map((item) => {
        if (item.item.id === element.id) {
          return {
            ...item,
            checked: !item.checked
          };
        }
        return item;
      })
    });
  };

  const updateStoreSelection = () => {
    Object.entries(selectedElements).forEach(([elementType, elements]) => {
      elements.forEach((item: SelectedTopOverlayItem) => {
        if (!item.checked) {
          actionsStore!.selectionSet(
            {
              [elementType]: [{ ...item.item }]
            },
            false
          );
        }
      });
    });
    setShowSelectedItems(false);
    setSelectedElements({
      Painpoint: [],
      Benchmark: [],
      Hypothesis: [],
      Prototype: [],
      Learning: []
    });
  };

  return (
    <>
      <div className="select-top">
        <div
          className="select-top__title"
          onClick={() => setShowSelectedItems(true)}
        >
          {filteredSelectCountTypesWithLabels.map(
            ([elementType, label], index) => (
              <CounterLabel
                key={elementType}
                name={label}
                itemNumber={actionsStore!.selectedCount(elementType)}
                lastItem={
                  index === filteredSelectCountTypesWithLabels.length - 1
                }
              />
            )
          )}
          <Icon name="pen" />
        </div>
        <DestroyButton onClick={() => actionsStore!.selectionClear()} />
        <SelectCounter value={actionsStore!.selectedCount()} />
      </div>
      {showSelectedItems && (
        <SimplePopup
          onAbort={() => setShowSelectedItems(false)}
          onSubmit={() => updateStoreSelection()}
          headlineTextId="selected share items"
          submitTextId="Close"
        >
          {actionsStore!.selectedCount() > 0 && (
            <SelectCounter value={actionsStore!.selectedCount()} />
          )}

          <div className="simple-popup__accent-body simple-popup__scrollable-body">
            <AutoGrid>
              {selectedElements.Painpoint.map((painpointElement) => (
                <PainpointSelectPopupItem
                  onClick={() => {}}
                  onChange={() => {
                    handleSetSelection(
                      ElementType.Painpoint,
                      painpointElement.item
                    );
                  }}
                  painpoint={
                    dataStore!.painpoints?.get(
                      painpointElement.item.id.toString()
                    ) as PainpointModelType
                  }
                  selected={painpointElement.checked}
                  key={painpointElement.item.id}
                />
              ))}
              {selectedElements.Benchmark.map((benchmarkItem) => (
                <BenchmarkSelectPopupItem
                  onClick={() =>
                    handleSetSelection(
                      ElementType.Benchmark,
                      benchmarkItem.item
                    )
                  }
                  benchmark={
                    dataStore!.benchmarks?.get(
                      benchmarkItem.item.id.toString()
                    ) as BenchmarkModelType
                  }
                  selected={benchmarkItem.checked}
                  key={benchmarkItem.item.id}
                />
              ))}
              {selectedElements.Hypothesis.map((hypothesesItem) => (
                <HypothesisSelectPopupItem
                  onClick={() =>
                    handleSetSelection(
                      ElementType.Hypothesis,
                      hypothesesItem.item
                    )
                  }
                  hypothesis={
                    dataStore!.hypotheses?.get(
                      hypothesesItem.item.id.toString()
                    ) as HypothesisModelType
                  }
                  selected={hypothesesItem.checked}
                  key={hypothesesItem.item.id}
                />
              ))}
              {selectedElements.Prototype.map((prototypeItem) => (
                <PrototypeSelectPopupItem
                  onClick={() =>
                    handleSetSelection(
                      ElementType.Prototype,
                      prototypeItem.item
                    )
                  }
                  prototype={
                    dataStore!.prototypes?.get(
                      prototypeItem.item.id.toString()
                    ) as PrototypeModelType
                  }
                  selected={prototypeItem.checked}
                  key={prototypeItem.item.id}
                />
              ))}
            </AutoGrid>
          </div>
        </SimplePopup>
      )}
    </>
  );
}

export default inject('actionsStore', 'dataStore')(observer(SelectTop));
