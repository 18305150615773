import classNames from 'classnames';
import { Link } from 'react-router-dom';

import CompanyLogo from 'components/CompanyLogo';
import DestroyButton from 'components/DestroyButton';
import Loading from 'components/Loading';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface FilePreviewProps {
  logo?: boolean;
  alt: string;
  imgUrl?: string;
  linkTo?: string;
  loading?: boolean;
  onDestroy?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  small?: boolean;
  medium?: boolean;
}

export default injectIntl(
  ({
    alt,
    logo,
    imgUrl,
    linkTo,
    loading,
    onDestroy,
    onClick,
    intl,
    small,
    medium
  }: FilePreviewProps & WrappedComponentProps) => {
    const FilePreviewClasses = classNames('file-preview', {
      'file-preview--small': small,
      'file-preview--medium': medium,
      'file-preview--clickable': !!onClick
    });

    const figure = !imgUrl ? null : logo ? (
      <CompanyLogo imgUrl={imgUrl} alt={alt} linkTo={linkTo} />
    ) : (
      <figure>
        <img className="file-preview__image" src={imgUrl} alt={alt} />
      </figure>
    );

    return (
      <div onClick={onClick} className={FilePreviewClasses}>
        {!loading && onDestroy && (
          <DestroyButton
            label={intl.formatMessage({ id: 'Remove' })}
            iconName="cross"
            onClick={onDestroy}
          />
        )}
        {!linkTo && figure}
        {linkTo && <Link to={linkTo}>{figure}</Link>}
        {loading && <Loading />}
      </div>
    );
  }
);
