import { flow, getEnv, types } from 'mobx-state-tree';

import PageSwitchDescriptor from 'types/PageSwitchDescriptor';
import { describePageSwitch } from 'utils/misc/page-switch';
import sortDraftsFirst from 'utils/sort/drafts-first';
import sortByField from 'utils/sort/field';
import multiSort from 'utils/sort/multi-sort';
import sortByNumber from 'utils/sort/number';
import { sortPublishedAsc, sortPublishedDesc } from 'utils/sort/published-at';
import {
  reduceAuthors,
  ReducedAuthors,
  reduceTags
} from 'utils/store/filter-reducers';
import { createLearningModel, LearningModelType } from './LearningModel';
import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { PublishStateEnumType } from './PublishStateEnum';
import { AdvancedStoreEnv } from './StoreEnv';
import { createServerTagList } from './TagListModel';

const LearningsStore = types
  .model('LearningsStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const getLearnings = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.listLoadingState = 'loading';
        dataStore.setLearningsList(undefined);

        const result: any = yield client.getLearnings(
          organizationId,
          projectId
        );

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.convertAndAddLearning(item);
          list.push(storeItem);
        }

        dataStore.setLearningsList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('LearningsStore | getLearnings', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return undefined;
        }

        self.listLoadingState = 'load_error';
        return undefined;
      }
    });

    const getLearning = flow(function* (
      learningId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setLearningItem(undefined);

        const result: any = yield client.getLearning(
          organizationId,
          projectId,
          learningId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const learning = dataStore.convertAndAddLearning(result, true);
        dataStore.setLearningItem(learning);

        self.itemLoadingState = undefined;
        return learning;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('LearningsStore | getLearning', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createLearning = flow(function* (
      learning: LearningModelType,
      addToList: boolean = false,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';
        dataStore.setLearningItem(undefined);

        const result: any = yield client.createLearning(
          organizationId,
          projectId,
          {
            ...learning,
            tags_attributes: createServerTagList(learning.tags),
            tags: undefined
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newLearning = dataStore.convertAndAddLearning(result, true);
        dataStore.setLearningItem(newLearning);

        if (addToList && newLearning) {
          dataStore.addToLearningsList(newLearning);
        }

        self.itemLoadingState = undefined;
        return newLearning;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('LearningsStore | createLearning', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const updateLearning = flow(function* (
      learningId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        const result: any = yield client.updateLearning(
          organizationId,
          projectId,
          learningId,
          serverPatch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newLearning = dataStore.convertAndAddLearning(result, true);
        dataStore.setLearningItem(newLearning);

        self.itemLoadingState = undefined;
        return newLearning;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('LearningsStore | updateLearning', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const deleteLearning = flow(function* (
      learningId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'deleting';

        yield client.deleteLearning(organizationId, projectId, learningId);

        dataStore.deleteLearning(learningId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('LearningsStore | deleteLearning', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteLearning(learningId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const bulkDeleteLearnings = flow(function* (
      learningIds: number[],
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      self.listLoadingState = 'deleting';
      let deleted = 0;

      for (const learningId of learningIds) {
        try {
          yield deleteLearning(learningId, organizationId, projectId);

          deleted++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error(
              'LearningsStore | bulkDeleteLearnings',
              error,
              error.body
            );
          }

          if (client.isNotFound(error)) {
            dataStore.deleteLearning(learningId);
            deleted++;
          }
        }
      }

      self.listLoadingState = undefined;
      return deleted;
    });

    const initializeItem = (data: any) => {
      // TODO how to handle hypothesis here?
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const learning = createLearningModel(data);

      dataStore.addLearning(learning, true);
      dataStore.setLearningItem(
        dataStore.learnings.get(learning.id.toString())
      );
    };

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    return {
      getLearnings,
      getLearning,
      initializeItem,
      createLearning,
      updateLearning,
      deleteLearning,
      bulkDeleteLearnings,
      setFilter
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return (
          self.itemLoadingState === 'loading' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },

      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListDeleting(): boolean {
        return self.listLoadingState === 'deleting';
      },
      get isListBusy(): boolean {
        return this.isListLoading || this.isListDeleting;
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      get hasAny(): boolean {
        return dataStore.learningsList && dataStore.learningsList.length > 0
          ? true
          : false;
      },
      list(
        state?: PublishStateEnumType,
        authorId?: string,
        tag?: string,
        sort?: string
      ): LearningModelType[] {
        if (!dataStore.learningsList || !dataStore.learningsList.length) {
          return [];
        }

        const authorIdNumber = authorId ? parseInt(authorId, 10) : undefined;

        let noFilter = !authorIdNumber && !state && !tag;
        const list: LearningModelType[] = [];

        for (const item of dataStore.learningsList.values()) {
          if (!item) {
            continue;
          }

          if (authorIdNumber && item.author?.id !== authorIdNumber) {
            continue;
          }

          if (state && item.publish_state !== state) {
            continue;
          }

          if (tag && (!item.tags || !item.tags.includes(tag))) {
            continue;
          }

          list.push(item);
        }

        let sortFunc;
        switch (sort) {
          case 'newest':
            sortFunc = sortPublishedDesc;
            break;

          case 'oldest':
            sortFunc = sortPublishedAsc;
            break;

          default:
            if (sort) {
              noFilter = false;
              sortFunc = sortByNumber(sort, true);
            } else {
              sortFunc = sortPublishedDesc;
            }
        }

        const sorts = [sortFunc, sortByField('headline')];

        if (noFilter) {
          sorts.unshift(sortDraftsFirst);
        }

        list.sort(multiSort(sorts));

        return list;
      },
      get listWithCurrentFilter(): LearningModelType[] {
        return this.list(
          self.filter.get('state'),
          self.filter.get('author'),
          self.filter.get('tag'),
          self.filter.get('sort')
        );
      },
      pageSwitch(current?: LearningModelType): PageSwitchDescriptor {
        return describePageSwitch<LearningModelType>(
          this.listWithCurrentFilter,
          current
        );
      },

      get tags(): string[] {
        return reduceTags(dataStore.learningsList);
      },
      get authors(): ReducedAuthors {
        return reduceAuthors(dataStore.learningsList);
      }
    };
  });

export type LearningsStoreType = typeof LearningsStore.Type;
export default LearningsStore;
