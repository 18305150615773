import { flow, getEnv, types } from 'mobx-state-tree';
import sortByField from 'utils/sort/field';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import { AssistantInvitationModelType } from './AssistantInvitationModel';
import { AssistantSessionBenchmarkModelType } from './AssistantSessionBenchmarkModel';
import {
  AssistantSessionModelType,
  AssistantType
} from './AssistantSessionModel';
import { AssistantSessionSolutionModelType } from './AssistantSessionSolutionModel';
import { ProjectsStoreType } from './ProjectsStore';
import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { AdvancedStoreEnv } from './StoreEnv';
import { createPrototypeScreenModel } from './PrototypeScreenModel';
import uniqBy from 'utils/misc/uniq-by';
import { FluidUserModelType } from './UserModel';
import { OrganizationModelType } from './OrganizationModel';

export type AssistantSessionUpdateType = Partial<AssistantSessionModelType> & {
  project_id?: number;
  benchmark_ids?: number[];
  prototype_headline?: string;
  prototype_description?: string;
};

export type AssistantSessionSolutionUpdateType =
  Partial<AssistantSessionSolutionModelType> & {
    assistant_session_id?: number;
  };

export type AssistantSessionBenchmarkUpdateType =
  Partial<AssistantSessionBenchmarkModelType> & {
    assistant_session_id?: number;
  };

interface StoreEnv extends AdvancedStoreEnv {
  projectsStore: ProjectsStoreType;
}

interface IGetAssistantSessions {
  organizationId?: number;
  authorId?: number;
}

const AssistantStore = types
  .model('AssistantStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    invitationsLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum)
  })
  .actions((self) => {
    const getAssistantSessions = flow(function* ({
      organizationId,
      authorId
    }: IGetAssistantSessions) {
      const { applicationStore, client, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.listLoadingState = 'loading';
        dataStore.setAssistantSessionsList(undefined);

        const result: any = yield client.getAssistantSessions({
          organizationId,
          authorId
        });

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.convertAndAddAssistantSession(item);
          list.push(storeItem);
        }

        dataStore.setAssistantSessionsList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | getAssistantSessions',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
      }
    });

    const getSentAssistantInvitations = flow(function* (projectId?: number) {
      const { applicationStore, client, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.invitationsLoadingState = 'loading';
        dataStore.setAssistantSessionsList(undefined);

        const result: any = yield client.getSentAssistantInvitations(projectId);

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem =
            dataStore.convertAndAddSentAssistantInvitation(item);
          list.push(storeItem);
        }

        dataStore.setSentAssistantInvitationsList(list);
        self.invitationsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | getSentAssistantInvitations',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.invitationsLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.invitationsLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.invitationsLoadingState = undefined;
          return;
        }

        self.invitationsLoadingState = 'load_error';
      }
    });

    const getAssistantSession = flow(function* (assistantSessionId: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.itemLoadingState = 'loading';
        dataStore.setAssistantSessionItem(undefined);

        const result: any = yield client.getAssistantSession(
          assistantSessionId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const assistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        dataStore.setAssistantSessionItem(assistantSession);

        self.itemLoadingState = undefined;
        return assistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | getAssistantSession',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createAssistantSession = flow(function* (
      assistantSession: AssistantSessionUpdateType,
      addToList: boolean = false
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.itemLoadingState = 'saving';
        dataStore.setAssistantSessionItem(undefined);

        const result: any = yield client.createAssistantSession(
          assistantSession
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        dataStore.setAssistantSessionItem(newAssistantSession);

        if (addToList && newAssistantSession) {
          dataStore.addToAssistantSessionsList(newAssistantSession);
        }

        self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | createAssistantSession',
            error,
            error.body
          );
        }

        // if (client.isNotFound(error)) {
        //   self.itemLoadingState = 'not_found';
        //   return undefined;
        // }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const createAssistantSessionInvitation = flow(function* (
      projectId: number,
      invitedUserId: number,
      sessionType: AssistantType,
      invitationText?: string,
      benchmarkIds?: number[],
      painpointId?: number,
      hypothesisId?: number
    ) {
      const { client, applicationStore, projectsStore } =
        getEnv<StoreEnv>(self);

      try {
        const result: any = yield client.createAssistantSessionInvitation(
          projectId,
          invitedUserId,
          sessionType,
          invitationText,
          sessionType === 'benchmarks_sprint' ? benchmarkIds : undefined,
          sessionType === 'hypothesis_sprint' ? painpointId : undefined,
          sessionType === 'prototype_sprint' ? hypothesisId : undefined
        );

        if (!result) {
          throw new Error('No response from server');
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | createAssistantSessionInvitation',
            error,
            error.body
          );
        }

        // if (client.isNotFound(error)) {
        //   self.itemLoadingState = 'not_found';
        //   return undefined;
        // }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }

      try {
        yield projectsStore.getAssistantStats();
      } catch (error) {
        // can be ignored
      }
    });

    const reinvite = flow(function* (assistantSessionId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.reinviteAssistantSession(assistantSessionId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('AssistantStore | reinvite', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const updateAssistantSession = flow(function* (
      assistantSessionId: number,
      patch: AssistantSessionUpdateType
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.itemLoadingState = 'saving';

        const result: any = yield client.updateAssistantSession(
          assistantSessionId,
          patch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        dataStore.setAssistantSessionItem(newAssistantSession);

        self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | updateAssistantSession',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const updateAssistantSessionProgess = flow(function* (
      assistantSessionId: number,
      progress: number
    ) {
      return yield updateAssistantSession(assistantSessionId, {
        progress
      });
    });

    const deleteAssistantSession = flow(function* (assistantSessionId: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.deleteAssistantSession(assistantSessionId);

        dataStore.deleteAssistantSession(assistantSessionId);
        dataStore.deleteSentAssistantSession(assistantSessionId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | deleteAssistantSession',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteAssistantSession(assistantSessionId);
          dataStore.deleteSentAssistantSession(assistantSessionId);
          return;
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('delete_error');
      }
    });

    const publishAssistantSession = flow(function* (
      assistantSessionId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.itemLoadingState = 'saving';

        const result: any = yield client.publishAssistantSession(
          assistantSessionId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        dataStore.setAssistantSessionItem(newAssistantSession);

        self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | publishAssistantSession',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          throw error;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw error;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = undefined;
        throw error;
      }
    });

    const createAssistantSessionSolution = flow(function* (
      assistantSessionId: number,
      assistantSessionSolution: AssistantSessionSolutionUpdateType
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        // self.itemLoadingState = 'saving';

        const result: any = yield client.createAssistantSessionSolution(
          assistantSessionId,
          assistantSessionSolution
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );

        // self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | createAssistantSessionSolution',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // self.itemLoadingState = 'not_found';
          // return undefined;
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          // self.itemLoadingState = 'access_denied';
          // return undefined;
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'save_error';
        // return undefined;
        throw new Error('save_error');
      }
    });

    const updateAssistantSessionSolution = flow(function* (
      assistantSessionId: number,
      assistantSessionSolutionId: number,
      patch: AssistantSessionSolutionUpdateType
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        // self.itemLoadingState = 'saving';

        const result: any = yield client.updateAssistantSessionSolution(
          assistantSessionId,
          assistantSessionSolutionId,
          patch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        // dataStore.setAssistantSessionItem(newAssistantSession);

        // self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | updateAssistantSessionSolution',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // self.itemLoadingState = 'not_found';
          // return undefined;
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          // self.itemLoadingState = 'access_denied';
          // return undefined;
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'save_error';
        // return undefined;
        throw new Error('save_error');
      }
    });

    const deleteAssistantSessionSolution = flow(function* (
      assistantSessionId: number,
      assistantSessionSolutionId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.deleteAssistantSessionSolution(
          assistantSessionId,
          assistantSessionSolutionId
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | deleteAssistantSessionSolution',
            error,
            error.body
          );
        }

        if (!client.isNotFound(error)) {
          if (client.isAccessDenied(error)) {
            // self.itemLoadingState = 'access_denied';
            // return undefined;
            throw new Error('access_denied');
          }

          if (applicationStore.handleAppError(error)) {
            // self.itemLoadingState = undefined;
            return undefined;
          }

          if (client.isFormError(error)) {
            // self.itemLoadingState = undefined;
            throw error;
          }

          // self.itemLoadingState = 'save_error';
          // return undefined;
          throw new Error('save_error');
        }
      }

      dataStore.assistantSessions
        .get(assistantSessionId.toString())
        ?.deleteSolution(assistantSessionSolutionId);
    });

    const createAssistantSessionBenchmark = flow(function* (
      assistantSessionId: number,
      assistantSessionBenchmark: AssistantSessionBenchmarkUpdateType
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        // self.itemLoadingState = 'saving';

        const result: any = yield client.createAssistantSessionBenchmark(
          assistantSessionId,
          assistantSessionBenchmark
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );

        // self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | createAssistantSessionBenchmark',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // self.itemLoadingState = 'not_found';
          // return undefined;
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          // self.itemLoadingState = 'access_denied';
          // return undefined;
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'save_error';
        // return undefined;
        throw new Error('save_error');
      }
    });

    const updateAssistantSessionBenchmark = flow(function* (
      assistantSessionId: number,
      assistantSessionBenchmarkId: number,
      patch: AssistantSessionBenchmarkUpdateType
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        // self.itemLoadingState = 'saving';

        const result: any = yield client.updateAssistantSessionBenchmark(
          assistantSessionId,
          assistantSessionBenchmarkId,
          patch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newAssistantSession = dataStore.convertAndAddAssistantSession(
          result,
          true
        );
        // dataStore.setAssistantSessionItem(newAssistantSession);

        // self.itemLoadingState = undefined;
        return newAssistantSession;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | updateAssistantSessionBenchmark',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // self.itemLoadingState = 'not_found';
          // return undefined;
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          // self.itemLoadingState = 'access_denied';
          // return undefined;
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'save_error';
        // return undefined;
        throw new Error('save_error');
      }
    });

    const deleteAssistantSessionBenchmark = flow(function* (
      assistantSessionId: number,
      assistantSessionBenchmarkId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.deleteAssistantSessionBenchmark(
          assistantSessionId,
          assistantSessionBenchmarkId
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | deleteAssistantSessionBenchmark',
            error,
            error.body
          );
        }

        if (!client.isNotFound(error)) {
          if (client.isAccessDenied(error)) {
            // self.itemLoadingState = 'access_denied';
            // return undefined;
            throw new Error('access_denied');
          }

          if (applicationStore.handleAppError(error)) {
            // self.itemLoadingState = undefined;
            return undefined;
          }

          if (client.isFormError(error)) {
            // self.itemLoadingState = undefined;
            throw error;
          }

          // self.itemLoadingState = 'save_error';
          // return undefined;
          throw new Error('save_error');
        }
      }

      dataStore.assistantSessions
        .get(assistantSessionId.toString())
        ?.deleteSessionBenchmark(assistantSessionBenchmarkId);
    });

    const getReceivedInvitationsCount = flow(function* () {
      const { client, applicationStore, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.getAssistantReceivedInvitationsCount();

        if (!result) {
          throw new Error('No response from server');
        }

        const count = numberOrUndefined(result.received_invitations_count) || 0;

        dataStore.setReceivedInvitationsCount(count);
        return count;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AssistantStore | getReceivedInvitationsCount',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('load_error');
      }
    });

    return {
      getAssistantSessions,
      getSentAssistantInvitations,
      getAssistantSession,

      createAssistantSession,
      createAssistantSessionInvitation,
      reinvite,
      updateAssistantSession,
      updateAssistantSessionProgess,
      deleteAssistantSession,
      publishAssistantSession,

      createAssistantSessionSolution,
      updateAssistantSessionSolution,
      deleteAssistantSessionSolution,
      createAssistantSessionBenchmark,
      updateAssistantSessionBenchmark,
      deleteAssistantSessionBenchmark,

      getReceivedInvitationsCount
    };
  })
  .views((self) => ({
    get isItemLoading(): boolean {
      return (
        self.itemLoadingState === 'loading' ||
        self.itemLoadingState === 'deleting'
      );
    },
    get isItemSaving(): boolean {
      return self.itemLoadingState === 'saving';
    },
    get isItemNotFound(): boolean {
      return self.itemLoadingState === 'not_found';
    },
    get isItemSaveError(): boolean {
      return (
        self.itemLoadingState === 'save_error' ||
        self.itemLoadingState === 'delete_error'
      );
    },
    get isItemLoadError(): boolean {
      return (
        self.itemLoadingState === 'load_error' ||
        self.itemLoadingState === 'access_denied' ||
        self.itemLoadingState === 'feature_disabled'
      );
    },

    get currentItem(): AssistantSessionModelType | undefined {
      return getEnv<AdvancedStoreEnv>(self).dataStore.assistantSessionItem;
    },
    get currentId(): number | undefined {
      return getEnv<AdvancedStoreEnv>(self).dataStore.assistantSessionItem?.id;
    },
    get currentProgress(): number | undefined {
      return getEnv<AdvancedStoreEnv>(self).dataStore.assistantSessionItem
        ?.progress;
    },
    get currentAssistantType(): AssistantType | undefined {
      const session =
        getEnv<AdvancedStoreEnv>(self).dataStore.assistantSessionItem;
      if (!session) {
        return undefined;
      }
      return session.assistant_type || 'creative_session';
    },

    get currentCreativeSessionsLength(): number {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      return (
        dataStore.assistantSessionsList?.filter(
          (session) => session.assistant_type === 'creative_session'
        ).length || 0
      );
    },

    get isListLoading(): boolean {
      return self.listLoadingState === 'loading';
    },
    get isListDeleting(): boolean {
      return self.listLoadingState === 'deleting';
    },
    get isListBusy(): boolean {
      return this.isListLoading || this.isListDeleting;
    },
    get isListError(): boolean {
      return (
        self.listLoadingState === 'load_error' ||
        self.listLoadingState === 'access_denied' ||
        self.listLoadingState === 'feature_disabled'
      );
    },

    get activeCount(): number {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return 0;
      }

      let count = 0;
      for (const item of dataStore.assistantSessionsList) {
        if (!item.published) {
          count++;
        }
      }

      return count;
    },
    get finishedCount(): number {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return 0;
      }

      let count = 0;
      for (const item of dataStore.assistantSessionsList) {
        if (item.published) {
          count++;
        }
      }

      return count;
    },

    get activeSessions(): AssistantSessionModelType[] {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return [];
      }

      const list: AssistantSessionModelType[] = [];
      for (const item of dataStore.assistantSessionsList) {
        if (item.invited || item.assistant_type !== 'creative_session') {
          continue;
        }

        if (!item.published) {
          list.push(item);
        }
      }

      list.sort(sortByField('lastUpdate', true));

      return list;
    },
    get finishedSessions(): AssistantSessionModelType[] {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return [];
      }

      const list: AssistantSessionModelType[] = [];
      for (const item of dataStore.assistantSessionsList) {
        if (item.invited || item.assistant_type !== 'creative_session') {
          continue;
        }

        if (item.published) {
          list.push(item);
        }
      }

      list.sort(sortByField('lastUpdate', true));

      return list;
    },

    get activeInvitations(): AssistantSessionModelType[] {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return [];
      }

      const list: AssistantSessionModelType[] = [];
      for (const item of dataStore.assistantSessionsList) {
        if (!item.invited) {
          continue;
        }

        if (item.published) {
          continue;
        }

        list.push(item);
      }

      list.sort(sortByField('created_at', true));

      return list;
    },
    get finishedInvitations(): AssistantSessionModelType[] {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.assistantSessionsList?.length) {
        return [];
      }

      const list: AssistantSessionModelType[] = [];
      for (const item of dataStore.assistantSessionsList) {
        if (!item.invited) {
          continue;
        }

        if (!item.published) {
          continue;
        }

        list.push(item);
      }

      list.sort(sortByField('created_at', true));

      return list;
    },

    get isInvitationsLoading(): boolean {
      return self.invitationsLoadingState === 'loading';
    },
    get isInvitationsError(): boolean {
      return (
        self.invitationsLoadingState === 'load_error' ||
        self.invitationsLoadingState === 'access_denied' ||
        self.invitationsLoadingState === 'feature_disabled'
      );
    },

    invitationsCount(sessionType: AssistantType): {
      open: number;
      active: number;
      finished: number;
      sum: number;
    } {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      const counts = { open: 0, active: 0, finished: 0, sum: 0 };

      if (!dataStore.sentAssistantInvitations.size) {
        return counts;
      }

      for (const invite of dataStore.sentAssistantInvitations.values()) {
        if (invite.assistant_type !== sessionType) {
          continue;
        }

        counts.sum++;

        if (invite.finished) {
          counts.finished++;
          continue;
        }

        if (!invite.progress) {
          counts.open++;
          continue;
        }

        counts.active++;
      }

      return counts;
    },
    filteredInvitations(
      sessionType: AssistantType,
      state?: 'active' | 'open' | 'finished'
    ): AssistantInvitationModelType[] {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      if (!dataStore.sentAssistantInvitations.size) {
        return [];
      }

      const list: AssistantInvitationModelType[] = [];

      for (const invite of dataStore.sentAssistantInvitations.values()) {
        if (invite.assistant_type !== sessionType) {
          continue;
        }

        if (state) {
          if (state === 'finished' && !invite.finished) {
            continue;
          }

          if (state === 'active' && (!invite.progress || invite.finished)) {
            continue;
          }

          if (state === 'open' && !!invite.progress) {
            continue;
          }
        }

        list.push(invite);
      }

      list.sort(sortByField('created_at'));
      return list;
    }
  }))
  .views((self) => ({
    get authors(): FluidUserModelType[] {
      let authors: FluidUserModelType[] = [];

      for (const sprint of self.activeInvitations) {
        if (sprint.author) {
          authors.push(sprint.author);
        }
      }

      authors = uniqBy(authors, 'fullName');
      authors.sort(sortByField('fullName'));

      return authors;
    },

    get organizations(): OrganizationModelType[] {
      let organizations: OrganizationModelType[] = [];

      for (const sprint of self.activeInvitations) {
        if (sprint.project?.organization) {
          organizations.push(sprint.project?.organization);
        }
      }

      organizations = uniqBy(organizations, 'name');
      organizations.sort(sortByField('name'));

      return organizations;
    }
  }))
  .actions((self) => {
    const createUnassignedPrototypeScreen = flow(function* (
      assistantSessionItem
    ) {
      const { client, applicationStore, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      const project = assistantSessionItem!.project;
      const orgId = project.organization_id;
      const projId = project.id;

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result = yield client.createPrototypeScreen(
          organizationId,
          projectId,
          undefined,
          assistantSessionItem.id,
          {
            prototype_id: -1,
            assistant_session_id: -1
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newScreen = createPrototypeScreenModel(result);

        assistantSessionItem!.putScreen(newScreen);
        return assistantSessionItem!.prototype_screens?.get(
          newScreen.id.toString()
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('AssistantStore | createScreen', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    return {
      createUnassignedPrototypeScreen
    };
  });

export type AssistantStoreType = typeof AssistantStore.Type;
export default AssistantStore;
