import Icon from 'components/Icon';
import MainButton from 'components/MainButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { IntlShape } from 'react-intl';

interface StickyBenchmarkButtonsProps {
  dataStore?: DataStoreType;
  painpointsStore?: PainpointsStoreType;
  applicationStore?: ApplicationStoreType;
  intl: IntlShape;
}

function StickyBenchmarkButtons({
  dataStore,
  intl,
  painpointsStore,
  applicationStore
}: StickyBenchmarkButtonsProps) {
  if (dataStore!.currentOrganization?.access_level === 'viewer') {
    return null;
  }

  const getMoreBenchmarks = () => {
    getBenchmarks(false);
  };
  const getRadicalBenchmarks = () => {
    getBenchmarks(true);
  };

  const getBenchmarks = async (radical: boolean) => {
    const organizationId = dataStore!.currentOrganizationId!;
    const projectId = dataStore!.currentProjectId!;

    if (!dataStore!.project!.briefing.completed) {
      applicationStore!.setAiUnavailable(true);
      return;
    }

    const response = await painpointsStore!.findMoreBenchmarksByAi(
      organizationId,
      projectId,
      radical
    );

    if (!response.success) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'AI create error' }),
        'error'
      );
    }
  };

  return (
    <div className="sticky-benchmark-bar">
      <MainButton onClick={getMoreBenchmarks}>
        <Icon name={'ai'} hidden={false} />
        <div className="text">
          {intl.formatMessage({ id: 'AI please more' })}
        </div>
      </MainButton>
      <MainButton onClick={getRadicalBenchmarks}>
        <Icon name={'ai'} hidden={false} />
        <div className="text">
          {intl.formatMessage({ id: 'AI more radicale' })}
        </div>
      </MainButton>
    </div>
  );
}

export default inject(
  'dataStore',
  'painpointsStore',
  'applicationStore'
)(observer(StickyBenchmarkButtons));
