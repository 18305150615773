import SubNavCategory from 'components/SubNavCategory';
import SubNavItem from 'components/SubNavItem';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantType } from 'models/AssistantSessionModel';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// tslint:disable: jsx-wrap-multiline

interface AssistantStatsProps {
  active?: string; // string to also accept (and ignore) other values from ProjectLayout
  basePath: string;
  projectsStore?: ProjectsStoreType;
  dataStore?: DataStoreType;
  applicationStore?: ApplicationStoreType;
}

interface AssistantStatsState {
  loadingState?: 'loading' | 'refreshing' | 'error';
  showOverlay?: boolean;
}

interface InviteNavItem {
  type: AssistantType;
  message_id: string;
  path: string;
  icon: string;
}

@inject('projectsStore', 'dataStore', 'applicationStore')
@observer
class AssistantStats extends Component<
  AssistantStatsProps,
  AssistantStatsState
> {
  state: AssistantStatsState = { showOverlay: false };

  componentDidMount() {
    this.loadStats();
  }

  async loadStats() {
    const { projectsStore, dataStore } = this.props;
    const { projectAssistantStats } = dataStore!;

    this.setState({
      loadingState:
        !projectAssistantStats ||
        dataStore!.currentProjectId !== projectAssistantStats.projectId
          ? 'loading'
          : 'refreshing'
    });

    try {
      await projectsStore!.getAssistantStats();

      this.setState({
        loadingState: undefined
      });
    } catch (error: any) {
      this.setState({
        loadingState: 'error'
      });
    }
  }

  render() {
    const { dataStore, active, basePath, applicationStore } = this.props;

    const currentProjectId = dataStore!.currentProjectId;
    const { projectAssistantStats } = dataStore!;

    if (
      !currentProjectId ||
      !projectAssistantStats ||
      currentProjectId !== projectAssistantStats.projectId
    ) {
      // we do not know if we might render something as we are probably not in project context (yet)
      return null;
    }

    const items: JSX.Element[] = [];

    const inviteItems = [
      {
        type: 'painpoint_session',
        message_id: 'painpoint invitations',
        path: 'invitations/painpoints',
        icon: 'clipboard'
      },
      {
        type: 'benchmarks_sprint',
        message_id: 'benchmarks invitations',
        path: 'invitations/benchmarks',
        icon: 'detail'
      },
      {
        type: 'hypothesis_sprint',
        message_id: 'hypothesis invitations',
        path: 'invitations/hypotheses',
        icon: 'bulb'
      },
      {
        type: 'prototype_sprint',
        message_id: 'prototype invitations',
        path: 'invitations/prototypes',
        icon: 'strategy'
      }
    ] as InviteNavItem[];
    inviteItems.map((inviteItem) => {
      if (projectAssistantStats.hasAny(inviteItem.type)) {
        const stat = projectAssistantStats.get(inviteItem.type)!;
        if (stat.hasUnfinished) {
          items.push(
            <SubNavItem
              key={inviteItem.type}
              title={<FormattedMessage id={inviteItem.message_id} />}
              iconName={inviteItem.icon}
              active={active === inviteItem.type}
              to={basePath + inviteItem.path}
              itemNumber={
                <FormattedMessage
                  id="x of y short"
                  values={{ x: stat.published_count, y: stat.total_count }}
                />
              }
              progress={stat.progress}
            />
          );
        }
      }
    });

    return (
      <SubNavCategory title={<FormattedMessage id="Team progress" />}>
        {items}
        <SubNavItem
          title={<FormattedMessage id="Create sprint" />}
          iconName="plus-circle-dotted"
          onClick={() => {
            applicationStore?.toggleCreateSprintOverlay();
          }}
          to={basePath + 'prototypes'}
        />
      </SubNavCategory>
    );
  }
}

export default AssistantStats;
