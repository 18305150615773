import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const BriefingModel = types.model('BriefingModel', {
  id: types.identifierNumber,
  project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
  author: types.maybe(FluidUserModel),
  question: types.maybe(types.string),
  description: types.maybe(types.string),
  tags: types.maybe(TagListModel),
  attachments: AttachmentsMapModel,
  comments: CommentsMapModel,
  bookmark_id: types.maybe(types.number),
  bookmarks_count: types.maybe(types.number),
  completed: types.maybe(types.boolean),
  ai_target_group_description: types.maybe(types.string),
  ai_service_description: types.maybe(types.string),
  ai_business_target_description: types.maybe(types.string),
  ai_characteristic_description: types.maybe(types.string)
});

export const createBriefingModel = (data?: any): BriefingModelType => {
  return BriefingModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    author: createFluidUserModel(data?.author),
    question: data?.question || undefined,
    description: data?.description || undefined,
    tags: createTagList(data?.tags),
    attachments: createAttachmentsMap(data?.attachments),
    comments: createCommentsMap(data?.comments),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    completed: data?.completed || undefined,
    ai_target_group_description: data?.ai_target_group_description || undefined,
    ai_service_description: data?.ai_service_description || undefined,
    ai_business_target_description:
      data?.ai_business_target_description || undefined,
    ai_characteristic_description:
      data?.ai_characteristic_description || undefined
  });
};

export const FluidBriefingModel = types.model('FluidBriefingModel', {
  id: types.maybe(types.number),
  question: types.maybe(types.string),
  description: types.maybe(types.string),
  tags: types.maybe(TagListModel),
  completed: types.maybe(types.boolean),
  ai_target_group_description: types.maybe(types.string),
  ai_service_description: types.maybe(types.string),
  ai_business_target_description: types.maybe(types.string),
  ai_characteristic_description: types.maybe(types.string)
});

export type FluidBriefingModelType = typeof FluidBriefingModel.Type;

export const createFluidBriefingModel = (
  data?: any
): FluidBriefingModelType => {
  return FluidBriefingModel.create({
    id: numberOrUndefined(data?.id),
    question: data?.question || undefined,
    description: data?.description || undefined,
    tags: createTagList(data?.tags),
    completed: data?.completed || undefined,
    ai_target_group_description: data?.ai_target_group_description || undefined,
    ai_service_description: data?.ai_service_description || undefined,
    ai_business_target_description:
      data?.ai_business_target_description || undefined,
    ai_characteristic_description:
      data?.ai_characteristic_description || undefined
  });
};

export type BriefingModelType = typeof BriefingModel.Type;
export default BriefingModel;
