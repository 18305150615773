
interface PageLogoEditProps {
  upload?: React.ReactNode;
  children: React.ReactNode;
}

export default function PageLogoEdit({ upload, children }: PageLogoEditProps) {
  return (
    <div className="page-logoEdit">
      {upload}
      <div className="page-logoEdit__inner">{children}</div>
    </div>
  );
}
