import CardHeader from 'components/CardHeader';
import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import {
  SessionActions,
  SessionFixed,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import shortenString from 'utils/misc/shorten-string';

export const MIN_IN_PROJECT = 1;

interface HypothesisSelectScreenProps {
  assistantStore: AssistantStoreType;
  hypothesesStore: HypothesesStoreType;
  dataStore: DataStoreType;
  form: FormType;
}

interface HypothesisSelectScreenState {
  hypothesesWarningVisible?: boolean;
  selectionWarningVisible?: 'continue' | 'draft';
  selectedHypothesis?: HypothesisModelType;
  loadHyptohesis?: boolean;
}

@inject('assistantStore', 'hypothesesStore', 'dataStore')
@observer
class HypothesisSelectScreen extends React.Component<
  HypothesisSelectScreenProps & HistoryProps & WrappedComponentProps,
  HypothesisSelectScreenState
> {
  state: HypothesisSelectScreenState = {};

  async componentDidMount() {
    await this.loadHypotheses();

    if (this.props.dataStore.hypothesisItem) {
      this.setState({
        selectedHypothesis: this.props.dataStore.hypothesisItem
      });
    } else if (this.props.assistantStore.currentItem?.hypothesis_id) {
      const { currentItem } = this.props.assistantStore;
      const project = currentItem?.project;

      this.setState({ loadHyptohesis: true });

      const knownHypothesis = await this.props.hypothesesStore.getHypothesis(
        this.props.assistantStore.currentItem?.hypothesis_id,
        project.organization_id,
        project.id
      );

      this.setState({
        loadHyptohesis: false,
        selectedHypothesis: knownHypothesis
      });
    }

    scrollSidebar('briefing');
  }

  private async loadHypotheses() {
    const { hypothesesStore } = this.props;
    const { currentItem } = this.props.assistantStore;
    const project = currentItem?.project;

    if (!project?.id || !project.organization_id) {
      this.props.history.replace(ROUTE_ASSISTANT);
      return;
    }

    await hypothesesStore.getHypotheses(project.organization_id, project.id);
  }

  private async continueSession() {
    const { assistantStore } = this.props;

    const {
      currentId,
      currentAssistantType,
      currentItem,
      updateAssistantSession
    } = assistantStore!;
    const { selectedHypothesis } = this.state;

    if (!currentId || !currentAssistantType || !selectedHypothesis) {
      return;
    }

    await this.props.dataStore?.setHypothesisItem(
      this.state.selectedHypothesis
    );

    const progress = ASSISTANTS[currentAssistantType].step1.progress;

    if (
      currentItem?.progress !== progress ||
      currentItem.hypothesis_id !== selectedHypothesis.id
    )
      await updateAssistantSession(currentId, {
        hypothesis_id: selectedHypothesis.id,
        progress
      });

    goToNextStep(currentId, progress, currentAssistantType);
  }

  render() {
    const { hypothesesStore, location, intl } = this.props;
    const { currentItem, currentAssistantType, isItemSaving } =
      this.props.assistantStore;
    const { isListLoading, isListError } = hypothesesStore;

    if (
      !currentItem ||
      !this.props.dataStore.hypothesesList ||
      this.state.loadHyptohesis
    ) {
      return null;
    }

    const sortedList = this.props.hypothesesStore.list(
      'active',
      undefined,
      undefined,
      (hypothesis: HypothesisModelType) => {
        const checked =
          hypothesis.id ===
          (this.props.dataStore.hypothesisItem?.id ||
            currentItem?.hypothesis_id);

        return checked ? -1 : 1;
      }
    );

    const stepInfo = getStepInfo('step1', currentAssistantType);
    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="hypothesis"
        location={location}
        sessionId={currentItem.id}
        bottomSpace={true}
        timerSeconds={240}
      >
        {(isListLoading || isItemSaving) && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant select hypothesis header" />
          </SessionHeadline>
        </SessionTop>

        <SessionMainText noMargin={true}>
          <FormattedMessage id="assistant select hypothesis" />
        </SessionMainText>

        <VideoPopupLink
          videoId="hCgMr0M9gGY"
          // headline={videoHeadline}
          // preview={videoPreview}
        />

        {isListError ? (
          <ErrorMessage onRetry={() => this.loadHypotheses()} />
        ) : (
          <>
            <ColumnWrapper gap="3em">
              {sortedList.length > 0 && (
                <div className="decoration-select-hypo" />
              )}

              {sortedList.map((hypothesis) => {
                const attachment = hypothesis.attachment;
                const checked =
                  this.state.selectedHypothesis?.id === hypothesis.id;

                return (
                  <RainbowCard
                    key={hypothesis.id}
                    src={attachment?.resource_urls?.large}
                    checked={checked}
                    onCheckboxChange={(checked) => {
                      if (checked) {
                        this.setState({ selectedHypothesis: hypothesis });
                      }
                    }}
                  >
                    <CardHeader
                      title={
                        hypothesis.headline ||
                        intl.formatMessage({ id: 'no headline' })
                      }
                    />

                    <Typography size="small">
                      {shortenString(
                        hypothesis.description,
                        LIST_ITEM_TEXT_LENGTH
                      )}
                    </Typography>
                  </RainbowCard>
                );
              })}
            </ColumnWrapper>
          </>
        )}
        <SessionFixed>
          <SessionActions>
            <MainButton
              onClick={() => this.continueSession()}
              disabled={!this.state.selectedHypothesis}
            >
              <FormattedMessage id="Next" />
            </MainButton>
          </SessionActions>
        </SessionFixed>
      </StepContainer>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <HypothesisSelectScreen {...props} form={form} />;
});
