import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import DestroyButton from 'components/DestroyButton';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import Loading from 'components/Loading';
import SelectMenu from 'components/SelectMenu';
import SelectTop from 'components/SelectTop';
import ToggleSwitch from 'components/ToggleSwitch';
import CopyToProjectContainer from 'containers/CopyToProjectContainer';
import {
  ActionsStoreType,
  elementMapFilter,
  elementMapToArray,
  elementMapToMatrix,
  SelectableElementType,
  TypedElementWithContextMap
} from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';
import ActionOverlayButton from 'components/ActionOverlayButton/ActionOverlayButton';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { FlashMessageDisplayComponent } from 'components/FlashMessage/FlashMessage';
import HypothesisButton from 'components/ActionOverlayButton/HypothesisButton';
import BenchmarksButton from 'components/ActionOverlayButton/BenchmarksButton';
import PrototypesButton from 'components/ActionOverlayButton/PrototypesButton';

enum ShareTab {
  download = 'download',
  sharelink = 'sharelink'
}
enum Overlay {
  copy = 'copy',
  share = 'share'
}

interface PublicActionsOverlayContainerProps extends WrappedComponentProps {
  copyInitiallySelectedOrganizationId?: number;
  copyInitiallySelectedProjectId?: number;
  shareInitiallyActiveTab?: ShareTab;
  showCopy?: boolean;
  showDelete?: boolean;
  showShare?: boolean;
  showAiBenchmark?: boolean;
  showAiBriefing?: boolean;
}

interface ActionsOverlayContainerProps
  extends PublicActionsOverlayContainerProps {
  actionsStore: ActionsStoreType;
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  projectsStore: ProjectsStoreType;
  form: FormType;
}

interface ActionsOverlayContainerState {
  activeOverlay?: Overlay;
  activeSharingTab?: ShareTab;
  isLoading: boolean;
  isSharingPasswordProtected: boolean;
  sharingLink?: string;
  wasCopySuccessful: boolean;
}

@inject(
  'actionsStore',
  'applicationStore',
  'dataStore',
  'painpointsStore',
  'projectsStore'
)
@observer
class ActionsOverlayContainer extends React.Component<
  ActionsOverlayContainerProps,
  ActionsOverlayContainerState
> {
  state: ActionsOverlayContainerState = {
    activeOverlay: undefined,
    activeSharingTab: ShareTab.sharelink,
    isLoading: false,
    isSharingPasswordProtected: false,
    sharingLink: undefined,
    wasCopySuccessful: false
  };

  getShareableElements(): Partial<TypedElementWithContextMap> {
    const { currentOrganizationId } = this.props.dataStore;
    return elementMapFilter(
      this.props.actionsStore.selectedElements,
      (element) =>
        element.organizationId === currentOrganizationId &&
        element.publishState !== 'draft'
    );
  }

  overlayOpen(overlay: Overlay) {
    this.setState({
      activeOverlay: overlay,
      // If at least one of the selected elements can be shared via link, show share link tab by default
      activeSharingTab:
        elementMapToArray(this.getShareableElements()).length > 0
          ? ShareTab.sharelink
          : ShareTab.download
    });
  }

  overlayClose() {
    this.props.form.values.sharePassword = '';
    this.props.form.values.shareHasPassword = false;
    this.setState({
      activeOverlay: undefined,
      activeSharingTab: ShareTab.sharelink,
      isLoading: false,
      isSharingPasswordProtected: false,
      sharingLink: undefined
    });
  }

  async copySharingLink() {
    if (!navigator.clipboard || !this.state.sharingLink) {
      return;
    }

    const { applicationStore, intl } = this.props;

    try {
      await navigator.clipboard.writeText(this.state.sharingLink);
      this.setState({ wasCopySuccessful: true });
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'copy link error' }),
        'error'
      );
    }
  }

  async deleteElements(): Promise<number> {
    const { actionsStore, intl } = this.props;

    // Abort if elements from different projects, or no elements at all are selected
    if (
      actionsStore.selectedCount() < 1 ||
      actionsStore.selectedFromProjectIds.length > 1
    ) {
      return 0;
    }

    const typesForMessageId: { [k in SelectableElementType]: string } = {
      Benchmark: 'benchmarks',
      Hypothesis: 'hypotheses',
      Painpoint: 'painpoints',
      Prototype: 'prototypes',
      Learning: 'learnings'
    };

    const { selectedFromTypes } = actionsStore;
    const typeInMessageId =
      selectedFromTypes.length > 1
        ? 'elements'
        : typesForMessageId[selectedFromTypes[0]];

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: `remove ${typeInMessageId} confirm` },
          { count: String(actionsStore.selectedCount()) }
        )
      )
    ) {
      return 0;
    }

    return await actionsStore.deleteElements(actionsStore.selectedIds);
  }

  async createSharingLink() {
    const { actionsStore, form } = this.props;
    const password = form.values.shareHasPassword
      ? form.values.sharePassword
      : '';

    this.setState({ isLoading: true });
    const sharingLink = await actionsStore.createSharingLink(
      password,
      actionsStore.selectedElements
    );

    if (!isDefined(sharingLink)) {
      // Sharing link could not be created
      this.setState({ isLoading: false });
      this.overlayClose();
      return;
    }

    this.setState({
      isLoading: false,
      isSharingPasswordProtected: sharingLink.requires_password,
      sharingLink: sharingLink.url
    });
  }

  async downloadElements() {
    const { actionsStore } = this.props;

    this.setState({ isLoading: true });
    await actionsStore.downloadPdf(actionsStore.selectedIds);
    this.setState({ isLoading: false });
    this.overlayClose();
  }

  render() {
    const {
      actionsStore,
      copyInitiallySelectedOrganizationId,
      copyInitiallySelectedProjectId,
      dataStore,
      form,
      intl,
      applicationStore,
      showAiBenchmark
    } = this.props;

    const { activeOverlay } = this.state;

    const showCreateHypotheses =
      actionsStore.selectedIds.Painpoint.length > 0 &&
      actionsStore.selectedIds.Benchmark.length > 0 &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const showCreateBenchmarks =
      showAiBenchmark &&
      (actionsStore.selectedIds.Painpoint.length > 0 ||
        actionsStore.selectedIds.Benchmark.length > 0) &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const showCreatePrototypes =
      actionsStore.selectedIds.Hypothesis.length > 0 &&
      dataStore.currentOrganization?.access_level !== 'viewer';

    const trueCount = [
      showCreateHypotheses,
      showCreateBenchmarks,
      showCreatePrototypes
    ].filter(Boolean).length;

    const fourColumns = trueCount === 2;
    const fiveColumns = trueCount === 3;

    const shareCountsForMessage = Object.entries(this.getShareableElements())
      .map(([elementType, elements]) => [
        elementType,
        isDefined(elements) ? elements.length : 0
      ])
      .reduce(
        (map, [elementType, count]) =>
          Object.assign(map, {
            [elementType]: count,
            total: map.total + Number(count)
          }),
        { Benchmark: 0, Hypothesis: 0, Prototype: 0, Learning: 0, total: 0 }
      );

    return (
      <>
        {activeOverlay === Overlay.copy && (
          <CopyToProjectContainer
            elements={elementMapToMatrix(actionsStore.selectedIds)}
            forceNoCurrent={true}
            initiallySelectedOrganizationId={
              copyInitiallySelectedOrganizationId
            }
            initiallySelectedProjectId={copyInitiallySelectedProjectId}
            onAbort={() => this.overlayClose()}
            onFinish={() => {
              this.overlayClose();
              actionsStore.selectionClear();
            }}
          />
        )}

        {activeOverlay === Overlay.share && (
          <OverlayWrapper disabled={true} onExit={() => this.overlayClose()}>
            <article className="share-popup">
              <header className="share-popup__header">
                <h1 className="share-popup__headline">
                  <FormattedMessage id="Share selected content" />
                </h1>
                {!isDefined(this.state.sharingLink) &&
                  !this.state.isLoading && (
                    <ul className="share-popup__tabs">
                      {[
                        { tab: ShareTab.sharelink, label: 'URL' },
                        { tab: ShareTab.download, label: 'PDF' }
                      ].map(({ tab, label }) => (
                        <li key={tab}>
                          <button
                            className={`share-popup__tab-link${
                              this.state.activeSharingTab === tab
                                ? ' share-popup__tab-link--active'
                                : ''
                            }`}
                            onClick={() =>
                              this.setState({ activeSharingTab: tab })
                            }
                          >
                            <FormattedMessage id={label} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
              </header>

              <div
                className="share-popup__body"
                style={{ '--min-height': '19em' } as React.CSSProperties}
              >
                {this.state.activeSharingTab === ShareTab.sharelink &&
                  (elementMapToArray(this.getShareableElements()).length < 1 ? (
                    // No shareable elements selected
                    <ColumnWrapper>
                      <div className="share-popup__info">
                        <FormattedMessage id="Share link no selected elements shareable" />
                      </div>
                    </ColumnWrapper>
                  ) : this.state.isLoading ? (
                    <div className="l-row-wrapper l-row-wrapper--center">
                      <Loading inline={true} />
                    </div>
                  ) : (
                    // Share-link-popup content
                    <>
                      {(!isDefined(this.state.sharingLink) ||
                        this.state.isSharingPasswordProtected) && (
                        <>
                          <div className="l-row-wrapper l-row-wrapper--spaceBetween l-row-wrapper--separator">
                            <div className="share-popup__description">
                              <FormattedMessage id="Share password protection" />
                              :
                            </div>
                            {isDefined(this.state.sharingLink) || (
                              <ToggleSwitch
                                id="share-has-password"
                                disabled={
                                  isDefined(this.state.sharingLink) ||
                                  this.state.isLoading
                                }
                                label={intl.formatMessage({
                                  id: 'Share password protection'
                                })}
                                {...form.bindCheckbox('shareHasPassword')}
                              />
                            )}
                          </div>

                          {form.values.shareHasPassword && (
                            <ColumnWrapper>
                              <Input
                                name="share-password"
                                readOnly={
                                  isDefined(this.state.sharingLink) ||
                                  this.state.isLoading
                                }
                                placeholder={intl.formatMessage({
                                  id: 'Share password placeholder'
                                })}
                                {...form.bindInput('sharePassword')}
                              />
                            </ColumnWrapper>
                          )}
                        </>
                      )}

                      {!isDefined(this.state.sharingLink) ? ( // Before link is created
                        <>
                          <ColumnWrapper>
                            {elementMapToArray(this.getShareableElements())
                              .length < actionsStore.selectedCount() && (
                              <>
                                <div className="share-popup__info">
                                  <FormattedMessage
                                    id="Share create link counts"
                                    values={shareCountsForMessage}
                                  />
                                </div>

                                {actionsStore.selectedCount() -
                                  actionsStore.selectedCount(
                                    ElementType.Painpoint
                                  ) >
                                  elementMapToArray(this.getShareableElements())
                                    .length && (
                                  <div className="share-popup__warning">
                                    <FormattedMessage id="Share link cannot share from network and drafts" />
                                  </div>
                                )}

                                {actionsStore.selectedCount(
                                  ElementType.Painpoint
                                ) > 0 && (
                                  <div className="share-popup__warning">
                                    <FormattedMessage id="Share link cannot share painpoints" />
                                  </div>
                                )}
                              </>
                            )}

                            <div className="share-popup__description text-center">
                              <FormattedMessage id="Share generate private URL" />
                            </div>

                            {elementMapToArray(this.getShareableElements())
                              .length === actionsStore.selectedCount() && (
                              /* Only show icon if no warnings are shown, so the popup does not grow vertically */
                              <div className="share-popup__icon">
                                <img src="/images/url-icon.png" alt="" />
                              </div>
                            )}
                          </ColumnWrapper>

                          <button
                            className="main-button"
                            onClick={() => this.createSharingLink()}
                          >
                            <FormattedMessage id="Create sharing link" />
                          </button>
                        </>
                      ) : (
                        // After link is created
                        <ColumnWrapper>
                          <div className="share-popup__info share-popup__info--space-before">
                            <FormattedMessage id="Share copy URL info" />
                            {/* TODO Add info icon with additional text on hover/tap */}
                          </div>
                          <Form onSubmit={() => this.copySharingLink()}>
                            <Input
                              button={intl.formatMessage({
                                id: this.state.wasCopySuccessful
                                  ? 'copy link success'
                                  : 'copy link'
                              })}
                              name="share-link-url"
                              readOnly={true}
                              showSuccess={this.state.wasCopySuccessful}
                              value={this.state.sharingLink}
                            />
                          </Form>
                        </ColumnWrapper>
                      )}
                    </>
                  ))}

                {this.state.activeSharingTab === ShareTab.download &&
                  (this.state.isLoading ? (
                    <div className="l-row-wrapper l-row-wrapper--center">
                      <Loading inline={true} />
                    </div>
                  ) : (
                    // Download-popup content
                    <>
                      <div className="share-popup__description text-center">
                        <FormattedMessage id="Share export as PDF" />
                      </div>

                      <div className="share-popup__icon">
                        <img src="/images/pdf-icon.png" alt="" />
                      </div>

                      <button
                        className="main-button"
                        onClick={() => this.downloadElements()}
                      >
                        <FormattedMessage id="Download PDF" />
                      </button>
                    </>
                  ))}
              </div>

              <DestroyButton onClick={() => this.overlayClose()} />
            </article>
          </OverlayWrapper>
        )}

        {actionsStore.selectedCount() > 0 && !isDefined(activeOverlay) && (
          <OverlayWrapper top={true}>
            <div className="select-menu--wrapper">
              <FlashMessageDisplayComponent
                message={
                  applicationStore!.flashMessageType === 'inlineError'
                    ? applicationStore.flashMessage
                    : undefined
                }
                type="error"
                hidden={applicationStore!.flashMessageHidden}
                onHide={() => applicationStore!.hideFlashMessage()}
              />
              <SelectMenu fourColumns={fourColumns} fiveColumns={fiveColumns}>
                <SelectTop />
                <div className="select-menu__buttons">
                  {this.props.showCopy &&
                    dataStore.currentOrganization?.access_level !==
                      'viewer' && (
                      <ActionOverlayButton
                        label={intl.formatMessage({ id: 'copy over' })}
                        iconName="arrow-right"
                        onClick={() => this.overlayOpen(Overlay.copy)}
                      />
                    )}
                  <div className="stack-m">
                    {this.props.showDelete && dataStore.isOrgAdmin && (
                      <ActionOverlayButton
                        inline={true}
                        label={intl.formatMessage({ id: 'Remove Now' })}
                        iconName="bin"
                        onClick={() => this.deleteElements()}
                      />
                    )}

                    {this.props.showShare && (
                      <ActionOverlayButton
                        inline={true}
                        label={intl.formatMessage({ id: 'Sharing' })}
                        iconName="clipboard"
                        onClick={() => this.overlayOpen(Overlay.share)}
                      />
                    )}
                  </div>
                  {showCreateBenchmarks && <BenchmarksButton />}
                  {showCreateHypotheses && <HypothesisButton />}
                  {showCreatePrototypes && <PrototypesButton />}
                </div>
              </SelectMenu>
            </div>
          </OverlayWrapper>
        )}
      </>
    );
  }
}

export default injectIntl((props: PublicActionsOverlayContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <ActionsOverlayContainer {...props} form={form} />;
});
