import classNames from 'classnames';
import React from 'react';

interface CardStackProps {
  noPadding?: boolean;
  children: React.ReactNode;
}

export default function CardStack({ children, noPadding }: CardStackProps) {
  const classes = classNames('l-card-stack', {
    'l-card-stack--no-padding': noPadding
  });

  return <div className={classes}>{children}</div>;
}
