import classNames from 'classnames';
import { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import UserAvatar from 'components/Avatar/UserAvatar';
import UserName from 'components/Avatar/UserName';
import EditMenu from 'components/EditMenu';
import MenuButton from 'components/MenuButton';
import RatingSlider from 'components/RatingSlider';
import Typography from 'components/Typography';
import { CommentModelType } from 'models/CommentModel';

// TODO Keep it this way or transform into modular component (see RainbowCard)?
export const ModelCommentCard = injectIntl(
  ({
    comment,
    onEditClick,
    onDeleteClick,
    intl
  }: {
    comment: CommentModelType;
    withRating?: boolean;
    onEditClick?: any;
    onDeleteClick?: any;
  } & WrappedComponentProps) => {
    const [menuVisible, setMenuVisible] = useState(false);

    let menu;
    if (onEditClick || onDeleteClick) {
      menu = (
        <EditMenu
          topRight={true}
          label={intl.formatMessage({ id: 'Edit' })}
          onShow={() => setMenuVisible(true)}
          onHide={() => setMenuVisible(false)}
        >
          {onEditClick && (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              description={intl.formatMessage({
                id: 'comment edit desc'
              })}
              iconName="pen"
              onClick={onEditClick}
            />
          )}
          {onDeleteClick && (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              description={intl.formatMessage({
                id: 'comment remove desc'
              })}
              iconName="bin"
              onClick={onDeleteClick}
            />
          )}
        </EditMenu>
      );
    }

    const articleClasses = classNames('comment-card', {
      'comment-card--menuVisible': menuVisible
    });

    return (
      <article className={articleClasses}>
        <div className="comment-cart__user">
          <UserAvatar user={comment.author} noName={true} />
        </div>

        <div className="comment-card__content">
          <header className="comment-card__header">
            <h3>
              <UserName user={comment.author} />
            </h3>
            {comment.created_at && (
              <time dateTime={comment.created_at}>
                {intl.formatDate(comment.created_at, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </time>
            )}
          </header>

          <div className="comment-card__rating-container">
            {comment.comment_type === 'valuation' && (
              <div className="comment-card__rating">
                <RatingSlider
                  value={comment.target_group_relevance || 0}
                  label={intl.formatMessage({ id: 'Traget group relevance' })}
                />
                <RatingSlider
                  value={comment.revenue_potential || 0}
                  label={intl.formatMessage({ id: 'Revenue potential' })}
                />
                <RatingSlider
                  value={comment.cost_efficiency || 0}
                  label={intl.formatMessage({ id: 'Cost efficiency' })}
                />
                <RatingSlider
                  value={comment.differentiation_degree || 0}
                  label={intl.formatMessage({ id: 'Differentiation degree' })}
                />
              </div>
            )}
            <Typography breaks={true} size="small">
              {comment.text}
            </Typography>
          </div>
          {/* TODO bookmark button */}
        </div>
        {menu}
      </article>
    );
  }
);

export default ModelCommentCard;
