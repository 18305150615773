import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import SimplePopup from 'components/SimplePopup';
import { inject, observer } from 'mobx-react';
import { PrototypeModelType } from 'models/PrototypeModel';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import { useEffect } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PrototypeEditPopupProps extends WrappedComponentProps {
  form?: FormType;
  screen: PrototypeScreenModelType;
  prototype: PrototypeModelType;
  prototypesStore?: PrototypesStoreType;
  onEditDone: () => void;
}

function PrototypeEditPopup({
  intl,
  screen,
  prototype,
  form,
  prototypesStore,
  onEditDone
}: PrototypeEditPopupProps) {
  useEffect(() => {
    form!.reset();
    form!.setField('headline', screen!.headline);
    form!.setField('description', screen!.description);
  }, []);

  const save = async () => {
    if (!prototype || !screen) {
      return;
    }

    form!.setLoading(true);

    try {
      await prototypesStore!.updateScreen({
        screenId: screen.id,
        prototype,
        patch: {
          headline: form!.values.headline || '',
          description: form!.values.description || ''
        }
      });

      onEditDone();
    } catch (e) {
      // TODO what to do here?
    }

    form!.setLoading(false);
  };

  return (
    <SimplePopup
      onSubmit={() => save()}
      onAbort={() => onEditDone()}
      isLoading={form!.loading}
    >
      <Input
        type="text"
        name="headline"
        normalFont={true}
        placeholder={intl.formatMessage({ id: 'Prototype Add Headline' })}
        {...form!.bindInput('headline')}
        disabled={form!.loading}
      />
      <div className="l-screen-item__description l-screen-item__description--auto">
        <Textarea
          name="description"
          normalFont={true}
          placeholder={intl.formatMessage({ id: 'Prototype Add Description' })}
          lines={8}
          {...form!.bindInput('description')}
        />
      </div>
    </SimplePopup>
  );
}

export default injectIntl(
  inject('prototypesStore')(
    observer((props: PrototypeEditPopupProps) => {
      const form = useForm();
      // @ts-ignore
      return <PrototypeEditPopup {...props} form={form} />;
    })
  )
);
