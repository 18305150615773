const SKIP_REPLACE = true;

// https://github.com/ReactTraining/react-router/issues/2144#issuecomment-153899046
export default function scrollToTop(history: any) {
  history.listen((location: any) => {
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
      // Keep default behavior of restoring scroll position when user:
      // - clicked back button
      // - clicked on a link that programmatically calls `history.goBack()`
      // - manually changed the URL in the address bar (here we might want
      // to scroll to top, but we can't differentiate it from the others)
      // - URL was replaced as this happens when opening/closing some modals
      if (history.action === 'POP' || (SKIP_REPLACE && history.action === 'REPLACE')) {
        return;
      }

      // when opening or closing a Theater, we do not want to jump to top
      if (location.state && location.state.noscroll) {
        return;
      }

      // In all other cases, check fragment/scroll to top
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
      }
    });
  });
}
