import { AssistantType } from 'models/AssistantSessionModel';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history } from 'utils/history';
import fromEntries from 'utils/misc/from-entries';

interface StepType {
  progress: number;
  route: string;
  percent?: number; // progress for progress bar on top and on invitation items
  header?: string; // step for headline, format '1/3' (= step 1 of 3)
}

interface StepWithKeyType extends StepType {
  key: string;
}

type AssistantsOrderType = {
  [key in AssistantType]: StepWithKeyType[];
};

export const ASSISTANT_BASE_ROUTES: {
  [key in AssistantType]?: string;
} = {
  benchmarks_sprint: 'benchmarks/',
  prototype_sprint: 'prototypes/'
};

// Hint: Steps need to be in correct order here!
// prettier-ignore
export const ASSISTANTS: {
  [key in AssistantType]: { [key: string]: StepType };
} = {
  creative_session: {
    introduction: { progress: 10, route: '/introduction', percent: 0 },

    step1_1: { progress: 1010, route: '/session1/step1', percent: 20, header: '1/4' },
    step1_2: { progress: 1020, route: '/session1/step2', percent: 40, header: '2/4' },
    step1_3: { progress: 1030, route: '/session1/step3', percent: 60, header: '3/4' },
    step1_4: { progress: 1040, route: '/session1/step4', percent: 80, header: '4/4' },

    step2_1: { progress: 2010, route: '/session2/step1', percent: 50, header: '1/1' },

    step3_1: { progress: 3010, route: '/session3/step1', header: '1/2', percent: 16 },
    step3_2: { progress: 3020, route: '/session3/step2', header: '2/2' },

    step4_1: { progress: 4010, route: '/session4/step1', percent: 33, header: '1/3' },
    step4_2: { progress: 4020, route: '/session4/step2', percent: 66, header: '2/3' },
    step4_3: { progress: 4030, route: '/session4/step3', percent: 100, header: '3/3' }
  },
  benchmarks_sprint: {
    invitation: { progress: 5, route: '/invitation', percent: 0 },

    step1: { progress: 1010, route: '/step1', percent: 33, header: '1/3' },
    step2: { progress: 1020, route: '/step2', percent: 66, header: '2/3' },

    finalize: { progress: 2010, route: '/finalize', percent: 100, header: '3/3' }
  },
  painpoint_session: {
    invitation: { progress: 5, route: '/invitation', percent: 0 },

    step1_1: { progress: 1010, route: '/session1/step1', percent: 20, header: '1/5' },
    step1_2: { progress: 1020, route: '/session1/step2', percent: 40, header: '2/5' },
    step1_3: { progress: 1030, route: '/session1/step3', percent: 60, header: '3/5' },
    step1_4: { progress: 1040, route: '/session1/step4', percent: 80, header: '4/5' },

    finalize: { progress: 1050, route: '/session1/finalize', percent: 100, header: '5/5' }
  },
  hypothesis_sprint: {
    invitation: { progress: 5, route: '/invitation', percent: 0 },

    step1_1: { progress: 1040, route: '/session1/select', percent: 14, header: '1/7' },

    step2_1: { progress: 2010, route: '/session2/step1', percent: 28, header: '2/7' },

    step3_1: { progress: 3010, route: '/session3/step1', percent: 42, header: '3/7' },
    step3_2: { progress: 3020, route: '/session3/step2', percent: 56, header: '4/7' },

    step4_1: { progress: 4010, route: '/session4/step1', percent: 70, header: '5/7' },
    step4_2: { progress: 4020, route: '/session4/step2', percent: 84, header: '6/7' },
    step4_3: { progress: 4030, route: '/session4/step3', percent: 100, header: '7/7' }
  },
  prototype_sprint: {
    invitation: { progress: 5, route: '/invitation', percent: 0 },

    step1: { progress: 1010, route: '/step1', percent: 17, header: '1/6' },
    step2: { progress: 1020, route: '/step2', percent: 34, header: '2/6' },
    step3: { progress: 1030, route: '/step3', percent: 50, header: '3/6' },
    step4: { progress: 1040, route: '/step4', percent: 67, header: '4/6' },
    step5: { progress: 1050, route: '/step5', percent: 84, header: '5/6' },
    finalize: { progress: 2000, route: '/finalize', percent: 100, header: '6/6' },
  }
};

export const ASSISTANTS_ORDER: AssistantsOrderType = fromEntries(
  Object.entries(ASSISTANTS).map(([key, assistant]) => {
    return [
      key,
      Object.entries(assistant).map(([stepKey, step]: [string, any]) => ({
        key: stepKey,
        ...step
      }))
    ];
  })
) as AssistantsOrderType;

export const getNextStepRoute = (
  currentProgress?: number,
  assistantType: AssistantType = 'creative_session'
) => {
  const order = ASSISTANTS_ORDER[assistantType];

  if (!currentProgress) {
    return order[0].route;
  }

  for (let i = 0; i < order.length; i++) {
    const isAtStep = order[i].progress === currentProgress;
    const isInBetweenSteps =
      !isAtStep && // check only necessary if step is not current step
      order[i].progress < currentProgress &&
      order[i + 1] &&
      order[i + 1].progress > currentProgress;

    if (isAtStep || isInBetweenSteps) {
      const next = order[i + 1];
      if (!next) {
        return undefined;
      }

      return next.route;
    }
  }

  return undefined;
};

export const getStepInfo = (
  stepName: string,
  assistantType: AssistantType = 'creative_session'
): StepType | undefined => {
  return (
    ASSISTANTS[assistantType]?.[stepName] ||
    ASSISTANTS.creative_session[stepName] ||
    undefined
  );
};

export const goToNextStep = (
  assistantSessionId: number,
  currentProgress: number | undefined,
  assistantType: AssistantType,
  replace: boolean = false
) => {
  const nextRoute = getNextStepRoute(currentProgress, assistantType);
  if (typeof nextRoute !== 'string') {
    return false;
  }

  const route =
    ROUTE_ASSISTANT +
    '/' +
    (ASSISTANT_BASE_ROUTES[assistantType] || '') +
    assistantSessionId +
    nextRoute;

  replace ? history.replace(route) : history.push(route);

  return true;
};

export const getProgressPercentage = (
  currentProgress: number | undefined,
  assistantType: AssistantType
): number => {
  const order = ASSISTANTS_ORDER[assistantType];

  if (!currentProgress) {
    return order[0].percent || 0;
  }

  for (let i = 0; i < order.length; i++) {
    if (currentProgress === order[i].progress) {
      return order[i].percent || 0;
    }

    if (
      order[i].progress < currentProgress &&
      order[i + 1] &&
      order[i + 1].progress > currentProgress
    ) {
      return order[i].percent || 0;
    }
  }

  return 100;
};
