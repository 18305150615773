import UserAvatar from 'components/Avatar/UserAvatar';
import CardHeader from 'components/CardHeader';
import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import UploadButton from 'components/UploadButton';
import UploadedImage from 'components/UploadedImage';
import config from 'config';
import UploadContainer from 'containers/UploadContainer';
import { UploadContainerChildProps } from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType, LanguageEnum } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { DataStoreType } from 'models/DataStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicEditUserContainerProps extends WrappedComponentProps {
  edit?: boolean;
}

interface EditUserContainerProps extends PublicEditUserContainerProps {
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

type EditMode = 'name' | 'email' | 'email_hint' | 'password';

interface EditUserContainerState {
  edit?: EditMode;
}

@inject('dataStore', 'applicationStore')
@observer
class EditUserContainer extends React.Component<
  EditUserContainerProps & HistoryProps,
  EditUserContainerState
> {
  state: EditUserContainerState = {};

  componentDidMount() {
    this.load();
  }

  async load() {
    await this.props.applicationStore.refreshCurrentUser();
  }

  async save(edit: EditMode) {
    const { applicationStore, dataStore, form, intl } = this.props;

    if (!dataStore.user) {
      return;
    }

    form.resetErrors();

    const patch: any = {};
    let currentPassword: string | undefined;

    switch (edit) {
      case 'name':
        patch.first_name = form.values.first_name || '';
        patch.last_name = form.values.last_name || '';
        break;

      case 'email':
        patch.email = form.values.email || '';
        currentPassword = form.values.current_password || '';
        break;

      case 'password':
        currentPassword = form.values.current_password || '';
        patch.password = form.values.password || '-';

        if (
          !patch.password.length ||
          patch.password.length < config.minPasswordLength
        ) {
          form.setError(
            'password',
            intl.formatMessage(
              { id: 'password too short message' },
              { min: config.minPasswordLength }
            )
          );
          return;
        }

        const confirmation = form.values.password_confirmation || '';
        if (confirmation !== patch.password) {
          form.setError(
            'password_confirmation',
            intl.formatMessage({ id: 'password mismatch message' })
          );
          return;
        }

        break;

      default:
        return;
    }

    try {
      await applicationStore.updateUser(
        dataStore.user.id,
        patch,
        currentPassword
      );

      if (!applicationStore.isUserSaveError) {
        this.finishEdit(edit === 'email');
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { dataStore, applicationStore } = this.props;

    if (!attachment) {
      return;
    }

    if (dataStore.user) {
      applicationStore.addUserAttachment(attachment);
    }
  }

  attachmentRemoved(id: number) {
    this.props.applicationStore.removeUserAttachment(id);
  }

  beginEdit(edit: EditMode) {
    const { applicationStore, form } = this.props;

    const user = applicationStore.currentUser;
    if (!user) {
      return;
    }

    if (applicationStore.isUserSaveError) {
      applicationStore.resetUserLoadingState();
    }

    form.reset();

    form.setField('first_name', user.first_name || '');
    form.setField('last_name', user.last_name || '');
    form.setField('email', user.unverified_email || user.email || '');

    this.setState({
      edit
    });
  }

  finishEdit(displayEmailHint: boolean = false) {
    this.setState({
      edit: displayEmailHint ? 'email_hint' : undefined
    });
  }

  async changeLanguage(lang: LanguageEnum) {
    // TODO move language select to component?
    const { applicationStore, form } = this.props;

    if (lang !== applicationStore.getCurrentLanguage()) {
      form.setLoading(true);

      try {
        await applicationStore.changeLanguage(lang);
      } catch (e) {
        // TODO do something here?

        form.setLoading(false);
      }
    }
  }

  renderPage(content: any) {
    const item = this.props.dataStore.user;
    return (
      <>
        <PageHeader
          titleId="Settings"
          headline={item?.fullName}
          avatar={item && <UserAvatar user={item} />}
          bold={true}
          mail={item?.email}
          center={true}
        />
        {content}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  renderError() {
    return this.renderPage(
      <ErrorMessage
        state={this.props.applicationStore.userLoadingState}
        onRetry={() => this.load()}
      />
    );
  }

  renderEditName() {
    const { applicationStore, form } = this.props;

    const loading = applicationStore.isUserSaving;

    return this.renderPage(
      <>
        {applicationStore.isUserSaveError && (
          <ErrorMessage state={applicationStore.userLoadingState} />
        )}

        <ContentWrapper>
          <Form loading={loading} onSubmit={() => this.save('name')}>
            <CardWrapper>
              <DestroyButton onClick={() => this.finishEdit()} />

              <Input
                gap={true}
                name="first_name"
                label={<FormattedMessage id="First name" />}
                autoFocus={true}
                {...form.bindInput('first_name')}
              />
              <Input
                gap={true}
                name="last_name"
                label={<FormattedMessage id="Last name" />}
                {...form.bindInput('last_name')}
              />

              <PositionWrapper center={true}>
                <MainButton type="submit" disabled={loading}>
                  <FormattedMessage id="Save" />
                </MainButton>
              </PositionWrapper>
            </CardWrapper>
          </Form>
        </ContentWrapper>
      </>
    );
  }

  renderEditEmail() {
    const { applicationStore, form } = this.props;

    const loading = applicationStore.isUserSaving;

    return this.renderPage(
      <>
        {applicationStore.isUserSaveError && (
          <ErrorMessage state={applicationStore.userLoadingState} />
        )}

        <ContentWrapper>
          <Form loading={loading} onSubmit={() => this.save('email')}>
            <CardWrapper>
              <DestroyButton onClick={() => this.finishEdit()} />

              <Input
                gap={true}
                name="email"
                label={<FormattedMessage id="Email address" />}
                autoFocus={true}
                {...form.bindInput('email')}
              />
              <Input
                type="password"
                gap={true}
                name="current_password"
                label={<FormattedMessage id="Current password" />}
                {...form.bindInput('current_password')}
              />

              <PositionWrapper center={true}>
                <MainButton type="submit" disabled={loading}>
                  <FormattedMessage id="Save" />
                </MainButton>
              </PositionWrapper>
            </CardWrapper>
          </Form>
        </ContentWrapper>
      </>
    );
  }

  renderEmailHint() {
    return this.renderPage(
      <ContentWrapper>
        <CardWrapper>
          <DestroyButton onClick={() => this.finishEdit()} />

          <Typography size="small">
            <FormattedMessage id="email verify hint" />
          </Typography>

          <PositionWrapper center={true}>
            <MainButton onClick={() => this.finishEdit()}>
              <FormattedMessage id="OK" />
            </MainButton>
          </PositionWrapper>
        </CardWrapper>
      </ContentWrapper>
    );
  }

  renderEditPassword() {
    const { applicationStore, form } = this.props;

    const loading = applicationStore.isUserSaving;

    return this.renderPage(
      <>
        {applicationStore.isUserSaveError && (
          <ErrorMessage state={applicationStore.userLoadingState} />
        )}

        <ContentWrapper>
          <Form loading={loading} onSubmit={() => this.save('password')}>
            <CardWrapper>
              <DestroyButton onClick={() => this.finishEdit()} />

              <Input
                type="password"
                gap={true}
                autoFocus={true}
                name="current_password"
                label={<FormattedMessage id="Current password" />}
                {...form.bindInput('current_password')}
              />

              <Input
                type="password"
                gap={true}
                name="password"
                label={<FormattedMessage id="New password" />}
                {...form.bindInput('password')}
              />
              <Input
                type="password"
                gap={true}
                name="password_confirmation"
                label={<FormattedMessage id="Confirm password" />}
                {...form.bindInput('password_confirmation')}
              />

              <PositionWrapper center={true}>
                <MainButton type="submit" disabled={loading}>
                  <FormattedMessage id="Save" />
                </MainButton>
              </PositionWrapper>
            </CardWrapper>
          </Form>
        </ContentWrapper>
      </>
    );
  }

  renderOverview() {
    const {
      dataStore,
      intl,
      form: { loading }
    } = this.props;
    const item = dataStore.user;

    let attachment: AttachmentModelType | undefined;
    if (item) {
      attachment = item.attachments.firstOfType('avatar');
    }

    return this.renderPage(
      <ContentWrapper>
        {loading && <Loading />}

        <RainbowEditWrapper settings={true}>
          {attachment && (
            <UploadedFileContainer
              attachment={attachment}
              onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
              key={attachment.id}
            >
              {(props) => <UploadedImage {...props} />}
            </UploadedFileContainer>
          )}
          {!attachment && (
            <UploadContainer
              attachmentType="avatar"
              elementType={ElementType.User}
              elementId={dataStore.user?.id}
              // waitForId={
              //   hasUnsavedChanges || briefingStore.isItemSaving
              // }
              onAttachmentReceived={(a) => this.attachmentReceived(a)}
            >
              {(props: UploadContainerChildProps) => (
                <UploadButton {...props} />
              )}
            </UploadContainer>
          )}

          <RainbowEditWrapper.Body>
            <CardWrapper>
              <CardHeader
                title={<FormattedMessage id="Name" />}
                single={true}
                onEditClick={() => this.beginEdit('name')}
              />

              <Typography size="small">
                {item?.fullName || intl.formatMessage({ id: 'no content yet' })}
              </Typography>
            </CardWrapper>

            <CardWrapper>
              <CardHeader
                title={<FormattedMessage id="Email address" />}
                single={true}
                onEditClick={() => this.beginEdit('email')}
              />

              <Typography size="small">
                {item?.unverified_email ? (
                  <FormattedMessage
                    id="unverified email"
                    values={{ email: item.unverified_email }}
                  />
                ) : (
                  item?.email || intl.formatMessage({ id: 'no content yet' })
                )}
              </Typography>

              {/* TODO unverified_email */}
            </CardWrapper>

            <CardWrapper>
              <CardHeader
                title={<FormattedMessage id="Password" />}
                single={true}
                onEditClick={() => this.beginEdit('password')}
              />

              <Typography size="small">********</Typography>
            </CardWrapper>

            <CardWrapper>
              <ColumnWrapper gap="1em">
                <CardHeader
                  title={<FormattedMessage id="Language" />}
                  single={true}
                />

                <Select
                  name="lang"
                  onChange={(e) =>
                    this.changeLanguage(e.target.value as LanguageEnum)
                  }
                  value={this.props.applicationStore.getCurrentLanguage()}
                >
                  <option value="de">Deutsch</option>
                  <option value="en">English</option>
                </Select>
              </ColumnWrapper>
            </CardWrapper>
          </RainbowEditWrapper.Body>
        </RainbowEditWrapper>
      </ContentWrapper>
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isUserLoading) {
      return this.renderLoading();
    }

    if (applicationStore.isUserLoadError) {
      return this.renderError();
    }

    switch (this.state.edit) {
      case 'name':
        return this.renderEditName();

      case 'email':
        return this.renderEditEmail();

      case 'email_hint':
        return this.renderEmailHint();

      case 'password':
        return this.renderEditPassword();

      default:
    }

    return this.renderOverview();
  }
}

export default injectIntl((props: PublicEditUserContainerProps) => {
  const form = useForm();
  return (
    // @ts-ignore
    <EditUserContainer {...props} form={form} />
  );
});
