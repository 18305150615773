import classNames from 'classnames';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';

interface SectionHeadlineProps {
  title: React.ReactNode;
  value?: number;
  anchor?: string;
  anchorText?: React.ReactNode;
  caption?: React.ReactNode;
  small?: boolean;
  big?: boolean;
  single?: boolean;
  noPadding?: boolean;
  lessPaddingTop?: boolean;
}

export default function SectionHeadline(props: SectionHeadlineProps) {
  const sectionHeadlineClasses = classNames('section-headline', {
    'section-headline--small': props.small,
    'section-headline--big': props.big,
    'section-headline--single': props.single,
    'section-headline--noPadding': props.noPadding,
    'section-headline--lessPaddingTop': props.lessPaddingTop
  });

  return (
    <div className={sectionHeadlineClasses}>
      <h2>
        {props.title}
        {!props.value ? null : (
          <span className="section-headline__counter">({props.value})</span>
        )}
      </h2>
      {!props.anchor ? null : (
        <span className="section-headline__anchor">
          <Link to={props.anchor}>{props.anchorText}</Link>
        </span>
      )}
      {!props.caption ? null : (
        <span className="section-headline__caption">
          <Typography>{props.caption}</Typography>
        </span>
      )}
    </div>
  );
}
