import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LinkProps } from 'react-router-dom';

import Bookmark from 'components/Bookmark';
// import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import CompanyLogo from 'components/CompanyLogo';
import EditMenu from 'components/EditMenu';
// import HashtagsList from 'components/HashtagsList';
import IconCounter from 'components/IconCounter';
import MenuButton from 'components/MenuButton';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
// import Typography from 'components/Typography';
import { BenchmarkModelType } from 'models/BenchmarkModel';

export default injectIntl(
  ({
    benchmark,
    checked,
    onChange,
    mayEdit,
    onEditClick,
    onRemoveClick,
    onBigRemoveClick,
    intl,
    contextUri,
    readOnly,
    fromList,
    simple
  }: {
    benchmark: BenchmarkModelType;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    mayEdit?: boolean;
    onEditClick?: () => void;
    onRemoveClick?: () => void;
    onBigRemoveClick?: () => void;
    contextUri?: string;
    readOnly?: boolean;
    fromList?: boolean;
    simple?: boolean;
  } & WrappedComponentProps) => {
    const attachment = benchmark.attachments.firstOfType('screen');
    const logo = benchmark.attachments.firstOfType('logo');

    const isDraft = benchmark.publish_state === 'draft';

    let menu;
    if (mayEdit && (onEditClick || onRemoveClick)) {
      menu = (
        <EditMenu bottomLeft={true} label={intl.formatMessage({ id: 'Edit' })}>
          {onEditClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              description={intl.formatMessage({
                id: 'benchmark edit desc'
              })}
              iconName="pen"
              onClick={onEditClick}
            />
          ) : null}
          {onRemoveClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              description={intl.formatMessage({
                id: 'benchmark remove desc'
              })}
              iconName="bin"
              onClick={onRemoveClick}
            />
          ) : null}
        </EditMenu>
      );
    }

    const to: LinkProps['to'] = {
      pathname:
        (contextUri ? contextUri + '/' : '') +
        'benchmarks/' +
        benchmark.id +
        (isDraft ? '/edit' : '')
    };
    if (fromList) {
      to.state = {
        switch: true
      };
    }

    return (
      <RainbowCardCompact
        to={to}
        src={attachment?.resource_urls?.large}
        alt=""
        onRemoveClick={onBigRemoveClick}
        sideLabel={isDraft ? intl.formatMessage({ id: 'Draft' }) : undefined}
        logo={
          logo &&
          logo.resource_urls && (
            <CompanyLogo
              imgUrl={logo.resource_urls.small}
              alt={intl.formatMessage({ id: 'Logo' })}
            />
          )
        }
        title={benchmark.headline}
      >
        {!simple && (
          <>
            <CardMenu user={simple ? undefined : benchmark.author}>
              <Bookmark
                elementType="Benchmark"
                elementId={benchmark.id}
                count={benchmark.bookmarks_count}
                id={benchmark.bookmark_id}
                disabled={readOnly}
              />
              <IconCounter
                value={benchmark.comments_count || 0}
                label={intl.formatMessage({ id: 'Comments' })}
                iconName="comment"
              />

              {menu}
            </CardMenu>
          </>
        )}
      </RainbowCardCompact>
    );
  }
);
