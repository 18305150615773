import NakedList from 'components/NakedList/NakedList';
import ProjectItem from 'components/ProjectItem';
import SimplePopup from 'components/SimplePopup';
import ProjectsListContainer from 'containers/ProjectsListContainer';
import shortenString from 'utils/misc/shorten-string';

interface CreateSessionPopupProps {
  selectedProjectId?: number;
  loading?: boolean;
  onSelect: (id: number) => void;
  onConfirm: () => void;
  onAbort: () => void;
}

export const CreateSessionPopup = ({
  selectedProjectId,
  loading,
  onSelect,
  onConfirm,
  onAbort
}: CreateSessionPopupProps) => (
  <SimplePopup
    reduced={true}
    onSubmit={onConfirm}
    onAbort={onAbort}
    isSubmitDisabled={!selectedProjectId}
    isLoading={!!loading}
    headlineTextId="Select project"
    submitTextId="Start session"
  >
    <div className="simple-popup__accent-body simple-popup__scrollable-body">
      <ProjectsListContainer
        itemContainer={NakedList}
        renderProject={(project) => (
          <li key={project.id}>
            <ProjectItem
              selected={project.id === selectedProjectId}
              onClick={() => onSelect(project.id)}
              title={project.topic || ''}
              subtitle={shortenString(project.briefing?.question || '', 50)}
            />
          </li>
        )}
      />
    </div>
  </SimplePopup>
);

export default CreateSessionPopup;
