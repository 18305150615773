import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import LabeledIcon from 'components/LabeledIcon';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import SectionHeadline from 'components/SectionHeadline';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import GapSizes from 'utils/constants/gap-sizes';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import AssistantInvitationListItem from './AssistantInvitationListItem';
import AssistantListScreenItem from './AssistantListScreenItem';
import CreateSessionPopup from './CreateSessionPopup';

import SimpleCardSlider from 'components/CardSlider';
import TagList from 'components/TagList';
import TagSelectInput from 'components/Inputs/TagSelectInput';
import useForm, { FormType } from 'utils/hooks/useForm';
import ROLES from 'utils/constants/roles';

// tslint:disable: jsx-wrap-multiline

interface AssistantListScreenProps extends WrappedComponentProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  dataStore: DataStoreType;
  form: FormType;
}

interface AssistantListScreenState {
  isCreateSessionPopupOpen: boolean;
  selectedProjectId?: number;
  newSessionState?: 'loading';
  deleteSessionState?: 'loading';
  authors?: Array<{ id: number | undefined; name: string | undefined }>;
}

const sliderConfig = {
  all: {
    itemsPerChunk: 1
  },
  '(min-width: 18.75em)': {
    itemsPerChunk: 1
  },
  '(min-width: 37.5em)': {
    itemsPerChunk: 3
  },
  '(min-width: 45.9375em)': {
    itemsPerChunk: 4
  }
};

@inject('applicationStore', 'assistantStore', 'dataStore')
@observer
class AssistantListScreen extends React.Component<
  AssistantListScreenProps & HistoryProps,
  AssistantListScreenState
> {
  state: AssistantListScreenState = {
    isCreateSessionPopupOpen: false
  };

  async componentDidMount() {
    if (this.props.dataStore.currentUser?.role === ROLES.ADMIN) {
      const defaultOrganization = this.props.dataStore.sortedOwnOrgs.find(
        (org) => {
          const findOrgName =
            process.env.NODE_ENV === 'development'
              ? '9elements'
              : 'Disrooptive';
          return findOrgName === org.name;
        }
      );
      if (defaultOrganization) {
        this.props.form.setField('organization', defaultOrganization.id);
      }
    } else {
      await this.loadSessions();
      this.updateAuthors();
    }

    this.loadInvitationsCount();
  }

  async componentDidUpdate(prevProps: AssistantListScreenProps) {
    if (
      JSON.stringify(this.props.form.values) !==
      JSON.stringify(prevProps.form.values)
    ) {
      await this.loadSessions();

      if (
        this.props.form.values.organization !==
        prevProps.form.values.organization
      ) {
        this.props.form.setField('author', '');
        this.updateAuthors();
      }
    }
  }

  updateAuthors = () => {
    if (!this.props.assistantStore?.listLoadingState) {
      const authors = this.props.assistantStore?.authors?.map((author) => {
        return { id: author.id, name: author.fullName };
      });
      this.setState({ authors });
    }
  };

  private async loadSessions() {
    const { organization, author } = this.props.form.values;

    await this.props.assistantStore.getAssistantSessions({
      organizationId: organization,
      authorId: author
    });
  }

  private async loadInvitationsCount() {
    try {
      await this.props.assistantStore.getReceivedInvitationsCount();
    } catch (error: any) {
      // ignore
    }
  }

  private beginNewSession() {
    this.setState({
      isCreateSessionPopupOpen: true,
      selectedProjectId: undefined
    });
  }

  private abortNewSession() {
    this.setState({
      isCreateSessionPopupOpen: false
    });
  }

  private async createNewSession() {
    const { applicationStore, assistantStore } = this.props;
    const { selectedProjectId } = this.state;

    if (!selectedProjectId) {
      return;
    }

    this.setState({
      newSessionState: 'loading'
    });

    let session;
    try {
      session = await assistantStore.createAssistantSession({
        project_id: selectedProjectId
      });
    } catch (error: any) {
      // form error!?
    }

    if (session?.id) {
      this.props.history.push(ROUTE_ASSISTANT + '/' + session.id);
      return;
    }

    applicationStore.setFlashMessage(
      getIntl().formatMessage({ id: 'create session error' }),
      'error'
    );

    this.setState({
      newSessionState: undefined
    });
  }

  private continueSession(id: number, step: number) {
    // const route = getNextStepRoute(step) || '';

    this.props.history.push(ROUTE_ASSISTANT + '/' + id); // + route);
  }

  private async deleteSession(id: number) {
    if (
      !window.confirm(
        getIntl().formatMessage(
          { id: 'assistant remove confirm' },
          { count: 1 }
        )
      )
    ) {
      return;
    }

    const { assistantStore, applicationStore } = this.props;

    this.setState({
      deleteSessionState: 'loading'
    });

    try {
      await assistantStore.deleteAssistantSession(id);

      applicationStore.setFlashMessage(
        getIntl().formatMessage({ id: 'remove session success' })
      );
    } catch (error: any) {
      applicationStore.setFlashMessage(
        getIntl().formatMessage({ id: 'remove session error' }),
        'error'
      );
    }

    this.setState({
      deleteSessionState: undefined
    });
  }

  renderNav(isFinishedActive?: boolean) {
    const { assistantStore } = this.props;

    return (
      <TabList small={true}>
        <TabListButton
          title={
            <FormattedMessage
              id="active sessions tab"
              values={{
                count: assistantStore.activeCount
              }}
            />
          }
          to="#active"
          active={!isFinishedActive}
        />

        <TabListButton
          title={
            <FormattedMessage
              id="finshed sessions tab"
              values={{
                count: assistantStore.finishedCount
              }}
            />
          }
          to="#finished"
          active={isFinishedActive}
        />
      </TabList>
    );
  }

  renderPage(content?: any) {
    return (
      <AppLayoutReduced active="assistant">
        <PageHeader
          titleId="Digital assistant(titleId)"
          center={false}
          wide={true}
          logoHeader={
            // TODO Add logo
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              big={true}
              title={<FormattedMessage id="assistant header" />}
            />
          }
        />

        {content}

        <CallToAction />
      </AppLayoutReduced>
    );
  }

  maybeRenderPopup() {
    if (!this.state.isCreateSessionPopupOpen) {
      return null;
    }

    return (
      <CreateSessionPopup
        selectedProjectId={this.state.selectedProjectId}
        loading={this.state.newSessionState === 'loading'}
        onAbort={() => this.abortNewSession()}
        onConfirm={() => this.createNewSession()}
        onSelect={(id) =>
          this.setState({
            selectedProjectId: id
          })
        }
      />
    );
  }

  renderList() {
    const { assistantStore, intl, form } = this.props;

    const isFinishedActive = this.props.location?.hash === '#finished';

    const sessions = isFinishedActive
      ? assistantStore.finishedSessions
      : assistantStore.activeSessions;
    const listEmpty = sessions.length < 1;

    const invitations = !isFinishedActive
      ? assistantStore.activeInvitations
      : assistantStore.finishedInvitations;

    return this.renderPage(
      <>
        {this.state.deleteSessionState === 'loading' && <Loading />}

        <ContentWrapper wide={true}>
          {this.props.dataStore.currentUser?.role === ROLES.ADMIN && (
            <TagList>
              <TagSelectInput
                name="organization"
                value={form.values.organization}
                onChange={(e) => form.setField('organization', e.target.value)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All companies' })}
                </option>
                <option value="" disabled={true} />
                {this.props.dataStore.sortedOwnOrgs.map((organization) => (
                  <option value={organization.id} key={organization.id}>
                    {organization.name}
                  </option>
                ))}
              </TagSelectInput>

              <TagSelectInput
                name="author"
                value={form.values.author}
                onChange={(e) => form.setField('author', e.target.value)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All persons' })}
                </option>
                <option value="" disabled={true} />
                {this.state.authors?.map((author) => (
                  <option value={author.id} key={author.id}>
                    {author.name}
                  </option>
                ))}
              </TagSelectInput>
            </TagList>
          )}

          {this.renderNav(isFinishedActive)}

          <ColumnWrapper gap="2.5rem">
            {invitations.length > 0 && (
              <SimpleCardSlider
                large={true}
                title={
                  <FormattedMessage
                    id="your sprint invites"
                    values={{ count: invitations.length || 0 }}
                  />
                }
                caption={
                  <FormattedMessage
                    id="your sprint invites caption"
                    values={{ count: invitations.length || 0 }}
                  />
                }
                responsiveConfig={sliderConfig}
              >
                {invitations.map((invitation) => (
                  <AssistantInvitationListItem
                    key={invitation.id}
                    assistantSession={invitation}
                  />
                ))}
              </SimpleCardSlider>
            )}

            <div>
              <SectionHeadline
                noPadding={true}
                title={
                  <FormattedMessage
                    id="your personal creative sessions"
                    values={{
                      count: assistantStore?.currentCreativeSessionsLength
                    }}
                  />
                }
              />
              <CardWrapper subtle={true}>
                <Columns gap={GapSizes.L} columnTemplate="auto 1fr auto">
                  <Column>
                    <LabeledIcon
                      icon="assistant"
                      label={<FormattedMessage id="Assistant" />}
                      opacity={0.5}
                    />
                  </Column>

                  <Column>
                    <FormattedMessage id="assistant description" />
                  </Column>

                  <Column>
                    <MainButton onClick={() => this.beginNewSession()}>
                      <FormattedMessage id="New creative session" />
                    </MainButton>
                  </Column>
                </Columns>
              </CardWrapper>
            </div>

            {!isFinishedActive && listEmpty ? (
              <AssistantListScreenItem
                isPlaceholer={true}
                onButtonClick={() => this.beginNewSession()}
              />
            ) : listEmpty ? null : (
              sessions.map((item) => (
                <AssistantListScreenItem
                  key={item.id}
                  assistantSession={item}
                  onButtonClick={(step) => this.continueSession(item.id, step)}
                  onDeleteClick={() => this.deleteSession(item.id)}
                />
              ))
            )}
          </ColumnWrapper>
        </ContentWrapper>

        {this.maybeRenderPopup()}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  renderError() {
    return this.renderPage(
      <ErrorMessage
        state={this.props.assistantStore.itemLoadingState}
        onRetry={() => this.loadSessions()}
      />
    );
  }

  render() {
    const { assistantStore } = this.props;

    if (assistantStore.isListLoading) {
      return this.renderLoading();
    }

    if (assistantStore.isListError) {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default injectIntl((props: any) => {
  const form = useForm({ organization: '', author: '' });
  // @ts-ignore
  return <AssistantListScreen form={form} {...props} />;
});
