import classNames from 'classnames';

import AddCardButton from 'components/AddCardButton';
import { inject, observer } from 'mobx-react';
import { ClusterModelType } from 'models/ClusterModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { FC } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import CardColumnHeader from './CardColumnHeader';
import ColumnLoading from 'components/Loading/ColumnLoading';
import { AddCardButtonIcons } from 'components/AddCardButton/AddCardButton';
import { DataStoreType } from 'models/DataStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { IntlShape, injectIntl } from 'react-intl';

interface CardColumnProps {
  children?: React.ReactNode;
  cluster?: ClusterModelType;
  count?: number;
  add?: boolean;
  nameChangeOnBlur?: boolean;
  onAddCard?: () => void;
  mayEdit?: boolean;
  accept?: string[];
  lastDroppedItem?: any;
  onDrop?: (item: any) => void;
  painpointsStore?: PainpointsStoreType;
  isLoading?: boolean;
  fixHeight?: number;
  dataStore?: DataStoreType;
  applicationStore?: ApplicationStoreType;
  intl: IntlShape;
}

export const ItemType = {
  CARD: 'card'
};

const CardColumn: FC<CardColumnProps> = function CardColumn({
  children,
  cluster,
  count,
  nameChangeOnBlur,
  onAddCard,
  add,
  mayEdit,
  painpointsStore,
  isLoading,
  fixHeight,
  dataStore,
  applicationStore,
  intl
}: CardColumnProps) {
  const CardColumnClasses = classNames('card-column', {
    'card-column--add': add
  });

  const [collectedProps, drop] = useDrop({
    accept: ItemType.CARD,
    drop: (item: { painpoint: PainpointModelType }) => {
      painpointsStore!.updatePainpoint(item.painpoint.id, {
        cluster_id: cluster!.id
      });

      return { accept: true };
    },
    collect: (monitor: DropTargetMonitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver()
      };
    }
  });

  const isActive = collectedProps.isOver && collectedProps.canDrop;
  let backgroundColor = '#262634';
  let opacity = 1;
  if (isActive) {
    opacity = 0.8;
    backgroundColor = '#5154f1';
  } else if (collectedProps.canDrop) {
    opacity = 0.5;
  }

  return (
    <div
      className={CardColumnClasses}
      ref={drop}
      style={{ backgroundColor, opacity }}
    >
      <CardColumnHeader
        cluster={cluster}
        changeOnBlur={nameChangeOnBlur}
        count={count}
        mayEdit={mayEdit}
        fixHeight={fixHeight}
      />

      {isLoading && <ColumnLoading />}
      <div className="card-column__body">{children}</div>

      {mayEdit && onAddCard && (
        <div className="card-column__button">
          <AddCardButton
            onClick={onAddCard}
            disabled={
              !dataStore!.isAdmin && dataStore?.project?.reachedPainpointLimit
            }
          />
          {dataStore?.organization?.ai_enabled && (
            <AddCardButton
              icon={AddCardButtonIcons.AI}
              disabled={
                !dataStore!.isAdmin && dataStore!.project?.reachedPainpointLimit
              }
              onClick={async (event) => {
                if (!dataStore.project!.briefing.completed) {
                  applicationStore!.setAiUnavailable(true);
                  return;
                }
                (event.target as HTMLButtonElement).disabled = true;
                cluster?.setIsBeeingFilled(true);
                const result =
                  await painpointsStore!.generateMorePainpointsByAi(
                    dataStore!.currentOrganizationId!,
                    dataStore!.currentProjectId!,
                    cluster!.id!
                  );
                if (result && !(result as any).success) {
                  applicationStore!.setFlashMessage(
                    intl.formatMessage({ id: 'AI create error' }),
                    'error'
                  );
                }
                cluster?.setIsBeeingFilled(false);
                (event.target as HTMLButtonElement).disabled = false;
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const InjectedScreen = inject(
  'painpointsStore',
  'dataStore',
  'applicationStore'
)(observer(CardColumn));

export default injectIntl((props: CardColumnProps) => {
  // @ts-ignore
  return <InjectedScreen {...props} />;
});
