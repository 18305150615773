import { FormattedMessage } from 'react-intl';

import AssistantGif from 'domain/assistant/AssistantGif';
import { SessionMainText } from 'domain/assistant/session-elements';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import ExplanationScreen from '../ExplanationScreen';

const BenchmarkDoneScreen = ({ location }: HistoryProps) => {
  return (
    <ExplanationScreen
      barProgress={100}
      sidebarHeadline={ASSISTANTS.creative_session.step2_1.header}
      header={<FormattedMessage id="assistant benchmarks header" />}
      headerStep={ASSISTANTS.creative_session.step2_1.header}
      buttonTextId="assistant finish button"
      nextRoute={ROUTE_ASSISTANT}
      nextRouteIsAbsolute={true}
      sidebarScrollTo="benchmarks"
      showHeader="benchmarks"
      location={location}
    >
      <SessionMainText>
        <FormattedMessage id="assistant benchmarks done" />
      </SessionMainText>

      <AssistantGif />
    </ExplanationScreen>
  );
};

export default BenchmarkDoneScreen;
