import classNames from 'classnames';

interface ContentLayoutProps {
  children: React.ReactNode;
  subNav: React.ReactNode;
  noHeader?: boolean;
}

export default function ContentLayout({
  children,
  subNav,
  noHeader
}: ContentLayoutProps) {
  return (
    <div className="l-content">
      <div className="l-content__first">{subNav}</div>
      <main
        role="main"
        className={classNames('l-content__second', {
          'l-content__second--noHeader': noHeader
        })}
      >
        {children}
      </main>
    </div>
  );
}
