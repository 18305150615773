import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import { DataStoreType } from 'models/DataStore';
import { ProjectModelType } from 'models/ProjectModel';
import { ProjectsStoreType } from 'models/ProjectsStore';

interface PublicProjectsListContainerProps {
  bookmarkedOnly?: boolean;
  itemContainer?: any;
  renderProject: (
    project: ProjectModelType,
    isCurrent: boolean
  ) => JSX.Element | null;
  loading?: any;
  orgId?: number;
}

interface ProjectsListContainerProps extends PublicProjectsListContainerProps {
  dataStore: DataStoreType;
  projectsStore: ProjectsStoreType;
}

@inject('dataStore', 'projectsStore')
@observer
class ProjectsListContainer extends React.Component<ProjectsListContainerProps> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: ProjectsListContainerProps) {
    if (this.props.orgId !== prevProps.orgId) {
      this.load();
    }
  }

  load() {
    this.props.projectsStore.getProjects(this.props.orgId);
  }

  renderLoading() {
    if (this.props.loading) {
      return this.props.loading;
    }

    return <Loading />;
  }

  renderError() {
    // TODO retry
    return <ErrorMessage state={this.props.projectsStore.listLoadingState} />;
  }

  render() {
    const {
      projectsStore,
      dataStore,
      itemContainer,
      renderProject,
      bookmarkedOnly
    } = this.props;

    if (projectsStore.isListLoading) {
      return this.renderLoading();
    }
    if (projectsStore.isListError) {
      return this.renderError();
    }

    const Container = !itemContainer ? React.Fragment : itemContainer;

    const list = bookmarkedOnly
      ? projectsStore.bookmarkedProjects
      : projectsStore.allProjects;

    const rendered = list
      .map((project) =>
        renderProject(project, project.id === dataStore.currentProjectId)
      )
      .filter((item) => item !== null);

    if (rendered.length < 1) {
      return (
        <CardWrapper>
          <FormattedMessage id="no project" />
        </CardWrapper>
      );
    }

    return <Container>{rendered}</Container>;
  }
}

export default (props: PublicProjectsListContainerProps) => {
  // @ts-ignore
  return <ProjectsListContainer {...props} />;
};
