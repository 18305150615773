import MainNavItem from 'components/MainNavItem';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';

// tslint:disable: jsx-wrap-multiline
// tslint:disable: align

interface AssistantNavItemProps {
  dataStore?: DataStoreType;
  assistantStore?: AssistantStoreType;
  active?: boolean;
  displayWithoutOnlyOrg?: boolean;
}

export default inject(
  'dataStore',
  'assistantStore'
)(
  observer(({ dataStore, assistantStore, active }: AssistantNavItemProps) => {
    const onlyOrg = dataStore!.onlyOwnOrg;

    // quick fix to allow superuser to create sessions for last org
    // this will only return true when there is an org in context (which is the last visited org)
    const isAdmin = dataStore!.isAdmin;

    if (
      (onlyOrg &&
        (onlyOrg.access_level === 'user' ||
          onlyOrg.access_level === 'admin' ||
          onlyOrg.access_level === 'superadmin')) ||
      isAdmin
    ) {
      useEffect(() => {
        if (dataStore!.receivedInvitationsCount === undefined) {
          const getCount = async () => {
            try {
              await assistantStore!.getReceivedInvitationsCount();
            } catch (error: any) {
              // ignore
            }
          };
          getCount();
        }
      }, []);

      return (
        <MainNavItem
          bubble={dataStore!.receivedInvitationsCount || undefined}
          label={<FormattedMessage id="Assistant" />}
          iconName="assistant"
          active={active}
          to={ROUTE_ASSISTANT}
        />
      );
    }

    return null;
  })
);
