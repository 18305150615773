
import Flow from 'components/Layout/Flow';
import SessionIcon from 'components/SessionIcon';
import Typography from 'components/Typography';
import { FormattedRelativeTime } from 'react-intl';

export const SidebarItemHeader = ({
  children,
  date
}: {
  children?: any;
  date?: string;
}) => {
  let relTime: number | undefined;
  if (date) {
    const then = new Date(date);
    if (then) {
      relTime = new Date().getTime() - then.getTime();
      relTime = relTime < 0 ? undefined : Math.round(relTime / -86400000);
    }
  }

  return (
    <div className="sidebar-teaser__body">
      <SessionIcon width="2.625rem" />
      <Flow flowSpace="0.125rem">
        <Typography size="small">{children}</Typography>
        {typeof relTime === 'number' ? (
          <Typography size="smaller" opaque={true}>
            <FormattedRelativeTime value={relTime} numeric="auto" unit="day" />
          </Typography>
        ) : null}
      </Flow>
    </div>
  );
};

export default SidebarItemHeader;
