
interface SubNavCategoryProps {
  children: React.ReactNode;
  title: React.ReactNode | string;
}

export default function SubNavCategory({
  children,
  title
}: SubNavCategoryProps) {
  return (
    <ul className="sub-nav__list">
      <h2 className="sub-nav__headline">{title}</h2>
      {children}
    </ul>
  );
}
