import { ItemType } from 'components/CardColumn/CardColumn';
import type { FC } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import PainpointsListItem, {
  PainpointsListItemProps
} from './PainpointsListItem';

interface DraggableProps {
  name?: string;
  type?: string;
  isDropped?: boolean;
}

export const DraggablePainPointItem: FC<
  PainpointsListItemProps & DraggableProps
> = function DraggablePainPointItem({
  name,
  type,
  isDropped,
  painpoint,
  ...rest
}: PainpointsListItemProps & DraggableProps) {
  const { id } = painpoint;

  const [{ isDragging }, drag] = useDrag({
    canDrag(monitor: DragSourceMonitor) {
      if (painpoint?.author?.id === rest.currentUserId || rest.isOrgAdmin) {
        return true;
      }
      return false;
    },
    type: ItemType.CARD,
    item: () => {
      return { painpoint };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.3 : 1 }}>
      {isDropped ? <s>{name}</s> : name}
      <PainpointsListItem painpoint={painpoint} key={id} {...rest} />
    </div>
  );
};
