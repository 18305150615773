import { inject, observer } from 'mobx-react';
import React from 'react';

import { NetworkAwareAppLayout } from 'components/AppLayout/NetworkAwareAppLayout';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';

interface PublicProjectContainerProps {
  projectId: number;
  loaderComponent?: React.ComponentType<any>;
  errorComponent?: React.ComponentType<any>;
  children?: React.ReactNode;
}

interface ProjectContainerProps {
  projectsStore: ProjectsStoreType;
  dataStore: DataStoreType;
}

@inject('projectsStore', 'dataStore')
@observer
class ProjectContainer extends React.Component<
  ProjectContainerProps & PublicProjectContainerProps
> {
  convertId(id: any): number {
    if (!id) {
      return 0;
    }

    return typeof id === 'number' ? id : parseInt(id, 10);
  }

  getProjectId(): number {
    return this.convertId(this.props.projectId);
  }

  async selectProject(projectId: number) {
    const { dataStore } = this.props;

    if (dataStore.project?.id === projectId) {
      // project has been loaded already
      return;
    }

    const project = await this.props.projectsStore.getProject(projectId);

    // we need to select this org for the current context manually
    // TODO create projectsStore.getProjectForContext() to handle all the context org related stuff?
    if (project) {
      dataStore.setProject(project);
    }
  }

  componentDidMount() {
    const projectId = this.getProjectId();

    if (!projectId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error('ProjectContainer mounted without project ID!');
      return;
    }

    this.selectProject(projectId);
  }

  componentDidUpdate(
    prevProps: ProjectContainerProps & PublicProjectContainerProps
  ) {
    const projectId = this.getProjectId();

    if (projectId === this.convertId(prevProps.projectId)) {
      return;
    }

    if (!projectId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error('ProjectContainer updated without project ID!');
      return;
    }

    this.selectProject(projectId);
  }

  renderNav(content: any) {
    const { currentOrganization, isAdmin } = this.props.dataStore;

    return (
      <NetworkAwareAppLayout
        org={currentOrganization}
        linkOrg={isAdmin}
        withSettings={true}
      >
        {content}
      </NetworkAwareAppLayout>
    );
  }

  renderProjectNotPresent(projectId: number) {
    const { projectsStore, errorComponent, loaderComponent } = this.props;

    if (!projectId || projectsStore.isItemLoadError) {
      if (errorComponent) {
        return React.createElement(errorComponent, {
          projectId,
          error: !projectId ? 'invalid_id' : projectsStore.itemLoadingState,
          onRetry:
            projectsStore.itemLoadingState !== 'not_found'
              ? () => this.selectProject(projectId)
              : undefined
        });
      }

      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable-next-line: no-console
        console.error('Warning: ProjectContainer has no errorComponent prop!');
      }
      return null;
    }

    if (loaderComponent) {
      return React.createElement(loaderComponent);
    }

    if (process.env.NODE_ENV !== 'production') {
      // tslint:disable-next-line: no-console
      console.error('Warning: ProjectContainer has no loaderComponent prop!');
    }
    return null;
  }

  render() {
    const {
      dataStore: { project }
    } = this.props;
    const projectId = this.getProjectId();

    if (!projectId || !project || project.id !== projectId) {
      return this.renderNav(this.renderProjectNotPresent(projectId));
    }

    return this.renderNav(this.props.children);
  }
}

export default (props: PublicProjectContainerProps) => (
  // @ts-ignore
  <ProjectContainer {...props} />
);
