import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import LoginForm from 'components/LoginForm';
import MainButton from 'components/MainButton';
import LanguageSelectContainer from 'containers/LanguageSelectContainer';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { SharedContentStoreType } from 'models/SharedContentStore';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicSharedContentContainerProps extends WrappedComponentProps {
  token?: string;
  // requirePassword?: boolean;
  children?: any;
}

interface SharedContentContainerProps
  extends PublicSharedContentContainerProps {
  applicationStore: ApplicationStoreType;
  sharedContentStore: SharedContentStoreType;
  form: FormType;
}

@inject('sharedContentStore')
@observer
class SharedContentContainer extends React.Component<SharedContentContainerProps> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: SharedContentContainerProps) {
    if (!this.props.token) {
      this.props.sharedContentStore.reset();
      return;
    }

    if (prevProps.token !== this.props.token) {
      this.load();
    }
  }

  async load(password?: string) {
    const { token } = this.props;
    if (!token) {
      return;
    }

    await this.props.sharedContentStore.getSharedContent(token, password);
  }

  submitPassword() {
    const { password } = this.props.form.values;
    if (!password) {
      return;
    }

    this.load(password);
  }

  renderPage(content?: any) {
    return content;
  }

  renderPasswordForm() {
    const { form } = this.props;
    const { loadingState } = this.props.sharedContentStore;

    return (
      <LoginForm>
        <Form onSubmit={() => this.submitPassword()}>
          {/* {loadingState === 'password_required' && (
            <Typography>
              <FormattedMessage id="shared link password required" />
            </Typography>
          )} */}
          {loadingState === 'wrong_password' && (
            <LoginForm.Error>
              <FormattedMessage id="shared link wrong password" />
            </LoginForm.Error>
          )}

          <LoginForm.Input>
            <Input
              name="password"
              type="password"
              autoFocus={true}
              {...form.bindInput('password')}
              label={<FormattedMessage id="Password" />}
              gap={true}
            />
          </LoginForm.Input>

          <PositionWrapper center={true} gap="2em">
            <RowWrapper gap="1em">
              <MainButton type="submit" disabled={!form.values.password}>
                <FormattedMessage id="OK" />
              </MainButton>
            </RowWrapper>
          </PositionWrapper>

          <PositionWrapper center={true}>
            <LanguageSelectContainer />
          </PositionWrapper>
        </Form>
      </LoginForm>
    );
  }

  renderContent() {
    return this.props.children;
  }

  renderError() {
    return this.renderPage(<ErrorMessage state="load_error" />);
  }

  render() {
    if (!this.props.token) {
      return this.renderError();
    }

    switch (this.props.sharedContentStore.loadingState) {
      case 'loading':
        return this.renderPage(<Loading />);

      case 'error':
        return this.renderError();

      case 'password_required':
      case 'wrong_password':
        return this.renderPasswordForm();

      case 'ok':
        return this.renderContent();

      default:
        return null;
    }
  }
}

export default injectIntl((props: PublicSharedContentContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <SharedContentContainer {...props} form={form} />;
});
