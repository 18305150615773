import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Prompt } from 'react-router';

import AddButton from 'components/AddButton';
import Bookmark from 'components/Bookmark';
import CardMenu from 'components/CardMenu';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import EditableCard from 'components/EditableCard/EditableCard';
import EditMenu from 'components/EditMenu';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormChangeHandler from 'components/FormChangeHandler/FormChangeHandler';
import FormTagList from 'components/FormTagList';
import HypothesisSelectPopup from 'components/HypothesisSelectPopup';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import CardStack from 'components/Layout/CardStack';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import MenuButton from 'components/MenuButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import PageSwitch from 'components/PageSwitch';
import SectionHeadline from 'components/SectionHeadline';
import SimplePopup from 'components/SimplePopup';
import TagList from 'components/TagList';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import { UploadContainerStateEnum } from 'containers/UploadContainer/UploadContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { LearningsStoreType } from 'models/LearningsStore';
import { tagsListToArray } from 'models/TagListModel';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import { HistoryProps, isPush } from 'utils/history';
import { idFromProps } from 'utils/history/param-from-props';
import { scrollToTop } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable: jsx-wrap-multiline

interface PublicLearningsDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
  create?: boolean;
}

interface LearningsDetailContainerProps
  extends PublicLearningsDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  learningsStore: LearningsStoreType;
  form: FormType;
}

interface LearningsDetailContainerState {
  hypothesisSelectVisible?: boolean;
}

@inject('applicationStore', 'dataStore', 'learningsStore')
@observer
class LearningsDetailContainer extends React.Component<
  LearningsDetailContainerProps & HistoryProps,
  LearningsDetailContainerState
> {
  state: LearningsDetailContainerState = {};

  componentDidMount() {
    if (this.props.create) {
      this.props.dataStore.setLearningItem(undefined);
      this.props.form.reset();
      return;
    }

    const id = idFromProps(this.props);
    if (!id) {
      return;
    }

    const {
      dataStore: { learningItem }
    } = this.props;

    if (isPush(this.props.history) || !learningItem || learningItem.id !== id) {
      this.loadLearning(id);
    }
  }

  componentDidUpdate(
    prevProps: PublicLearningsDetailContainerProps & HistoryProps
  ) {
    const id = idFromProps(this.props);
    const prevId = idFromProps(prevProps);

    if (this.props.create && !prevProps.create) {
      this.props.dataStore.setLearningItem(undefined);
      this.props.form.reset();
      return;
    }

    if (id && id !== prevId) {
      this.loadLearning(id);
    }
  }

  load() {
    const id = idFromProps(this.props);
    if (id) {
      this.loadLearning(id);
    }
  }

  async loadLearning(id: number) {
    const { form, learningsStore } = this.props;

    form.reset();

    const learning = await learningsStore.getLearning(id);

    if (learning) {
      if (this.props.edit) {
        form.setField('headline', learning.headline);
        form.setField('description', learning.description);
        form.setField('result', learning.result);
      } else if (learning.publish_state === 'draft') {
        this.props.history.replace(learning.id + '/edit');
      }

      form.setField('tags', tagsListToArray(learning.tags));
    }
  }

  async save(buttonSave = false, publish = false) {
    const { dataStore, learningsStore, form, create } = this.props;

    const patch: any = {
      ...form.values,
      hypothesis_id: dataStore.learningItemHypothesis
        ? dataStore.learningItemHypothesis.id
        : null
    };
    if (publish) {
      patch.publish_state = 'active';
    }

    try {
      let item = dataStore.learningItem;
      if (create && !item) {
        item = await learningsStore.createLearning(patch, true);
      } else {
        if (!item || (!create && item.id !== idFromProps(this.props))) {
          return;
        }

        // TODO update form after update?
        item = await learningsStore.updateLearning(item.id, patch);
      }

      if (buttonSave) {
        if (learningsStore.isItemSaveError) {
          this.displayError();
        } else {
          this.props.history.replace(dataStore.contextUri + '/learnings');
        }
      }
    } catch (error) {
      if (handleFormError(form, error)) {
        scrollToTop();
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { dataStore, learningsStore } = this.props;
    const item = dataStore.learningItem;

    if (!item) {
      return;
    }

    try {
      await learningsStore.updateLearning(item.id, {
        tags: newTags || []
      });
    } catch (e) {
      // TODO How to handle this?
    }
  }

  async remove() {
    const { intl, learningsStore, dataStore, history } = this.props;

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage({ id: 'remove learnings confirm' }, { count: 1 })
      )
    ) {
      return;
    }

    if (!dataStore.learningItem) {
      return;
    }

    try {
      await learningsStore.deleteLearning(dataStore.learningItem.id);

      history.replace(dataStore.contextUri + '/learnings');
    } catch (error) {
      this.displayError('remove');
    }
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    const { form, dataStore } = this.props;

    if (!newState) {
      form.setLoading(false);
      return;
    }

    if (newState === 'saving' && !dataStore.learningItem?.id) {
      // item has not been saved yet, so we need to wait for the upload before we can reenable form
      form.setLoading(true);
    }
  }

  removeHypothesis(save: boolean = false) {
    this.props.dataStore.setLearningItemHypothesis(undefined);

    if (save) {
      this.saveHypothesis(null);
    }
  }

  beginSelectHypothesis() {
    this.setState({
      hypothesisSelectVisible: true
    });
  }

  selectHypothesis(id: number, save: boolean = false) {
    const { dataStore } = this.props;

    const hypothesis = dataStore.hypotheses.get(id.toString());
    if (hypothesis) {
      dataStore.setLearningItemHypothesis(hypothesis);
    }

    this.finishSelectHypothesis();

    if (save) {
      this.saveHypothesis(id);
    }
  }

  finishSelectHypothesis() {
    this.setState({
      hypothesisSelectVisible: false
    });
  }

  async saveHypothesis(id?: number | null | undefined) {
    const { dataStore, learningsStore } = this.props;

    if (dataStore.learningItem) {
      try {
        await learningsStore.updateLearning(dataStore.learningItem.id, {
          hypothesis_id: id || null
        });

        if (learningsStore.isItemSaveError) {
          this.displayError();
          return;
        }
      } catch (error) {
        // there should be no form error here
      }
    }
  }

  async saveFieldUpdate(names: string[]) {
    const { form, learningsStore, dataStore } = this.props;

    if (!dataStore.learningItem) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await learningsStore.updateLearning(dataStore.learningItem.id, patch);

      if (learningsStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  editTitle() {
    const { form, dataStore } = this.props;
    const item = dataStore.learningItem;

    if (!item) {
      return;
    }

    form.setField('headline', item.headline);

    form.setEditing('title');
  }

  isFromEmptyState() {
    return this.props.location.query?.empty === 'yes';
  }

  links() {
    const { dataStore, intl } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: false,
        to: contextUri + '/learnings',
        content: intl.formatMessage({ id: 'Learnings' })
      },
      {
        isLink: true,
        to: contextUri + '/learnings/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderPageSwitch() {
    if (!this.props.location?.state?.switch) {
      return;
    }

    const { dataStore, learningsStore, history } = this.props;
    return (
      <PageSwitch
        history={history}
        basePath={dataStore.contextUri + '/learnings/'}
        pageSwitch={learningsStore.pageSwitch(dataStore.learningItem)}
      />
    );
  }

  renderPage(content: any) {
    const showClose = !this.props.location?.state?.isRedirect;

    return (
      <>
        {content}

        {showClose && (
          <OverlayWrapper topRight={true}>
            <CloseButton
              label={this.props.intl.formatMessage({ id: 'Close' })}
              iconName="cross"
              onClick={() =>
                this.props.history.push(
                  this.props.dataStore.contextUri + '/learnings'
                )
              }
            />
          </OverlayWrapper>
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(
      <>
        <PageHeader titleId="Learnings" />
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        <PageHeader titleId="Learnings" />
        <ErrorMessage
          state={this.props.learningsStore.itemLoadingState}
          onRetry={() => this.load()}
        />
      </>
    );
  }

  renderHypothesisPopup(save: boolean = false) {
    if (!this.state.hypothesisSelectVisible) {
      return null;
    }

    return (
      <HypothesisSelectPopup
        onAbort={() => this.finishSelectHypothesis()}
        onSelect={(id) => this.selectHypothesis(id, save)}
      />
    );
  }

  renderHypothesis(
    mayEdit: boolean,
    hypothesis?: HypothesisModelType,
    save: boolean = false
  ) {
    const { intl, dataStore } = this.props;

    return (
      <>
        <SectionHeadline
          title={<FormattedMessage id="hypothesis header learning" />}
        />

        {hypothesis ? (
          <CardStack>
            <div />
            {hypothesis.painpoint && (
              <PainpointsListItem
                painpoint={hypothesis.painpoint}
                contextUri={dataStore.contextUri}
                readOnly={true}
              />
            )}
            <HypothesesListItem
              hypothesis={hypothesis}
              contextUri={dataStore.contextUri}
              readOnly={true}
              showDestroyButton={true}
              onRemoveClick={() => this.removeHypothesis(save)}
            />
          </CardStack>
        ) : (
          mayEdit && (
            <CardStack noPadding={true}>
              <AddButton
                iconName="plus"
                label={intl.formatMessage({
                  id: 'Select hypothesis'
                })}
                onClick={() => this.beginSelectHypothesis()}
              />
            </CardStack>
          )
        )}
      </>
    );
  }

  renderEditMode() {
    const { learningsStore, dataStore, form, intl } = this.props;
    const item = dataStore.learningItem;
    const hypothesis = dataStore.learningItemHypothesis;

    const itemIsActive = item && item.publish_state === 'active';

    return this.renderPage(
      <>
        {itemIsActive && learningsStore.isItemSaving && <Loading />}

        {this.renderHypothesisPopup(true)}
        {this.renderPageSwitch()}

        <FormChangeHandler
          halted={itemIsActive}
          onSave={() => this.save()}
          isSaving={learningsStore.isItemSaving}
        >
          {({ onTextChanged, hasUnsavedChanges, isSaving }) => (
            <>
              {itemIsActive && hasUnsavedChanges && (
                <Prompt
                  message={intl.formatMessage({ id: 'unsaved prompt' })}
                />
              )}
              <Form loading={form.loading} onSubmit={() => this.save(true)}>
                <PageHeader titleId="Learnings" />
                <ContentWrapper>
                  <FormTagList
                    form={form}
                    field="tags"
                    allowAdd={true}
                    onChanged={onTextChanged}
                  />

                  {this.isFromEmptyState() && (
                    <div style={{ marginBottom: '3em' }}>
                      <LinkRow links={this.links()} />
                    </div>
                  )}

                  <ColumnWrapper gap="4em">
                    <CardStack>
                      <CardWrapper>
                        <Input
                          headline={true}
                          name="headline"
                          placeholder={intl.formatMessage({
                            id: 'Insert headline'
                          })}
                          {...form.bindInput('headline', onTextChanged)}
                        />
                      </CardWrapper>

                      <CardWrapper>
                        <ColumnWrapper gap="0.5em">
                          <Textarea
                            label={intl.formatMessage({
                              id: 'learning description'
                            })}
                            placeholder={intl.formatMessage({
                              id: 'learning description placeholder'
                            })}
                            name="description"
                            {...form.bindInput('description', onTextChanged)}
                          />
                        </ColumnWrapper>
                      </CardWrapper>

                      <CardWrapper>
                        <ColumnWrapper gap="0.5em">
                          <Textarea
                            label={intl.formatMessage({
                              id: 'learning result'
                            })}
                            placeholder={intl.formatMessage({
                              id: 'learning result placeholder'
                            })}
                            name="result"
                            {...form.bindInput('result', onTextChanged)}
                          />
                        </ColumnWrapper>
                      </CardWrapper>
                    </CardStack>

                    {this.renderHypothesis(true, hypothesis, true)}

                    <PositionWrapper end={true}>
                      {itemIsActive ? (
                        <MainButton type="submit">
                          <FormattedMessage id="Save" />
                        </MainButton>
                      ) : (
                        <RowWrapper gap="1em" alignRight={true}>
                          <MainButton type="submit" secondary={true}>
                            {isSaving && <Loading />}
                            <FormattedMessage id="Save draft" />
                          </MainButton>
                          <MainButton
                            type="button"
                            onClick={() => this.save(true, true)}
                          >
                            <FormattedMessage id="Publish" />
                          </MainButton>
                        </RowWrapper>
                      )}
                    </PositionWrapper>
                  </ColumnWrapper>
                </ContentWrapper>
              </Form>
            </>
          )}
        </FormChangeHandler>
      </>
    );
  }

  renderViewMode() {
    const { intl, dataStore, learningsStore, form } = this.props;

    const item = this.props.dataStore.learningItem;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author);

    return this.renderPage(
      <>
        {learningsStore.isItemSaving && form.editing !== 'title' && <Loading />}

        {this.renderHypothesisPopup(true)}
        {this.renderPageSwitch()}

        <PageHeader
          titleId="Learnings"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
              onEditClick={!mayEdit ? undefined : () => this.editTitle()}
            />
          }
        />

        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            <TagList tags={item.tags} />
          )}

          <ColumnWrapper gap="1em">
            <ColumnWrapper gap="1em">
              <EditableCard
                form={form}
                name="description"
                value={item.description}
                label={<FormattedMessage id="learning description" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['description'])}
              />

              <EditableCard
                form={form}
                name="result"
                value={item.result}
                label={<FormattedMessage id="learning result" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['result'])}
                extra={
                  <CardMenu user={item.author}>
                    <Bookmark
                      elementType="Learning"
                      elementId={item.id}
                      count={item.bookmarks_count}
                      id={item.bookmark_id}
                    />
                    {mayEdit && (
                      <EditMenu
                        bottomLeft={true}
                        label={intl.formatMessage({ id: 'Edit' })}
                      >
                        <MenuButton
                          label={intl.formatMessage({ id: 'Edit' })}
                          description={intl.formatMessage({
                            id: 'learning edit desc'
                          })}
                          iconName="pen"
                          onClick={() => this.editTitle()}
                        />
                        <MenuButton
                          label={intl.formatMessage({ id: 'Remove' })}
                          description={intl.formatMessage({
                            id: 'learning remove desc'
                          })}
                          iconName="bin"
                          onClick={() => this.remove()}
                        />
                      </EditMenu>
                    )}
                  </CardMenu>
                }
              />
            </ColumnWrapper>

            {this.renderHypothesis(mayEdit, item.hypothesis, true)}
          </ColumnWrapper>

          {item.publish_state !== 'draft' && (
            <CommentsContainer
              elementType={ElementType.Learning}
              element={item}
              textId="comment text Learning"
            />
          )}
        </ContentWrapper>

        {form.editing === 'title' && (
          <Form disabled={learningsStore.isItemSaving}>
            <SimplePopup
              onAbort={() => form.setEditing()}
              onSubmit={() => this.saveFieldUpdate(['headline'])}
              isLoading={learningsStore.isItemSaving}
            >
              <Input
                autoFocus={true}
                headline={true}
                name="headline"
                placeholder={intl.formatMessage({
                  id: 'Insert headline'
                })}
                {...form.bindInput('headline')}
              />
            </SimplePopup>
          </Form>
        )}
      </>
    );
  }

  render() {
    const { learningsStore, create, edit } = this.props;

    if (learningsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (learningsStore.isItemLoadError) {
      return this.renderError();
    }

    if (create || edit) {
      return this.renderEditMode();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicLearningsDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <LearningsDetailContainer {...props} form={form} />;
});
