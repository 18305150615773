import CreateUserFlowWrapper from 'components/CreateUserFlowWrapper';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { PrototypesStoreType } from 'models/PrototypesStore';
import { useState } from 'react';

export interface ScribbleUserFlowContainerProps {
  dataStore?: DataStoreType;
  assistantStore?: AssistantStoreType;
  prototypesStore?: PrototypesStoreType;
}

function ScribbleUserFlowContainer({
  dataStore,
  prototypesStore,
  assistantStore
}: ScribbleUserFlowContainerProps) {
  const [adding, setAdding] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  if (!prototypesStore || !dataStore || !assistantStore) return null;

  const assistantSession = dataStore!.assistantSessionItem;

  const project = assistantStore!.currentItem!.project;
  const projId = project.id;
  const orgId = project.organization_id;

  assistantStore!.currentItem!.project!.id;

  const add = async () => {
    setAdding(true);

    // let newScreen;
    try {
      const newScreen = await assistantStore!.createUnassignedPrototypeScreen(
        assistantSession
      );

      if (newScreen) {
        prototypesStore.initializeItem({
          id: newScreen.prototype_id,
          prototype_screens: [newScreen]
        });
      } else {
        throw Error('no new screen was returned');
      }
    } catch (e) {
      // TODO what to do here?
      console.log(e);
    }

    setAdding(false);
  };

  const remove = async (id: number) => {
    setDeleting(true);

    try {
      await prototypesStore.deleteScreen({
        screenId: id,
        prototype: undefined,
        assistantSession: assistantSession,
        orgId,
        projId
      });
    } catch (e) {
      // TODO what to do here?
      if (process.env.NODE_ENV !== 'production') {
        console.log('CreateUserFlowContainer - remove', e);
      }
    }

    setDeleting(false);
  };

  const move = async (id: number, direction: 'left' | 'right') => {
    setSorting(true);
    try {
      await prototypesStore.moveScreen(
        id,
        direction,
        undefined,
        assistantSession,
        orgId,
        projId
      );
    } catch (e) {
      // TODO what to do here?
      if (process.env.NODE_ENV !== 'production') {
        console.log('CreateUserFlowContainer - move', e);
      }
    }

    setSorting(false);
  };

  return (
    <CreateUserFlowWrapper
      full={true}
      onAddClick={add}
      onMoveClick={move}
      onRemoveClick={remove}
      sorting={sorting}
      adding={adding}
      deleting={deleting}
    />
  );
}

export default inject(
  'dataStore',
  'assistantStore',
  'prototypesStore'
)(observer(ScribbleUserFlowContainer));
