import classNames from 'classnames';

interface TabListProps {
  children: React.ReactNode;
  small?: boolean;
  projectList?: boolean;
  search?: boolean;
}

export default function TabList({ children, small, search, projectList }: TabListProps) {
  const tablistClasses = classNames('tablist', {
    'tablist--small': small,
    'tablist--search': search,
    'tablist--project-list': projectList,
  });

  return <div className={tablistClasses}>{children}</div>;
}
