import { injectIntl, WrappedComponentProps } from 'react-intl';

import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { HypothesisModelType } from 'models/HypothesisModel';

export default injectIntl(
  ({
    hypothesis,
    intl,
    onClick,
    selected
  }: {
    hypothesis: HypothesisModelType;
    onClick: () => void;
    selected: boolean;
  } & WrappedComponentProps) => {
    const attachment = hypothesis.attachment;
    const logo = hypothesis.attachment;

    return (
      <RainbowCardCompact
        simple={true}
        onCheckboxChange={onClick}
        checked={selected}
        title={hypothesis.headline || intl.formatMessage({ id: 'no headline' })}
        src={attachment?.resource_urls?.large}
        logo={
          logo &&
          logo.resource_urls && (
            <CompanyLogo
              imgUrl={logo.resource_urls.small}
              alt={intl.formatMessage({ id: 'Logo' })}
            />
          )
        }
      />
    );
  }
);
