import { inject, observer } from 'mobx-react';
import { injectIntl, useIntl } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

import { WrappedComponentProps } from 'react-intl';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import OrgServiceItem from './OrgServiceItem';
import AddButton from 'components/AddButton';
import CardGrid from 'components/CardGrid';
import { useEffect, useState } from 'react';
import OrgServiceItemEditPopup from './OrgServiceItemEditPopup';
import OrgServiceModel from 'models/OrgServiceModel';

interface PublicOrgServicesContainerProps extends WrappedComponentProps {}

interface OrgServicesContainerProps extends PublicOrgServicesContainerProps {
  form: FormType;
  organizationsStore?: OrganizationsStoreType;
  dataStore?: DataStoreType;
  applicationStore?: ApplicationStoreType;
}

const OrgServicesContainer = observer((props: OrgServicesContainerProps) => {
  const [isAdding, setIsAdding] = useState(false);
  useEffect(() => {
    props.organizationsStore!.getServices();
  }, [props.organizationsStore]);

  const intl = useIntl();

  return (
    <CardGrid
      title={intl.formatMessage({ id: 'Services' })}
      noPadding={true}
      twoColumns={true}
    >
      {[...props.dataStore!.orgServices.values()].map((service) => (
        <OrgServiceItem key={service.id} service={service} />
      ))}
      {isAdding && (
        <OrgServiceItemEditPopup
          isAdding={isAdding}
          onEditDone={() => setIsAdding(false)}
          service={OrgServiceModel.create({
            id: -1,
            name: undefined,
            content: undefined
          })}
        />
      )}
      <AddButton
        iconName="plus"
        label={intl.formatMessage({ id: 'Add Service' })}
        style={{ height: 'revert-layer', minWidth: '100%' }}
        onClick={() => {
          setIsAdding(true);
        }}
      />
    </CardGrid>
  );
});

export default injectIntl(
  inject(
    'organizationsStore',
    'dataStore',
    'applicationStore'
  )(
    observer((props: PublicOrgServicesContainerProps) => {
      const form = useForm();
      return <OrgServicesContainer {...props} form={form} />;
    })
  )
);
