import Icon from 'components/Icon';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export enum AddCardButtonIcons {
  PLUS = 'plus',
  AI = 'ai'
}

interface AddCardButtonProps extends HtmlButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: AddCardButtonIcons;
}

export default function AddCardButton({
  icon = AddCardButtonIcons.PLUS,
  ...props
}: AddCardButtonProps) {
  return (
    <button
      {...{ ...props }}
      className="add-card-button"
      onClick={props.onClick}
    >
      <Icon
        name={icon}
        size={icon === AddCardButtonIcons.AI ? 'medium' : undefined}
      />
    </button>
  );
}
