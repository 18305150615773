import classNames from 'classnames';
import { ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';

import DestroyButton from 'components/DestroyButton';
import Loading from 'components/Loading';

interface MobilePreviewProps {
  alt: string;
  imgUrl?: string;
  linkTo?: string;
  loading?: boolean;
  children?: ReactNode;
  onDestroy?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  overflow?: boolean;
  allowEmpty?: boolean;
}

export default injectIntl(
  ({
    alt,
    imgUrl,
    linkTo,
    loading,
    onDestroy,
    children,
    intl,
    overflow,
    allowEmpty
  }: MobilePreviewProps & WrappedComponentProps) => {
    const figure = !imgUrl ? (
      allowEmpty ? (
        <figure />
      ) : null
    ) : (
      <figure>
        <img src={imgUrl} alt={alt} />
      </figure>
    );
    return (
      <div
        className={classNames('mobile-preview', {
          'mobile-preview--overflow': overflow
        })}
      >
        {!loading && onDestroy && (
          <DestroyButton
            label={intl.formatMessage({ id: 'Remove' })}
            iconName="cross"
            onClick={onDestroy}
          />
        )}
        {!linkTo && figure}
        {linkTo && <Link to={linkTo}>{figure}</Link>}
        {children}
        {loading && <Loading />}
      </div>
    );
  }
);
