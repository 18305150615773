import Icon from 'components/Icon';

interface ProjectItemProps {
  title: string;
  subtitle?: React.ReactNode;
  withArrow?: boolean;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
}

export default function ProjectItem({
  title,
  subtitle,
  withArrow,
  onClick,
  selected,
  disabled
}: ProjectItemProps) {
  let classList = 'project-item';
  classList += selected ? ' project-item--selected' : '';
  classList += disabled ? ' project-item--disabled' : '';

  return (
    <button
      className={classList}
      onClick={onClick}
      aria-label={title || 'Projekt'}
    >
      <div className="project-item__body">
        <h3 className="project-item__title">{title}</h3>
        <h4 className="project-item__subtitle">{subtitle}</h4>
      </div>
      {withArrow && (
        <div className="project-item__arrow">
          <Icon name="arrow-right" />
        </div>
      )}
    </button>
  );
}
