import classNames from 'classnames';
import React, { ChangeEvent } from 'react';

import sortByField from 'utils/sort/field';
import Input from './Input';
import InputInterface, { cleanupInputProps } from './InputInterface';

interface Suggestion {
  id: number;
  name: string;
}

type HtmlInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface InputDropDownProps {
  headline?: boolean;
  subheadline?: boolean;
  suggestions?: Suggestion[];
  button?: React.ReactNode | string;
  active?: boolean;
  innerRef?: any;
  onSuggestionSelected?: (id: number, name: string) => void;
}

interface InputDropDownState {
  selected: number;
  overrideValue?: string;
  showSuggestions: boolean;
  suggestions?: Suggestion[];
  filteredSuggestions?: Suggestion[];
}

class InputWithDropdown extends React.Component<
  HtmlInputProps & InputInterface & InputDropDownProps,
  InputDropDownState
> {
  constructor(props: HtmlInputProps & InputInterface & InputDropDownProps) {
    super(props);

    this.state = {
      selected: -1,
      showSuggestions: true
    };
  }

  handleSelectionChange(direction: 'up' | 'down') {
    const { selected, filteredSuggestions } = this.state;

    if (filteredSuggestions === undefined) {
      return;
    }

    let newSelected = selected;

    if (direction === 'up') {
      if (selected > -1) {
        newSelected -= 1;
      } else {
        newSelected = filteredSuggestions.length - 1;
      }
    } else {
      if (selected < filteredSuggestions.length - 1) {
        newSelected += 1;
      } else {
        newSelected = -1;
      }
    }

    this.setState({
      selected: newSelected,
      overrideValue: filteredSuggestions[newSelected]?.name || undefined
    });
  }

  handleKeyPress = (e: React.KeyboardEvent) => {
    if (!this.state.showSuggestions) {
      return;
    }

    // UP
    if (e.keyCode === 38) {
      e.preventDefault();
      this.handleSelectionChange('up');
    }

    // DOWN
    if (e.keyCode === 40) {
      e.preventDefault();
      this.handleSelectionChange('down');
    }

    // ENTER
    if (e.keyCode === 13) {
      e.preventDefault();
      this.selectSuggestion();
    }

    // ESC
    if (e.keyCode === 27) {
      this.setState({ showSuggestions: false, selected: -1 });
    }
  };

  selectSuggestion() {
    const { onSuggestionSelected } = this.props;
    const { filteredSuggestions, selected } = this.state;

    this.setState({ showSuggestions: false });

    let suggestion;
    if (
      (selected || selected === 0) &&
      filteredSuggestions &&
      filteredSuggestions[selected]
    ) {
      suggestion = filteredSuggestions[selected];
    }

    onSuggestionSelected &&
      suggestion &&
      onSuggestionSelected(suggestion.id || -1, suggestion.name || '');
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange, suggestions } = this.props;
    const value = e.target.value || '';

    const filteredSuggestions = this.filterSuggestions(suggestions, value);

    this.setState({
      showSuggestions: true,
      filteredSuggestions,
      selected: -1,
      overrideValue: undefined
    });

    onChange && onChange(e);
  };

  handleLoseFocus = (e: Event) => {
    e.preventDefault();
    setTimeout(() => {
      this.setState({ showSuggestions: false, selected: -1 });
      // tslint:disable-next-line: align
    }, 150);

    // do not select on blur as item click doesn't work properly then
    // this.selectSuggestion();
  };

  handleReceiveFocus = (_: Event) => {
    this.setState({ showSuggestions: true });
  };

  handleSuggestionClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
    name: string
  ) => {
    e.preventDefault();

    this.setState({
      overrideValue: undefined
    });

    const { onSuggestionSelected } = this.props;
    onSuggestionSelected && onSuggestionSelected(id || -1, name || '');
  };

  filterSuggestions(
    suggestions: Suggestion[] | undefined,
    text: string
  ): Suggestion[] | undefined {
    const filter = text.toLowerCase();

    const filtered = suggestions
      ?.filter((s) => s.name.toLowerCase().startsWith(filter))
      .slice(0, 6);

    filtered?.sort(sortByField('name'));
    return filtered;
  }

  render() {
    const { selected, showSuggestions, filteredSuggestions, overrideValue } =
      this.state;
    const value = (this.props.value || '').toString();

    const processedSuggestions = filteredSuggestions?.map(
      (suggestion: Suggestion, index: number) => ({
        id: suggestion.id,
        name: suggestion.name,
        active: index === selected
      })
    );

    const showSuggestionItems =
      showSuggestions &&
      processedSuggestions &&
      processedSuggestions.length > 0;

    const inputClasses = classNames('edit-input__input', {
      'edit-input__input--gap': this.props.gap,
      'edit-input__input--simple-headline': this.props.headline,
      'edit-input__input--simple-subheadline': this.props.subheadline
    });

    const id = this.props.id ? this.props.id : '__formfield_' + this.props.name;
    return (
      <Input
        id={id}
        className={inputClasses}
        type="text"
        {...cleanupInputProps(this.props)}
        autoComplete="off"
        onKeyDown={(e) => this.handleKeyPress(e)}
        value={overrideValue || value}
        onChange={this.handleChange}
        onBlur={this.handleLoseFocus}
        onFocus={this.handleReceiveFocus}
        suggested={showSuggestionItems}
        suggestions={processedSuggestions}
        onSuggestionClick={this.handleSuggestionClick}
      />
    );
  }
}

export default React.forwardRef(
  (props: HtmlInputProps & InputInterface & InputDropDownProps, ref: any) => (
    <InputWithDropdown ref={ref} {...props} />
  )
);
