import UserAvatar from 'components/Avatar/UserAvatar';
import ErrorMessage from 'components/ErrorMessage';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import ListDetailContainer from 'containers/list/ListDetailContainer';
import ListOverviewContainer from 'containers/list/ListOverviewContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import {
  ContentListStoreType,
  SearchableElementType
} from 'models/ContentListStore';
import { DataStoreType } from 'models/DataStore';
import { UserModelType } from 'models/UserModel';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_AUTH, ROUTE_PROFILE } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface PublicUserElementsListProps {
  elementType?:
    | ElementType.Briefing
    | ElementType.Painpoint
    | ElementType.Benchmark
    | ElementType.Hypothesis
    | ElementType.Prototype
    | ElementType.Learning;
  user: UserModelType;
}

interface UserElementsListProps
  extends PublicUserElementsListProps,
    HistoryProps {
  contentListStore: ContentListStoreType;
  dataStore: DataStoreType;
}

@inject('contentListStore', 'dataStore')
@observer
class UserElementsList extends React.Component<UserElementsListProps> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: UserElementsListProps) {
    if (
      this.props.elementType !== prevProps.elementType ||
      prevProps.user.id !== this.props.user.id
    ) {
      this.load();
    }
  }

  load() {
    this.getElements(this.props?.location?.query?.page);
  }

  getElementType(): SearchableElementType | undefined {
    return this.props.elementType;
  }
  pluralizeElementType(elementType?: string, lowerCase = false) {
    if (!elementType) {
      return;
    }
    // @ts-ignore
    const type = {
      briefing: 'Briefings',
      painpoint: 'Painpoints',
      benchmark: 'Benchmarks',
      hypothesis: 'Hypotheses',
      prototype: 'Prototypes',
      learning: 'Learnings'
    }[elementType.toLowerCase()];
    // @ts-ignore
    return lowerCase ? type.toLowerCase() : type;
  }

  getElements(page?: number) {
    const { contentListStore, user } = this.props;
    const elementType = this.getElementType();

    contentListStore.getUserElements(user.id, elementType, page);
  }

  loadMore() {
    const elementType = this.getElementType();
    if (!elementType) {
      return;
    }

    const { contentListStore } = this.props;

    this.getElements(contentListStore.pageOf(elementType) + 1);
  }

  renderPage(content: any, titleId?: string) {
    const { dataStore, elementType, user } = this.props;

    // TODO proper formatting
    const title = elementType ? (
      <>
        {user.fullName} -{' '}
        <FormattedMessage id={this.pluralizeElementType(elementType)} />
      </>
    ) : (
      user.fullName
    );

    let userLink;
    if (user.id === dataStore.currentUserId) {
      userLink = ROUTE_AUTH + '/profile/' + user.id + '/settings';
    }

    return (
      <>
        <PageHeader
          titleId={this.pluralizeElementType(this.getElementType()) || titleId}
          headline={title}
          avatar={<UserAvatar user={user} url={userLink} />}
          bold={true}
          mail={user.email}
          center={true}
        />
        {content}
      </>
    );
  }

  renderOverview() {
    const { contentListStore, user } = this.props;
    if (contentListStore.isListLoading) {
      return this.renderLoading('Overview(titleId)');
    }

    return this.renderPage(
      // tslint:disable-next-line: jsx-wrap-multiline
      <ContentWrapper>
        <ListOverviewContainer
          type="author"
          headerType="author"
          baseUrl={'/app/profile/' + user.id + '/'}
          buildDetailUrl={(type) =>
            ROUTE_PROFILE.replace(':id', user.id.toString()) +
            '/' +
            this.pluralizeElementType(type, true)
          }
          calculateLimit={(type) => (type === 'painpoint' ? 6 : 3)}
          onRetry={() => this.load()}
        />
      </ContentWrapper>,
      'Overview(titleId)'
    );
  }

  renderDetail() {
    const elementType = this.getElementType();
    if (!elementType) {
      return null;
    }

    const { contentListStore } = this.props;

    let loadingMore = false;
    if (contentListStore.isListLoading) {
      if (contentListStore.hasAnyOf(elementType)) {
        loadingMore = true;
      } else {
        return this.renderLoading();
      }
    }

    return this.renderPage(
      <ContentWrapper>
        <ListDetailContainer
          type="author"
          noHeader={true}
          elementType={elementType}
          loadingMore={loadingMore}
          onLoadMore={() => this.loadMore()}
          onRetry={() => this.load()}
        />
      </ContentWrapper>
    );
  }

  renderLoading(titleId?: string) {
    return this.renderPage(<Loading />, titleId);
  }
  renderError() {
    return this.renderPage(
      <ErrorMessage
        state={this.props.contentListStore.listLoadingState}
        onRetry={() => this.load()}
      />
    );
  }

  render() {
    if (this.props.contentListStore.isListError) {
      return this.renderError();
    }
    if (this.getElementType()) {
      // detail page
      return this.renderDetail();
    }

    // overview page
    return this.renderOverview();
  }
}

export default (props: PublicUserElementsListProps) => (
  // @ts-ignore
  <UserElementsList {...props} />
);
