import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Link } from 'react-router-dom';

import OrgCardTop from 'components/CardTop/OrgCardTop';
import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import ErrorMessage from 'components/ErrorMessage';
import TagSelectInput from 'components/Inputs/TagSelectInput';
import LabeledIcon from 'components/LabeledIcon';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import TagList from 'components/TagList';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ContentListStoreType } from 'models/ContentListStore';
import { DataStoreType } from 'models/DataStore';
import { PrototypeModelType } from 'models/PrototypeModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import { HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';
import authorIdFromModel from 'utils/store/authorIdFromModel';
import PrototypesListItem from './PrototypesListItem';

interface PublicPrototypesListScreenProps extends WrappedComponentProps {
  showRecommendations?: boolean;
}

interface PrototypesListScreenState {
  copyToProjectOverlayActive: boolean;
}

interface PrototypesListScreenProps extends PublicPrototypesListScreenProps {
  form: FormType;
  contentListStore: ContentListStoreType;
  dataStore: DataStoreType;
  prototypesStore: PrototypesStoreType;
  actionsStore: ActionsStoreType;
}

@inject('contentListStore', 'dataStore', 'prototypesStore', 'actionsStore')
@observer
class PrototypesListScreen extends React.Component<
  PrototypesListScreenProps & HistoryProps,
  PrototypesListScreenState
> {
  state = {
    copyToProjectOverlayActive: false
  };

  componentDidMount() {
    if (this.props.showRecommendations) {
      this.loadPrototypeRecommendations();
    } else {
      this.loadPrototypes(!isPush(this.props.history));
    }
  }

  async loadPrototypes(checkIfAlreadyInStore = false) {
    const { dataStore, prototypesStore } = this.props;

    if (checkIfAlreadyInStore && dataStore.prototypesList) {
      return;
    }

    await prototypesStore.getPrototypes();

    if (!dataStore.prototypesList?.length && dataStore.isProjectEditor) {
      this.props.history.replace(
        dataStore.contextUri + '/prototypes/create?empty=yes',
        {
          isRedirect: true
        }
      );
    }
  }

  async loadPrototypeRecommendations() {
    const { contentListStore, dataStore } = this.props;
    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;
    if (!organizationId || !projectId) {
      return;
    }
    await contentListStore.getRecommendations(
      ElementType.Prototype,
      organizationId,
      projectId
    );
  }

  checkChanged(element: PrototypeModelType, newChecked: boolean) {
    const { dataStore } = this.props;

    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;

    if (!isDefined(organizationId) || !isDefined(projectId)) {
      return;
    }

    this.props.actionsStore.selectionSet(
      {
        Prototype: [
          {
            id: element.id,
            organizationId,
            projectId,
            publishState: element.publish_state
          }
        ]
      },
      newChecked
    );
  }

  async deletePrototypes(ids: number[]) {
    const { actionsStore, intl, prototypesStore } = this.props;

    if (!isDefined(ids) || ids.length < 1) {
      return;
    }

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: 'remove prototypes confirm' },
          { count: ids.length }
        )
      )
    ) {
      return;
    }

    await prototypesStore.bulkDeletePrototypes(ids);
    actionsStore.selectionClear();
  }

  setFilter(filter: string, value: string, changed: boolean = false) {
    this.props.prototypesStore.setFilter(filter, value);
  }

  getFilter(filter: string) {
    return this.props.prototypesStore.filter.get(filter);
  }

  resetFilters() {
    this.setFilter('tag', '');
    this.setFilter('state', '');
    this.setFilter('author', '');
  }

  links() {
    const { dataStore, intl, showRecommendations } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: showRecommendations,
        to: contextUri + '/prototypes',
        content: intl.formatMessage({ id: 'Prototypes' })
      },
      {
        isLink: !showRecommendations,
        to: contextUri + '/prototypes/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderList() {
    const {
      contentListStore,
      prototypesStore,
      dataStore,
      form,
      intl,
      actionsStore,
      showRecommendations
    } = this.props;

    if (showRecommendations) {
      if (!contentListStore.hasAnyOf(ElementType.Prototype)) {
        return this.renderPage(
          <ContentWrapper>
            <ColumnWrapper gap="3em">
              <LinkRow links={this.links()} />
              <RowWrapper gap="2em">
                <CardWrapper>
                  <FormattedMessage id={'Recommendations empty'} />
                  <div className="margin-v text-center">
                    <Link
                      className="main-button display-inline-flex"
                      to={dataStore.contextUri}
                    >
                      {intl.formatMessage({
                        id: 'Recommendations to briefing'
                      })}
                    </Link>
                  </div>
                  <img
                    alt={intl.formatMessage({
                      id: 'Recommendations add tags to briefing'
                    })}
                    className="margin-auto-h max-width-50"
                    src="/images/briefing-add-tag.png"
                  />
                </CardWrapper>
              </RowWrapper>
            </ColumnWrapper>
          </ContentWrapper>
        );
      }
    } else if (!prototypesStore.hasAny) {
      return this.renderPage(
        <ContentWrapper>
          <ColumnWrapper gap="1em">
            <LinkRow links={this.links()} />
            <RowWrapper gap="2em">
              <CardWrapper>
                <FormattedMessage id="no prototypes" />
              </CardWrapper>
            </RowWrapper>
          </ColumnWrapper>
        </ContentWrapper>
      );
    }

    const isOrgAdmin = dataStore.isOrgAdmin;
    const currentUserId = dataStore.currentUserId;

    const list = showRecommendations
      ? contentListStore.prototypesSlice()
      : prototypesStore.listWithCurrentFilter;
    const hasFilter =
      this.getFilter('state') ||
      this.getFilter('tag') ||
      this.getFilter('author');
    const checked: number[] = actionsStore.selectedIds.Prototype;

    return this.renderPage(
      <>
        <ContentWrapper>
          {(prototypesStore.isListDeleting || form.loading) && <Loading />}

          {!showRecommendations && (
            <TagList>
              <TagSelectInput
                label={intl.formatMessage({ id: 'Sort by' })}
                value={this.getFilter('sort')}
                onChange={(e) => this.setFilter('sort', e.target.value, false)}
              >
                <option value="newest">
                  {intl.formatMessage({ id: 'Newest' })}
                </option>
                <option value="oldest">
                  {intl.formatMessage({ id: 'Oldest' })}
                </option>
                <option value="" disabled={true} />
                <option value="bookmarks_count">
                  {intl.formatMessage({ id: 'Most bookmarks' })}
                </option>
                <option value="comments_count">
                  {intl.formatMessage({ id: 'Most comments' })}
                </option>
                <option value="" disabled={true} />
                <option value="average_target_group_relevance">
                  {intl.formatMessage({ id: 'Best target group relevance' })}
                </option>
                <option value="average_revenue_potential">
                  {intl.formatMessage({ id: 'Best revenue potential' })}
                </option>
                <option value="average_cost_efficiency">
                  {intl.formatMessage({ id: 'Best cost efficiency' })}
                </option>
                <option value="average_differentiation_degree">
                  {intl.formatMessage({ id: 'Best differentiation degree' })}
                </option>
              </TagSelectInput>

              <TagSelectInput
                name="tag"
                value={this.getFilter('tag')}
                onChange={(e) => this.setFilter('tag', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All hashtags' })}
                </option>
                <option value="" disabled={true} />
                {prototypesStore.tags.map((tag) => (
                  <option value={tag} key={tag}>
                    #{tag}
                  </option>
                ))}
              </TagSelectInput>

              <TagSelectInput
                name="state"
                value={this.getFilter('state')}
                onChange={(e) => this.setFilter('state', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All types' })}
                </option>
                <option value="" disabled={true} />
                <option value="active">
                  {intl.formatMessage({ id: 'Published' })}
                </option>
                <option value="draft">
                  {intl.formatMessage({ id: 'Drafts' })}
                </option>
              </TagSelectInput>

              <TagSelectInput
                name="author"
                value={this.getFilter('author')}
                onChange={(e) => this.setFilter('author', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All persons' })}
                </option>
                <option value="" disabled={true} />
                {prototypesStore.authors.map((author) => (
                  <option value={author.id} key={author.id}>
                    {author.name}
                  </option>
                ))}
              </TagSelectInput>
            </TagList>
          )}

          <ColumnWrapper gap="3em">
            <LinkRow links={this.links()} />

            {!showRecommendations && hasFilter && (
              <CardWrapper>
                <DestroyButton
                  label={intl.formatMessage({ id: 'Reset filters' })}
                  iconName="cross"
                  onClick={() => this.resetFilters()}
                />
                <FormattedMessage
                  id="prototype filter label"
                  values={{ count: list.length }}
                />
              </CardWrapper>
            )}

            {!!showRecommendations && (
              <CardWrapper>
                <RowWrapper gap="1em">
                  <LabeledIcon
                    icon="network"
                    label={intl.formatMessage({ id: 'Network' })}
                    opacity={0.5}
                  />
                  <div
                    style={
                      {
                        color: 'var(--text-color-secondary)'
                      } as React.CSSProperties
                    }
                  >
                    {intl.formatMessage({
                      id: 'Recommendations info prototypes'
                    })}
                  </div>
                </RowWrapper>
              </CardWrapper>
            )}

            {list.map((element: PrototypeModelType) => {
              const mayEdit =
                !showRecommendations &&
                (isOrgAdmin || authorIdFromModel(element) === currentUserId);
              const editPath = element.publish_state === 'draft' ? '/edit' : '';

              return (
                <PrototypesListItem
                  prototype={element}
                  key={element.id}
                  fromList={!showRecommendations}
                  readOnly={showRecommendations}
                  checked={checked.indexOf(element.id) > -1}
                  onChange={(c) => this.checkChanged(element, c)}
                  mayEdit={mayEdit}
                  onEditClick={() =>
                    this.props.history.push(
                      'prototypes/' + element.id + editPath
                    )
                  }
                  onRemoveClick={() => this.deletePrototypes([element.id])}
                  top={
                    showRecommendations && (
                      <OrgCardTop org={element.project?.organization} />
                    )
                  }
                />
              );
            })}

            {!!showRecommendations && (
              <div className="margin-auto-h">
                <Link className="main-button" to="/app/network?type=prototype">
                  {intl.formatMessage({
                    id: 'More recommendations in network'
                  })}
                </Link>
              </div>
            )}
          </ColumnWrapper>
        </ContentWrapper>

        {!prototypesStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              showRecommendations ? dataStore.currentOrganizationId : undefined
            }
            copyInitiallySelectedProjectId={
              showRecommendations ? dataStore.currentProjectId : undefined
            }
            showCopy={true}
            showAiBenchmark={true}
            showAiBriefing={true}
            showDelete={
              !showRecommendations &&
              actionsStore.selectedFromProjectIds.length === 1 &&
              actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={!showRecommendations}
          />
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(<Loading />);
  }

  renderPage(content: any) {
    return (
      <>
        <PageHeader
          titleId="Prototypes"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={<FormattedMessage id="prototype list header" />}
              subtitle={<FormattedMessage id="prototype list caption" />}
              list={true}
            />
          }
        />
        {content}
      </>
    );
  }

  render() {
    const { contentListStore, prototypesStore } = this.props;

    if (prototypesStore.isListLoading || contentListStore.isListLoading) {
      return this.renderLoading();
    }

    if (prototypesStore.isListError) {
      return this.renderPage(
        <ErrorMessage
          state={this.props.prototypesStore.listLoadingState}
          onRetry={() => this.loadPrototypes()}
        />
      );
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicPrototypesListScreenProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <PrototypesListScreen {...props} form={form} />;
});
