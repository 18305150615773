import { types } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';
import ModuleStateEnumModel, {
  createModuleStateEnumModel
} from './ModuleStateEnumModel';
import OrgAccessLevelEnumModel, {
  createOrgAccessLevelEnumModel
} from './OrgAccessLevelEnumModel';
import ProjectLanguageTypeEnum from './ProjectLanguageEnum';

export const OrganizationModel = types
  .model({
    id: types.identifierNumber,
    name: types.maybe(types.string),
    sector: types.maybe(types.string),
    description: types.maybe(types.string),
    target_group: types.maybe(types.string),
    service: types.maybe(types.string),
    access_level: types.maybe(OrgAccessLevelEnumModel),
    is_agency: types.maybe(types.boolean),
    agency_id: types.maybe(types.number),
    last_activity: types.number,
    project_count: types.number,
    network_enabled: types.maybe(types.boolean),
    ai_enabled: types.maybe(types.boolean),
    project_limit: types.maybe(types.number),
    cluster_limit: types.maybe(types.number),
    painpoint_limit: types.maybe(types.number),
    benchmark_limit: types.maybe(types.number),
    hypothesis_limit: types.maybe(types.number),
    prototype_limit: types.maybe(types.number),
    default_net_project_state: types.maybe(ModuleStateEnumModel),
    default_net_painpoints_state: types.maybe(ModuleStateEnumModel),
    default_net_benchmarks_state: types.maybe(ModuleStateEnumModel),
    default_net_hypotheses_state: types.maybe(ModuleStateEnumModel),
    default_net_prototypes_state: types.maybe(ModuleStateEnumModel),
    default_net_learnings_state: types.maybe(ModuleStateEnumModel),
    attachments: AttachmentsMapModel,
    default_language: types.maybe(ProjectLanguageTypeEnum)
  })
  .views((self) => ({
    get reachedProjectLimit(): boolean {
      return !!self.project_limit && self.project_count! >= self.project_limit!;
    }
  }));

export const createOrganizationModel = (data?: any): OrganizationModelType => {
  return OrganizationModel.create({
    id: mandatoryId(data?.id),
    name: data?.name || undefined,
    sector: data?.sector || undefined,
    description: data?.description || undefined,
    access_level: createOrgAccessLevelEnumModel(data?.access_level),
    is_agency: data?.is_agency || false,
    agency_id: data?.agency_id || undefined,
    last_activity: data?.last_activity,
    project_count: data?.project_count,
    network_enabled: booleanOrUndefined(data?.network_enabled),
    ai_enabled: booleanOrUndefined(data?.ai_enabled),
    project_limit: numberOrUndefined(data?.project_limit),
    cluster_limit: numberOrUndefined(data?.cluster_limit),
    painpoint_limit: numberOrUndefined(data?.painpoint_limit),
    benchmark_limit: numberOrUndefined(data?.benchmark_limit),
    hypothesis_limit: numberOrUndefined(data?.hypothesis_limit),
    prototype_limit: numberOrUndefined(data?.prototype_limit),
    default_net_project_state: createModuleStateEnumModel(
      data?.default_net_project_state
    ),
    default_net_painpoints_state: createModuleStateEnumModel(
      data?.default_net_painpoints_state
    ),
    default_net_benchmarks_state: createModuleStateEnumModel(
      data?.default_net_benchmarks_state
    ),
    default_net_hypotheses_state: createModuleStateEnumModel(
      data?.default_net_hypotheses_state
    ),
    default_net_prototypes_state: createModuleStateEnumModel(
      data?.default_net_prototypes_state
    ),
    default_net_learnings_state: createModuleStateEnumModel(
      data?.default_net_learnings_state
    ),
    attachments: createAttachmentsMap(data?.attachments),
    default_language: ProjectLanguageTypeEnum.is(data.default_language)
      ? data.default_language
      : undefined
  });
};

export const FluidOrganizationModel = types
  .model({
    id: types.maybe(types.number),
    name: types.maybe(types.string),
    sector: types.maybe(types.string),
    description: types.maybe(types.string),
    target_group: types.maybe(types.string),
    service: types.maybe(types.string),
    is_agency: types.maybe(types.boolean),
    last_activity: types.number,
    project_count: types.number,
    agency_id: types.maybe(types.number),
    access_level: types.maybe(OrgAccessLevelEnumModel),
    network_enabled: types.maybe(types.boolean),
    ai_enabled: types.maybe(types.boolean),
    project_limit: types.maybe(types.number),
    cluster_limit: types.maybe(types.number),
    painpoint_limit: types.maybe(types.number),
    benchmark_limit: types.maybe(types.number),
    hypothesis_limit: types.maybe(types.number),
    prototype_limit: types.maybe(types.number),
    default_net_project_state: types.maybe(ModuleStateEnumModel),
    default_net_painpoints_state: types.maybe(ModuleStateEnumModel),
    default_net_benchmarks_state: types.maybe(ModuleStateEnumModel),
    default_net_hypotheses_state: types.maybe(ModuleStateEnumModel),
    default_net_prototypes_state: types.maybe(ModuleStateEnumModel),
    default_net_learnings_state: types.maybe(ModuleStateEnumModel),
    attachments: AttachmentsMapModel,
    default_language: types.maybe(ProjectLanguageTypeEnum)
  })
  .views((self) => ({
    get reachedProjectLimit(): boolean {
      return !!self.project_limit && self.project_count! >= self.project_limit!;
    }
  }));

export const createFluidOrganizationModel = (
  data?: any
): FluidOrganizationModelType => {
  return FluidOrganizationModel.create({
    id: numberOrUndefined(data?.id),
    name: data?.name || undefined,
    sector: data?.sector || undefined,
    description: data?.description || undefined,
    target_group: data?.target_group || undefined,
    service: data?.service || undefined,
    is_agency: booleanOrUndefined(data?.is_agency),
    agency_id: data?.agency_id || undefined,
    last_activity: data?.last_activity || 0,
    project_count: data?.project_count || 0,
    access_level: createOrgAccessLevelEnumModel(data?.access_level),
    network_enabled: booleanOrUndefined(data?.network_enabled),
    ai_enabled: booleanOrUndefined(data?.ai_enabled),
    project_limit: numberOrUndefined(data?.project_limit),
    cluster_limit: numberOrUndefined(data?.cluster_limit),
    painpoint_limit: numberOrUndefined(data?.painpoint_limit),
    benchmark_limit: numberOrUndefined(data?.benchmark_limit),
    hypothesis_limit: numberOrUndefined(data?.hypothesis_limit),
    prototype_limit: numberOrUndefined(data?.prototype_limit),
    default_net_project_state: createModuleStateEnumModel(
      data?.default_net_project_state
    ),
    default_net_painpoints_state: createModuleStateEnumModel(
      data?.default_net_painpoints_state
    ),
    default_net_benchmarks_state: createModuleStateEnumModel(
      data?.default_net_benchmarks_state
    ),
    default_net_hypotheses_state: createModuleStateEnumModel(
      data?.default_net_hypotheses_state
    ),
    default_net_prototypes_state: createModuleStateEnumModel(
      data?.default_net_prototypes_state
    ),
    default_net_learnings_state: createModuleStateEnumModel(
      data?.default_net_learnings_state
    ),
    attachments: createAttachmentsMap(data?.attachments),
    default_language: ProjectLanguageTypeEnum.is(data.default_language)
      ? data.default_language
      : undefined
  });
};

export type FluidOrganizationModelType = typeof FluidOrganizationModel.Type;

export type OrganizationModelType = typeof OrganizationModel.Type;
export default OrganizationModel;
