interface ProjectTypeProps {
  strategyTypes: {id: string, type: string, description: string, icon: string}[];
}

export default function ProjectType({ strategyTypes }: ProjectTypeProps) {

  const selectItem = (e: any) => {
    document.querySelectorAll(".project-type-card").forEach((card) => {
      card.classList.remove("selected")
    })
    e.target.parentNode.classList.add("selected")
  }

  return (
    <div className="project-type" style={{}}>
      {strategyTypes.map((strategyType) => (
        <div key={strategyType.id} className="project-type-card">
          <input type="radio" id={strategyType.id} name="selectedStrategyType" value={strategyType.id} onChange={selectItem}/>
          <label htmlFor={strategyType.id}>
            <div className="project-type-body">
              <img src={strategyType.icon} />
              <div style={{fontWeight: "bold", fontStyle: "italic", textAlign: "center"}}>
                {strategyType.type}
              </div>
              <p style={{fontSize: "smaller"}}>{strategyType.description}</p>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
}
