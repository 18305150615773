// @ts-ignore
// Lightbox:
// https://github.com/banthagroup/fslightbox-react/
// Unfortunatly has no types. Usage is minimal, so probably not an issue for now.
import FsLightbox from 'fslightbox-react';
import { Component } from 'react';

type Source = string | undefined;

interface Properties {
  toggler: boolean;
  type: string;
  sources: Source[];
  source: Source;
}

interface State {
  isOpen?: boolean;
}

const wheelOptions = { passive: false } as EventListenerOptions;
const preventDefault = (event: Event) => event.preventDefault();

export default class Lightbox extends (FsLightbox as new () => Component<
  Properties,
  State
>) {
  componentDidUpdate(prevProps: Properties, prevState: State) {
    super.componentDidUpdate?.apply(this, arguments as any);

    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        window.addEventListener('wheel', preventDefault, wheelOptions);
        window.addEventListener('touchmove', preventDefault, wheelOptions);
      } else {
        window.removeEventListener('wheel', preventDefault, wheelOptions);
        window.removeEventListener('touchmove', preventDefault, wheelOptions);
      }
    }
  }
}
