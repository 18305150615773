import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';

const values = ['draft', 'active'];

export const EditingStateEnum = types.enumeration(values);

export const createEditingState = (
  value: any
): EditingStateEnumType | undefined =>
  values.includes(value) ? value : undefined;

export type EditingStateEnumType = typeof EditingStateEnum.Type;

export const AssistantSessionSolutionModel = types.model(
  'AssistantSessionSolutionModel',
  {
    id: types.identifierNumber,
    benchmark_id: types.maybe(types.number),
    // benchmark: types.maybe(BenchmarkModel),
    description: types.maybe(types.string),
    editing_state: types.maybe(EditingStateEnum),
    attachments: AttachmentsMapModel,

    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string)
  }
);

export const createAssistantSessionSolutionModel = (
  data: any
): AssistantSessionSolutionModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return AssistantSessionSolutionModel.create({
    id: mandatoryId(data.id),
    benchmark_id: numberOrUndefined(data.benchmark_id),
    // benchmark: createBenchmarkModel(data?.benchmark),
    description: data.description || undefined,
    editing_state: createEditingState(data?.editing_state),
    attachments: createAttachmentsMap(data?.attachments),

    created_at: data.created_at || undefined,
    updated_at: data.updated_at || undefined
  });
};

export type AssistantSessionSolutionModelType = typeof AssistantSessionSolutionModel.Type;
export default AssistantSessionSolutionModel;
