import classNames from 'classnames';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface InputButtonProps extends HtmlButtonProps {
  secondary?: boolean;
  className?: string;
}

export default function InputButton(props: InputButtonProps) {
  const inputButtonClasses = classNames(props.className, 'input-button', {
    'input-button--secondary': props.secondary
  });

  return (
    <button
      {...{ ...props, secondary: undefined }}
      className={inputButtonClasses}
    >
      {props.children}
    </button>
  );
}
