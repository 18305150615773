export const scrollTo = (elem: any) => {
  if (!elem) {
    return;
  }

  elem.scrollIntoView({ block: 'start', behavior: 'instant' });
};

export const scrollToElem = (querySelector: string) => {
  scrollTo(document.querySelector(querySelector));
};

export const scrollToTop = () => {
  window.scroll(0, 0);
};

export default scrollTo;
