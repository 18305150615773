const SHORTEN_ADD = '…';

export const shortenString = (
  str?: string,
  maxLength?: number,
  add: boolean | string = true
) => {
  if (!str || !maxLength) {
    return str;
  }

  str = str.toString();
  if (str.length <= maxLength) {
    return str;
  }

  if (!Array.from) {
    return str.substring(0, maxLength);
  }

  const arr = Array.from(str);
  if (arr.length <= maxLength) {
    return str;
  }

  str = arr.slice(0, maxLength).join('');
  if (!add) {
    return str;
  }

  return str + (add === true ? SHORTEN_ADD : add);
};

export default shortenString;
