import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import ProjectAccessLevelEnumModel, {
  createProjectAccessLevelEnumModel
} from './ProjectAccessLevelEnumModel';
import UserModel, { UserModelType } from './UserModel';

export const ProjectMemberModel = types
  .model('ProjectMemberModel', {
    id: types.identifierNumber,
    access_level: types.maybe(ProjectAccessLevelEnumModel),
    user: types.maybe(
      types.reference(types.late((): IAnyModelType => UserModel))
    )
  })
  .actions(self => {
    const setUser = (user?: UserModelType) => {
      self.user = user;
    };

    return {
      setUser
    };
  });

export const createProjectMemberModel = (data?: any): ProjectMemberModelType | undefined => {
  if (!data?.user?.id) {
    return undefined;
  }

  return ProjectMemberModel.create({
    id: mandatoryId(data.user.id),
    access_level: createProjectAccessLevelEnumModel(data.access_level),
    user: undefined
  });
};

export type ProjectMemberModelType = typeof ProjectMemberModel.Type;
export default ProjectMemberModel;
