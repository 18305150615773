import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';
import DestroyButton from 'components/DestroyButton';

interface RainbowCardSmallProps {
  children?: React.ReactNode;
  to?: LinkProps['to'];
  checked?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
  onRemoveClick?: () => void;
  onClick?: (e: any) => void;
  src?: string;
  alt?: string;
  gray?: boolean;
  sideLabel?: string;
}

export const RainbowCardSmall = ({
  children,
  to,
  src,
  alt = 'screenshot',
  checked,
  onCheckboxChange,
  onRemoveClick,
  onClick,
  sideLabel,
  gray
}: RainbowCardSmallProps) => {
  const rainbowCardClasses = classNames('rainbow-card-small', {
    'rainbow-card-small--gray': gray,
    'rainbow-card-small--with-image': !!src,
    'rainbow-card-small--draft': !!sideLabel
  });

  const renderScreenInPhone = () => {
    if (!src) {
      return null;
    }

    return (
      <div className="rainbow-card-small__image">
        <figure className="rainbow-card-small__phone">
          <img alt={alt} src={src} />
        </figure>
      </div>
    );
  };

  // eslint-disable-next-line
  const cardLink = onClick ? (
    <a className="rainbow-card-small__link" onClick={onClick} />
  ) : to ? (
    <Link className="rainbow-card-small__link" to={to} />
  ) : null;

  const renderDraft = () => {
    if (!sideLabel) {
      return null;
    }
    return <div className="rainbow-card-small__draft">{sideLabel}</div>;
  };

  const renderCheckbox = () => {
    if (!onCheckboxChange) {
      return null;
    }
    return (
      <div className="card-checkbox">
        <label>
          <input
            className="card-checkbox__box"
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
            type="checkbox"
          />
        </label>
      </div>
    );
  };

  return (
    <article className={rainbowCardClasses}>
      {renderScreenInPhone()}
      <div className="rainbow-card-small__body">
        {onRemoveClick && (
          <div className="rainbow-card-small__remove-button">
            <DestroyButton onClick={onRemoveClick} />
          </div>
        )}
        {children}
        {renderCheckbox()}
        {cardLink}
      </div>
      {renderDraft()}
    </article>
  );
};

export default RainbowCardSmall;
