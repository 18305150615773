import UserAvatar from 'components/Avatar/UserAvatar';
import ErrorMessage from 'components/ErrorMessage';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import ProfileLayout from 'components/ProfileLayout';
import ListDetailContainer from 'containers/list/ListDetailContainer';
import ListOverviewContainer from 'containers/list/ListOverviewContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import {
  ContentListStoreType,
  SearchableElementType
} from 'models/ContentListStore';
import { DataStoreType } from 'models/DataStore';
import React from 'react';
import { ROUTE_AUTH } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface UserBookmarksListProps extends HistoryProps {
  contentListStore: ContentListStoreType;
  dataStore: DataStoreType;
}

type ValidQueryTypes =
  | 'briefing'
  | 'painpoint'
  | 'benchmark'
  | 'hypothesis'
  | 'prototype';

@inject('contentListStore', 'dataStore')
@observer
class UserBookmarksList extends React.Component<UserBookmarksListProps> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: HistoryProps) {
    if (this.getElementType() !== this.getElementType(prevProps)) {
      this.load();
    }
  }

  load() {
    this.getBookmarks(this.props?.location?.query?.page);
  }

  getElementType(props?: HistoryProps): SearchableElementType | undefined {
    if (!props) {
      props = this.props;
    }
    const elementType = props.location?.query?.type;
    if (!elementType) {
      return undefined;
    }

    const elementTypeMapping: Map<ValidQueryTypes, SearchableElementType> =
      new Map([
        ['briefing', ElementType.Briefing],
        ['painpoint', ElementType.Painpoint],
        ['benchmark', ElementType.Benchmark],
        ['hypothesis', ElementType.Hypothesis],
        ['prototype', ElementType.Prototype]
      ]);
    return elementTypeMapping.get(elementType);
  }

  pluralizeElementType(elementType?: string, lowerCase = false) {
    if (!elementType) {
      return;
    }
    // @ts-ignore
    const type = {
      briefing: 'Briefings',
      painpoint: 'Painpoints',
      benchmark: 'Benchmarks',
      hypothesis: 'Hypotheses',
      prototype: 'Prototypes'
    }[elementType.toLowerCase()];
    // @ts-ignore
    return lowerCase ? type.toLowerCase() : type;
  }

  getBookmarks(page?: number) {
    const { contentListStore } = this.props;
    const elementType = this.getElementType();

    contentListStore.getBookmarkedElements(elementType, page);
  }

  loadMore() {
    const elementType = this.getElementType();
    if (!elementType) {
      return;
    }

    const { contentListStore } = this.props;

    this.getBookmarks(contentListStore.pageOf(elementType) + 1);
  }

  renderPage(content: any) {
    const { dataStore } = this.props;
    const user = dataStore.currentUser;

    // TODO: Use right SVG
    return (
      <ProfileLayout
        active="bookmarks"
        userName={user?.fullName}
        userId={user?.id}
        isSelf={true}
      >
        <PageHeader
          titleId={
            this.pluralizeElementType(this.getElementType()) || 'Bookmarks'
          }
          headline={user?.fullName}
          avatar={
            user && (
              <UserAvatar
                user={dataStore.user}
                url={ROUTE_AUTH + '/profile/' + user.id + '/settings'}
              />
            )
          }
          bold={true}
          mail={user?.email}
          center={true}
        />
        {content}
      </ProfileLayout>
    );
  }

  renderOverview() {
    const { contentListStore, dataStore } = this.props;
    if (contentListStore.isListLoading) {
      return this.renderLoading();
    }

    return this.renderPage(
      <ContentWrapper>
        <ListOverviewContainer
          type="bookmark"
          headerType="bookmark"
          baseUrl={'/app/profile/' + dataStore.user!.id + '/bookmarks'}
          calculateLimit={(type) => (type === 'painpoint' ? 6 : 3)}
          onRetry={() => this.getBookmarks()}
        />
      </ContentWrapper>
    );
  }

  renderDetail() {
    const elementType = this.getElementType();
    if (!elementType) {
      return null;
    }

    const { contentListStore } = this.props;

    let loadingMore = false;
    if (contentListStore.isListLoading) {
      if (contentListStore.hasAnyOf(elementType)) {
        loadingMore = true;
      } else {
        return this.renderLoading();
      }
    }

    return this.renderPage(
      <ContentWrapper>
        {/* TODO: Maybe add a link back to the Overview here? */}
        <ListDetailContainer
          type="bookmark"
          headerType="bookmark"
          elementType={elementType}
          loadingMore={loadingMore}
          onLoadMore={() => this.loadMore()}
          onRetry={() => this.getBookmarks()}
        />
      </ContentWrapper>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }
  renderError() {
    return this.renderPage(
      <ErrorMessage
        state={this.props.contentListStore.listLoadingState}
        onRetry={() => this.load()}
      />
    );
  }

  render() {
    if (this.props.contentListStore.isListError) {
      return this.renderError();
    }
    if (this.getElementType()) {
      // detail page
      return this.renderDetail();
    }

    // overview page
    return this.renderOverview();
  }
}

export default (props: HistoryProps) => {
  // @ts-ignore
  return <UserBookmarksList {...props} />;
};
