import classNames from 'classnames';
import SuggestedSearch from 'components/SuggestedSearch';
import SuggestedSearchItem from 'components/SuggestedSearch/SuggestedSearchItem';
import React from 'react';
import InputButton from './InputButton';

import InputInterface, { cleanupInputProps } from './InputInterface';
import InputStateContainer from './InputStateContainer';

export type HtmlInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface TextInputProps {
  headline?: boolean;
  subheadline?: boolean;
  suggested?: boolean;
  suggestions?: Array<{ id: number; name: string; active: boolean }>;
  button?: React.ReactNode | string;
  active?: boolean;
  normalFont?: boolean;
  onSuggestionClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
    name: string
  ) => void;
  showSuccess?: boolean;
}

export default React.forwardRef(
  (props: HtmlInputProps & InputInterface & TextInputProps, ref: any) => {
    const inputClasses = classNames('edit-input__input', {
      'edit-input__input--gap': props.gap,
      'edit-input__input--simple-headline': props.headline,
      'edit-input__input--simple-subheadline': props.subheadline,
      'edit-input__input--normal-font': props.normalFont
    });

    const id = props.id ? props.id : '__formfield_' + props.name;
    return (
      <div
        className={
          'edit-input' + (props.showSuccess ? ' edit-input--success' : '')
        }
      >
        {props.label && <label htmlFor={id}>{props.label}</label>}
        <InputStateContainer error={props.error} success={props.success}>
          <input
            id={id}
            ref={ref}
            className={inputClasses}
            type={props.type || 'text'}
            {...cleanupInputProps(props)}
          />
          {props.button && (
            <InputButton disabled={props.active} type="submit">
              {props.button}
            </InputButton>
          )}
          {props.suggested && (
            <SuggestedSearch>
              {props.suggestions?.map &&
                props.suggestions?.map(
                  (suggestion: {
                    id: number;
                    name: string;
                    active: boolean;
                  }) => (
                    <SuggestedSearchItem
                      key={suggestion.id}
                      value={suggestion.name}
                      active={suggestion.active}
                      onClick={(e) =>
                        props.onSuggestionClick &&
                        props.onSuggestionClick(
                          e,
                          suggestion.id,
                          suggestion.name
                        )
                      }
                    />
                  )
                )}
            </SuggestedSearch>
          )}
        </InputStateContainer>
      </div>
    );
  }
);
