import { FormattedMessage } from 'react-intl';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import SidebarItem from '../SidebarItem';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';
import { observer } from 'mobx-react';
import CardHeader from 'components/CardHeader';

function SidebarPrototypeDescription({ session }: SidebarItemProps) {
  return (
    <SidebarMarker type="prototype_description">
      <SidebarItem>
        <SidebarItemHeader>
          <FormattedMessage id="assistant sidebar prototype description" />
        </SidebarItemHeader>

        <RainbowCard
          top={true}
          shrink={true}
          unresponsive={true}
          to={{
            pathname:
              ROUTE_ASSISTANT +
              '/prototypes/' +
              session!.id +
              ASSISTANTS.prototype_sprint.step5.route,
            state: {
              canGoBack: true
            }
          }}
        >
          <CardHeader title={session?.prototype_headline} />
          <Typography size="small">{session?.prototype_description}</Typography>
        </RainbowCard>
      </SidebarItem>
    </SidebarMarker>
  );
}

export default observer(SidebarPrototypeDescription);
