import { inject, observer } from 'mobx-react';
import React from 'react';

import AppLayout from 'components/AppLayout';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { UserModelType } from 'models/UserModel';

interface PublicUserContainerProps {
  userId: number;
  loaderComponent?: React.ComponentType<any>;
  errorComponent?: React.ComponentType<any>;
  children?: React.ReactNode;
}

interface UserContainerProps {
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore', 'dataStore')
@observer
class UserContainer extends React.Component<
  UserContainerProps & PublicUserContainerProps
> {
  convertId(id: any): number {
    if (!id) {
      return this.props.dataStore.user!.id;
    }

    return typeof id === 'number' ? id : parseInt(id, 10);
  }

  getUser(): UserModelType | undefined {
    return this.props.dataStore.users.get(this.props.userId.toString());
  }

  async loadUser() {
    const { dataStore, userId } = this.props;

    const user = await this.props.applicationStore.getUser(userId);
    if (user) {
      dataStore.addUser(user);
    }
  }

  componentDidMount() {
    const user = this.getUser();

    if (!user) {
      this.loadUser();
    }
  }

  componentDidUpdate(prevProps: UserContainerProps & PublicUserContainerProps) {
    if (prevProps.userId !== this.props.userId) {
      if (!this.getUser()) {
        this.loadUser();
      }
    }
  }

  renderUserNotPresent() {
    const { applicationStore, errorComponent, loaderComponent } = this.props;

    if (applicationStore.userLoadingState !== 'loading') {
      if (errorComponent) {
        return React.createElement(errorComponent, {
          error: applicationStore.userLoadingState,
          onRetry: () => this.loadUser()
        });
      }

      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable-next-line: no-console
        console.error('Warning: UserContainer has no errorComponent prop!');
      }
      return null;
    }

    if (loaderComponent) {
      return React.createElement(loaderComponent);
    }

    if (process.env.NODE_ENV !== 'production') {
      // tslint:disable-next-line: no-console
      console.error('Warning: UserContainer has no loaderComponent prop!');
    }
    return null;
  }

  renderLayout(content: any, active?: 'profile' | 'lab') {
    return <AppLayout active={active}>{content}</AppLayout>;
  }

  render() {
    const user = this.getUser();

    if (!user) {
      return this.renderLayout(this.renderUserNotPresent());
    }

    return this.renderLayout(
      this.props.children,
      user.id === this.props.dataStore.currentUserId ? 'profile' : 'lab'
    );
  }
}

export default (props: PublicUserContainerProps) => (
  // @ts-ignore
  <UserContainer {...props} />
);
