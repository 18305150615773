import { inject, observer } from 'mobx-react';
import React from 'react';

import AppLayout from 'components/AppLayout';
import CompanyLogo from 'components/CompanyLogo';
import ContentWrapper from 'components/Layout/ContentWrapper';
import ContextAwareOrgLayout from 'components/OrgSettingsLayout/ContextAwareOrgLayout';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import OrgMembersContainer from 'containers/OrgMembersContainer';
import { DataStoreType } from 'models/DataStore';
import { HistoryProps } from 'utils/history';

// tslint:disable-next-line: no-empty-interface
interface PublicOrgMembersScreenProps {}

interface OrgMembersScreenProps extends PublicOrgMembersScreenProps {
  dataStore: DataStoreType;
}

@inject('dataStore', 'organizationsStore', 'applicationStore')
@observer
class OrgMembersScreen extends React.Component<
  OrgMembersScreenProps & HistoryProps
> {
  render() {
    const { dataStore, location } = this.props;
    const org = dataStore.currentOrganization;

    let header;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    const isPending = location.query?.display === 'pending';
    const filter = isPending ? 'pending' : 'active';

    return (
      <AppLayout active="settings" withSettings={true}>
        <ContextAwareOrgLayout active="members">
          <PageHeader titleId="Settings" logoHeader={header} center={true} />

          <ContentWrapper small={true}>
            <OrgMembersContainer filter={filter} />
          </ContentWrapper>
        </ContextAwareOrgLayout>
      </AppLayout>
    );
  }
}

export default (props: PublicOrgMembersScreenProps) => {
  // @ts-ignore
  return <OrgMembersScreen {...props} />;
};
