import { FormattedMessage } from 'react-intl';

import AssistantGif from 'domain/assistant/AssistantGif';
import { SessionMainText } from 'domain/assistant/session-elements';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import ExplanationScreen from '../ExplanationScreen';

export const PainpointDoneScreen = ({ location }: HistoryProps) => (
  <ExplanationScreen
    barProgress={100}
    header={<FormattedMessage id="assistant question header" />}
    headerStep={ASSISTANTS.creative_session.step1_4.header}
    sidebarHeadline={ASSISTANTS.creative_session.step1_4.header}
    buttonTextId="assistant finish button"
    nextRoute={ROUTE_ASSISTANT}
    nextRouteIsAbsolute={true}
    sidebarScrollTo="painpoint_question"
    location={location}
  >
    <SessionMainText>
      <FormattedMessage id="assistant painpoint done" />
    </SessionMainText>

    <AssistantGif />
  </ExplanationScreen>
);

export default PainpointDoneScreen;
