
import Typography from 'components/Typography';
import config from 'config';
import { FormattedMessage } from 'react-intl';

export const LoginForm = ({ children }: { children: any }) => (
  <section className="login-screen">
    <div className="login-screen__logo">
      <img src="/images/logo_white512.png" alt="DISROOPTIVE" />
    </div>

    <div className="login-form">{children}</div>

    <Typography size="small">
      <a href={config.legalUrl} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="Legal" />
      </a>{' '}
      |{' '}
      <a href={config.privacyUrl} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="Privacy" />
      </a>
    </Typography>
  </section>
);

LoginForm.Input = ({ children }: { children: any }) => (
  <div className="login-form__input">{children}</div>
);

LoginForm.Error = ({ children }: { children: any }) => (
  <div className="login-form__error">
    <em>{children}</em>
  </div>
);

export default LoginForm;
