import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const PainpointAimModel = types.model('PainpointAimModel', {
  id: types.identifierNumber,
  author: types.maybe(FluidUserModel),
  aim: types.maybe(types.string),
  created_at: types.maybe(types.string)
});

export const createPainpointAimModel = (data?: any): PainpointAimModelType => {
  return {
    id: mandatoryId(data?.id),
    author: data?.author ? createFluidUserModel(data.author) : undefined,
    aim: data?.aim || undefined,
    created_at: data?.created_at || undefined
  };
};

export type PainpointAimModelType = typeof PainpointAimModel.Type;
export default PainpointAimModel;
