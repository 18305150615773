import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import LoginForm from 'components/LoginForm';
import MainButton from 'components/MainButton';
import Typography from 'components/Typography';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_HOME } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface ActivateScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class ActivateScreen extends React.Component<
  ActivateScreenProps & HistoryProps
> {
  async componentDidMount() {
    const { applicationStore, match } = this.props;
    const token = match.params?.token;

    if (!token) {
      this.props.history.replace(ROUTE_HOME);
      return;
    }

    await applicationStore.verifyEmailAddress(token);
  }

  render() {
    const state = this.props.applicationStore.authState.verifyEmailState;

    if (!state || state === 'loading') {
      return <Loading />;
    }

    let content;

    switch (state) {
      case 'finished':
        content = <FormattedMessage id="email verify finished" />;
        break;

      case 'token_invalid':
        content = <FormattedMessage id="email verify token invalid" />;
        break;

      case 'error':
        content = <FormattedMessage id="email verify error" />;
        break;

      default:
        return <Loading />;
    }

    return (
      <LoginForm>
        <CardWrapper>
          <Typography>{content}</Typography>

          <RowWrapper gap="1em">
            <PositionWrapper center={true}>
              <MainButton
                onClick={() => this.props.history.replace(ROUTE_HOME)}
              >
                <FormattedMessage id="OK" />
              </MainButton>
            </PositionWrapper>
          </RowWrapper>
        </CardWrapper>
      </LoginForm>
    );
  }
}

export default (props: HistoryProps) => {
  // @ts-ignore
  return <ActivateScreen {...props} />;
};
