import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';

interface SubNavItemProps {
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  logo?: React.ReactNode;
  active?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  to?: LinkProps['to'];
  header?: boolean;
}

export default function SubNavItem({
  title,
  subtitle,
  logo,
  active,
  to,
  inactive,
  disabled,
  header
}: SubNavItemProps) {
  const subNavItemClasses = classNames('sub-nav__link', {
    'sub-nav__link--active': active,
    'sub-nav__link--inactive': disabled || (inactive && !active),
    'sub-nav__link--header': header
  });

  if (disabled || !to) {
    return (
      <li className="sub-nav__item">
        <div className={subNavItemClasses}>
          <div className="project-item">
            {logo && logo}
            <div className="project-item__body">
              {title && <h3 className="project-item__title">{title}</h3>}
              {subtitle && (
                <h4 className="project-item__subtitle">{subtitle}</h4>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li className="sub-nav__item">
      <Link to={to} className={subNavItemClasses}>
        <div className="company-nav-item">
          {logo && logo}
          <div className="company-nav-item__body">
            {title && <h3 className="project-item__title">{title}</h3>}
            {subtitle && <h4 className="project-item__subtitle">{subtitle}</h4>}
          </div>
        </div>
      </Link>
    </li>
  );
}
