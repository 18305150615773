import EditButton, { EditButtonProps } from 'components/EditButton/EditButton';
import EditMenu from 'components/EditMenu';
import Loading from 'components/Loading';
import MenuButton from 'components/MenuButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ItemLoadingStateEnumType } from 'models/LoadingStateEnums';
import { OrgAccessLevelEnumModelType } from 'models/OrgAccessLevelEnumModel';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { OrgMemberModelType } from 'models/OrgMemberModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';

import AccessLevelSelect from './AccessLevelSelect';

// tslint:disable-next-line: no-empty-interface
interface PublicOrgMemberRowProps extends WrappedComponentProps {
  member: OrgMemberModelType;
  pending?: boolean;
  onNameEditClick?: EditButtonProps['onClick'];
  onEmailEditClick?: EditButtonProps['onClick'];
  onDeleteClick?: EditButtonProps['onClick'];
}

interface OrgMemberRowProps extends PublicOrgMemberRowProps {
  // form: FormType;
  organizationsStore: OrganizationsStoreType;
  applicationStore: ApplicationStoreType;
}

interface OrgMemberRowState {
  loadingState?: ItemLoadingStateEnumType;
  newAccessLevel?: OrgAccessLevelEnumModelType;
}

@inject('organizationsStore', 'applicationStore')
@observer
class OrgMemberRow extends React.Component<
  OrgMemberRowProps,
  OrgMemberRowState
> {
  state: OrgMemberRowState = {};

  async save(level: OrgAccessLevelEnumModelType) {
    const { organizationsStore, member, applicationStore, intl } = this.props;

    if (!member || !member.id) {
      return;
    }

    this.setState({
      loadingState: 'saving'
    });

    try {
      await organizationsStore.createOrUpdateMembership(member.id, level);

      this.setState({
        loadingState: undefined,
        newAccessLevel: undefined
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'access level flash' })
      );
    } catch (error: any) {
      this.setState({
        loadingState: 'save_error',
        newAccessLevel: undefined
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'access level error flash' }),
        'error'
      );
    }
  }

  selectLevel(level: OrgAccessLevelEnumModelType) {
    this.setState({
      newAccessLevel: level
    });

    this.save(level);
  }

  async reinvite() {
    const { organizationsStore, member, applicationStore, intl } = this.props;

    if (!member || !member.id) {
      return;
    }

    this.setState({
      loadingState: 'saving'
    });

    try {
      await organizationsStore.reinviteMember(member.id);

      this.setState({
        loadingState: undefined
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'reinvite flash' })
      );
    } catch (error: any) {
      this.setState({
        loadingState: 'save_error'
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'reinvite error flash' }),
        'error'
      );
    }
  }

  render() {
    const {
      member,
      pending,
      intl,
      onNameEditClick,
      onEmailEditClick,
      onDeleteClick
    } = this.props;
    const { newAccessLevel, loadingState } = this.state;

    const accessLevel =
      newAccessLevel || member.access_level || ORG_ACCESS_LEVELS.DISABLED;

    // TODO proper loading state display
    const loading = loadingState === 'saving';

    let emailEdit;
    if (pending) {
      emailEdit = (
        <EditMenu topRight={true} label={intl.formatMessage({ id: 'Edit' })}>
          {onEmailEditClick && (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              iconName="pen"
              onClick={onEmailEditClick}
            />
          )}
          <MenuButton
            label={intl.formatMessage({ id: 'Resend invite' })}
            iconName="envelope"
            onClick={() => this.reinvite()}
          />
        </EditMenu>
      );
    } else if (onEmailEditClick) {
      emailEdit = (
        <EditButton
          label={intl.formatMessage({ id: 'Edit' })}
          onClick={onEmailEditClick}
        />
      );
    }

    return (
      <div key={member.id} className="member-table__row">
        <div className="member-table__name">
          {member.user?.fullName || (
            <em>
              <FormattedMessage id="no name" />
            </em>
          )}
          {onNameEditClick && (
            <EditButton
              label={intl.formatMessage({ id: 'Edit' })}
              onClick={onNameEditClick}
            />
          )}
        </div>
        <div className="member-table__email">
          {member.user?.email}
          {emailEdit}
        </div>
        <div className="member-table__access">
          <AccessLevelSelect
            name="level"
            value={accessLevel}
            disabled={loading}
            onChange={({ target }: { target: any }) =>
              this.selectLevel(target.value as OrgAccessLevelEnumModelType)
            }
          />
          {loading && <Loading />}
        </div>
        <div className="member-table__delete">
          {onDeleteClick && (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              iconName="bin"
              onClick={onDeleteClick}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl((props: PublicOrgMemberRowProps) => {
  // const form = useForm();
  // @ts-ignore
  return <OrgMemberRow {...props} />;
});
