import { FormattedMessage } from 'react-intl';

import UserAvatar from 'components/Avatar/UserAvatar';
import AvatarBubble from 'components/AvatarBubble/AvatarBubble';
import MainButton from 'components/MainButton';
import RainbowCard from 'components/RainbowCard';
import { AssistantSessionModelType } from 'models/AssistantSessionModel';
import { getProgressPercentage } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history } from 'utils/history';
import Icon from 'components/Icon';

const IconMapper = {
  painpoint_session: 'clipboard',
  benchmarks_sprint: 'detail',
  hypothesis_sprint: 'bulb',
  prototype_sprint: 'strategy',
  creative_session: ''
};

export const AssistantInvitationListItem = ({
  assistantSession
}: {
  assistantSession: AssistantSessionModelType;
}) => {
  const invite: AssistantSessionModelType = assistantSession;
  const progress = getProgressPercentage(
    assistantSession.progress,
    assistantSession.assistant_type
  );

  return (
    <RainbowCard gray={true}>
      <div className="invite-members__item">
        <div className="invite-members__top">
          <div className="invite-members__icon">
            <Icon
              name={
                IconMapper[
                  assistantSession.assistant_type as keyof typeof IconMapper
                ]
              }
              size="medium"
            />
          </div>
          <h2 className="invite-members__name">{invite.project?.name}</h2>
        </div>
        <AvatarBubble content={invite.invitation_text}>
          <UserAvatar user={invite.inviter} noName={true} noLink={true} />
        </AvatarBubble>

        {!invite.published && (
          <div style={{ marginTop: '1rem' }}>
            <MainButton
              // unstarted buttons should be fully filled
              progress={progress || 100}
              onClick={() =>
                history.push(ROUTE_ASSISTANT + '/' + assistantSession.id)
              }
            >
              <FormattedMessage
                id={
                  progress > 0 ? 'assistant continue session' : 'Start session'
                }
              />
            </MainButton>
          </div>
        )}
      </div>
    </RainbowCard>
  );
};

export default AssistantInvitationListItem;
