import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import BriefingElement from 'components/BriefingListElement';
import OrgCardTop from 'components/CardTop/OrgCardTop';
import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import FileWrapper from 'components/Layout/FileWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import SectionHeadline from 'components/SectionHeadline';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { BriefingModelType } from 'models/BriefingModel';
import {
  ContentListStoreType,
  SearchableElementType
} from 'models/ContentListStore';
import { BookmarkableEnumType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { LearningModelType } from 'models/LearningModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PrototypeModelType } from 'models/PrototypeModel';
import BenchmarksListItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksListItem';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import LearningsList from 'screens/learnings/LearningsListScreen/LearningsList';
import LearningsListItem from 'screens/learnings/LearningsListScreen/LearningsListItem';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import PrototypesListItem from 'screens/prototypes/PrototypesListScreen/PrototypesListItem';
import { ROUTE_LAB } from 'utils/constants/routes';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicListDetailContainerProps {
  type?: 'author' | 'bookmark';
  headerType?: 'author' | 'bookmark';
  elementType: SearchableElementType;
  onLoadMore?: () => void;
  buttonContainer?: any;
  loadingMore?: boolean;
  noHeader?: boolean;
  reduced?: boolean;
  onRetry?: () => void;
}

interface ListDetailContainerProps extends PublicListDetailContainerProps {
  actionsStore: ActionsStoreType;
  contentListStore: ContentListStoreType;
  form: FormType;
}

@inject('actionsStore', 'contentListStore')
@observer
class ListDetailContainer extends React.Component<ListDetailContainerProps> {
  componentDidUpdate(prevProps: ListDetailContainerProps) {
    if (
      prevProps.type !== this.props.type ||
      prevProps.elementType !== this.props.elementType
    ) {
      this.props.form.reset();
    }
  }

  bookmarkRemoved(elementType: BookmarkableEnumType, elementId: number) {
    if (this.props.type !== 'bookmark') {
      return;
    }
    this.props.contentListStore.removeByBookmark(elementType, elementId);
  }

  checkChanged(
    project: { id: number; organization_id: number },
    id: number,
    newChecked: boolean
  ) {
    const { actionsStore, elementType } = this.props;
    const organizationId = project.organization_id;
    const projectId = project.id;
    actionsStore.selectionSet(
      { [elementType]: [{ id, organizationId, projectId }] },
      newChecked
    );
  }

  resetSelection() {
    this.props.form.reset();
  }

  renderBriefings(): [number, any] {
    const list = this.props.contentListStore.briefingsSlice();
    return [
      list.length,
      list.map((briefing: BriefingModelType) => (
        <BriefingElement
          key={briefing.id}
          briefing={briefing}
          gradient={true}
          to={
            ROUTE_LAB +
            '/' +
            briefing.project?.organization_id +
            '/' +
            briefing.project?.id
          }
          top={
            briefing.project?.organization && (
              <OrgCardTop org={briefing.project?.organization} />
            )
          }
        />
      ))
    ];
  }

  renderPainpoints(): [number, any] {
    const list = this.props.contentListStore.painpointsSlice();
    const checked = this.props.actionsStore.selectedIds.Painpoint;
    const readOnly = this.props.type !== 'bookmark';

    return [
      list.length,
      list.map((painpoint: PainpointModelType) => (
        <PainpointsListItem
          key={painpoint.id}
          painpoint={painpoint}
          contextUri={
            ROUTE_LAB +
            '/' +
            painpoint.project?.organization_id +
            '/' +
            painpoint.project?.id
          }
          onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
          checked={checked.indexOf(painpoint.id) > -1}
          onChange={(c) =>
            this.checkChanged(painpoint.project, painpoint.id, c)
          }
          top={
            painpoint.project?.organization && (
              <OrgCardTop org={painpoint.project?.organization} />
            )
          }
          readOnly={readOnly}
        />
      ))
    ];
  }

  renderBenchmarks(): [number, any] {
    const list = this.props.contentListStore.benchmarksSlice();
    const checked = this.props.actionsStore.selectedIds.Benchmark;
    const readOnly = this.props.type !== 'bookmark';

    return [
      list.length,
      list.map((benchmark: BenchmarkModelType) => (
        <BenchmarksListItem
          key={benchmark.id}
          benchmark={benchmark}
          contextUri={
            ROUTE_LAB +
            '/' +
            benchmark.project?.organization_id +
            '/' +
            benchmark.project?.id
          }
          onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
          top={
            benchmark.project?.organization && (
              <OrgCardTop org={benchmark.project?.organization} />
            )
          }
          checked={checked.indexOf(benchmark.id) > -1}
          onChange={(c) =>
            this.checkChanged(benchmark.project, benchmark.id, c)
          }
          readOnly={readOnly}
        />
      ))
    ];
  }

  renderHypotheses(): [number, any] {
    const list = this.props.contentListStore.hypothesesSlice();
    const checked = this.props.actionsStore.selectedIds.Hypothesis;
    const readOnly = this.props.type !== 'bookmark';

    return [
      list.length,
      list.map((hypothesis: HypothesisModelType) => (
        <HypothesesListItem
          key={hypothesis.id}
          hypothesis={hypothesis}
          contextUri={
            ROUTE_LAB +
            '/' +
            hypothesis.project?.organization_id +
            '/' +
            hypothesis.project?.id
          }
          onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
          top={
            hypothesis.project?.organization && (
              <OrgCardTop org={hypothesis.project?.organization} />
            )
          }
          checked={checked.indexOf(hypothesis.id) > -1}
          onChange={(c) =>
            this.checkChanged(hypothesis.project, hypothesis.id, c)
          }
          readOnly={readOnly}
        />
      ))
    ];
  }

  renderPrototypes(): [number, any] {
    const list = this.props.contentListStore.prototypesSlice();
    const checked = this.props.actionsStore.selectedIds.Prototype;
    const readOnly = this.props.type !== 'bookmark';

    return [
      list.length,
      list.map((prototype: PrototypeModelType) => (
        <PrototypesListItem
          key={prototype.id}
          prototype={prototype}
          contextUri={
            ROUTE_LAB +
            '/' +
            prototype.project?.organization_id +
            '/' +
            prototype.project?.id
          }
          onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
          top={
            prototype.project?.organization && (
              <OrgCardTop org={prototype.project?.organization} />
            )
          }
          checked={checked.indexOf(prototype.id) > -1}
          onChange={(c) =>
            this.checkChanged(prototype.project, prototype.id, c)
          }
          readOnly={readOnly}
        />
      ))
    ];
  }

  renderLearnings(): [number, any] {
    const list = this.props.contentListStore.learningsSlice();
    const checked = this.props.actionsStore.selectedIds.Learning;
    const readOnly = this.props.type !== 'bookmark';

    return [
      list.length,
      list.map((learning: LearningModelType) => (
        <LearningsListItem
          key={learning.id}
          learning={learning}
          contextUri={
            ROUTE_LAB +
            '/' +
            learning.project?.organization_id +
            '/' +
            learning.project?.id
          }
          onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
          top={
            learning.project?.organization && (
              <OrgCardTop org={learning.project?.organization} />
            )
          }
          checked={checked.indexOf(learning.id) > -1}
          onChange={(c) => this.checkChanged(learning.project, learning.id, c)}
          readOnly={readOnly}
        />
      ))
    ];
  }

  pluralizeType(type?: SearchableElementType): string | undefined {
    switch (type) {
      case 'Briefing':
        return 'Briefings';

      case 'Painpoint':
        return 'Painpoints';

      case 'Benchmark':
        return 'Benchmarks';

      case 'Hypothesis':
        return 'Hypotheses';

      case 'Prototype':
        return 'Prototypes';

      case 'Learning':
        return 'Learnings';

      default:
        return undefined;
    }
  }

  render() {
    const {
      contentListStore,
      elementType,
      buttonContainer,
      loadingMore,
      noHeader,
      headerType,
      onLoadMore
    } = this.props;

    let list;
    let listLength = 0;
    let count = 0;
    let headerId = elementType + 's';

    let ListCont: any = React.Fragment;

    switch (elementType) {
      case 'Briefing':
        count = contentListStore.briefingsCount || 0;
        [listLength, list] = this.renderBriefings();
        break;

      case 'Painpoint':
        ListCont = ({ children }: any) => (
          <FileWrapper centered={true}>{children}</FileWrapper>
        );
        count = contentListStore.painpointsCount || 0;
        [listLength, list] = this.renderPainpoints();
        break;

      case 'Benchmark':
        count = contentListStore.benchmarksCount || 0;
        [listLength, list] = this.renderBenchmarks();
        break;

      case 'Hypothesis':
        headerId = 'Hypotheses';
        count = contentListStore.hypothesesCount || 0;
        [listLength, list] = this.renderHypotheses();
        break;

      case 'Prototype':
        count = contentListStore.prototypesCount || 0;
        [listLength, list] = this.renderPrototypes();
        break;

      case 'Learning':
        ListCont = LearningsList;
        count = contentListStore.learningsCount || 0;
        [listLength, list] = this.renderLearnings();
        break;

      default:
    }

    const headerSuffix = !headerType ? '' : ' ' + headerType;

    if (!listLength) {
      if (contentListStore.isListError) {
        return (
          <ErrorMessage
            inline={true}
            state={contentListStore.listLoadingState}
            onRetry={this.props.onRetry}
          />
        );
      }

      return (
        <CardWrapper>
          <FormattedMessage id={'no elements' + headerSuffix} />
        </CardWrapper>
      );
    }

    const hasMore = (loadingMore || count > listLength) && !!onLoadMore;

    const BtnCont = !buttonContainer ? React.Fragment : buttonContainer;
    // const ListCont = !listContainer ? React.Fragment : listContainer;

    return (
      <>
        <ColumnWrapper gap="3em">
          {!noHeader && (
            <SectionHeadline
              title={<FormattedMessage id={headerId + headerSuffix} />}
              value={count}
              single={true}
            />
          )}

          {/*elementType === 'Painpoint' ? (
            <FileWrapper centered={true}>{list}</FileWrapper>
          ) : (
            <ListCont>{list}</ListCont>
          )*/}

          <ListCont>{list}</ListCont>

          {hasMore && (
            <BtnCont>
              <MainButton onClick={onLoadMore} disabled={loadingMore}>
                {loadingMore && <Loading />}
                <FormattedMessage id="Load more" />
              </MainButton>
            </BtnCont>
          )}
        </ColumnWrapper>

        <ActionsOverlayContainer
          showCopy={true}
          showShare={true}
          showAiBenchmark={true}
          showAiBriefing={true}
        />
      </>
    );
  }
}

export default (props: PublicListDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <ListDetailContainer {...props} form={form} />;
};
