import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Bookmark from 'components/Bookmark';
import { BookmarkRemovedEvent } from 'components/Bookmark/Bookmark';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import EditMenu from 'components/EditMenu';
import HashtagsList from 'components/HashtagsList';
import IconCounter from 'components/IconCounter';
import MenuButton from 'components/MenuButton';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { LearningModelType } from 'models/LearningModel';
import { LinkProps } from 'react-router-dom';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { isDefined } from 'utils/misc/is-defined';
import shortenString from 'utils/misc/shorten-string';

export default injectIntl(
  ({
    learning,
    checked,
    onChange,
    mayEdit,
    onEditClick,
    onRemoveClick,
    intl,
    contextUri,
    readOnly,
    fromList,
    onBookmarkRemoved,
    top,
    noMenu
  }: {
    learning: LearningModelType;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    mayEdit?: boolean;
    onEditClick?: () => void;
    onRemoveClick?: () => void;
    contextUri?: string;
    readOnly?: boolean;
    fromList?: boolean;
    onBookmarkRemoved?: BookmarkRemovedEvent;
    top?: React.ReactNode;
    noMenu?: boolean;
  } & WrappedComponentProps) => {
    const isDraft = learning.publish_state === 'draft';

    let menu;
    if (mayEdit && !noMenu && (onEditClick || onRemoveClick)) {
      menu = (
        <EditMenu bottomLeft={true} label={intl.formatMessage({ id: 'Edit' })}>
          {onEditClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              description={intl.formatMessage({
                id: 'learning edit desc'
              })}
              iconName="pen"
              onClick={onEditClick}
            />
          ) : null}
          {onRemoveClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              description={intl.formatMessage({
                id: 'learning remove desc'
              })}
              iconName="bin"
              onClick={onRemoveClick}
            />
          ) : null}
        </EditMenu>
      );
    }

    const to: LinkProps['to'] = {
      pathname: `${
        isDefined(learning.project)
          ? `/app/lab/${learning.project.organization_id}/${learning.project.id}/`
          : contextUri
          ? contextUri + '/'
          : ''
      }learnings/${learning.id}${isDraft ? '/edit' : ''}`
    };
    if (fromList) {
      to.state = {
        switch: true
      };
    }

    return (
      <RainbowCard
        to={to}
        checked={checked}
        onCheckboxChange={onChange}
        sideLabel={isDraft ? intl.formatMessage({ id: 'Draft' }) : undefined}
        top={top}
      >
        <CardHeader
          title={learning.headline || intl.formatMessage({ id: 'no headline' })}
        />

        <div style={{ minHeight: '14rem', marginTop: '1rem' }}>
          <CardHeader
            simple={true}
            title={intl.formatMessage({ id: 'learning description' })}
          />

          <Typography size="small">
            {shortenString(learning.description, LIST_ITEM_TEXT_LENGTH)}
          </Typography>

          <div style={{ marginTop: '1.5rem' }}>
            <CardHeader
              simple={true}
              title={intl.formatMessage({ id: 'learning result' })}
            />

            <Typography size="small">
              {shortenString(learning.result, LIST_ITEM_TEXT_LENGTH)}
            </Typography>
          </div>
        </div>

        <HashtagsList tags={learning.tags} />

        {!noMenu && (
          <CardMenu user={learning.author}>
            <Bookmark
              elementType="Learning"
              elementId={learning.id}
              count={learning.bookmarks_count}
              id={learning.bookmark_id}
              disabled={readOnly}
              onRemoved={onBookmarkRemoved}
            />
            <IconCounter
              value={learning.comments_count || 0}
              label={intl.formatMessage({ id: 'Comments' })}
              iconName="comment"
            />

            {menu}
          </CardMenu>
        )}
      </RainbowCard>
    );
  }
);
