
import Headline from 'components/headline';
import SimplePopup from 'components/SimplePopup';

interface VideoPopupProps {
  videoId: string;
  headline?: React.ReactNode;
  onClose: () => void;
}

export const VideoPopup = ({ videoId, headline, onClose }: VideoPopupProps) => (
  <SimplePopup
    reduced={true}
    onAbort={onClose}
    onSubmit={onClose}
    submitTextId="Understood"
  >
    {headline && <Headline>{headline}</Headline>}

    <div className="video-container">
      <iframe
        title="Video"
        width="560"
        height="315"
        src={
          'https://www.youtube-nocookie.com/embed/' +
          encodeURIComponent(videoId) +
          '?autoplay=1'
        }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      />
    </div>
  </SimplePopup>
);

export default VideoPopup;
