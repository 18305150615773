import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import { ApplicationStoreType } from 'models/ApplicationStore';

// tslint:disable: align

const TIMEOUT = 2500;

interface FlashMessageDisplayComponentProps {
  onHide: () => void;
  message?: string;
  type?: string;
  hidden?: boolean;
}

// TODO move to CSS
const Fade = styled.div<any>`
  opacity: ${({ state }: { state: any }) => (state === 'entered' ? 1 : 0)};
  transition: 0.5s;
`;

export class FlashMessageDisplayComponent extends React.Component<FlashMessageDisplayComponentProps> {
  timer: any;

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setTimer() {
    this.clearTimer();

    this.timer = setTimeout(() => {
      this.clearMessage();
    }, TIMEOUT);
  }

  clearMessage() {
    this.props.onHide();
  }

  clear() {
    this.clearTimer();
    this.clearMessage();
  }

  componentDidMount() {
    const { message } = this.props;

    this.clearTimer();

    if (message) {
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps: FlashMessageDisplayComponentProps) {
    const { message, type, hidden } = this.props;

    if (!message || hidden) {
      // message removed, stop timer
      this.clearTimer();
      return;
    }

    const prevMessage = prevProps.message;
    const prevType = prevProps.type;
    const prevHidden = prevProps.hidden;

    if (message !== prevMessage || type !== prevType || hidden !== prevHidden) {
      // timer needs to be reset
      this.setTimer();
    }
  }

  render() {
    const { message, hidden, type } = this.props;
    const visible = !!message && !hidden;

    const flashMessageClasses = classNames('flash-message', {
      'flash-message--error': type === 'error',
      'flash-message--warning': type === 'warning'
    });

    return (
      <Transition
        in={visible}
        timeout={500}
        unmountOnExit={true}
        mountOnEnter={true}
        enter={false}
      >
        {(state: any) => (
          <Fade state={state}>
            <div className={flashMessageClasses} onClick={() => this.clear()}>
              <span>{message}</span>
            </div>
          </Fade>
        )}
      </Transition>
    );
  }
}

interface FlashMessageProps {
  applicationStore?: ApplicationStoreType;
}

// tslint:disable-next-line: max-classes-per-file
@inject('applicationStore')
@observer
class FlashMessage extends React.Component<FlashMessageProps> {
  render() {
    const { applicationStore } = this.props;

    if (applicationStore?.flashMessageType === 'inlineError') {
      return null;
    }

    return (
      <FlashMessageDisplayComponent
        message={applicationStore!.flashMessage}
        type={applicationStore!.flashMessageType}
        hidden={applicationStore!.flashMessageHidden}
        onHide={() => applicationStore!.hideFlashMessage()}
      />
    );
  }
}

export default FlashMessage;
