import { FormattedMessage } from 'react-intl';

import CallToAction from 'components/CallToAction';
import ContentLayout from 'components/Layout/ContentLayout';
import SubNav from 'components/SubNav';
import SubNavCategory from 'components/SubNavCategory';
import SubNavItem from 'components/SubNavItem';
import { OrganizationModelType } from 'models/OrganizationModel';
import { ROUTE_LAB } from 'utils/constants/routes';
import AIResponseCounter from 'components/AIResponseCounter';

export interface OrgLayoutProps {
  active?: 'settings' | 'members' | 'target-groups' | 'services';
  children?: React.ReactNode;
  org: OrganizationModelType;
  admin?: boolean;
}

// tslint:disable: jsx-wrap-multiline
export default ({ active, children, org, admin }: OrgLayoutProps) => {
  const orgPath = ROUTE_LAB + '/' + org.id;

  return (
    <ContentLayout
      subNav={
        <SubNav title={org.name}>
          <SubNavCategory title={false}>
            <SubNavItem
              title={<FormattedMessage id="Settings(local)" />}
              iconName="cogwheel"
              active={active === 'settings'}
              to={orgPath + '/settings'}
            />
            <SubNavItem
              title={<FormattedMessage id="Members" />}
              iconName="member"
              active={active === 'members'}
              to={orgPath + '/settings/members'}
            />
            <SubNavItem
              title={<FormattedMessage id="Target groups" />}
              iconName="target-group"
              active={active === 'target-groups'}
              to={orgPath + '/settings/target-groups'}
            />
            <SubNavItem
              title={<FormattedMessage id="Services" />}
              iconName="service"
              active={active === 'services'}
              to={orgPath + '/settings/services'}
            />
          </SubNavCategory>
        </SubNav>
      }
    >
      <AIResponseCounter />
      {children}

      <CallToAction />
    </ContentLayout>
  );
};
