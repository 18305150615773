import AddButton from 'components/AddButton';
import CreateUserFlowItemSimple from 'components/CreateUserFlowItemSimple';
import ContentWrapper from 'components/Layout/ContentWrapper';
import FileWrapper from 'components/Layout/FileWrapper';
import ScreenItem from 'components/Layout/ScreenItem';
import ScribbleUserFlowItem from 'components/ScribbleUserFlowItem/ScribbleUserFlowItem';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { PrototypesStoreType } from 'models/PrototypesStore';

interface ICreateUserFlowComponent {
  full?: boolean;
  onAddClick: () => void;
  onMoveClick: (id: number, direction: 'left' | 'right') => void;
  onRemoveClick: (id: number) => void;
  sorting?: boolean;
  adding?: boolean;
  deleting?: boolean;
  assistantStore?: AssistantStoreType;
  prototypesStore?: PrototypesStoreType;
}

function CreateUserFlowWrapper({
  full,
  onAddClick,
  onRemoveClick,
  onMoveClick,
  assistantStore,
  prototypesStore
}: ICreateUserFlowComponent): JSX.Element {
  const onEdit = async (screenId: number, headline: string) => {
    await prototypesStore!.updateScreen({
      assistantSession: assistantStore!.currentItem,
      screenId,
      patch: {
        headline
      },
      orgId: assistantStore?.currentItem?.project.organization_id,
      projId: assistantStore?.currentItem?.project.id
    });
  };

  const RenderComponent = full
    ? ScribbleUserFlowItem
    : CreateUserFlowItemSimple;

  return (
    <ContentWrapper ultra={true}>
      <FileWrapper screen={true} noMargin={true} centered={full}>
        {assistantStore?.currentItem &&
          assistantStore?.currentItem.sortedScreens.map((screen) => (
            <RenderComponent
              key={screen.id}
              screen={screen}
              onRemoveClick={onRemoveClick}
              onMoveClick={onMoveClick}
              onEdit={onEdit}
            />
          ))}

        <ScreenItem asUserflow={true} small={!full}>
          <AddButton
            userFlow={!full}
            fillContainer={full}
            iconName="plus"
            label="User Flow Schritt hinzufügen"
            onClick={onAddClick}
          />
        </ScreenItem>
      </FileWrapper>
    </ContentWrapper>
  );
}

export default inject(
  'assistantStore',
  'prototypesStore'
)(observer(CreateUserFlowWrapper));
