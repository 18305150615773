import { getSnapshot, isStateTreeNode, types } from 'mobx-state-tree';

import { sortCreatedAsc, sortCreatedDesc } from 'utils/sort/created-at';
import createMap from 'utils/store/createMap';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ApiElementTypeEnum from './ApiElementTypeEnum';
import { createFluidUserModel, FluidUserModel } from './UserModel';

const COMMENT_TYPES = ['unknown', 'text_only', 'valuation'];
export const VALUATION_TYPES = [
  'Painpoint',
  'Benchmark',
  'Hypothesis',
  'Prototype',
  'Learning'
];

export const CommentModel = types.model('CommentModel', {
  id: types.identifierNumber,
  element_type: types.maybe(ApiElementTypeEnum),
  element_id: types.maybe(types.number),
  comment_type: types.maybe(types.enumeration(COMMENT_TYPES)),
  author: types.maybe(FluidUserModel),
  text: types.maybe(types.string),
  target_group_relevance: types.maybe(types.number),
  revenue_potential: types.maybe(types.number),
  cost_efficiency: types.maybe(types.number),
  differentiation_degree: types.maybe(types.number),
  created_at: types.maybe(types.string)
});

const castNumber = (value: any): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseInt(value, 10);
};

export const createCommentModel = (data?: any): CommentModelType => {
  return {
    id: mandatoryId(data?.id),
    element_type: data?.element_type || undefined,
    element_id: numberOrUndefined(data?.element_id),
    comment_type:
      COMMENT_TYPES.indexOf(data?.comment_type) > -1
        ? data.comment_type
        : undefined,
    author: createFluidUserModel(data?.author),
    text: data?.text || undefined,
    target_group_relevance: castNumber(data?.target_group_relevance),
    revenue_potential: castNumber(data?.revenue_potential),
    cost_efficiency: castNumber(data?.cost_efficiency),
    differentiation_degree: castNumber(data?.differentiation_degree),
    created_at: data?.created_at || undefined
  };
};

export const CommentsMapModel = types
  .model('CommentsMapModel', {
    map: types.map(CommentModel)
  })
  .actions(self => {
    const put = (item: CommentModelType) => self.map.put(item);
    const get = (id: number) => self.map.get(id.toString());
    const remove = (id: number) => self.map.delete(id.toString());

    return {
      put,
      get,
      delete: remove
    };
  })
  .views(self => {
    return {
      get hasAny(): boolean {
        return self.map.size > 0 ? true : false;
      },
      get all(): CommentModelType[] {
        const comments = [];

        for (const comment of self.map.values()) {
          comments.push(comment);
        }

        return comments;
      },
      get count(): number {
        return self.map.size;
      },
      sorted(ascending = false): CommentModelType[] {
        const list: CommentModelType[] = [];

        if (self.map && self.map.size > 0) {
          for (const comment of self.map.values()) {
            list.push(comment);
          }

          list.sort(ascending ? sortCreatedAsc : sortCreatedDesc);
        }

        return list;
      },
      byUser(userId: number): CommentModelType | undefined {
        if (!self.map || self.map.size < 1) {
          return undefined;
        }

        for (const comment of self.map.values()) {
          if (comment.author && comment.author.id === userId) {
            return comment;
          }
        }

        return undefined;
      }
    };
  });
export type CommentsMapModelType = typeof CommentsMapModel.Type;

export const createCommentsMap = (data?: any): any =>
  isStateTreeNode(data)
    ? getSnapshot(data)
    : CommentsMapModel.create({
        map: createMap(!data ? [] : data, createCommentModel)
      });

export type CommentModelType = typeof CommentModel.Type;
export default CommentModel;
