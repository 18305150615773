import { FormattedMessage } from 'react-intl';

import CurrentUserAvatar from 'components/Avatar/CurrentUserAvatar';
import PageLayout from 'components/Layout/PageLayout';
import MainNav from 'components/MainNav';
import MainNavAvatar from 'components/MainNavAvatar';
import MainNavItem from 'components/MainNavItem';
import { OrganizationModelType } from 'models/OrganizationModel';
import {
  ROUTE_FAQ_PAGES,
  ROUTE_LAB,
  ROUTE_NETWORK,
  ROUTE_PERFORM_LOGOUT
} from 'utils/constants/routes';
import AssistantNavItem from './AssistantNavItem';
import CustomerNavItem from './CustomerNavItem';
import SettingsNavItem from './SettingsNavItem';

export type AppLayoutEnum =
  | 'assistant'
  | 'profile'
  | 'lab'
  | 'network'
  | 'settings'
  | 'customers'
  | 'faq-pages';

export interface AppLayoutProps {
  active?: AppLayoutEnum;
  children?: React.ReactNode;
  reduced?: boolean;
  withSettings?: boolean;
  org?: OrganizationModelType;
}

// tslint:disable: jsx-wrap-multiline
export default ({
  active,
  children,
  reduced,
  withSettings,
  org
}: AppLayoutProps) => (
  <PageLayout
    reduced={reduced}
    noHeaderBar={
      active === 'lab' || active === 'network' || active === 'faq-pages'
    }
    mainNav={
      <MainNav>
        <MainNavAvatar active={active === 'profile'}>
          <CurrentUserAvatar noName={true} />
        </MainNavAvatar>

        <MainNavItem
          label={<FormattedMessage id="Lab" />}
          iconName="lab"
          active={active === 'lab'}
          to={ROUTE_LAB + (org ? '/' + org.id : '')}
        />

        <CustomerNavItem active={active === 'customers'} />

        <AssistantNavItem active={active === 'assistant'} />

        <MainNavItem
          label={<FormattedMessage id="Network" />}
          iconName="network"
          active={active === 'network'}
          to={ROUTE_NETWORK}
        />
        <MainNavItem
          label={<FormattedMessage id="FAQ" />}
          iconName="faq"
          to={ROUTE_FAQ_PAGES}
          active={active === 'faq-pages'}
        />
        <SettingsNavItem
          active={active === 'settings'}
          displayWithoutOnlyOrg={withSettings}
        />

        <MainNavItem
          label={<FormattedMessage id="Logout" />}
          iconName="logout"
          to={ROUTE_PERFORM_LOGOUT}
        />
      </MainNav>
    }
  >
    {children}
  </PageLayout>
);
