import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import SearchForm from 'components/SearchForm';
import { Component } from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';
import Icon from 'components/Icon';
import classNames from 'classnames';

interface PublicAIResponseCounterProps extends WrappedComponentProps {
  dark?: boolean;
}
interface AIResponseCounterProps extends PublicAIResponseCounterProps {
  dataStore: DataStoreType;
}
@inject('dataStore')
@observer
class AIResponseCounter extends Component<AIResponseCounterProps> {
  capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const { dataStore, intl, dark } = this.props;

    const inProgessRequests = dataStore?.gptRequestCounterList?.filter(
      (item) => item.status == 'in_progress'
    );
    const completedRequests = dataStore?.gptRequestCounterList?.filter(
      (item) => item.status == 'completed'
    );
    const failedRequests = dataStore?.gptRequestCounterList?.filter(
      (item) => item.status == 'failed'
    );

    function requestCounter() {
      return inProgessRequests && inProgessRequests.length > 0;
    }

    function requestTargetCount() {
      let counter = 0;

      if (dataStore?.gptRequestCounterList) {
        dataStore?.gptRequestCounterList
          .filter((item) => item.status == 'in_progress')
          .map((item) => {
            if (item.current_amount) counter += item.current_amount;
          });
      }
      return counter;
    }

    function requestTargetAmount() {
      let amount = 0;

      if (dataStore?.gptRequestCounterList) {
        dataStore?.gptRequestCounterList
          .filter((item) => item.status == 'in_progress')
          .map((item) => {
            if (item.target_amount) amount += item.target_amount;
          });
      }
      return amount;
    }

    return (
      <div
        className={classNames('search-and-counter-bar', {
          'search-and-counter-bar--dark': dark
        })}
      >
        <SearchForm
          noMargin={true}
          selectSize="s"
          placeholder={intl.formatMessage({ id: 'Search it' })}
        />
        {dataStore.currentOrganization?.access_level !== 'viewer' && (
          <div className="search-and-counter-bar__aiButton">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                backgroundColor: 'var(--gray-250)',
                padding: '0 0.75rem',
                borderRadius: '6px',
                position: 'relative'
              }}
            >
              <img
                src={
                  requestCounter()
                    ? '/images/robot.gif'
                    : '/images/robot-frame.gif'
                }
                alt=""
                style={{
                  width: 'auto',
                  height: '33px',
                  left: '-.2rem',
                  position: 'relative'
                }}
              />
              <div className="response-counter">
                <details className="request-hover">
                  <summary>
                    {!requestCounter() ? (
                      <FormattedMessage id="GPTRequest inactive" />
                    ) : (
                      `${requestTargetCount()} von ${requestTargetAmount()} Antworten`
                    )}
                  </summary>
                  <hr />
                  <div className="request-status-heading">
                    <FormattedMessage id="GPTRequest active" />
                  </div>
                  {inProgessRequests?.length == 0 && (
                    <p className="list-item">
                      <FormattedMessage id="None" />
                    </p>
                  )}
                  {inProgessRequests?.map((item) => {
                    return (
                      <p className="list-item active" key={item.id}>
                        <FormattedMessage
                          id="x of y"
                          values={{
                            x: item.current_amount,
                            y: item.target_amount
                          }}
                        />{' '}
                        {this.capitalize(item.request_type!)}
                      </p>
                    );
                  })}
                  <hr />
                  <div className="request-status-heading">
                    <FormattedMessage id="GPTRequest completed" />
                  </div>
                  {completedRequests?.length == 0 && (
                    <p className="list-item">
                      <FormattedMessage id="None" />
                    </p>
                  )}
                  {completedRequests?.map((item) => {
                    return (
                      <>
                        <p
                          className="list-item done"
                          onClick={() => {
                            dataStore.removeGPTRequestCounter(item.id);
                          }}
                          key={item.id}
                        >
                          <FormattedMessage
                            id="x of y"
                            values={{
                              x: item.current_amount,
                              y: item.target_amount
                            }}
                          />{' '}
                          {this.capitalize(item.request_type!)}
                          <Icon name="cross" />
                        </p>
                      </>
                    );
                  })}
                  <hr />
                  <div className="request-status-heading">
                    <FormattedMessage id="GPTRequest failed" />
                  </div>
                  {failedRequests?.length == 0 && (
                    <p className="list-item">
                      <FormattedMessage id="None" />
                    </p>
                  )}
                  {failedRequests?.map((item) => {
                    return (
                      <p className="list-item failed" key={item.id}>
                        <FormattedMessage
                          id="x of y"
                          values={{
                            x: item.current_amount,
                            y: item.target_amount
                          }}
                        />{' '}
                        {this.capitalize(item.request_type!)}
                      </p>
                    );
                  })}
                </details>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl((props: PublicAIResponseCounterProps) => (
  // @ts-ignore
  <AIResponseCounter {...props} />
));
