import { types } from 'mobx-state-tree';

import { createMapWithTransform } from 'utils/store/createMap';
import { AssistantType, AssistantTypeEnum } from './AssistantSessionModel';

export const AssistantStatsModelRow = types
  .model({
    id: types.identifier,
    total_count: types.number,
    published_count: types.number
  })
  .views((self) => {
    return {
      get unpublished_count(): number {
        return self.total_count - self.published_count;
      },
      get progress(): number {
        return Math.round((self.published_count / self.total_count) * 100);
      },
      get hasUnfinished(): boolean {
        return self.published_count < self.total_count;
      }
    };
  });

export type AssistantStatsModelRowType = typeof AssistantStatsModelRow.Type;

export const createAssistantStatsModelRow = (data?: any) => {
  if (!data || !AssistantTypeEnum.is(data.assistant_type)) {
    return undefined;
  }

  return AssistantStatsModelRow.create({
    id: data.assistant_type,
    total_count: data.total_count || 0,
    published_count: data.published_count || 0
  });
};

export const AssistantStatsModel = types
  .model('AssistantStatsModel', {
    projectId: types.identifierNumber,
    stats: types.map(AssistantStatsModelRow)
  })
  .views((self) => {
    return {
      hasAny(assistantType: AssistantType): boolean {
        return !!self.stats.get(assistantType)?.total_count;
      },
      get(
        assistantType: AssistantType
      ): AssistantStatsModelRowType | undefined {
        return self.stats.get(assistantType);
      }
    };
  });

export const createAssistantStatsModel = (projectId: number, data?: any) => {
  if (!Array.isArray(data)) {
    return undefined;
  }

  return AssistantStatsModel.create({
    projectId,
    stats: createMapWithTransform(
      data.map((row) => ({ id: row.assistant_type, ...row })),
      createAssistantStatsModelRow
    )
  });
};

export type AssistantStatsModelType = typeof AssistantStatsModel.Type;
export default AssistantStatsModel;
