import { inject, observer } from 'mobx-react';

import { DataStoreType } from 'models/DataStore';
import { Component } from 'react';
import UserAvatar from './UserAvatar';

interface PublicCurrentUserAvatarProps {
  noName?: boolean;
}

interface CurrentUserAvatarProps extends PublicCurrentUserAvatarProps {
  dataStore: DataStoreType;
}

@inject('dataStore')
@observer
class CurrentUserAvatar extends Component<CurrentUserAvatarProps> {
  render() {
    const {
      dataStore: { user },
      noName
    } = this.props;

    if (!user) {
      return null;
    }

    return <UserAvatar isSelf={true} user={user} noName={noName} />;
  }
}

export default (props: PublicCurrentUserAvatarProps) => (
  // @ts-ignore
  <CurrentUserAvatar {...props} />
);
