import ColumnWrapper from 'components/Layout/ColumnWrapper';
import MainButton from 'components/MainButton';
import CreateUserFlowContainer from 'containers/CreateUserFlowContainer';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';

interface CreateUserFlowScreenProps {
  assistantStore: AssistantStoreType;
}

function CreateUserFlowScreen({
  assistantStore,
  location
}: CreateUserFlowScreenProps & HistoryProps) {
  const { currentItem, currentId, currentAssistantType } = assistantStore;

  const stepInfo = getStepInfo('step3', currentAssistantType);

  if (!currentId || !currentAssistantType) {
    return null;
  }

  useEffect(() => {
    scrollSidebar('hypothesis');
  }, []);

  const continueSession = async () => {
    const progress = ASSISTANTS[currentAssistantType].step3.progress;
    await assistantStore!.updateAssistantSessionProgess(currentId, progress);

    goToNextStep(currentId, progress, currentAssistantType);
  };

  return (
    <StepContainer
      progress={stepInfo?.percent}
      sidebarHeadline={stepInfo?.header}
      location={location}
      sessionId={currentItem?.id}
      bottomSpace={false}
      minHeightZero={true}
      timerSeconds={600}
    >
      <SessionTop>
        <SessionHeadline step={stepInfo?.header}>
          <FormattedMessage id="assistant create userflow" />
        </SessionHeadline>
      </SessionTop>

      <SessionMainText noMargin={true}>
        <FormattedMessage id="assistant create userflow introduction" />
      </SessionMainText>

      <ColumnWrapper gap="3em" noTranslate={true}>
        <CreateUserFlowContainer />
      </ColumnWrapper>

      <SessionBottom>
        <SessionActions>
          <MainButton onClick={continueSession}>
            <FormattedMessage id="Next" />
          </MainButton>
        </SessionActions>
      </SessionBottom>
    </StepContainer>
  );
}

export default inject('assistantStore')(observer(CreateUserFlowScreen));
