import {
  createIntl as createIntlInstance,
  createIntlCache,
  IntlShape
} from 'react-intl';

const cache = createIntlCache();

let _intl: any;

export function createIntl(options: any): IntlShape {
  _intl = createIntlInstance(options, cache);
  return _intl;
}

export function getIntl(): IntlShape {
  return _intl;
}

export default getIntl;
