import ActionOverlaySelectButton from 'components/ActionOverlaySelectButton';
import AddButton from 'components/AddButton';
import UserAvatar from 'components/Avatar/UserAvatar';
import Bookmark from 'components/Bookmark';
import CardMenu from 'components/CardMenu';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import EditableCard from 'components/EditableCard/EditableCard';
import EditMenu from 'components/EditMenu';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormChangeHandler from 'components/FormChangeHandler/FormChangeHandler';
import FormTagList from 'components/FormTagList';
import HypothesisSelectPopup from 'components/HypothesisSelectPopup';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import CardStack from 'components/Layout/CardStack';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import MenuButton from 'components/MenuButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import PageSwitch from 'components/PageSwitch';
import SimplePopup from 'components/SimplePopup';
import TagList from 'components/TagList';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import PrototypeScreensContainer from 'containers/PrototypeScreensContainer';
import { UploadContainerStateEnum } from 'containers/UploadContainer/UploadContainer';
import { inject, observer } from 'mobx-react';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import { tagsListToArray } from 'models/TagListModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import { ROUTE_PROFILE } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import { idFromProps } from 'utils/history/param-from-props';
import { scrollToTop } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable: jsx-wrap-multiline

interface PublicPrototypesDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
  create?: boolean;
}

interface PrototypesDetailContainerProps
  extends PublicPrototypesDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  prototypesStore: PrototypesStoreType;
  actionsStore: ActionsStoreType;
  form: FormType;
}

interface PrototypesDetailContainerState {
  hypothesisSelectVisible?: boolean;
}

@inject('applicationStore', 'dataStore', 'prototypesStore', 'actionsStore')
@observer
class PrototypesDetailContainer extends React.Component<
  PrototypesDetailContainerProps & HistoryProps,
  PrototypesDetailContainerState
> {
  state: PrototypesDetailContainerState = {};

  componentDidMount() {
    if (this.props.create) {
      this.props.dataStore.setPrototypeItem(undefined);
      this.props.form.reset();
      return;
    }

    const id = idFromProps(this.props);
    if (!id) {
      return;
    }

    const {
      dataStore: { prototypeItem }
    } = this.props;

    if (
      isPush(this.props.history) ||
      !prototypeItem ||
      prototypeItem.id !== id
    ) {
      this.loadPrototype(id);
    }
  }

  componentDidUpdate(
    prevProps: PublicPrototypesDetailContainerProps & HistoryProps
  ) {
    const id = idFromProps(this.props);
    const prevId = idFromProps(prevProps);

    if (this.props.create && !prevProps.create) {
      this.props.dataStore.setPrototypeItem(undefined);
      this.props.form.reset();
      return;
    }

    if (id && id !== prevId) {
      this.loadPrototype(id);
    }
  }

  load() {
    const id = idFromProps(this.props);
    if (id) {
      this.loadPrototype(id);
    }
  }

  prototypeCreatedByScreen(screen: PrototypeScreenModelType) {
    const { dataStore, prototypesStore } = this.props;
    if (dataStore.prototypeItem) {
      return;
    }

    prototypesStore.initializeItem({
      id: screen.prototype_id,
      prototype_screens: [screen]
    });
  }

  async loadPrototype(id: number) {
    const { form, prototypesStore } = this.props;

    form.reset();

    const prototype = await prototypesStore.getPrototype(id);

    if (prototype) {
      if (this.props.edit) {
        form.setField('headline', prototype.headline);
        form.setField('flow_description', prototype.flow_description);
      } else if (prototype.publish_state === 'draft') {
        this.props.history.replace(prototype.id + '/edit');
      }

      form.setField('tags', tagsListToArray(prototype.tags));
    }
  }

  async save(buttonSave = false, publish = false) {
    const { dataStore, prototypesStore, form, create } = this.props;

    const patch: any = {
      ...form.values,
      hypothesis_id: dataStore.prototypeItemHypothesis
        ? dataStore.prototypeItemHypothesis.id
        : null
    };
    if (publish) {
      patch.publish_state = 'active';
    }

    try {
      let item = dataStore.prototypeItem;
      if (create && !item) {
        item = await prototypesStore.createPrototype(patch, true);
      } else {
        if (!item || (!create && item.id !== idFromProps(this.props))) {
          return;
        }

        // TODO update form after update?
        item = await prototypesStore.updatePrototype(item.id, patch);
      }

      if (buttonSave) {
        if (prototypesStore.isItemSaveError) {
          this.displayError();
        } else {
          this.props.history.replace(dataStore.contextUri + '/prototypes');
        }
      }
    } catch (error: any) {
      if (handleFormError(form, error)) {
        scrollToTop();
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { dataStore, prototypesStore } = this.props;
    const item = dataStore.prototypeItem;

    if (!item) {
      return;
    }

    try {
      await prototypesStore.updatePrototype(item.id, {
        tags: newTags || []
      });
    } catch (e) {
      // TODO How to handle this?
    }
  }

  async remove() {
    const { intl, prototypesStore, dataStore, history } = this.props;

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage({ id: 'remove prototypes confirm' }, { count: 1 })
      )
    ) {
      return;
    }

    if (!dataStore.prototypeItem) {
      return;
    }

    try {
      await prototypesStore.deletePrototype(dataStore.prototypeItem.id);

      history.replace(dataStore.contextUri + '/prototypes');
    } catch (error: any) {
      this.displayError('remove');
    }
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    const { form, dataStore } = this.props;

    if (!newState) {
      form.setLoading(false);
      return;
    }

    if (newState === 'saving' && !dataStore.prototypeItem?.id) {
      // item has not been saved yet, so we need to wait for the upload before we can reenable form
      form.setLoading(true);
    }
  }

  removeHypothesis(save: boolean = false) {
    this.props.dataStore.setPrototypeItemHypothesis(undefined);

    if (save) {
      this.saveHypothesis(null);
    }
  }

  beginSelectHypothesis() {
    this.setState({
      hypothesisSelectVisible: true
    });
  }

  selectHypothesis(id: number, save: boolean = false) {
    const { dataStore } = this.props;

    const hypothesis = dataStore.hypotheses.get(id.toString());
    if (hypothesis) {
      dataStore.setPrototypeItemHypothesis(hypothesis);
    }

    this.finishSelectHypothesis();

    if (save) {
      this.saveHypothesis(id);
    }
  }

  finishSelectHypothesis() {
    this.setState({
      hypothesisSelectVisible: false
    });
  }

  async saveHypothesis(id?: number | null | undefined) {
    const { dataStore, prototypesStore } = this.props;

    if (dataStore.prototypeItem) {
      try {
        await prototypesStore.updatePrototype(dataStore.prototypeItem.id, {
          hypothesis_id: id || null
        });

        if (prototypesStore.isItemSaveError) {
          this.displayError();
          return;
        }
      } catch (error: any) {
        // there should be no form error here
      }
    }
  }

  async saveFieldUpdate(names: string[]) {
    const { form, prototypesStore, dataStore } = this.props;

    if (!dataStore.prototypeItem) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await prototypesStore.updatePrototype(dataStore.prototypeItem.id, patch);

      if (prototypesStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  editTitle() {
    const { form, dataStore } = this.props;
    const item = dataStore.prototypeItem;

    if (!item) {
      return;
    }

    form.setField('headline', item.headline);

    form.setEditing('title');
  }

  isFromEmptyState() {
    return this.props.location.query?.empty === 'yes';
  }

  links() {
    const { dataStore, intl } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: false,
        to: contextUri + '/prototypes',
        content: intl.formatMessage({ id: 'Prototypes' })
      },
      {
        isLink: true,
        to: contextUri + '/prototypes/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderPageSwitch() {
    if (!this.props.location?.state?.switch) {
      return;
    }

    const { dataStore, prototypesStore, history } = this.props;
    return (
      <PageSwitch
        history={history}
        basePath={dataStore.contextUri + '/prototypes/'}
        pageSwitch={prototypesStore.pageSwitch(dataStore.prototypeItem)}
      />
    );
  }

  renderPage(content: any) {
    const {
      dataStore: {
        prototypeItem,
        contextUri,
        currentOrganizationId,
        currentProjectId
      },
      location,
      actionsStore,
      actionsStore: { selectionSet },
      intl,
      history
    } = this.props;

    const showClose = !location?.state?.isRedirect;
    const checked: number[] = actionsStore.selectedIds.Prototype;
    const isSelected =
      (prototypeItem && checked.indexOf(prototypeItem.id) > -1) || false;

    return (
      <>
        {content}

        {showClose && (
          <OverlayWrapper topRight={true} twoButtons={true}>
            <CloseButton
              label={intl.formatMessage({ id: 'Close' })}
              iconName="cross"
              onClick={() => history.push(contextUri + '/prototypes')}
            />
            <ActionOverlaySelectButton
              onClick={() => {
                const organizationId = currentOrganizationId!;
                const projectId = currentProjectId!;

                selectionSet(
                  {
                    Prototype: [
                      {
                        id: prototypeItem?.id!,
                        organizationId: organizationId,
                        projectId,
                        publishState: prototypeItem?.publish_state
                      }
                    ]
                  },
                  !isSelected
                );
              }}
              selected={isSelected}
            />
          </OverlayWrapper>
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(
      <>
        <PageHeader titleId="Prototypes" />
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        <PageHeader titleId="Prototypes" />
        <ErrorMessage
          state={this.props.prototypesStore.itemLoadingState}
          onRetry={() => this.load()}
        />
      </>
    );
  }

  renderHypothesisPopup(save: boolean = false) {
    if (!this.state.hypothesisSelectVisible) {
      return null;
    }

    return (
      <HypothesisSelectPopup
        onAbort={() => this.finishSelectHypothesis()}
        onSelect={(id) => this.selectHypothesis(id, save)}
      />
    );
  }

  renderHypothesis(
    mayEdit: boolean,
    hypothesis?: HypothesisModelType,
    save: boolean = false
  ) {
    const { intl, dataStore } = this.props;

    return hypothesis ? (
      <>
        {hypothesis.painpoint && (
          <PainpointsListItem
            painpoint={hypothesis.painpoint}
            contextUri={dataStore.contextUri}
            readOnly={true}
          />
        )}
        <HypothesesListItem
          hypothesis={hypothesis}
          contextUri={dataStore.contextUri}
          readOnly={true}
          showDestroyButton={true}
          onRemoveClick={() => this.removeHypothesis(save)}
        />
      </>
    ) : (
      mayEdit && (
        <AddButton
          iconName="plus"
          label={intl.formatMessage({
            id: 'Select hypothesis'
          })}
          onClick={() => this.beginSelectHypothesis()}
        />
      )
    );
  }

  renderEditMode() {
    const { prototypesStore, dataStore, form, intl } = this.props;
    const item = dataStore.prototypeItem;
    const hypothesis = dataStore.prototypeItemHypothesis;

    const itemIsActive = item && item.publish_state === 'active';

    return this.renderPage(
      <>
        {itemIsActive && prototypesStore.isItemSaving && <Loading />}

        {this.renderHypothesisPopup(true)}
        {this.renderPageSwitch()}

        <FormChangeHandler
          halted={itemIsActive}
          onSave={() => this.save()}
          isSaving={prototypesStore.isItemSaving}
        >
          {({ onTextChanged, hasUnsavedChanges, isSaving }) => (
            <>
              {itemIsActive && hasUnsavedChanges && (
                <Prompt
                  message={intl.formatMessage({ id: 'unsaved prompt' })}
                />
              )}
              <Form loading={form.loading} onSubmit={() => this.save(true)}>
                <PageHeader titleId="Prototypes" />
                <ContentWrapper>
                  <FormTagList
                    form={form}
                    field="tags"
                    allowAdd={true}
                    onChanged={onTextChanged}
                  />

                  {this.isFromEmptyState() && (
                    <div style={{ marginBottom: '3em' }}>
                      <LinkRow links={this.links()} />
                    </div>
                  )}

                  <ColumnWrapper gap="4em">
                    <CardWrapper>
                      <Input
                        headline={true}
                        name="headline"
                        placeholder={intl.formatMessage({
                          id: 'Insert headline'
                        })}
                        {...form.bindInput('headline', onTextChanged)}
                      />
                    </CardWrapper>

                    <PrototypeScreensContainer
                      prototype={item}
                      editable={true}
                      create={this.props.create}
                      onNewScreen={(screen) =>
                        this.prototypeCreatedByScreen(screen)
                      }
                    />
                    <ColumnWrapper gap="1em">
                      <CardStack>
                        <CardWrapper>
                          <ColumnWrapper gap="0.5em">
                            <Textarea
                              label={intl.formatMessage({
                                id: 'prototype short description'
                              })}
                              placeholder={intl.formatMessage({
                                id: 'prototype description placeholder'
                              })}
                              name="flow_description"
                              {...form.bindInput(
                                'flow_description',
                                onTextChanged
                              )}
                            />
                          </ColumnWrapper>
                        </CardWrapper>

                        {this.renderHypothesis(true, hypothesis, true)}
                      </CardStack>
                      <PositionWrapper end={true}>
                        {itemIsActive ? (
                          <MainButton type="submit">
                            <FormattedMessage id="Save" />
                          </MainButton>
                        ) : (
                          <RowWrapper gap="1em" alignRight={true}>
                            <MainButton type="submit" secondary={true}>
                              {isSaving && <Loading />}
                              <FormattedMessage id="Save draft" />
                            </MainButton>
                            <MainButton
                              type="button"
                              onClick={() => this.save(true, true)}
                            >
                              <FormattedMessage id="Publish" />
                            </MainButton>
                          </RowWrapper>
                        )}
                      </PositionWrapper>
                    </ColumnWrapper>
                  </ColumnWrapper>
                </ContentWrapper>
              </Form>
            </>
          )}
        </FormChangeHandler>
      </>
    );
  }

  renderViewMode() {
    const { intl, dataStore, prototypesStore, form } = this.props;

    const item = this.props.dataStore.prototypeItem;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author);

    return this.renderPage(
      <>
        {prototypesStore.isItemSaving && form.editing !== 'title' && (
          <Loading />
        )}

        {this.renderHypothesisPopup(true)}
        {this.renderPageSwitch()}

        <PageHeader
          titleId="Prototypes"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
              onEditClick={!mayEdit ? undefined : () => this.editTitle()}
            />
          }
        />

        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            <TagList tags={item.tags} />
          )}

          <ColumnWrapper>
            <PrototypeScreensContainer
              prototype={item}
              editable={mayEdit}
              onNewScreen={(screen) => this.prototypeCreatedByScreen(screen)}
            />

            <CardStack>
              <EditableCard
                form={form}
                name="flow_description"
                value={item.flow_description}
                label={<FormattedMessage id="prototype short description" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['flow_description'])}
                extra={
                  <CardMenu
                    avatar={
                      <UserAvatar
                        user={item.author}
                        isAiRobot={item.created_by_ai}
                      />
                    }
                  >
                    <Bookmark
                      elementType="Prototype"
                      elementId={item.id}
                      count={item.bookmarks_count}
                      id={item.bookmark_id}
                    />
                    {mayEdit && (
                      <EditMenu
                        bottomLeft={true}
                        label={intl.formatMessage({ id: 'Edit' })}
                      >
                        <MenuButton
                          label={intl.formatMessage({ id: 'Edit' })}
                          description={intl.formatMessage({
                            id: 'prototype edit desc'
                          })}
                          iconName="pen"
                          onClick={() => this.editTitle()}
                        />
                        <MenuButton
                          label={intl.formatMessage({ id: 'Remove' })}
                          description={intl.formatMessage({
                            id: 'prototype remove desc'
                          })}
                          iconName="bin"
                          onClick={() => this.remove()}
                        />
                      </EditMenu>
                    )}
                    {item.created_by_ai && item.author && (
                      <span className="ai-author">
                        Generated by{' '}
                        <Link
                          to={ROUTE_PROFILE.replace(
                            ':id',
                            item.author.id!.toString()
                          )}
                        >
                          <span className="underline">
                            {item.author.fullName}
                          </span>
                        </Link>
                      </span>
                    )}
                  </CardMenu>
                }
              />

              {this.renderHypothesis(mayEdit, item.hypothesis, true)}
            </CardStack>
          </ColumnWrapper>

          {item.publish_state !== 'draft' && (
            <CommentsContainer
              elementType={ElementType.Prototype}
              element={item}
              textId="comment text Prototype"
            />
          )}
        </ContentWrapper>

        {form.editing === 'title' && (
          <Form disabled={prototypesStore.isItemSaving}>
            <SimplePopup
              onAbort={() => form.setEditing()}
              onSubmit={() => this.saveFieldUpdate(['headline'])}
              isLoading={prototypesStore.isItemSaving}
            >
              <Input
                autoFocus={true}
                headline={true}
                name="headline"
                placeholder={intl.formatMessage({
                  id: 'Insert headline'
                })}
                {...form.bindInput('headline')}
              />
            </SimplePopup>
          </Form>
        )}
        {!prototypesStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              dataStore.currentOrganizationId
            }
            copyInitiallySelectedProjectId={dataStore.currentProjectId}
            showAiBenchmark={true}
            showAiBriefing={true}
            showCopy={true}
            showDelete={
              this.props.actionsStore.selectedFromProjectIds.length === 1 &&
              this.props.actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={true}
          />
        )}
      </>
    );
  }

  render() {
    const { prototypesStore, create, edit } = this.props;

    if (prototypesStore.isItemLoading) {
      return this.renderLoading();
    }

    if (prototypesStore.isItemLoadError) {
      return this.renderError();
    }

    if (create || edit) {
      return this.renderEditMode();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicPrototypesDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <PrototypesDetailContainer {...props} form={form} />;
});
