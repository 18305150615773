import classNames from 'classnames';
import { CSSProperties } from 'react';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface MainButtonProps extends HtmlButtonProps {
  secondary?: boolean;
  progress?: number;
  className?: string;
}

export default function MainButton(props: MainButtonProps) {
  const mainButtonClasses = classNames(props.className, 'main-button', {
    'main-button--secondary': props.secondary,
    'main-button--progress': props.progress !== undefined
  });

  return (
    <button
      style={
        props.progress !== undefined
          ? ({ '--button-progress': `${props.progress}%` } as CSSProperties)
          : undefined
      }
      {...{ ...props, secondary: undefined, progress: undefined }}
      className={mainButtonClasses}
    >
      {props.children}
    </button>
  );
}
