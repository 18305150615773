import classNames from 'classnames';

interface ToggleSwitchProps {
  label: string;
  disabled?: boolean;
  name?: string;
  id?: number;
}

export default function ToggleSwitch(
  props: ToggleSwitchProps & React.InputHTMLAttributes<HTMLInputElement>
) {
  const toggleSwitchClasses = classNames('toggle-switch__label', {
    'toggle-switch__label--disabled': props.disabled
  });

  const id = !props.id ? 'switch_' + props.name : props.id;
  return (
    <>
      <input type="checkbox" className="toggle-switch" id={id} {...props} />
      <label htmlFor={id} className={toggleSwitchClasses}>
        {props.label}
      </label>
    </>
  );
}
