import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import CardHeader from 'components/CardHeader';
import Typography from 'components/Typography';
import { BriefingModelType } from 'models/BriefingModel';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import shortenString from 'utils/misc/shorten-string';

interface BriefingElementProps {
  briefing: BriefingModelType;
  gradient?: boolean;
  to?: LinkProps['to'];
  top?: React.ReactNode;
}

export default function BriefingElement({
  gradient,
  to,
  top,
  briefing
}: BriefingElementProps) {
  const BriefingElementClasses = classNames('briefing-element', {
    'briefing-element--gradient': gradient,
    'briefing-element--with-top': top
  });

  const cardLink = to ? (
    <Link className="briefing-element__link" to={to} />
  ) : null;

  return (
    <article className={BriefingElementClasses}>
      {top}
      <div className="briefing-element__body">
        <CardHeader title={briefing.project?.name} />
        <Typography size="small">
          {shortenString(briefing.question, LIST_ITEM_TEXT_LENGTH)}
        </Typography>
        {cardLink}
      </div>
    </article>
  );
}
