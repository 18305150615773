import classNames from 'classnames';

interface PopupMenuProps {
  children?: React.ReactNode;
  bottomLeft?: boolean;
  topLeft?: boolean;
  topRight?: boolean;
}

export default function PopupMenu(props: PopupMenuProps) {
  const PopupMenuClasses = classNames('popup-menu', {
    'popup-menu--bottomLeft': props.bottomLeft,
    'popup-menu--topLeft': props.topLeft,
    'popup-menu--topRight': props.topRight
  });

  return <div className={PopupMenuClasses}>{props.children}</div>;
}
