
interface SettingsTableProps {
  title?: React.ReactNode;
  headers?: string[];
  rows: Array<{
    description: string;
    data: React.ReactNode;
  }>;
}

export default function SettingsTable({
  rows,
  headers,
  title
}: SettingsTableProps) {
  return (
    <section className="settings-table">
      <h3 className="settings-table__title">{title}</h3>
      <table className="table">
        {headers && (
          <thead>
            <tr>
              <td />
              {headers.map((header) => (
                <td key={header} className="table__column-header">
                  {header}
                </td>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map(({ description, data }) => (
            <tr key={description}>
              <td className="table__row-description">{description}</td>
              <td className="table__row-data">{data}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
