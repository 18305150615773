import Loading from 'components/Loading';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import React from 'react';

interface PublicClustersContainerProps {
  itemType: any;
  containerType?: any;
  loadingType?: any;
  emptyListType?: any;
  selected?: number;
  onItemClick?: (id: number) => void;
  onNoClustersFound?: () => void;
  containerProps?: any;
  alwaysRefresh?: boolean;
}

interface ClustersContainerProps extends PublicClustersContainerProps {
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
}

@inject('dataStore', 'painpointsStore')
@observer
class ClustersContainer extends React.Component<ClustersContainerProps> {
  state = {
    loading: false,
    error: false
  };

  componentDidMount() {
    if (
      !this.props.alwaysRefresh &&
      this.props.painpointsStore.hasAnyClusters
    ) {
      return;
    }

    this.load();
  }

  async load() {
    const { painpointsStore } = this.props;

    // TODO move to store so containing page can handle it?
    this.setState({
      error: false,
      loading: true
    });

    try {
      await painpointsStore.getClusters();
    } catch (error: any) {
      this.setState({
        error: true,
        loading: false
      });
      return;
    }

    if (this.props.onNoClustersFound && !painpointsStore.hasAnyClusters) {
      this.props.onNoClustersFound();
    }

    this.setState({
      loading: false
    });
  }

  renderLoading() {
    if (this.props.loadingType) {
      return this.props.loadingType;
    }

    return <Loading />;
  }

  renderError() {
    // TODO
    return <em>Error!</em>;
  }

  render() {
    const {
      painpointsStore,
      itemType,
      emptyListType,
      containerType,
      onItemClick,
      selected,
      containerProps
    } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return this.renderLoading();
    }
    if (error) {
      return this.renderLoading();
    }

    const Item = itemType;

    const list = painpointsStore.clustersByName.map((elem) => (
      <Item
        key={elem.id}
        cluster={elem}
        onClick={() => onItemClick && onItemClick(elem.id)}
        selected={selected === elem.id}
      />
    ));

    if (!containerType) {
      return list;
    }

    if (painpointsStore.clustersByName.length === 0 && emptyListType) {
      const EmptyList = emptyListType;
      return <EmptyList />;
    }

    const Container = containerType;
    return <Container {...containerProps}>{list}</Container>;
  }
}

export default (props: PublicClustersContainerProps) => {
  // @ts-ignore
  return <ClustersContainer {...props} />;
};
