import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import BriefingElement from 'components/BriefingListElement';
import CardSlider, { smallSliderResponsiveConfig } from 'components/CardSlider';
import OrgCardTop from 'components/CardTop/OrgCardTop';
import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { BriefingModelType } from 'models/BriefingModel';
import { ContentListStoreType } from 'models/ContentListStore';
import { BookmarkableEnumType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { LearningModelType } from 'models/LearningModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PrototypeModelType } from 'models/PrototypeModel';
import BenchmarksListItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksListItem';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import LearningsListItem from 'screens/learnings/LearningsListScreen/LearningsListItem';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import PrototypesListItem from 'screens/prototypes/PrototypesListScreen/PrototypesListItem';
import { ROUTE_LAB } from 'utils/constants/routes';
import useForm, { FormType } from 'utils/hooks/useForm';

export type ElementContentType =
  | 'briefing'
  | 'painpoint'
  | 'benchmark'
  | 'hypothesis'
  | 'prototype'
  | 'learning';

interface PublicListOverviewContainerProps {
  type?: 'author' | 'bookmark';
  headerType?: 'author' | 'bookmark' | 'network';
  baseUrl: string;
  largeSlider?: boolean;
  buildDetailUrl?: (elementType: ElementContentType) => string;
  limit?: number;
  calculateLimit?: (elementType: ElementContentType) => number;
  onRetry?: () => void;
}

interface ListOverviewContainerProps extends PublicListOverviewContainerProps {
  actionsStore: ActionsStoreType;
  contentListStore: ContentListStoreType;
  form: FormType;
}

@inject('actionsStore', 'contentListStore')
@observer
class ListOverviewContainer extends React.Component<ListOverviewContainerProps> {
  bookmarkRemoved(elementType: BookmarkableEnumType, elementId: number) {
    if (this.props.type !== 'bookmark') {
      return;
    }
    this.props.contentListStore.removeByBookmark(elementType, elementId);
  }

  detailUrl(type: ElementContentType) {
    const { buildDetailUrl, baseUrl } = this.props;

    if (buildDetailUrl) {
      return buildDetailUrl(type);
    }

    const join = baseUrl.indexOf('?') > -1 ? '&' : '?';
    return baseUrl + join + 'type=' + type;
  }

  getLimit(elementType: ElementContentType) {
    const { limit, calculateLimit } = this.props;
    if (calculateLimit) {
      return calculateLimit(elementType);
    } else if (limit) {
      return limit;
    } else {
      return 3;
    }
  }

  checkChanged(
    project: { id: number; organization_id: number },
    elementType: ElementType,
    id: number,
    newChecked: boolean
  ) {
    const { actionsStore } = this.props;
    const organizationId = project.organization_id;
    const projectId = project.id;
    actionsStore.selectionSet(
      { [elementType]: [{ id, organizationId, projectId }] },
      newChecked
    );
  }

  render() {
    const { contentListStore, type, headerType } = this.props;

    const {
      briefingsCount,
      painpointsCount,
      benchmarksCount,
      hypothesesCount,
      prototypesCount,
      learningsCount
    } = contentListStore;

    const briefings = contentListStore.briefingsSlice(
      this.getLimit('briefing')
    );
    const painpoints = contentListStore.painpointsSlice(
      this.getLimit('painpoint')
    );
    const benchmarks = contentListStore.benchmarksSlice(
      this.getLimit('benchmark')
    );
    const hypotheses = contentListStore.hypothesesSlice(
      this.getLimit('hypothesis')
    );
    const prototypes = contentListStore.prototypesSlice(
      this.getLimit('prototype')
    );
    const learnings = contentListStore.learningsSlice(
      this.getLimit('learning')
    );

    const headerSuffix = !headerType ? '' : ' ' + headerType;

    const sum =
      briefings.length +
      painpoints.length +
      benchmarks.length +
      hypotheses.length +
      prototypes.length +
      learnings.length;
    if (!sum) {
      if (contentListStore.isListError) {
        return (
          <ErrorMessage
            inline={true}
            state={contentListStore.listLoadingState}
            onRetry={this.props.onRetry}
          />
        );
      }

      return (
        <CardWrapper>
          <FormattedMessage id={'no elements' + headerSuffix} />
        </CardWrapper>
      );
    }

    const isNetwork = headerType === 'network';
    const readOnly = type !== 'bookmark';

    const briefingsList =
      briefings.length > 0 ? (
        <CardSlider
          large={this.props.largeSlider}
          title={<FormattedMessage id={'Briefings' + headerSuffix} />}
          value={briefingsCount}
          anchor={
            (briefingsCount || 0) > briefings.length
              ? this.detailUrl('briefing')
              : undefined
          }
          anchorText={<FormattedMessage id="Show all" />}
        >
          {briefings.map((briefing: BriefingModelType) => (
            <BriefingElement
              key={briefing.id}
              briefing={briefing}
              gradient={true}
              to={
                ROUTE_LAB +
                '/' +
                briefing.project?.organization_id +
                '/' +
                briefing.project?.id
              }
              top={
                briefing.project?.organization && (
                  <OrgCardTop org={briefing.project?.organization} />
                )
              }
            />
          ))}
        </CardSlider>
      ) : null;

    const checked = this.props.actionsStore.selectedIds;

    return (
      <ColumnWrapper gap="1em" list={true}>
        {!isNetwork ? briefingsList : null}

        {!isNetwork && painpoints.length > 0 && (
          <>
            <CardSlider
              large={this.props.largeSlider}
              responsiveConfig={smallSliderResponsiveConfig}
              title={<FormattedMessage id={'Painpoints' + headerSuffix} />}
              value={painpointsCount}
              anchor={
                (painpointsCount || 0) > painpoints.length
                  ? this.detailUrl('painpoint')
                  : undefined
              }
              anchorText={<FormattedMessage id="Show all" />}
            >
              {painpoints.map((painpoint: PainpointModelType) => (
                <PainpointsListItem
                  key={painpoint.id}
                  painpoint={painpoint}
                  contextUri={
                    ROUTE_LAB +
                    '/' +
                    painpoint.project?.organization_id +
                    '/' +
                    painpoint.project?.id
                  }
                  top={
                    painpoint.project?.organization && (
                      <OrgCardTop org={painpoint.project?.organization} />
                    )
                  }
                  onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
                  checked={checked.Painpoint.indexOf(painpoint.id) > -1}
                  onChange={(c) =>
                    this.checkChanged(
                      painpoint.project,
                      ElementType.Painpoint,
                      painpoint.id,
                      c
                    )
                  }
                  readOnly={readOnly}
                />
              ))}
            </CardSlider>
          </>
        )}

        {benchmarks.length > 0 && (
          <>
            <CardSlider
              large={this.props.largeSlider}
              title={<FormattedMessage id={'Benchmarks' + headerSuffix} />}
              value={benchmarksCount}
              anchor={
                (benchmarksCount || 0) > benchmarks.length
                  ? this.detailUrl('benchmark')
                  : undefined
              }
              anchorText={<FormattedMessage id="Show all" />}
              smallHeadline={
                !!benchmarks.find(
                  (benchmark) =>
                    benchmark.project?.organizatio &&
                    !!benchmark.attachments.firstOfType('screen')
                )
              }
            >
              {benchmarks.map((benchmark: BenchmarkModelType) => (
                <BenchmarksListItem
                  key={benchmark.id}
                  benchmark={benchmark}
                  contextUri={
                    ROUTE_LAB +
                    '/' +
                    benchmark.project?.organization_id +
                    '/' +
                    benchmark.project?.id
                  }
                  top={
                    benchmark.project?.organization && (
                      <OrgCardTop org={benchmark.project?.organization} />
                    )
                  }
                  onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
                  checked={checked.Benchmark.indexOf(benchmark.id) > -1}
                  onChange={(c) =>
                    this.checkChanged(
                      benchmark.project,
                      ElementType.Benchmark,
                      benchmark.id,
                      c
                    )
                  }
                  readOnly={readOnly}
                />
              ))}
            </CardSlider>
          </>
        )}

        {hypotheses.length > 0 && (
          <>
            <CardSlider
              large={this.props.largeSlider}
              title={<FormattedMessage id={'Hypotheses' + headerSuffix} />}
              value={hypothesesCount}
              anchor={
                (hypothesesCount || 0) > hypotheses.length
                  ? this.detailUrl('hypothesis')
                  : undefined
              }
              anchorText={<FormattedMessage id="Show all" />}
              smallHeadline={
                !!hypotheses.find(
                  (hypothese) =>
                    hypothese.project?.organizatio &&
                    !!hypothese.attachment
                )
              }
            >
              {hypotheses.map((hypothesis: HypothesisModelType) => (
                <HypothesesListItem
                  key={hypothesis.id}
                  hypothesis={hypothesis}
                  contextUri={
                    ROUTE_LAB +
                    '/' +
                    hypothesis.project?.organization_id +
                    '/' +
                    hypothesis.project?.id
                  }
                  top={
                    hypothesis.project?.organization && (
                      <OrgCardTop org={hypothesis.project?.organization} />
                    )
                  }
                  onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
                  checked={checked.Hypothesis.indexOf(hypothesis.id) > -1}
                  onChange={(c) =>
                    this.checkChanged(
                      hypothesis.project,
                      ElementType.Hypothesis,
                      hypothesis.id,
                      c
                    )
                  }
                  readOnly={readOnly}
                />
              ))}
            </CardSlider>
          </>
        )}

        {prototypes.length > 0 && (
          <>
            <CardSlider
              large={this.props.largeSlider}
              title={<FormattedMessage id={'Prototypes' + headerSuffix} />}
              value={prototypesCount}
              anchor={
                (prototypesCount || 0) > prototypes.length
                  ? this.detailUrl('prototype')
                  : undefined
              }
              anchorText={<FormattedMessage id="Show all" />}
              smallHeadline={
                !!prototypes.find(
                  (prototype) =>
                    prototype.project?.organizatio &&
                    !!prototype.sortedScreens.find(
                      (screen) =>
                        !!screen.attachments?.firstOfType('screen')
                          ?.resource_urls?.small
                    )
                )
              }
            >
              {prototypes.map((prototype: PrototypeModelType) => (
                <PrototypesListItem
                  key={prototype.id}
                  prototype={prototype}
                  contextUri={
                    ROUTE_LAB +
                    '/' +
                    prototype.project?.organization_id +
                    '/' +
                    prototype.project?.id
                  }
                  top={
                    prototype.project?.organization && (
                      <OrgCardTop org={prototype.project?.organization} />
                    )
                  }
                  onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
                  checked={checked.Prototype.indexOf(prototype.id) > -1}
                  onChange={(c) =>
                    this.checkChanged(
                      prototype.project,
                      ElementType.Prototype,
                      prototype.id,
                      c
                    )
                  }
                  readOnly={readOnly}
                />
              ))}
            </CardSlider>
          </>
        )}

        {learnings.length > 0 && (
          <>
            <CardSlider
              large={this.props.largeSlider}
              title={<FormattedMessage id={'Learnings' + headerSuffix} />}
              value={learningsCount}
              anchor={
                (learningsCount || 0) > learnings.length
                  ? this.detailUrl('learning')
                  : undefined
              }
              anchorText={<FormattedMessage id="Show all" />}
            >
              {learnings.map((learning: LearningModelType) => (
                <LearningsListItem
                  key={learning.id}
                  learning={learning}
                  contextUri={
                    ROUTE_LAB +
                    '/' +
                    learning.project?.organization_id +
                    '/' +
                    learning.project?.id
                  }
                  top={
                    learning.project?.organization && (
                      <OrgCardTop org={learning.project?.organization} />
                    )
                  }
                  onBookmarkRemoved={(t, i) => this.bookmarkRemoved(t, i)}
                  checked={checked.Learning.indexOf(learning.id) > -1}
                  onChange={(c) =>
                    this.checkChanged(
                      learning.project,
                      ElementType.Learning,
                      learning.id,
                      c
                    )
                  }
                  readOnly={readOnly}
                />
              ))}
            </CardSlider>
          </>
        )}

        {isNetwork ? briefingsList : null}

        <ActionsOverlayContainer
          showCopy={true}
          showShare={true}
          showAiBenchmark={true}
          showAiBriefing={true}
        />
      </ColumnWrapper>
    );
  }
}

export default (props: PublicListOverviewContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <ListOverviewContainer {...props} form={form} />;
};
