import { types } from 'mobx-state-tree';

const values = ['off', 'on'];

export const ModuleStateEnumModel = types.enumeration(values);

export const createModuleStateEnumModel = (value?: any): ModuleStateEnumModelType | undefined => {
  if (!value || !values.includes(value)) {
    return undefined;
  }

  return value;
};

export type ModuleStateEnumModelType = typeof ModuleStateEnumModel.Type;
export default ModuleStateEnumModel;
