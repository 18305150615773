import { FormattedMessage } from 'react-intl';

import CardHeader from 'components/CardHeader';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import shortenString from 'utils/misc/shorten-string';
import SidebarItem from '../SidebarItem';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

interface SidebarSelectedHypothesisProps {
  dataStore?: DataStoreType;
  hypothesesStore?: HypothesesStoreType;
}

function SidebarSelectedHypothesis({
  dataStore,
  hypothesesStore,
  session,
  showHeader
}: SidebarSelectedHypothesisProps & SidebarItemProps) {
  const { hypothesisItem } = dataStore!;

  return (
    <SidebarMarker type="hypothesis">
      <SidebarItem>
        <SidebarItemHeader>
          <FormattedMessage id="assistant sidebar selected hypothesis" />
        </SidebarItemHeader>

        <RainbowCard
          unresponsive={true}
          to={{
            pathname:
              ROUTE_ASSISTANT +
              '/prototypes/' +
              session!.id +
              ASSISTANTS.prototype_sprint.step2.route,
            state: {
              canGoBack: true
            }
          }}
          src={
            hypothesisItem?.attachment?.resource_urls
              ?.large
          }
          alt=""
        >
          {hypothesisItem?.headline && (
            <CardHeader title={hypothesisItem?.headline} />
          )}

          {hypothesisItem?.description && (
            <Typography size="small">
              {shortenString(
                hypothesisItem?.description,
                LIST_ITEM_TEXT_LENGTH
              )}
            </Typography>
          )}
        </RainbowCard>
      </SidebarItem>
    </SidebarMarker>
  );
}

export default inject(
  'dataStore',
  'hypothesesStore'
)(observer(SidebarSelectedHypothesis));
