import classNames from 'classnames';

import UserAvatar, { UserAvatarProps } from 'components/Avatar/UserAvatar';
import { FluidUserModelType, UserModelType } from 'models/UserModel';

interface CardMenuProps {
  user?: UserModelType | FluidUserModelType;
  avatar?: React.ReactNode; // for backward compatibility
  children?: React.ReactNode;
  small?: boolean;
  avatarProps?: UserAvatarProps;
}

export default function CardMenu({
  user,
  children,
  small,
  avatar,
  avatarProps
}: CardMenuProps) {
  const cardMenuClasses = classNames('card-menu', {
    'card-menu--small': small
  });

  return (
    <div className={cardMenuClasses}>
      {user && (
        <div className="card-menu__avatar">
          <UserAvatar user={user} {...avatarProps} />
        </div>
      )}
      {avatar && <div className="card-menu__avatar">{avatar}</div>}
      <div className="card-menu__body">{children}</div>
    </div>
  );
}
