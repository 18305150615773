import { Route, Switch } from 'react-router';

import AssistantSessionContainer from 'containers/AssistantSessionContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ASSISTANT_PROTOTYP_SESSION } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import AssistantInvitationScreen from './AssistantInvitationScreen';
import HypothesisSelectScreen from 'screens/sprints/prototyp/steps/HypothesisSelectScreen';
import HypothesisShowScreen from 'screens/sprints/prototyp/steps/HypothesisShowScreen';
import CreateUserFlowScreen from 'screens/sprints/prototyp/steps/CreateUserFlowScreen';
import ScribbleUserFlowScreen from 'screens/sprints/prototyp/steps/ScribbleUserFlowScreen';
import DescribeUserFlowScreen from 'screens/sprints/prototyp/steps/DescribeUserFlowScreen';
import FinalizePrototypSprintScreen from 'screens/sprints/prototyp/steps/FinalizePrototypSprintScreen';
import AssistantPrototypeContainer from './AssistantPrototypeContainer';

export default ({ history, match, location }: HistoryProps) => {
  // prepare Data for all views

  return (
    <AssistantSessionContainer
      history={history}
      assistantSessionId={match.params?.id}
      assistantType="prototype_sprint"
    >
      <AssistantPrototypeContainer
        history={history}
        match={match}
        location={location}
      >
        <Switch>
          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/invitation'}
            component={AssistantInvitationScreen}
          />

          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/step1'}
            component={HypothesisSelectScreen}
          />
          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/step2'}
            component={HypothesisShowScreen}
          />
          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/step3'}
            component={CreateUserFlowScreen}
          />
          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/step4'}
            component={ScribbleUserFlowScreen}
          />
          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/step5'}
            component={DescribeUserFlowScreen}
          />

          <Route
            path={ROUTE_ASSISTANT_PROTOTYP_SESSION + '/finalize'}
            component={FinalizePrototypSprintScreen}
          />

          <NotFoundRoute />
        </Switch>
      </AssistantPrototypeContainer>
    </AssistantSessionContainer>
  );
};
