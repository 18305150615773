import classNames from 'classnames';

interface FileWrapperProps {
  children: React.ReactNode;
  screen?: boolean;
  centered?: boolean;
  noMargin?: boolean;
}

export default function FileWrapper({
  children,
  screen,
  centered,
  noMargin
}: FileWrapperProps) {
  const fileWrapperClasses = classNames('l-file-wrapper', {
    'l-file-wrapper--screen': screen,
    'l-file-wrapper--centered': centered,
    'l-file-wrapper--no-margin': noMargin
  });
  return <div className={fileWrapperClasses}>{children}</div>;
}
