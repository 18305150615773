import { Route, Switch } from 'react-router';

import FaqPageDetailScreen from 'screens/FAQPages/FaqPageDetailScreen';
import { ROUTE_FAQ_PAGES } from 'utils/constants/routes';
import FaqPagesListScreen from '../screens/FAQPages/FaqPagesListScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_FAQ_PAGES + '/:id'} component={FaqPageDetailScreen} />
    <Route path={ROUTE_FAQ_PAGES} exact={true} component={FaqPagesListScreen} />
  </Switch>
);
