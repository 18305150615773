
import Icon from 'components/Icon';
import { IconNameType } from 'components/Icon/Icon';

interface IconCounterProps {
  value?: number;
  label: string;
  iconName: IconNameType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function IconCounter({
  value,
  label,
  iconName,
  onClick
}: IconCounterProps) {
  if (onClick) {
    return (
      <button
        className="icon-counter icon-counter--clickable"
        aria-label={label}
        onClick={onClick}
      >
        <Icon name={iconName} />
        {value}
      </button>
    );
  } else {
    return (
      <span className="icon-counter" aria-label={label}>
        <Icon name={iconName} />
        {value}
      </span>
    );
  }
}
