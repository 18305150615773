
import Icon from 'components/Icon';

export interface LabeledIconProps {
  icon: string;
  label: React.ReactNode;
  opacity?: number;
}

export default ({ icon, label, opacity }: LabeledIconProps) => {
  const style = Object.assign(
    {},
    typeof opacity === 'number' && opacity >= 0 && opacity <= 1
      ? { '--icon-opacity': String(opacity) }
      : {}
  ) as React.CSSProperties;

  return (
    <div className="labeled-icon" style={style}>
      <span className="labeled-icon__icon">
        <Icon name={icon} />
      </span>
      <span className="labeled-icon__label">{label}</span>
    </div>
  );
};
