import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import Bookmark from 'components/Bookmark';
import CircleChart from 'components/CircleChart/CircleChart';
import EditMenu from 'components/EditMenu';
import IconCounter from 'components/IconCounter';
import MenuButton from 'components/MenuButton';
import { ProjectModelType } from 'models/ProjectModel';

interface ProjectCardSmallProps {
  project: ProjectModelType;
  checked?: boolean;
  linkto: string;
  onCheckboxChange?: (checked: boolean) => void;
  onDeleteClick?: () => void;
}

export const ProjectCardSmall = ({
  project,
  checked,
  linkto,
  intl,
  onCheckboxChange,
  onDeleteClick
}: ProjectCardSmallProps & WrappedComponentProps) => {
  const ProjectCardClasses = classNames('project-card-small ');

  const renderCheckbox = () => {
    if (!onCheckboxChange) {
      return null;
    }
    return (
      <div className="card-checkbox">
        <label>
          <input
            className="card-checkbox__box"
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
            type="checkbox"
          />
        </label>
      </div>
    );
  };

  let menu;
  if (onDeleteClick) {
    menu = (
      <EditMenu bottomRight={true} label={intl.formatMessage({ id: 'Edit' })}>
        {/* TODO make available as link to linkto + '/settings' ?
        <MenuButton
          label={intl.formatMessage({ id: 'Edit' })}
          iconName="pen"
          onClick={() => this.edit(text)}
        />*/}
        <MenuButton
          label={intl.formatMessage({ id: 'Remove' })}
          iconName="bin"
          onClick={() => onDeleteClick()}
        />
      </EditMenu>
    );
  }

  const hasInvites = !!(
    project.assistant_invites?.total_count ||
    project.assistant_invites?.published_count
  );
  const progress = hasInvites
    ? Math.round(
        ((project.assistant_invites!.published_count || 0) /
          (project.assistant_invites!.total_count || 0)) *
          100
      )
    : 0;

  return (
    <article className={ProjectCardClasses}>
      <div className="project-card-small__body">
        {hasInvites && progress < 100 && (
          <div className="project-card-small__icon">
            <CircleChart progress={progress} label={progress + '%'} />
          </div>
        )}

        {project.private && (
          <div className="project-card-small__private-badge">
            <span className="project-card-small__private-label">
              #{intl.formatMessage({ id: 'Private' })}
            </span>
          </div>
        )}
        <Link to={linkto}>
          <div className="project-card-small__headline">{project.topic}</div>
        </Link>

        {renderCheckbox()}
        <div className="project-card-small__footer">
          <div className="project-card-small__left-footer">
            <Bookmark
              elementType="Project"
              elementId={project.id}
              count={project.bookmarks_count}
              id={project.bookmark_id}
            />

            {!!project.painpoints_count && (
              <IconCounter
                value={project.painpoints_count}
                label={intl.formatMessage({ id: 'Painpoints' })}
                iconName="clipboard"
              />
            )}
            {!!project.benchmarks_count && (
              <IconCounter
                value={project.benchmarks_count}
                label={intl.formatMessage({ id: 'Benchmarks' })}
                iconName="detail"
              />
            )}
            {!!project.hypotheses_count && (
              <IconCounter
                value={project.hypotheses_count}
                label={intl.formatMessage({ id: 'Hypotheses' })}
                iconName="bulb"
              />
            )}
            {!!project.prototypes_count && (
              <IconCounter
                value={project.prototypes_count}
                label={intl.formatMessage({ id: 'Prototypes' })}
                iconName="strategy"
              />
            )}
            {!!project.learnings_count && (
              <IconCounter
                value={project.learnings_count}
                label={intl.formatMessage({ id: 'Learnings' })}
                iconName="learning"
              />
            )}
          </div>

          {menu}
        </div>
      </div>
    </article>
  );
};

export default injectIntl(ProjectCardSmall);
