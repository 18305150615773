import { types } from 'mobx-state-tree';

import PROJECT_ACCESS_LEVELS from 'utils/constants/project-access-levels';

const values = [PROJECT_ACCESS_LEVELS.UNKNOWN, PROJECT_ACCESS_LEVELS.MEMBER];

export const ProjectAccessLevelEnumModel = types.enumeration(values);

export const createProjectAccessLevelEnumModel = (
  value?: any
): ProjectAccessLevelEnumModelType | undefined => {
  if (!value || !values.includes(value)) {
    return undefined;
  }

  return value;
};

export type ProjectAccessLevelEnumModelType = typeof ProjectAccessLevelEnumModel.Type;
export default ProjectAccessLevelEnumModel;
