import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_BENCHMARKS } from 'utils/constants/routes';
import BenchmarksCreateScreen from './BenchmarksCreateScreen';
import BenchmarksDetailScreen from './BenchmarksDetailScreen';
import BenchmarksEditScreen from './BenchmarksEditScreen';
import BenchmarksListScreen from './BenchmarksListScreen';
import BenchmarksRecommendationsScreen from './BenchmarksRecommendationsScreen';

// const Dummy = () => <>benchmarks</>;

export default () => (
  <ContextAwareProjectLayout active="benchmarks">
    <Switch>
      <Route
        path={ROUTE_BENCHMARKS}
        exact={true}
        component={BenchmarksListScreen}
      />
      <Route
        path={ROUTE_BENCHMARKS + '/create'}
        exact={true}
        component={BenchmarksCreateScreen}
      />
      <Route
        path={ROUTE_BENCHMARKS + '/inspiration'}
        exact={true}
        component={BenchmarksRecommendationsScreen}
      />
      <Route
        path={ROUTE_BENCHMARKS + '/:id'}
        exact={true}
        component={BenchmarksDetailScreen}
      />
      <Route
        path={ROUTE_BENCHMARKS + '/:id/edit'}
        exact={true}
        component={BenchmarksEditScreen}
      />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
