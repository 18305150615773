import DisrooptiveApi from 'api/DisrooptiveApi';
import { flow, getEnv, types } from 'mobx-state-tree';

import { ApiElementTypeEnumType } from './ApiElementTypeEnum';
import { ApplicationStoreType } from './ApplicationStore';
import { AttachmentTypeEnumType, createAttachmentModel } from './AttachmentModel';

export type AttachmentRotationEnum = 0 | 90 | 180 | 270;

interface EnvType {
  client: DisrooptiveApi;
  applicationStore: ApplicationStoreType;
}

const AttachmentsStore = types
  .model('AttachmentsStore', {
    itemLoadingState: types.maybe(
      types.enumeration([
        'loading',
        'load_error',
        'saving',
        'save_error',
        'access_denied',
        'not_found'
      ])
    )
  })
  .actions(self => {
    const upload = flow(function*(
      file: File,
      attachmentType: AttachmentTypeEnumType,
      elementType: ApiElementTypeEnumType,
      elementId?: number,
      projectId?: number,
      rotation?: AttachmentRotationEnum
    ) {
      const { client, applicationStore }: EnvType = getEnv(self);

      if (!projectId) {
        projectId = applicationStore.currentProject?.id;
      }

      try {
        const result: any = yield client.uploadAttachment(
          attachmentType,
          elementType,
          elementId,
          file,
          projectId,
          rotation
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const attachment = createAttachmentModel(result);
        return attachment;
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('AttachmentsStore | upload', error, error.body);
        }

        if (client.isFormError(error)) {
          throw error;
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          // TODO handle this globally somehow?
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          throw new Error('app_error');
        }

        throw new Error('save_error');
      }
    });

    const deleteAttachment = flow(function*(attachmentId: number) {
      const { client, applicationStore }: EnvType = getEnv(self);

      try {
        yield client.deleteAttachment(attachmentId);
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'AttachmentsStore | deleteAttachment',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // okay then
          return;
        }

        if (client.isAccessDenied(error)) {
          // TODO handle this globally somehow?
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          throw new Error('app_error');
        }

        throw new Error('remove_error');
      }
    });

    return {
      upload,
      deleteAttachment
    };
  })
  .views(self => {
    return {
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return self.itemLoadingState === 'save_error';
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied'
        );
      }
    };
  });

export type AttachmentsStoreType = typeof AttachmentsStore.Type;
export default AttachmentsStore;
