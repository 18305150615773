import classNames from 'classnames';
import React from 'react';

import InputInterface, { cleanupInputProps } from './InputInterface';
import InputStateContainer from './InputStateContainer';

type HtmlInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface TextInputProps {
  headline?: boolean;
  subheadline?: boolean;
  login?: boolean;
  normalFont?: boolean;
}

export type SelectProps = HtmlInputProps & InputInterface & TextInputProps;

export default React.forwardRef((props: SelectProps, ref: any) => {
  const inputClasses = classNames('edit-input__input', {
    'edit-input__input--gap': props.gap,
    'edit-input__input--simple-headline': props.headline,
    'edit-input__input--simple-subheadline': props.subheadline,
    'edit-input__input--normal-font': props.normalFont
  });

  const editInputClasses = classNames('edit-input', {
    'edit-input--login': props.login
  });

  const id = props.id ? props.id : '__formfield_' + props.name;
  return (
    <div className={editInputClasses}>
      {props.label && <label htmlFor={id}>{props.label}</label>}
      <InputStateContainer error={props.error} success={props.success}>
        <div className="edit-input__select-wrapper">
          <select
            id={id}
            ref={ref}
            className={inputClasses}
            size={1}
            {...cleanupInputProps(props)}
          />
        </div>
      </InputStateContainer>
    </div>
  );
});
