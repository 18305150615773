import CardWrapper from 'components/CardWrapper';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import {
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantSessionModelType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface HypothesisScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  form: FormType;
}

@inject('applicationStore', 'assistantStore')
@observer
class HypothesisScreen extends React.Component<
  HypothesisScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    this.fillForm();

    scrollSidebar('hypothesis');
  }

  private fillForm() {
    const { form } = this.props;
    const { currentItem } = this.props.assistantStore;

    if (!currentItem) {
      return;
    }

    form.setField('hypothesis_headline', currentItem.hypothesis_headline);
    form.setField('hypothesis_description', currentItem.hypothesis_description);
    form.setField('hypothesis_added_value', currentItem.hypothesis_added_value);
  }

  private async submit(continueSession: boolean = false) {
    const { applicationStore, assistantStore, intl, form } = this.props;
    const { currentId, currentAssistantType } = assistantStore;

    if (!currentId || !currentAssistantType) {
      return;
    }

    form.resetErrors();

    const patch: Partial<AssistantSessionModelType> = {
      hypothesis_headline: form.values.hypothesis_headline || '',
      hypothesis_description: form.values.hypothesis_description || '',
      hypothesis_added_value: form.values.hypothesis_added_value || '',
      hypothesis_date: new Date().toJSON()
    };

    if (continueSession) {
      patch.progress = ASSISTANTS.creative_session.step4_2.progress;
    }

    let newAssistantSession;
    try {
      newAssistantSession = await assistantStore.updateAssistantSession(
        currentId,
        patch
      );
    } catch (error: any) {
      if (handleFormError(form, error)) {
        return;
      }
    }

    if (newAssistantSession) {
      this.fillForm();
    } else {
      // TODO change updateAssistantSession to throw any error?
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'save error' }),
        'error'
      );
      return;
    }

    if (continueSession) {
      goToNextStep(
        currentId,
        ASSISTANTS.creative_session.step4_2.progress,
        currentAssistantType
      );
    } else {
      this.props.history.push(ROUTE_ASSISTANT);
    }
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    this.props.form.setLoading(newState === 'saving');
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { currentItem } = this.props.assistantStore;

    if (!currentItem || !attachment) {
      return;
    }

    currentItem.attachments.put(attachment);
  }

  attachmentRemoved(id: number) {
    const { currentItem } = this.props.assistantStore;

    if (!currentItem) {
      return;
    }

    currentItem.attachments.delete(id);
  }

  renderForm(session: AssistantSessionModelType) {
    const { form, intl } = this.props;

    const attachment: AttachmentModelType | undefined =
      session.attachments.firstOfType('screen');

    const nextStepDisabled =
      !attachment ||
      !form.values.hypothesis_headline ||
      !form.values.hypothesis_description ||
      !form.values.hypothesis_added_value;

    return (
      <ColumnWrapper gap="2em" noTranslate={true}>
        <RainbowEditWrapper>
          <RainbowEditWrapper.Image>
            {attachment ? (
              <UploadedFileContainer
                attachment={attachment}
                onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                key={attachment.id}
              >
                {(props) => <UploadedMobileImage {...props} overflow={true} />}
              </UploadedFileContainer>
            ) : (
              <UploadContainer
                attachmentType="screen"
                elementType={ElementType.AssistantSession}
                elementId={session.id}
                onLoadingStateChanged={(s) => this.uploadLoadingStateChanged(s)}
                onAttachmentReceived={(a) => this.attachmentReceived(a)}
              >
                {(props: UploadContainerChildProps) => (
                  <UploadMobileButton {...props} overflow={true} />
                )}
              </UploadContainer>
            )}
          </RainbowEditWrapper.Image>

          <RainbowEditWrapper.Body>
            <CardWrapper>
              <Input
                headline={true}
                name="hypothesis_headline"
                placeholder={intl.formatMessage({
                  id: 'Insert headline'
                })}
                {...form.bindInput('hypothesis_headline')}
              />
            </CardWrapper>

            <CardWrapper>
              <Textarea
                label={<FormattedMessage id="solution description" />}
                name="hypothesis_description"
                {...form.bindInput('hypothesis_description')}
              />
            </CardWrapper>

            {/* <CardWrapper>
                <Textarea
                  label={<FormattedMessage id="problem description" />}
                  name="hypothesis_problem"
                  {...form.bindInput('hypothesis_problem')}
                />
              </CardWrapper> */}

            <CardWrapper>
              <Textarea
                label={<FormattedMessage id="added value description" />}
                name="hypothesis_added_value"
                {...form.bindInput('hypothesis_added_value')}
              />
            </CardWrapper>
          </RainbowEditWrapper.Body>
        </RainbowEditWrapper>

        <PositionWrapper end={true}>
          <RowWrapper gap="1em" alignRight={true}>
            <MainButton
              type="button"
              secondary={true}
              onClick={() => this.submit()}
            >
              <FormattedMessage id="Save draft" />
            </MainButton>
            <MainButton
              type="submit"
              onClick={() => this.submit(true)}
              disabled={nextStepDisabled}
            >
              <FormattedMessage id="Next" />
            </MainButton>
          </RowWrapper>
        </PositionWrapper>
      </ColumnWrapper>
    );
  }

  render() {
    const { currentItem, currentAssistantType, isItemSaving } =
      this.props.assistantStore;

    if (!currentItem) {
      return null;
    }

    const stepInfo = getStepInfo('step4_2', currentAssistantType);

    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="hypothesis"
        location={this.props.location}
        timerSeconds={900}
      >
        {isItemSaving && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant hypothesis header" />
          </SessionHeadline>
        </SessionTop>

        <SessionMainText noMargin={true}>
          <FormattedMessage id="assistant hypothesis" />
        </SessionMainText>

        <div style={{ margin: 0, paddingBottom: '2em' }}>
          <VideoPopupLink
            videoId="hCgMr0M9gGY"
            // headline={videoHeadline}
            // preview={videoPreview}
          />
        </div>

        {this.renderForm(currentItem)}
      </StepContainer>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <HypothesisScreen {...props} form={form} />;
});
