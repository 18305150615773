
interface AvatarBubbleProps {
  content?: React.ReactNode;
  children?: any;
}

export const AvatarBubble = ({ content, children }: AvatarBubbleProps) => {
  if (!content) {
    return children;
  }

  return (
    <div className="avatar-bubble">
      <div className="bubble">
        <div className="bubble__content">{content}</div>
      </div>

      {children}
    </div>
  );
};

export default AvatarBubble;
