
import CompanyLogo from 'components/CompanyLogo';
import { BenchmarkModelType } from 'models/BenchmarkModel';

export const AssistantItemBenchmark = ({
  benchmark
}: {
  benchmark: BenchmarkModelType;
}) => {
  const logo = benchmark.attachments.firstOfType('logo')?.resource_urls?.small;
  const screen =
    benchmark.attachments.firstOfType('screen')?.resource_urls?.small;

  return (
    <div className="inspiration-item">
      <h4>{benchmark.company_name || benchmark.headline}</h4>
      <div className="logo-with-thumbnail">
        <div className="logo-with-thumbnail__thumbnail">
          {screen ? (
            <div className="phone-thumbnail">
              <img src={screen} alt="Screenshot" />
            </div>
          ) : (
            <div className="phone-thumbnail-empty" />
          )}
        </div>
        <div className="logo-with-thumbnail__logo">
          {logo && <CompanyLogo alt="Logo" imgUrl={logo} />}
        </div>
      </div>
    </div>
  );
};

export default AssistantItemBenchmark;
