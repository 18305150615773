import { types } from 'mobx-state-tree';

export const TagModel = types.model('TagModel', {
  tag: types.string,
  created_at: types.maybe(types.string)
});

export const createTagModel = (data: any): TagModelType => {
  return {
    tag: data?.tag || '',
    created_at: data?.created_at || undefined
  };
};

export type TagModelType = typeof TagModel.Type;
export default TagModel;
