import { inject, observer } from 'mobx-react';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import AddButton from 'components/AddButton';
import FileWrapper from 'components/Layout/FileWrapper';
import Loading from 'components/Loading';
import { DataStoreType } from 'models/DataStore';
import { PrototypeModelType } from 'models/PrototypeModel';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import useForm, { FormType } from 'utils/hooks/useForm';
import PrototypeScreensItem from './PrototypeScreensItem';
import PrototypeScreensItemDummy from './PrototypeScreensItemDummy';
import PrototypeEditPopup from './PrototypeEditPopup';

interface PublicPrototypeScreensContainerProps extends WrappedComponentProps {
  prototype?: PrototypeModelType;
  editable?: boolean;
  create?: boolean;
  onNewScreen?: (screen: PrototypeScreenModelType) => void;
}

interface PrototypeScreensContainerProps
  extends PublicPrototypeScreensContainerProps {
  dataStore: DataStoreType;
  prototypesStore: PrototypesStoreType;
  form: FormType;
}

interface PrototypeScreensContainerState {
  adding?: boolean;
  sorting?: boolean;
  dummyAutoFocus?: 'title' | 'file';
  editingScreen?: PrototypeScreenModelType;
  width: number;
  height: number;
}

@inject('dataStore', 'prototypesStore')
@observer
class PrototypeScreensContainer extends React.Component<
  PrototypeScreensContainerProps,
  PrototypeScreensContainerState
> {
  state: PrototypeScreensContainerState = {
    adding: false,
    sorting: false,
    editingScreen: undefined,
    width: window.innerWidth,
    height: window.innerHeight
  };

  // Lifecycle method to add the event listener when the component mounts
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  // Lifecycle method to remove the event listener before the component unmounts to prevent memory leaks
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // Event handler to update state with the new window size, triggering a re-render
  handleResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  async add(addSecond: boolean = false, dummyAutoFocus?: 'title' | 'file') {
    let { prototype } = this.props;
    const { prototypesStore, onNewScreen } = this.props;

    this.setState({
      adding: true,
      dummyAutoFocus: dummyAutoFocus || undefined
    });

    let newScreen;
    try {
      newScreen = await prototypesStore.createScreen({}, prototype);

      if (newScreen && onNewScreen) {
        onNewScreen(newScreen);
      }
    } catch (e) {
      // TODO what to do here?
    }

    if (addSecond && (prototype || newScreen)) {
      // when add button is clicked while there is no screen yet, we actually need to create two screens here
      if (!prototype && newScreen?.prototype_id) {
        prototype = this.props.dataStore.prototypes.get(
          newScreen.prototype_id.toString()
        );
      }
      if (prototype) {
        try {
          await prototypesStore.createScreen({}, prototype);
        } catch (e) {
          // TODO what to do here?
        }
      }
    }

    this.setState({
      adding: false
    });
  }

  async move(id: number, direction: 'left' | 'right') {
    const { prototype } = this.props;
    if (!prototype) {
      return;
    }

    this.setState({
      sorting: true
    });

    try {
      await this.props.prototypesStore.moveScreen(id, direction, prototype);
    } catch (e) {
      // TODO what to do here?
    }

    this.setState({
      sorting: false
    });
  }

  dummyClicked(focus: 'title' | 'file') {
    this.add(false, focus);
  }

  didAutoFocus() {
    this.setState({
      dummyAutoFocus: undefined
    });
  }

  render() {
    const { prototype, editable } = this.props;
    const { adding } = this.state;

    const screens = prototype?.sortedScreens;
    const max = (screens?.length || 0) - 1;

    const hasScreens = !!prototype?.hasScreens;
    const dummyVisible = editable && !hasScreens;
    const hasOnlyOne = screens?.length === 1;

    const evenScreenLength = screens && screens?.length % 2 === 0;
    const fullRow = screens && screens?.length % 3 === 0;

    let threeColumns = false;
    if (
      (window.screen.width >= 862 && window.screen.width < 1128) ||
      window.screen.width >= 1224
    ) {
      // we can show three items in a row
      threeColumns = true;
    }

    let calculateMarginTop;
    if (window.screen.width > 541) {
      if (threeColumns) {
        calculateMarginTop = '22em';
        if (fullRow) {
          calculateMarginTop = '9em';
        }
      } else {
        calculateMarginTop = '22em';
        if (evenScreenLength) {
          calculateMarginTop = '9em';
        }
      }
    }

    return (
      <>
        {(this.state.sorting || (!hasScreens && adding)) && <Loading />}

        {/* The div is dirty, but it is the easiest way right now */}
        <div
          style={
            this.props.create
              ? { marginTop: 0 }
              : {
                  marginTop: 0,
                  marginBottom:
                    window.screen.width < 542 ||
                    (threeColumns && fullRow) ||
                    (window.screen.width > 542 &&
                      !threeColumns &&
                      evenScreenLength)
                      ? '-6em'
                      : '6em'
                }
          }
        >
          <FileWrapper screen={true} centered={true}>
            {dummyVisible && (
              <PrototypeScreensItemDummy
                onTitleClick={() => this.dummyClicked('title')}
                onScreenClick={() => this.dummyClicked('file')}
              />
            )}

            {screens &&
              screens.map((screen, idx) => (
                <PrototypeScreensItem
                  onlyOne={hasOnlyOne}
                  autoFocus={
                    editable && idx === 0
                      ? this.state.dummyAutoFocus
                      : undefined
                  }
                  index={idx}
                  onAutoFocus={() => this.didAutoFocus()}
                  key={screen.id}
                  screen={screen}
                  prototype={prototype}
                  editable={editable}
                  first={idx === 0}
                  last={idx === max}
                  onMove={(i, d) => this.move(i, d)}
                  isEditing={this.state.editingScreen === screen}
                  onEdit={() => this.setState({ editingScreen: screen })}
                />
              ))}

            {editable && (
              <AddButton
                screen={true}
                iconName="plus"
                style={{
                  marginTop: calculateMarginTop
                }}
                label={this.props.intl.formatMessage({ id: 'Add screen' })}
                onClick={() => this.add(dummyVisible)}
                loading={adding && hasScreens}
              />
            )}
          </FileWrapper>
        </div>
        {this.state.editingScreen && (
          <PrototypeEditPopup
            onEditDone={() => {
              this.setState({
                editingScreen: undefined
              });
            }}
            screen={this.state.editingScreen}
            prototype={prototype!}
          />
        )}
      </>
    );
  }
}

export default injectIntl((props: PublicPrototypeScreensContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <PrototypeScreensContainer {...props} form={form} />;
});
