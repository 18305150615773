import classNames from 'classnames';

interface CardWrapperProps {
  top?: React.ReactNode;
  children?: React.ReactNode;
  gradient?: boolean;
  subtle?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function CardWrapper({
  top,
  children,
  gradient,
  subtle,
  onClick
}: CardWrapperProps) {
  const cardWrapperClasses = classNames('card-wrapper', {
    'card-wrapper--gradient': gradient,
    'card-wrapper--top': top,
    'card-wrapper--subtle': subtle,
    'card-wrapper--clickable': !!onClick
  });

  if (!!onClick) {
    return (
      <div className={cardWrapperClasses} onClick={onClick}>
        {top}
        {children}
      </div>
    );
  } else {
    return (
      <div className={cardWrapperClasses}>
        {top}
        {children}
      </div>
    );
  }
}
