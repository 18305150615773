import classNames from 'classnames';

interface ScreenItemProps {
  children: React.ReactNode;
  asUserflow?: boolean;
  small?: boolean;
}

export default function ScreenItem({
  children,
  asUserflow,
  small
}: ScreenItemProps) {
  const baseName = asUserflow ? 'l-user-flow-item' : 'l-screen-item';

  return (
    <div
      className={classNames(baseName, {
        [`${baseName}--small`]: small
      })}
    >
      <div
        className={classNames(`${baseName}__arrow`, {
          [`${baseName}__arrow--small`]: small
        })}
      >
        <div
          className={classNames(`${baseName}__arrow-head`, {
            [`${baseName}__arrow-head--small`]: small
          })}
        />
      </div>
      {children}
    </div>
  );
}
