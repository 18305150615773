import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SessionMainText } from 'domain/assistant/session-elements';
import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS, getStepInfo } from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';
import ExplanationScreen from '../ExplanationScreen';

interface PainpointIntroScreenProps {
  assistantStore: AssistantStoreType;
}

@inject('assistantStore')
@observer
class PainpointIntroScreen extends React.Component<
  PainpointIntroScreenProps & HistoryProps
> {
  // componentDidMount() {
  //   scrollSidebar('material');
  // }

  render() {
    const { currentItem, currentAssistantType } = this.props.assistantStore;

    const stepInfo = getStepInfo('step1_1', currentAssistantType);
    return (
      <ExplanationScreen
        location={this.props.location}
        progress={
          stepInfo?.progress || ASSISTANTS.creative_session.step1_1.progress
        }
        barProgress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        header={<FormattedMessage id="assistant painpoint intro header" />}
        headerStep={stepInfo?.header}
        videoId="hCgMr0M9gGY"
        videoPreview="/images/assistant/video/painpoint_intro.png"
        videoHeadline={
          <FormattedMessage id="assistant painpoint intro video header" />
        }
        timerSeconds={300}
        sidebarScrollTo="material"
      >
        <SessionMainText>
          <FormattedMessage id="assistant painpoint intro" />
          <br />
          <em>
            <FormattedMessage
              id="assistant painpoint intro briefing"
              values={{
                briefing: currentItem?.project?.briefing?.question || '-'
              }}
            />
          </em>
        </SessionMainText>
      </ExplanationScreen>
    );
  }
}

export default PainpointIntroScreen;
