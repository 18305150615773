import classNames from 'classnames';

interface ColumnWrapperProps {
  children: any;
  gap?: string;
  list?: boolean;
  noTranslate?: boolean;
}

export default function ColumnWrapper({
  children,
  gap,
  list,
  noTranslate
}: ColumnWrapperProps) {
  const style = { '--column-gap': gap } as React.CSSProperties;

  const columnWrapperClasses = classNames('l-column-wrapper', {
    'l-column-wrapper--list': list,
    'l-column-wrapper--noTranslate': noTranslate
  });

  return (
    <div className={columnWrapperClasses} style={style}>
      {children}
    </div>
  );
}
