import { getParent, types } from 'mobx-state-tree';
import createMap from 'utils/store/createMap';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import {
  AttachmentModelType,
  AttachmentsMapModel,
  createAttachmentsMap
} from './AttachmentModel';
import { PrototypeModelType } from './PrototypeModel';

export const PrototypeScreenModel = types
  .model('PrototypeScreenModel', {
    id: types.identifierNumber,
    prototype_id: types.maybe(types.number),
    assistant_session_id: types.maybe(types.number),
    order: types.maybe(types.number),
    headline: types.maybe(types.string),
    description: types.maybe(types.string),
    attachments: types.maybe(AttachmentsMapModel)
  })
  .actions((self) => ({
    setOrder(order: number) {
      self.order = order;
    },

    addAttachment(attachment: AttachmentModelType) {
      if (self.attachments) {
        self.attachments.put(attachment);
      } else {
        self.attachments = createMap([attachment]);
      }
    }
  }))
  .views((self) => ({
    // can't get this to return the current Type without running in failures
    get prototype(): any {
      return getParent<PrototypeModelType>(self, 2);
    }
  }))
  .views((self) => ({
    get isLast() {
      return (
        self.prototype.sortedScreens[
          self.prototype.sortedScreens.length - 1
        ] === self
      );
    },

    get isFirst() {
      return self.prototype.sortedScreens[0] === self;
    }
  }));

export const createPrototypeScreenModel = (
  data?: any
): PrototypeScreenModelType => {
  return PrototypeScreenModel.create({
    id: mandatoryId(data?.id),
    prototype_id: mandatoryId(data?.prototype_id),
    assistant_session_id: mandatoryId(data?.assistant_session_id),
    order: numberOrUndefined(data?.order),
    headline: data?.headline || undefined,
    description: data?.description || undefined,
    attachments: createAttachmentsMap(data?.attachments)
  });
};

export type PrototypeScreenModelType = typeof PrototypeScreenModel.Type;
export default PrototypeScreenModel;
