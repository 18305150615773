
interface CardTopProps {
  logo?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export default function CardTop({ logo, title, subtitle }: CardTopProps) {
  return (
    <div className="card-top">
      {logo}
      <div className="card-top__inner">
        <h1 className="card-top__title">{title}</h1>
        <h2 className="card-top__subtitle">{subtitle}</h2>
      </div>
    </div>
  );
}
