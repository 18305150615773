import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import CardHeader from 'components/CardHeader';
import CardWrapper from 'components/CardWrapper';
import EditButton from 'components/EditButton';
import Headline from 'components/headline';
import AssistantGrid from 'components/Layout/AssistantGrid';
import AutoGrid from 'components/Layout/AutoGrid';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import Flow from 'components/Layout/Flow';
import GridCard from 'components/Layout/GridCard';
import MainButton from 'components/MainButton';
import MobilePreview from 'components/MobilePreview';
import Typography from 'components/Typography';
import { AssistantSessionModelType } from 'models/AssistantSessionModel';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { GapSizes } from 'utils/constants/gap-sizes';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history } from 'utils/history';
import { MAX_SOLUTIONS } from '../session3/SolutionFormComponent';
import AssistantItemBenchmark from './AssistantItemBenchmark';
import { AssistantItemSolution } from './AssistantItemSolution';

interface AssistantListScreenItemProps {
  assistantSession?: AssistantSessionModelType;
  isPlaceholer?: boolean;
  onButtonClick?: (step: number) => void;
  onDeleteClick?: () => void;
}

export const AssistantListScreenItem = injectIntl(
  ({
    intl,
    assistantSession: session,
    isPlaceholer,
    onButtonClick,
    onDeleteClick
  }: AssistantListScreenItemProps & WrappedComponentProps) => {
    // TODO Each session has 3 states: empty, partial, finished
    // Empty sessions can be started, partial can be continued, finished show on rainbow card
    // The progress counter is incremented by 1000 for each finished session and by 1 for each
    // step of a session. On finishing a session, the step counter is reset. E.g. 2003 means
    // the 2nd session is completed and the 3rd step of the 3rd session is completed.
    const progress: number = session?.progress || 0;

    let header;
    if (isPlaceholer) {
      header = <FormattedMessage id="assistant create first session" />;
    } else {
      header = session?.project?.name;
    }

    const solutions = session?.sortedSolutionsWithBenchmarks;
    const solutionsCount = solutions?.length || 0;

    const benchmarks = session?.sortedBenchmarks || [];
    const benchmarksCount = benchmarks.length;

    const step1Active =
      progress >= ASSISTANTS.creative_session.introduction.progress;
    const step2Active =
      (progress >= ASSISTANTS.creative_session.step1_4.progress &&
        benchmarksCount > 0) ||
      progress >= ASSISTANTS.creative_session.step2_1.progress;
    const step3Active =
      progress >= ASSISTANTS.creative_session.step3_1.progress;
    const step4Active =
      progress >= ASSISTANTS.creative_session.step4_1.progress;

    const attachment = session?.attachments.firstOfType('screen');

    const published = !!session?.published;
    const showEditLink = !!session?.id && !published;

    return (
      <CardWrapper subtle={true}>
        {header && (
          <CardHeader
            title={header}
            onEditClick={onDeleteClick}
            editIcon="bin"
          />
        )}

        <AssistantGrid>
          <GridCard hasGradient={step1Active}>
            <Flow>
              <Headline tag="h3" size="M" opaque={!step1Active}>
                {progress >= ASSISTANTS.creative_session.step1_4.progress &&
                  showEditLink && (
                    <span style={{ float: 'right' }}>
                      <EditButton
                        label={intl.formatMessage({ id: 'Edit' })}
                        onClick={() =>
                          history.push(
                            ROUTE_ASSISTANT +
                              '/' +
                              session!.id +
                              ASSISTANTS.creative_session.step1_2.route
                          )
                        }
                      />
                    </span>
                  )}
                1. <FormattedMessage id="assistant step 1 heading" />
              </Headline>
              <Typography size="smaller" opaque={!step1Active}>
                {session?.painpoint_description || (
                  <FormattedMessage id="assistant step 1 description" />
                )}
              </Typography>

              <Headline tag="h3" size="M" opaque={!step1Active}>
                2. <FormattedMessage id="assistant step 2 heading" />
              </Headline>
              <Typography size="smaller" opaque={!step1Active}>
                {session?.painpoint_question || (
                  <FormattedMessage id="assistant step 2 description" />
                )}
              </Typography>

              {isPlaceholer ||
              (!published &&
                progress < ASSISTANTS.creative_session.step1_4.progress) ? (
                <MainButton
                  onClick={() =>
                    onButtonClick &&
                    onButtonClick(
                      isPlaceholer
                        ? ASSISTANTS.creative_session.introduction.progress
                        : ASSISTANTS.creative_session.step1_1.progress
                    )
                  }
                >
                  {isPlaceholer || progress === 0 ? (
                    <FormattedMessage
                      id="assistant start nth session"
                      values={{ n: 1 }}
                    />
                  ) : (
                    <FormattedMessage id="assistant continue session" />
                  )}
                </MainButton>
              ) : null}
            </Flow>

            {/* <div className="grid-card__footer">
              <CardMenu avatar={undefined} small={true}>
                <EditMenu label={intl.formatMessage({ id: 'Edit' })}>
                  <MenuButton
                    label={intl.formatMessage({ id: 'Remove' })}
                    description={intl.formatMessage({
                      id: 'remove session label'
                    })}
                    iconName="bin"
                    onClick={onDeleteClick}
                  />
                </EditMenu>
              </CardMenu>
            </div> */}
          </GridCard>

          <GridCard hasGradient={step2Active}>
            <Flow>
              <Headline tag="h3" size="M" opaque={!step2Active}>
                {progress >= ASSISTANTS.creative_session.step2_1.progress &&
                  showEditLink && (
                    <span style={{ float: 'right' }}>
                      <EditButton
                        label={intl.formatMessage({ id: 'Edit' })}
                        onClick={() =>
                          history.push(
                            ROUTE_ASSISTANT +
                              '/' +
                              session!.id +
                              ASSISTANTS.creative_session.step2_1.route
                          )
                        }
                      />
                    </span>
                  )}
                3. <FormattedMessage id="assistant step 3 heading" />
              </Headline>

              {benchmarksCount > 0 ? (
                <AutoGrid gap="0.5rem" gapVertical="1.5rem" minSize="5.375rem">
                  {benchmarks!.map((item) => (
                    <AssistantItemBenchmark key={item.id} benchmark={item} />
                  ))}
                </AutoGrid>
              ) : (
                <Typography size="smaller" opaque={!step2Active}>
                  <FormattedMessage id="assistant step 3 description" />
                </Typography>
              )}

              {!isPlaceholer &&
                !published &&
                progress >= ASSISTANTS.creative_session.step1_4.progress &&
                progress < ASSISTANTS.creative_session.step2_1.progress && (
                  <MainButton
                    onClick={() =>
                      onButtonClick &&
                      onButtonClick(
                        ASSISTANTS.creative_session.step2_1.progress
                      )
                    }
                  >
                    {benchmarksCount < 1 ? (
                      <FormattedMessage
                        id="assistant start nth session"
                        values={{ n: 2 }}
                      />
                    ) : (
                      <FormattedMessage id="assistant continue session" />
                    )}
                  </MainButton>
                )}
            </Flow>
          </GridCard>

          <GridCard hasGradient={step3Active}>
            <Flow>
              <Headline tag="h3" size="M" opaque={!step3Active}>
                {progress >= ASSISTANTS.creative_session.step3_2.progress &&
                  showEditLink && (
                    <span style={{ float: 'right' }}>
                      <EditButton
                        label={intl.formatMessage({ id: 'Edit' })}
                        onClick={() => {
                          const query =
                            solutions!.length >= MAX_SOLUTIONS
                              ? '?solution=1'
                              : '';

                          history.push(
                            ROUTE_ASSISTANT +
                              '/' +
                              session!.id +
                              ASSISTANTS.creative_session.step3_2.route +
                              query
                          );
                        }}
                      />
                    </span>
                  )}
                4. <FormattedMessage id="assistant step 4 heading" />
              </Headline>

              {solutionsCount > 0 ? (
                <AutoGrid minSize="15rem">
                  {solutions!.map(({ solution, benchmark }) => (
                    <AssistantItemSolution
                      key={solution.id}
                      solution={solution}
                      benchmark={benchmark}
                    />
                  ))}
                </AutoGrid>
              ) : (
                <Typography size="smaller" opaque={!step3Active}>
                  <FormattedMessage id="assistant step 4 description" />
                </Typography>
              )}

              {!isPlaceholer &&
                !published &&
                progress >= ASSISTANTS.creative_session.step2_1.progress &&
                progress < ASSISTANTS.creative_session.step3_2.progress && (
                  <MainButton
                    onClick={() =>
                      onButtonClick &&
                      onButtonClick(
                        ASSISTANTS.creative_session.step3_1.progress
                      )
                    }
                  >
                    {progress ===
                    ASSISTANTS.creative_session.step2_1.progress ? (
                      <FormattedMessage
                        id="assistant start nth session"
                        values={{ n: 3 }}
                      />
                    ) : (
                      <FormattedMessage id="assistant continue session" />
                    )}
                  </MainButton>
                )}
            </Flow>
          </GridCard>

          <GridCard hasGradient={step4Active}>
            <Columns gap={GapSizes.M} columnTemplate="13.875rem 1fr">
              <Column>
                <div className="grid-card__phone">
                  <MobilePreview
                    imgUrl={attachment?.resource_urls?.small}
                    overflow={true}
                    allowEmpty={true}
                    alt="Screen"
                  />
                </div>
              </Column>
              <Column>
                <Flow>
                  <Headline tag="h3" size="M" opaque={!step4Active}>
                    5. <FormattedMessage id="assistant step 5 heading" />
                  </Headline>
                  <Typography size="smaller" opaque={!step4Active}>
                    {session?.hypothesis_description || (
                      <FormattedMessage id="assistant step 5 description" />
                    )}
                  </Typography>

                  {!isPlaceholer &&
                    !published &&
                    progress >=
                      ASSISTANTS.creative_session.step3_2.progress && (
                      <MainButton
                        onClick={() =>
                          onButtonClick &&
                          onButtonClick(
                            ASSISTANTS.creative_session.step4_1.progress
                          )
                        }
                      >
                        {progress ===
                        ASSISTANTS.creative_session.step3_2.progress ? (
                          <FormattedMessage
                            id="assistant start nth session"
                            values={{ n: 4 }}
                          />
                        ) : (
                          <FormattedMessage id="assistant continue session" />
                        )}
                      </MainButton>
                    )}
                </Flow>
              </Column>
            </Columns>
          </GridCard>
        </AssistantGrid>
      </CardWrapper>
    );
  }
);

export default AssistantListScreenItem;
