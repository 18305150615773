import { flow, getEnv, types } from 'mobx-state-tree';
import sortByField from 'utils/sort/field';

import {
  isLoadError,
  isSaveError,
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { OrgAccessLevelEnumModelType } from './OrgAccessLevelEnumModel';
import {
  createOrganizationModel,
  OrganizationModelType
} from './OrganizationModel';
import { createOrgMemberModel, OrgMemberModelType } from './OrgMemberModel';
import { AdvancedStoreEnv } from './StoreEnv';
import { createUserModel } from './UserModel';
import { createOrgTargetGroupModel } from './OrgTargetGroupModel';
import { createOrgServiceModel } from './OrgServiceModel';

const OrganizationsStore = types
  .model('OrganizationsStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    membersLoadingState: types.maybe(ListLoadingStateEnum),
    targetGroupsLoadingState: types.maybe(ListLoadingStateEnum),
    networkListLoadingState: types.maybe(ListLoadingStateEnum),
    servicesLoadingState: types.maybe(ListLoadingStateEnum)
  })
  .actions((self) => {
    // this is currently only used to load org for context and might
    // require quite some updates in order to work with multiple orgs
    const getOrganization = flow(function* (id: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const organization = createOrganizationModel(
          yield client.getOrganization(id)
        );

        if (!organization || organization.id < 0) {
          throw new Error('No valid response from client');
        }

        dataStore.addOrganization(organization);

        self.itemLoadingState = undefined;
        return dataStore.organizations.get(organization.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | getOrganization',
            error,
            error.body
          );
        }

        // normally, applicationStore would handle access denied (at least at the moment)
        // but for context we have to handle it another way - this will need to be optional
        // as soon as this method is used to retrieve arbitrary organizations
        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'load_error';
      }
    });

    const updateOrganization = flow(function* (id: number, patch: any) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const updatedOrg = createOrganizationModel(
          yield client.updateOrganization(id, patch)
        );

        if (!updatedOrg || updatedOrg.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addOrganization(updatedOrg);

        self.itemLoadingState = undefined;
        return dataStore.organizations.get(updatedOrg.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | updateOrganization',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });

    const createOrganization = flow(function* (organization: any) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'saving';

        const createdOrg = createOrganizationModel(
          yield client.createOrganization(organization)
        );

        if (!createdOrg || createdOrg.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addOrganization(createdOrg);

        self.itemLoadingState = undefined;
        return dataStore.organizations.get(createdOrg.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | createOrganization',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });

    const getCustomers = flow(function* () {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.listLoadingState = 'loading';

        const customers = yield client.listCustomers(
          dataStore.currentOrganizationId!
        );

        if (!customers || !Array.isArray(customers)) {
          throw new Error('No valid response from client');
        }

        for (const customer of customers) {
          const customerModel = createOrganizationModel(customer);
          if (!customerModel) {
            continue;
          }

          dataStore.addCustomer(customerModel);
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | getCustomers', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
      }
    });

    const createCustomer = flow(function* (
      organizationId: number,
      organization: any
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'saving';
        const createdOrg = createOrganizationModel(
          yield client.createCustomer(organizationId, organization)
        );

        if (!createdOrg) {
          throw new Error('No valid response from server');
        }

        dataStore.addCustomer(createdOrg);
        self.itemLoadingState = undefined;

        return createdOrg;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | createCustomer',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });

    const deleteCustomer = flow(function* (
      organizationId: number,
      customerId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'saving';

        const deletedOrg = createOrganizationModel(
          yield client.deleteCustomer(organizationId, customerId)
        );

        if (!deletedOrg) {
          throw new Error('No valid response from server');
        }

        dataStore.removeCustomer(customerId);
        self.itemLoadingState = undefined;

        return deletedOrg;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | deleteCustomer',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });
    const getMembers = flow(function* (
      organizationId?: number,
      includeSuperusers = false,
      includeAgencyUsers = false
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        self.membersLoadingState = 'loading';

        dataStore.clearOrgMembers();

        const members = yield client.listOrganizationMembers(
          organizationId,
          includeSuperusers,
          includeAgencyUsers
        );

        if (!members || !Array.isArray(members)) {
          throw new Error('No valid response from client');
        }

        for (const member of members) {
          const memberModel = createOrgMemberModel(member);
          if (!memberModel) {
            // insufficient user info in membership record
            continue;
          }

          if (member.user) {
            memberModel.setUser(
              dataStore.addUser(createUserModel(member.user))
            );
          }

          dataStore.addOrgMember(memberModel);
        }

        self.membersLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | getMembers', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          self.membersLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.membersLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.membersLoadingState = undefined;
          return;
        }

        self.membersLoadingState = 'load_error';
      }
    });

    const createOrUpdateMembership = flow(function* (
      userId: number,
      accessLevel: OrgAccessLevelEnumModelType,
      organizationId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const updatedMembership =
          yield client.createOrUpdateOrganizationMembership(
            organizationId,
            userId,
            accessLevel
          );

        if (updatedMembership) {
          const memberModel = createOrgMemberModel(updatedMembership);
          if (memberModel) {
            if (updatedMembership.user) {
              memberModel.setUser(
                dataStore.addUser(createUserModel(updatedMembership.user))
              );
            }

            dataStore.addOrgMember(memberModel);
          }
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | createOrUpdateMembership',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    // TODO merge createMember and updateMember with applicationStore.updateUser into new ProfilesStore
    const createMember = flow(function* (
      firstName: string,
      lastName: string,
      email: string,
      accessLevel: OrgAccessLevelEnumModelType,
      organizationId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const user: any = yield client.createUser(
          {
            first_name: firstName,
            last_name: lastName,
            email
          },
          organizationId,
          accessLevel
        );

        if (!user) {
          throw new Error('No response from server');
        }

        const memberModel = createOrgMemberModel({
          user,
          access_level: accessLevel
        });
        memberModel?.setUser(dataStore.addUser(createUserModel(user)));

        if (memberModel) {
          dataStore.addOrgMember(memberModel);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | createMember', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateMember = flow(function* (userId: number, patch: any) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        const user: any = yield client.updateUser(userId, patch);

        if (!user) {
          throw new Error('No response from server');
        }

        dataStore.addUser(createUserModel(user));
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | updateMember', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const reinviteMember = flow(function* (userId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.reinviteUser(userId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | reinviteMember',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        throw new Error('save_error');
      }
    });

    const getNetworkOrganizations = flow(function* () {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.networkListLoadingState = 'loading';
        dataStore.setHypothesesList(undefined);

        const result: any = yield client.getNetworkOrganizations();

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.addOrganization(
            createOrganizationModel(item)
          );
          if (storeItem) {
            list.push(storeItem);
          }
        }

        dataStore.setNetworkOrganizationsList(list);
        self.networkListLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | getNetworkOrganizations',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.networkListLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.networkListLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.networkListLoadingState = undefined;
          return undefined;
        }

        self.networkListLoadingState = 'load_error';
        return undefined;
      }
    });

    const deleteMember = flow(function* (userId: number) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.deleteUser(userId);
        dataStore.removeOrgMember(userId);

        if (!result) {
          throw new Error('No response from server');
        }

        return;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | deleteMember', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        throw new Error('delete error');
      }
    });

    const getTargetGroups = flow(function* (organizationId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        self.targetGroupsLoadingState = 'loading';

        dataStore.clearOrgTargetGroups();

        const targetGroups = yield client.listOrganizationTargetGroups(
          organizationId
        );

        if (!targetGroups || !Array.isArray(targetGroups)) {
          throw new Error('No valid response from client');
        }

        for (const targetGroup of targetGroups) {
          const targetGroupModel = createOrgTargetGroupModel(targetGroup);
          if (!targetGroupModel) {
            // insufficient target group info in the record
            continue;
          }
          dataStore.addOrgTargetGroup(targetGroupModel);
        }

        self.targetGroupsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | getMembers', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          self.targetGroupsLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.targetGroupsLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.targetGroupsLoadingState = undefined;
          return;
        }

        self.targetGroupsLoadingState = 'load_error';
      }
    });

    const createTargetGroup = flow(function* (
      organizationId,
      {
        name,
        content
      }: {
        organizationId?: number;
        name: string;
        content: string;
      }
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const targetGroup: any = yield client.createTargetGroup(
          organizationId,
          { name, content }
        );

        if (!targetGroup) {
          throw new Error('No response from server');
        }

        const targetGroupModel = createOrgTargetGroupModel({
          id: targetGroup.id,
          name: targetGroup.name,
          content: targetGroup.content,
          organization_id: targetGroup.organization_id,
          organization: targetGroup.organization
        });

        if (targetGroupModel) {
          dataStore.addOrgTargetGroup(targetGroupModel);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | createTargetGroup',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateTargetGroup = flow(function* ({
      organizationId,
      targetGroupId,
      patch
    }: {
      organizationId: number | undefined;
      targetGroupId: number;
      patch: any;
    }) {
      const { client, applicationStore, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const targetGroup: any = yield client.updateTargetGroup(
          organizationId,
          targetGroupId,
          patch
        );

        if (!targetGroup) {
          throw new Error('No response from server');
        }

        const targetGroupModel = createOrgTargetGroupModel(targetGroup);
        if (targetGroupModel) {
          dataStore.addOrgTargetGroup(targetGroupModel);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | updateTargetGroup',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteTargetGroup = flow(function* (
      organizationId,
      targetGroupId: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.deleteTargetGroup(
          organizationId,
          targetGroupId
        );
        dataStore.removeOrgTargetGroup(targetGroupId);

        if (!result) {
          throw new Error('No response from server');
        }

        return;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | deleteTargetGroup',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        throw new Error('delete error');
      }
    });

    const getServices = flow(function* (organizationId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        self.servicesLoadingState = 'loading';

        dataStore.clearOrgServices();

        const services = yield client.listOrganizationServices(organizationId);

        if (!services || !Array.isArray(services)) {
          throw new Error('No valid response from client');
        }

        for (const service of services) {
          const serviceModel = createOrgServiceModel(service);
          if (!serviceModel) {
            // insufficient service info in the record
            continue;
          }
          dataStore.addOrgService(serviceModel);
        }

        self.servicesLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('OrganizationsStore | getServices', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          self.servicesLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.servicesLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.servicesLoadingState = undefined;
          return;
        }

        self.servicesLoadingState = 'load_error';
      }
    });

    const createService = flow(function* (
      organizationId,
      {
        name,
        content
      }: {
        organizationId?: number;
        name: string;
        content: string;
      }
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const service: any = yield client.createService(organizationId, {
          name,
          content
        });

        if (!service) {
          throw new Error('No response from server');
        }

        const serviceModel = createOrgServiceModel({
          id: service.id,
          name: service.name,
          content: service.content,
          organization_id: service.organization_id,
          organization: service.organization
        });

        if (serviceModel) {
          dataStore.addOrgService(serviceModel);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | createService',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateService = flow(function* ({
      organizationId,
      serviceId,
      patch
    }: {
      organizationId: number | undefined;
      serviceId: number;
      patch: any;
    }) {
      const { client, applicationStore, dataStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!organizationId) {
        throw new Error('Insufficient context');
      }

      try {
        const service: any = yield client.updateService(
          organizationId,
          serviceId,
          patch
        );

        if (!service) {
          throw new Error('No response from server');
        }

        const serviceModel = createOrgServiceModel(service);
        if (serviceModel) {
          dataStore.addOrgService(serviceModel);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | updateService',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteService = flow(function* (organizationId, serviceId: number) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.deleteService(
          organizationId,
          serviceId
        );
        dataStore.removeOrgService(serviceId);

        if (!result) {
          throw new Error('No response from server');
        }

        return;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'OrganizationsStore | deleteService',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        throw new Error('delete error');
      }
    });

    return {
      getOrganization,
      updateOrganization,
      createOrganization,
      getCustomers,
      createCustomer,
      deleteCustomer,
      getMembers,
      createOrUpdateMembership,
      createMember,
      updateMember,
      reinviteMember,
      getNetworkOrganizations,
      deleteMember,
      getTargetGroups,
      createTargetGroup,
      updateTargetGroup,
      deleteTargetGroup,
      getServices,
      createService,
      updateService,
      deleteService
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemLoadError(): boolean {
        return isLoadError(self.itemLoadingState);
      },
      get isItemSaveError(): boolean {
        return isSaveError(self.itemLoadingState);
      },

      get isMembersLoading(): boolean {
        return self.membersLoadingState === 'loading';
      },
      get isMembersLoadError(): boolean {
        return isLoadError(self.membersLoadingState);
      },

      get isNetworkLoading(): boolean {
        return self.networkListLoadingState === 'loading';
      },
      get isNetworkLoadError(): boolean {
        return isLoadError(self.networkListLoadingState);
      },

      sortedMembers(
        filter?: 'pending' | 'active' | 'fully_active'
      ): OrgMemberModelType[] {
        const list: OrgMemberModelType[] = [];
        if (dataStore.orgMembers.size < 1) {
          return list;
        }

        for (const member of dataStore.orgMembers.values()) {
          if (filter === 'pending' && !member.user?.pending) {
            continue;
          }
          if (filter === 'active' && member.user?.pending) {
            continue;
          }
          if (
            filter === 'fully_active' &&
            (member.user?.pending || member.access_level === 'disabled')
          ) {
            continue;
          }

          list.push(member);
        }

        list.sort((a, b) => {
          const aName = a.user?.fullName || '';
          const bName = b.user?.fullName || '';

          if (aName > bName) {
            return 1;
          }

          if (aName < bName) {
            return -1;
          }

          return 0;
        });

        return list;
      },
      membersCount(filter?: 'pending' | 'active' | 'fully_active'): number {
        if (dataStore.orgMembers.size < 1) {
          return 0;
        }

        let count = 0;

        for (const member of dataStore.orgMembers.values()) {
          if (filter === 'pending' && !member.user?.pending) {
            continue;
          }
          if (filter === 'active' && member.user?.pending) {
            continue;
          }
          if (
            filter === 'fully_active' &&
            (member.user?.pending || member.access_level === 'disabled')
          ) {
            continue;
          }

          count++;
        }

        return count;
      },

      doesEmailExist(email: string): boolean {
        if (dataStore.orgMembers.size < 1) {
          return false;
        }

        email = email.toString().trim().toLowerCase();

        for (const member of dataStore.orgMembers.values()) {
          if (member.user?.email && member.user.email.toLowerCase() === email) {
            return true;
          }
        }

        return false;
      },

      get hasNetworkOrgs(): boolean {
        return dataStore.networkOrganizationsList &&
          dataStore.networkOrganizationsList.length > 0
          ? true
          : false;
      },
      get sortedNetworkOrgs(): OrganizationModelType[] {
        const list: OrganizationModelType[] = [];

        if (
          !dataStore.networkOrganizationsList ||
          !dataStore.networkOrganizationsList.length
        ) {
          return list;
        }

        for (const item of dataStore.networkOrganizationsList.values()) {
          list.push(item);
        }

        list.sort(sortByField('name'));

        return list;
      }
    };
  });

export type OrganizationsStoreType = typeof OrganizationsStore.Type;
export default OrganizationsStore;
