import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardHeader from 'components/CardHeader';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer from 'domain/assistant/StepContainer';
import { AssistantType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS, goToNextStep } from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';

interface AssistantInvitationScreenProps {
  assistantStore?: AssistantStoreType;
}

@inject('assistantStore')
@observer
class AssistantInvitationScreen extends React.Component<
  AssistantInvitationScreenProps & HistoryProps
> {
  // componentDidMount() {
  //   scrollSidebar(sidebarScrollTo);
  // }

  // private getBarProgress(assistantType?: AssistantType) {
  //   switch (assistantType) {
  //     case 'benchmarks_sprint':
  //       return 10;

  //     default:
  //       return undefined;
  //   }
  // }

  private getProgress(assistantType: AssistantType) {
    switch (assistantType) {
      case 'benchmarks_sprint':
      case 'painpoint_session':
      case 'hypothesis_sprint':
      case 'prototype_sprint':
        return ASSISTANTS[assistantType].invitation.progress;

      default:
        // other assistant types have no invitation screen
        return 0;
    }
  }

  private async continueSession() {
    const { assistantStore } = this.props;

    const { currentId, currentAssistantType } = assistantStore!;
    if (!currentId || !currentAssistantType) {
      return;
    }

    const progress = this.getProgress(currentAssistantType);

    await assistantStore!.updateAssistantSessionProgess(currentId, progress);

    goToNextStep(currentId, progress, currentAssistantType);
  }

  render() {
    const { assistantStore, location } = this.props;
    const { currentItem } = assistantStore!;

    return (
      <StepContainer
        // progress={this.getBarProgress(currentItem?.assistant_type)}
        // showHeader={showHeader}
        sidebarHeadline={<FormattedMessage id="Briefing" />}
        location={location}
        sessionId={assistantStore!.currentId}
      >
        {assistantStore!.isItemSaving && <Loading />}

        <SessionTop user={currentItem?.inviter}>
          <SessionHeadline>
            <FormattedMessage id="Briefing" />
          </SessionHeadline>

          <ColumnWrapper gap="2em">
            {currentItem?.invitation_text && (
              <SessionMainText>{currentItem.invitation_text}</SessionMainText>
            )}

            <ColumnWrapper gap="0.625em">
              <RainbowCard shrink={true}>
                <CardHeader
                  title={<FormattedMessage id="question long" />}
                  single={true}
                />

                <Typography size="large" breaks={true}>
                  {currentItem?.project?.briefing?.question}
                </Typography>
              </RainbowCard>

              <RainbowCard shrink={true}>
                <CardHeader
                  title={<FormattedMessage id="Short description" />}
                  single={true}
                />

                <Typography breaks={true}>
                  {currentItem?.project?.briefing?.description}
                </Typography>
              </RainbowCard>
            </ColumnWrapper>
          </ColumnWrapper>
        </SessionTop>

        <SessionBottom>
          <SessionActions>
            <MainButton onClick={() => this.continueSession()}>
              <FormattedMessage id="Next" />
            </MainButton>
          </SessionActions>
        </SessionBottom>
      </StepContainer>
    );
  }
}

export default AssistantInvitationScreen;
