
import ErrorMessage from 'components/ErrorMessage';

// NotFoundScreen should be used in feature routers. It won't render a nav bar
// but rely on the feature's nav bar being present.
export const NotFoundScreen: React.FC = () => (
  <ErrorMessage state="not_found" />
);

export default NotFoundScreen;
