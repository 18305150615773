
import AppLayout, { AppLayoutEnum } from './AppLayout';

interface AppLayoutReducedProps {
  active?: AppLayoutEnum;
  children?: React.ReactNode;
  withSettings?: boolean;
}

export default (props: AppLayoutReducedProps) => (
  <AppLayout {...props} reduced={true} />
);
