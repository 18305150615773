import { getSnapshot, isStateTreeNode, types } from 'mobx-state-tree';

import { createArray } from 'utils/store/createArray';
import createMap from 'utils/store/createMap';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ApiElementTypeEnum from './ApiElementTypeEnum';

const ATTACHMENT_TYPES = ['logo', 'avatar', 'screen', 'image'];
const FILE_TYPES = ['video', 'image'];

export const AttachmentTypeEnum = types.enumeration(ATTACHMENT_TYPES);
export type AttachmentTypeEnumType = typeof AttachmentTypeEnum.Type;
export const FileTypeEnum = types.enumeration(FILE_TYPES);
export type FileTypeEnumType = typeof FileTypeEnum.Type;

const AttachmentResourcesModel = types.model('AttachmentResourcesModel', {
  original: types.maybe(types.string),
  small: types.maybe(types.string),
  large: types.maybe(types.string),
  screen: types.maybe(types.string)
});

const createAttachmentResourcesModel = (data: any) =>
  !data
    ? undefined
    : {
        original: data.original || undefined,
        small: data.small || undefined,
        large: data.large || undefined,
        screen: data.screen || undefined
      };

export const AttachmentModel = types.model('AttachmentModel', {
  id: types.identifierNumber,
  element_type: types.maybe(ApiElementTypeEnum),
  element_id: types.maybe(types.number),
  rotation: types.maybe(types.number),
  attachment_type: types.maybe(AttachmentTypeEnum),
  file_type: types.maybe(FileTypeEnum),
  resource_urls: types.maybe(AttachmentResourcesModel)
});

export const createAttachmentModel = (data?: any): AttachmentModelType => {
  return {
    id: mandatoryId(data?.id),
    element_type: data?.element_type || undefined,
    element_id: numberOrUndefined(data?.element_id),
    rotation: numberOrUndefined(data?.rotation),
    attachment_type:
      ATTACHMENT_TYPES.indexOf(data?.attachment_type) > -1
        ? data.attachment_type
        : undefined,
    resource_urls: createAttachmentResourcesModel(data?.resource_urls),
    file_type: data?.file_type
  };
};

export const FluidAttachmentModel = types.model('FluidAttachmentModel', {
  id: types.maybe(types.number),
  element_type: types.maybe(ApiElementTypeEnum),
  element_id: types.maybe(types.number),
  rotation: types.maybe(types.number),
  attachment_type: types.maybe(AttachmentTypeEnum),
  resource_urls: types.maybe(AttachmentResourcesModel)
});

export type FluidAttachmentModelType = typeof FluidAttachmentModel.Type;

export const createFluidAttachmentModel = (
  data?: any
): FluidAttachmentModelType => {
  return {
    id: numberOrUndefined(data?.id),
    element_type: data?.element_type || undefined,
    element_id: numberOrUndefined(data?.element_id),
    rotation: numberOrUndefined(data?.rotation),
    attachment_type:
      ATTACHMENT_TYPES.indexOf(data?.attachment_type) > -1
        ? data.attachment_type
        : undefined,
    resource_urls: createAttachmentResourcesModel(data?.resource_urls)
  };
};

export const AttachmentsMapModel = types
  .model('AttachmentsMapModel', {
    map: types.map(AttachmentModel)
  })
  .actions((self) => {
    const put = (attachment: AttachmentModelType) => self.map.put(attachment);
    const remove = (attachmentId: number) =>
      self.map.delete(attachmentId.toString());
    return {
      put,
      delete: remove
    };
  })
  .views((self) => {
    return {
      get hasAny(): boolean {
        return self.map.size > 0 ? true : false;
      },
      get countAll(): number {
        return self.map.size;
      },
      get all(): AttachmentModelType[] {
        const attachments = [];

        for (const attachment of self.map.values()) {
          attachments.push(attachment);
        }

        return attachments;
      },
      allOfType(type: AttachmentTypeEnumType): AttachmentModelType[] {
        const attachments = [];

        for (const attachment of self.map.values()) {
          if (attachment.attachment_type === type) {
            attachments.push(attachment);
          }
        }

        return attachments;
      },
      firstOfType(
        type: AttachmentTypeEnumType
      ): AttachmentModelType | undefined {
        for (const attachment of self.map.values()) {
          if (attachment.attachment_type === type) {
            return attachment;
          }
        }
        return undefined;
      },

      get hash(): string {
        if (self.map.size < 1) {
          return 'empty';
        }

        const ids: string[] = [];
        for (const attachment of self.map.values()) {
          ids.push(attachment.id.toString());
        }

        return self.map.size + '#' + ids.join('-');
      }
    };
  });

export const createAttachmentsMap = (data?: any): any =>
  isStateTreeNode(data)
    ? getSnapshot(data)
    : AttachmentsMapModel.create({
        map: createMap(!data ? [] : data, createAttachmentModel)
      });

export const FluidAttachmentsListModel = types
  .model('FluidAttachmentsMapModel', {
    list: types.array(FluidAttachmentModel)
  })
  // .actions(self => {
  //   const push = (attachment: AttachmentModelType) => self.list.push(attachment);
  //   return {
  //     push
  //   };
  // })
  .views((self) => {
    return {
      get hasAny(): boolean {
        return self.list.length > 0 ? true : false;
      },
      get all(): FluidAttachmentModelType[] {
        const attachments = [];

        for (const attachment of self.list.values()) {
          attachments.push(attachment);
        }

        return attachments;
      },
      allOfType(type: AttachmentTypeEnumType): FluidAttachmentModelType[] {
        const attachments = [];

        for (const attachment of self.list.values()) {
          if (attachment.attachment_type === type) {
            attachments.push(attachment);
          }
        }

        return attachments;
      },
      firstOfType(
        type: AttachmentTypeEnumType
      ): FluidAttachmentModelType | undefined {
        for (const attachment of self.list.values()) {
          if (attachment.attachment_type === type) {
            return attachment;
          }
        }
        return undefined;
      }
    };
  });

export const createFluidAttachmentsList = (data?: any): any =>
  isStateTreeNode(data)
    ? getSnapshot(data)
    : FluidAttachmentsListModel.create({
        list: createArray(!data ? [] : data, createFluidAttachmentModel)
      });

export type AttachmentModelType = typeof AttachmentModel.Type;
export default AttachmentModel;
