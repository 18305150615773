import messagesDe from './messages-de.json';
import messagesEn from './messages-en.json';

export default function() {
  return {
    locale: 'en',
    messages: {
      ...messagesDe,
      ...messagesEn
    }
  };
}
