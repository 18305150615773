import { flow, getEnv, types } from 'mobx-state-tree';
import sortByField from 'utils/sort/field';

import { ClusterModelType, createClusterModel } from './ClusterModel';
import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { createPainpointAimModel } from './PainpointAimModel';
import { createPainpointModel, PainpointModelType } from './PainpointModel';
import { createPainpointQuestionModel } from './PainpointQuestionModel';
import { ProjectsStoreType } from './ProjectsStore';
import { PublishStateEnumType } from './PublishStateEnum';
import { AdvancedStoreEnv } from './StoreEnv';
import { createServerTagList } from './TagListModel';

export interface ClusterPainpoints {
  cluster?: ClusterModelType;
  painpoints: PainpointModelType[];
}

interface StoreEnv extends AdvancedStoreEnv {
  projectsStore: ProjectsStoreType;
}

const PainpointsStore = types
  .model('PainpointsStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const getPainpoints = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.listLoadingState = 'loading';
        dataStore.setPainpointsList(undefined);

        const result: any = yield client.getPainpoints(
          organizationId,
          projectId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        if (result.loading) {
          dataStore.project?.setAiAtWork(true);
        }

        if (Array.isArray(result.painpoints)) {
          const list = [];
          for (const item of result.painpoints) {
            const storeItem = dataStore.convertAndAddPainpoint(item);
            list.push(storeItem);
          }

          dataStore.setPainpointsList(list);
        }

        if (Array.isArray(result.clusters)) {
          for (const item of result.clusters) {
            dataStore.addCluster(createClusterModel(item));
          }
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | getPainpoints', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return undefined;
        }

        self.listLoadingState = 'load_error';
        return undefined;
      }
    });

    const getPainpoint = flow(function* (
      painpointId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setPainpointItem(undefined);

        const result: any = yield client.getPainpoint(
          organizationId,
          projectId,
          painpointId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const painpoint = dataStore.convertAndAddPainpoint(result);
        dataStore.setPainpointItem(painpoint);

        self.itemLoadingState = undefined;
        return painpoint;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | getPainpoint', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const generateMorePainpointsByAi = flow(function* (
      orgId: number,
      projId: number,
      clusterId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );
      try {
        self.itemLoadingState = 'loading';

        const result: any = yield client.generateMorePainpointsByAi(
          organizationId,
          projectId,
          clusterId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        self.itemLoadingState = undefined;
        return { success: true };
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'BriefingStore | generateMorePainpoints',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return { success: false };
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return { success: false };
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return { success: false };
        }

        self.itemLoadingState = 'load_error';
        return { success: false };
      }
    });

    const findBenchmarksByAi = flow(function* (
      orgId: number,
      projId: number,
      painpointIds: number[]
      ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);
      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);
        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generateGeneralBenchmarksForPainpointsByAi(
          orgId,
          projId,
          painpointIds
        );

        // set all loading states to false here
        // applicationStore.setIsPrefillingBenchmarks(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generateBenchmarksForPainpointsByAi',
            error,
            error.body
          );
        }
      }
    });

    const findMoreBenchmarksByAi = flow(function* (
      orgId: number,
      projId: number,
      radical: boolean = false
      ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);
      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);
        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generateMoreGeneralBenchmarksByAi(
          orgId,
          projId,
          radical
        );

        // set all loading states to false here
        // applicationStore.setIsPrefillingBenchmarks(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generateBenchmarksForPainpointsByAi',
            error,
            error.body
          );
        }
      }
    });

    const createPainpoint = flow(function* (
      painpoint: PainpointModelType,
      addToList: boolean = false,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';
        dataStore.setPainpointItem(undefined);

        const result: any = yield client.createPainpoint(
          organizationId,
          projectId,
          {
            ...painpoint,
            tags_attributes: createServerTagList(painpoint.tags),
            tags: undefined
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newPainpoint = dataStore.convertAndAddPainpoint(result);
        dataStore.setPainpointItem(newPainpoint);

        if (addToList && newPainpoint) {
          dataStore.addToPainpointsList(newPainpoint);
        }

        self.itemLoadingState = undefined;
        return newPainpoint;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | createPainpoint', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const requestPainpoint = flow(function* (
      painpointId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.requestPainpoint(
          organizationId,
          projectId,
          painpointId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updatePainpointFromCable(painpointId, result);

        // check if successful or requested by another user
        return (
          !!dataStore.currentUserId &&
          result.requesting_user?.id === dataStore.currentUserId
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'PainpointsStore | requestPainpoint',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }
    });

    const assignPainpoint = flow(function* (
      painpointId: number,
      invitationText: string,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore, projectsStore } =
        getEnv<StoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.assignPainpoint(
          organizationId,
          projectId,
          painpointId,
          invitationText
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updatePainpointFromCable(painpointId, result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | assignPainpoint', error, error.body);
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }

      try {
        yield projectsStore.getAssistantStats();
      } catch (error) {
        // can be ignored
      }
    });

    const declinePainpoint = flow(function* (
      painpointId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.declinePainpoint(
          organizationId,
          projectId,
          painpointId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updatePainpointFromCable(painpointId, result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'PainpointsStore | declinePainpoint',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }
    });

    const updatePainpoint = flow(function* (
      painpointId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        const result: any = yield client.updatePainpoint(
          organizationId,
          projectId,
          painpointId,
          serverPatch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newPainpoint = dataStore.convertAndAddPainpoint(result);
        dataStore.setPainpointItem(newPainpoint);

        self.itemLoadingState = undefined;
        return newPainpoint;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | updatePainpoint', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const deletePainpoint = flow(function* (
      painpointId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'deleting';

        yield client.deletePainpoint(organizationId, projectId, painpointId);

        dataStore.deletePainpoint(painpointId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | deletePainpoint', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deletePainpoint(painpointId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const createSubelement = flow(function* (
      painpoint: PainpointModelType,
      elementType: 'Aim' | 'Question',
      element: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      const apiMethod =
        elementType === 'Question'
          ? client.createPainpointQuestion
          : client.createPainpointAim;

      try {
        const result: any = yield apiMethod(
          organizationId,
          projectId,
          painpoint.id,
          element
        );

        if (!result) {
          throw new Error('No response from server');
        }

        elementType === 'Question'
          ? painpoint.putQuestion(createPainpointQuestionModel(result))
          : painpoint.putAim(createPainpointAimModel(result));
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'PainpointsStore | createSubelement',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateSubelement = flow(function* (
      painpoint: PainpointModelType,
      elementType: 'Aim' | 'Question',
      elementId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      const apiMethod =
        elementType === 'Question'
          ? client.updatePainpointQuestion
          : client.updatePainpointAim;

      try {
        const result: any = yield apiMethod(
          organizationId,
          projectId,
          painpoint.id,
          elementId,
          patch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        elementType === 'Question'
          ? painpoint.putQuestion(createPainpointQuestionModel(result))
          : painpoint.putAim(createPainpointAimModel(result));
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'PainpointsStore | updateSubelement',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteSubelement = flow(function* (
      painpoint: PainpointModelType,
      elementType: 'Aim' | 'Question',
      elementId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      const apiMethod =
        elementType === 'Question'
          ? client.deletePainpointQuestion
          : client.deletePainpointAim;

      try {
        yield apiMethod(organizationId, projectId, painpoint.id, elementId);

        elementType === 'Question'
          ? painpoint.deleteQuestion(elementId)
          : painpoint.deleteAim(elementId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'PainpointsStore | deleteSubelement',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          return;
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const bulkDeletePainpoints = flow(function* (
      painpointIds: number[],
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      self.listLoadingState = 'deleting';
      let deleted = 0;

      for (const painpointId of painpointIds) {
        try {
          yield deletePainpoint(painpointId, organizationId, projectId);

          deleted++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error(
              'PainpointsStore | bulkDeletePainpoints',
              error,
              error.body
            );
          }

          if (client.isNotFound(error)) {
            dataStore.deletePainpoint(painpointId);
            deleted++;
          }
        }
      }

      self.listLoadingState = undefined;
      return deleted;
    });

    const initializeItem = (data: any) => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const painpoint = createPainpointModel(data);

      dataStore.addPainpoint(painpoint, true);
      dataStore.setPainpointItem(
        dataStore.painpoints.get(painpoint.id.toString())
      );
    };

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    const getClusters = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.getClusters(organizationId, projectId);

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        dataStore.clearClusters();

        for (const item of result) {
          dataStore.addCluster(createClusterModel(item));
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | getClusters', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        throw new Error('load_error');
      }
    });

    const createCluster = flow(function* (
      cluster: ClusterModelType,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.createCluster(
          organizationId,
          projectId,
          cluster
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newCluster = createClusterModel(result);
        dataStore.addCluster(newCluster);

        return dataStore.clusters.get(newCluster.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | createCluster', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateCluster = flow(function* (
      clusterId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.updateCluster(
          organizationId,
          projectId,
          clusterId,
          patch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newCluster = createClusterModel(result);
        dataStore.addCluster(newCluster);

        return dataStore.clusters.get(newCluster.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | updateCluster', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteCluster = flow(function* (
      clusterId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        yield client.deleteCluster(organizationId, projectId, clusterId);

        dataStore.deleteCluster(clusterId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PainpointsStore | deleteCluster', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteCluster(clusterId);
          return;
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('delete_error');
      }
    });

    return {
      getPainpoints,
      getPainpoint,
      initializeItem,
      createPainpoint,
      generateMorePainpointsByAi,
      findBenchmarksByAi,
      findMoreBenchmarksByAi,
      requestPainpoint,
      assignPainpoint,
      declinePainpoint,
      updatePainpoint,
      deletePainpoint,
      createSubelement,
      updateSubelement,
      deleteSubelement,
      bulkDeletePainpoints,
      setFilter,
      getClusters,
      createCluster,
      updateCluster,
      deleteCluster
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return (
          self.itemLoadingState === 'loading' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },

      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListDeleting(): boolean {
        return self.listLoadingState === 'deleting';
      },
      get isListBusy(): boolean {
        return this.isListLoading || this.isListDeleting;
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      get hasAny(): boolean {
        return dataStore.painpointsList && dataStore.painpointsList.length > 0
          ? true
          : false;
      },

      get hasAnyClusters(): boolean {
        return dataStore.clusters.size > 0 ? true : false;
      },
      get clustersByName(): ClusterModelType[] {
        const clusters: ClusterModelType[] = [];

        if (dataStore.clusters.size < 1) {
          return clusters;
        }

        for (const cluster of dataStore.clusters.values()) {
          clusters.push(cluster);
        }

        clusters.sort(sortByField('name'));

        return clusters;
      },

      grid(
        highligthedPainpointId?: number,
        clusters?: ClusterModelType[],
        painpoints?: PainpointModelType[]
      ): ClusterPainpoints[] {
        const grid: ClusterPainpoints[] = [];
        const clusterIds: number[] = [];
        const uncategorized: PainpointModelType[] = [];
        let highlightedClusterId: number | undefined;

        const clusterList = clusters ? clusters : dataStore.clusters;
        const painpointsList = painpoints
          ? painpoints
          : dataStore.painpointsList;

        for (const cluster of clusterList.values()) {
          grid.push({
            cluster,
            painpoints: []
          });
          clusterIds.push(cluster.id);
        }

        if (painpointsList) {
          for (const painpoint of painpointsList.values()) {
            const clusterId = painpoint.cluster?.id;
            const index = !clusterId ? -1 : clusterIds.indexOf(clusterId);

            if (
              highligthedPainpointId &&
              painpoint.id === highligthedPainpointId
            ) {
              highlightedClusterId = clusterId;
            }

            if (index > -1 && grid[index]) {
              grid[index].painpoints.push(painpoint);
            } else {
              uncategorized.push(painpoint);
            }
          }
        }

        for (const column of grid) {
          column.painpoints.sort(sortByField('created_at'));
        }

        let highlightedCluster: ClusterPainpoints | undefined;
        if (highlightedClusterId) {
          const index = clusterIds.indexOf(highlightedClusterId);
          if (index > -1) {
            highlightedCluster = grid.splice(index, 1)[0];
          }
        }

        grid.sort((a: ClusterPainpoints, b: ClusterPainpoints) => {
          if ((a.cluster?.order || 999999) > (b.cluster?.order || 999999)) {
            return 1;
          }
          if ((a.cluster?.order || 999999) < (b.cluster?.order || 999999)) {
            return -1;
          }
          return 0;
        });

        if (uncategorized.length) {
          grid.unshift({
            painpoints: uncategorized
          });
        }

        if (highlightedCluster) {
          grid.unshift(highlightedCluster);
        }

        return grid;
      },

      list(
        state?: PublishStateEnumType,
        filter?: string
      ): PainpointModelType[] {
        const painpoints: PainpointModelType[] = [];

        if (!dataStore.painpointsList || !dataStore.painpointsList.length) {
          return painpoints;
        }

        const filterLowercase = filter?.toLowerCase() || undefined;

        for (const painpoint of dataStore.painpointsList.values()) {
          if (state && painpoint.publish_state !== state) {
            continue;
          }

          if (
            filterLowercase &&
            (!painpoint.question ||
              painpoint.question.toLowerCase().indexOf(filterLowercase) < 0)
          ) {
            continue;
          }

          painpoints.push(painpoint);
        }

        painpoints.sort(sortByField('question'));

        return painpoints;
      }
    };
  });

export type PainpointsStoreType = typeof PainpointsStore.Type;
export default PainpointsStore;
