import DestroyButton from 'components/DestroyButton';

interface UserFlowCard {
  children?: React.ReactNode;
  src?: string;
  alt?: string;
  onRemoveClick?: () => void;
}

export const UserFlowCard = ({
  children,
  src,
  alt = 'screenshot',
  onRemoveClick
}: UserFlowCard) => {
  return (
    <article className="user-flow-card">
      <div className="user-flow-card__body">
        <div className="user-flow-card__remove-button">
          <DestroyButton onClick={onRemoveClick} />
        </div>
        {children}
      </div>
    </article>
  );
};

export default UserFlowCard;
