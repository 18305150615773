import { Children, Component, createRef, Touch, TouchEvent } from 'react';

import SectionHeadline from 'components/SectionHeadline';

interface Properties {
  children?: React.ReactNode;
  title?: React.ReactNode;
  caption?: React.ReactNode;
  value?: number;
  anchor?: string;
  anchorText?: React.ReactNode;
  responsiveConfig?: ResponsiveConfig;
  large?: boolean;
  smallHeadline?: boolean;
  fullHeight?: boolean;
}

interface State {
  selectedIndex: number;
  itemsPerChunk: number;
}

interface Config {
  itemsPerChunk: number;
}

interface ResponsiveConfig {
  [key: string]: Config;
}

const bem = (base: string, modifiers: Array<string | boolean>) =>
  [base, ...modifiers.map((modifier) => `${base}--${modifier}`)]
    .filter((x) => !!x)
    .join(' ');

export const defaultSliderResponsiveConfig: ResponsiveConfig = {
  all: {
    itemsPerChunk: 1
  }
};

export const smallSliderResponsiveConfig: ResponsiveConfig = {
  ...defaultSliderResponsiveConfig,
  '(min-width: 18.75em)': {
    itemsPerChunk: 1
  },
  '(min-width: 37.5em)': {
    itemsPerChunk: 2
  },
  '(min-width: 45.9375em)': {
    itemsPerChunk: 3
  }
};

export default class CardSlider extends Component<Properties, State> {
  private static identifierCounter = 0;

  private identifier: number;
  private itemsRef: React.RefObject<HTMLDivElement>;

  public state = {
    itemsPerChunk: 3,
    selectedIndex: 0
  };

  private mediaQueryLists: Array<{
    mediaQueryList: MediaQueryList;
    listener: (event: MediaQueryListEvent) => void;
  }> = [];

  constructor(props: Properties) {
    super(props);

    this.identifier = CardSlider.identifierCounter++;

    this.itemsRef = createRef();

    this.navigate = this.navigate.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    Object.assign(this.state, this.initResponsiveConfig());
  }

  private initResponsiveConfig() {
    this.mediaQueryLists.forEach(({ mediaQueryList, listener }) =>
      mediaQueryList.removeListener(listener)
    );

    const responsiveConfig =
      this.props.responsiveConfig || defaultSliderResponsiveConfig;

    const configBuffer: any[] = [];

    const mergeConfig = (buffer: any[]): any =>
      buffer
        .filter((config) => !!config)
        .reduce((carry: any, config: any) => ({ ...(carry || {}), ...config }));

    Object.entries(responsiveConfig).forEach(
      ([mediaQueryString, mediaQueryConfig], mediaQueryIndex) => {
        const mediaQueryList = matchMedia(mediaQueryString);

        configBuffer[mediaQueryIndex] = mediaQueryList.matches
          ? mediaQueryConfig
          : null;

        const listener = (event: MediaQueryListEvent) => {
          const { selectedIndex } = this.state;

          configBuffer[mediaQueryIndex] = event.matches
            ? mediaQueryConfig
            : null;

          const config = mergeConfig(configBuffer);

          this.setState({
            ...config,
            selectedIndex:
              Math.floor(selectedIndex / config.itemsPerChunk) *
              config.itemsPerChunk
          });
        };

        mediaQueryList.addListener(listener);
        this.mediaQueryLists.push({ mediaQueryList, listener });
      }
    );

    return mergeConfig(configBuffer);
  }

  public componentWillUnmount(): void {
    this.mediaQueryLists.forEach(({ mediaQueryList, listener }) =>
      mediaQueryList.removeListener(listener)
    );
  }

  public componentDidUpdate(prevProps: Properties, prevState: State) {
    const prevChildrenLength = Children.count(prevProps.children);
    const childrenLength = Children.count(this.props.children);

    if (
      prevState.selectedIndex === this.state.selectedIndex &&
      prevChildrenLength !== childrenLength
    ) {
      const newSelectedIndex = Math.floor(
        (this.state.selectedIndex / prevChildrenLength) * childrenLength
      );

      this.setState({
        selectedIndex: Math.max(0, Math.min(newSelectedIndex, childrenLength))
      });
    }

    if (prevProps.responsiveConfig !== this.props.responsiveConfig) {
      this.initResponsiveConfig();
    }
  }

  public navigate(amount: number): void {
    const { selectedIndex: prevSelectedIndex } = this.state;
    const { children } = this.props;

    const count = Children.toArray(children).filter((child) => !!child).length;
    const selectedIndex = prevSelectedIndex + amount;

    if (selectedIndex >= 0 && selectedIndex < count) {
      this.setState({ selectedIndex });
    }
  }

  public prev(): void {
    const { itemsPerChunk } = this.state;

    this.navigate(-itemsPerChunk);
  }

  public next(): void {
    const { itemsPerChunk } = this.state;

    this.navigate(itemsPerChunk);
  }

  private touchStart?: Touch;
  private touchOffset: number = 0;

  private handleTouchStart(event: TouchEvent<HTMLDivElement>): void {
    this.touchStart = event.touches[0];
    if (this.itemsRef.current) {
      this.itemsRef.current.classList.toggle(
        'card-slider__items--animated',
        false
      );
    }
  }

  private handleTouchMove(event: TouchEvent<HTMLDivElement>): void {
    if (this.touchStart && this.itemsRef.current) {
      this.touchOffset = event.touches[0].clientX - this.touchStart.clientX;
      this.itemsRef.current.style.transform = `translateX(${this.touchOffset}px)`;
    }
  }

  private handleTouchEnd(event: TouchEvent<HTMLDivElement>): void {
    if (this.touchStart && this.itemsRef.current) {
      if (
        Math.abs(this.touchOffset) >=
        this.itemsRef.current.offsetWidth / 50
      ) {
        (this.touchOffset > 0 ? this.prev : this.next)();
      }

      this.itemsRef.current.classList.toggle(
        'card-slider__items--animated',
        true
      );
      this.itemsRef.current.style.transform = '';
      this.touchStart = undefined;
      this.touchOffset = 0;
    }
  }

  public render(): JSX.Element {
    const { selectedIndex, itemsPerChunk } = this.state;
    const {
      title,
      value,
      caption,
      children,
      anchor,
      anchorText,
      large,
      smallHeadline,
      fullHeight
    } = this.props;

    const count = Children.toArray(children).filter((child) => !!child).length;

    const showPrevButton = selectedIndex > 0;
    const showNextButton = selectedIndex < count - itemsPerChunk;

    const classNames = bem('card-slider', [
      large ? 'size-large' : 'size-normal'
    ]);

    const toggleTabIndices =
      (enableTabIndex: boolean) =>
      (element: HTMLElement | null): void => {
        if (element) {
          if (enableTabIndex) {
            const prevTabIndex = element.getAttribute('data-prevtabindex');

            if (prevTabIndex) {
              element.setAttribute('tabindex', prevTabIndex);
            } else {
              element.removeAttribute('tabindex');
            }

            element.removeAttribute('data-prevtabindex');
          } else {
            const tabIndex = element.getAttribute('tabindex');

            if (tabIndex !== '-1') {
              if (tabIndex) {
                element.setAttribute('data-prevtabindex', tabIndex);
              }

              element.setAttribute('tabindex', '-1');
            }
          }

          [].forEach.apply(element.children, [
            toggleTabIndices(enableTabIndex)
          ]);
        }
      };

    return (
      <div className={classNames} data-id={this.identifier}>
        {title && (
          <SectionHeadline
            title={title}
            value={value}
            caption={caption}
            anchor={anchor}
            anchorText={anchorText}
            small={smallHeadline}
          />
        )}

        <div className="card-slider__wrapper">
          <div
            ref={this.itemsRef}
            className="card-slider__items"
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
          >
            {Children.map(
              children,
              (child, index) =>
                child && (
                  <div
                    className={
                      fullHeight
                        ? 'card-slider__item card-slider__item--full-height'
                        : 'card-slider__item'
                    }
                    ref={toggleTabIndices(
                      selectedIndex ===
                        Math.floor(index / itemsPerChunk) * itemsPerChunk
                    )}
                  >
                    {child}
                  </div>
                )
            )}
          </div>
          <div className="card-slider__controls">
            <button
              type="button"
              className={bem('card-slider__control', [
                'left',
                showPrevButton || 'hidden'
              ])}
              onClick={this.prev}
              tabIndex={-1}
            >
              ◀
            </button>
            <button
              type="button"
              className={bem('card-slider__control', [
                'right',
                showNextButton || 'hidden'
              ])}
              onClick={this.next}
              tabIndex={-1}
            >
              ▶
            </button>
          </div>
        </div>
        <style>
          {itemsPerChunk > 1
            ? `.card-slider[data-id="${this.identifier}"] .card-slider__items {
                width: calc(100% * ${count} / ${itemsPerChunk});
              }`
            : `.card-slider[data-id="${this.identifier}"] .card-slider__items {
                width: calc(100% * ${count} / ${itemsPerChunk} ${
                count > 1 ? `- 5em` : ``
              } * ${count});
                ${count > 1 ? `margin-left: 2.5em;` : ``}
              }`}

          {`.card-slider[data-id="${this.identifier}"] .card-slider__item {
              width: calc(100% / ${count});
              transform: translateX(calc(-100% * ${selectedIndex} ${
            selectedIndex === 0 && itemsPerChunk === 1 && count > 1
              ? `- 2.5em`
              : ``
          }));
            }`}
        </style>
      </div>
    );
  }
}
