import { inject, observer } from 'mobx-react';
import React from 'react';

import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS, goToNextStep } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import SolutionFormComponent, { MAX_SOLUTIONS } from './SolutionFormComponent';

interface SolutionScreenProps {
  assistantStore: AssistantStoreType;
}

@inject('assistantStore')
@observer
class SolutionScreen extends React.Component<
  SolutionScreenProps & HistoryProps
> {
  componentDidMount() {
    this.redirectIfFinished();
  }

  componentDidUpdate(prevProps: HistoryProps) {
    if (this.props.location?.key !== prevProps.location?.key) {
      this.redirectIfFinished();
    }
  }

  private getIndex() {
    const { location } = this.props;

    const index = parseInt(location.query?.solution || '0', 10);
    if (isNaN(index) || index < 1 || index > MAX_SOLUTIONS) {
      return undefined;
    }

    return index - 1;
  }

  private hasValidIndex() {
    return this.getIndex() !== undefined;
  }

  private isFinished() {
    const {
      assistantStore: { currentItem }
    } = this.props;

    if (this.hasValidIndex()) {
      // edit mode
      return false;
    }

    return (
      (currentItem?.progress || 0) >=
        ASSISTANTS.creative_session.step3_2.progress &&
      (currentItem?.activeSolutionsCount || 0) >= MAX_SOLUTIONS
    );
  }

  private redirectIfFinished() {
    if (this.isFinished()) {
      const { currentItem } = this.props.assistantStore;
      if (!currentItem) {
        return;
      }

      if (currentItem.assistant_type === 'hypothesis_sprint') {
        goToNextStep(currentItem.id, currentItem.progress, 'hypothesis_sprint');
      } else {
        this.props.history.push(
          ROUTE_ASSISTANT + '/' + currentItem.id + '/session3/done'
        );
      }
    }
  }

  render() {
    if (this.isFinished()) {
      // redirect will be triggered anyway, so do not render form
      return null;
    }

    const { location } = this.props;

    const indexFromUrl = this.getIndex();
    if (indexFromUrl !== undefined) {
      // index present in location (edit mode)
      return (
        <SolutionFormComponent
          key={'edit-' + indexFromUrl}
          index={indexFromUrl}
          location={location}
          editMode={true}
        />
      );
    }

    const {
      assistantStore: { currentItem }
    } = this.props;

    if (!currentItem) {
      // cannot do anything without session
      return null;
    }

    // always display last solution if no index is present in location (new/draft mode)
    let currentSolutionIndex = currentItem.activeSolutionsCount;
    if (currentSolutionIndex >= MAX_SOLUTIONS) {
      currentSolutionIndex = MAX_SOLUTIONS - 1;
    }

    return (
      <SolutionFormComponent
        key={'new-' + currentSolutionIndex}
        index={currentSolutionIndex}
        location={location}
      />
    );
  }
}

export default SolutionScreen;
