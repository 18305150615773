// This helper creates a valid snapshot for mobx state tree 'map' type
// from an array containing objects. These snapshots can be used to
// initialize a map in a state tree model.
// Objects need to have an 'id' property in order to be added to the map.

export const createMapWithTransform = (arr?: any, transform?: (item: any) => any) => {
  if (!Array.isArray(arr) || !arr.length) {
    return {};
  }

  const map: any = {};
  arr.forEach((item: any) => {
    if (item.id) {
      const add: any = !transform ? item : transform(item);
      map[item.id] = add;
    }
  });

  return map;
};

export const nullToUndefined = (obj: any) => {
  const newObj = { ...obj };
  for (const fieldName in newObj) {
    if (newObj.hasOwnProperty(fieldName) && newObj[fieldName] === null) {
      newObj[fieldName] = undefined;
    }
  }

  return newObj;
};

export const createMap = (arr?: any, transform?: (item: any) => any) =>
  createMapWithTransform(arr, !transform ? nullToUndefined : transform);

export default createMap;
