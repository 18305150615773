import { FormattedMessage } from 'react-intl';
import { LinkProps } from 'react-router-dom';

import CardHeader from 'components/CardHeader';
import CompanyLogo from 'components/CompanyLogo';
import Headline from 'components/headline';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import RainbowCardSmall from 'components/RainbowCard/RainbowCardSmall';
import Typography from 'components/Typography';
import { AssistantSessionSolutionModelType } from 'models/AssistantSessionSolutionModel';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import GapSizes from 'utils/constants/gap-sizes';
import SidebarItemContent from './SidebarItemContent';

interface SidebarSolutionItemProps {
  solution: AssistantSessionSolutionModelType;
  benchmark?: BenchmarkModelType;
  index: number;
  to?: LinkProps['to'];
}

export const SidebarSolutionItem = ({
  solution,
  benchmark,
  index,
  to
}: SidebarSolutionItemProps) => {
  const screen =
    solution.attachments.firstOfType('screen')?.resource_urls?.small;
  const logo = benchmark?.attachments.firstOfType('logo')?.resource_urls?.small;

  return (
    <SidebarItemContent>
      <RainbowCardSmall to={to}>
        <CardHeader
          simple={true}
          title={
            // tslint:disable-next-line: jsx-wrap-multiline
            <FormattedMessage
              id="assistant solution number"
              values={{ number: '0' + (index + 1) }}
            />
          }
        />

        <Columns gap={GapSizes.NONE} columnTemplate="auto 1fr">
          <Column>
            <div className="logo-with-thumbnail">
              <div className="logo-with-thumbnail__thumbnail">
                {screen ? (
                  <div className="phone-thumbnail">
                    <img src={screen} alt="Screen" />
                  </div>
                ) : (
                  <div className="phone-thumbnail-empty" />
                )}
              </div>
              <div className="logo-with-thumbnail__logo">
                {logo && <CompanyLogo alt="Logo" imgUrl={logo} />}
              </div>
            </div>
          </Column>
          <Column>
            <Headline tag="h4" size="XS" opaque={true}>
              {benchmark?.company_name || benchmark?.headline}
            </Headline>
            <Typography size="smaller">{solution.description}</Typography>
          </Column>
        </Columns>
      </RainbowCardSmall>
    </SidebarItemContent>
  );
};

export default SidebarSolutionItem;
