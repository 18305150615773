import GapSizes from 'utils/constants/gap-sizes';

interface ColumnsProps {
  children: any;
  columnTemplate?: string;
  gap?: GapSizes;
}

export default function Columns({
  children,
  columnTemplate,
  gap
}: ColumnsProps) {
  // if (process.env.NODE_ENV !== 'production') {
  //   for (const child in children) {
  //     if (children[child].type.name !== 'Column') {
  //       const hasError = true;
  //     }
  //   }
  // }

  const style = {
    '--column-template': columnTemplate,
    '--gap': gap
  } as React.CSSProperties;

  return (
    <div className="l-columns" style={style}>
      {children}
    </div>
  );
}
