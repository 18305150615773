import { IAnyModelType, types } from 'mobx-state-tree';

import { sortCreatedAsc } from 'utils/sort/created-at';
import createMap from 'utils/store/createMap';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { createFluidClusterModel, FluidClusterModel } from './ClusterModel';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import HypothesisModel, { HypothesisModelType } from './HypothesisModel';
import PainpointAimModel, {
  createPainpointAimModel,
  PainpointAimModelType
} from './PainpointAimModel';
import PainpointQuestionModel, {
  createPainpointQuestionModel,
  PainpointQuestionModelType
} from './PainpointQuestionModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PublishStateEnum, { createPublishState } from './PublishStateEnum';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';
import PrototypeModel from './PrototypeModel';

export const PainpointModel = types
  .model('PainpointModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    cluster: types.maybe(FluidClusterModel),
    author: types.maybe(FluidUserModel),
    requesting_user: types.maybe(FluidUserModel),
    question: types.maybe(types.string),
    questions: types.maybe(types.map(PainpointQuestionModel)),
    aims: types.maybe(types.map(PainpointAimModel)),
    prototypes: types.maybe(
      types.map(
        types.reference(types.late((): IAnyModelType => PrototypeModel))
      )
    ),
    hypotheses: types.maybe(
      types.map(
        types.reference(types.late((): IAnyModelType => HypothesisModel))
      )
    ),
    publish_state: types.maybe(PublishStateEnum),
    created_at: types.maybe(types.string),
    published_at: types.maybe(types.string),
    tags: types.maybe(TagListModel),
    comments_count: types.maybe(types.number),
    comments: CommentsMapModel,
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    created_by_ai: types.maybe(types.boolean)
  })
  .actions((self) => {
    const putAim = (aim: PainpointAimModelType) => {
      if (self.aims) {
        self.aims.put(aim);
      } else {
        self.aims = createMap([aim]);
      }
    };

    const deleteAim = (aimId: number) => {
      if (self.aims) {
        self.aims.delete(aimId.toString());
      }
    };

    const putQuestion = (question: PainpointQuestionModelType) => {
      if (self.questions) {
        self.questions.put(question);
      } else {
        self.questions = createMap([question]);
      }
    };

    const deleteQuestion = (questionId: number) => {
      if (self.questions) {
        self.questions.delete(questionId.toString());
      }
    };

    const putHypothesis = (hypothesis: any) => {
      if (!hypothesis) {
        return;
      }

      if (!self.hypotheses) {
        self.hypotheses = createMap({});
      }
      self.hypotheses!.put(hypothesis);
    };

    const putPrototype = (prototype: any) => {
      if (!prototype) {
        return;
      }

      if (!self.prototypes) {
        self.prototypes = createMap({});
      }
      self.prototypes!.put(prototype);
    };

    return {
      putAim,
      deleteAim,
      putQuestion,
      deleteQuestion,
      putHypothesis,
      putPrototype
    };
  })
  .views((self) => {
    return {
      get sortedAims(): PainpointAimModelType[] {
        const aims: PainpointAimModelType[] = [];

        if (self.aims && self.aims.size > 0) {
          for (const aim of self.aims.values()) {
            aims.push(aim);
          }

          aims.sort(sortCreatedAsc);
        }

        return aims;
      },
      get sortedQuestions(): PainpointQuestionModelType[] {
        const questions: PainpointQuestionModelType[] = [];

        if (self.questions && self.questions.size > 0) {
          for (const question of self.questions.values()) {
            questions.push(question);
          }

          questions.sort(sortCreatedAsc);
        }

        return questions;
      },

      get sortedHypotheses(): HypothesisModelType[] {
        const hypotheses: HypothesisModelType[] = [];

        if (self.hypotheses && self.hypotheses.size > 0) {
          for (const hypothesis of self.hypotheses.values()) {
            hypotheses.push(hypothesis);
          }

          hypotheses.sort(sortCreatedAsc);
        }

        return hypotheses;
      },
      get sortedPrototypes(): any[] {
        const prototypes: any[] = [];

        if (self.prototypes && self.prototypes.size > 0) {
          for (const prototype of self.prototypes.values()) {
            prototypes.push(prototype);
          }

          prototypes.sort(sortCreatedAsc);
        }

        return prototypes;
      }
    };
  });

export const createPainpointModel = (data?: any): PainpointModelType => {
  return PainpointModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    cluster: data?.cluster ? createFluidClusterModel(data.cluster) : undefined,
    author: createFluidUserModel(data?.author),
    requesting_user: createFluidUserModel(data?.requesting_user),
    question: data?.question || undefined,
    questions: data?.painpoint_questions
      ? createMap(data.painpoint_questions, createPainpointQuestionModel)
      : undefined,
    aims: data?.painpoint_aims
      ? createMap(data.painpoint_aims, createPainpointAimModel)
      : undefined,
    prototypes: undefined,
    hypotheses: undefined,
    publish_state: createPublishState(data?.publish_state),
    created_at: data?.created_at || undefined,
    published_at: data?.published_at || data?.created_at || undefined,
    tags: createTagList(data?.tags),
    comments_count: numberOrUndefined(data?.ratings_count),
    comments: createCommentsMap(data?.ratings),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    created_by_ai: data.created_by_ai
  });
};

export type PainpointModelType = typeof PainpointModel.Type;
export default PainpointModel;
