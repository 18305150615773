import { CSSProperties } from 'react';

interface CircleChartProps {
  progress?: number;
  monochrome?: boolean;
  label?: React.ReactFragment;
}

export const CircleChart = ({
  progress = 0,
  label,
  monochrome
}: CircleChartProps) => (
  <div
    className="circle-chart"
    style={{ '--value': progress } as CSSProperties}
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 600 600">
      <defs>
        <linearGradient
          id={monochrome ? 'paint0_monochrome1' : 'paint0_linear1'}
          x1="130"
          x2="490"
          y1="130"
          y2="490"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={monochrome ? '#262634' : '#304E9D'} />
          <stop offset="1" stopColor={monochrome ? '#262634' : '#E84C88'} />
        </linearGradient>
      </defs>
      <circle
        className="circle-chart__stroke"
        pathLength="100"
        cx="300"
        cy="300"
        r="250"
        stroke={
          monochrome ? 'url(#paint0_monochrome1)' : 'url(#paint0_linear1)'
        }
        strokeWidth="80"
        strokeLinecap="round"
      />
      <circle
        pathLength="100"
        cx="300"
        cy="300"
        r="250"
        stroke="#fff"
        strokeWidth="80"
        strokeLinecap="round"
        opacity="0.1"
      />
    </svg>

    {label && <span className="circle-chart__value">{label}</span>}
  </div>
);

export default CircleChart;
