import { IAnyModelType, types } from 'mobx-state-tree';

import createMap from 'utils/store/createMap';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import HypothesisModel, { HypothesisModelType } from './HypothesisModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PrototypeScreenModel, {
  createPrototypeScreenModel,
  PrototypeScreenModelType
} from './PrototypeScreenModel';
import PublishStateEnum, { createPublishState } from './PublishStateEnum';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const PrototypeModel = types
  .model('PrototypeModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    author: types.maybe(FluidUserModel),
    hypothesis: types.maybe(
      types.reference(types.late((): IAnyModelType => HypothesisModel))
    ),
    headline: types.maybe(types.string),
    // subline: types.maybe(types.string),
    // flow_headline: types.maybe(types.string),
    // flow_subline: types.maybe(types.string),
    flow_description: types.maybe(types.string),
    publish_state: types.maybe(PublishStateEnum),
    created_at: types.maybe(types.string),
    published_at: types.maybe(types.string),
    average_target_group_relevance: types.maybe(types.number),
    average_revenue_potential: types.maybe(types.number),
    average_cost_efficiency: types.maybe(types.number),
    average_differentiation_degree: types.maybe(types.number),
    prototype_screens: types.maybe(types.map(PrototypeScreenModel)),
    tags: types.maybe(TagListModel),
    comments_count: types.maybe(types.number),
    comments: CommentsMapModel,
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    created_by_ai: types.maybe(types.boolean)
  })
  .actions((self) => {
    const putScreen = (screen: PrototypeScreenModelType) => {
      if (self.prototype_screens) {
        self.prototype_screens.put(screen);
      } else {
        self.prototype_screens = createMap([screen]);
      }
    };

    const deleteScreen = (screenId: number) => {
      if (self.prototype_screens) {
        self.prototype_screens.delete(screenId.toString());
      }
    };

    const updateScreenOrder = (screenId: number, newOrder: number) => {
      if (!self.prototype_screens) {
        return;
      }

      const screen = self.prototype_screens.get(screenId.toString());
      if (!screen) {
        return;
      }

      screen.order = newOrder;
    };

    const setHypothesis = (hypothesis?: HypothesisModelType) => {
      self.hypothesis = hypothesis;
    };

    return {
      putScreen,
      deleteScreen,
      updateScreenOrder,
      setHypothesis
    };
  })
  .views((self) => {
    return {
      get hasScreens(): boolean {
        return self.prototype_screens && self.prototype_screens.size > 0
          ? true
          : false;
      },
      get sortedScreens(): PrototypeScreenModelType[] {
        const screens: PrototypeScreenModelType[] = [];

        if (self.prototype_screens && self.prototype_screens.size > 0) {
          for (const screen of self.prototype_screens.values()) {
            screens.push(screen);
          }

          screens.sort(
            (a: PrototypeScreenModelType, b: PrototypeScreenModelType) => {
              const sa = a.order === undefined ? a.id : a.order;
              const sb = b.order === undefined ? b.id : b.order;

              if (sa > sb) {
                return 1;
              }
              if (sa < sb) {
                return -1;
              }
              return 0;
            }
          );
        }

        return screens;
      }
    };
  });

export const createPrototypeModel = (data?: any): PrototypeModelType => {
  return PrototypeModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    author: createFluidUserModel(data?.author),
    hypothesis: undefined,
    headline: data?.headline || undefined,
    // subline: data?.subline || undefined,
    // flow_headline: data?.flow_headline || undefined,
    // flow_subline: data?.flow_subline || undefined,
    flow_description: data?.flow_description || undefined,
    publish_state: createPublishState(data?.publish_state),
    created_at: data?.created_at || undefined,
    published_at: data?.published_at || data?.created_at || undefined,
    average_target_group_relevance: numberOrUndefined(
      data?.average_target_group_relevance
    ),
    average_revenue_potential: numberOrUndefined(
      data?.average_revenue_potential
    ),
    average_cost_efficiency: numberOrUndefined(data?.average_cost_efficiency),
    average_differentiation_degree: numberOrUndefined(
      data?.average_differentiation_degree
    ),
    prototype_screens: data?.prototype_screens
      ? createMap(data.prototype_screens, createPrototypeScreenModel)
      : undefined,
    tags: createTagList(data?.tags),
    comments_count: numberOrUndefined(data?.ratings_count),
    comments: createCommentsMap(data?.ratings),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    created_by_ai: data?.created_by_ai || false
  });
};

export type PrototypeModelType = typeof PrototypeModel.Type;
export default PrototypeModel;
