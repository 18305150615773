import CardWrapper from 'components/CardWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import MainButton from 'components/MainButton';
import {
  ItemLoadingStateEnumType,
  ListLoadingStateEnumType
} from 'models/LoadingStateEnums';
import { FormattedMessage } from 'react-intl';

interface ErrorMessageProps {
  state?: ItemLoadingStateEnumType | ListLoadingStateEnumType;
  onRetry?: () => void;
  onLoadRetry?: () => void;
  onSaveRetry?: () => void;
  onDeleteRetry?: () => void;
  inline?: boolean;
}

export default function ErrorMessage({
  state,
  onRetry,
  onLoadRetry,
  onSaveRetry,
  onDeleteRetry,
  inline
}: ErrorMessageProps) {
  const possibleErrors = [
    'load_error',
    'save_error',
    'delete_error',
    'access_denied',
    'feature_disabled',
    'not_found'
  ];
  if (!state) {
    return null;
  }
  const messageId = possibleErrors.includes(state) ? state : 'unknown_error';
  const MaybeWrapped = ({ children }: { children: React.ReactElement }) => {
    if (inline) {
      return children;
    }
    return <ContentWrapper>{children}</ContentWrapper>;
  };
  const renderRetryButton = (callback: () => void) => (
    <PositionWrapper end={true}>
      <MainButton type="button" onClick={callback}>
        <FormattedMessage id="Try again" />
      </MainButton>
    </PositionWrapper>
  );
  let retryButton;
  if (onLoadRetry && state === 'load_error') {
    retryButton = renderRetryButton(onLoadRetry);
  } else if (onSaveRetry && state === 'save_error') {
    retryButton = renderRetryButton(onSaveRetry);
  } else if (onDeleteRetry && state === 'delete_error') {
    retryButton = renderRetryButton(onDeleteRetry);
  } else if (onRetry) {
    retryButton = renderRetryButton(onRetry);
  }

  return (
    <MaybeWrapped>
      <CardWrapper>
        <RowWrapper>
          <FormattedMessage id={messageId} />
          {retryButton}
        </RowWrapper>
      </CardWrapper>
    </MaybeWrapped>
  );
}
