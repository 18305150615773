import { inject, observer } from 'mobx-react';
import React from 'react';

import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import SearchForm from 'components/SearchForm';
import ListDetailContainer from 'containers/list/ListDetailContainer';
import ListOverviewContainer from 'containers/list/ListOverviewContainer';
import { ElementContentType } from 'containers/list/ListOverviewContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import {
  ContentListStoreType,
  SearchableElementType
} from 'models/ContentListStore';
import { HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import SearchNav from './SearchNav';
import { searchQueryURL } from 'utils/history/search';
import { WrappedComponentProps, injectIntl } from 'react-intl';

interface PublicSearchScreenProps extends WrappedComponentProps {
  form: FormType;
}

interface SearchScreenProps extends PublicSearchScreenProps, HistoryProps {
  contentListStore: ContentListStoreType;
}

@inject('contentListStore')
@observer
class SearchScreen extends React.Component<SearchScreenProps> {
  componentDidMount() {
    this.checkSearch();
  }

  componentDidUpdate(prevProps: SearchScreenProps) {
    if (
      this.getQuery() !== this.getQuery(prevProps) ||
      this.getElementType() !== this.getElementType(prevProps)
    ) {
      this.checkSearch();
    }
  }

  getQuery(props?: SearchScreenProps): string {
    if (!props) {
      props = this.props;
    }
    return (props?.location?.query?.query || '').toString().trim();
  }

  getElementType(props?: SearchScreenProps): SearchableElementType | undefined {
    if (!props) {
      props = this.props;
    }

    const type = props.location?.query?.type;
    if (!type) {
      return undefined;
    }

    switch (type) {
      case 'briefing':
        return ElementType.Briefing;

      case 'painpoint':
        return ElementType.Painpoint;

      case 'benchmark':
        return ElementType.Benchmark;

      case 'hypothesis':
        return ElementType.Hypothesis;

      case 'prototype':
        return ElementType.Prototype;

      case 'learning':
        return ElementType.Learning;

      default:
        return undefined;
    }
  }

  submitSearchForm() {
    const searchURL = searchQueryURL({ query: this.props.form.values.query });
    this.props.history.push(searchURL);
  }

  checkSearch() {
    const query = this.getQuery();

    if (query === '') {
      return;
    }

    const elementType = this.getElementType();

    if (
      isPush(this.props.history) ||
      !this.props.contentListStore.isCurrentSearch(query, elementType)
    ) {
      this.search();
    }
  }

  search(page?: number) {
    const { contentListStore } = this.props;
    const query = this.getQuery();

    if (query === '') {
      // TODO clear search results here?
      return;
    }

    const elementType = this.getElementType();

    contentListStore.search({
      query,
      organizationId: this.props?.location?.query?.organization_id,
      projectId: this.props?.location?.query?.project_id,
      elementType,
      page
    });
  }

  loadMore() {
    const elementType = this.getElementType();
    if (!elementType) {
      return;
    }

    const { contentListStore } = this.props;

    this.search(contentListStore.pageOf(elementType) + 1);
  }

  renderPage(content: any) {
    return (
      <AppLayoutReduced>
        <PageHeader titleId="Search" />
        {content}
      </AppLayoutReduced>
    );
  }

  renderOverview() {
    if (this.props.contentListStore.isListLoading) {
      return this.renderLoading();
    }

    const query = this.getQuery();
    const baseUrl = searchQueryURL({
      query,
      filter: this.props?.location?.query?.filter
    });

    return this.renderPage(
      <ContentWrapper small={false}>
        <SearchForm
          query={query}
          filter={this.props?.location?.query?.filter}
          placeholder={this.props.intl.formatMessage({ id: 'Search it' })}
        />

        <SearchNav baseUrl={baseUrl} />

        <ListOverviewContainer
          baseUrl={baseUrl}
          limit={3}
          largeSlider={true}
          onRetry={() => this.search()}
        />
        <CallToAction hideSearch={true} />
      </ContentWrapper>
    );
  }

  renderDetail() {
    const elementType = this.getElementType();
    if (!elementType) {
      return null;
    }

    const { contentListStore } = this.props;

    let loadingMore = false;
    if (contentListStore.isListLoading) {
      if (contentListStore.hasAnyOf(elementType)) {
        loadingMore = true;
      } else {
        return this.renderLoading();
      }
    }

    const query = this.getQuery();
    const baseUrl = searchQueryURL({
      query,
      filter: this.props?.location?.query?.filter
    });

    return this.renderPage(
      <ContentWrapper>
        <SearchForm
          query={query}
          filter={this.props?.location?.query?.filter}
          placeholder={this.props.intl.formatMessage({ id: 'Search it' })}
        />

        <SearchNav
          baseUrl={baseUrl}
          activeType={
            this.props.location?.query?.type as ElementContentType | undefined
          }
        />

        <ListDetailContainer
          elementType={elementType}
          loadingMore={loadingMore}
          onLoadMore={() => this.loadMore()}
          noHeader={true}
          reduced={true}
          onRetry={() => this.search()}
        />
      </ContentWrapper>
    );
  }

  renderLoading() {
    const query = this.getQuery();

    return this.renderPage(
      <ContentWrapper>
        <SearchForm
          query={query}
          filter={this.props?.location?.query?.filter}
          placeholder={this.props.intl.formatMessage({ id: 'Search it' })}
        />
        <SearchNav
          activeType={
            this.props.location?.query?.type as ElementContentType | undefined
          }
          baseUrl=""
        />

        <Loading />
      </ContentWrapper>
    );
  }

  render() {
    if (this.getElementType()) {
      // detail page
      return this.renderDetail();
    }

    // overview page
    return this.renderOverview();
  }
}

export default injectIntl((props: SearchScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <SearchScreen {...props} form={form} />;
});
