import { inject, observer } from 'mobx-react';
import React from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { DataStoreType } from 'models/DataStore';

interface PublicBenchmarksListContainerProps {
  itemType: any;
  itemContainer?: any;
  emptyComponent?: any;
  loading?: any;
  selected?: number[];
  filter?: string;
  onItemClick?: (id: number) => void;
}

interface BenchmarksListContainerProps
  extends PublicBenchmarksListContainerProps {
  dataStore: DataStoreType;
  benchmarksStore: BenchmarksStoreType;
}

@inject('dataStore', 'benchmarksStore')
@observer
class BenchmarksListContainer extends React.Component<BenchmarksListContainerProps> {
  componentDidMount() {
    this.loadBenchmarks();
  }

  loadBenchmarks() {
    this.props.benchmarksStore.getBenchmarks();
  }

  renderLoading() {
    if (this.props.loading) {
      return this.props.loading;
    }

    return <Loading />;
  }

  renderError() {
    // TODO
    return (
      <ErrorMessage
        state={this.props.benchmarksStore.listLoadingState}
        onRetry={() => this.loadBenchmarks()}
      />
    );
  }

  render() {
    const {
      benchmarksStore,
      itemType,
      itemContainer,
      emptyComponent,
      onItemClick,
      selected,
      filter
    } = this.props;

    if (benchmarksStore.isListLoading) {
      return this.renderLoading();
    }
    if (benchmarksStore.isListError) {
      return this.renderLoading();
    }

    const Item = itemType;
    const Container = !itemContainer ? React.Fragment : itemContainer;
    const Empty = !emptyComponent ? React.Fragment : emptyComponent;

    const list = benchmarksStore
      .list('active', undefined, undefined, 'headline', filter || undefined)
      .map((elem) => (
        <Item
          key={elem.id}
          benchmark={elem}
          onClick={() => onItemClick && onItemClick(elem.id)}
          selected={selected && selected.indexOf(elem.id) > -1}
        />
      ));

    return <Container>{!list.length ? <Empty /> : list}</Container>;
  }
}

export default (props: PublicBenchmarksListContainerProps) => {
  // @ts-ignore
  return <BenchmarksListContainer {...props} />;
};
