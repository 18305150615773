import { types } from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';

const AuthStateModel = types.model('AuthStateModel', {
  loading: types.maybe(
    types.enumeration(['login', 'check', 'refresh', 'logout'])
  ),
  error: types.maybe(
    types.enumeration([
      // TODO Do we need all of these?
      'not_found',
      'login_failed',
      'login_error',
      'check_error',
      'logout_error',
      'refresh_error'
    ])
  ),
  resetActive: types.maybe(types.boolean),
  resetState: types.maybe(
    types.enumeration([
      'loading',
      'user_not_found',
      'token_requested',
      'token_invalid',
      'finished',
      'error'
    ])
  ),
  joinState: types.maybe(
    types.enumeration([
      'loading',
      'token_invalid',
      'finished',
      'error'
    ])
  ),
  verifyEmailState: types.maybe(
    types.enumeration([
      'loading',
      'token_invalid',
      'finished',
      'error'
    ])
  )
});

export const createAuthStateModel = (data?: any): AuthStateModelType => {
  return {
    loading: data?.loading || undefined,
    error: data?.error || undefined,
    resetActive: booleanOrUndefined(data?.resetActive),
    resetState: data?.reset || undefined,
    joinState: data?.join || undefined,
    verifyEmailState: data?.verifyEmail || undefined
  };
};

export type AuthStateModelType = typeof AuthStateModel.Type;
export default AuthStateModel;
