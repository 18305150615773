import React from 'react';

import ProfileLayout from 'components/ProfileLayout';
import UserElementsListContainer from 'containers/UserElementsListContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { HistoryProps, idFromMatch } from 'utils/history';

interface UserBenchmarksListScreenProps {
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('dataStore', 'applicationStore')
@observer
class UserBenchmarksListScreen extends React.Component<
  HistoryProps & UserBenchmarksListScreenProps
> {
  render() {
    const { match, dataStore, applicationStore } = this.props;
    const userId = idFromMatch(match);
    const user = dataStore.users.get(userId!.toString())!;
    const isSelf = applicationStore.currentUser?.id === userId;

    return (
      <ProfileLayout
        active="benchmarks"
        userName={user!.fullName}
        userId={user!.id}
        isSelf={isSelf}
      >
        <UserElementsListContainer
          elementType={ElementType.Benchmark}
          user={user}
        />
      </ProfileLayout>
    );
  }
}

export default (props: any) => {
  // @ts-ignore
  return <UserBenchmarksListScreen {...props} />;
};
