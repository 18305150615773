
interface flowProps {
  children: React.ReactNode;
  flowSpace?: string;
}

export default function Flow({ children, flowSpace }: flowProps) {
  const style = { '--flow-space': flowSpace } as React.CSSProperties;

  return (
    <div className="flow" style={style}>
      {children}
    </div>
  );
}
