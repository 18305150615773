const env = process.env.DISROOPTIVE_ENVIRONMENT || 'development';
const isDev = env === 'development' ? true : false;
const isStaging = env === 'staging' ? true : false;

export default {
  apiUrl: process.env.DISROOPTIVE_API_URL || '/api/v1',
  apiCorsPolicy: process.env.DISROOPTIVE_API_CORS || 'include', // include|same-origin - see https://mzl.la/2JIauRr

  // cableUrl: process.env.DISROOPTIVE_CABLE_URL || '/cable',
  cableUrl: isDev ? 'ws://localhost:3000/cable' : (process.env.DISROOPTIVE_CABLE_URL || '/cable'),

  minPasswordLength: 6,

  env: {
    environment: env,
    development: isDev,
    staging: isStaging,
    production: env === 'production' ? true : false,
    devOrStaging: isDev || isStaging ? true : false
  },

  legalUrl: 'https://disrooptive.com/impressum',
  privacyUrl: 'https://disrooptive.com/datenschutz',

  langCookieName: 'app_lang'
};
