import classNames from 'classnames';

interface SelectMenuProps {
  children?: React.ReactNode;
  fourColumns?: boolean;
  fiveColumns?: boolean;
}

export default function SelectMenu({
  children,
  fourColumns,
  fiveColumns
}: SelectMenuProps) {
  return (
    <div
      className={classNames({
        'select-menu': true,
        'select-menu--fourColumns': fourColumns,
        'select-menu--fiveColumns': fiveColumns
      })}
    >
      {children}
    </div>
  );
}
