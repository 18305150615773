import { PainpointModelType } from 'models/PainpointModel';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';

export default ({
  painpoint,
  onClick,
  onChange,
  selected
}: {
  painpoint: PainpointModelType;
  onClick: () => void;
  onChange?: () => void;
  selected?: boolean;
}) => (
  <PainpointsListItem
    painpoint={painpoint}
    onClick={onClick}
    onChange={onChange}
    checked={selected}
    readOnly={true}
  />
);
