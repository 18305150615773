import { FormattedMessage } from 'react-intl';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import SidebarItem from '../SidebarItem';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';
import { observer } from 'mobx-react';

function SidebarSelectedHypothesis({ session }: SidebarItemProps) {
  const screens =
    session?.sortedScreens
      .filter((screen) => screen.attachments?.hasAny)
      .slice(0, 3)
      .map(
        (screen) =>
          screen.attachments!.firstOfType('screen')!.resource_urls!.large!
      ) || [];

  return (
    <SidebarMarker type="userflow">
      <SidebarItem>
        <SidebarItemHeader>
          <FormattedMessage id="assistant sidebar uploaded flows" />
        </SidebarItemHeader>

        <RainbowCard
          unresponsive={true}
          to={{
            pathname:
              ROUTE_ASSISTANT +
              '/prototypes/' +
              session!.id +
              ASSISTANTS.prototype_sprint.step4.route,
            state: {
              canGoBack: true
            }
          }}
          forSidebar={true}
          src={screens}
          alt=""
        >
          <Typography size="small" opaque={true}>
            Rapid Prototype
          </Typography>
        </RainbowCard>
      </SidebarItem>
    </SidebarMarker>
  );
}

export default observer(SidebarSelectedHypothesis);
