import { FormattedMessage } from 'react-intl';

import { SessionMainText } from 'domain/assistant/session-elements';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';
import ExplanationScreen from './ExplanationScreen';

export const IntroductionScreen = ({ location }: HistoryProps) => (
  <ExplanationScreen
    progress={ASSISTANTS.creative_session.introduction.progress}
    sidebarHeadline={<FormattedMessage id="assistant material" />}
    header={<FormattedMessage id="assistant introduction header" />}
    headerStep={<FormattedMessage id="Preparation" />}
    videoId="hCgMr0M9gGY"
    videoPreview="/images/assistant/video/introduction.png"
    buttonTextId="assistant begin button"
    location={location}
  >
    <SessionMainText>
      <FormattedMessage
        id="assistant introduction"
        values={{ break: <br /> }}
      />
    </SessionMainText>
  </ExplanationScreen>
);

export default IntroductionScreen;
