import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApiElementTypeEnumType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  AttachmentModelType,
  AttachmentTypeEnumType
} from 'models/AttachmentModel';
import { AttachmentsStoreType } from 'models/AttachmentsStore';
import React from 'react';
import styled from 'styled-components';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

const ACCEPT_IMAGES = ['image/jpeg', 'image/png'];
const ACCEPT_VIDEO = ['video/*', '.mov', '.mpeg', '.mp4', '.mpg'];

export type UploadContainerStateEnum = 'saving' | 'waiting';

export interface UploadContainerChildProps {
  onSelectFileClick: (a: React.MouseEvent<HTMLButtonElement>) => void;
  onUploadClick: () => void;
  onResetClick: () => void;
  state?: UploadContainerStateEnum;
  error?: string | boolean;
}

interface PublicUploadContainerProps {
  organizationId?: number;
  projectId?: number;
  elementType: ApiElementTypeEnumType;
  elementId?: number;
  attachmentType: AttachmentTypeEnumType;
  waitForId?: boolean;
  noAutoUploadOnSelect?: boolean;
  onAttachmentReceived?: (attachment: AttachmentModelType) => void;
  onLoadingStateChanged?: (
    newState: UploadContainerStateEnum | undefined
  ) => void;
  onWaitingForId?: () => void;
  children?: (childProps: UploadContainerChildProps) => React.ReactNode;
  acceptVideo?: boolean;
  autoSelect?: boolean;
}

interface UploadContainerProps extends PublicUploadContainerProps {
  applicationStore: ApplicationStoreType;
  attachmentsStore: AttachmentsStoreType;
  form: FormType;
}

// https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Using_a_label_element_to_trigger_a_hidden_file_input_element
const VisuallyHiddenInput = styled.input`
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
`;

@inject('applicationStore', 'attachmentsStore')
@observer
class UploadContainer extends React.Component<UploadContainerProps> {
  _inputRef?: any = null;

  componentDidMount() {
    if (this.props.autoSelect) {
      this.selectFile();
    }
  }

  componentDidUpdate(prevProps: UploadContainerProps) {
    const { elementId, form } = this.props;

    if (
      prevProps.waitForId &&
      !prevProps.elementId &&
      elementId &&
      form.values.file
    ) {
      this.upload();
    }
  }

  async upload(file?: File) {
    const {
      form,
      attachmentType,
      attachmentsStore,
      elementType,
      elementId,
      waitForId,
      onLoadingStateChanged,
      onAttachmentReceived,
      onWaitingForId
    } = this.props;

    const uploadFile = file || form.values.file;

    if (!uploadFile) {
      return;
    }

    if (!elementId && waitForId) {
      onWaitingForId && onWaitingForId();
      return;
    }

    form.resetErrors();

    form.setLoading(true);
    if (onLoadingStateChanged) {
      onLoadingStateChanged('saving');
    }

    try {
      const attachment = await attachmentsStore.upload(
        uploadFile,
        attachmentType,
        elementType,
        elementId
      );

      if (onAttachmentReceived && attachment) {
        onAttachmentReceived(attachment);
      }
    } catch (error: any) {
      if (handleFormError(form, error) && !!form.errors.file) {
        this.props.applicationStore.setFlashMessage(
          getIntl().formatMessage(
            { id: 'upload form error flash' },
            {
              message: form.errors.file
            }
          ),
          'error'
        );
      } else {
        form.setError('file', true);

        this.props.applicationStore.setFlashMessage(
          getIntl().formatMessage({ id: 'upload error flash' }),
          'error'
        );
      }
    }

    form.setLoading(false);
    if (onLoadingStateChanged) {
      onLoadingStateChanged(undefined);
    }

    if (!this.props.noAutoUploadOnSelect) {
      form.setField('file', undefined);
    }
  }

  reset() {
    this.props.form.reset();

    if (this._inputRef) {
      this._inputRef.value = '';
    }
  }

  fileChanged(e: any) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    this.props.form.setField('file', file);

    if (!this.props.noAutoUploadOnSelect) {
      setTimeout(() => {
        this.upload(file);
      });
    }
  }

  selectFile(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (this._inputRef) {
      this._inputRef.click();
    }
  }

  render() {
    const { form, attachmentsStore, waitForId, children, acceptVideo } =
      this.props;

    const isWaiting = waitForId && !!form.values.file;
    const isInputDisabled = isWaiting || attachmentsStore.isItemSaving;

    const state = isWaiting ? 'waiting' : form.loading ? 'saving' : undefined;

    const acceptTypes = ACCEPT_IMAGES;
    if (acceptVideo) {
      acceptTypes.push(...ACCEPT_VIDEO);
    }

    return (
      <>
        {children
          ? children({
              onSelectFileClick: (e) => this.selectFile(e),
              onUploadClick: () => this.upload(),
              onResetClick: () => this.reset(),
              state,
              error: form.errors.file
            })
          : null}

        <VisuallyHiddenInput
          type="file"
          name="file"
          accept={acceptTypes.join(',')}
          onChange={(e) => this.fileChanged(e)}
          disabled={isInputDisabled}
          ref={(r) => (this._inputRef = r)}
        />
      </>
    );
  }
}

export default (props: PublicUploadContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <UploadContainer form={form} {...props} />;
};
