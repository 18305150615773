import { flow, getEnv, types } from 'mobx-state-tree';

import { ApiElementTypeEnumType } from './ApiElementTypeEnum';
import { CommentModelType, createCommentModel, VALUATION_TYPES } from './CommentModel';
import { ItemLoadingStateEnum } from './LoadingStateEnums';
import { AdvancedStoreEnv } from './StoreEnv';

const CommentsStore = types
  .model('CommentsStore', {
    formVisible: types.maybe(types.boolean),
    itemLoadingState: types.maybe(ItemLoadingStateEnum)
  })
  .actions(self => {
    const showAddCommentForm = () => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      dataStore.setCommentItem(undefined);
      self.itemLoadingState = undefined;

      self.formVisible = true;
    };

    const showEditCommentForm = (comment: CommentModelType) => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);

      dataStore.setCommentItem(comment);
      self.itemLoadingState = undefined;

      self.formVisible = true;
    };

    const closeCommentForm = () => {
      self.formVisible = false;
    };

    const getComment = flow(function*(isValuation: boolean, commentId: number) {
      const { client, dataStore, applicationStore } = getEnv<AdvancedStoreEnv>(
        self
      );

      const method = isValuation ? client.getRating : client.getComment;

      try {
        self.itemLoadingState = 'loading';
        dataStore.setCommentItem(undefined);

        const result: any = yield method(commentId);

        if (!result) {
          throw new Error('No response from server');
        }

        const comment = dataStore.setCommentItem(createCommentModel(result));

        self.itemLoadingState = undefined;
        return comment;
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('CommentsStore | getComment', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createComment = flow(function*(
      elementType: ApiElementTypeEnumType,
      elementId: number,
      comment: CommentModelType,
      closeForm: boolean = true,
      clearItem: boolean = true
    ) {
      const { client, dataStore, applicationStore } = getEnv<AdvancedStoreEnv>(
        self
      );

      const isValuation = VALUATION_TYPES.includes(elementType);
      const method = isValuation ? client.createRating : client.createComment;

      try {
        self.itemLoadingState = 'saving';
        dataStore.setCommentItem(undefined);

        const result: any = yield method({
          ...comment,
          element_type: elementType,
          element_id: elementId
        });

        if (!result) {
          throw new Error('No response from server');
        }

        const newComment = createCommentModel(result);

        if (clearItem) {
          // As we add comments with a layer which is closed after adding the comment,
          // we do not need the comment to be stored as an item, so clear it just to
          // be sure.
          dataStore.setCommentItem(undefined);
        }

        if (closeForm) {
          closeCommentForm();
        }

        self.itemLoadingState = undefined;
        return newComment;
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('CommentsStore | createComment', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const updateComment = flow(function*(
      isValuation: boolean,
      commentId: number,
      patch: CommentModelType,
      closeForm: boolean = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      const method = isValuation ? client.updateRating : client.updateComment;

      try {
        self.itemLoadingState = 'saving';

        const result: any = yield method(commentId, patch);

        if (!result) {
          throw new Error('No response from server');
        }

        const updatedComment = createCommentModel(result);

        if (closeForm) {
          closeCommentForm();
        }

        // dataStore.replaceCommentItem(updatedComment);

        self.itemLoadingState = undefined;
        return updatedComment;
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('CommentsStore | updateComment', error, error.body);
        }

        if (client.isNotFound(error)) {
          // TODO Handle by creating new comment - at least when trying to edit own comment?
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    // TODO throw error or store state in comment model to display error?
    const removeComment = flow(function*(
      isValuation: boolean,
      commentId: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      const method = isValuation ? client.deleteRating : client.deleteComment;

      try {
        // self.itemLoadingState = 'deleting';

        yield method(commentId);

        // self.itemLoadingState = undefined;
        return true; // TODO this is just temporary, see TODO above
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('CommentsStore | removeComment', error, error.body);
        }

        if (client.isNotFound(error)) {
          // TODO Handle by creating new comment - at least when trying to edit own comment?
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        return;
      }
    });

    return {
      showAddCommentForm,
      showEditCommentForm,
      closeCommentForm,
      getComment,
      createComment,
      updateComment,
      removeComment
    };
  })
  .views(self => {
    return {
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'not_found' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      }
    };
  });

export type CommentsStoreeType = typeof CommentsStore.Type;
export default CommentsStore;
