import Textarea from 'components/Inputs/Textarea';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import { AssistantSidebarProps } from 'domain/assistant/AssistantSidebar/AssistantSidebar';
import { SidebarMarkerType } from 'domain/assistant/AssistantSidebar/SidebarMarker';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantSessionModelType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { goToNextStep } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicSingleInputScreenProps extends WrappedComponentProps {
  progress: number;
  barProgress?: number;
  sidebarHeadline?: React.ReactNode;
  videoId?: string;
  nextRoute?: string;
  nextRouteIsAbsolute?: boolean;
  fieldName: keyof AssistantSessionModelType;
  dateFieldName?: keyof AssistantSessionModelType;
  header?: string | React.ReactNode;
  headerStep?: string | React.ReactNode;
  children?: any;
  placeholderId?: string;
  timerSeconds?: number;
  showHeader?: AssistantSidebarProps['showHeader'];
  sidebarScrollTo?: SidebarMarkerType;
  allowEmptyInput?: boolean;
  predefinedText?: React.ReactNode;
  largeTextareaFont?: boolean;
  location: HistoryProps['location'];
}

interface SingleInputScreenProps extends PublicSingleInputScreenProps {
  form: FormType;
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
}

interface SingleInputScreenState {
  videoVisible?: boolean;
}

@inject('applicationStore', 'assistantStore')
@observer
class SingleInputScreen extends React.Component<
  SingleInputScreenProps,
  SingleInputScreenState
> {
  state: SingleInputScreenState = {};

  componentDidMount() {
    const { sidebarScrollTo } = this.props;

    if (sidebarScrollTo) {
      scrollSidebar(sidebarScrollTo);
    }

    this.fillForm();
  }

  private fillForm() {
    const {
      assistantStore: { currentItem },
      form,
      fieldName
    } = this.props;

    form.setField(fieldName as string, currentItem?.[fieldName] || '');
  }

  private async submit(continueSession: boolean = false) {
    const {
      applicationStore,
      assistantStore,
      intl,
      form,
      fieldName,
      dateFieldName,
      progress,
      nextRoute,
      nextRouteIsAbsolute,
      allowEmptyInput
    } = this.props;
    const { currentId, currentAssistantType } = assistantStore;

    if (!currentId || !currentAssistantType) {
      return;
    }

    form.resetErrors();

    const value = form.values[fieldName] || '';

    const patch: Partial<AssistantSessionModelType> = {
      [fieldName]: value
    };

    if (dateFieldName) {
      (patch as any)[dateFieldName] = new Date().toJSON();
    }

    if (continueSession) {
      patch.progress = progress;
    }

    let newAssistantSession;
    try {
      newAssistantSession = await assistantStore.updateAssistantSession(
        currentId,
        patch
      );
    } catch (error: any) {
      if (handleFormError(form, error)) {
        return;
      }
    }

    if (newAssistantSession) {
      this.fillForm();
    } else {
      // TODO change updateAssistantSession to throw any error?
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'save error' }),
        'error'
      );
      return;
    }

    if (continueSession && (!!value || allowEmptyInput)) {
      if (nextRoute) {
        history.push(
          nextRouteIsAbsolute
            ? nextRoute
            : ROUTE_ASSISTANT + '/' + currentId + nextRoute
        );
      } else {
        goToNextStep(currentId, progress, currentAssistantType);
      }
    } else {
      history.push(ROUTE_ASSISTANT);
    }
  }

  renderBackStep() {
    const { videoId } = this.props;

    if (!videoId) {
      return null;
    }

    return <VideoPopupLink videoId={videoId} />;
  }

  render() {
    const {
      intl,
      form,
      assistantStore: { isItemSaving, currentId },
      header,
      headerStep,
      children,
      fieldName,
      placeholderId,
      barProgress,
      sidebarHeadline,
      allowEmptyInput,
      predefinedText,
      largeTextareaFont,
      timerSeconds,
      showHeader,
      location
    } = this.props;

    const isInputEmpty = !form.values[fieldName];

    return (
      <StepContainer
        progress={barProgress}
        sidebarHeadline={sidebarHeadline}
        timerSeconds={timerSeconds}
        showHeader={showHeader}
        location={location}
        sessionId={currentId}
      >
        {isItemSaving && <Loading />}

        <SessionTop>
          {header && (
            <SessionHeadline step={headerStep}>{header}</SessionHeadline>
          )}

          {children}

          {this.renderBackStep()}
        </SessionTop>

        <SessionBottom>
          <Textarea
            autoFocus={true}
            placeholder={
              placeholderId
                ? intl.formatMessage({ id: placeholderId })
                : undefined
            }
            name={fieldName as string}
            predefined_text={predefinedText}
            noAutoHeight={true}
            largeFont={largeTextareaFont}
            slim={true}
            {...form.bindInput(fieldName as string)}
          />

          <SessionActions>
            <MainButton secondary={true} onClick={() => this.submit()}>
              <FormattedMessage id="Save draft" />
            </MainButton>
            <MainButton
              type="submit"
              onClick={() => this.submit(true)}
              disabled={!allowEmptyInput && isInputEmpty}
            >
              <FormattedMessage id="Next" />
            </MainButton>
          </SessionActions>
        </SessionBottom>
      </StepContainer>
    );
  }
}

export default injectIntl((props: PublicSingleInputScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <SingleInputScreen {...props} form={form} />;
});
