import classNames from 'classnames';

interface ChildrenProps {
  children?: React.ReactNode;
  settings?: boolean;
}

export const RainbowEditWrapper = ({ children, settings }: ChildrenProps) => {
  const rainbowEditWrapperClasses = classNames('l-rainbow-edit-wrapper', {
    'l-rainbow-edit-wrapper--settings': settings
  });

  return <div className={rainbowEditWrapperClasses}>{children}</div>;
};

RainbowEditWrapper.Image = ({ children }: ChildrenProps) => {
  return <div className="l-rainbow-edit-wrapper__image">{children}</div>;
};

RainbowEditWrapper.Body = ({ children }: ChildrenProps) => {
  return <div className="l-rainbow-edit-wrapper__body">{children}</div>;
};

export default RainbowEditWrapper;
