import AutoGrid from 'components/Layout/AutoGrid';
import SearchForm from 'components/SearchForm/SelectPopupSearchForm';
import SimplePopup from 'components/SimplePopup';
import HypothesesListContainer from 'containers/HypothesesListContainer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import useForm from 'utils/hooks/useForm';

import HypothesisSelectPopupItem from './HypothesisSelectPopupItem';

export default injectIntl(
  ({
    onAbort,
    onSelect,
    intl
  }: {
    onAbort: () => void;
    onSelect: (id: number) => void;
  } & WrappedComponentProps) => {
    const form = useForm({ filter: '' });

    return (
      <SimplePopup onAbort={onAbort} headlineTextId="Select hypothesis">
        <SearchForm
          name="filter"
          placeholder={intl.formatMessage({ id: 'Hypothesis search' })}
          {...form.bindInput('filter')}
        />
        <div className="simple-popup__accent-body simple-popup__scrollable-body">
          <HypothesesListContainer
            itemType={HypothesisSelectPopupItem}
            itemContainer={AutoGrid}
            onItemClick={onSelect}
            filter={(form.values.filter || '').toString().trim()}
          />
        </div>
      </SimplePopup>
    );
  }
);
