import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import {
  BriefingModelType,
  createFluidBriefingModel,
  FluidBriefingModel,
  FluidBriefingModelType
} from './BriefingModel';
import ModuleStateEnumModel, {
  createModuleStateEnumModel
} from './ModuleStateEnumModel';
import {
  createFluidOrganizationModel,
  FluidOrganizationModel
} from './OrganizationModel';
import ProjectAccessLevelEnumModel, {
  createProjectAccessLevelEnumModel
} from './ProjectAccessLevelEnumModel';
import { ProjectLanguageTypeEnum } from './ProjectLanguageEnum';

export const ProjectModel = types
  .model('ProjectModel', {
    id: types.identifierNumber,
    organization_id: types.maybe(types.number),
    organization: types.maybe(
      types.late((): IAnyModelType => FluidOrganizationModel)
    ),
    topic: types.maybe(types.string),
    created_at: types.maybe(types.string),
    private: types.maybe(types.boolean),
    painpoints_state: types.maybe(ModuleStateEnumModel),
    benchmarks_state: types.maybe(ModuleStateEnumModel),
    hypotheses_state: types.maybe(ModuleStateEnumModel),
    prototypes_state: types.maybe(ModuleStateEnumModel),
    learnings_state: types.maybe(ModuleStateEnumModel),
    network_project_state: types.maybe(ModuleStateEnumModel),
    network_painpoints_state: types.maybe(ModuleStateEnumModel),
    network_benchmarks_state: types.maybe(ModuleStateEnumModel),
    network_hypotheses_state: types.maybe(ModuleStateEnumModel),
    network_prototypes_state: types.maybe(ModuleStateEnumModel),
    network_learnings_state: types.maybe(ModuleStateEnumModel),
    painpoints_count: types.maybe(types.number),
    benchmarks_count: types.maybe(types.number),
    hypotheses_count: types.maybe(types.number),
    prototypes_count: types.maybe(types.number),
    learnings_count: types.maybe(types.number),
    access_level: types.maybe(ProjectAccessLevelEnumModel),
    briefing: types.maybe(types.late((): IAnyModelType => FluidBriefingModel)),
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    assistant_invites: types.maybe(
      types.model({
        total_count: types.maybe(types.number),
        published_count: types.maybe(types.number)
      })
    ),
    ai_at_work: types.maybe(types.boolean),
    gpt_strategy_type: types.maybe(types.string),
    min_selected_painpoints_for_ai: types.maybe(types.number),
    max_selected_painpoints_for_ai: types.maybe(types.number),
    min_selected_benchmarks_for_ai: types.maybe(types.number),
    max_selected_benchmarks_for_ai: types.maybe(types.number),
    language: types.maybe(ProjectLanguageTypeEnum)
  })
  .actions((self) => ({
    setAiAtWork(aiAtWork: boolean) {
      self.ai_at_work = aiAtWork;
    },
    setBriefing(briefing: BriefingModelType | FluidBriefingModelType) {
      self.briefing = briefing;
    },
    incrementBenchmarkCount() {
      if (self.benchmarks_count != null) {
        self.benchmarks_count += 1;
      } else {
        self.benchmarks_count = 1;
      }
    },
    decrementBenchmarkCount() {
      if (self.benchmarks_count != null && self.benchmarks_count > 0) {
        self.benchmarks_count -= 1;
      }
    },
    setBenchmarkCount(count: number) {
      self.benchmarks_count = count;
    },
    incrementPainpointCount() {
      if (self.painpoints_count != null) {
        self.painpoints_count += 1;
      } else {
        self.painpoints_count = 1;
      }
    },
    decrementPainpointCount() {
      if (self.painpoints_count != null && self.painpoints_count > 0) {
        self.painpoints_count -= 1;
      }
    },
    setPainpointCount(count: number) {
      self.painpoints_count = count;
    },
    setHypothesesCount(count: number) {
      self.hypotheses_count = count;
    },
    incrementHypothesesCount() {
      if (self.hypotheses_count != null) {
        self.hypotheses_count += 1;
      } else {
        self.hypotheses_count = 1;
      }
    },
    decrementHypothesesCount() {
      if (self.hypotheses_count != null && self.hypotheses_count > 0) {
        self.hypotheses_count -= 1;
      }
    },
    incrementPrototypesCount() {
      if (self.prototypes_count != null) {
        self.prototypes_count += 1;
      } else {
        self.prototypes_count = 1;
      }
    },
    decrementPrototypesCount() {
      if (self.prototypes_count != null && self.prototypes_count > 0) {
        self.prototypes_count -= 1;
      }
    }
  }))
  .views((self) => ({
    get aiSelectLimits(): {
      minPainpoints: number;
      maxPainpoints: number;
      minBenchmarks: number;
      maxBenchmarks: number;
    } {
      return {
        minPainpoints: self.min_selected_painpoints_for_ai || 2,
        maxPainpoints: self.max_selected_painpoints_for_ai || 5,
        minBenchmarks: self.min_selected_benchmarks_for_ai || 2,
        maxBenchmarks: self.max_selected_benchmarks_for_ai || 5
      };
    },
    get reachedPainpointLimit(): boolean {
      return (
        !!self.organization!.painpoint_limit &&
        self.painpoints_count! >= self.organization.painpoint_limit
      );
    },
    get reachedBenchmarkLimit(): boolean {
      return (
        !!self.organization!.benchmark_limit &&
        self.benchmarks_count! >= self.organization.benchmark_limit!
      );
    },
    get reachedHypothesisLimit(): boolean {
      return (
        !!self.organization!.hypothesis_limit &&
        self.hypotheses_count! >= self.organization.hypothesis_limit!
      );
    },
    get reachedPrototypeLimit(): boolean {
      return (
        !!self.organization!.prototype_limit &&
        self.prototypes_count! >= self.organization.prototype_limit
      );
    }
  }));

export const createProjectModel = (data?: any): ProjectModelType => {
  return ProjectModel.create({
    id: mandatoryId(data?.id),
    organization_id: numberOrUndefined(data?.organization_id),
    organization: data?.organization
      ? createFluidOrganizationModel(data.organization)
      : undefined,
    topic: data?.topic || undefined,
    created_at: data?.created_at || undefined,
    private: data?.private || undefined,
    painpoints_state: createModuleStateEnumModel(data?.painpoints_state),
    benchmarks_state: createModuleStateEnumModel(data?.benchmarks_state),
    hypotheses_state: createModuleStateEnumModel(data?.hypotheses_state),
    prototypes_state: createModuleStateEnumModel(data?.prototypes_state),
    learnings_state: createModuleStateEnumModel(data?.learnings_state),
    network_project_state: createModuleStateEnumModel(
      data?.network_project_state
    ),
    network_painpoints_state: createModuleStateEnumModel(
      data?.network_painpoints_state
    ),
    network_benchmarks_state: createModuleStateEnumModel(
      data?.network_benchmarks_state
    ),
    network_hypotheses_state: createModuleStateEnumModel(
      data?.network_hypotheses_state
    ),
    network_prototypes_state: createModuleStateEnumModel(
      data?.network_prototypes_state
    ),
    network_learnings_state: createModuleStateEnumModel(
      data?.network_learnings_state
    ),
    painpoints_count: numberOrUndefined(data?.painpoints_count),
    benchmarks_count: numberOrUndefined(data?.benchmarks_count),
    hypotheses_count: numberOrUndefined(data?.hypotheses_count),
    prototypes_count: numberOrUndefined(data?.prototypes_count),
    learnings_count: numberOrUndefined(data?.learnings_count),
    access_level: createProjectAccessLevelEnumModel(data?.access_level),
    briefing: data?.briefing
      ? createFluidBriefingModel(data?.briefing)
      : undefined,
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    assistant_invites: data.assistant_invites
      ? {
          total_count: numberOrUndefined(data.assistant_invites.total_count),
          published_count: numberOrUndefined(
            data.assistant_invites.published_count
          )
        }
      : undefined,
    gpt_strategy_type: data?.gpt_strategy_type || undefined,
    min_selected_painpoints_for_ai: numberOrUndefined(
      data?.min_selected_painpoints_for_ai
    ),
    max_selected_painpoints_for_ai: numberOrUndefined(
      data?.max_selected_painpoints_for_ai
    ),
    min_selected_benchmarks_for_ai: numberOrUndefined(
      data?.min_selected_benchmarks_for_ai
    ),
    max_selected_benchmarks_for_ai: numberOrUndefined(
      data?.max_selected_benchmarks_for_ai
    ),
    language: ProjectLanguageTypeEnum.is(data.language)
      ? data.language
      : undefined
  });
};

export const FluidProjectModel = types
  .model('FluidProjectModel', {
    id: types.number,
    organization_id: types.maybe(types.number),
    organization: types.maybe(
      types.late((): IAnyModelType => FluidOrganizationModel)
    ),
    topic: types.maybe(types.string),
    created_at: types.maybe(types.string),
    painpoints_state: types.maybe(ModuleStateEnumModel),
    benchmarks_state: types.maybe(ModuleStateEnumModel),
    hypotheses_state: types.maybe(ModuleStateEnumModel),
    prototypes_state: types.maybe(ModuleStateEnumModel),
    learnings_state: types.maybe(ModuleStateEnumModel),
    network_project_state: types.maybe(ModuleStateEnumModel),
    network_painpoints_state: types.maybe(ModuleStateEnumModel),
    network_benchmarks_state: types.maybe(ModuleStateEnumModel),
    network_hypotheses_state: types.maybe(ModuleStateEnumModel),
    network_prototypes_state: types.maybe(ModuleStateEnumModel),
    network_learnings_state: types.maybe(ModuleStateEnumModel),
    painpoints_count: types.maybe(types.number),
    benchmarks_count: types.maybe(types.number),
    hypotheses_count: types.maybe(types.number),
    prototypes_count: types.maybe(types.number),
    learnings_count: types.maybe(types.number),
    access_level: types.maybe(ProjectAccessLevelEnumModel),
    briefing: types.maybe(types.late((): IAnyModelType => FluidBriefingModel)),
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    assistant_invites: types.maybe(
      types.model({
        total_count: types.maybe(types.number),
        published_count: types.maybe(types.number)
      })
    ),
    ai_at_work: types.maybe(types.boolean),
    gpt_strategy_type: types.maybe(types.string),
    min_selected_painpoints_for_ai: types.maybe(types.number),
    max_selected_painpoints_for_ai: types.maybe(types.number),
    min_selected_benchmarks_for_ai: types.maybe(types.number),
    max_selected_benchmarks_for_ai: types.maybe(types.number),
    language: types.maybe(ProjectLanguageTypeEnum)
  })
  .actions((self) => ({
    setBriefing(briefing: BriefingModelType | FluidBriefingModelType) {
      self.briefing = briefing;
    },
    incrementBenchmarkCount() {
      if (self.benchmarks_count != null) {
        self.benchmarks_count += 1;
      } else {
        self.benchmarks_count = 1;
      }
    },
    decrementBenchmarkCount() {
      if (self.benchmarks_count != null && self.benchmarks_count > 0) {
        self.benchmarks_count -= 1;
      }
    },
    setBenchmarkCount(count: number) {
      self.benchmarks_count = count;
    },
    incrementPainpointCount() {
      if (self.painpoints_count != null) {
        self.painpoints_count += 1;
      } else {
        self.painpoints_count = 1;
      }
    },
    decrementPainpointCount() {
      if (self.painpoints_count != null && self.painpoints_count > 0) {
        self.painpoints_count -= 1;
      }
    },
    setPainpointCount(count: number) {
      self.painpoints_count = count;
    }
  }))
  .views((self) => ({
    get aiSelectLimits(): {
      minPainpoints: number;
      maxPainpoints: number;
      minBenchmarks: number;
      maxBenchmarks: number;
    } {
      return {
        minPainpoints: self.min_selected_painpoints_for_ai || 2,
        maxPainpoints: self.max_selected_painpoints_for_ai || 5,
        minBenchmarks: self.min_selected_benchmarks_for_ai || 2,
        maxBenchmarks: self.max_selected_benchmarks_for_ai || 5
      };
    }
  }));

export const createFluidProjectModel = (data?: any): FluidProjectModelType => {
  return FluidProjectModel.create({
    id: mandatoryId(data?.id),
    organization_id: numberOrUndefined(data?.organization_id),
    organization: data?.organization
      ? createFluidOrganizationModel(data.organization)
      : undefined,
    topic: data?.topic || undefined,
    created_at: data?.created_at || undefined,
    painpoints_state: createModuleStateEnumModel(data?.painpoints_state),
    benchmarks_state: createModuleStateEnumModel(data?.benchmarks_state),
    hypotheses_state: createModuleStateEnumModel(data?.hypotheses_state),
    prototypes_state: createModuleStateEnumModel(data?.prototypes_state),
    learnings_state: createModuleStateEnumModel(data?.learnings_state),
    network_project_state: createModuleStateEnumModel(
      data?.network_project_state
    ),
    network_painpoints_state: createModuleStateEnumModel(
      data?.network_painpoints_state
    ),
    network_benchmarks_state: createModuleStateEnumModel(
      data?.network_benchmarks_state
    ),
    network_hypotheses_state: createModuleStateEnumModel(
      data?.network_hypotheses_state
    ),
    network_prototypes_state: createModuleStateEnumModel(
      data?.network_prototypes_state
    ),
    network_learnings_state: createModuleStateEnumModel(
      data?.network_learnings_state
    ),
    painpoints_count: numberOrUndefined(data?.painpoints_count),
    benchmarks_count: numberOrUndefined(data?.benchmarks_count),
    hypotheses_count: numberOrUndefined(data?.hypotheses_count),
    prototypes_count: numberOrUndefined(data?.prototypes_count),
    learnings_count: numberOrUndefined(data?.learnings_count),
    access_level: createProjectAccessLevelEnumModel(data?.access_level),
    briefing: data?.briefing
      ? createFluidBriefingModel(data?.briefing)
      : undefined,
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    assistant_invites: data.assistant_invites
      ? {
          total_count: numberOrUndefined(data.assistant_invites.total_count),
          published_count: numberOrUndefined(
            data.assistant_invites.published_count
          )
        }
      : undefined,
    gpt_strategy_type: data?.gpt_strategy_type || undefined,
    min_selected_painpoints_for_ai: numberOrUndefined(
      data?.min_selected_painpoints_for_ai
    ),
    max_selected_painpoints_for_ai: numberOrUndefined(
      data?.max_selected_painpoints_for_ai
    ),
    min_selected_benchmarks_for_ai: numberOrUndefined(
      data?.min_selected_benchmarks_for_ai
    ),
    max_selected_benchmarks_for_ai: numberOrUndefined(
      data?.max_selected_benchmarks_for_ai
    ),
    language: ProjectLanguageTypeEnum.is(data.language)
      ? data.language
      : undefined
  });
};

export type FluidProjectModelType = typeof FluidProjectModel.Type;

export type ProjectModelType = typeof ProjectModel.Type;
export default ProjectModel;
