import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import {
  SessionFixed,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import BenchmarksListItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksListItem';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface AssistantBenchmarkListScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
}

@inject('applicationStore', 'assistantStore')
@observer
class AssistantBenchmarkListScreen extends React.Component<
  AssistantBenchmarkListScreenProps & HistoryProps
> {
  componentDidMount() {
    scrollSidebar('benchmarks');
  }

  private async continueSession() {
    const { assistantStore } = this.props;

    const { currentId, currentAssistantType } = assistantStore!;
    if (!currentId || !currentAssistantType) {
      return;
    }

    await assistantStore!.updateAssistantSessionProgess(
      currentId,
      ASSISTANTS.benchmarks_sprint.step1.progress
    );

    goToNextStep(
      currentId,
      ASSISTANTS.benchmarks_sprint.step1.progress,
      currentAssistantType
    );
  }

  private quit() {
    this.props.history.push(ROUTE_ASSISTANT);
  }

  render() {
    const { location } = this.props;
    const { currentItem, currentAssistantType, isItemSaving } =
      this.props.assistantStore;

    if (!currentItem) {
      return null;
    }

    const list = currentItem.sortedBenchmarks;

    const stepInfo = getStepInfo('step1', currentAssistantType);

    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        // showHeader="benchmarks" // TODO ?
        location={location}
        sessionId={currentItem.id}
        bottomSpace={true}
        timerSeconds={600}
      >
        {isItemSaving && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="benchmarks assistant benchmarks header" />
          </SessionHeadline>

          <SessionMainText noMargin={true}>
            <FormattedMessage id="benchmarks assistant benchmarks" />
          </SessionMainText>

          <VideoPopupLink videoId="hCgMr0M9gGY" />
        </SessionTop>

        <ColumnWrapper gap="3em">
          {/* TODO create correct decoration */}
          {list.length > 0 && (
            <div className="decoration-benchmark-inspiration" />
          )}

          {list.map((elem) => (
            <BenchmarksListItem
              benchmark={elem}
              key={elem.id}
              fromList={false}
              noMenu={true}
              to={{
                // pathname: contextUri + elem.id,
                pathname: location.pathname,
                search: '?benchmark=' + elem.id,
                state: {
                  canGoBack: true
                }
              }}
            />
          ))}
        </ColumnWrapper>

        <SessionFixed>
          <MainButton
            secondary={true}
            type="button"
            onClick={() => this.quit()}
          >
            <FormattedMessage id="Save draft" />
          </MainButton>
          <MainButton onClick={() => this.continueSession()}>
            <FormattedMessage id="Next" />
          </MainButton>
        </SessionFixed>
      </StepContainer>
    );
  }
}

export default AssistantBenchmarkListScreen;
