// https://stackoverflow.com/a/40808569
export const uniqBy = <T extends any>(
  arr: T[],
  predicate: string | ((o: T) => any)
): T[] => {
  const cb =
    typeof predicate === 'function'
      ? predicate
      : (o: T) => (o as { [key: string]: any })[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values()
  ];
};

export default uniqBy;
