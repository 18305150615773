export const ROUTE_HOME = '/';

export const ROUTE_SHARED_BASE = '/shared';
export const ROUTE_SHARED = ROUTE_SHARED_BASE + '/:token';

export const ROUTE_AUTH = '/app';
export const ROUTE_PERFORM_LOGOUT = '/app/logout';

export const ROUTE_DASHBOARD = ROUTE_AUTH;

export const ROUTE_LAB = ROUTE_AUTH + '/lab';
export const ROUTE_ASSISTANT = ROUTE_AUTH + '/assistant';
export const ROUTE_ASSISTANT_BENCHMARK_SESSION =
  ROUTE_ASSISTANT + '/benchmarks/:id';
export const ROUTE_ASSISTANT_PROTOTYP_SESSION =
  ROUTE_ASSISTANT + '/prototypes/:id';
export const ROUTE_ASSISTANT_SESSION = ROUTE_ASSISTANT + '/:id';
export const ROUTE_ORG = ROUTE_LAB + '/:orgId';
export const ROUTE_ORG_SETTINGS = ROUTE_ORG + '/settings';
export const ROUTE_ORG_MEMBERS = ROUTE_ORG_SETTINGS + '/members';
export const ROUTE_ORG_TARGET_GROUPS = ROUTE_ORG_SETTINGS + '/target-groups';
export const ROUTE_ORG_SERVICES = ROUTE_ORG_SETTINGS + '/services';
export const ROUTE_PROJECT = ROUTE_ORG + '/:projectId';

export const ROUTE_CUSTOMERS_BASE = ROUTE_AUTH + '/customers';
export const ROUTE_CUSTOMERS_AGENCY = ROUTE_CUSTOMERS_BASE + '/:agencyId';

export const ROUTE_BRIEFING = ROUTE_PROJECT;
export const ROUTE_PAINPOINTS = ROUTE_PROJECT + '/painpoints';
export const ROUTE_BENCHMARKS = ROUTE_PROJECT + '/benchmarks';
export const ROUTE_HYPOTHESES = ROUTE_PROJECT + '/hypotheses';
export const ROUTE_PROTOTYPES = ROUTE_PROJECT + '/prototypes';
export const ROUTE_LEARNINGS = ROUTE_PROJECT + '/learnings';
export const ROUTE_INVITATIONS = ROUTE_PROJECT + '/invitations';
export const ROUTE_PROJECT_SETTINGS = ROUTE_PROJECT + '/settings';
export const ROUTE_PROJECT_MEMBERS = ROUTE_PROJECT + '/members';

export const ROUTE_NETWORK = ROUTE_AUTH + '/network';

export const ROUTE_SETTINGS = ROUTE_AUTH + '/settings';

export const ROUTE_SEARCH = ROUTE_AUTH + '/search';
// export const ROUTE_OWN_USER_PROFILE = ROUTE_AUTH + '/profile/me/';
export const ROUTE_PROFILE = ROUTE_AUTH + '/profile/:id';
export const ROUTE_USER_PROFILE_OVERVIEW = ROUTE_PROFILE + '/';

export const ROUTE_USER_PROFILE_BOOKMARKS = ROUTE_PROFILE + '/bookmarks';
export const ROUTE_USER_PROFILE_DRAFTS = ROUTE_PROFILE + '/drafts';
export const ROUTE_USER_PROFILE_SETTINGS = ROUTE_PROFILE + '/settings';

export const ROUTE_USER_PROFILE_BRIEFINGS = ROUTE_PROFILE + '/briefings';
export const ROUTE_USER_PROFILE_BENCHMARKS = ROUTE_PROFILE + '/benchmarks';
export const ROUTE_USER_PROFILE_PAINPOINTS = ROUTE_PROFILE + '/painpoints';
export const ROUTE_USER_PROFILE_HYPOTHESES = ROUTE_PROFILE + '/hypotheses';
export const ROUTE_USER_PROFILE_PROTOTYPES = ROUTE_PROFILE + '/prototypes';
export const ROUTE_USER_PROFILE_LEARNINGS = ROUTE_PROFILE + '/learnings';

export const ROUTE_LOGIN_PAGE = ROUTE_HOME;
export const ROUTE_LOGIN = ROUTE_LOGIN_PAGE + '?auth=unauthorized';
export const ROUTE_LOGIN_FAILED = ROUTE_LOGIN_PAGE + '?auth=failed';
export const ROUTE_LOGOUT = ROUTE_LOGIN_PAGE + '?auth=logout';
export const ROUTE_AUTH_ERROR = ROUTE_LOGIN_PAGE + '?auth=error';
export const ROUTE_LOGOUT_ERROR = ROUTE_AUTH + '?error=logout';
export const ROUTE_UNCONFIRMED = ROUTE_LOGIN_PAGE + '?auth=unconfirmed';

export const ROUTE_FAQ_PAGES = ROUTE_AUTH + '/faq-pages';
