import classNames from 'classnames';

interface RowWrapperProps {
  children: React.ReactNode;
  gap?: string;
  alignRight?: boolean;
}

export default function RowWrapper({
  children,
  gap,
  alignRight
}: RowWrapperProps) {
  const style = { '--row-gap': gap } as React.CSSProperties;

  const rowWrapperClasses = classNames('l-row-wrapper', {
    'l-row-wrapper--alignRight': alignRight
  });

  return (
    <div>
      <div className={rowWrapperClasses} style={style}>
        {children}
      </div>
    </div>
  );
}
