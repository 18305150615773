import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import SimplePopup from 'components/SimplePopup';
import { inject, observer } from 'mobx-react';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { OrgServiceModelType } from 'models/OrgServiceModel';
import { useEffect } from 'react';
import { WrappedComponentProps, useIntl } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface OrgServiceItemEditPopupProps {
  service: OrgServiceModelType;
  onEditDone: () => void;
  isAdding?: boolean;
}

interface OrgServiceItemEditWrappedPopupProps
  extends OrgServiceItemEditPopupProps,
    WrappedComponentProps {
  form?: FormType;
  service: OrgServiceModelType;
  organizationsStore?: OrganizationsStoreType;
}

function OrgServiceItemEditPopup({
  service,
  form,
  organizationsStore,
  onEditDone,
  isAdding
}: OrgServiceItemEditWrappedPopupProps) {
  const intl = useIntl();
  useEffect(() => {
    form!.reset();
    form!.setField('name', service!.name);
    form!.setField('content', service!.content);
  }, []);

  const save = async () => {
    if (!service) {
      return;
    }

    form!.setLoading(true);

    try {
      if (isAdding) {
        await organizationsStore!.createService(service.organization_id, {
          name: form!.values.name,
          content: form!.values.content
        });
      } else {
        await organizationsStore!.updateService({
          organizationId: service.organization_id,
          serviceId: service.id,
          patch: {
            name: form!.values.name,
            content: form!.values.content
          }
        });
      }

      onEditDone();
    } catch (e) {
      // TODO what to do here?
    }

    form!.setLoading(false);
  };

  return (
    <SimplePopup
      onSubmit={() => save()}
      onAbort={() => onEditDone()}
      isLoading={form!.loading}
      noPadding={true}
    >
      <Input
        type="text"
        name="name"
        normalFont={true}
        placeholder={intl.formatMessage({ id: 'Service Add Name' })}
        {...form!.bindInput('name')}
        disabled={form!.loading}
      />
      <div className="l-screen-item__description l-screen-item__description--auto">
        <Textarea
          name="content"
          normalFont={true}
          placeholder={intl.formatMessage({ id: 'Service Add Content' })}
          lines={8}
          {...form!.bindInput('content')}
        />
      </div>
    </SimplePopup>
  );
}

export default inject('organizationsStore')(
  observer((props: OrgServiceItemEditPopupProps) => {
    const form = useForm();
    // @ts-ignore
    return <OrgServiceItemEditPopup {...props} form={form} />;
  })
);
