import { useIntl } from 'react-intl';
import ActionOverlayButton from './ActionOverlayButton';
import { DataStoreType } from 'models/DataStore';
import { ActionsStoreType } from 'models/ActionsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { inject, observer } from 'mobx-react';

function HypothesisButton({
  dataStore,
  actionsStore,
  projectsStore,
  applicationStore
}: {
  dataStore?: DataStoreType;
  actionsStore?: ActionsStoreType;
  projectsStore?: ProjectsStoreType;
  applicationStore?: ApplicationStoreType;
}) {
  const intl = useIntl();
  let disabled = false;
  let intlId: string;
  let intlVars: { count: number; count2: number };

  const organizationId = dataStore!.currentOrganizationId!;
  const projectId = dataStore!.currentProjectId!;
  const painpointIds = actionsStore!.selectedIds.Painpoint;
  const benchmarkIds = actionsStore!.selectedIds.Benchmark;

  if (
    benchmarkIds.length <
      dataStore!.currentProject?.aiSelectLimits.minBenchmarks! ||
    painpointIds.length <
      dataStore!.currentProject?.aiSelectLimits.minPainpoints!
  ) {
    disabled = true;
    intlVars = {
      count: dataStore!.currentProject?.aiSelectLimits.minBenchmarks!,
      count2: dataStore!.currentProject?.aiSelectLimits.minPainpoints!
    };
    intlId = 'create hypotheses not enough benchmarks';
  } else if (
    benchmarkIds.length >
      dataStore!.currentProject?.aiSelectLimits.maxBenchmarks! ||
    painpointIds.length >
      dataStore!.currentProject?.aiSelectLimits.maxPainpoints!
  ) {
    disabled = true;
    intlVars = {
      count: dataStore!.currentProject?.aiSelectLimits.maxBenchmarks!,
      count2: dataStore!.currentProject?.aiSelectLimits.maxPainpoints!
    };
    intlId = 'create hypotheses too many benchmarks';
  }

  return (
    <ActionOverlayButton
      label={intl.formatMessage({ id: 'Create hypotheses' })}
      iconName="ai"
      disabled={dataStore?.project?.reachedHypothesisLimit}
      onClick={async () => {
        if (!dataStore?.project?.briefing.completed) {
          applicationStore!.setAiUnavailable(true);
          return;
        }
        if (disabled) {
          applicationStore!.setFlashMessage(
            intl.formatMessage(
              {
                id: intlId
              },
              intlVars
            ),
            'inlineError'
          );
          return;
        }

        actionsStore!.selectionClear();
        try {
          const response = await projectsStore!.generateHypothesesByAi(
            organizationId,
            projectId,
            painpointIds,
            benchmarkIds
          );
          if (!response.success) {
            applicationStore!.setFlashMessage(
              intl.formatMessage({ id: 'AI create error' }),
              'error'
            );
          }
        } catch (error: any) {
          applicationStore!.setFlashMessage(
            intl.formatMessage({
              id: 'hypotheses search failed error'
            }),
            'error'
          );
        }
      }}
    />
  );
}

export default inject(
  'dataStore',
  'actionsStore',
  'projectsStore',
  'applicationStore'
)(observer(HypothesisButton));
