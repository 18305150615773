import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import UserAvatar from 'components/Avatar/UserAvatar';
import UserName from 'components/Avatar/UserName';
import CommentPopup from 'components/CommentPopup';
import Form, { FormProps } from 'components/Form/Form';
import Textarea from 'components/Inputs/Textarea';
import MainButton from 'components/MainButton';
import RatingSlider from 'components/RatingSlider';
import { AnyUserModelType } from 'models/UserModel';
import { FormType } from 'utils/hooks/useForm';

interface CommentFormProps {
  form: FormType;
  onSubmit?: FormProps['onSubmit'];
  onClose?: () => void;
  withRatings?: boolean;
  editMode?: boolean;
  user?: AnyUserModelType;
  loading?: boolean;
}

export default injectIntl(
  ({
    withRatings,
    editMode,
    loading,
    form,
    user,
    onSubmit,
    onClose,
    intl
  }: CommentFormProps & WrappedComponentProps) => (
    <Form onSubmit={onSubmit} loading={loading}>
      <CommentPopup
        title={
          // tslint:disable-next-line: jsx-wrap-multiline
          <FormattedMessage
            id={
              editMode
                ? 'Save changes'
                : withRatings
                ? 'Valuate now'
                : 'Comment now'
            }
          />
        }
        onClose={onClose}
        buttons={
          // tslint:disable-next-line: jsx-wrap-multiline
          <MainButton type="submit">
            <FormattedMessage
              id={
                editMode
                  ? 'Save changes'
                  : withRatings
                  ? 'Valuate now'
                  : 'Comment now'
              }
            />
          </MainButton>
        }
      >
        <CommentPopup.User>
          <UserAvatar user={user} noName={true} />
        </CommentPopup.User>

        <CommentPopup.Content>
          <CommentPopup.Header>
            <UserName user={user} />
          </CommentPopup.Header>

          <CommentPopup.Grid>
            {withRatings ? (
              <>
                <CommentPopup.Ratings>
                  <RatingSlider
                    name="target_group_relevance"
                    label={intl.formatMessage({
                      id: 'Traget group relevance'
                    })}
                    {...form.bindInput('target_group_relevance')}
                  />
                  <RatingSlider
                    name="revenue_potential"
                    label={intl.formatMessage({ id: 'Revenue potential' })}
                    {...form.bindInput('revenue_potential')}
                  />
                  <RatingSlider
                    name="cost_efficiency"
                    label={intl.formatMessage({ id: 'Cost efficiency' })}
                    {...form.bindInput('cost_efficiency')}
                  />
                  <RatingSlider
                    name="differentiation_degree"
                    label={intl.formatMessage({
                      id: 'Differentiation degree'
                    })}
                    {...form.bindInput('differentiation_degree')}
                  />
                </CommentPopup.Ratings>

                <Textarea
                  name="text"
                  placeholder={intl.formatMessage({ id: 'Enter valuation' })}
                  {...form.bindInput('text')}
                />
              </>
            ) : (
              <Textarea
                name="text"
                placeholder={intl.formatMessage({ id: 'Enter comment' })}
                {...form.bindInput('text')}
              />
            )}
          </CommentPopup.Grid>
        </CommentPopup.Content>
      </CommentPopup>
    </Form>
  )
);
