export default (tag: any, withHash: boolean = true): string => {
  if (!tag) {
    return '';
  }

  // TODO use unicode ranges here?
  const sanitized: string = tag
    .toString()
    .toLowerCase()
    .replace(/[^a-z0-9äöüß\s]/gi, '');

  if (!sanitized.length) {
    return '';
  }

  if (withHash) {
    return '#' + sanitized;
  }

  return sanitized;
};
