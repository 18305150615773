import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_PROTOTYPES } from 'utils/constants/routes';
import PrototypesCreateScreen from './PrototypesCreateScreen';
import PrototypesDetailScreen from './PrototypesDetailScreen';
import PrototypesEditScreen from './PrototypesEditScreen';
import PrototypesListScreen from './PrototypesListScreen';
import PrototypesRecommendationsScreen from './PrototypesRecommendationsScreen';

export default () => (
  <ContextAwareProjectLayout active="prototypes">
    <Switch>
      <Route
        path={ROUTE_PROTOTYPES}
        exact={true}
        component={PrototypesListScreen}
      />
      <Route
        path={ROUTE_PROTOTYPES + '/create'}
        exact={true}
        component={PrototypesCreateScreen}
      />
      <Route
        path={ROUTE_PROTOTYPES + '/inspiration'}
        exact={true}
        component={PrototypesRecommendationsScreen}
      />
      <Route
        path={ROUTE_PROTOTYPES + '/:id'}
        exact={true}
        component={PrototypesDetailScreen}
      />
      <Route
        path={ROUTE_PROTOTYPES + '/:id/edit'}
        exact={true}
        component={PrototypesEditScreen}
      />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
