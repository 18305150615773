import classNames from 'classnames';

// @ts-ignore
import onClickOutside from 'react-onclickoutside';

import EditButton from 'components/EditButton';
import PopupMenu from 'components/PopupMenu';
import { Component } from 'react';

interface EditMenuProps {
  children: React.ReactNode;
  label: string;
  bottomLeft?: boolean;
  topLeft?: boolean;
  topRight?: boolean;
  onShow?: () => void;
  onHide?: () => void;
}

class EditMenu extends Component<EditMenuProps> {
  state: any = {};

  handleClickOutside = (event: React.MouseEvent) => {
    this.setState({
      visible: false
    });

    this.props.onHide && this.props.onHide();
  };

  toggle(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();

    const { onShow, onHide } = this.props;

    const visible = !this.state.visible ? true : false;
    this.setState({
      visible
    });

    visible ? onShow && onShow() : onHide && onHide();
  }

  hide() {
    this.setState({
      visible: false
    });
  }

  render() {
    const { props } = this;

    const EditMenuClasses = classNames('edit-menu', {
      'edit-menu--bottomLeft': props.bottomLeft,
      'edit-menu--topLeft': props.topLeft,
      'edit-menu--topRight': props.topRight
    });

    return (
      <div className={EditMenuClasses}>
        <EditButton
          label={props.label}
          iconName="dots"
          onClick={(event) => this.toggle(event)}
        />
        <div
          className="edit-menu__menu"
          hidden={!this.state.visible}
          onClick={() => this.hide()}
        >
          <PopupMenu
            bottomLeft={props.bottomLeft}
            topLeft={props.topLeft}
            topRight={props.topRight}
          >
            {props.children}
          </PopupMenu>
        </div>
      </div>
    );
  }
}

export default onClickOutside(EditMenu);
