import classNames from 'classnames';
import CardWrapper from 'components/CardWrapper';
import { OrgTargetGroupModelType } from 'models/OrgTargetGroupModel';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import OrgTargetGroupItemEditPopup from './OrgTargetGroupItemEditPopup';
import EditMenu from 'components/EditMenu';
import MenuButton from 'components/MenuButton';
import { inject } from 'mobx-react';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { DataStoreType } from 'models/DataStore';
import Icon from 'components/Icon';

interface OrgTargetGroupItemProps {
  targetGroup: OrgTargetGroupModelType;
  organizationsStore?: OrganizationsStoreType;
  dataStore?: DataStoreType;
}

function OrgTargetGroupItem({
  targetGroup,
  organizationsStore,
  dataStore
}: OrgTargetGroupItemProps) {
  const intl = useIntl();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <CardWrapper
        onClick={() => {
          !isDeleting && setIsEditing(true);
        }}
      >
        <h5
          className={classNames({
            'l-row-wrapper': true,
            'l-row-wrapper--spaceBetween': true,
            'l-screen-item__headline--placeholder': !targetGroup.name
          })}
        >
          {targetGroup.name ||
            intl.formatMessage({ id: 'Target Group Add Name' })}
          <Icon name="pen" size="large" />
        </h5>
        <span
          className={classNames({
            'l-screen-item__description': true,
            'l-screen-item__description--placeholder': !targetGroup.content,
            'l-screen-item__description--evenly': true
          })}
        >
          {targetGroup.content ||
            intl.formatMessage({ id: 'Target Group Add Content' })}
        </span>
        <div
          className="l-row-wrapper l-row-wrapper--spaceBetween"
          style={{
            position: 'absolute',
            bottom: '1rem',
            left: '0',
            right: '1.5em'
          }}
        >
          <div />
          <EditMenu
            label={intl.formatMessage({ id: 'Edit' })}
            onShow={() => setIsDeleting(true)}
            onHide={() => setIsDeleting(false)}
          >
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              iconName="bin"
              onClick={async () => {
                if (
                  window.confirm(
                    intl.formatMessage({ id: 'Delete Target Group' })
                  )
                ) {
                  await organizationsStore!.deleteTargetGroup(
                    dataStore!.currentOrganization!.id,
                    targetGroup.id
                  );
                }
              }}
            />
          </EditMenu>
        </div>
      </CardWrapper>
      {isEditing && (
        <OrgTargetGroupItemEditPopup
          onEditDone={() => setIsEditing(false)}
          targetGroup={targetGroup}
        />
      )}
    </>
  );
}

export default inject('organizationsStore', 'dataStore')(OrgTargetGroupItem);
