
import classNames from 'classnames';

interface MainNavAvatarProps {
  active?: boolean;
  children?: React.ReactChild;
}

export default function MainNavAvatar({
  active,
  children
}: MainNavAvatarProps) {
  const mainNavItemClasses = classNames('main-nav__avatar', {
    'main-nav__avatar--active': active
  });

  return <li className={mainNavItemClasses}>{children}</li>;
}
