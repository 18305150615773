import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';

import CallToAction from 'components/CallToAction';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import ProjectWrapper from 'components/Layout/ProjectWrapper';
import { WrappedComponentProps, useIntl } from 'react-intl';
import { DataStoreType } from 'models/DataStore';
import { HistoryProps } from 'utils/history';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import ErrorMessage from 'components/ErrorMessage';
import { NetworkAwareAppLayout } from 'components/AppLayout/NetworkAwareAppLayout';
import PageLogoHeader from 'components/PageLogoHeader';
import CompanyLogo from 'components/CompanyLogo';
import MainButton from 'components/MainButton';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import Icon from 'components/Icon';
import { OrganizationModelType } from 'models/OrganizationModel';
import OrganizationsTable from 'components/OrganizationsTableComponent/OrganizationsTableComponent';
import classNames from 'classnames';

interface CustomersListScreenProps extends HistoryProps, WrappedComponentProps {
  dataStore: DataStoreType;
  organizationsStore: OrganizationsStoreType;
}

function CustomersListScreen({
  dataStore,
  organizationsStore,
  history
}: CustomersListScreenProps & HistoryProps) {
  const [customers, setCustomers] = useState<OrganizationModelType[]>([]);
  const [filter, setFilter] = useState<string>('');
  const intl = useIntl();

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = async () => {
    await organizationsStore.getCustomers();
    setCustomers(
      Array.from(dataStore.customers.values()).sort((a, b) =>
        a.name!.localeCompare(b.name!)
      )
    );
  };

  const header = useMemo(() => {
    const { currentOrganization } = dataStore;
    if (currentOrganization) {
      const logo = currentOrganization.attachments.firstOfType('logo');
      return (
        <PageLogoHeader
          big={true}
          title={currentOrganization.name}
          subtitle={currentOrganization.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }
  }, [dataStore.currentOrganization]);

  return (
    <NetworkAwareAppLayout
      active="customers"
      reduced={true}
      org={dataStore.currentOrganization}
      withSettings={true}
    >
      <PageHeader
        reduced={true}
        titleId="Our projects(titleId)"
        logoHeader={header}
      />
      {!dataStore.currentOrganization ? (
        <ErrorMessage state="load_error" onRetry={() => loadCustomers()} />
      ) : (
        <>
          <ProjectWrapper>
            <ContentWrapper>
              <form
                className={classNames({
                  'search-form search-screen__search-form': true,
                  'search-form__search-bar': true
                })}
                onChange={(e) =>
                  setFilter((e.target as HTMLInputElement).value)
                }
              >
                <div className="search-form__fieldset">
                  <span className="search-form__input-icon">
                    <Icon name="magnify" />
                  </span>
                  <input
                    className={'search-form__input'}
                    type="text"
                    name="filter"
                    placeholder={intl.formatMessage({ id: 'Customer search' })}
                  />
                </div>
              </form>
              {customers.length > 5 && (
                <PositionWrapper center={true}>
                  <MainButton
                    onClick={() =>
                      history.push(
                        '/app/customers/' +
                          dataStore.currentOrganization!.id! +
                          '/create'
                      )
                    }
                  >
                    Neuer Kunde
                  </MainButton>
                </PositionWrapper>
              )}
              <OrganizationsTable
                organizations={customers}
                searchTerm={filter}
                onDeleted={() => loadCustomers()}
                history={history}
                showMenu={true}
              />
            </ContentWrapper>
            <PositionWrapper center={true}>
              <MainButton
                onClick={() =>
                  history.push(
                    '/app/customers/' +
                      dataStore.currentOrganization!.id! +
                      '/create'
                  )
                }
              >
                Neuer Kunde
              </MainButton>
            </PositionWrapper>
          </ProjectWrapper>
          <CallToAction />
        </>
      )}
    </NetworkAwareAppLayout>
  );
}

export default inject(
  'dataStore',
  'organizationsStore'
)(observer(CustomersListScreen));
