import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';

import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import CompanyLogo from 'components/CompanyLogo';
import ContentWrapper from 'components/Layout/ContentWrapper';
import ObjectWrapper from 'components/Layout/ObjectWrapper';
import Loading from 'components/Loading';
import NetworkCompanySlider from 'components/NetworkCompanySlider';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import SearchForm from 'components/SearchForm';
import ListDetailContainer from 'containers/list/ListDetailContainer';
import ListOverviewContainer from 'containers/list/ListOverviewContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import {
  ContentListStoreType,
  SearchableElementType
} from 'models/ContentListStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicNetworkScreenProps extends WrappedComponentProps {
  form: FormType;
}

interface NetworkScreenProps extends PublicNetworkScreenProps, HistoryProps {
  contentListStore: ContentListStoreType;
  organizationsStore: OrganizationsStoreType;
}

@inject('contentListStore', 'organizationsStore')
@observer
class NetworkScreen extends React.Component<NetworkScreenProps> {
  componentDidMount() {
    this.checkLoad();
    this.checkLoadOrgs();
  }

  componentDidUpdate(prevProps: NetworkScreenProps) {
    if (this.getElementType() !== this.getElementType(prevProps)) {
      this.checkLoad();
    }
  }

  getElementType(
    props?: NetworkScreenProps
  ): SearchableElementType | undefined {
    if (!props) {
      props = this.props;
    }

    const type = props.location?.query?.type;
    if (!type) {
      return undefined;
    }

    switch (type) {
      case 'briefing':
        return ElementType.Briefing;

      case 'painpoint':
        return ElementType.Painpoint;

      case 'benchmark':
        return ElementType.Benchmark;

      case 'hypothesis':
        return ElementType.Hypothesis;

      case 'prototype':
        return ElementType.Prototype;

      case 'learning':
        return ElementType.Learning;

      default:
        return undefined;
    }
  }

  pluralizeType(type?: SearchableElementType): string | undefined {
    switch (type) {
      case 'Briefing':
        return 'Briefings';

      case 'Painpoint':
        return 'Painpoints';

      case 'Benchmark':
        return 'Benchmarks';

      case 'Hypothesis':
        return 'Hypotheses';

      case 'Prototype':
        return 'Prototypes';

      case 'Learning':
        return 'Learnings';

      default:
        return undefined;
    }
  }

  checkLoad() {
    const elementType = this.getElementType();

    if (
      isPush(this.props.history) ||
      !this.props.contentListStore.isCurrentList(
        'network_recent',
        elementType || 'all'
      )
    ) {
      this.load();
    }
  }

  checkLoadOrgs() {
    const { organizationsStore } = this.props;

    if (!organizationsStore.hasNetworkOrgs || isPush(this.props.history)) {
      organizationsStore.getNetworkOrganizations();
    }
  }

  load(page?: number) {
    const { contentListStore } = this.props;

    contentListStore.getRecentNetworkElements(this.getElementType(), page);
  }

  loadMore() {
    const elementType = this.getElementType();
    if (!elementType) {
      return;
    }

    const { contentListStore } = this.props;

    this.load(contentListStore.pageOf(elementType) + 1);
  }

  renderPage(content: any) {
    return <AppLayoutReduced active="network">{content}</AppLayoutReduced>;
  }

  renderOrgs() {
    const { organizationsStore } = this.props;

    if (!organizationsStore.hasNetworkOrgs) {
      return null;
    }

    return (
      <NetworkCompanySlider
        large={true}
        spacing={true}
        headline={<FormattedMessage id="network companies header" />}
        entries={organizationsStore.sortedNetworkOrgs.map((org) => {
          const logo = org.attachments.firstOfType('logo');

          return {
            title: org.name,
            subtitle: org.sector || <>&nbsp;</>,
            linkTo: ROUTE_LAB + '/' + org.id,
            logo: logo?.resource_urls ? (
              <CompanyLogo imgUrl={logo.resource_urls.small} alt="" />
            ) : undefined
          };
        })}
      />
    );
  }

  renderOverview() {
    if (
      this.props.contentListStore.isListLoading ||
      this.props.organizationsStore.isNetworkLoading
    ) {
      return this.renderLoading();
    }

    const baseUrl = '/app/network';

    return this.renderPage(
      <>
        <PageHeader
          titleId="Network"
          center={false}
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              big={true}
              title={<FormattedMessage id="network header" />}
              subtitle={<FormattedMessage id="network subheader" />}
            />
          }
        />

        <ContentWrapper big={true}>
          <SearchForm
            hideProject={true}
            placeholder={this.props.intl.formatMessage({ id: 'Search it' })}
          />
          <ObjectWrapper>
            {this.renderOrgs()}

            <ListOverviewContainer
              baseUrl={baseUrl}
              limit={3}
              largeSlider={true}
              headerType="network"
              onRetry={() => this.load()}
            />
          </ObjectWrapper>
          <CallToAction />
        </ContentWrapper>
      </>
    );
  }

  renderDetail() {
    const elementType = this.getElementType();
    if (!elementType) {
      return null;
    }

    const { contentListStore } = this.props;

    let loadingMore = false;
    if (contentListStore.isListLoading) {
      if (contentListStore.hasAnyOf(elementType)) {
        loadingMore = true;
      } else {
        return this.renderLoading();
      }
    }

    return this.renderPage(
      <>
        <PageHeader
          titleId="Network"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={
                // tslint:disable-next-line: jsx-wrap-multiline
                <FormattedMessage
                  id={this.pluralizeType(elementType) + ' network'}
                />
              }
            />
          }
        />

        <ContentWrapper>
          <ListDetailContainer
            elementType={elementType}
            loadingMore={loadingMore}
            onLoadMore={() => this.loadMore()}
            noHeader={true}
            reduced={true}
            onRetry={() => this.load()}
          />
          <CallToAction />
        </ContentWrapper>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  render() {
    if (this.getElementType()) {
      // detail page
      return this.renderDetail();
    }

    // overview page
    return this.renderOverview();
  }
}

export default injectIntl((props: PublicNetworkScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <NetworkScreen {...props} form={form} />;
});
