import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { SessionMainText } from 'domain/assistant/session-elements';
import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS, getStepInfo } from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';
import SingleInputScreen from '../SingleInputScreen';

export const QuestionScreen = inject('assistantStore')(
  observer(
    ({
      assistantStore,
      location
    }: HistoryProps & { assistantStore: AssistantStoreType }) => {
      const stepInfo = getStepInfo(
        'step1_4',
        assistantStore.currentAssistantType
      );

      return (
        <SingleInputScreen
          progress={
            stepInfo?.progress || ASSISTANTS.creative_session.step1_4.progress
          }
          barProgress={stepInfo?.percent}
          sidebarHeadline={stepInfo?.header}
          nextRoute={
            assistantStore.currentAssistantType !== 'painpoint_session'
              ? '/session1/done'
              : undefined
          }
          videoId="hCgMr0M9gGY"
          fieldName="painpoint_question"
          dateFieldName="painpoint_question_date"
          header={<FormattedMessage id="assistant question header" />}
          headerStep={stepInfo?.header}
          placeholderId="assistant question placeholder"
          predefinedText={<FormattedMessage id="painpoint question prefix" />}
          largeTextareaFont={true}
          sidebarScrollTo="painpoint_question"
          showHeader="painpoint_question"
          location={location}
          timerSeconds={120}
        >
          <SessionMainText noMargin={true}>
            <FormattedMessage id="assistant question" />
          </SessionMainText>
        </SingleInputScreen>
      );
    }
  )
);

export default QuestionScreen;
