import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MainNavItem from 'components/MainNavItem';
import { DataStoreType } from 'models/DataStore';
import { ROUTE_LAB } from 'utils/constants/routes';

interface SettingsNavItemProps {
  dataStore?: DataStoreType;
  active?: boolean;
  displayWithoutOnlyOrg?: boolean;
}

export default inject('dataStore')(
  observer(
    ({ dataStore, active, displayWithoutOnlyOrg }: SettingsNavItemProps) => {
      let orgId;

      const onlyOrg = dataStore!.onlyOwnOrg;
      if (
        onlyOrg &&
        (onlyOrg.access_level === 'admin' ||
          onlyOrg.access_level === 'superadmin')
      ) {
        orgId = onlyOrg.id;
      }

      if (!orgId && displayWithoutOnlyOrg) {
        // inside of org context - this is required for superadmins
        if (dataStore!.isOrgAdmin) {
          orgId = dataStore!.currentOrganizationId;
        }
      }

      if (!orgId) {
        return null;
      }

      return (
        <MainNavItem
          label={<FormattedMessage id="Account" />}
          iconName="cogwheel"
          active={active}
          to={ROUTE_LAB + '/' + orgId + '/settings'}
        />
      );
    }
  )
);
