import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import CardWrapper from 'components/CardWrapper';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import MainButton from 'components/MainButton';
import { ROUTE_LAB } from 'utils/constants/routes';

export default withRouter(
  ({
    history,
    organizationId,
    projectId,
    onAbort
  }: RouteComponentProps & {
    organizationId?: number;
    projectId?: number;
    onAbort?: () => void;
  }) => (
    <CardWrapper>
      <ColumnWrapper gap="1em">
        <span>
          <FormattedMessage id="assistant invite no benchmarks" />
        </span>

        {projectId && organizationId ? (
          <PositionWrapper center={true}>
            <MainButton
              onClick={() => {
                history.push(
                  ROUTE_LAB +
                    '/' +
                    organizationId +
                    '/' +
                    projectId +
                    '/benchmarks'
                );
                onAbort && onAbort();
              }}
            >
              <FormattedMessage id="Go to library" />
            </MainButton>
          </PositionWrapper>
        ) : null}
      </ColumnWrapper>
    </CardWrapper>
  )
);
