
import classNames from 'classnames';
import Icon from 'components/Icon';

interface FloatButtonProps {
  label: string;
  iconName?: string;
  transformToIconName?: string;
  customIcon?: string;
  type?: 'add' | 'menu';
  active?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function FloatButton({
  label,
  iconName,
  transformToIconName,
  customIcon,
  onClick,
  type,
  active,
  disabled
}: FloatButtonProps) {
  const FloatButtonClasses = classNames('float-button', {
    'float-button--add': type === 'add' && active,
    'float-button--colored': customIcon === undefined
  });

  let icon;
  if (type === 'menu') {
    const FloatButtonMenuClasses = classNames('float-button__menu', {
      'float-button__menu cross': !!active
    });

    icon = (
      <div className={FloatButtonMenuClasses}>
        <span />
      </div>
    );
  } else if (customIcon) {
    icon = <img src={customIcon} alt={label} />;
  } else if (iconName) {
    icon = <Icon name={iconName} hidden={!!transformToIconName && active} />;

    if (transformToIconName) {
      icon = (
        <>
          {icon}
          <Icon name={transformToIconName} hidden={!active} />
        </>
      );
    }
  }

  return (
    <button disabled={disabled} className={FloatButtonClasses} aria-label={label} onClick={onClick}>
      {icon}
    </button>
  );
}
