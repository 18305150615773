import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const PainpointQuestionModel = types.model('PainpointQuestionModel', {
  id: types.identifierNumber,
  author: types.maybe(FluidUserModel),
  question: types.maybe(types.string),
  created_at: types.maybe(types.string)
});

export const createPainpointQuestionModel = (
  data: any
): PainpointQuestionModelType => {
  return {
    id: mandatoryId(data?.id),
    author: data?.author ? createFluidUserModel(data.author) : undefined,
    question: data?.question || undefined,
    created_at: data?.created_at || undefined
  };
};

export type PainpointQuestionModelType = typeof PainpointQuestionModel.Type;
export default PainpointQuestionModel;
