import CompanyLogo from 'components/CompanyLogo';
import Headline from 'components/headline';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import Flow from 'components/Layout/Flow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import SimplePopup from 'components/SimplePopup';
import Typography from 'components/Typography';
import UploadButton from 'components/UploadButton';
import UploadedImage from 'components/UploadedImage';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  AssistantSessionSolutionUpdateType,
  AssistantStoreType
} from 'models/AssistantStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import GapSizes from 'utils/constants/gap-sizes';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

export const MAX_SOLUTIONS = 4;

interface PublicSolutionFormComponentProps extends WrappedComponentProps {
  location: HistoryProps['location'];
  index: number;
  editMode?: boolean;
}

interface SolutionFormComponentProps extends PublicSolutionFormComponentProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  form: FormType;
}

interface SolutionFormComponentState {
  loadingState?: 'loading' | 'error';
  confirmFinish?: boolean;
}

@inject('applicationStore', 'assistantStore')
@observer
class SolutionFormComponent extends React.Component<
  SolutionFormComponentProps,
  SolutionFormComponentState
> {
  state: SolutionFormComponentState = {};

  componentDidMount() {
    this.fillForm();

    scrollSidebar('solutions');
  }

  private fillForm() {
    const {
      form,
      index,
      assistantStore: { currentItem }
    } = this.props;

    form.reset();

    const solution = currentItem?.sortedSolutions[index];

    if (!solution) {
      return;
    }

    form.setField('id', solution.id || undefined);
    form.setField('benchmark_id', (solution.benchmark_id || '').toString());
    form.setField('description', solution.description);
  }

  private pushFormRoute() {
    const { currentId } = this.props.assistantStore;
    if (!currentId) {
      return;
    }

    history.push(
      ROUTE_ASSISTANT +
        '/' +
        currentId +
        ASSISTANTS.creative_session.step3_2.route
    );
  }

  private async save(mode: 'attachment' | 'draft' | 'continue' | 'finish') {
    const { assistantStore, form, index } = this.props;
    const { currentId: currentSessionId } = assistantStore;

    if (!currentSessionId) {
      return;
    }

    if (mode !== 'attachment') {
      this.setState({
        loadingState: 'loading'
      });
    }

    const solution = assistantStore.currentItem?.sortedSolutions[index];
    const id = solution?.id;

    const isUpdate = !!id;
    const hasAttachment = !!solution?.attachments.firstOfType('screen');
    const hasAnyContent =
      !!form.values.benchmark_id || !!form.values.description || hasAttachment;
    const isCompleted =
      !!form.values.benchmark_id && !!form.values.description && hasAttachment;

    const patch: AssistantSessionSolutionUpdateType = {
      benchmark_id: form.values.benchmark_id
        ? parseInt(form.values.benchmark_id, 10)
        : undefined,
      description: form.values.description || ''
    };

    if (mode === 'continue' || (mode === 'finish' && isCompleted)) {
      patch.editing_state = 'active';
    }

    if (mode !== 'finish' || hasAnyContent) {
      try {
        if (isUpdate) {
          await assistantStore.updateAssistantSessionSolution(
            currentSessionId,
            id!,
            patch
          );
        } else {
          patch.assistant_session_id = currentSessionId;

          await assistantStore.createAssistantSessionSolution(
            currentSessionId,
            patch
          );
        }
      } catch (error: any) {
        if (handleFormError(form, error)) {
          this.setState({
            loadingState: undefined
          });
          return;
        }

        this.setState({
          loadingState: 'error'
        });
        return;
      }
    }

    if (mode === 'attachment') {
      return;
    }

    if (mode === 'draft') {
      history.push(ROUTE_ASSISTANT);
      return;
    }

    if (this.props.editMode) {
      this.pushFormRoute();
      return;
    }

    const isFinish = mode === 'finish' || index === MAX_SOLUTIONS - 1;
    if (!isFinish) {
      return;
    }

    let isProgressUpdated = true;
    try {
      await assistantStore.updateAssistantSessionProgess(
        currentSessionId,
        ASSISTANTS.creative_session.step3_2.progress
      );
    } catch (error: any) {
      // TODO do something here?
      isProgressUpdated = false;
    }

    if (
      isProgressUpdated &&
      assistantStore.currentItem?.assistant_type === 'hypothesis_sprint'
    ) {
      goToNextStep(
        assistantStore.currentItem.id,
        assistantStore.currentItem.progress,
        'hypothesis_sprint'
      );
    } else {
      history.push(ROUTE_ASSISTANT + '/' + currentSessionId + '/session3/done');
    }
  }

  private finish(confirmed: boolean = false) {
    if (!confirmed && this.props.index < MAX_SOLUTIONS - 1) {
      this.setState({
        confirmFinish: true
      });
      return;
    }

    this.setState({
      confirmFinish: false
    });

    this.save('finish');
  }

  private saveForAttachment() {
    // this.submit(false, true);
    this.save('attachment');
  }

  private async deleteSolution() {
    const { assistantStore, intl, index } = this.props;

    const session = assistantStore.currentItem;
    const sessionId = session?.id;
    const solutionId = session?.sortedSolutions[index]?.id;

    if (!sessionId || !solutionId) {
      return;
    }

    if (session!.solutionsCount < 2) {
      alert(intl.formatMessage({ id: 'assistant solution last error' }));
      return;
    }

    if (
      !window.confirm(
        intl.formatMessage({ id: 'assistant solution delete confirm' })
      )
    ) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      await assistantStore.deleteAssistantSessionSolution(
        sessionId,
        solutionId
      );

      // history.push(ROUTE_ASSISTANT + '/' + sessionId);
      this.pushFormRoute();
    } catch (error: any) {
      this.props.applicationStore.setFlashMessage(
        intl.formatMessage({
          id: 'assistant solution delete error'
        }),
        'error'
      );

      this.setState({
        loadingState: undefined
      });
    }
  }

  private uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    if (!newState) {
      this.setState({
        loadingState: undefined
      });
      return;
    }

    if (newState === 'saving') {
      this.setState({
        loadingState: 'loading'
      });
    }
  }

  private attachmentReceived(attachment: AttachmentModelType) {
    const {
      assistantStore: { currentItem },
      index
    } = this.props;

    if (!attachment) {
      return;
    }

    const solution = currentItem?.sortedSolutions[index];

    if (solution) {
      solution.attachments.put(attachment);
    } else if (attachment.element_id) {
      // TODO what to do without item!?
      // benchmarksStore.initializeItem({
      //   id: attachment.element_id,
      //   attachments: [attachment]
      // });
    }
  }

  private attachmentRemoved(id: number) {
    const {
      assistantStore: { currentItem },
      index
    } = this.props;

    currentItem?.sortedSolutions[index]?.attachments.delete(id);
  }

  private renderBenchmarkIcon() {
    const {
      form,
      assistantStore: { currentItem }
    } = this.props;

    if (!form.values.benchmark_id) {
      return null;
    }

    const id = parseInt(form.values.benchmark_id, 10);

    const benchmark = currentItem?.benchmarkById(id);
    const logo = benchmark?.attachments.firstOfType('logo');

    if (!logo?.resource_urls?.small) {
      return null;
    }

    return (
      <div className="logo-with-thumbnail__logo-large">
        <CompanyLogo alt="Logo" imgUrl={logo.resource_urls?.small} />
      </div>
    );
  }

  private maybeRenderConfirm() {
    if (!this.state.confirmFinish) {
      return null;
    }

    return (
      <SimplePopup
        onAbort={() => this.setState({ confirmFinish: undefined })}
        onSubmit={() => this.finish(true)}
        submitTextId="Continue anyway"
        reduced={true}
      >
        <Headline>
          <FormattedMessage
            id="assistant solutions warning header"
            values={{ count: MAX_SOLUTIONS }}
          />
        </Headline>

        <Typography>
          <FormattedMessage
            id="assistant solutions warning"
            values={{ count: MAX_SOLUTIONS }}
          />
        </Typography>
      </SimplePopup>
    );
  }

  render() {
    const {
      form,
      intl,
      index,
      assistantStore: { currentItem, currentAssistantType, isItemSaving }
    } = this.props;
    const { loadingState } = this.state;

    const benchmarks = currentItem?.sortedBenchmarks || [];

    let isActive: boolean = false;
    let attachment: AttachmentModelType | undefined;
    const solution = currentItem?.sortedSolutions[index];
    if (solution) {
      attachment = solution.attachments.firstOfType('screen');
      isActive = solution.editing_state === 'active';
    }

    const isContinueDisabled =
      !form.values.benchmark_id || !form.values.description || !attachment;
    const isDraftDisabled =
      !form.values.benchmark_id && !form.values.description && !attachment;

    const stepInfo = getStepInfo('step3_2', currentAssistantType);
    const isNotCreativeSession = currentAssistantType !== 'creative_session';

    return (
      <StepContainer
        progress={
          isNotCreativeSession ? stepInfo?.percent : (index + 1) * 16 + 16
        }
        sidebarHeadline={stepInfo?.header}
        showHeader="solutions"
        location={this.props.location}
        timerSeconds={600}
      >
        {this.maybeRenderConfirm()}
        {(loadingState === 'loading' || isItemSaving) && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant solutions header" />
          </SessionHeadline>

          <SessionMainText noMargin={true}>
            <FormattedMessage id="assistant solutions" />
          </SessionMainText>

          <div style={{ margin: 0, paddingBottom: '2em' }}>
            <VideoPopupLink videoId="hCgMr0M9gGY" />
          </div>
        </SessionTop>

        <SessionBottom>
          <Columns columnTemplate="11.125rem 1fr" gap={GapSizes.S}>
            <Column>
              <div className="logo-with-thumbnail">
                {attachment ? (
                  <UploadedFileContainer
                    attachment={attachment}
                    onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                    key={attachment.id}
                  >
                    {(props) => (
                      <div className="logo-with-thumbnail__thumbnail">
                        <UploadedImage {...props} />
                      </div>
                    )}
                  </UploadedFileContainer>
                ) : (
                  <UploadContainer
                    attachmentType="screen"
                    elementType={ElementType.AssistantSessionSolution}
                    elementId={solution?.id}
                    waitForId={true}
                    onLoadingStateChanged={(s) =>
                      this.uploadLoadingStateChanged(s)
                    }
                    onAttachmentReceived={(a) => this.attachmentReceived(a)}
                    onWaitingForId={() => this.saveForAttachment()}
                  >
                    {(props: UploadContainerChildProps) => (
                      <UploadButton {...props} />
                    )}
                  </UploadContainer>
                )}

                {this.renderBenchmarkIcon()}
              </div>
            </Column>
            <Column>
              <Flow flowSpace="0.5rem">
                <Select name="benchmark_id" {...form.bindInput('benchmark_id')}>
                  <option key="empty" value="" disabled={true}>
                    {intl.formatMessage({
                      id: 'assistant solutions benchmark placeholder'
                    })}
                  </option>
                  {benchmarks.map((benchmark) => (
                    <option key={benchmark.id} value={benchmark.id.toString()}>
                      {benchmark.company_name || benchmark.headline}
                    </option>
                  ))}
                </Select>
                <Textarea
                  placeholder={intl.formatMessage({
                    id: 'assistant solutions description placeholder'
                  })}
                  name="description"
                  slim={true}
                  {...form.bindInput('description')}
                />
              </Flow>
            </Column>
          </Columns>
        </SessionBottom>

        {isActive ? (
          <SessionActions>
            <MainButton secondary={true} onClick={() => this.deleteSolution()}>
              <FormattedMessage id="Remove" />
            </MainButton>

            <MainButton
              type="submit"
              disabled={isContinueDisabled}
              onClick={() => this.save('continue')}
            >
              <FormattedMessage id="Update solution" />
            </MainButton>
          </SessionActions>
        ) : (
          <>
            <SessionActions>
              <MainButton
                secondary={true}
                disabled={isDraftDisabled}
                onClick={() => this.save('draft')}
              >
                <FormattedMessage id="Save draft" />
              </MainButton>

              <MainButton
                type="submit"
                disabled={isContinueDisabled}
                onClick={() => this.save('continue')}
              >
                <FormattedMessage id="Add solution" />
              </MainButton>
            </SessionActions>

            <SessionActions>
              <MainButton
                type="button"
                onClick={() => this.finish()}
                disabled={isContinueDisabled && index === 0}
              >
                <FormattedMessage
                  id={isNotCreativeSession ? 'Next' : 'assistant finish button'}
                />
              </MainButton>
            </SessionActions>
          </>
        )}
      </StepContainer>
    );
  }
}

export default injectIntl((props: PublicSolutionFormComponentProps) => {
  const form = useForm();
  // @ts-ignore
  return <SolutionFormComponent {...props} form={form} />;
});
