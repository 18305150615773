import React, { useState } from 'react';
import TabButton from './TabButton';

interface HeadlinePropsType {
  active: boolean;
  title: string;
  onClick?: () => void;
}
export const TabContainer = ({
  children,
  HeadlineElement
}: {
  children: Array<React.ReactElement<HeadlinePropsType> | false>;
  HeadlineElement?: React.ElementType<HeadlinePropsType>;
}) => {
  const hasChildren = React.Children.count(children) > 0;
  const childs = children.filter((child) => child !== false) as Array<
    React.ReactElement<HeadlinePropsType>
  >;
  const [activeTabKey, setActiveTabKey] = useState(
    hasChildren && childs.length > 0 && childs[0].props.title
  );

  if (!hasChildren) {
    return null;
  }
  const HeadlineItem = HeadlineElement ? HeadlineElement : TabButton;
  return (
    <div className="tab-container">
      <div className="tab-container__button-container">
        {React.Children.map(childs, (child) => (
          <HeadlineItem
            key={child.props.title}
            active={activeTabKey === child.props.title}
            title={child.props.title}
            // @ts-ignore
            onClick={() => setActiveTabKey(child.props.title)}
          />
        ))}
      </div>
      {React.Children.map(
        childs,
        (child) => activeTabKey === child.props.title && child
      )}
    </div>
  );
};

export default TabContainer;
