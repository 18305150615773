const FaqPageCardItem = ({
  title,
  description,
  image,
  handleClick
}: {
  title?: string;
  description?: string;
  image?: string;
  handleClick: () => void;
}) => {
  const teaser = image || '/images/faq/default-teaser.png';
  return (
    <div className="faq-page-card" onClick={() => handleClick()}>
      <div className="faq-page-card__image-container">
        <img src={teaser} alt={title} />
      </div>
      <div className="faq-page-card__body">
        <h2 className="faq-page-card__headline">{title}</h2>
      </div>
    </div>
  );
};

export default FaqPageCardItem;
