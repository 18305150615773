import CardWrapper from 'components/CardWrapper';
import Form from 'components/Form';
import { DisconnectedButton } from 'components/Form/DisconnectedButton';
import Input from 'components/Inputs/Input';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import LoginForm from 'components/LoginForm';
import MainButton from 'components/MainButton';
import ToggleSwitch from 'components/ToggleSwitch';
import LanguageSelectContainer from 'containers/LanguageSelectContainer';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicLoginFormContainerProps {
  test?: boolean;
}

interface LoginFormContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  form: FormType;
}

interface LoginFormContainerState {
  changingLanguage?: boolean;
}

@inject('applicationStore', 'dataStore')
@observer
class LoginFormContainer extends React.Component<
  LoginFormContainerProps & PublicLoginFormContainerProps,
  LoginFormContainerState
> {
  state: LoginFormContainerState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    if (
      !applicationStore.isAuthenticated &&
      !applicationStore.isAuthenticating
    ) {
      // make request to check if user is logged in, redirect to dashboard then
      applicationStore.checkAuthenticated(false, true);
    }
  }

  async handleLogin() {
    const { applicationStore, form } = this.props;

    try {
      await applicationStore.login(
        form.values.email || '',
        form.values.password || '',
        form.values.remember ? true : false
      );
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  handleLogout() {
    this.props.applicationStore.logout();
  }

  async handleReset() {
    const { applicationStore, form } = this.props;

    try {
      await applicationStore.initPasswordReset(form.values.email || '');
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  resetPassword() {
    this.props.form.resetErrors();
    this.props.form.setField('password', '');

    this.props.applicationStore.enterPasswordReset();
  }

  exitReset() {
    this.props.form.resetErrors();
    this.props.applicationStore.exitPasswordReset();
  }

  renderLoginForm() {
    const { applicationStore, form } = this.props;

    let loading;
    if (
      applicationStore.isLoggingIn ||
      applicationStore.isAuthenticating ||
      form.loading
    ) {
      loading = <Loading />;
    }

    let error;
    switch (applicationStore.authState.error) {
      case 'login_failed':
        error = <FormattedMessage id="login failed message" />;
        break;

      case 'login_error':
        error = <FormattedMessage id="login error message" />;
        break;

      default:
    }

    return (
      <LoginForm>
        <Form onSubmit={() => this.handleLogin()}>
          {loading}

          {error && <LoginForm.Error>{error}</LoginForm.Error>}

          <LoginForm.Input>
            <Input
              name="email"
              key="login_email"
              autoFocus={true}
              {...form.bindInput('email')}
              label={<FormattedMessage id="Email address" />}
              gap={true}
            />
          </LoginForm.Input>

          <LoginForm.Input>
            <Input
              name="password"
              type="password"
              {...form.bindInput('password')}
              label={<FormattedMessage id="Password" />}
              gap={true}
            />
          </LoginForm.Input>

          <div style={{ margin: '0 0 1em' }}>
            <RowWrapper gap="1em">
              <ToggleSwitch
                label={<FormattedMessage id="Remember me" />}
                {...form.bindCheckbox('remember')}
              />
              <span aria-hidden="true">
                <FormattedMessage id="Remember me" />
              </span>
            </RowWrapper>
          </div>

          <PositionWrapper center={true} gap="2em">
            <RowWrapper gap="1em">
              <MainButton type="submit">
                <FormattedMessage id="Login" />
              </MainButton>
              <DisconnectedButton
                secondary={true ? 1 : 0}
                large={true ? 1 : 0}
                onClick={() => this.resetPassword()}
              >
                <FormattedMessage id="Forgot password" />
              </DisconnectedButton>
            </RowWrapper>
          </PositionWrapper>

          <PositionWrapper center={true}>
            <LanguageSelectContainer />
          </PositionWrapper>
        </Form>
      </LoginForm>
    );
  }

  renderPasswordResetSent() {
    return (
      <LoginForm>
        <CardWrapper>
          <FormattedMessage id="passwort reset requested" />

          <MainButton onClick={() => this.exitReset()}>
            <FormattedMessage id="Back to login" />
          </MainButton>
        </CardWrapper>
      </LoginForm>
    );
  }

  renderPasswordReset() {
    const { applicationStore, form } = this.props;

    if (applicationStore.authState.resetState === 'token_requested') {
      return this.renderPasswordResetSent();
    }

    let loading;
    if (
      applicationStore.authState.resetState === 'loading' ||
      applicationStore.isLoggingIn ||
      applicationStore.isAuthenticating
    ) {
      loading = <Loading />;
    }

    let error;
    switch (applicationStore.authState.resetState) {
      case 'user_not_found':
        error = <FormattedMessage id="reset not found message" />;
        break;

      case 'error':
        error = <FormattedMessage id="reset error message" />;
        break;

      default:
    }

    return (
      <LoginForm>
        <Form onSubmit={() => this.handleReset()}>
          {loading}

          {error && <LoginForm.Error>{error}</LoginForm.Error>}

          <LoginForm.Input>
            <Input
              name="email"
              key="reset_email"
              autoFocus={true}
              {...form.bindInput('email')}
              label={<FormattedMessage id="Email address" />}
              gap={true}
            />
          </LoginForm.Input>

          <RowWrapper gap="1em">
            <MainButton type="submit">
              <FormattedMessage id="Reset password" />
            </MainButton>
            <DisconnectedButton
              secondary={true ? 1 : 0}
              onClick={() => this.exitReset()}
            >
              <FormattedMessage id="Cancel" />
            </DisconnectedButton>
          </RowWrapper>
        </Form>
      </LoginForm>
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.authState.resetActive) {
      return this.renderPasswordReset();
    }

    return this.renderLoginForm();
  }
}

export default (props: PublicLoginFormContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <LoginFormContainer {...props} form={form} />;
};
