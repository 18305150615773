import ListMenu from 'components/ListMenu';
import MenuButton from 'components/MenuButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter } from 'react-router';
// import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface SettingsNavItemProps {
  applicationStore?: ApplicationStoreType;
  assistantStore?: AssistantStoreType;
  dataStore?: DataStoreType;
}

export default injectIntl(
  withRouter(
    inject(
      'applicationStore',
      'assistantStore',
      'dataStore'
    )(
      observer(
        ({
          applicationStore,
          assistantStore,
          dataStore,
          intl,
          history
        }: SettingsNavItemProps & WrappedComponentProps & HistoryProps) => {
          if (!dataStore?.isProjectEditor) {
            return null;
          }

          const fm = (id: string, limit?: number) =>
            intl.formatMessage({ id }, { limit });
          const create = (type: string) =>
            history.push(dataStore.contextUri + '/' + type + '/create');

          //           const beginSession = async () => {
          //             if (!dataStore!.currentProjectId) {
          //               return;
          //             }
          //
          //             let session;
          //             try {
          //               session = await assistantStore!.createAssistantSession({
          //                 project_id: dataStore!.currentProjectId
          //               });
          //             } catch (error: any) {
          //               // form error!?
          //             }
          //
          //             if (session?.id) {
          //               history.push(ROUTE_ASSISTANT + '/' + session.id);
          //               return;
          //             }
          //
          //             applicationStore!.setFlashMessage(
          //               intl.formatMessage({ id: 'create session error' }),
          //               'error'
          //             );
          //           };

          return (
            <ListMenu label={fm('Create')}>
              <MenuButton
                label={fm('New painpoint')}
                description={
                  dataStore.project?.reachedPainpointLimit
                    ? fm(
                        'Painpoint limit reached',
                        dataStore.organization?.painpoint_limit
                      )
                    : fm('Create new painpoint')
                }
                iconName="clipboard"
                disabled={
                  !dataStore!.isAdmin &&
                  dataStore.project?.reachedPainpointLimit
                }
                onClick={() =>
                  history.push(
                    dataStore.contextUri + '/painpoints?action=create'
                  )
                }
              />
              <MenuButton
                label={fm('New benchmark')}
                description={
                  dataStore.project?.reachedBenchmarkLimit
                    ? fm(
                        'Benchmark limit reached',
                        dataStore.organization?.benchmark_limit
                      )
                    : fm('Create new benchmark')
                }
                iconName="detail"
                disabled={
                  !dataStore!.isAdmin &&
                  dataStore.project?.reachedBenchmarkLimit
                }
                onClick={() => create('benchmarks')}
              />
              <MenuButton
                label={fm('New hypothesis')}
                description={
                  dataStore.project?.reachedHypothesisLimit
                    ? fm(
                        'Hypothesis limit reached',
                        dataStore.organization?.hypothesis_limit
                      )
                    : fm('Create new hypothesis')
                }
                iconName="bulb"
                disabled={
                  !dataStore!.isAdmin &&
                  dataStore.project?.reachedHypothesisLimit
                }
                onClick={() => create('hypotheses')}
              />
              <MenuButton
                label={fm('New prototype')}
                description={
                  dataStore.project?.reachedPrototypeLimit
                    ? fm(
                        'Prototype limit reached',
                        dataStore.organization?.prototype_limit
                      )
                    : fm('Create new prototype')
                }
                iconName="strategy"
                disabled={
                  !dataStore!.isAdmin &&
                  dataStore.project?.reachedPrototypeLimit
                }
                onClick={() => create('prototypes')}
              />
              <MenuButton
                label={fm('New learning')}
                description={fm('Create new learning')}
                iconName="learning"
                onClick={() => create('learnings')}
              />
              {/* <MenuButton
                label={fm('New creative session')}
                description={fm('Create creative session')}
                iconName="assistant"
                onClick={beginSession}
              /> */}
            </ListMenu>
          );
        }
      )
    )
  )
);
