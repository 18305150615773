import React, { useEffect } from 'react';
import OverlayWrapper from 'components/Layout/OverlayWrapper/OverlayWrapper';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import Icon from 'components/Icon';

interface AiUnavailableProps {
  applicationStore?: ApplicationStoreType;
}

function AiUnavailable({ applicationStore }: AiUnavailableProps) {
  const history = useHistory();
  const { orgId, projectId } = useParams<{
    orgId: string;
    projectId: string;
  }>();

  const main = document.getElementsByTagName('main')[0];
  const oldOverflow = main.style.overflow;
  useEffect(() => {
    main.style.overflow = 'hidden';

    return () => {
      // This function runs when the component unmounts
      document.getElementsByTagName('main')[0].style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '1001'
      }}
    >
      <OverlayWrapper
        disabled={true}
        background="dark"
        center={true}
        reduced={true}
        absolute={true}
      >
        <article className="share-popup">
          <header className="share-popup__header">
            <h1 className="share-popup__headline">
              <FormattedMessage id="Hint" />
            </h1>
          </header>

          <div
            className="share-popup__body share-popup__body--evenly"
            style={{ '--min-height': '19em' } as React.CSSProperties}
          >
            <>
              <div className="share-popup__description share-popup__description--centered">
                <FormattedMessage
                  id="Briefing not completed"
                  values={{
                    br: <br />
                  }}
                />
              </div>
              <div
                style={{
                  borderRadius: '50%',
                  background: 'red',
                  width: '3.375em',
                  height: '3.375em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon name="cross" size="large" />
              </div>

              <button
                className="main-button"
                onClick={() => {
                  applicationStore!.setAiUnavailable(false);

                  document.getElementsByTagName('main')[0].style.overflow =
                    oldOverflow;

                  history.push(`/app/lab/${orgId}/${projectId}`);
                }}
              >
                <FormattedMessage id="Briefing not completed link" />
              </button>
            </>
          </div>
        </article>
      </OverlayWrapper>
    </div>
  );
}

export default inject('applicationStore')(observer(AiUnavailable));
