import Icon from 'components/Icon';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import { OrganizationModelType } from 'models/OrganizationModel';
import { FormattedMessage } from 'react-intl';
import CustomerListScreenMenu from 'components/OrganizationsTableComponent/OrganizationsTableItemMenu';
import CompanyLogo from 'components/CompanyLogo';
import { useEffect, useState } from 'react';

export default function OrganizationsTable({
  organizations,
  showMenu = true,
  onDeleted,
  history,
  searchTerm = ''
}: {
  organizations: OrganizationModelType[];
  showMenu: boolean;
  onDeleted?: () => void;
  history: any;
  searchTerm?: string;
}) {
  const [currentOrganizations, setCurrentOrganizations] = useState<
    OrganizationModelType[]
  >([]);

  const [sortBy, setSortBy] = useState<'name' | 'projects' | 'activity'>(
    'name'
  );
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const toggleSortBy = (nextSortBy: 'name' | 'projects' | 'activity') => {
    if (sortBy === nextSortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirection('asc');
      setSortBy(nextSortBy);
    }
  };

  useEffect(() => {
    setCurrentOrganizations(organizations);
  }, [organizations]);

  useEffect(() => {
    let sortedOrganizations = organizations;

    switch (sortBy) {
      case 'name':
        sortedOrganizations = [...organizations].sort((a, b) =>
          sortDirection === 'asc'
            ? a.name!.localeCompare(b.name!)
            : b.name!.localeCompare(a.name!)
        );
        break;
      case 'projects':
        sortedOrganizations = [...organizations].sort((a, b) =>
          sortDirection === 'asc'
            ? a.project_count - b.project_count
            : b.project_count - a.project_count
        );
        break;
      case 'activity':
        sortedOrganizations = [...organizations].sort((a, b) =>
          sortDirection === 'asc'
            ? a.last_activity - b.last_activity
            : b.last_activity - a.last_activity
        );
        break;
    }

    setCurrentOrganizations(sortedOrganizations);
  }, [sortDirection, sortBy, organizations]);

  const filteredOrganizations = currentOrganizations.filter((organization) =>
    organization.name!.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <table className="customer-list">
      <thead className="customer-info-header">
        <tr>
          <th>
            <PositionWrapper center={true}>
              <Icon name="agency" size="large" />
            </PositionWrapper>
          </th>
          <th
            className={sortBy === 'name' ? sortDirection : 'desc inactive'}
            onClick={() => {
              toggleSortBy('name');
            }}
          >
            <FormattedMessage id="Customer name" />
          </th>
          <th
            className={sortBy === 'projects' ? sortDirection : 'desc inactive'}
            onClick={() => {
              toggleSortBy('projects');
            }}
          >
            <FormattedMessage id="Customer project size" />
          </th>
          <th
            className={sortBy === 'activity' ? sortDirection : 'desc inactive'}
            onClick={() => {
              toggleSortBy('activity');
            }}
          >
            <FormattedMessage id="Customer last activity" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filteredOrganizations.map((organization: OrganizationModelType) => (
          <tr className="customer-row" key={organization.id}>
            <td
              className="customer-logo"
              onClick={() => {
                history.push(`/app/lab/${organization.id}`);
              }}
            >
              <CompanyLogo
                imgUrl={
                  organization.attachments?.firstOfType('logo')?.resource_urls
                    ?.small
                }
                alt="Logo"
                big={true}
              />
            </td>
            <td
              className="customer-name"
              onClick={() => {
                history.push(`/app/lab/${organization.id}`);
              }}
            >
              <div>{organization.name}</div>
              <div className="customer-sector">{organization.sector}</div>
            </td>
            <td className="customer-info">
              <FormattedMessage
                id="Customer project count"
                values={{ count: organization.project_count }}
              />
            </td>
            <td className="customer-info">
              {new Date(organization.last_activity!).toLocaleString()}
            </td>
            <td>
              {showMenu && (
                <CustomerListScreenMenu
                  customer={organization}
                  history={history}
                  onDeleted={
                    onDeleted
                      ? () => {
                          onDeleted();
                        }
                      : undefined
                  }
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
