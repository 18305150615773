import DisrooptiveApi from 'api/DisrooptiveApi';
import { saveAs } from 'file-saver';
import getIntl from 'i18n/locales';
import { flow, getEnv, IMSTMap, types } from 'mobx-state-tree';
import { isDefined } from 'utils/misc/is-defined';

import { ApiElementTypeEnumType, ElementType } from './ApiElementTypeEnum';
import { ApplicationStoreType } from './ApplicationStore';
import { BenchmarksStoreType } from './BenchmarksStore';
import { HypothesesStoreType } from './HypothesesStore';
import { LearningsStoreType } from './LearningsStore';
import { ListLoadingStateEnum } from './LoadingStateEnums';
import { PainpointsStoreType } from './PainpointsStore';
import { PrototypesStoreType } from './PrototypesStore';

export type DownloadableElementType =
  | ElementType.Benchmark
  | ElementType.Hypothesis
  | ElementType.Prototype
  | ElementType.Learning
  | ElementType.Painpoint;
export type SelectableElementType =
  | ElementType.Benchmark
  | ElementType.Hypothesis
  | ElementType.Painpoint
  | ElementType.Prototype
  | ElementType.Learning;

const downloadableElementTypes = [
  ElementType.Benchmark,
  ElementType.Hypothesis,
  ElementType.Prototype,
  ElementType.Learning,
  ElementType.Painpoint
];

/** Array of IDs with their ElementType  */
export interface TypedElementList {
  ids: number[];
  type: ElementType;
}

export type ElementCopyMatrix = TypedElementList[];

/** Map from ElementType to array of IDs */
export type TypedElementMap = { [t in ElementType]: number[] };

/** Element ID with context and metadata */
export interface ElementWithContext {
  id: number;
  organizationId: number;
  projectId: number;
  publishState?: string;
}

export interface TypedElementWithContext<T = ElementType>
  extends ElementWithContext {
  type: T;
}

/** Map from ElementType to array of ElementWithContext */
export type TypedElementWithContextMap = {
  [t in ElementType]: ElementWithContext[];
};

export const ElementWithContextModel = types.model('ElementWithContextModel', {
  id: types.identifierNumber,
  organizationId: types.number,
  projectId: types.number,
  publishState: types.maybe(types.string)
});

// Helper functions
export const hasElementMatrixAny = (matrix?: ElementCopyMatrix): boolean => {
  return (
    isDefined(matrix) &&
    matrix.length > 0 &&
    matrix.some((list) => list.ids.length > 0)
  );
};

export const countMatrixElements = (matrix?: ElementCopyMatrix): number => {
  return isDefined(matrix) && matrix.length > 0
    ? matrix.reduce((sum, list) => sum + list.ids.length, 0)
    : 0;
};

/** Convert TypedElementMap to ElementCopyMatrix */
export const elementMapToMatrix = (
  elementMap: Partial<TypedElementMap>
): ElementCopyMatrix =>
  Object.entries(elementMap).map(([type, ids]) => ({
    type: type as ElementType,
    ids: ids as number[]
  }));

/** Convert TypedElementWithContextMap to array of TypedElementWithContext */
export const elementMapToArray = (
  elementMap: Partial<TypedElementWithContextMap>
): TypedElementWithContext[] =>
  Object.entries(elementMap)
    .map(([elementType, elements]) =>
      !isDefined(elements)
        ? []
        : elements.map((element) => ({
            ...element,
            type: elementType as SelectableElementType
          }))
    )
    .reduce((all, current) => all.concat(current), []);

export const elementsToTypedElements = <T = ElementType>(
  type: T,
  elements: ElementWithContext[]
): Array<TypedElementWithContext<T>> =>
  elements.map((element) => Object.assign({}, element, { type }));

/** Filter all arrays of ElementWithContext in a TypedElementWithContextMap */
export const elementMapFilter = (
  elementMap: Partial<TypedElementWithContextMap>,
  filterFn: (element: TypedElementWithContext) => boolean
): Partial<TypedElementWithContextMap> =>
  Object.entries(elementMap)
    .map(([elementType, elements]) => [
      elementType,
      !isDefined(elements)
        ? []
        : elementsToTypedElements(elementType as ElementType, elements).filter(
            filterFn
          )
    ])
    .reduce(
      (map, [elementType, elements]) =>
        Object.assign(map, { [String(elementType)]: elements }),
      {}
    );

/** Map all arrays of ElementWithContext in a TypedElementWithContextMap */
export const elementMapMap = <T>(
  elementMap: Partial<TypedElementWithContextMap>,
  mapFn: (element: TypedElementWithContext) => T
): Partial<{ [t in keyof TypedElementWithContextMap]: T[] }> =>
  Object.entries(elementMap)
    .map(([elementType, elements]) => [
      elementType,
      !isDefined(elements)
        ? []
        : elementsToTypedElements(elementType as ElementType, elements).map(
            mapFn
          )
    ])
    .reduce(
      (map, [elementType, elements]) =>
        Object.assign(map, { [String(elementType)]: elements }),
      {}
    );

/** Convert a TypedElementWithContextMap to a TypedElementMap */
export const elementMapRemoveContext = (
  elementMap: Partial<TypedElementWithContextMap>
): Partial<TypedElementMap> =>
  elementMapMap(elementMap, (element) => element.id);

const getElementsFromMap = (map: IMSTMap<any>): ElementWithContext[] =>
  Array.from(map.values()); // TODO Should be `typeof ElementWithContextModel.Type` or so

const getElementIdsFromMap = (
  map: IMSTMap<any>
): number[] => // TODO Should be `typeof ElementWithContextModel.Type` or so
  Array.from(map.values()).map((element: ElementWithContext) => element.id);

interface ActionsStoreEnvType {
  client: DisrooptiveApi;
  applicationStore: ApplicationStoreType;
  benchmarksStore: BenchmarksStoreType;
  hypothesesStore: HypothesesStoreType;
  painpointsStore: PainpointsStoreType;
  prototypesStore: PrototypesStoreType;
  learningsStore: LearningsStoreType;
}

const ActionsStore = types
  .model('ActionsStore', {
    copyLoadingState: types.maybe(ListLoadingStateEnum),

    // Selection
    benchmarks: types.map(ElementWithContextModel),
    hypotheses: types.map(ElementWithContextModel),
    painpoints: types.map(ElementWithContextModel),
    prototypes: types.map(ElementWithContextModel),
    learnings: types.map(ElementWithContextModel)
  })
  .actions((self) => {
    const copyToProject = flow(function* (
      elementId: number,
      elementType: ApiElementTypeEnumType,
      targetProjectId: number
    ) {
      const { client, applicationStore }: ActionsStoreEnvType = getEnv(self);

      try {
        yield client.copyElement(elementId, elementType, targetProjectId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ActionsStore | copyToProject', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    // internal action to generalize bulkCopyToProject and mixedBulkCopyToProject
    const bulkCopy = flow(function* (
      elementIds: number[],
      elementType: ApiElementTypeEnumType,
      targetProjectId: number
    ) {
      const { applicationStore }: ActionsStoreEnvType = getEnv(self);

      let copied = 0;

      for (const elementId of elementIds) {
        try {
          yield copyToProject(elementId, elementType, targetProjectId);

          copied++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error('ActionsStore | bulkCopy', error, error.body);
          }

          if (applicationStore.handleAppError(error)) {
            throw error;
          }
        }
      }

      return copied;
    });

    // const bulkCopyToProject = flow(function*(
    //   elementIds: number[],
    //   elementType: ApiElementTypeEnumType,
    //   targetProjectId: number
    // ) {
    //   const { applicationStore }: ActionsStoreEnvType = getEnv(self);

    //   self.copyLoadingState = 'loading';
    //   let copied = 0;

    //   try {
    //     copied = yield bulkCopy(elementIds, elementType, targetProjectId);
    //   } catch (error:any) {
    //     if (applicationStore.handleAppError(error)) {
    //       return;
    //     }
    //   }

    //   self.copyLoadingState = undefined;
    //   return copied;
    // });

    const mixedBulkCopyToProject = flow(function* (
      elements: ElementCopyMatrix,
      targetProjectId: number
    ) {
      const { applicationStore }: ActionsStoreEnvType = getEnv(self);

      self.copyLoadingState = 'loading';
      let copied = 0;

      for (const ident of elements) {
        try {
          const copiedCurrent = yield bulkCopy(
            ident.ids,
            ident.type,
            targetProjectId
          );

          copied = copied + copiedCurrent;
        } catch (error: any) {
          if (applicationStore.handleAppError(error)) {
            return;
          }
        }
      }

      self.copyLoadingState = undefined;
      return copied;
    });

    const deleteElements = flow(function* (
      elementIds: Partial<TypedElementMap>
    ) {
      const {
        benchmarksStore,
        hypothesesStore,
        painpointsStore,
        prototypesStore,
        learningsStore
      }: ActionsStoreEnvType = getEnv(self);

      const deletedCounts: number[] = yield Promise.all(
        Object.entries(elementIds).map(([elementType, ids]) => {
          return !isDefined(ids)
            ? 0
            : elementType === ElementType.Benchmark
            ? benchmarksStore.bulkDeleteBenchmarks(ids)
            : elementType === ElementType.Hypothesis
            ? hypothesesStore.bulkDeleteHypotheses(ids)
            : elementType === ElementType.Painpoint
            ? painpointsStore.bulkDeletePainpoints(ids)
            : elementType === ElementType.Prototype
            ? prototypesStore.bulkDeletePrototypes(ids)
            : elementType === ElementType.Learning
            ? learningsStore.bulkDeleteLearnings(ids)
            : 0;
        })
      );

      const deletedTotal = deletedCounts.reduce(
        (sum: number, count: number) => sum + count
      );

      if (deletedTotal > 0) {
        selectionClear();
      }

      return deletedTotal;
    });

    const downloadPdf = flow(function* (
      elements: Partial<Pick<TypedElementMap, DownloadableElementType>>
    ) {
      const { client, applicationStore }: ActionsStoreEnvType = getEnv(self);

      const elementTypes: ElementType[] = Object.entries(elements)
        .filter(([_, ids]) => isDefined(ids) && ids.length > 0)
        .map(([elementType, _]) => elementType as ElementType);
      if (
        !elementTypes.some((type) => downloadableElementTypes.includes(type))
      ) {
        throw new Error(
          'Invalid PDF type. Needs at least one of "Benchmark", "Hypothesis", "Prototype", "Learning", "Painpoint".'
        );
      }

      const elementTypeToPlural: Partial<Record<ElementType, string>> = {
        Benchmark: 'benchmarks',
        Hypothesis: 'hypotheses',
        Prototype: 'prototypes',
        Learning: 'learnings',
        Painpoint: 'painpoints'
      };

      try {
        const file = yield elementTypes.length === 1
          ? client.downloadPdf(
              elementTypes[0],
              elements[elementTypes[0] as DownloadableElementType]
            )
          : client.downloadZip(elements);

        const filename =
          elementTypes.length === 1
            ? `${elementTypeToPlural[elementTypes[0]]}.pdf`
            : 'export.zip';

        saveAs(yield file.blob(), filename);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ActionsStore | downloadPdf', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        applicationStore.setFlashMessage(
          getIntl().formatMessage({ id: 'pdf download error flash' }),
          'error'
        );
      }
    });

    const createSharingLink = flow(function* (
      password: string,
      elements: Partial<TypedElementWithContextMap>
    ) {
      const { client, applicationStore }: ActionsStoreEnvType = getEnv(self);

      try {
        const sharingLink: {
          id: number;
          url_token: string;
          url: string;
          requires_password: boolean;
        } = yield client.createSharingLink(
          password,
          elementMapRemoveContext(
            elementMapFilter(
              elements,
              (element) => element.publishState !== 'draft'
            )
          )
        );
        return sharingLink;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ActionsStore | createSharingLink', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        applicationStore.setFlashMessage(
          getIntl().formatMessage({ id: 'sharing link create error flash' }),
          'error'
        );
      }
    });

    // Selection
    const selectableTypeMaps = {
      Benchmark: self.benchmarks,
      Hypothesis: self.hypotheses,
      Painpoint: self.painpoints,
      Prototype: self.prototypes,
      Learning: self.learnings
    };
    const selectionAdd = (elementMap: Partial<TypedElementWithContextMap>) => {
      Object.entries(elementMap).forEach(([type, elements]) => {
        if (!selectableTypeMaps.hasOwnProperty(type) || !isDefined(elements)) {
          return;
        }
        elements.forEach((element) =>
          selectableTypeMaps[type as keyof typeof selectableTypeMaps].put(
            element
          )
        );
      });
    };

    const selectionClear = () => {
      Object.values(selectableTypeMaps).forEach((map) => map.clear());
    };

    const selectionRemove = (
      elementMap: Partial<TypedElementWithContextMap>
    ) => {
      Object.entries(elementMap).forEach(([type, elements]) => {
        if (!selectableTypeMaps.hasOwnProperty(type) || !isDefined(elements)) {
          return;
        }
        elements.forEach(({ id }) =>
          selectableTypeMaps[type as keyof typeof selectableTypeMaps].delete(
            String(id)
          )
        );
      });
    };

    const selectionSet = (
      elementMap: Partial<TypedElementWithContextMap>,
      isSelected: boolean
    ) => {
      return isSelected
        ? selectionAdd(elementMap)
        : selectionRemove(elementMap);
    };

    return {
      copyToProject,
      // bulkCopyToProject,
      mixedBulkCopyToProject,
      deleteElements,
      downloadPdf,
      createSharingLink,
      selectionAdd,
      selectionClear,
      selectionRemove,
      selectionSet
    };
  })
  .views((self) => ({
    get isCopyLoading(): boolean {
      return self.copyLoadingState === 'loading';
    },
    get isCopyLoadError(): boolean {
      return (
        self.copyLoadingState === 'load_error' ||
        self.copyLoadingState === 'access_denied'
      );
    },

    // Selection
    selectionIsOnlyFromProject(projectId: number) {
      for (const list of [
        self.benchmarks,
        self.hypotheses,
        self.prototypes,
        self.learnings
      ]) {
        for (const element of list.values()) {
          if (projectId !== element.projectId) {
            return false;
          }
        }
      }
      return true;
    },

    /**
     * Number of selected elements of the given type, or in total if none is given
     */
    selectedCount(elementType?: SelectableElementType): number {
      return elementType === ElementType.Benchmark
        ? self.benchmarks.size
        : elementType === ElementType.Hypothesis
        ? self.hypotheses.size
        : elementType === ElementType.Painpoint
        ? self.painpoints.size
        : elementType === ElementType.Prototype
        ? self.prototypes.size
        : elementType === ElementType.Learning
        ? self.learnings.size
        : self.benchmarks.size +
          self.hypotheses.size +
          self.painpoints.size +
          self.prototypes.size +
          self.learnings.size;
    },

    /**
     * A map from SelectableElementType to selected elements of that type, with context
     */
    get selectedElements(): Pick<
      TypedElementWithContextMap,
      SelectableElementType
    > {
      return {
        Benchmark: getElementsFromMap(self.benchmarks),
        Hypothesis: getElementsFromMap(self.hypotheses),
        Painpoint: getElementsFromMap(self.painpoints),
        Prototype: getElementsFromMap(self.prototypes),
        Learning: getElementsFromMap(self.learnings)
      };
    },

    /**
     * A map from SelectableElementType to array of IDs of selected elements of that type
     */
    get selectedIds(): Pick<TypedElementMap, SelectableElementType> {
      return {
        Benchmark: getElementIdsFromMap(self.benchmarks),
        Hypothesis: getElementIdsFromMap(self.hypotheses),
        Painpoint: getElementIdsFromMap(self.painpoints),
        Prototype: getElementIdsFromMap(self.prototypes),
        Learning: getElementIdsFromMap(self.learnings)
      };
    },

    /**
     * The IDs of the projects of which at least one element is selected
     */
    get selectedFromProjectIds(): number[] {
      const projectIds: Set<number> = new Set();
      elementMapToArray(this.selectedElements).forEach((element) =>
        projectIds.add(element.projectId)
      );
      return Array.from(projectIds);
    },

    /**
     * The element types of which at least one element is selected
     */
    get selectedFromTypes(): SelectableElementType[] {
      const typeNames: Set<SelectableElementType> = new Set();
      elementMapToArray(this.selectedElements).forEach((element) =>
        typeNames.add(element.type as SelectableElementType)
      );
      return Array.from(typeNames);
    }
  }));

export type ActionsStoreType = typeof ActionsStore.Type;
export type ElementWithContextModelType = typeof ElementWithContextModel.Type;
export default ActionsStore;
