import { types } from 'mobx-state-tree';

import { sortCreatedAsc } from 'utils/sort/created-at';
import sanitizeTag from 'utils/tags/sanitizeTag';
import TagModel, { TagModelType } from './TagModel';

export const TagListModel = types
  .model('TagListModel', {
    tags: types.array(TagModel)
  })
  .views(self => {
    return {
      get sortedStrings(): string[] {
        const tags: TagModelType[] = [];

        for (const tag of self.tags) {
          tags.push(tag);
        }

        tags.sort(sortCreatedAsc);

        return tags.map(tag => tag.tag);
      },
      get strings(): string[] {
        return self.tags.map(tag => tag.tag);
      },
      includes(tag?: string): boolean {
        if (!tag) {
          return false;
        }

        for (const elem of self.tags) {
          if (elem.tag === tag) {
            return true;
          }
        }

        return false;
      }
    };
  });

export const createTagList = (data: any): TagListModelType => {
  if (!data) {
    return TagListModel.create({
      tags: []
    });
  }

  if (data.tags) {
    // probably a TagListModel already
    data = data.tags;
  } else if (typeof data === 'string') {
    return TagListModel.create({
      tags: [
        {
          tag: data
        }
      ]
    });
  }

  const tags: TagModelType[] = [];
  for (const tag of data) {
    if (typeof tag === 'string') {
      tags.push({
        tag: sanitizeTag(tag, false),
        created_at: undefined
      });
    } else {
      if (tag.tag) {
        tags.push({
          tag: sanitizeTag(tag.tag.toString(), false),
          created_at: tag.created_at || undefined
        });
      } else {
        tags.push({
          tag: sanitizeTag(tag.toString(), false),
          created_at: undefined
        });
      }
    }
  }

  return TagListModel.create({
    tags
  });
};

export const tagsListToArray = (
  list?: TagListModelType
): string[] | undefined => (!list ? undefined : list.sortedStrings);

export const createServerTagList = (
  tags?: string[] | TagListModelType
): any[] => {
  if (!tags) {
    return [];
  }

  if (!Array.isArray(tags)) {
    tags = tags.strings;
  }

  if (!tags.length) {
    return [];
  }

  const list: any[] = [];
  for (const tag of tags) {
    list.push({ tag });
  }

  return list;
};

export type TagListModelType = typeof TagListModel.Type;
export default TagListModel;
