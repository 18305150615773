import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import NewSprintPopup from 'screens/assistant/NewSprintPopup';

interface OverlaysContainerProps {
  applicationStore?: ApplicationStoreType;
}

function OverlaysContainer(props: OverlaysContainerProps) {
  const { applicationStore } = props;

  return applicationStore?.showCreateSprintOverlay ? (
    <NewSprintPopup
      onCancel={applicationStore.toggleCreateSprintOverlay}
      onComplete={applicationStore.toggleCreateSprintOverlay}
    />
  ) : null;
}

export default inject('applicationStore')(observer(OverlaysContainer));
