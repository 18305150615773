import React from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from 'components/headline';
import Icon from 'components/Icon';
import SimplePopup from 'components/SimplePopup';

interface SessionTimerProps {
  seconds: number;
  visible?: boolean; // TODO remove when styled properly
}

interface SessionTimerState {
  time: number;
  running: boolean;
  popupVisible?: boolean;
}

export class SessionTimer extends React.Component<
  SessionTimerProps,
  SessionTimerState
> {
  timer: any;

  state: SessionTimerState = {
    time: -1,
    running: false
  };

  componentWillUnmount() {
    this.clear(true);
  }

  clear(skipState = false) {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }

    if (!skipState) {
      this.setState({
        running: false
      });
    }
  }

  tick() {
    const value = this.state.time - 1;

    let popupVisible = false;
    if (value < 1) {
      this.clear();
      popupVisible = true;
    }

    this.setState({
      time: value,
      popupVisible
    });
  }

  start(isRestart = false) {
    const value = this.state.time;

    if (!isRestart || value < 1) {
      this.setState({
        time: this.props.seconds,
        running: true
      });
    }

    this.clear();

    this.setState({
      running: true
    });

    this.timer = setInterval(() => this.tick(), 1000);
  }

  toggle() {
    if (!this.timer) {
      this.start(true);
    } else {
      this.clear();
    }
  }

  hidePopup() {
    this.setState({
      popupVisible: false
    });
  }

  timeInMinutes(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return (
      minutes.toString() + ':' + (seconds < 10 ? '0' : '') + seconds.toString()
    );
  }

  maybeRenderPopup() {
    if (!this.state.popupVisible) {
      return null;
    }

    return (
      <SimplePopup
        onAbort={() => this.hidePopup()}
        onSubmit={() => this.hidePopup()}
        submitTextId="OK"
        reduced={true}
      >
        <Headline>
          <FormattedMessage id="timer ended" />
        </Headline>
      </SimplePopup>
    );
  }

  render() {
    const { time, running } = this.state;
    const isOff = time < 0;

    return (
      <>
        {this.maybeRenderPopup()}

        <button
          className={isOff ? 'timer' : 'timer timer--running'}
          onClick={() => {
            isOff ? this.start() : this.toggle();
          }}
        >
          <Icon name={isOff ? 'timer-stop' : running ? 'pause' : 'play'} />
          <div className="timer__body">
            {isOff ? (
              <>
                <FormattedMessage
                  id="assistant timer label"
                  values={{ break: <br /> }}
                />
              </>
            ) : (
              <>{this.timeInMinutes(time)}</>
            )}
          </div>
        </button>
      </>
    );
  }
}

export default SessionTimer;
