import { types } from 'mobx-state-tree';

import ROLES from 'utils/constants/roles';

const values = [ROLES.DISROOPTIVE, ROLES.ADMIN, ROLES.USER, ROLES.VIEWER];

export const RolesEnumModel = types.enumeration(values);

export const createRolesEnumModel = (value?: any): RolesEnumModelType | undefined => {
  if (!value || !values.includes(value)) {
    return undefined;
  }

  return value;
};

export type RolesEnumModelType = typeof RolesEnumModel.Type;
export default RolesEnumModel;
