import { flow, getEnv, types } from 'mobx-state-tree';
import PageSwitchDescriptor from 'types/PageSwitchDescriptor';
import { describePageSwitch } from 'utils/misc/page-switch';
import sortDraftsFirst from 'utils/sort/drafts-first';
import sortByField from 'utils/sort/field';
import multiSort from 'utils/sort/multi-sort';
import sortByNumber from 'utils/sort/number';
import { sortPublishedAsc, sortPublishedDesc } from 'utils/sort/published-at';
import {
  reduceAuthors,
  ReducedAuthors,
  reduceTags
} from 'utils/store/filter-reducers';

import { AttachmentModelType } from './AttachmentModel';
import { BenchmarkModelType } from './BenchmarkModel';
import { createHypothesisModel, HypothesisModelType } from './HypothesisModel';
import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { ProjectsStoreType } from './ProjectsStore';
import { PublishStateEnumType } from './PublishStateEnum';
import { AdvancedStoreEnv } from './StoreEnv';
import { createServerTagList } from './TagListModel';

interface StoreEnv extends AdvancedStoreEnv {
  projectsStore: ProjectsStoreType;
}

const HypothesesStore = types
  .model('HypothesesStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const getHypotheses = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.listLoadingState = 'loading';
        dataStore.setHypothesesList(undefined);

        const result: any = yield client.getHypotheses(
          organizationId,
          projectId
        );

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.convertAndAddHypothesis(item);
          list.push(storeItem);
        }

        dataStore.setHypothesesList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('HypothesesStore | getHypotheses', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return undefined;
        }

        self.listLoadingState = 'load_error';
        return undefined;
      }
    });

    const getHypothesis = flow(function* (
      hypothesisId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setHypothesisItem(undefined);

        const result: any = yield client.getHypothesis(
          organizationId,
          projectId,
          hypothesisId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const hypothesis = dataStore.convertAndAddHypothesis(result, true);
        dataStore.setHypothesisItem(hypothesis);

        self.itemLoadingState = undefined;
        return hypothesis;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('HypothesesStore | getHypothesis', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createHypothesis = flow(function* (
      hypothesis: HypothesisModelType,
      addToList: boolean = false,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';
        dataStore.setHypothesisItem(undefined);

        const result: any = yield client.createHypothesis(
          organizationId,
          projectId,
          {
            ...hypothesis,
            tags_attributes: createServerTagList(hypothesis.tags),
            tags: undefined
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newHypothesis = dataStore.convertAndAddHypothesis(result, true);
        dataStore.setHypothesisItem(newHypothesis);

        if (addToList && newHypothesis) {
          dataStore.addToHypothesesList(newHypothesis);
        }

        self.itemLoadingState = undefined;
        return newHypothesis;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesesStore | createHypothesis',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const requestHypothesis = flow(function* (
      hypothesisId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.requestHypothesis(
          organizationId,
          projectId,
          hypothesisId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updateHypothesisFromCable(hypothesisId, result);

        // check if successful or requested by another user
        return (
          !!dataStore.currentUserId &&
          result.requesting_user?.id === dataStore.currentUserId
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesissStore | requestHypothesis',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }
    });

    const assignHypothesis = flow(function* (
      hypothesisId: number,
      invitationText: string,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore, projectsStore } =
        getEnv<StoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.assignHypothesis(
          organizationId,
          projectId,
          hypothesisId,
          invitationText
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updateHypothesisFromCable(hypothesisId, result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesissStore | assignHypothesis',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }

      try {
        yield projectsStore.getAssistantStats();
      } catch (error) {
        // can be ignored
      }
    });

    const declineHypothesis = flow(function* (
      hypothesisId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.declineHypothesis(
          organizationId,
          projectId,
          hypothesisId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.updateHypothesisFromCable(hypothesisId, result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesissStore | declineHypothesis',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error, false)) {
          return;
        }

        throw error;
      }
    });

    const updateHypothesis = flow(function* (
      hypothesisId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        const result: any = yield client.updateHypothesis(
          organizationId,
          projectId,
          hypothesisId,
          serverPatch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newHypothesis = dataStore.convertAndAddHypothesis(result, true);
        dataStore.setHypothesisItem(newHypothesis);

        self.itemLoadingState = undefined;
        return newHypothesis;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesesStore | updateHypothesis',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const deleteHypothesis = flow(function* (
      hypothesisId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'deleting';

        yield client.deleteHypothesis(organizationId, projectId, hypothesisId);

        dataStore.deleteHypothesis(hypothesisId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesesStore | deleteHypothesis',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteHypothesis(hypothesisId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const bulkDeleteHypotheses = flow(function* (
      hypothesisIds: number[],
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      self.listLoadingState = 'deleting';
      let deleted = 0;

      for (const hypothesisId of hypothesisIds) {
        try {
          yield deleteHypothesis(hypothesisId, organizationId, projectId);

          deleted++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error(
              'HypothesesStore | bulkDeleteHypotheses',
              error,
              error.body
            );
          }

          if (client.isNotFound(error)) {
            dataStore.deleteHypothesis(hypothesisId);
            deleted++;
          }
        }
      }

      self.listLoadingState = undefined;
      return deleted;
    });

    const newImageForHypothesis = flow(function* (
      hypothesisId: number,
      prompt: string,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);


      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        const result: any = yield client.generateNewHypothesesImage(
          organizationId,
          projectId,
          hypothesisId,
          prompt
        );

        if (!result) {
          throw new Error('No response from server');
        }

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'HypothesesStore | newImageForHypothesis',
            error,
            error.body
          );
        }

        if (error.body.error && error.body.error.prompt_error) {
          self.itemLoadingState = undefined;
          throw new Error(error.body.error.prompt_error);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }


        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const initializeItem = (data: any) => {
      // TODO how to handle painpoint here?
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const hypothesis = createHypothesisModel(data);

      dataStore.addHypothesis(hypothesis, true);
      dataStore.setHypothesisItem(
        dataStore.hypotheses.get(hypothesis.id.toString())
      );
    };

    const addAttachment = (attachment: AttachmentModelType) => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const { hypothesisItem } = dataStore;

      if (!hypothesisItem) {
        return;
      }

      dataStore.setHypothesisItem(createHypothesisModel({...hypothesisItem, attachment}));
    };

    const removeAttachment = () => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const { hypothesisItem } = dataStore;

      if (!hypothesisItem) {
        return;
      }

      dataStore.setHypothesisItem(createHypothesisModel({...hypothesisItem, attachment: undefined}));
    };

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    return {
      getHypotheses,
      getHypothesis,
      initializeItem,
      createHypothesis,
      requestHypothesis,
      assignHypothesis,
      declineHypothesis,
      updateHypothesis,
      deleteHypothesis,
      bulkDeleteHypotheses,
      addAttachment,
      removeAttachment,
      setFilter,
      newImageForHypothesis
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return (
          self.itemLoadingState === 'loading' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },

      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListDeleting(): boolean {
        return self.listLoadingState === 'deleting';
      },
      get isListBusy(): boolean {
        return this.isListLoading || this.isListDeleting;
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      get hasAny(): boolean {
        return dataStore.hypothesesList && dataStore.hypothesesList.length > 0
          ? true
          : false;
      },
      list(
        state?: PublishStateEnumType,
        authorId?: string,
        tag?: string,
        sort?: string | Function,
        filter?: string
      ): HypothesisModelType[] {
        if (!dataStore.hypothesesList || !dataStore.hypothesesList.length) {
          return [];
        }

        const authorIdNumber = authorId ? parseInt(authorId, 10) : undefined;

        let noFilter = !authorIdNumber && !state && !tag;
        const list: HypothesisModelType[] = [];

        const filterLowercase = filter?.toLowerCase() || undefined;

        for (const item of dataStore.hypothesesList.values()) {
          if (!item) {
            continue;
          }

          if (authorIdNumber && item.author?.id !== authorIdNumber) {
            continue;
          }

          if (state && item.publish_state !== state) {
            continue;
          }

          if (tag && (!item.tags || !item.tags.includes(tag))) {
            continue;
          }

          if (filterLowercase) {
            if (
              !item.headline ||
              item.headline.toLowerCase().indexOf(filterLowercase) < 0
            ) {
              continue;
            }
          }

          list.push(item);
        }

        let sortFunc;
        if (typeof sort === 'function') {
          sortFunc = sort;
        } else {
          switch (sort) {
            case 'newest':
              sortFunc = sortPublishedDesc;
              break;

            case 'oldest':
              sortFunc = sortPublishedAsc;
              break;

            default:
              if (sort) {
                noFilter = false;
                sortFunc = sortByNumber(sort as string, true);
              } else {
                sortFunc = sortPublishedDesc;
              }
          }
        }

        const sorts = [sortFunc, sortByField('headline')];

        if (noFilter) {
          sorts.unshift(sortDraftsFirst);
        }

        list.sort(multiSort(sorts));

        return list;
      },

      get itemBenchmarkIds(): number[] {
        const ids: number[] = [];

        if (
          !dataStore.hypothesisItemBenchmarks ||
          dataStore.hypothesisItemBenchmarks.length < 1
        ) {
          return ids;
        }

        for (const benchmark of dataStore.hypothesisItemBenchmarks.values()) {
          if (benchmark) {
            ids.push(benchmark.id);
          }
        }

        return ids;
      },

      get itemBenchmarks(): BenchmarkModelType[] {
        if (
          !dataStore.hypothesisItemBenchmarks ||
          dataStore.hypothesisItemBenchmarks.length < 1
        ) {
          return [];
        }

        const benchmarks: BenchmarkModelType[] = [];

        for (const benchmark of dataStore.hypothesisItemBenchmarks.values()) {
          if (benchmark) {
            benchmarks.push(benchmark);
          }
        }

        benchmarks.sort(sortByField('headline'));

        return benchmarks;
      },
      get listWithCurrentFilter(): HypothesisModelType[] {
        return this.list(
          self.filter.get('state'),
          self.filter.get('author'),
          self.filter.get('tag'),
          self.filter.get('sort')
        );
      },
      pageSwitch(current?: HypothesisModelType): PageSwitchDescriptor {
        return describePageSwitch<HypothesisModelType>(
          this.listWithCurrentFilter,
          current
        );
      },

      get tags(): string[] {
        return reduceTags(dataStore.hypothesesList);
      },
      get authors(): ReducedAuthors {
        return reduceAuthors(dataStore.hypothesesList);
      }
    };
  });

export type HypothesesStoreType = typeof HypothesesStore.Type;
export default HypothesesStore;
