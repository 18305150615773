import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';
import DestroyButton from 'components/DestroyButton';

interface RainbowCardCompactProps {
  children?: React.ReactNode;
  to?: LinkProps['to'];
  checked?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
  onRemoveClick?: () => void;
  src?: string;
  alt?: string;
  gray?: boolean;
  sideLabel?: string;
  logo?: React.ReactNode;
  title?: React.ReactNode;
  company?: React.ReactNode;
  simple?: boolean;
}

export const RainbowCardCompact = ({
  children,
  to,
  src,
  alt = 'screenshot',
  checked,
  onCheckboxChange,
  onRemoveClick,
  sideLabel,
  logo,
  title,
  company,
  gray,
  simple
}: RainbowCardCompactProps) => {
  const rainbowCardClasses = classNames('rainbow-card-compact', {
    'rainbow-card-compact--gray': gray,
    'rainbow-card-compact--with-image': !!src,
    'rainbow-card-compact--draft': !!sideLabel,
    'rainbow-card-compact--simple': !!simple,
    'rainbow-card-compact--company': !!company
  });

  const renderScreenInPhone = () => {
    if (!src) {
      return <div className="rainbow-card-compact__image-wrapper" />;
    }

    return (
      <div className="rainbow-card-compact__image-wrapper">
        <div className="rainbow-card-compact__image">
          <figure className="rainbow-card-compact__phone">
            <img alt={alt} src={src} />
          </figure>
        </div>
      </div>
    );
  };

  const cardLink = to ? (
    <Link className="rainbow-card-compact__link" to={to} />
  ) : null;

  const renderDraft = () => {
    if (!sideLabel) {
      return null;
    }
    return <div className="rainbow-card-compact__draft">{sideLabel}</div>;
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }
    return <div className="rainbow-card-compact__title">{title}</div>;
  };

  const renderCompany = () => {
    if (!company) {
      return null;
    }
    return <div className="rainbow-card-compact__company">{company}</div>;
  };

  const renderCheckbox = () => {
    if (!onCheckboxChange) {
      return null;
    }
    return (
      <div className="card-checkbox">
        <label>
          <input
            className="card-checkbox__box"
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
            type="checkbox"
          />
        </label>
      </div>
    );
  };

  return (
    <article className={rainbowCardClasses}>
      <div className="rainbow-card-compact__body">
        {renderCompany()}
        {renderScreenInPhone()}
        {logo}
        {onRemoveClick && (
          <div className="rainbow-card-compact__remove-button">
            <DestroyButton onClick={onRemoveClick} />
          </div>
        )}
        {renderTitle()}
        {children}
        {renderCheckbox()}
        {cardLink}
      </div>
      {renderDraft()}
    </article>
  );
};

export default RainbowCardCompact;
