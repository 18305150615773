import { FormattedMessage } from 'react-intl';

import CardHeader from 'components/CardHeader';
import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import MainButton from 'components/MainButton';
import RainbowCard from 'components/RainbowCard/RainbowCard';
import Typography from 'components/Typography';
import { TypographyProps } from 'components/Typography/Typography';
import { FormType } from 'utils/hooks/useForm';

interface EditableCardProps {
  editable?: boolean;
  form: FormType;
  name: string;
  value?: string;
  label?: React.ReactNode;
  extra?: React.ReactNode;
  saveOnBlur?: boolean;
  onSave: () => void;
  shrink?: boolean;
  textSize?: TypographyProps['size'];
  singleLine?: boolean;
  lines?: number;
  largeFont?: boolean;
  simple?: boolean;
}

export default ({
  editable,
  form,
  name,
  value,
  label,
  extra,
  saveOnBlur,
  onSave,
  shrink,
  textSize,
  singleLine,
  lines,
  largeFont,
  simple
}: EditableCardProps) => {
  const edit = () => {
    form.setField(name, value || '');
    form.setEditing(name);
  };
  const save = () => {
    if ((form.values[name] || '') !== (value || '')) {
      onSave();
    } else {
      form.setEditing();
    }
  };

  const Comp = singleLine && !lines ? Input : Textarea;

  return form.editing === name ? (
    <CardWrapper>
      {!saveOnBlur && <DestroyButton onClick={() => form.setEditing()} />}
      <ColumnWrapper gap="1em">
        <Comp
          label={label}
          name={name}
          {...form.bindInput(name)}
          autoFocus={true}
          onBlur={saveOnBlur ? save : undefined}
          onKeyUp={(e: any) => {
            if (e.keyCode === 27) {
              form.setEditing();
            }
          }}
          onKeyPress={
            lines
              ? (e: any) => {
                  if (e.charCode === 13) {
                    e.preventDefault();
                    save();
                  }
                }
              : undefined
          }
          lines={lines}
          largeFont={largeFont}
        />

        {!saveOnBlur && (
          // TODO Button style like with popups?
          <PositionWrapper end={true}>
            <MainButton type="button" onClick={save}>
              <FormattedMessage id="Save" />
            </MainButton>
          </PositionWrapper>
        )}
      </ColumnWrapper>
    </CardWrapper>
  ) : (
    <RainbowCard shrink={shrink}>
      <CardHeader
        title={label}
        single={true}
        simple={simple}
        onEditClick={editable ? edit : undefined}
      />

      <Typography size={textSize || 'small'} breaks={true}>
        {value || <FormattedMessage id="no content yet" />}
      </Typography>

      {extra}
    </RainbowCard>
  );
};
