import { inject, observer } from 'mobx-react';
import React from 'react';

import AppLayout from 'components/AppLayout';
import CompanyLogo from 'components/CompanyLogo';
import ContentWrapper from 'components/Layout/ContentWrapper';
import ContextAwareOrgLayout from 'components/OrgSettingsLayout/ContextAwareOrgLayout';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import { DataStoreType } from 'models/DataStore';
import { HistoryProps } from 'utils/history';
import OrgTargetGroupsContainer from 'containers/OrgTargetGroupsContainer';

// tslint:disable-next-line: no-empty-interface
interface PublicOrgTargetGroupsScreenProps {}

interface OrgTargetGroupsScreenProps extends PublicOrgTargetGroupsScreenProps {
  dataStore: DataStoreType;
}

@inject('dataStore', 'organizationsStore', 'applicationStore')
@observer
class OrgTargetGroupsScreen extends React.Component<
  OrgTargetGroupsScreenProps & HistoryProps
> {
  render() {
    const { dataStore } = this.props;
    const org = dataStore.currentOrganization;

    let header;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    return (
      <AppLayout active="settings" withSettings={true}>
        <ContextAwareOrgLayout active="target-groups">
          <PageHeader
            titleId="Target-Group-Header"
            logoHeader={header}
            center={true}
          />

          <ContentWrapper small={true}>
            <OrgTargetGroupsContainer />
          </ContentWrapper>
        </ContextAwareOrgLayout>
      </AppLayout>
    );
  }
}

export default (props: PublicOrgTargetGroupsScreenProps) => {
  // @ts-ignore
  return <OrgTargetGroupsScreen {...props} />;
};
