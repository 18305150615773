import { inject, observer } from 'mobx-react';
import React from 'react';

import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import CallToAction from 'components/CallToAction';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import ProjectWrapper from 'components/Layout/ProjectWrapper';
import { DataStoreType } from 'models/DataStore';
import { isActiveOrgLevel } from 'models/OrgAccessLevelEnumModel';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { ROUTE_AUTH, ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import OrganizationsTable from 'components/OrganizationsTableComponent/OrganizationsTableComponent';
import Icon from 'components/Icon';
import classNames from 'classnames';

interface LabScreenProps {
  dataStore: DataStoreType;
}

@inject('dataStore')
@observer
class LabScreen extends React.Component<
  LabScreenProps & HistoryProps & WrappedComponentProps
> {
  state: { filter: string } = { filter: '' };

  componentDidMount() {
    const {
      history,
      dataStore: { hasOwnOrgs, onlyOwnOrg, currentUserId, ownAgency }
    } = this.props;

    // redirect agencies to their own lab
    if (ownAgency) {
      history.replace(ROUTE_LAB + '/' + ownAgency.id);
      return;
    }

    if (
      !hasOwnOrgs ||
      (onlyOwnOrg && !isActiveOrgLevel(onlyOwnOrg.access_level))
    ) {
      // no orgs to show
      history.replace(ROUTE_AUTH + '/profile/' + currentUserId + '/settings');
      return;
    }

    if (onlyOwnOrg) {
      // will redirect to org, so do not show anything
      history.replace(ROUTE_LAB + '/' + onlyOwnOrg.id);
    }
  }

  render() {
    const { dataStore, history, intl } = this.props;

    if (dataStore.organizations.size < 2) {
      return null;
    }

    const sorted = dataStore.sortedOwnOrgs;
    return (
      <AppLayoutReduced active="lab">
        <PageHeader reduced={true} title="Organization" />
        <ProjectWrapper>
          <ContentWrapper>
            <form
              className={classNames({
                'search-form search-screen__search-form': true,
                'search-form__search-bar': true
              })}
              onChange={(e) =>
                this.setState({ filter: (e.target as HTMLInputElement).value })
              }
            >
              <div className="search-form__fieldset">
                <span className="search-form__input-icon">
                  <Icon name="magnify" />
                </span>
                <input
                  className="search-form__input"
                  type="text"
                  name="filter"
                  autoFocus={true}
                  placeholder={intl.formatMessage({ id: 'Customer search' })}
                />
              </div>
            </form>
            <OrganizationsTable
              organizations={sorted}
              searchTerm={this.state.filter}
              history={history}
              showMenu={true}
            />
          </ContentWrapper>
        </ProjectWrapper>
        <CallToAction />
      </AppLayoutReduced>
    );
  }
}

export default injectIntl((props: LabScreenProps & WrappedComponentProps) => {
  return (
    // @ts-ignore
    <LabScreen {...props} />
  );
});
