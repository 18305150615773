import { Consumer, createConsumer } from '@rails/actioncable';
import React from 'react';

import config from 'config';

// tslint:disable: no-console
const LOGGING_ENABLED = !config.env.production;

export type CableChannelType = 'PainpointChannel' | 'HypothesisChannel' | 'BenchmarkChannel' | 'ProjectChannel';
export type CableElementType = 'painpoint' | 'cluster' | 'hypothesis' | 'benchmark' | 'prototype' | 'gptRequestCounter';

interface ActionCableComponentProps {
  channel: CableChannelType;
  projectId: number;
  onUpdated?: (elementType: CableElementType, element: any) => void;
  onDeleted?: (elementType: CableElementType, elementId: number) => void;
}

interface ActionCableComponentState {
  connectionState?: 'connected' | 'disconnected';
}

class ActionCableComponent extends React.Component<
  ActionCableComponentProps,
  ActionCableComponentState
> {
  state: ActionCableComponentState = {};

  private consumer?: Consumer;

  componentDidMount() {
    if (LOGGING_ENABLED) {
      console.log('Cable didMount');
    }

    this.connect();
  }

  componentWillUnmount() {
    if (LOGGING_ENABLED) {
      console.log('Cable willUnmount');
    }

    this.disconnect();
  }

  private connect() {
    this.consumer = createConsumer(config.cableUrl);

    this.consumer.subscriptions.create(
      {
        channel: this.props.channel,
        project_id: this.props.projectId
      },
      {
        connected: () => {
          // Called when the subscription is ready for use on the server
          if (LOGGING_ENABLED) {
            console.log('Cable connected');
          }

          this.setState({
            connectionState: 'connected'
          });
        },

        disconnected: () => {
          // Called when the subscription has been terminated by the server
          if (LOGGING_ENABLED) {
            console.log('Cable disconnected');
          }

          this.setState({
            connectionState: 'disconnected'
          });
        },

        received: data => this.handleData(data)
      }
    );
  }

  private disconnect() {
    this.consumer?.disconnect();
  }

  private handleData(data: any) {
    const { onUpdated, onDeleted } = this.props;

    if (LOGGING_ENABLED) {
      console.log('Cable received:', data);
    }

    switch (data?.action) {
      case 'update':
        if (
          !onUpdated ||
          !data?.element_type ||
          typeof data.element?.id !== 'number'
        ) {
          return;
        }

        onUpdated(data.element_type, data.element);
        return;

      case 'delete':
        if (
          !onDeleted ||
          !data?.element_type ||
          typeof data.element_id !== 'number'
        ) {
          return;
        }

        onDeleted(data.element_type, data.element_id);
        return;

      default:
    }
  }

  render() {
    return null;
  }
}

export default ActionCableComponent;
