import TextSpinner from './TextSpinner';

export default function ColumnLoading() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: '2em 0',
        background: 'rgb(0 0 0 / 0.6)',
        backdropFilter: 'blur(1px)',
        display: 'flex',
        borderRadius: '0.625em',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 20
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#31324a',
          width: '90%',
          borderRadius: '0.65em',
          height: '15em',
          fontSize: '1em',
          lineHeight: '1.5',
          fontWeight: '800',
          fontStyle: 'italic',
          textTransform: 'uppercase',
          color: '#bebebe',
          padding: '2em',
          rowGap: '1em',
          textAlign: 'center'
        }}
      >
        <div style={{ height: '7em' }}>
          <TextSpinner />
        </div>
        <img
          src="/images/robot.gif"
          alt=""
          style={{
            width: 'auto',
            height: '5em'
          }}
        />
      </div>
    </div>
  );
}
