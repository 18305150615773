import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { AssistantStoreType } from 'models/AssistantStore';
import { HistoryProps } from 'utils/history';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { DataStoreType } from 'models/DataStore';

interface AssistantPrototypeContainerProps {
  dataStore?: DataStoreType;
  assistantStore?: AssistantStoreType;
  hypothesesStore?: HypothesesStoreType;
  painpointsStore?: PainpointsStoreType;
  children?: React.ReactNode;
}

function AssistantPrototypeContainer({
  dataStore,
  assistantStore,
  hypothesesStore,
  painpointsStore,
  location,
  children
}: AssistantPrototypeContainerProps & HistoryProps) {
  // as we want to jump in the sidebar all the time, we need the informations for each step all the time.

  // load hypothesis
  useEffect(() => {
    const fetchHypothesis = async () => {
      let hypothesisId: number | undefined = undefined;
      if (
        assistantStore!.currentItem &&
        assistantStore!.currentItem.hypothesis_id
      ) {
        hypothesisId = assistantStore!.currentItem?.hypothesis_id;
      } else if (location?.query?.hypothesis) {
        hypothesisId = parseInt(location?.query?.hypothesis, 10);
      }
      if (hypothesisId) {
        await hypothesesStore!.getHypothesis(
          hypothesisId,
          assistantStore?.currentItem?.project?.organization_id,
          assistantStore?.currentItem?.project?.id
        );
      }
    };
    fetchHypothesis();
    // load painpoints
    if (location.query?.painpoint) {
      const painpointId = location.query?.painpoint;
      painpointsStore!.getPainpoint(
        painpointId,
        assistantStore!.currentItem?.project?.organization_id,
        assistantStore!.currentItem?.project?.id
      );
    }
  }, [assistantStore, hypothesesStore, location]);

  return <>{children}</>;
}

export default inject(
  'dataStore',
  'assistantStore',
  'hypothesesStore',
  'painpointsStore'
)(observer(AssistantPrototypeContainer));
