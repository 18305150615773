import { inject, observer } from 'mobx-react';
import { injectIntl, useIntl } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

import { WrappedComponentProps } from 'react-intl';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import OrgTargetGroupItem from './OrgTargetGroupItem';
import AddButton from 'components/AddButton';
import CardGrid from 'components/CardGrid';
import { useEffect, useState } from 'react';
import OrgTargetGroupItemEditPopup from './OrgTargetGroupItemEditPopup';
import OrgTargetGroupModel from 'models/OrgTargetGroupModel';

interface PublicOrgTargetGroupsContainerProps extends WrappedComponentProps {}

interface OrgTargetGroupsContainerProps
  extends PublicOrgTargetGroupsContainerProps {
  form: FormType;
  organizationsStore?: OrganizationsStoreType;
  dataStore?: DataStoreType;
  applicationStore?: ApplicationStoreType;
}

const OrgTargetGroupsContainer = observer(
  (props: OrgTargetGroupsContainerProps) => {
    const [isAdding, setIsAdding] = useState(false);
    useEffect(() => {
      props.organizationsStore!.getTargetGroups();
    }, [props.organizationsStore]);

    const intl = useIntl();

    return (
      <CardGrid
        title={intl.formatMessage({ id: 'Target groups' })}
        noPadding={true}
        twoColumns={true}
      >
        {[...props.dataStore!.orgTargetGroups.values()].map((targetGroup) => (
          <OrgTargetGroupItem key={targetGroup.id} targetGroup={targetGroup} />
        ))}
        {isAdding && (
          <OrgTargetGroupItemEditPopup
            isAdding={isAdding}
            onEditDone={() => setIsAdding(false)}
            targetGroup={OrgTargetGroupModel.create({
              id: -1,
              name: undefined,
              content: undefined
            })}
          />
        )}
        <AddButton
          iconName="plus"
          label={intl.formatMessage({ id: 'Add Target Group' })}
          style={{ height: 'revert-layer', minWidth: '100%' }}
          onClick={() => {
            setIsAdding(true);
          }}
        />
      </CardGrid>
    );
  }
);

export default injectIntl(
  inject(
    'organizationsStore',
    'dataStore',
    'applicationStore'
  )(
    observer((props: PublicOrgTargetGroupsContainerProps) => {
      const form = useForm();
      return <OrgTargetGroupsContainer {...props} form={form} />;
    })
  )
);
