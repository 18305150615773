import { types } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import {
  AttachmentsMapModel,
  createAttachmentsMap,
  createFluidAttachmentsList,
  FluidAttachmentsListModel
} from './AttachmentModel';
import RolesEnumModel, { createRolesEnumModel } from './RolesEnumModel';

const views = (_self: any) => {
  const self = _self as UserModelType;

  return {
    get avatar() {
      return self.attachments?.firstOfType('avatar');
    },
    get smallAvatar() {
      return self.avatar?.resource_urls?.small;
    },

    get initials() {
      if (self.first_name && self.last_name) {
        return (
          self.first_name.substring(0, 1) + self.last_name.substring(0, 1)
        ).toUpperCase();
      }

      if (self.first_name) {
        return self.first_name.substring(0, 2).toUpperCase();
      }

      if (self.last_name) {
        return self.last_name.substring(0, 2).toUpperCase();
      }

      return '??';
    }
  };
};

export const UserModel = types
  .model('UserModel', {
    id: types.identifierNumber,
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    fullName: types.maybe(types.string),
    email: types.maybe(types.string),
    unverified_email: types.maybe(types.string),
    pending: types.maybe(types.boolean),
    role: types.maybe(RolesEnumModel),
    agent: types.boolean,
    // attachment: types.maybe(AttachmentModel),
    attachments: AttachmentsMapModel
  })
  .views(views);

export const createUserModel = (data?: any): UserModelType => {
  return UserModel.create({
    id: mandatoryId(data?.id),
    first_name: data?.first_name || undefined,
    last_name: data?.last_name || undefined,
    fullName: data?.full_name || undefined,
    email: data?.email || undefined,
    unverified_email: data?.unverified_email || undefined,
    pending: booleanOrUndefined(data?.pending),
    role: createRolesEnumModel(data?.role),
    agent: data?.agent || false,
    attachments: createAttachmentsMap(data?.attachments)
  });
};

export const FluidUserModel = types
  .model('FluidUserModel', {
    id: types.maybe(types.number),
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    fullName: types.maybe(types.string),
    email: types.maybe(types.string),
    unverified_email: types.maybe(types.string),
    pending: types.maybe(types.boolean),
    role: types.maybe(RolesEnumModel),
    agent: types.boolean,
    attachments: FluidAttachmentsListModel
  })
  .views(views);

export type FluidUserModelType = typeof FluidUserModel.Type;

export const createFluidUserModel = (
  data?: any
): FluidUserModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return FluidUserModel.create({
    id: numberOrUndefined(data?.id),
    first_name: data?.first_name || undefined,
    last_name: data?.last_name || undefined,
    fullName: data?.full_name || undefined,
    email: data?.email || undefined,
    unverified_email: data?.unverified_email || undefined,
    pending: booleanOrUndefined(data?.pending),
    role: createRolesEnumModel(data?.role),
    agent: data?.agent || false,
    attachments: createFluidAttachmentsList(data?.attachments)
  });
};

export type UserModelType = typeof UserModel.Type;
export type AnyUserModelType = UserModelType | FluidUserModelType;
export default UserModel;
