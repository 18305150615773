import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import CardHeader from 'components/CardHeader';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import RainbowCard from 'components/RainbowCard/RainbowCard';
import TagList from 'components/TagList';
import Typography from 'components/Typography';
import PrototypeScreensContainer from 'containers/PrototypeScreensContainer';
import SharedContentDetailContainer from 'containers/SharedContentContainer/SharedContentDetailContainer';
import { SharedContentStoreType } from 'models/SharedContentStore';
import { HistoryProps, idFromMatch } from 'utils/history';

// tslint:disable: jsx-wrap-multiline

interface SharedPrototypeScreenProps extends WrappedComponentProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedPrototypeScreen extends React.Component<
  SharedPrototypeScreenProps & HistoryProps
> {
  renderNotFound() {
    return (
      <SharedContentDetailContainer notFound={true} itemType="prototypes" />
    );
  }

  render() {
    const { sharedContentStore, intl } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return this.renderNotFound();
    }

    const item = sharedContentStore.prototypes?.get(id?.toString());
    if (!item) {
      return this.renderNotFound();
    }

    return (
      <SharedContentDetailContainer itemType="prototypes">
        <PageHeader
          titleId="Prototypes"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
            />
          }
        />

        <ContentWrapper>
          <TagList tags={item.tags} />

          <ColumnWrapper gap="4em">
            <PrototypeScreensContainer prototype={item} editable={false} />

            <RainbowCard>
              <CardHeader
                title={<FormattedMessage id="prototype short description" />}
                single={true}
              />

              <Typography size="small" breaks={true}>
                {item.flow_description || (
                  <FormattedMessage id="no content yet" />
                )}
              </Typography>
            </RainbowCard>
          </ColumnWrapper>
        </ContentWrapper>
      </SharedContentDetailContainer>
    );
  }
}

export default injectIntl(SharedPrototypeScreen);
