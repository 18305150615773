
import Icon from 'components/Icon';

interface MoveButtonProps {
  label?: string;
  iconName?: string;
  right?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function MoveButton({
  label,
  iconName,
  onClick,
  right
}: MoveButtonProps) {
  return (
    <button
      type="button"
      className="move-button"
      aria-label={label || 'Move'}
      onClick={onClick}
    >
      {right ? (
        <Icon name={iconName || 'chevron-right'} />
      ) : (
        <Icon name={iconName || 'chevron-left'} />
      )}
    </button>
  );
}
