import React, { useEffect } from 'react';
import OverlayWrapper from 'components/Layout/OverlayWrapper/OverlayWrapper';
import { inject, observer } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { FormattedMessage } from 'react-intl';

interface AiLoadingProps {
  dataStore?: DataStoreType;
}

function AiLoading({ dataStore }: AiLoadingProps) {
  const main = document.getElementsByTagName('main')[0];
  const oldOverflow = main.style.overflow;
  useEffect(() => {
    main.style.overflow = 'hidden';

    return () => {
      // This function runs when the component unmounts
      document.getElementsByTagName('main')[0].style.overflow = 'auto';
    };
  }, []);

  return (
    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', zIndex:'1001' }}>
      <OverlayWrapper
        disabled={true}
        background="dark"
        center={true}
        reduced={true}
        absolute={true}
      >
        <article className="share-popup">
          <header className="share-popup__header">
            <h1 className="share-popup__headline">
              <FormattedMessage id="AI request started" />
            </h1>
          </header>

          <div
            className="share-popup__body share-popup__body--centered"
            style={{ '--min-height': '19em' } as React.CSSProperties}
          >
            <>
              <div className="share-popup__description">
                <FormattedMessage id="AI wait" />:
              </div>
              <img
                src="/images/robot.gif"
                alt=""
                style={{
                  width: '30%',
                  height: 'auto'
                }}
              />

              <button
                className="main-button"
                onClick={() => {
                  dataStore!.project?.setAiAtWork(false);
                  document.getElementsByTagName('main')[0].style.overflow = oldOverflow;
                }}
              >
                <FormattedMessage id="AI ignore" />
              </button>
            </>
          </div>
        </article>
      </OverlayWrapper>
    </div>
  );
}

export default inject('dataStore')(observer(AiLoading));
