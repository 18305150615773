
interface GridCardProps {
  children: React.ReactNode;
  hasGradient?: boolean;
}

export default function GridCard({ children, hasGradient }: GridCardProps) {
  return (
    <div className={`grid-card${hasGradient ? ' grid-card--gradient' : ''}`}>
      {children}
    </div>
  );
}
