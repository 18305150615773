import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';

import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import SettingsTable from 'components/SettingsTable';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import ToggleSwitch from 'components/ToggleSwitch';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';

import CardHeader from 'components/CardHeader';
import Select from 'components/Inputs/Select';
import { ProjectLanguageType } from 'models/ProjectLanguageEnum';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface ProjectSettingsProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  projectsStore: ProjectsStoreType;
  form: FormType;
}

@inject('applicationStore', 'dataStore', 'projectsStore')
@observer
class ProjectSettingsScreen extends React.Component<
  ProjectSettingsProps & WrappedComponentProps
> {
  componentDidMount() {
    this.getSettings().forEach((setting) =>
      this.props.form.setField(setting.propertyName, setting.state)
    );

    this.getNetworkSettings().forEach((setting) =>
      this.props.form.setField(setting.propertyName, setting.state)
    );

    this.props.form.setField(
      'name',
      this.props.dataStore.currentProject?.topic
    );
    this.props.form.setField(
      'private',
      this.props.dataStore.currentProject?.private
    );
    this.props.form.setField(
      'language',
      this.props.dataStore.currentProject?.language
    );
  }

  async save() {
    const { dataStore, projectsStore, form } = this.props;

    form.resetErrors();

    const patch: any = {
      painpoints_state: form.values.painpoints_state ? 'on' : 'off',
      benchmarks_state: form.values.benchmarks_state ? 'on' : 'off',
      hypotheses_state: form.values.hypotheses_state ? 'on' : 'off',
      prototypes_state: form.values.prototypes_state ? 'on' : 'off',
      learnings_state: form.values.learnings_state ? 'on' : 'off',

      network_project_state: form.values.network_project_state ? 'on' : 'off',
      network_painpoints_state: form.values.network_painpoints_state
        ? 'on'
        : 'off',
      network_benchmarks_state: form.values.network_benchmarks_state
        ? 'on'
        : 'off',
      network_hypotheses_state: form.values.network_hypotheses_state
        ? 'on'
        : 'off',
      network_prototypes_state: form.values.network_prototypes_state
        ? 'on'
        : 'off',
      network_learnings_state: form.values.network_learnings_state
        ? 'on'
        : 'off',

      name: form.values.name,
      private: form.values.private,
      language: form.values.language
    };

    try {
      await projectsStore.updateProject(
        dataStore.currentProject!.id,
        patch,
        dataStore.currentOrganizationId
      );
    } catch (error) {
      if (handleFormError(form, error)) {
        return;
      }
    }

    if (projectsStore.isItemSaveError) {
      this.displayError();
      return;
    }

    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: 'save success flash' })
    );
  }

  getSettings() {
    const { currentProject } = this.props.dataStore;
    if (!currentProject) {
      return [];
    }

    const fm = (id: string) => this.props.intl.formatMessage({ id });

    return [
      {
        description: fm('Painpoints'),
        propertyName: 'painpoints_state',
        state: currentProject.painpoints_state === 'on'
      },
      {
        description: fm('Benchmarks'),
        propertyName: 'benchmarks_state',
        state: currentProject.benchmarks_state === 'on'
      },
      {
        description: fm('Hypotheses'),
        propertyName: 'hypotheses_state',
        state: currentProject.hypotheses_state === 'on'
      },
      {
        description: fm('Prototypes'),
        propertyName: 'prototypes_state',
        state: currentProject.prototypes_state === 'on'
      },
      {
        description: fm('Learnings'),
        propertyName: 'learnings_state',
        state: currentProject.learnings_state === 'on'
      }
    ];
  }

  getNetworkSettings() {
    const { currentProject } = this.props.dataStore;
    if (!currentProject) {
      return [];
    }

    const fm = (id: string) => this.props.intl.formatMessage({ id });

    return [
      {
        description: fm('project and briefing'),
        propertyName: 'network_project_state',
        state: currentProject.network_project_state === 'on'
      },
      {
        description: fm('Painpoints'),
        propertyName: 'network_painpoints_state',
        state: currentProject.network_painpoints_state === 'on'
      },
      {
        description: fm('Benchmarks'),
        propertyName: 'network_benchmarks_state',
        state: currentProject.network_benchmarks_state === 'on'
      },
      {
        description: fm('Hypotheses'),
        propertyName: 'network_hypotheses_state',
        state: currentProject.network_hypotheses_state === 'on'
      },
      {
        description: fm('Prototypes'),
        propertyName: 'network_prototypes_state',
        state: currentProject.network_prototypes_state === 'on'
      },
      {
        description: fm('Learnings'),
        propertyName: 'network_learnings_state',
        state: currentProject.network_learnings_state === 'on'
      }
    ];
  }

  displayError() {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: 'save error flash' }),
      'error'
    );
  }

  render() {
    const { form, projectsStore, dataStore, intl } = this.props;

    const project = dataStore.currentProject;

    if (!project) {
      return null;
    }

    const organization = project.organization;

    const baseUrl = dataStore.contextUri;
    return (
      <ContextAwareProjectLayout active="settings">
        <PageHeader titleId="Settings" />

        {projectsStore.isItemLoading && <Loading />}

        <ContentWrapper small={true}>
          <TabList small={true}>
            <TabListButton
              title={<FormattedMessage id="Sharing" />}
              to={baseUrl + '/settings'}
              active={true}
            />
            <TabListButton
              title={<FormattedMessage id="Members" />}
              to={baseUrl + '/members'}
            />
          </TabList>

          <Form onSubmit={() => this.save()}>
            <ColumnWrapper gap="3em">
              <Input
                type="text"
                gap={true}
                name="name"
                label={<FormattedMessage id="Project name" />}
                {...form.bindInput('name')}
              />
              <ColumnWrapper gap="2em">
                <CardHeader
                  title={<FormattedMessage id="Language" />}
                  single={true}
                />

                <Select
                  name="language"
                  value={dataStore.currentProject?.language || 'deutsch'}
                  {...form.bindInput('language')}
                >
                  {Object.values(ProjectLanguageType).map((lang) => (
                    <option key={lang} value={lang}>
                      {lang[0].toLocaleUpperCase() + lang.slice(1)}
                    </option>
                  ))}
                </Select>
                <SettingsTable
                  title={<FormattedMessage id="global visibility header" />}
                  headers={[intl.formatMessage({ id: 'Visibility' })]}
                  rows={[
                    {
                      description: intl.formatMessage({
                        id: 'global visibility members'
                      }),
                      data: (
                        <ToggleSwitch
                          label={<FormattedMessage id="Project private" />}
                          {...form.bindCheckbox('private')}
                        />
                      )
                    }
                  ]}
                />
                <SettingsTable
                  title={<FormattedMessage id="feature visibility header" />}
                  headers={[intl.formatMessage({ id: 'Visibility' })]}
                  rows={this.getSettings().map(
                    ({ description, propertyName }) => ({
                      description,
                      data: (
                        <ToggleSwitch
                          name={propertyName}
                          label={description}
                          {...form.bindCheckbox(propertyName)}
                        />
                      )
                    })
                  )}
                />

                {organization && organization.network_enabled && (
                  <SettingsTable
                    title={
                      <FormattedMessage id="feature network visibility header" />
                    }
                    headers={[intl.formatMessage({ id: 'Visibility' })]}
                    rows={this.getNetworkSettings().map(
                      ({ description, propertyName }) => ({
                        description,
                        data: (
                          <ToggleSwitch
                            name={propertyName}
                            label={description}
                            disabled={
                              propertyName !== 'network_project_state' &&
                              !form.values.network_project_state
                            }
                            {...form.bindCheckbox(propertyName)}
                            checked={
                              form.values.network_project_state &&
                              form.values[propertyName]
                                ? true
                                : false
                            }
                          />
                        )
                      })
                    )}
                  />
                )}
              </ColumnWrapper>
              <PositionWrapper center={true} gap="2em">
                <MainButton type="submit">
                  <FormattedMessage id="Save changes" />
                </MainButton>
              </PositionWrapper>
            </ColumnWrapper>
          </Form>
        </ContentWrapper>
      </ContextAwareProjectLayout>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <ProjectSettingsScreen {...props} form={form} />;
});
