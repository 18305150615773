import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import SimplePopup from 'components/SimplePopup';
import { inject, observer } from 'mobx-react';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { OrgTargetGroupModelType } from 'models/OrgTargetGroupModel';
import { useEffect } from 'react';
import { WrappedComponentProps, useIntl } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface OrgTargetGroupItemEditPopupProps {
  targetGroup: OrgTargetGroupModelType;
  onEditDone: () => void;
  isAdding?: boolean;
}

interface OrgTargetGroupItemEditWrappedPopupProps
  extends OrgTargetGroupItemEditPopupProps,
    WrappedComponentProps {
  form?: FormType;
  organizationsStore?: OrganizationsStoreType;
}

function OrgTargetGroupItemEditPopup({
  targetGroup,
  form,
  organizationsStore,
  onEditDone,
  isAdding
}: OrgTargetGroupItemEditWrappedPopupProps) {
  const intl = useIntl();
  useEffect(() => {
    form!.reset();
    form!.setField('name', targetGroup!.name);
    form!.setField('content', targetGroup!.content);
  }, []);

  const save = async () => {
    if (!targetGroup) {
      return;
    }

    form!.setLoading(true);

    try {
      if (isAdding) {
        await organizationsStore!.createTargetGroup(
          targetGroup.organization_id,
          {
            name: form!.values.name,
            content: form!.values.content
          }
        );
      } else {
        await organizationsStore!.updateTargetGroup({
          organizationId: targetGroup.organization_id,
          targetGroupId: targetGroup.id,
          patch: {
            name: form!.values.name,
            content: form!.values.content
          }
        });
      }

      onEditDone();
    } catch (e) {
      // TODO what to do here?
    }

    form!.setLoading(false);
  };

  return (
    <SimplePopup
      onSubmit={() => save()}
      onAbort={() => onEditDone()}
      isLoading={form!.loading}
      noPadding={true}
    >
      <Input
        type="text"
        name="name"
        normalFont={true}
        placeholder={intl.formatMessage({ id: 'Target Group Add Name' })}
        {...form!.bindInput('name')}
        disabled={form!.loading}
      />
      <div className="l-screen-item__description l-screen-item__description--auto">
        <Textarea
          name="content"
          normalFont={true}
          placeholder={intl.formatMessage({ id: 'Target Group Add Content' })}
          lines={8}
          {...form!.bindInput('content')}
        />
      </div>
    </SimplePopup>
  );
}

export default inject('organizationsStore')(
  observer((props: OrgTargetGroupItemEditPopupProps) => {
    const form = useForm();
    // @ts-ignore
    return <OrgTargetGroupItemEditPopup {...props} form={form} />;
  })
);
