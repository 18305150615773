import { FormattedMessage } from 'react-intl';
import { LinkProps } from 'react-router-dom';

import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import SidebarItem from '../SidebarItem';
import SidebarItemContent from '../SidebarItemContent';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

export function generateBenchmarkLink(
  baseLink: string,
  benchmarkId: number,
  currentPathWithSearch?: string
): LinkProps['to'] {
  if (!currentPathWithSearch) {
    return baseLink + '/benchmark/' + benchmarkId;
  }

  // tslint:disable-next-line: prefer-const
  let [pathname, search] = currentPathWithSearch.toString().split('?', 2);

  if (typeof search === 'string' && search !== '') {
    if (search.indexOf('benchmark=') > -1) {
      // we are on benchmark view
      search = search.replace(/benchmark=[0-9]+/, 'benchmark=' + benchmarkId);
    } else {
      // we are on other view
      search = search + '&benchmark=' + benchmarkId;
    }
  } else {
    search = 'benchmark=' + benchmarkId;
  }

  return {
    pathname,
    search,
    state: {
      canGoBack: true
    }
  };
}

export const SidebarBenchmarks = ({
  session,
  showHeader,
  currentPathWithSearch
}: SidebarItemProps & { currentPathWithSearch?: string }) => {
  const benchmarks = session?.sortedBenchmarks;

  if (!benchmarks?.length) {
    if (showHeader) {
      return (
        <SidebarMarker type="benchmarks">
          <SidebarItem>
            <SidebarItemHeader>
              <FormattedMessage id="assistant sidebar benchmarks" />
            </SidebarItemHeader>
          </SidebarItem>
        </SidebarMarker>
      );
    }

    return <SidebarMarker type="benchmarks" />;
  }

  const baseLink = ROUTE_ASSISTANT + '/' + session!.id + '/benchmark/';

  return (
    <SidebarMarker type="benchmarks">
      <SidebarItem>
        <SidebarItemHeader date={session!.benchmarks_date}>
          <FormattedMessage id="assistant sidebar benchmarks" />
        </SidebarItemHeader>

        {benchmarks.map((benchmark) => {
          const attachment = benchmark.attachments.firstOfType('screen');
          const logo = benchmark.attachments.firstOfType('logo');

          return (
            <SidebarItemContent key={benchmark.id}>
              <RainbowCardCompact
                to={generateBenchmarkLink(
                  baseLink,
                  benchmark.id,
                  currentPathWithSearch
                )}
                simple={true}
                company={benchmark.company_name}
                title={benchmark.headline}
                src={attachment?.resource_urls?.small}
                logo={
                  logo?.resource_urls?.small && (
                    <CompanyLogo imgUrl={logo.resource_urls.small} alt="Logo" />
                  )
                }
              />
            </SidebarItemContent>
          );
        })}
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarBenchmarks;
