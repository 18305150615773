import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_PAINPOINTS } from 'utils/constants/routes';
import PainpointCreateScreen from './PainpointCreateScreen';
import PainpointDetailScreen from './PainpointDetailScreen';
import PainpointEditScreen from './PainpointEditScreen';
import PainpointsListScreen from './PainpointsListScreen';

export default () => (
  <ContextAwareProjectLayout active="painpoints">
    <Switch>
      <Route
        path={ROUTE_PAINPOINTS}
        exact={true}
        component={PainpointsListScreen}
      />
      <Route
        path={ROUTE_PAINPOINTS + '/create'}
        exact={true}
        component={PainpointCreateScreen}
      />
      <Route
        path={ROUTE_PAINPOINTS + '/:id'}
        exact={true}
        component={PainpointDetailScreen}
      />
      <Route
        path={ROUTE_PAINPOINTS + '/:id/edit'}
        exact={true}
        component={PainpointEditScreen}
      />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
