import { FormattedMessage } from 'react-intl';

import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import SidebarItem from '../SidebarItem';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import SidebarSolutionItem from '../SidebarSolutionItem';
import { SidebarItemProps } from './item-types';

export const SidebarSolutions = ({ session, showHeader }: SidebarItemProps) => {
  const solutions = session?.filteredSolutions(true);

  if (!solutions?.length) {
    if (showHeader) {
      return (
        <SidebarMarker type="solutions">
          <SidebarItem>
            <SidebarItemHeader>
              <FormattedMessage id="assistant sidebar solutions" />
            </SidebarItemHeader>
          </SidebarItem>
        </SidebarMarker>
      );
    }

    return <SidebarMarker type="solutions" />;
  }

  const baseLink =
    ROUTE_ASSISTANT +
    '/' +
    session!.id +
    ASSISTANTS.creative_session.step3_2.route;

  return (
    <SidebarMarker type="solutions">
      <SidebarItem>
        <SidebarItemHeader date={session!.solutionsDate}>
          <FormattedMessage id="assistant sidebar solutions" />
        </SidebarItemHeader>

        {solutions.map((solution, idx) => {
          return (
            <SidebarSolutionItem
              key={solution.id}
              solution={solution}
              index={idx}
              benchmark={
                !solution.benchmark_id
                  ? undefined
                  : session?.benchmarkById(solution.benchmark_id)
              }
              to={{
                pathname: baseLink,
                search: '?solution=' + (idx + 1),
                state: { canGoBack: true }
              }}
            />
          );
        })}
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarSolutions;
