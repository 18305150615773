import classNames from 'classnames';
import { FormEvent } from 'react';

import Loading from 'components/Loading';

export interface FormProps {
  loading?: boolean;
  disabled?: boolean;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
  space?: boolean;
}

// TODO proper loading state
export const Form = ({
  onSubmit,
  children,
  loading,
  disabled,
  space
}: FormProps) => {
  const formClasses = classNames('form', {
    'form--space': space
  });

  return (
    <form
      className={formClasses}
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (onSubmit) {
          onSubmit(e);
        }
      }}
    >
      {loading && <Loading />}
      <fieldset className="form__fieldset" disabled={disabled || loading}>
        {children}
      </fieldset>
    </form>
  );
};

export default Form;
