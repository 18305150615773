import DisrooptiveApi from 'api/DisrooptiveApi';
import { flow, getEnv, types } from 'mobx-state-tree';

import { ApplicationStoreType } from './ApplicationStore';
import { AttachmentModelType } from './AttachmentModel';
import { createBriefingModel } from './BriefingModel';
import { DataStoreType } from './DataStore';
import { ItemLoadingStateEnum } from './LoadingStateEnums';
import { createServerTagList } from './TagListModel';

interface BriefingStoreEnvType {
  client: DisrooptiveApi;
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
}

const BriefingStore = types
  .model('BriefingStore', {
    itemLoadingState: types.maybe(ItemLoadingStateEnum)
  })
  .actions((self) => {
    const getBriefing = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore }: BriefingStoreEnvType =
        getEnv(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setBriefing(undefined);

        const result: any = yield client.getBriefing(organizationId, projectId);

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.setBriefing(createBriefingModel(result));

        self.itemLoadingState = undefined;
        return dataStore.briefing;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('BriefingStore | getBriefing', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createOrUpdateBriefing = flow(function* (
      patch: any,
      orgId?: number,
      projId?: number,
      updateStore = true
    ) {
      const { client, dataStore, applicationStore }: BriefingStoreEnvType =
        getEnv(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        let result;
        if (!dataStore.briefing) {
          result = yield client.createBriefing(
            organizationId,
            projectId,
            serverPatch
          );
        } else {
          result = yield client.updateBriefing(
            organizationId,
            projectId,
            serverPatch
          );
        }

        if (!result) {
          throw new Error('No response from server');
        }

        if (updateStore) {
          dataStore.setBriefing(createBriefingModel(result));
          dataStore.updateProjectBriefing(result);

          self.itemLoadingState = undefined;
          return dataStore.briefing;
        }

        self.itemLoadingState = undefined;
        return undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('BriefingStore | updateBriefing', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const initializeItem = (data: any) => {
      const { dataStore }: BriefingStoreEnvType = getEnv(self);
      dataStore.setBriefing(createBriefingModel(data));
    };

    const addAttachment = (attachment: AttachmentModelType) => {
      const { dataStore }: BriefingStoreEnvType = getEnv(self);
      const { briefing } = dataStore;

      if (!briefing) {
        return;
      }

      briefing.attachments.put(attachment);
    };

    const removeAttachment = (id: number) => {
      const { dataStore }: BriefingStoreEnvType = getEnv(self);
      const { briefing } = dataStore;

      if (!briefing) {
        return;
      }

      briefing.attachments.delete(id);
    };

    return {
      getBriefing,
      createOrUpdateBriefing,
      initializeItem,
      addAttachment,
      removeAttachment
    };
  })
  .views((self) => {
    return {
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return self.itemLoadingState === 'save_error';
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied'
        );
      }
    };
  });

export type BriefingStoreType = typeof BriefingStore.Type;
export default BriefingStore;
