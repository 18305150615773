import { injectIntl, WrappedComponentProps } from 'react-intl';

import EditMenu from 'components/EditMenu';
import MenuButton from 'components/MenuButton';
import { HypothesisModelType } from 'models/HypothesisModel';

export type DeclineActionType = 'decline' | 'retract';

export interface HypothesissListItemMenuProps {
  hypothesis: HypothesisModelType;

  currentUserId?: number;
  isOrgAdmin?: boolean;
  isProjectEditor?: boolean;

  onRequestClick?: () => void;
  onAssignClick?: () => void;
  onDeclineClick?: (type: DeclineActionType) => void;
  onInviteClick?: () => void;
  onEditClick?: () => void;
  onRemoveClick?: () => void;
}

const HypothesissListItemMenu = ({
  hypothesis,
  currentUserId,
  isOrgAdmin,
  isProjectEditor,
  onRequestClick,
  onAssignClick,
  onDeclineClick,
  onInviteClick,
  onEditClick,
  onRemoveClick,
  intl
}: HypothesissListItemMenuProps & WrappedComponentProps) => {
  const items: any[] = [];

  // use some defaults for user IDs so we do not need to check for undefined every time
  const userId = currentUserId || -1;
  const authorId = hypothesis.author?.id || -2;
  const requesterId = hypothesis.requesting_user?.id || undefined;

  if (hypothesis.publish_state === 'active') {
    // assignments
    if (requesterId) {
      if (isOrgAdmin) {
        if (onAssignClick) {
          items.push(
            <MenuButton
              key="assign"
              label={intl.formatMessage({ id: 'Confirm' })}
              description={intl.formatMessage({
                id: 'hypothesis assign desc'
              })}
              iconName="thumbs-up"
              onClick={onAssignClick}
            />
          );
        }

        if (onDeclineClick) {
          items.push(
            <MenuButton
              key="decline"
              label={intl.formatMessage({ id: 'Decline' })}
              description={intl.formatMessage({
                id: 'hypothesis decline desc'
              })}
              iconName="decline"
              onClick={() => onDeclineClick('decline')}
            />
          );
        }
      } else if (requesterId === userId) {
        if (onDeclineClick) {
          items.push(
            <MenuButton
              key="retract"
              label={intl.formatMessage({ id: 'Retract' })}
              description={intl.formatMessage({
                id: 'hypothesis retract desc'
              })}
              iconName="decline"
              onClick={() => onDeclineClick('retract')}
            />
          );
        }
      }
    } else if (isOrgAdmin || isProjectEditor) {
      if (onRequestClick) {
        items.push(
          <MenuButton
            key="request"
            label={intl.formatMessage({ id: 'Raise hand' })}
            description={intl.formatMessage({
              id: 'hypothesis request desc'
            })}
            iconName="raise-hand"
            onClick={onRequestClick}
          />
        );
      }
    }

    // invite
    if (isOrgAdmin && onInviteClick) {
      items.push(
        <MenuButton
          key="invite"
          label={intl.formatMessage({ id: 'Invite persons' })}
          description={intl.formatMessage({
            id: 'hypothesis invite desc'
          })}
          iconName="persons"
          onClick={onInviteClick}
        />
      );
    }
  }

  // edit actions
  const mayEdit = isOrgAdmin || authorId === userId;
  if (mayEdit) {
    if (onEditClick) {
      items.push(
        <MenuButton
          key="edit"
          label={intl.formatMessage({ id: 'Edit' })}
          description={intl.formatMessage({
            id: 'hypothesis edit desc'
          })}
          iconName="pen"
          onClick={onEditClick}
        />
      );
    }

    if (onRemoveClick) {
      items.push(
        <MenuButton
          key="remove"
          label={intl.formatMessage({ id: 'Remove' })}
          description={intl.formatMessage({
            id: 'hypothesis remove desc'
          })}
          iconName="bin"
          onClick={onRemoveClick}
        />
      );
    }
  }

  if (!items.length) {
    return null;
  }

  return (
    <EditMenu bottomRight={true} label={intl.formatMessage({ id: 'Edit' })}>
      {items}
    </EditMenu>
  );
};

export default injectIntl(HypothesissListItemMenu);
