import CardWrapper from 'components/CardWrapper';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import LoginForm from 'components/LoginForm';
import MainButton from 'components/MainButton';
import Typography from 'components/Typography';
import config from 'config';
import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_LOGIN_PAGE } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PasswordResetScreenProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('applicationStore')
@observer
class PasswordResetScreen extends React.Component<
  PasswordResetScreenProps & HistoryProps
> {
  async componentDidMount() {
    const { applicationStore, match, form } = this.props;
    const token = match.params?.token;

    if (!token) {
      return;
    }

    const email: any = await applicationStore.verifyPasswordResetToken(token);

    if (email) {
      form.setField('email', email);
    }
  }

  async handleSubmit() {
    const { form, match, applicationStore } = this.props;

    form.resetErrors();

    const token = match.params?.token;
    const newPassword = form.values.password || '';
    const confirmation = form.values.confirmation || '';

    // validate
    let submit = true;

    if (!newPassword.length || newPassword.length < config.minPasswordLength) {
      form.setError(
        'password',
        getIntl().formatMessage(
          { id: 'password too short message' },
          { min: config.minPasswordLength }
        )
      );
      submit = false;
    } else if (newPassword !== confirmation) {
      form.setError(
        'confirmation',
        getIntl().formatMessage({ id: 'password mismatch message' })
      );
      submit = false;
    }

    if (!submit) {
      return;
    }

    try {
      await applicationStore.finishPasswordReset(token, newPassword);
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  renderForm() {
    const {
      form,
      applicationStore: { authState }
    } = this.props;

    let loading;
    if (authState.resetState === 'loading') {
      loading = <Loading />;
    }

    let error;
    if (authState.resetState === 'error') {
      error = <FormattedMessage id="reset error message" />;
    }

    return (
      <LoginForm>
        <Form onSubmit={() => this.handleSubmit()}>
          {loading}

          {error && <LoginForm.Error>{error}</LoginForm.Error>}

          {form.values.email && (
            <LoginForm.Input>
              <Input
                name="email"
                readOnly={true}
                {...form.bindInput('email')}
                label={<FormattedMessage id="Login email address" />}
                gap={true}
              />
            </LoginForm.Input>
          )}

          <LoginForm.Input>
            <Input
              name="password"
              type="password"
              autoFocus={true}
              {...form.bindInput('password')}
              label={<FormattedMessage id="New password" />}
              gap={true}
            />
          </LoginForm.Input>

          <LoginForm.Input>
            <Input
              name="confirmation"
              type="password"
              {...form.bindInput('confirmation')}
              label={<FormattedMessage id="Confirm password" />}
              gap={true}
            />
          </LoginForm.Input>

          <RowWrapper gap="1em">
            <MainButton type="submit">
              <FormattedMessage id="Reset password" />
            </MainButton>
            <MainButton
              type="button"
              secondary={true}
              onClick={() => this.props.history.push(ROUTE_LOGIN_PAGE)}
            >
              <FormattedMessage id="Cancel" />
            </MainButton>
          </RowWrapper>
        </Form>
      </LoginForm>
    );
  }

  renderInvalidToken() {
    return (
      <LoginForm>
        <CardWrapper>
          <Typography>
            <FormattedMessage id="reset token invalid message" />
          </Typography>

          <RowWrapper gap="1em">
            <PositionWrapper center={true}>
              <MainButton
                onClick={() => this.props.history.replace(ROUTE_LOGIN_PAGE)}
              >
                <FormattedMessage id="Go to login" />
              </MainButton>
            </PositionWrapper>
          </RowWrapper>
        </CardWrapper>
      </LoginForm>
    );
  }

  renderFinished() {
    return (
      <LoginForm>
        <CardWrapper>
          <Typography>
            <FormattedMessage id="reset success message" />
          </Typography>

          <RowWrapper gap="1em">
            <PositionWrapper center={true}>
              <MainButton
                onClick={() => this.props.history.replace(ROUTE_LOGIN_PAGE)}
              >
                <FormattedMessage id="Go to login" />
              </MainButton>
            </PositionWrapper>
          </RowWrapper>
        </CardWrapper>
      </LoginForm>
    );
  }

  render() {
    const {
      applicationStore: { authState }
    } = this.props;

    if (authState.resetState === 'finished') {
      return this.renderFinished();
    }

    if (authState.resetState === 'token_invalid') {
      return this.renderInvalidToken();
    }

    return this.renderForm();
  }
}

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PasswordResetScreen {...props} form={form} />;
};
