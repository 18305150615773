import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import MainNavItem from 'components/MainNavItem';
import { DataStoreType } from 'models/DataStore';
import { ROUTE_CUSTOMERS_BASE } from 'utils/constants/routes';

interface CustomerNavItemProps {
  dataStore?: DataStoreType;
  active?: boolean;
  displayWithoutOnlyOrg?: boolean;
}

export default inject('dataStore')(
  observer(({ dataStore, active }: CustomerNavItemProps) => {
    if (
      !(
        dataStore!.user?.agent ||
        (dataStore!.isAgency && dataStore!.organization?.access_level) ||
        dataStore!.organization?.agency_id
      )
    ) {
      return null;
    }

    let agencyId;

    if (dataStore!.user?.agent) {
      agencyId = dataStore!.ownAgency!.id;
    } else if (dataStore!.isAgency) {
      agencyId = dataStore!.organization!.id;
    } else {
      agencyId = dataStore!.organization?.agency_id;
    }

    return (
      <MainNavItem
        label={<FormattedMessage id="Customers" />}
        iconName="agency"
        active={active}
        to={ROUTE_CUSTOMERS_BASE + '/' + agencyId}
      />
    );
  })
);
