const sortDraftsFirst = (a: any, b: any) => {
  const da = a?.publish_state === 'draft';
  const db = b?.publish_state === 'draft';

  if (da && !db) {
    return -1;
  }
  if (!da && db) {
    return 1;
  }
  return 0;
};

export default sortDraftsFirst;
