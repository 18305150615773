import { injectIntl, WrappedComponentProps } from 'react-intl';

import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { BenchmarkModelType } from 'models/BenchmarkModel';

export default injectIntl(
  ({
    benchmark,
    selected,
    intl,
    onClick
  }: {
    benchmark: BenchmarkModelType;
    selected?: boolean;
    onClick: () => void;
  } & WrappedComponentProps) => {
    const attachment = benchmark.attachments.firstOfType('screen');
    const logo = benchmark.attachments.firstOfType('logo');

    return (
      <RainbowCardCompact
        simple={true}
        checked={selected}
        onCheckboxChange={onClick}
        company={benchmark.company_name}
        title={benchmark.headline || intl.formatMessage({ id: 'no headline' })}
        src={attachment?.resource_urls?.large}
        logo={
          logo &&
          logo.resource_urls && (
            <CompanyLogo
              imgUrl={logo.resource_urls.small}
              alt={intl.formatMessage({ id: 'Logo' })}
            />
          )
        }
      />
    );
  }
);
