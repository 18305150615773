import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

interface TabListButtonProps {
  title: React.ReactNode | string;
  to: LinkProps['to'];
  active?: boolean;
  onClick?: () => void;
}

export default function TabListButton({
  title,
  to,
  active,
  onClick,
}: TabListButtonProps) {
  const tabListButtonClasses = classNames('tablist__button', {
    'tablist__button--active': active
  });

  if (onClick) {
    return (
      <a onClick={onClick} className={tabListButtonClasses}>
        {title}
      </a>
    );
  }
  return (
    <Link to={to} className={tabListButtonClasses}>
      {title}
    </Link>
  );
}
