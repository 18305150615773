import { FormattedMessage } from 'react-intl';

import CardHeader from 'components/CardHeader';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import shortenString from 'utils/misc/shorten-string';
import SidebarItem from '../SidebarItem';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

export const SidebarHypothesis = ({
  session,
  showHeader
}: SidebarItemProps) => {
  if (!session) {
    return null;
  }

  const screen = session.attachments.firstOfType('screen');
  if (
    !session.hypothesis_headline &&
    !session.hypothesis_description &&
    !session.hypothesis_added_value &&
    !screen
  ) {
    if (showHeader) {
      return (
        <SidebarMarker type="hypothesis">
          <SidebarItem>
            <SidebarItemHeader>
              <FormattedMessage id="assistant sidebar hypothesis" />
            </SidebarItemHeader>
          </SidebarItem>
        </SidebarMarker>
      );
    }

    return <SidebarMarker type="hypothesis" />;
  }

  return (
    <SidebarMarker type="hypothesis">
      <SidebarItem>
        <SidebarItemHeader date={session.hypothesis_date}>
          <FormattedMessage id="assistant sidebar hypothesis" />
        </SidebarItemHeader>

        <RainbowCard
          unresponsive={true}
          to={{
            pathname:
              ROUTE_ASSISTANT +
              '/' +
              session.id +
              ASSISTANTS.creative_session.step4_2.route,
            state: {
              canGoBack: true
            }
          }}
          src={screen?.resource_urls?.large}
          alt=""
        >
          {session.hypothesis_headline && (
            <CardHeader title={session.hypothesis_headline} />
          )}

          {session.hypothesis_description && (
            <Typography size="small">
              {shortenString(
                session.hypothesis_description,
                LIST_ITEM_TEXT_LENGTH
              )}
            </Typography>
          )}
        </RainbowCard>
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarHypothesis;
