import { inject, observer } from 'mobx-react';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import DestroyButton from 'components/DestroyButton';
import ScreenItem from 'components/Layout/ScreenItem';
import MobilePreview from 'components/MobilePreview';
import MoveButton from 'components/MoveButton';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import { AttachmentModelType } from 'models/AttachmentModel';
import { DataStoreType } from 'models/DataStore';
import { PrototypeModelType } from 'models/PrototypeModel';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import classNames from 'classnames';

interface PublicPrototypeScreensItemProps extends WrappedComponentProps {
  prototype?: PrototypeModelType;
  screen?: PrototypeScreenModelType;
  editable?: boolean;
  first?: boolean;
  last?: boolean;
  index: number;
  autoFocus?: 'title' | 'file';
  onAutoFocus?: () => void;
  onlyOne?: boolean;
  isEditing?: boolean;
  onMove?: (id: number, direction: 'left' | 'right') => void;
  onEdit: () => void;
}

interface PrototypeScreensItemProps extends PublicPrototypeScreensItemProps {
  dataStore: DataStoreType;
  prototypesStore: PrototypesStoreType;
}

interface PublicPrototypeScreensItemState {
  deleting?: boolean;
}

// tslint:disable: jsx-wrap-multiline

@inject('dataStore', 'prototypesStore')
@observer
class PrototypeScreensItem extends React.Component<
  PrototypeScreensItemProps,
  PublicPrototypeScreensItemState
> {
  state: PublicPrototypeScreensItemState = {};

  async remove() {
    const { intl, prototypesStore, prototype, screen } = this.props;

    if (!prototype || !screen) {
      return;
    }

    if (
      (screen.attachments?.hasAny || !!screen.headline) &&
      !window.confirm(
        intl.formatMessage({ id: 'remove prototype screen confirm' })
      )
    ) {
      return;
    }

    this.setState({
      deleting: true
    });

    try {
      await prototypesStore.deleteScreen({ prototype, screenId: screen.id });
    } catch (e) {
      this.setState({
        deleting: false
      });

      // TODO what to do here?
    }
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    // TODO do we need to do something here?
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { screen } = this.props;

    if (!screen || !attachment) {
      return;
    }

    screen.attachments?.put(attachment);
  }

  attachmentRemoved(id: number) {
    const { screen } = this.props;

    if (!screen) {
      return;
    }

    screen.attachments?.delete(id);
  }

  fillHeadline(headline?: string) {
    const { intl, editable, index } = this.props;

    if (!headline || headline.toString().trim() === '') {
      return intl.formatMessage({
        id: editable ? 'Prototype Add Headline' : 'no headline'
      });
    }

    const paddedIndex = String(index + 1).padStart(2, '0');
    return `${paddedIndex}) ${headline}`;
  }

  renderView() {
    const { screen, intl } = this.props;
    if (!screen) {
      return null;
    }

    const attachment = screen.attachments?.firstOfType('screen');

    return (
      <ScreenItem>
        <div className="l-screen-item__headline">
          <h5
            title={screen.headline}
            className={classNames({
              'l-screen-item__headline--placeholder': !screen.headline
            })}
          >
            {this.fillHeadline(screen.headline)}
          </h5>
          <span
            className={classNames({
              'l-screen-item__description': true,
              'l-screen-item__description--placeholder': !screen.description
            })}
            title={
              screen.description ||
              intl.formatMessage({ id: 'Prototype Add Description' })
            }
          >
            {screen.description ||
              intl.formatMessage({ id: 'Prototype Add Description' })}
          </span>
        </div>
        {attachment && attachment.resource_urls ? (
          <UploadedMobileImage attachment={attachment} />
        ) : (
          <MobilePreview alt="Empty attachment" />
        )}
      </ScreenItem>
    );
  }

  renderEditable() {
    const { intl, screen, first, last, onMove, autoFocus, onlyOne } =
      this.props;
    if (!screen) {
      return null;
    }

    const attachment = screen.attachments?.firstOfType('screen');
    const destroyVisible = !onlyOne || !!screen.headline || !!attachment;

    // TODO loading/deleting state!?
    return (
      <ScreenItem>
        <div className="l-screen-item__headline">
          <h5
            title={screen.headline}
            onClick={this.props.onEdit}
            className={classNames({
              'l-screen-item__headline--placeholder': !screen.headline
            })}
          >
            {this.fillHeadline(screen.headline)}
          </h5>
          <div
            className={classNames({
              'l-screen-item__description': true,
              'l-screen-item__description--placeholder': !screen.description
            })}
            title={
              screen.description ||
              intl.formatMessage({ id: 'Prototype Add Description' })
            }
            onClick={this.props.onEdit}
          >
            {screen.description ||
              intl.formatMessage({ id: 'Prototype Add Description' })}
          </div>
          {destroyVisible && <DestroyButton onClick={() => this.remove()} />}
        </div>

        {attachment ? (
          <>
            <UploadedFileContainer
              attachment={attachment}
              onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
              key={attachment.id}
            >
              {(props) => <UploadedMobileImage {...props} />}
            </UploadedFileContainer>
          </>
        ) : (
          <UploadContainer
            attachmentType="screen"
            elementType={ElementType.PrototypeScreen}
            elementId={screen.id}
            onLoadingStateChanged={(s) => this.uploadLoadingStateChanged(s)}
            onAttachmentReceived={(a) => this.attachmentReceived(a)}
            acceptVideo={true}
            autoSelect={autoFocus === 'file'}
          >
            {(props: UploadContainerChildProps) => (
              <>
                <UploadMobileButton {...props} />
              </>
            )}
          </UploadContainer>
        )}

        <div
          className={classNames('move-buttons', {
            'move-buttons--multiple': !first && !last
          })}
        >
          {!first && onMove && (
            <MoveButton onClick={() => onMove(screen.id, 'left')} />
          )}
          {!last && onMove && (
            <MoveButton
              right={true}
              onClick={() => onMove(screen.id, 'right')}
            />
          )}
        </div>
      </ScreenItem>
    );
  }

  render() {
    if (this.props.editable) {
      return this.renderEditable();
    }

    return this.renderView();
  }
}

export default injectIntl((props: PublicPrototypeScreensItemProps) => {
  // @ts-ignore
  return <PrototypeScreensItem {...props} />;
});
