import { Route, Switch } from 'react-router';

import AssistantSessionContainer from 'containers/AssistantSessionContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ASSISTANT_SESSION } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import AssistantInvitationScreen from './AssistantInvitationScreen';
import IntroductionScreen from './IntroductionScreen';
import FinalizePainpointScreen from './session1/FinalizePainpointScreen';
import PainpointDoneScreen from './session1/PainpointDoneScreen';
import PainpointIntroScreen from './session1/PainpointIntroScreen';
import PainpointScreen from './session1/PainpointScreen';
import PainpointSelectScreen from './session1/PainpointSelectScreen';
import QuestionIntroScreen from './session1/QuestionIntroScreen';
import QuestionScreen from './session1/QuestionScreen';
import BenchmarkDoneScreen from './session2/BenchmarkDoneScreen';
import BenchmarkSelectScreen from './session2/BenchmarkSelectScreen';
import SolutionDoneScreen from './session3/SolutionDoneScreen';
import SolutionIntroScreen from './session3/SolutionIntroScreen';
import SolutionScreen from './session3/SolutionScreen';
import HypothesisDoneScreen from './session4/HypothesisDoneScreen';
import HypothesisIntroScreen from './session4/HypothesisIntroScreen';
import HypothesisScreen from './session4/HypothesisScreen';

export default ({ history, match }: HistoryProps) => (
  <AssistantSessionContainer
    history={history}
    assistantSessionId={match.params?.id}
    assistantType={[
      'creative_session',
      'painpoint_session',
      'hypothesis_sprint',
      'prototype_sprint'
    ]}
  >
    <Switch>
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/invitation'}
        component={AssistantInvitationScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_SESSION + '/introduction'}
        component={IntroductionScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/select'}
        component={PainpointSelectScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/step1'}
        component={PainpointIntroScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/step2'}
        component={PainpointScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/step3'}
        component={QuestionIntroScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/step4'}
        component={QuestionScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/done'}
        component={PainpointDoneScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session1/finalize'}
        component={FinalizePainpointScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session2/step1'}
        component={BenchmarkSelectScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session2/done'}
        component={BenchmarkDoneScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session3/step1'}
        component={SolutionIntroScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session3/step2'}
        component={SolutionScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session3/done'}
        component={SolutionDoneScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session4/step1'}
        component={HypothesisIntroScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session4/step2'}
        component={HypothesisScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_SESSION + '/session4/step3'}
        component={HypothesisDoneScreen}
      />

      <NotFoundRoute />
    </Switch>
  </AssistantSessionContainer>
);
