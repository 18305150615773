import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import HypothesisModel from './HypothesisModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PublishStateEnum, { createPublishState } from './PublishStateEnum';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';
import PainpointModel, { PainpointModelType } from './PainpointModel';

export const BenchmarkModel = types
  .model('BenchmarkModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    painpoint: types.maybe(
      types.reference(types.late((): IAnyModelType => PainpointModel))
    ),
    author: types.maybe(FluidUserModel),
    headline: types.maybe(types.string),
    company_name: types.maybe(types.string),
    description: types.maybe(types.string),
    purpose: types.maybe(types.string),
    publish_state: types.maybe(PublishStateEnum),
    created_at: types.maybe(types.string),
    published_at: types.maybe(types.string),
    average_target_group_relevance: types.maybe(types.number),
    average_revenue_potential: types.maybe(types.number),
    average_cost_efficiency: types.maybe(types.number),
    average_differentiation_degree: types.maybe(types.number),
    hypotheses: types.maybe(
      types.map(
        types.reference(types.late((): IAnyModelType => HypothesisModel))
      )
    ),
    tags: types.maybe(TagListModel),
    attachments: AttachmentsMapModel,
    comments_count: types.maybe(types.number),
    comments: CommentsMapModel,
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    created_by_ai: types.maybe(types.boolean)
  })
  .actions((self) => {
    const setPainpoint = (painpoint?: PainpointModelType) => {
      self.painpoint = painpoint;
    };

    return {
      setPainpoint
    };
  });

export const createBenchmarkModel = (data?: any): BenchmarkModelType => {
  return BenchmarkModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    painpoint: data?.painpoint ? data.painpoint.id : undefined, // i have no idea why this is needed, without this line the painpoint is getting removed again on first load
    author: createFluidUserModel(data?.author),
    headline: data?.headline || undefined,
    company_name: data?.company_name || undefined,
    description: data?.description || undefined,
    purpose: data?.purpose || undefined,
    publish_state: createPublishState(data?.publish_state),
    created_at: data?.created_at || undefined,
    published_at: data?.published_at || data?.created_at || undefined,
    average_target_group_relevance: numberOrUndefined(
      data?.average_target_group_relevance
    ),
    average_revenue_potential: numberOrUndefined(
      data?.average_revenue_potential
    ),
    average_cost_efficiency: numberOrUndefined(data?.average_cost_efficiency),
    average_differentiation_degree: numberOrUndefined(
      data?.average_differentiation_degree
    ),
    hypotheses: data?.hypotheses || undefined,
    tags: createTagList(data?.tags),
    attachments: createAttachmentsMap(data?.attachments),
    comments_count: numberOrUndefined(data?.ratings_count),
    comments: createCommentsMap(data?.ratings),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    created_by_ai: data.created_by_ai
  });
};

export type BenchmarkModelType = typeof BenchmarkModel.Type;
export default BenchmarkModel;
