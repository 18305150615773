import CardWrapper from 'components/CardWrapper';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import LoginForm from 'components/LoginForm';
import MainButton from 'components/MainButton';
import Typography from 'components/Typography';
import config from 'config';
import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType, LanguageEnum } from 'models/ApplicationStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTE_HOME, ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface JoinScreenProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('applicationStore')
@observer
class JoinScreen extends React.Component<JoinScreenProps & HistoryProps> {
  async componentDidMount() {
    const { applicationStore, match, form } = this.props;
    const token = match.params?.token;

    if (!token) {
      return;
    }

    const email: any = await applicationStore.verifyInvitation(token);

    if (email) {
      form.setField('email', email);
    }
  }

  async changeLanguage(lang: LanguageEnum) {
    // TODO move language select to component?
    const { applicationStore, form } = this.props;

    if (lang !== applicationStore.getCurrentLanguage()) {
      form.setLoading(true);

      try {
        await applicationStore.changeLanguage(lang);
      } catch (e) {
        // TODO do something here?

        form.setLoading(false);
      }
    }
  }

  async handleSubmit() {
    const { form, match, applicationStore } = this.props;

    form.resetErrors();

    const firstName = (form.values.first_name || '').toString().trim();
    const lastName = (form.values.last_name || '').toString().trim();

    const token = match.params?.token;
    const newPassword = form.values.password || '';
    const confirmation = form.values.confirmation || '';

    // validate
    let submit = true;

    if (!firstName) {
      form.setError(
        'first_name',
        getIntl().formatMessage({ id: 'cant be blank' })
      );
      submit = false;
    }
    if (!lastName) {
      form.setError(
        'last_name',
        getIntl().formatMessage({ id: 'cant be blank' })
      );
      submit = false;
    }

    if (!newPassword.length || newPassword.length < config.minPasswordLength) {
      form.setError(
        'password',
        getIntl().formatMessage(
          { id: 'password too short message' },
          { min: config.minPasswordLength }
        )
      );
      submit = false;
    } else if (newPassword !== confirmation) {
      form.setError(
        'confirmation',
        getIntl().formatMessage({ id: 'password mismatch message' })
      );
      submit = false;
    }

    if (!submit) {
      return;
    }

    try {
      await applicationStore.join(token, newPassword, firstName, lastName);

      if (applicationStore.authState.joinState === 'finished') {
        this.props.history.push(ROUTE_LAB);
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  renderForm() {
    const { form, applicationStore } = this.props;
    const { authState } = applicationStore;

    let loading;
    if (authState.joinState === 'loading') {
      loading = <Loading />;
    }

    let error;
    if (authState.joinState === 'error') {
      error = <FormattedMessage id="join error message" />;
    }

    return (
      <LoginForm>
        <Form onSubmit={() => this.handleSubmit()}>
          {loading}

          <PositionWrapper center={true} gap="2em">
            <Select
              login={true}
              name="lang"
              onChange={(e) =>
                this.changeLanguage(e.target.value as LanguageEnum)
              }
              value={applicationStore.getCurrentLanguage()}
            >
              <option value="de">Deutsch</option>
              <option value="en">English</option>
            </Select>
          </PositionWrapper>

          {error && <LoginForm.Error>{error}</LoginForm.Error>}

          <Typography>
            <FormattedMessage id="join info" />
          </Typography>

          {form.values.email && (
            <LoginForm.Input>
              <Input
                name="email"
                readOnly={true}
                {...form.bindInput('email')}
                label={<FormattedMessage id="Email address" />}
                gap={true}
              />
            </LoginForm.Input>
          )}

          <LoginForm.Input>
            <Input
              name="first_name"
              type="text"
              autoFocus={true}
              {...form.bindInput('first_name')}
              label={<FormattedMessage id="First name" />}
              gap={true}
            />
          </LoginForm.Input>

          <LoginForm.Input>
            <Input
              name="last_name"
              type="text"
              {...form.bindInput('last_name')}
              label={<FormattedMessage id="Last name" />}
              gap={true}
            />
          </LoginForm.Input>

          <LoginForm.Input>
            <Input
              name="password"
              type="password"
              {...form.bindInput('password')}
              label={<FormattedMessage id="New password" />}
              gap={true}
            />
          </LoginForm.Input>

          <LoginForm.Input>
            <Input
              name="confirmation"
              type="password"
              {...form.bindInput('confirmation')}
              label={<FormattedMessage id="Confirm password" />}
              gap={true}
            />
          </LoginForm.Input>

          <RowWrapper gap="1em">
            <MainButton type="submit">
              <FormattedMessage id="Next" />
            </MainButton>
          </RowWrapper>
        </Form>
      </LoginForm>
    );
  }

  renderInvalidToken() {
    return (
      <LoginForm>
        <CardWrapper>
          <Typography>
            <FormattedMessage id="join token invalid message" />
          </Typography>

          <RowWrapper gap="1em">
            <PositionWrapper center={true}>
              <MainButton
                onClick={() => this.props.history.replace(ROUTE_HOME)}
              >
                <FormattedMessage id="OK" />
              </MainButton>
            </PositionWrapper>
          </RowWrapper>
        </CardWrapper>
      </LoginForm>
    );
  }

  render() {
    const {
      applicationStore: { authState }
    } = this.props;

    if (authState.joinState === 'token_invalid') {
      return this.renderInvalidToken();
    }

    return this.renderForm();
  }
}

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <JoinScreen {...props} form={form} />;
};
