import { flow, getEnv, types } from 'mobx-state-tree';
import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';
import PROJECT_ACCESS_LEVELS from 'utils/constants/project-access-levels';
import arrUnique from 'utils/misc/arr-unique';
import { sortCreatedAsc, sortCreatedDesc } from 'utils/sort/created-at';
import sortByField from 'utils/sort/field';
import sortUsers from 'utils/sort/sort-users';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import { createAssistantStatsModel } from './AssistantStatsModel';
import {
  isLoadError,
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { OrgAccessLevelEnumModelType } from './OrgAccessLevelEnumModel';
import { ProjectAccessLevelEnumModelType } from './ProjectAccessLevelEnumModel';
import { createProjectMemberModel } from './ProjectMemberModel';
import { createProjectModel, ProjectModelType } from './ProjectModel';
import { AdvancedStoreEnv } from './StoreEnv';
import { createUserModel, UserModelType } from './UserModel';

export interface EditorType {
  user: UserModelType;
  isProjectMember: boolean;
  orgAccessLevel: OrgAccessLevelEnumModelType;
}

export interface ViewerType {
  user: UserModelType;
  isProjectMember: boolean;
}

interface MembersListType {
  editors: EditorType[];
  viewers: ViewerType[];
}

const ProjectsStore = types
  .model('ProjectsStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    membersLoadingState: types.maybe(ListLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const getProjects = flow(function* (organizationId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.listLoadingState = 'loading';
        dataStore.setProjectsList(undefined);

        if (organizationId === undefined) {
          organizationId = dataStore.currentOrganizationId;
        }
        if (organizationId === undefined) {
          throw new Error('No organization in context');
        }

        const result = yield client.listProjects(organizationId);

        if (!result || !Array.isArray(result)) {
          throw new Error('No valid response from server');
        }

        const list = [];
        for (const project of result) {
          const storeItem = dataStore.addProject(createProjectModel(project));
          list.push(storeItem);
        }

        dataStore.setProjectsList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | getProjects', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
      }
    });

    const getProject = flow(function* (id: number, organizationId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        if (organizationId === undefined) {
          organizationId = dataStore.currentOrganizationId;
        }
        if (organizationId === undefined) {
          throw new Error('No organization in context');
        }

        const project = createProjectModel(
          yield client.getProject(organizationId, id)
        );

        if (!project || project.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addProject(project);

        self.itemLoadingState = undefined;
        return dataStore.projects.get(project.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | getProject', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'load_error';
      }
    });

    const updateProject = flow(function* (
      id: number,
      patch: any,
      organizationId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        if (organizationId === undefined) {
          organizationId = dataStore.currentOrganizationId;
        }
        if (organizationId === undefined) {
          throw new Error('No organization in context');
        }

        const updatedProject = createProjectModel(
          yield client.updateProject(id, patch, organizationId)
        );

        if (!updatedProject || updatedProject.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addProject(updatedProject);

        self.itemLoadingState = undefined;
        return dataStore.projects.get(updatedProject.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | getProject', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });

    const getStrategyTypes = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        const result = yield client.getStrategyTypes();

        if (!result) {
          throw new Error('No valid response from server');
        }

        return result.strategy_types;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | getStrategyTypes', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'load_error';
      }
    });
    const createProject = flow(function* (
      project: any,
      organizationId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'saving';

        if (organizationId === undefined) {
          organizationId = dataStore.currentOrganizationId;
        }
        if (organizationId === undefined) {
          throw new Error('No organization in context');
        }

        const createdProject = createProjectModel(
          yield client.createProject(organizationId, project)
        );

        if (!createdProject || createdProject.id < 0) {
          throw new Error('No valid response from server');
        }

        dataStore.addProject(createdProject);

        self.itemLoadingState = undefined;
        return dataStore.projects.get(createdProject.id.toString());
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | createProject', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
      }
    });

    const deleteProject = flow(function* (
      organizationId: number,
      projectId: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      try {
        self.itemLoadingState = 'deleting';

        yield client.deleteProject(organizationId, projectId);

        dataStore.deleteProject(projectId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | deleteProject', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteProject(projectId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const getMembers = flow(function* (
      organizationId?: number,
      projectId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!projectId) {
        projectId = dataStore.currentProjectId;
      }

      if (!organizationId || !projectId) {
        throw new Error('Insufficient context');
      }

      try {
        self.membersLoadingState = 'loading';

        dataStore.clearProjectMembers();

        const members = yield client.listProjectMembers(
          organizationId,
          projectId
        );

        if (!members || !Array.isArray(members)) {
          throw new Error('No valid response from client');
        }

        for (const member of members) {
          const memberModel = createProjectMemberModel(member);
          if (!memberModel) {
            // insufficient user info in membership record
            continue;
          }

          if (member.user) {
            memberModel.setUser(
              dataStore.addUser(createUserModel(member.user))
            );
          }

          dataStore.addProjectMember(memberModel);
        }

        self.membersLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | getMembers', error, error.body);
        }

        if (client.isAccessDenied(error)) {
          self.membersLoadingState = 'access_denied';
          return;
        }

        if (client.isNotFound(error)) {
          self.membersLoadingState = 'not_found';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.membersLoadingState = undefined;
          return;
        }

        self.membersLoadingState = 'load_error';
      }
    });

    const createOrUpdateMembership = flow(function* (
      userId: number,
      accessLevel: ProjectAccessLevelEnumModelType,
      organizationId?: number,
      projectId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!projectId) {
        projectId = dataStore.currentProjectId;
      }

      if (!organizationId || !projectId) {
        throw new Error('Insufficient context');
      }

      try {
        const updatedMembership = yield client.createOrUpdateProjectMembership(
          organizationId,
          projectId,
          userId,
          accessLevel
        );

        if (updatedMembership) {
          const memberModel = createProjectMemberModel(updatedMembership);
          if (memberModel) {
            if (updatedMembership.user) {
              memberModel.setUser(
                dataStore.addUser(createUserModel(updatedMembership.user))
              );
            }

            dataStore.addProjectMember(memberModel);
          }
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ProjectsStore | createOrUpdateMembership',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteMembership = flow(function* (
      userId: number,
      organizationId?: number,
      projectId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!projectId) {
        projectId = dataStore.currentProjectId;
      }

      if (!organizationId || !projectId) {
        throw new Error('Insufficient context');
      }

      try {
        yield client.deleteProjectMembership(organizationId, projectId, userId);

        dataStore.deleteProjectMember(userId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ProjectsStore | deleteMembership', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const getGPTRequestCounter = flow(function* (
      organizationId?: number,
      projectId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      if (!organizationId) {
        organizationId = dataStore.currentOrganizationId;
      }
      if (!projectId) {
        projectId = dataStore.currentProjectId;
      }

      if (!organizationId || !projectId) {
        throw new Error('Insufficient context');
      }

      try {
        const result = yield client.getGPTRequestCounter(
          organizationId,
          projectId
        );

        if (!result) {
          throw new Error('No valid response from client');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.convertAndAddGPTRequestCounter(item);
          list.push(storeItem);
        }

        dataStore.setGPTRequestCounterList(list);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ProjectsStore | getGPTRequestCounter',
            error,
            error.body
          );
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const getAssistantStats = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);
      try {
        const { organizationId, projectId } = dataStore.applyContext(
          orgId,
          projId
        );

        if (!organizationId || !projectId) {
          throw new Error('Insufficient context');
        }

        const result = yield client.getProjectAssistantStats(
          organizationId,
          projectId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const stats = createAssistantStatsModel(
          projectId,
          result?.assistant_stats || []
        );
        dataStore.setProjectAssistantStats(stats);

        dataStore.setReceivedInvitationsCount(
          numberOrUndefined(result.received_invitations_count)
        );

        return dataStore.projectAssistantStats;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ProjectsStore | getProjectAssistantStats',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        throw new Error('load_error');
      }
    });

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    const generatePainpointsByAi = flow(function* (
      orgId: number,
      projId: number
    ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);
      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);
        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generatePainpointsByAi(orgId, projId);

        // set all loading states to false here
        // applicationStore.setIsPrefillingPainPointClusters(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generatePainpointsByAi',
            error,
            error.body
          );
        }
      }
    });

    const generateBenchmarksByAi = flow(function* (
      orgId: number,
      projId: number
    ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);
      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);

        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generateGeneralBenchmarksByAi(
          orgId,
          projId
        );

        // set all loading states to false here
        // applicationStore.setIsPrefillingBenchmarks(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generateBenchmarksByAi',
            error,
            error.body
          );
        }
      }
    });

    const generateHypothesesByAi = flow(function* (
      orgId: number,
      projId: number,
      painpointIds: number[],
      benchmarkIds: number[]
    ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);
      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);

        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generateHypothesesByAi(
          orgId,
          projId,
          painpointIds,
          benchmarkIds
        );

        // set all loading states to false here
        // applicationStore.setIsPrefillingHypotheses(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generateHypothesesByAi',
            error,
            error.body
          );
        }
      }
    });

    const generatePrototypesByAi = flow(function* (
      orgId: number,
      projId: number,
      hypothesesIds: number[]
    ) {
      const { client, dataStore }: AdvancedStoreEnv = getEnv(self);

      try {
        // set all loading states to true here
        dataStore.project?.setAiAtWork(true);

        // create/trigger all AI/GPT Endpoints here
        const result: any = yield client.generatePrototypesByAi(
          orgId,
          projId,
          hypothesesIds
        );

        // set all loading states to false here
        // applicationStore.setIsPrefillingPrototypes(false);

        if (!result) {
          throw new Error('No response from server');
        }

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'DataStore | generatePrototypesByAi',
            error,
            error.body
          );
        }
      }
    });

    return {
      getProjects,
      getProject,
      updateProject,
      getStrategyTypes,
      createProject,
      deleteProject,
      getMembers,
      createOrUpdateMembership,
      deleteMembership,
      getGPTRequestCounter,
      getAssistantStats,
      setFilter,
      generatePainpointsByAi,
      generateBenchmarksByAi,
      generateHypothesesByAi,
      generatePrototypesByAi
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);

    return {
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemSaving(): boolean {
        return (
          self.itemLoadingState === 'saving' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'not_found' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },
      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'not_found'
        );
      },

      get isMembersLoading(): boolean {
        return self.membersLoadingState === 'loading';
      },
      get isMembersLoadError(): boolean {
        return isLoadError(self.membersLoadingState);
      },

      get hasAny(): boolean {
        return dataStore.projectsList && dataStore.projectsList.length > 0
          ? true
          : false;
      },
      get countProjects(): number {
        return dataStore.projectsList?.length || 0;
      },
      get allProjects(): ProjectModelType[] {
        const list: ProjectModelType[] = [];

        if (!dataStore.projectsList) {
          return list;
        }

        for (const project of dataStore.projectsList.values()) {
          list.push(project);
        }

        list.sort(sortByField('name'));
        return list;
      },
      get bookmarkedProjects(): ProjectModelType[] {
        const list: ProjectModelType[] = [];

        if (!dataStore.projectsList) {
          return list;
        }

        for (const project of dataStore.projectsList.values()) {
          if (project.bookmark_id) {
            list.push(project);
          }
        }

        list.sort(sortByField('name'));
        return list;
      },
      get hasBookmarkedProjects(): boolean {
        if (!dataStore.projectsList) {
          return false;
        }

        for (const project of dataStore.projectsList.values()) {
          if (project.bookmark_id) {
            return true;
          }
        }
        return false;
      },

      list(
        bookmarkedOnly?: boolean,
        tag?: string,
        sort?: string,
        gptStrategyType?: string
      ): ProjectModelType[] {
        if (!dataStore.projectsList || dataStore.projectsList.length < 0) {
          return [];
        }

        const list: ProjectModelType[] = [];

        for (const item of dataStore.projectsList.values()) {
          if (!item) {
            continue;
          }

          if (bookmarkedOnly && !item.bookmark_id) {
            continue;
          }

          if (
            tag &&
            (!item.briefing ||
              !item.briefing.tags ||
              !item.briefing.tags.includes(tag))
          ) {
            continue;
          }

          if (
            gptStrategyType &&
            (!item.gpt_strategy_type ||
              item.gpt_strategy_type !== gptStrategyType)
          ) {
            continue;
          }

          list.push(item);
        }

        let sortFunc;
        switch (sort) {
          case 'newest':
            sortFunc = sortCreatedDesc;
            break;

          case 'oldest':
            sortFunc = sortCreatedAsc;
            break;

          default:
            sortFunc = sortByField('name');
        }

        list.sort(sortFunc);

        return list;
      },
      listWithCurrentFilter(bookmarkedOnly?: boolean): ProjectModelType[] {
        return this.list(
          bookmarkedOnly,
          self.filter.get('tag'),
          self.filter.get('sort'),
          self.filter.get('gpt_strategy_type')
        );
      },

      listFilteredWithoutGPTStrategyType(
        bookmarkedOnly?: boolean
      ): ProjectModelType[] {
        return this.list(
          bookmarkedOnly,
          self.filter.get('tag'),
          self.filter.get('sort')
        );
      },

      get membersList(): MembersListType {
        const list: MembersListType = {
          editors: [],
          viewers: []
        };

        for (const orgMember of dataStore.orgMembers.values()) {
          const user = orgMember.user;
          if (!user) {
            continue;
          }

          const projectMember = dataStore.projectMembers.get(
            user.id.toString()
          );
          const isProjectMember =
            projectMember &&
            projectMember.access_level === PROJECT_ACCESS_LEVELS.MEMBER
              ? true
              : false;

          if (
            orgMember.access_level === ORG_ACCESS_LEVELS.ADMIN ||
            orgMember.access_level === ORG_ACCESS_LEVELS.USER
          ) {
            list.editors.push({
              user,
              isProjectMember,
              orgAccessLevel: orgMember.access_level
            });
          }

          if (orgMember.access_level === ORG_ACCESS_LEVELS.VIEWER) {
            list.viewers.push({
              user,
              isProjectMember
            });
          }
        }

        const userSort = sortUsers();
        const memberSort = (a: any, b: any) => userSort(a?.user, b?.user);

        list.editors.sort(memberSort);
        list.viewers.sort(memberSort);

        return list;
      },

      get tags(): string[] {
        let tags: string[] = [];

        if (!dataStore.projectsList) {
          return tags;
        }

        for (const item of dataStore.projectsList.values()) {
          if (item && item.briefing && item.briefing.tags) {
            tags.push(...item.briefing.tags.strings);
          }
        }

        tags = arrUnique(tags);
        tags.sort();

        return tags;
      },

      get gptStrategyTypes(): string[] {
        let strategyTypes: string[] = [];

        if (!dataStore.projectsList) {
          return strategyTypes;
        }

        for (const item of dataStore.projectsList.values()) {
          if (item && item.gpt_strategy_type) {
            strategyTypes.push(item.gpt_strategy_type);
          }
        }

        strategyTypes = arrUnique(strategyTypes);
        strategyTypes.sort();

        return strategyTypes;
      }
    };
  });

export type ProjectsStoreType = typeof ProjectsStore.Type;
export default ProjectsStore;
