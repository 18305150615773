import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import { AssistantSidebarProps } from 'domain/assistant/AssistantSidebar/AssistantSidebar';
import { SidebarMarkerType } from 'domain/assistant/AssistantSidebar/SidebarMarker';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { AssistantStoreType } from 'models/AssistantStore';
import { goToNextStep } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';

interface ExplanationScreenProps {
  location: HistoryProps['location'];
  progress?: number;
  barProgress?: number;
  sidebarHeadline?: React.ReactNode;
  header?: string | React.ReactNode;
  headerStep?: string | React.ReactNode;
  children?: any;
  videoId?: string;
  videoPreview?: string;
  videoHeadline?: React.ReactNode;
  buttonTextId?: string;
  timerSeconds?: number;
  nextRoute?: string;
  nextRouteIsAbsolute?: boolean;
  showHeader?: AssistantSidebarProps['showHeader'];
  sidebarScrollTo?: SidebarMarkerType;
  assistantStore?: AssistantStoreType;
}

@inject('assistantStore')
@observer
class ExplanationScreen extends React.Component<ExplanationScreenProps> {
  componentDidMount() {
    const { sidebarScrollTo } = this.props;

    if (sidebarScrollTo) {
      scrollSidebar(sidebarScrollTo);
    }
  }

  private async continueSession() {
    const { assistantStore, progress, nextRoute, nextRouteIsAbsolute } =
      this.props;
    const { currentId, currentAssistantType } = assistantStore!;

    if (!currentId || !currentAssistantType) {
      return;
    }

    const hasProgress = typeof progress === 'number';

    if (hasProgress) {
      await assistantStore!.updateAssistantSessionProgess(currentId, progress!);
    }

    if (nextRoute) {
      history.push(
        nextRouteIsAbsolute
          ? nextRoute
          : ROUTE_ASSISTANT + '/' + currentId + nextRoute
      );
    } else if (hasProgress) {
      goToNextStep(currentId, progress, currentAssistantType);
    }
  }

  render() {
    const {
      assistantStore,
      header,
      headerStep,
      children,
      videoId,
      videoPreview,
      videoHeadline,
      buttonTextId,
      barProgress,
      sidebarHeadline,
      timerSeconds,
      showHeader,
      location
    } = this.props;

    // TODO add video preview image properly
    return (
      <StepContainer
        progress={barProgress}
        sidebarHeadline={sidebarHeadline}
        timerSeconds={timerSeconds}
        showHeader={showHeader}
        location={location}
        sessionId={assistantStore!.currentId}
      >
        {assistantStore!.isItemSaving && <Loading />}

        <SessionTop>
          {header && (
            <SessionHeadline step={headerStep}>{header}</SessionHeadline>
          )}

          {children}

          {videoId && (
            <VideoPopupLink
              videoId={videoId}
              headline={videoHeadline}
              preview={videoPreview}
            />
          )}
        </SessionTop>

        <SessionBottom>
          <SessionActions>
            <MainButton onClick={() => this.continueSession()}>
              <FormattedMessage id={buttonTextId || 'Next'} />
            </MainButton>
          </SessionActions>
        </SessionBottom>
      </StepContainer>
    );
  }
}

export default ExplanationScreen;
