import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Loading from 'components/Loading';
import { AssistantType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import InviteFlow from './InviteFlow';
import FloatButton from 'components/FloatButton';
import { ApplicationStoreType } from 'models/ApplicationStore';

interface InviteButtonProps {
  dataStore?: DataStoreType;
  assistantStore?: AssistantStoreType;
  applicationStore?: ApplicationStoreType;
}

export default injectIntl(
  inject(
    'dataStore',
    'assistantStore',
    'applicationStore'
  )(
    observer(
      ({
        dataStore,
        assistantStore,
        applicationStore,
        intl
      }: InviteButtonProps & WrappedComponentProps) => {
        const [assistantType, setAssistantType] = useState<
          AssistantType | undefined
        >();

        if (!dataStore!.isProjectEditor) {
          return null;
        }

        const fm = (id: string, values?: any) =>
          intl.formatMessage({ id }, values);

        return (
          <>
            {assistantStore!.isItemSaving && <Loading />}

            <FloatButton
              label={fm('Invite')}
              iconName="persons"
              transformToIconName="plus"
              type="add"
              onClick={applicationStore?.toggleCreateSprintOverlay}
            />

            <InviteFlow
              active={!!assistantType}
              assistantType={assistantType}
              onFinish={() => setAssistantType(undefined)}
            />
          </>
        );
      }
    )
  )
);
