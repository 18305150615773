import classNames from 'classnames';

import LinkIf, { LinkIfProps } from 'components/LinkIf';

export interface LinkRowProps {
  links: Array<LinkIfProps & { content: string }>;
}

const linkClass = (active?: boolean, className?: string) =>
  classNames('link-row__link', { 'link-row__link--active': active }, className);

export default ({ links }: LinkRowProps) => (
  <div className="link-row">
    {links.map(({ isLink, className, to, content }) => (
      <LinkIf
        key={String(to)}
        isLink={isLink}
        className={linkClass(!isLink, className)}
        to={to}
      >
        {content}
      </LinkIf>
    ))}
  </div>
);
