import { types } from 'mobx-state-tree';

const values = ['draft', 'active', 'archived'];

export const PublishStateEnum = types.enumeration(values);

export const createPublishState = (
  value: any
): PublishStateEnumType | undefined =>
  values.includes(value) ? value : undefined;

export type PublishStateEnumType = typeof PublishStateEnum.Type;
export default PublishStateEnum;
