import classNames from 'classnames';
import Icon from 'components/Icon';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface PageLayoutProps {
  logoHeader?: React.ReactNode;
  title?: string;
  titleId?: string;
  avatar?: React.ReactNode;
  headline?: React.ReactNode;
  accounts?: React.ReactNode;
  reduced?: boolean;
  bold?: boolean;
  center?: boolean;
  wide?: boolean;
  // company?: React.ReactNode;
  mail?: React.ReactNode;
  onEditClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const PageHeader = ({
  logoHeader,
  title,
  titleId,
  avatar,
  headline,
  accounts,
  reduced,
  bold,
  center,
  // company,
  mail,
  wide,
  onEditClick,
  intl
}: PageLayoutProps & WrappedComponentProps) => {
  const pageHeaderClasses = classNames('page-header', {
    'page-header--reduced': reduced,
    'page-header--edit': !!onEditClick,
    'page-header--bold': bold,
    'page-header--center': center,
    'page-header--wide': wide
  });

  if (titleId) {
    title = intl.formatMessage({ id: titleId });
  }

  return (
    <header id="header" className={pageHeaderClasses} onClick={onEditClick}>
      <h1 className="page-header__title">
        <svg aria-hidden="true" focusable="false">
          <use
            xlinkHref={'/headlines/headline-symbols.svg#' + title}
            width="100%"
          />
        </svg>
        <span className="sr-only">{title}</span>
      </h1>
      <div className="page-header__content">
        {logoHeader}
        {avatar ? <div className="page-header__avatar">{avatar}</div> : null}
        <div className="page-header__inner">
          {headline && (
            <h2 className="page-header__headline">
              {headline}
              {!!onEditClick && (
                <button className="page-header__edit">
                  <Icon name="pen" />
                </button>
              )}
            </h2>
          )}
          {mail && (
            <h3 className="page-header__subline">
              {/* <span className="page-header__company">{company}</span> */}
              <span className="page-header__mail">{mail}</span>
            </h3>
          )}
        </div>

        {accounts ? (
          <div className="page-header__accounts">{accounts}</div>
        ) : null}
      </div>
    </header>
  );
};

export default injectIntl(PageHeader);
