import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import TagList from 'components/TagList';
import SharedContentDetailContainer from 'containers/SharedContentContainer/SharedContentDetailContainer';
import { SharedContentStoreType } from 'models/SharedContentStore';
import { HistoryProps, idFromMatch } from 'utils/history';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ObjectWrapper from 'components/Layout/ObjectWrapper';
import PainpointSubelementContainer from 'containers/PainpointSubelementContainer';
import TabContainer from 'components/TabContainer';
import TabItem from 'components/TabContainer/TabItem';
import CardSlider from 'components/CardSlider';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import { PrototypeModelType } from 'models/PrototypeModel';
import { HypothesisModelType } from 'models/HypothesisModel';
import PrototypesListItem from 'screens/prototypes/PrototypesListScreen/PrototypesListItem';

// tslint:disable: jsx-wrap-multiline

interface SharedPainpointScreenProps extends WrappedComponentProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedPainpointScreen extends React.Component<
  SharedPainpointScreenProps & HistoryProps
> {
  renderNotFound() {
    return (
      <SharedContentDetailContainer notFound={true} itemType="painpoints" />
    );
  }

  render() {
    const { sharedContentStore, intl } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return this.renderNotFound();
    }

    const item = sharedContentStore.painpoints?.get(id?.toString());
    if (!item) {
      return this.renderNotFound();
    }

    const prototypes: PrototypeModelType[] = item.sortedPrototypes;
    const hypotheses: HypothesisModelType[] = item.sortedHypotheses;

    return (
      <SharedContentDetailContainer itemType="painpoints">
        <PageHeader
          titleId="Painpoints"
          headline={
            <FormattedMessage
              id="painpoint question full"
              values={{ question: item.question }}
            />
          }
        />

        <ContentWrapper>
          <TagList tags={item.tags} />

          <ColumnWrapper gap="1em">
            <ObjectWrapper>
              <PainpointSubelementContainer type="Aim" painpoint={item} />
              <PainpointSubelementContainer type="Question" painpoint={item} />

              {(hypotheses?.length > 0 || prototypes?.length > 0) && (
                <TabContainer>
                  {hypotheses.length > 0 && (
                    <TabItem
                      key="hypotheses"
                      title={`${intl.formatMessage({ id: 'Hypotheses' })} (${
                        hypotheses.length
                      })`}
                    >
                      <CardSlider>
                        {hypotheses.map((hypothesis) => (
                          <HypothesesListItem
                            key={hypothesis.id}
                            hypothesis={hypothesis}
                            contextUri={sharedContentStore.contextUri}
                            readOnly={true}
                          />
                        ))}
                      </CardSlider>
                    </TabItem>
                  )}

                  {prototypes.length > 0 && (
                    <TabItem
                      key="prototypes"
                      title={`${intl.formatMessage({ id: 'Prototypes' })} (${
                        prototypes.length
                      })`}
                    >
                      <CardSlider value={prototypes.length}>
                        {prototypes.map((prototype) => (
                          <PrototypesListItem
                            key={prototype.id}
                            prototype={prototype}
                            contextUri={sharedContentStore.contextUri}
                            readOnly={true}
                          />
                        ))}
                      </CardSlider>
                    </TabItem>
                  )}
                </TabContainer>
              )}
            </ObjectWrapper>
            {item.publish_state !== 'draft' && (
              <CommentsContainer
                elementType={ElementType.Painpoint}
                element={item}
              />
            )}
          </ColumnWrapper>
        </ContentWrapper>
      </SharedContentDetailContainer>
    );
  }
}

export default injectIntl(SharedPainpointScreen);
