import classNames from 'classnames';
import Icon from 'components/Icon';

interface PageLogoHeaderProps {
  logo?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  strategyType?: React.ReactNode;
  list?: boolean;
  big?: boolean;
  extraPadding?: boolean;
  onEditClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function PageLogoHeader({
  logo,
  title,
  subtitle,
  strategyType,
  list,
  big,
  extraPadding,
  onEditClick
}: PageLogoHeaderProps) {
  const pageLogoHeaderClasses = classNames('page-logoHeader', {
    'page-logoHeader--list': list,
    'page-logoHeader--big': big,
    'page-logoHeader--extraPadding': extraPadding,
    'page-logoHeader--edit': !!onEditClick
  });

  return (
    <div className={pageLogoHeaderClasses} onClick={onEditClick}>
      {logo}
      <div className="page-logoHeader__inner">
        {!!strategyType && (
          <h3 className="page-logoHeader__strategy">#{strategyType}</h3>
        )}
        <h1 className="page-logoHeader__title">
          {title}
          {!!onEditClick && (
            <button className="page-logoHeader__edit">
              <Icon name="pen" />
            </button>
          )}
        </h1>
        {!!subtitle && (
          <h2 className="page-logoHeader__subtitle">{subtitle}</h2>
        )}
      </div>
    </div>
  );
}
