import EditMenu from 'components/EditMenu';
import MenuButton from 'components/MenuButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationModelType } from 'models/OrganizationModel';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { FormattedMessage, useIntl } from 'react-intl';

interface ICustomerListScreenMenuProps {
  dataStore?: DataStoreType;
  customer: OrganizationModelType;
  organizationsStore?: OrganizationsStoreType;
  applicationStore?: ApplicationStoreType;
  history: any;
  onDeleted?: () => void;
}
function CustomerListScreenMenu({
  dataStore,
  customer,
  organizationsStore,
  applicationStore,
  history,
  onDeleted
}: ICustomerListScreenMenuProps) {
  const intl = useIntl();

  const deleteCustomer = async (customerId: number) => {
    // TODO create nicer confirm?
    if (!window.confirm(intl.formatMessage({ id: 'remove comment confirm' }))) {
      return;
    }

    try {
      await organizationsStore!.deleteCustomer(
        dataStore!.currentOrganizationId!,
        customerId
      );

      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'Customer delete flash' })
      );

      onDeleted && onDeleted();
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'Customer delete error flash' }),
        'error'
      );
    }
  };

  return (
    <EditMenu label={'Edit'}>
      <MenuButton
        label={<FormattedMessage id="Customer visit" />}
        description={<FormattedMessage id="Customer visit desc" />}
        iconName="magnify"
        onClick={() => history.push(`/app/lab/${customer.id}`)}
      />
      <MenuButton
        label={<FormattedMessage id="Customer edit" />}
        description={<FormattedMessage id="Customer edit desc" />}
        iconName="pen"
        onClick={() => history.push(`/app/lab/${customer.id}/settings`)}
      />
      {onDeleted && (
        <MenuButton
          label={<FormattedMessage id="Customer remove" />}
          description={<FormattedMessage id="Customer remove desc" />}
          iconName="bin"
          onClick={() => deleteCustomer(customer.id)}
        />
      )}
    </EditMenu>
  );
}

export default inject(
  'dataStore',
  'organizationsStore',
  'applicationStore'
)(observer(CustomerListScreenMenu));
