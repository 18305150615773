export const sortCreatedAsc = (a: any, b: any) => {
  const ca = a?.created_at || 'Z';
  const cb = b?.created_at || 'Z';

  if (ca > cb) {
    return 1;
  }
  if (ca < cb) {
    return -1;
  }
  return 0;
};

export const sortCreatedDesc = (a: any, b: any) => {
  const ca = a.created_at || '0';
  const cb = b.created_at || '0';

  if (ca < cb) {
    return 1;
  }
  if (ca > cb) {
    return -1;
  }
  return 0;
};
