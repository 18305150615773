import classNames from 'classnames';
import DestroyButton from 'components/DestroyButton';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ClusterModelType, createClusterModel } from 'models/ClusterModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { Component, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicCardColumnHeaderProps extends WrappedComponentProps {
  cluster?: ClusterModelType;
  add?: boolean;
  count?: number;
  changeOnBlur?: boolean;
  mayEdit?: boolean;
  fixHeight?: number;
}

interface CardColumnHeaderState {
  edit?: boolean;
}

interface CardColumnHeaderProps extends PublicCardColumnHeaderProps {
  form: FormType;
  // dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  applicationStore: ApplicationStoreType;
}

const InputForm = ({
  fixHeight,
  ...rest
}: {
  fixHeight?: number;
  rest: any;
}) => {
  return fixHeight ? (
    <textarea {...(rest as TextareaHTMLAttributes<HTMLTextAreaElement>)} />
  ) : (
    <input type="text" {...(rest as InputHTMLAttributes<HTMLInputElement>)} />
  );
};

@inject('painpointsStore', 'applicationStore')
@observer
class CardColumnHeader extends Component<
  CardColumnHeaderProps,
  CardColumnHeaderState
> {
  state: CardColumnHeaderState = {};

  beginEdit() {
    const { cluster, form } = this.props;

    form.setField('name', cluster?.name || '');

    this.setState({
      edit: true
    });
  }

  finishEdit() {
    this.setState({
      edit: undefined
    });
  }

  async save(blur?: boolean) {
    const { form, painpointsStore, add, cluster } = this.props;

    const name = (form.values.name || '').toString().trim();
    if (name === '') {
      this.finishEdit();
      return;
    }

    form.setLoading(true);

    try {
      if (add) {
        await painpointsStore.createCluster(
          createClusterModel({
            name
          })
        );
      } else if (cluster) {
        await painpointsStore.updateCluster(cluster.id, {
          name
        });
      }
    } catch (error: any) {
      this.displayError();
    }

    this.finishEdit();
    form.setLoading(false);
  }

  checkRemove() {
    const { count, intl } = this.props;

    if (
      count &&
      count > 0 &&
      !confirm(intl.formatMessage({ id: 'cluster remove alert' }))
    ) {
      return;
    }

    this.remove();
  }

  async remove() {
    const { form, painpointsStore, cluster } = this.props;
    if (!cluster || !cluster.id) {
      return;
    }

    form.setLoading(true);

    try {
      await painpointsStore.deleteCluster(cluster.id);
    } catch (error: any) {
      this.displayError();
      form.setLoading(false);
    }
  }

  abort() {
    this.finishEdit();
  }

  displayError() {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: 'save error flash' }),
      'error'
    );
  }

  renderAddContainer(content: any) {
    return <div className="card-column card-column--add">{content}</div>;
  }

  renderEdit() {
    const { form, changeOnBlur, add, fixHeight } = this.props;

    const input = (
      <InputForm
        className={classNames('card-column__head', {
          'card-column__head--input': !fixHeight,
          'card-column__head--textarea': fixHeight
        })}
        style={{
          height: fixHeight ? `${fixHeight + 1}em` : undefined
        }}
        fixHeight={fixHeight}
        name="name"
        autoFocus={true}
        {...form.bindInput('name')}
        onBlur={() => {
          if (changeOnBlur) {
            this.save(true);
          } else {
            this.abort();
          }
        }}
        onFocus={(e: any) => e.target.select()}
        onKeyUp={(e: any) => {
          if (e.keyCode === 27) {
            this.abort();
          }
        }}
        onKeyPress={(e: any) => {
          if (e.charCode === 13) {
            this.save();
          }
        }}
      />
    );

    if (add) {
      return this.renderAddContainer(input);
    }

    return input;
  }

  renderAdd() {
    const { intl } = this.props;

    return this.renderAddContainer(
      <button
        className="card-column__head card-column__head--add"
        onClick={() => this.beginEdit()}
      >
        <Icon name="plus" />
        <h3 className="card-column__title">
          &nbsp;&nbsp;{intl.formatMessage({ id: 'Add cluster' })}
        </h3>
      </button>
    );
  }

  renderHeader() {
    const { cluster, count, mayEdit, form, intl, fixHeight } = this.props;

    if (!cluster) {
      return (
        <div
          className="card-column__head"
          style={{
            height: fixHeight ? `${fixHeight}em` : undefined
          }}
        >
          <h3 className="card-column__title">
            {intl.formatMessage({ id: 'New painpoints' })}
          </h3>
          {count ? (
            <span className="card-column__value">&nbsp;({count})</span>
          ) : null}
        </div>
      );
    }

    const { loading } = form;

    const content = (
      <>
        <h3
          className={classNames('card-column__title', {
            'card-column__head--line-clamp-3': fixHeight
          })}
          title={cluster.name}
        >
          {loading
            ? form.values.name
            : cluster.name || intl.formatMessage({ id: 'Insert title' })}
        </h3>
        {count ? (
          <span className="card-column__value">&nbsp;({count})</span>
        ) : null}
      </>
    );

    if (!mayEdit) {
      return <div className="card-column__head">{content}</div>;
    }

    return (
      <>
        <button
          className="card-column__head"
          style={{
            height: fixHeight ? `${fixHeight}em` : undefined
          }}
          onClick={() => this.beginEdit()}
          disabled={loading}
        >
          {loading && <Loading />}
          {content}
        </button>
        <DestroyButton
          label={intl.formatMessage({ id: 'Remove' })}
          iconName="cross"
          onClick={() => this.checkRemove()}
        />
      </>
    );
  }

  render() {
    if (this.state.edit) {
      return this.renderEdit();
    }

    if (this.props.add) {
      return this.renderAdd();
    }

    return this.renderHeader();
  }
}

export default injectIntl((props: PublicCardColumnHeaderProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <CardColumnHeader {...props} form={form} />;
});
