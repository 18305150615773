import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';

import Bookmark from 'components/Bookmark';
import { BookmarkRemovedEvent } from 'components/Bookmark/Bookmark';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import CompanyLogo from 'components/CompanyLogo';
import EditMenu from 'components/EditMenu';
import HashtagsList from 'components/HashtagsList';
import IconCounter from 'components/IconCounter';
import MenuButton from 'components/MenuButton';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { isDefined } from 'utils/misc/is-defined';
import shortenString from 'utils/misc/shorten-string';
import UserAvatar from 'components/Avatar/UserAvatar';
import { ROUTE_PROFILE } from 'utils/constants/routes';

export default injectIntl(
  ({
    benchmark,
    checked,
    onChange,
    mayEdit,
    onEditClick,
    onRemoveClick,
    onBigRemoveClick,
    intl,
    to,
    contextUri,
    readOnly,
    fromList,
    onBookmarkRemoved,
    top,
    noMenu
  }: {
    benchmark: BenchmarkModelType;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    mayEdit?: boolean;
    onEditClick?: () => void;
    onRemoveClick?: () => void;
    onBigRemoveClick?: () => void;
    to?: LinkProps['to'];
    contextUri?: string;
    readOnly?: boolean;
    fromList?: boolean;
    onBookmarkRemoved?: BookmarkRemovedEvent;
    top?: React.ReactNode;
    noMenu?: boolean;
  } & WrappedComponentProps) => {
    const attachment = benchmark.attachments.firstOfType('screen');
    const logo = benchmark.attachments.firstOfType('logo');

    const isDraft = benchmark.publish_state === 'draft';

    let menu;
    if (mayEdit && !noMenu && (onEditClick || onRemoveClick)) {
      menu = (
        <EditMenu bottomLeft={true} label={intl.formatMessage({ id: 'Edit' })}>
          {onEditClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              description={intl.formatMessage({
                id: 'benchmark edit desc'
              })}
              iconName="pen"
              onClick={onEditClick}
            />
          ) : null}
          {onRemoveClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              description={intl.formatMessage({
                id: 'benchmark remove desc'
              })}
              iconName="bin"
              onClick={onRemoveClick}
            />
          ) : null}
        </EditMenu>
      );
    }

    if (!to) {
      to = {
        pathname: `${
          isDefined(benchmark.project)
            ? `/app/lab/${benchmark.project.organization_id}/${benchmark.project.id}/`
            : contextUri
            ? contextUri + '/'
            : ''
        }benchmarks/${benchmark.id}${isDraft ? '/edit' : ''}`
      };
      if (fromList) {
        to.state = {
          switch: true
        };
      }
    }

    return (
      <RainbowCard
        to={to}
        src={attachment?.resource_urls?.large}
        alt=""
        checked={checked}
        onCheckboxChange={onChange}
        onRemoveClick={onBigRemoveClick}
        sideLabel={isDraft ? intl.formatMessage({ id: 'Draft' }) : undefined}
        top={top}
      >
        <CardHeader
          title={
            benchmark.headline || intl.formatMessage({ id: 'no headline' })
          }
          subtitle={benchmark.company_name}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt={intl.formatMessage({ id: 'Logo' })}
              />
            )
          }
        />

        <Typography size="small">
          {shortenString(benchmark.description, LIST_ITEM_TEXT_LENGTH)}
        </Typography>

        <HashtagsList tags={benchmark.tags} />

        {!noMenu && (
          <CardMenu
            avatar={
              <UserAvatar
                user={benchmark.author}
                isAiRobot={benchmark.created_by_ai}
              />
            }
          >
            <Bookmark
              elementType="Benchmark"
              elementId={benchmark.id}
              count={benchmark.bookmarks_count}
              id={benchmark.bookmark_id}
              disabled={readOnly}
              onRemoved={onBookmarkRemoved}
            />
            <IconCounter
              value={benchmark.comments_count || 0}
              label={intl.formatMessage({ id: 'Comments' })}
              iconName="comment"
            />

            {menu}

            {benchmark.created_by_ai && benchmark.author && (
              <span className="ai-author">
                Generated by{' '}
                <Link
                  to={ROUTE_PROFILE.replace(
                    ':id',
                    benchmark.author.id!.toString()
                  )}
                >
                  <span className="underline">{benchmark.author.fullName}</span>
                </Link>
              </span>
            )}
          </CardMenu>
        )}
      </RainbowCard>
    );
  }
);
