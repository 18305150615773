import ActionOverlaySelectButton from 'components/ActionOverlaySelectButton';
import AddButton from 'components/AddButton';
import UserAvatar from 'components/Avatar/UserAvatar';
import BenchmarkSelectPopup from 'components/BenchmarkSelectPopup';
import Bookmark from 'components/Bookmark';
import CardMenu from 'components/CardMenu';
import SimpleCardSlider, {
  smallSliderResponsiveConfig
} from 'components/CardSlider';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import EditableCard from 'components/EditableCard/EditableCard';
import EditMenu from 'components/EditMenu';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormChangeHandler from 'components/FormChangeHandler/FormChangeHandler';
import FormTagList from 'components/FormTagList';
import Headline from 'components/headline/headline';
import Icon from 'components/Icon';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import MenuButton from 'components/MenuButton';
import MobilePreview from 'components/MobilePreview';
import PageHeader from 'components/PageHeader';
import PageLogoEdit from 'components/PageLogoEdit';
import PageLogoHeader from 'components/PageLogoHeader';
import PageSwitch from 'components/PageSwitch';
import PainpointSelectPopup from 'components/PainpointSelectPopup';
import SimplePopup from 'components/SimplePopup';
import TagList from 'components/TagList';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { inject, observer } from 'mobx-react';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { tagsListToArray } from 'models/TagListModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import BenchmarksCompactItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksCompactItem';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import { ROUTE_PROFILE } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import { idFromProps } from 'utils/history/param-from-props';
import { scrollToTop } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable: jsx-wrap-multiline

interface PublicHypothesisDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
  create?: boolean;
  simple?: boolean;
  withoutComment?: boolean;
  hideClose?: boolean;
}

interface HypothesisDetailContainerProps
  extends PublicHypothesisDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  hypothesesStore: HypothesesStoreType;
  actionsStore: ActionsStoreType;
  form: FormType;
}

interface HypothesisDetailContainerState {
  painpointSelectVisible?: boolean;
  benchmarksSelectVisible?: boolean;
  showImagePromptPopup?: boolean;
}

@inject('applicationStore', 'dataStore', 'hypothesesStore', 'actionsStore')
@observer
class HypothesisDetailContainer extends React.Component<
  HypothesisDetailContainerProps & HistoryProps,
  HypothesisDetailContainerState
> {
  state: HypothesisDetailContainerState = {};

  componentDidMount() {
    if (this.props.create) {
      this.props.dataStore.setHypothesisItem(undefined);
      this.props.form.reset();
      return;
    }

    const id = idFromProps(this.props);
    if (!id) {
      return;
    }

    const {
      dataStore: { hypothesisItem }
    } = this.props;
    if (
      isPush(this.props.history) ||
      !hypothesisItem ||
      hypothesisItem.id !== id
    ) {
      this.loadHypothesis(id);
    }
  }

  componentDidUpdate(
    prevProps: PublicHypothesisDetailContainerProps & HistoryProps
  ) {
    const id = idFromProps(this.props);
    const prevId = idFromProps(prevProps);

    if (this.props.create && !prevProps.create) {
      this.props.dataStore.setHypothesisItem(undefined);
      this.props.form.reset();
      return;
    }

    if (id && id !== prevId) {
      this.loadHypothesis(id);
    }
  }

  load() {
    const id = idFromProps(this.props);
    if (id) {
      this.loadHypothesis(id);
    }
  }

  async loadHypothesis(id: number) {
    const { form, hypothesesStore } = this.props;

    form.reset();

    const hypothesis = await hypothesesStore.getHypothesis(id);

    if (hypothesis) {
      if (this.props.edit) {
        form.setField('headline', hypothesis.headline);
        form.setField('description', hypothesis.description);
        form.setField('problem', hypothesis.problem);
        form.setField('added_value', hypothesis.added_value);
      } else if (hypothesis.publish_state === 'draft') {
        this.props.history.replace(hypothesis.id + '/edit');
      }

      form.setField('tags', tagsListToArray(hypothesis.tags));
      form.setField('image_prompt', hypothesis.image_prompt);
    }
  }

  async save(buttonSave = false, publish = false) {
    const { dataStore, hypothesesStore, form, create } = this.props;

    const patch: any = {
      ...form.values,
      painpoint_id: dataStore.hypothesisItemPainpoint
        ? dataStore.hypothesisItemPainpoint.id
        : null,
      benchmark_ids: hypothesesStore.itemBenchmarkIds
    };

    if (publish) {
      patch.publish_state = 'active';
    }

    try {
      let item = dataStore.hypothesisItem;
      if (create && !item) {
        item = await hypothesesStore.createHypothesis(patch, true);
      } else {
        if (!item || (!create && item.id !== idFromProps(this.props))) {
          return;
        }

        // TODO update form after update?
        item = await hypothesesStore.updateHypothesis(item.id, patch);
      }

      if (buttonSave) {
        if (hypothesesStore.isItemSaveError) {
          this.displayError();
        } else {
          this.props.history.replace(dataStore.contextUri + '/hypotheses');
        }
      }
    } catch (error: any) {
      if (handleFormError(form, error)) {
        scrollToTop();
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { dataStore, hypothesesStore } = this.props;
    const item = dataStore.hypothesisItem;

    if (!item) {
      return;
    }

    try {
      await hypothesesStore.updateHypothesis(item.id, {
        tags: newTags || []
      });
    } catch (e) {
      // TODO How to handle this?
    }
  }

  async remove() {
    const { intl, hypothesesStore, dataStore, history } = this.props;

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage({ id: 'remove hypotheses confirm' }, { count: 1 })
      )
    ) {
      return;
    }

    if (!dataStore.hypothesisItem) {
      return;
    }

    try {
      await hypothesesStore.deleteHypothesis(dataStore.hypothesisItem.id);

      history.replace(dataStore.contextUri + '/hypotheses');
    } catch (error: any) {
      this.displayError('remove');
    }
  }

  async newImagePrompt() {
    const { form, hypothesesStore, dataStore, applicationStore, intl } =
      this.props;
    const item = dataStore.hypothesisItem;

    if (!item) {
      return;
    }

    try {
      this.setState({ showImagePromptPopup: false });
      await hypothesesStore.newImageForHypothesis(
        item.id,
        form.values.image_prompt
      );
    } catch (error: any) {
      applicationStore.setFlashMessage(
        intl.formatMessage({
          id: 'image prompt error'
        }),
        'error'
      );
    }

    form.setEditing();
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    const { form, dataStore } = this.props;

    if (!newState) {
      form.setLoading(false);
      return;
    }

    if (newState === 'saving' && !dataStore.hypothesisItem?.id) {
      // item has not been saved yet, so we need to wait for the upload before we can reenable form
      form.setLoading(true);
    }
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { hypothesesStore, dataStore } = this.props;

    if (!attachment) {
      return;
    }

    if (dataStore.hypothesisItem) {
      hypothesesStore.addAttachment(attachment);
    } else if (attachment.element_id) {
      hypothesesStore.initializeItem({
        id: attachment.element_id,
        attachment: attachment
      });
    }
  }

  attachmentRemoved(id?: number) {
    this.props.hypothesesStore.removeAttachment();
  }

  removePainpoint(save: boolean = false) {
    this.props.dataStore.setHypothesisItemPainpoint(undefined);

    if (save) {
      this.savePainpoint(null);
    }
  }

  beginSelectPainpoint() {
    this.setState({
      painpointSelectVisible: true
    });
  }

  selectPainpoint(id: number, save: boolean = false) {
    const { dataStore } = this.props;

    const painpoint = dataStore.painpoints.get(id.toString());
    if (painpoint) {
      dataStore.setHypothesisItemPainpoint(painpoint);
    }

    this.finishSelect();

    if (save) {
      this.savePainpoint(id);
    }
  }

  async savePainpoint(id?: number | null | undefined) {
    const { dataStore, hypothesesStore } = this.props;

    if (dataStore.hypothesisItem) {
      try {
        await hypothesesStore.updateHypothesis(dataStore.hypothesisItem.id, {
          painpoint_id: id || null
        });

        if (hypothesesStore.isItemSaveError) {
          this.displayError();
          return;
        }
      } catch (error: any) {
        // there should be no form error here
      }
    }
  }

  removeBenchmark(id: number, save: boolean = false) {
    const { dataStore, hypothesesStore } = this.props;

    const benchmarks = hypothesesStore.itemBenchmarks.filter(
      (elem) => elem.id !== id
    );
    dataStore.setHypothesisItemBechmarks(benchmarks);

    if (save && dataStore.hypothesisItem) {
      this.saveBenchmarks(benchmarks.map((benchmark) => benchmark.id));
    }
  }

  beginSelectBenchmarks() {
    this.setState({
      benchmarksSelectVisible: true
    });
  }

  selectBenchmarks(ids: number | number[], save: boolean = false) {
    const { dataStore } = this.props;

    const benchmarks: BenchmarkModelType[] = [];

    if (typeof ids === 'number') {
      ids = [ids];
    }
    for (const id of ids) {
      const benchmark = dataStore.benchmarks.get(id.toString());
      if (benchmark) {
        benchmarks.push(benchmark);
      }
    }

    dataStore.setHypothesisItemBechmarks(benchmarks);

    this.finishSelect();

    if (save) {
      this.saveBenchmarks(ids);
    }
  }

  async saveBenchmarks(ids: number[]) {
    const { dataStore, hypothesesStore } = this.props;

    if (dataStore.hypothesisItem) {
      try {
        await hypothesesStore.updateHypothesis(dataStore.hypothesisItem.id, {
          benchmark_ids: ids
        });

        if (hypothesesStore.isItemSaveError) {
          this.displayError();
          return;
        }
      } catch (error: any) {
        // there should be no form error here
      }
    }
  }

  finishSelect() {
    this.setState({
      painpointSelectVisible: false,
      benchmarksSelectVisible: false
    });
  }

  async saveFieldUpdate(names: string[]) {
    const { form, hypothesesStore, dataStore } = this.props;

    if (!dataStore.hypothesisItem) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await hypothesesStore.updateHypothesis(
        dataStore.hypothesisItem.id,
        patch
      );

      if (hypothesesStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  editTitle() {
    const { form, dataStore } = this.props;
    const item = dataStore.hypothesisItem;

    if (!item) {
      return;
    }

    form.setField('headline', item.headline);

    form.setEditing('title');
  }

  isFromEmptyState() {
    return this.props.location.query?.empty === 'yes';
  }

  links() {
    const { dataStore, intl } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: false,
        to: contextUri + '/hypotheses',
        content: intl.formatMessage({ id: 'Hypotheses' })
      },
      {
        isLink: true,
        to: contextUri + '/hypotheses/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderPageSwitch() {
    if (!this.props.location?.state?.switch) {
      return;
    }

    const { dataStore, hypothesesStore, history } = this.props;
    return (
      <PageSwitch
        history={history}
        basePath={dataStore.contextUri + '/hypotheses/'}
        pageSwitch={hypothesesStore.pageSwitch(dataStore.hypothesisItem)}
      />
    );
  }

  renderPage(content: any) {
    const {
      dataStore: {
        hypothesisItem,
        contextUri,
        currentOrganizationId,
        currentProjectId
      },
      location,
      actionsStore,
      actionsStore: { selectionSet },
      intl,
      history,
      hideClose
    } = this.props;

    const showClose = !hideClose && !location?.state?.isRedirect;
    const checked: number[] = actionsStore.selectedIds.Hypothesis;
    const isSelected =
      (hypothesisItem && checked.indexOf(hypothesisItem.id) > -1) || false;

    return (
      <>
        {content}

        {showClose && (
          <OverlayWrapper topRight={true} twoButtons={true}>
            <CloseButton
              label={intl.formatMessage({ id: 'Close' })}
              iconName="cross"
              onClick={() => history.push(contextUri + '/hypotheses')}
            />
            <ActionOverlaySelectButton
              onClick={() => {
                const organizationId = currentOrganizationId!;
                const projectId = currentProjectId!;

                selectionSet(
                  {
                    Hypothesis: [
                      {
                        id: hypothesisItem?.id!,
                        organizationId: organizationId,
                        projectId,
                        publishState: hypothesisItem?.publish_state
                      }
                    ]
                  },
                  !isSelected
                );
              }}
              selected={isSelected}
            />
          </OverlayWrapper>
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(
      <>
        {!this.props.simple && <PageHeader titleId="Hypotheses" />}
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        {!this.props.simple && <PageHeader titleId="Hypotheses" />}
        <ErrorMessage
          state={this.props.hypothesesStore.itemLoadingState}
          onRetry={() => this.load()}
        />
      </>
    );
  }

  renderPainpointSelect(save: boolean = false) {
    if (!this.state.painpointSelectVisible) {
      return null;
    }

    return (
      <PainpointSelectPopup
        onAbort={() => this.finishSelect()}
        onSelect={(id) => this.selectPainpoint(id, save)}
      />
    );
  }

  renderBenchmarkSelect(save: boolean = false) {
    if (!this.state.benchmarksSelectVisible) {
      return null;
    }

    return (
      <BenchmarkSelectPopup
        selected={this.props.hypothesesStore.itemBenchmarkIds}
        onAbort={() => this.finishSelect()}
        onSelect={(ids) => this.selectBenchmarks(ids, save)}
      />
    );
  }

  renderEditMode() {
    const { hypothesesStore, dataStore, form, intl } = this.props;

    const item = dataStore.hypothesisItem;
    const painpoint = dataStore.hypothesisItemPainpoint;

    let attachment: AttachmentModelType | undefined;
    if (item) {
      attachment = item.attachment;
    }

    const itemIsActive = item && item.publish_state === 'active';

    return this.renderPage(
      <>
        {itemIsActive && hypothesesStore.isItemSaving && <Loading />}

        {this.renderPainpointSelect(true)}
        {this.renderBenchmarkSelect(true)}

        {this.renderPageSwitch()}

        <FormChangeHandler
          halted={itemIsActive}
          onSave={() => this.save()}
          isSaving={hypothesesStore.isItemSaving}
        >
          {({ onTextChanged, hasUnsavedChanges, isSaving }) => (
            <>
              {itemIsActive && hasUnsavedChanges && (
                <Prompt
                  message={intl.formatMessage({ id: 'unsaved prompt' })}
                />
              )}
              <Form loading={form.loading} onSubmit={() => this.save(true)}>
                <PageHeader titleId="Hypotheses" />

                <ContentWrapper>
                  <FormTagList
                    form={form}
                    field="tags"
                    allowAdd={true}
                    onChanged={onTextChanged}
                  />

                  {this.isFromEmptyState() && (
                    <div style={{ marginBottom: '3em' }}>
                      <LinkRow links={this.links()} />
                    </div>
                  )}

                  <ColumnWrapper gap="2em" noTranslate={true}>
                    <RainbowEditWrapper>
                      <RainbowEditWrapper.Image>
                        {attachment ? (
                          <UploadedFileContainer
                            attachment={attachment}
                            onAttachmentRemoved={() => this.attachmentRemoved()}
                            key={attachment.id}
                          >
                            {(props) => (
                              <UploadedMobileImage {...props} overflow={true} />
                            )}
                          </UploadedFileContainer>
                        ) : (
                          <UploadContainer
                            attachmentType="screen"
                            elementType={ElementType.Hypothesis}
                            elementId={item?.id}
                            acceptVideo={true}
                            waitForId={
                              hasUnsavedChanges || hypothesesStore.isItemSaving
                            }
                            onLoadingStateChanged={(s) =>
                              this.uploadLoadingStateChanged(s)
                            }
                            onAttachmentReceived={(a) =>
                              this.attachmentReceived(a)
                            }
                          >
                            {(props: UploadContainerChildProps) => (
                              <UploadMobileButton {...props} overflow={true} />
                            )}
                          </UploadContainer>
                        )}
                      </RainbowEditWrapper.Image>

                      <RainbowEditWrapper.Body>
                        <CardWrapper>
                          <Input
                            headline={true}
                            name="headline"
                            placeholder={intl.formatMessage({
                              id: 'Insert headline'
                            })}
                            {...form.bindInput('headline', onTextChanged)}
                          />
                        </CardWrapper>

                        <CardWrapper>
                          <Textarea
                            label={
                              <FormattedMessage id="solution description" />
                            }
                            name="description"
                            {...form.bindInput('description', onTextChanged)}
                          />
                        </CardWrapper>

                        <CardWrapper>
                          <Textarea
                            label={
                              <FormattedMessage id="problem description" />
                            }
                            name="problem"
                            {...form.bindInput('problem', onTextChanged)}
                          />
                        </CardWrapper>

                        <CardWrapper>
                          <Textarea
                            label={
                              <FormattedMessage id="added value description" />
                            }
                            name="added_value"
                            {...form.bindInput('added_value', onTextChanged)}
                          />
                        </CardWrapper>
                      </RainbowEditWrapper.Body>
                    </RainbowEditWrapper>

                    <ColumnWrapper gap="1em">
                      <SimpleCardSlider
                        responsiveConfig={smallSliderResponsiveConfig}
                      >
                        {painpoint ? (
                          <PainpointsListItem
                            painpoint={painpoint}
                            contextUri={dataStore.contextUri}
                            readOnly={true}
                            onBigRemoveClick={() => this.removePainpoint(true)}
                          />
                        ) : (
                          <AddButton
                            iconName="plus"
                            label={intl.formatMessage({
                              id: 'select painpoint question'
                            })}
                            onClick={() => this.beginSelectPainpoint()}
                          />
                        )}

                        {hypothesesStore.itemBenchmarks.map((benchmark) => (
                          <React.Fragment key={benchmark.id}>
                            <BenchmarksCompactItem
                              onBigRemoveClick={() =>
                                this.removeBenchmark(benchmark.id, true)
                              }
                              benchmark={benchmark}
                              contextUri={dataStore.contextUri}
                              readOnly={true}
                            />
                          </React.Fragment>
                        ))}
                        <AddButton
                          iconName="plus"
                          label={intl.formatMessage({ id: 'Add benchmarks' })}
                          onClick={() => this.beginSelectBenchmarks()}
                        />
                      </SimpleCardSlider>

                      <PositionWrapper end={true}>
                        {itemIsActive ? (
                          <MainButton type="submit">
                            <FormattedMessage id="Save" />
                          </MainButton>
                        ) : (
                          <RowWrapper gap="1em" alignRight={true}>
                            <MainButton type="submit" secondary={true}>
                              {isSaving && <Loading />}
                              <FormattedMessage id="Save draft" />
                            </MainButton>
                            <MainButton
                              type="button"
                              onClick={() => this.save(true, true)}
                            >
                              <FormattedMessage id="Publish" />
                            </MainButton>
                          </RowWrapper>
                        )}
                      </PositionWrapper>
                    </ColumnWrapper>
                  </ColumnWrapper>
                </ContentWrapper>
              </Form>
            </>
          )}
        </FormChangeHandler>
      </>
    );
  }

  renderImagePromptPopup() {
    const { form, intl } = this.props;

    return (
      <SimplePopup
        onSubmit={() => this.newImagePrompt()}
        isLoading={form!.loading}
        onAbort={() => this.setState({ showImagePromptPopup: false })}
      >
        <ColumnWrapper gap="1em">
          <Headline center={true}>
            <FormattedMessage id="image prompt edit" />
          </Headline>
          <div className="l-screen-item__description l-screen-item__description--auto">
            <Textarea
              name="image_prompt"
              normalFont={true}
              placeholder={intl.formatMessage({
                id: 'image prompt'
              })}
              lines={8}
              {...form!.bindInput('image_prompt')}
            />
          </div>
        </ColumnWrapper>
      </SimplePopup>
    );
  }

  renderViewMode() {
    const { intl, dataStore, hypothesesStore, form } = this.props;

    const item = this.props.dataStore.hypothesisItem;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author) && !this.props.simple;

    const attachment = item.attachment;

    let phone;
    if (mayEdit) {
      phone = attachment ? (
        <UploadedFileContainer
          attachment={attachment}
          onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
          key={attachment.id}
        >
          {(props) => <UploadedMobileImage {...props} overflow={false} />}
        </UploadedFileContainer>
      ) : (
        <UploadContainer
          attachmentType="screen"
          elementType={ElementType.Hypothesis}
          elementId={item.id}
          acceptVideo={true}
          onLoadingStateChanged={(s) => this.uploadLoadingStateChanged(s)}
          onAttachmentReceived={(a) => this.attachmentReceived(a)}
        >
          {(props: UploadContainerChildProps) => (
            <UploadMobileButton {...props} overflow={true} />
          )}
        </UploadContainer>
      );
    } else {
      phone =
        attachment && attachment.resource_urls ? (
          <UploadedMobileImage attachment={attachment} overflow={false} />
        ) : (
          <MobilePreview alt="Empty attachment" overflow={true} />
        );
    }

    return this.renderPage(
      <>
        {hypothesesStore.isItemSaving && form.editing !== 'title' && (
          <Loading />
        )}

        {this.renderPainpointSelect(true)}
        {this.renderBenchmarkSelect(true)}

        {this.renderPageSwitch()}

        {!this.props.simple && (
          <PageHeader
            titleId="Hypotheses"
            logoHeader={
              <PageLogoHeader
                title={
                  item.headline || intl.formatMessage({ id: 'no headline' })
                }
                onEditClick={!mayEdit ? undefined : () => this.editTitle()}
              />
            }
          />
        )}

        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            !this.props.simple && <TagList tags={item.tags} />
          )}

          <RainbowEditWrapper>
            <RainbowEditWrapper.Image>
              {phone}
              <div style={{ width: 'calc(100% + 1.1rem)' }}>
                <CardWrapper
                  onClick={() => this.setState({ showImagePromptPopup: true })}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '0.625rem'
                    }}
                  >
                    <Icon name="pen" />
                    <FormattedMessage id="image prompt edit" />
                  </div>
                </CardWrapper>
              </div>
              {this.state.showImagePromptPopup && this.renderImagePromptPopup()}
            </RainbowEditWrapper.Image>

            <RainbowEditWrapper.Body>
              <EditableCard
                form={form}
                name="description"
                value={item.description}
                label={<FormattedMessage id="solution description" />}
                editable={mayEdit && !this.props.simple}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['description'])}
              />

              <EditableCard
                form={form}
                name="problem"
                value={item.problem}
                label={<FormattedMessage id="problem description" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['problem'])}
              />

              <EditableCard
                form={form}
                name="added_value"
                value={item.added_value}
                label={<FormattedMessage id="added value description" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['added_value'])}
                extra={
                  <CardMenu
                    avatar={
                      <UserAvatar
                        user={item.author}
                        isAiRobot={item.created_by_ai}
                      />
                    }
                  >
                    <Bookmark
                      elementType="Hypothesis"
                      elementId={item.id}
                      count={item.bookmarks_count}
                      id={item.bookmark_id}
                    />
                    {mayEdit && (
                      <EditMenu
                        bottomLeft={true}
                        label={intl.formatMessage({ id: 'Edit' })}
                      >
                        <MenuButton
                          label={intl.formatMessage({ id: 'Edit' })}
                          description={intl.formatMessage({
                            id: 'hypothesis edit desc'
                          })}
                          iconName="pen"
                          onClick={() => this.editTitle()}
                        />
                        <MenuButton
                          label={intl.formatMessage({ id: 'Remove' })}
                          description={intl.formatMessage({
                            id: 'hypothesis remove desc'
                          })}
                          iconName="bin"
                          onClick={() => this.remove()}
                        />
                      </EditMenu>
                    )}
                    {item.created_by_ai && item.author && (
                      <span className="ai-author">
                        Generated by{' '}
                        <Link
                          to={ROUTE_PROFILE.replace(
                            ':id',
                            item.author.id!.toString()
                          )}
                        >
                          <span className="underline">
                            {item.author.fullName}
                          </span>
                        </Link>
                      </span>
                    )}
                  </CardMenu>
                }
              />
            </RainbowEditWrapper.Body>
          </RainbowEditWrapper>

          {(mayEdit ||
            item.painpoint ||
            (item.benchmarks && item.benchmarks.length > 0)) &&
          !this.props.simple ? (
            <SimpleCardSlider
              responsiveConfig={smallSliderResponsiveConfig}
              title={intl.formatMessage({ id: 'painpoint slider header' })}
            >
              {item.painpoint ? (
                <PainpointsListItem
                  painpoint={item.painpoint}
                  contextUri={dataStore.contextUri}
                  readOnly={true}
                  onBigRemoveClick={
                    !mayEdit ? undefined : () => this.removePainpoint(true)
                  }
                />
              ) : (
                mayEdit && (
                  <AddButton
                    iconName="plus"
                    label={intl.formatMessage({
                      id: 'select painpoint question'
                    })}
                    onClick={() => this.beginSelectPainpoint()}
                  />
                )
              )}

              {item.benchmarks?.length
                ? item.benchmarks.map((benchmark) => (
                    <BenchmarksCompactItem
                      key={benchmark.id}
                      benchmark={benchmark}
                      contextUri={dataStore.contextUri}
                      readOnly={true}
                      onBigRemoveClick={
                        !mayEdit
                          ? undefined
                          : () => this.removeBenchmark(benchmark.id, true)
                      }
                    />
                  ))
                : null}
              {mayEdit && (
                <AddButton
                  iconName="plus"
                  label={intl.formatMessage({ id: 'Add benchmarks' })}
                  onClick={() => this.beginSelectBenchmarks()}
                />
              )}
            </SimpleCardSlider>
          ) : null}

          {item.publish_state !== 'draft' && !this.props.withoutComment && (
            <CommentsContainer
              elementType={ElementType.Hypothesis}
              element={item}
              textId="comment text Hypothesis"
            />
          )}
        </ContentWrapper>

        {form.editing === 'title' && (
          <Form disabled={hypothesesStore.isItemSaving}>
            <SimplePopup
              onAbort={() => form.setEditing()}
              isLoading={hypothesesStore.isItemSaving}
              onSubmit={() => this.saveFieldUpdate(['headline'])}
            >
              <PageLogoEdit>
                <Input
                  autoFocus={true}
                  headline={true}
                  name="headline"
                  placeholder={intl.formatMessage({
                    id: 'Insert headline'
                  })}
                  {...form.bindInput('headline')}
                />
              </PageLogoEdit>
            </SimplePopup>
          </Form>
        )}
        {!hypothesesStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              dataStore.currentOrganizationId
            }
            copyInitiallySelectedProjectId={dataStore.currentProjectId}
            showCopy={true}
            showAiBenchmark={true}
            showAiBriefing={true}
            showDelete={
              this.props.actionsStore.selectedFromProjectIds.length === 1 &&
              this.props.actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={true}
          />
        )}
      </>
    );
  }

  renderHeader() {}

  render() {
    const { hypothesesStore, create, edit } = this.props;

    if (hypothesesStore.isItemLoading) {
      return this.renderLoading();
    }

    if (hypothesesStore.isItemLoadError) {
      return this.renderError();
    }

    if (create || edit) {
      return this.renderEditMode();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicHypothesisDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <HypothesisDetailContainer {...props} form={form} />;
});
