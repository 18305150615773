import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import useForm, { FormType } from 'utils/hooks/useForm';
import ProjectMemberRow from './ProjectMemberRow';

// tslint:disable-next-line: no-empty-interface
interface PublicProjectMembersContainerProps extends WrappedComponentProps {}

interface ProjectMembersContainerProps
  extends PublicProjectMembersContainerProps {
  form: FormType;
  organizationsStore: OrganizationsStoreType;
  projectsStore: ProjectsStoreType;
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
}

// tslint:disable-next-line: no-empty-interface
interface ProjectMembersContainerState {
  loading?: boolean;
}

@inject('dataStore', 'organizationsStore', 'projectsStore', 'applicationStore')
@observer
class ProjectMembersContainer extends React.Component<
  ProjectMembersContainerProps,
  ProjectMembersContainerState
> {
  state: ProjectMembersContainerState = {};

  componentDidMount() {
    this.load();
  }

  async load() {
    const { organizationsStore, projectsStore } = this.props;

    this.setState({
      loading: true
    });

    try {
      await organizationsStore.getMembers();
      await projectsStore.getMembers();
    } catch (err) {
      // can be ignored
    }

    this.setState({
      loading: false
    });
  }

  isListLoading() {
    if (this.state.loading) {
      return true;
    }

    const { organizationsStore, projectsStore } = this.props;
    return (
      organizationsStore.isMembersLoading || projectsStore.isMembersLoading
    );
  }

  isListLoadError() {
    const { organizationsStore, projectsStore } = this.props;
    return (
      organizationsStore.isMembersLoadError || projectsStore.isMembersLoadError
    );
  }

  renderNav(content: any) {
    const baseUrl = this.props.dataStore.contextUri;
    return (
      <>
        <TabList small={true}>
          <TabListButton
            title={<FormattedMessage id="Sharing" />}
            to={baseUrl + '/settings'}
          />
          <TabListButton
            title={<FormattedMessage id="Members" />}
            to={baseUrl + '/members'}
            active={true}
          />
        </TabList>

        {content}
      </>
    );
  }

  renderLoading() {
    return this.renderNav(<Loading />);
  }

  renderError() {
    return this.renderNav(<ErrorMessage state="load_error" />);
  }

  renderList() {
    const { projectsStore } = this.props;

    const { editors, viewers } = projectsStore.membersList;

    return this.renderNav(
      <ColumnWrapper gap="2em">
        {editors.length < 1 ? (
          <h3>
            <FormattedMessage id="No Editors" />
          </h3>
        ) : (
          <section className="settings-table">
            <h3 className="settings-table__title">
              <FormattedMessage id="Editors" />
            </h3>
            <table className="table">
              <thead>
                <tr>
                  <td />
                  <td className="table__column-header">
                    <FormattedMessage id="may edit" />
                  </td>
                </tr>
              </thead>
              <tbody>
                {editors.map((member) => (
                  <ProjectMemberRow
                    key={member.user.id}
                    type="editor"
                    member={member}
                  />
                ))}
              </tbody>
            </table>
          </section>
        )}

        {viewers.length < 1 ? (
          <h3>
            <FormattedMessage id="No Viewers" />
          </h3>
        ) : (
          <section className="settings-table">
            <h3 className="settings-table__title">
              <FormattedMessage id="Viewers" />
            </h3>
            <table className="table">
              <thead>
                <tr>
                  <td />
                  <td className="table__column-header">
                    <FormattedMessage id="may view" />
                  </td>
                </tr>
              </thead>
              <tbody>
                {viewers.map((member) => (
                  <ProjectMemberRow
                    key={member.user.id}
                    type="viewer"
                    member={member}
                  />
                ))}
              </tbody>
            </table>
          </section>
        )}
      </ColumnWrapper>
    );
  }

  render() {
    if (this.isListLoading()) {
      return this.renderLoading();
    }

    if (this.isListLoadError()) {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicProjectMembersContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProjectMembersContainer {...props} form={form} />;
});
