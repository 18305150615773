import classNames from 'classnames';
import React from 'react';

interface TypographyProps {
  children?: React.ReactNode;
  size?: string;
  tag?: string;
  opaque?: boolean;
  center?: boolean;
}

export default function Headline({
  tag = 'h3',
  children,
  size = 'L',
  opaque,
  center
}: TypographyProps) {
  const HeadlineClasses = classNames('headline', {
    'headline-XXL': size === 'XXL',
    'headline-XL': size === 'XL',
    'headline-L': size === 'L',
    'headline-M': size === 'M',
    'headline-S': size === 'S',
    'headline-XS': size === 'XS',
    'headline-XXS': size === 'XXS',
    opaque: opaque,
    'text-center': center,
  });

  return React.createElement(tag, { className: HeadlineClasses }, children);
}
