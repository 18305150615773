import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

interface SubNavProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  top?: React.ReactNode;
}

const Header = ({ children }: { children?: React.ReactNode }) => (
  <h2 className="sub-nav__project">{children}</h2>
);

export const SubNav = ({ children, title }: SubNavProps) => {
  return (
    <nav aria-label="Unterrubriken" className="sub-nav">
      {title && <Header>{title}</Header>}
      {children}
    </nav>
  );
};

SubNav.Header = Header;

SubNav.BackLink = ({ to }: { to: string }) => (
  <Link to={to} className="sub-nav__back">
    <Icon name="arrow-left" />
    <FormattedMessage id="Back" />
  </Link>
);

export default SubNav;
