import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { AttachmentsStoreType } from 'models/AttachmentsStore';
import React from 'react';
import useForm, { FormType } from 'utils/hooks/useForm';

export type UploadedFileContainerStateEnum = 'removing';

export interface UploadedFileContainerChildProps {
  attachment: AttachmentModelType;
  onRemoveClick?: (event: any) => void;
  state?: UploadedFileContainerStateEnum;
  error?: string | boolean;
}

interface PublicUploadedFileContainerProps {
  attachment?: AttachmentModelType;
  onAttachmentRemoved?: (id: number) => void;
  children?: (childProps: UploadedFileContainerChildProps) => React.ReactNode;
}

interface UploadedFileContainer extends PublicUploadedFileContainerProps {
  applicationStore: ApplicationStoreType;
  attachmentsStore: AttachmentsStoreType;
  form: FormType;
}

@inject('applicationStore', 'attachmentsStore')
@observer
class UploadedFileContainer extends React.Component<UploadedFileContainer> {
  async removeClick(event: any) {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    const { attachmentsStore, form, attachment, onAttachmentRemoved } =
      this.props;

    const id = attachment?.id;
    if (!id) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    try {
      await attachmentsStore.deleteAttachment(id);

      if (onAttachmentRemoved) {
        onAttachmentRemoved(id);
      }
    } catch (error: any) {
      form.setError('file', true);

      this.props.applicationStore.setFlashMessage(
        getIntl().formatMessage({ id: 'upload remove error flash' }),
        'error'
      );
    }

    form.setLoading(false);
  }

  render() {
    const { children, form, attachment } = this.props;

    if (!attachment) {
      return null;
    }

    return children
      ? children({
          attachment,
          onRemoveClick: (e) => this.removeClick(e),
          state: form.loading ? 'removing' : undefined,
          error: form.errors.file
        })
      : null;
  }
}

export default (props: PublicUploadedFileContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <UploadedFileContainer form={form} {...props} />;
};
