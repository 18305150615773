import AIResponseCounter from 'components/AIResponseCounter';
import AddButton from 'components/AddButton';
import { NetworkAwareAppLayout } from 'components/AppLayout/NetworkAwareAppLayout';
import CallToAction from 'components/CallToAction';
import CardWrapper from 'components/CardWrapper';
import CompanyLogo from 'components/CompanyLogo';
import DestroyButton from 'components/DestroyButton';
import ErrorMessage from 'components/ErrorMessage';
import Icon from 'components/Icon';
import Input from 'components/Inputs/Input';
import TagSelectInput from 'components/Inputs/TagSelectInput';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ProjectWrapper from 'components/Layout/ProjectWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import ProjectCard from 'components/ProjectCard';
import ProjectList from 'components/ProjectList';
import SectionHeadline from 'components/SectionHeadline';
import SimplePopup from 'components/SimplePopup';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import TagList from 'components/TagList';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { ProjectModelType } from 'models/ProjectModel';
import { ProjectsStoreType } from 'models/ProjectsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface ProjectsListScreenState {
  addProjectPopupVisible: boolean;
  searchProjectPopupVisible: boolean;
  searchProjectTopic: string;
}
interface ProjectsListScreenProps extends HistoryProps, WrappedComponentProps {
  projectsStore: ProjectsStoreType;
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  form: FormType;
}

@inject('dataStore', 'projectsStore', 'assistantStore', 'applicationStore')
@observer
class ProjectsListScreen extends React.Component<
  ProjectsListScreenProps,
  ProjectsListScreenState
> {
  state: ProjectsListScreenState = {
    addProjectPopupVisible: false,
    searchProjectPopupVisible: false,
    searchProjectTopic: ''
  };

  componentDidMount() {
    this.loadProjects();
    this.loadInvitationsCount();
  }

  loadProjects() {
    this.props.projectsStore.getProjects();
  }

  async loadInvitationsCount() {
    try {
      await this.props.assistantStore.getReceivedInvitationsCount();
    } catch (error: any) {
      // ignore
    }
  }

  openAddProjectPopup() {
    this.setState({ addProjectPopupVisible: true });
  }

  closeAddProjectPopup() {
    this.setState({ addProjectPopupVisible: false });
  }

  closeSearchProjectPopup() {
    this.setState({ searchProjectPopupVisible: false });
  }
  createProject() {
    const { history, dataStore } = this.props;

    // this.openAddProjectPopup();
    history.push(ROUTE_LAB + '/' + dataStore.currentOrganizationId + '/create');
  }

  async addProject() {
    const { form, projectsStore, dataStore } = this.props;

    const project = {
      topic: form.values.topic || ''
    };

    try {
      await projectsStore.createProject(
        project,
        dataStore.currentOrganizationId
      );

      if (!projectsStore.isItemSaveError) {
        this.closeAddProjectPopup();
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  async deleteProject(project: ProjectModelType) {
    const {
      applicationStore,
      projectsStore,
      dataStore: { currentOrganization },
      intl
    } = this.props;

    if (!currentOrganization) {
      return;
    }

    if (
      !window.confirm(
        this.props.intl.formatMessage(
          { id: 'project delete confirm' },
          { topic: project.topic }
        )
      )
    ) {
      return;
    }

    try {
      await projectsStore.deleteProject(currentOrganization.id, project.id);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'project delete flash' })
      );
    } catch (error: any) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'project delete error flash' }),
        'error'
      );
    }
  }

  getFilter(filter: string) {
    return this.props.projectsStore.filter.get(filter);
  }

  setFilter(filter: string, value: string) {
    this.props.projectsStore.setFilter(filter, value);
  }

  resetFilters() {
    this.setFilter('tag', '');
  }

  maybeRenderAddPopup() {
    const { projectsStore, intl, form } = this.props;

    if (!this.state.addProjectPopupVisible) {
      return null;
    }
    return (
      <SimplePopup
        onSubmit={() => this.addProject()}
        onAbort={() => this.closeAddProjectPopup()}
        isLoading={projectsStore.isItemSaving}
        reduced={true}
      >
        <ColumnWrapper gap="1em">
          <h2>
            <FormattedMessage id="Add project" />
          </h2>
          <Input
            autoFocus={true}
            gap="1em"
            headline={true}
            topic="topic"
            placeholder={intl.formatMessage({ id: 'Project topic' })}
            {...form.bindInput('topic')}
          />
        </ColumnWrapper>
      </SimplePopup>
    );
  }

  renderPage(content: any) {
    const { dataStore } = this.props;

    let header;

    const org = dataStore.currentOrganization;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    return (
      <NetworkAwareAppLayout reduced={true} org={org} withSettings={true}>
        <AIResponseCounter dark={true} />

        <PageHeader
          reduced={true}
          titleId="Our projects(titleId)"
          logoHeader={header}
        />
        {content}
      </NetworkAwareAppLayout>
    );
  }

  renderError(withRetry: boolean = false) {
    return this.renderPage(
      <ErrorMessage
        state="load_error"
        onRetry={withRetry ? () => this.loadProjects() : undefined}
      />
    );
  }

  render() {
    const { projectsStore, dataStore, intl } = this.props;
    const { searchProjectPopupVisible, searchProjectTopic } = this.state;

    const org = dataStore.currentOrganization;
    if (!org) {
      return this.renderError();
    }

    if (projectsStore.isListError) {
      return this.renderError(true);
    }

    const loading =
      projectsStore.isItemSaving ||
      (projectsStore.isListLoading && !projectsStore.hasAny);

    const hasFilter = !!this.getFilter('tag');
    const mayEdit = dataStore.isOrgAdmin;

    const all = projectsStore.listWithCurrentFilter();
    const bookmarked = projectsStore.listWithCurrentFilter(true);

    function projectAmountByType(type: string) {
      return projectsStore
        .listFilteredWithoutGPTStrategyType()
        .filter((project) => project.gpt_strategy_type === type).length;
    }

    const addNewProjectLabel = () => {
      if (dataStore.isAdmin || !org.project_limit) {
        return intl.formatMessage({ id: 'Add project' });
      }
      if (org.reachedProjectLimit) {
        return intl.formatMessage(
          { id: 'Project limit reached' },
          { limit: org.project_limit }
        );
      } else {
        return intl.formatMessage(
          { id: 'Add project within limit' },
          { limit: org.project_limit, count: org.project_count }
        );
      }
    };

    return this.renderPage(
      <>
        <ProjectWrapper>
          {loading && <Loading />}

          <TagList>
            <TagSelectInput
              label={intl.formatMessage({ id: 'Sort by' })}
              value={this.getFilter('sort')}
              onChange={(e) => this.setFilter('sort', e.target.value)}
            >
              <option value="topic">
                {intl.formatMessage({ id: 'Topic' })}
              </option>
              <option value="newest">
                {intl.formatMessage({ id: 'Newest' })}
              </option>
              <option value="oldest">
                {intl.formatMessage({ id: 'Oldest' })}
              </option>
            </TagSelectInput>

            <TagSelectInput
              name="tag"
              value={this.getFilter('tag')}
              onChange={(e) => this.setFilter('tag', e.target.value)}
            >
              <option value="">
                {intl.formatMessage({ id: 'All hashtags' })}
              </option>
              <option value="" disabled={true} />
              {projectsStore.tags.map((tag) => (
                <option value={tag} key={tag}>
                  #{tag}
                </option>
              ))}
            </TagSelectInput>
          </TagList>
          <TabList projectList={true}>
            <Icon
              name="magnify"
              size="large"
              onClick={() => {
                if (searchProjectPopupVisible) {
                  this.setState({
                    searchProjectPopupVisible: false,
                    searchProjectTopic: ''
                  });
                } else {
                  this.setState({ searchProjectPopupVisible: true });
                }
              }}
            />
            {searchProjectPopupVisible && (
              <Input
                autoFocus
                className="search-project-input"
                placeholder="Projekttopic"
                name="searchProject"
                onChange={(e) =>
                  this.setState({ searchProjectTopic: e.target.value })
                }
              ></Input>
            )}
            <TabListButton
              key="all"
              to={''}
              title={intl.formatMessage({ id: 'All' })}
              active={
                !this.getFilter('gpt_strategy_type') ||
                this.getFilter('gpt_strategy_type') === ''
              }
              onClick={() => this.setFilter('gpt_strategy_type', '')}
            />
            {projectsStore.gptStrategyTypes.map((gptStrategyType) => (
              <TabListButton
                key={gptStrategyType}
                to={''}
                title={
                  gptStrategyType +
                  ' (' +
                  projectAmountByType(gptStrategyType) +
                  ')'
                }
                active={this.getFilter('gpt_strategy_type') === gptStrategyType}
                onClick={() =>
                  this.setFilter('gpt_strategy_type', gptStrategyType)
                }
              />
            ))}
          </TabList>
          {hasFilter && (
            <CardWrapper>
              <DestroyButton
                label={intl.formatMessage({ id: 'Reset filters' })}
                onClick={() => this.resetFilters()}
              />
              <FormattedMessage
                id="project filter label"
                values={{ count: all.length }}
              />
            </CardWrapper>
          )}

          {bookmarked.length > 0 && (
            <>
              <SectionHeadline
                title={<FormattedMessage id="Personal projects" />}
                lessPaddingTop={true}
              />
              <ProjectList>
                {bookmarked
                  .filter((project) =>
                    project.topic
                      ?.toLowerCase()
                      .includes(searchProjectTopic.toLowerCase())
                  )
                  .map((project) => (
                    <ProjectCard
                      project={project}
                      linkto={ROUTE_LAB + '/' + org.id + '/' + project.id}
                      onDeleteClick={
                        mayEdit ? () => this.deleteProject(project) : undefined
                      }
                      key={project.id}
                    />
                  ))}
              </ProjectList>
            </>
          )}

          {all.length > 0 && (
            <SectionHeadline
              title={<FormattedMessage id="All projects" />}
              lessPaddingTop={true}
            />
          )}
          <ProjectList>
            {all
              .filter((project) =>
                project.topic
                  ?.toLowerCase()
                  .includes(searchProjectTopic.toLowerCase())
              )
              .map((project) => (
                <ProjectCard
                  project={project}
                  linkto={ROUTE_LAB + '/' + org.id + '/' + project.id}
                  onDeleteClick={
                    mayEdit ? () => this.deleteProject(project) : undefined
                  }
                  key={project.id}
                />
              ))}

            {!loading && dataStore.isOrgEditor && (
              <AddButton
                fillContainer={true}
                label={addNewProjectLabel()}
                iconName={!org.reachedProjectLimit ? 'plus' : 'stop'}
                disabled={!dataStore.isAdmin && org.reachedProjectLimit}
                onClick={() => this.createProject()}
              />
            )}
          </ProjectList>

          {!loading && !dataStore.isOrgEditor && all.length < 1 && (
            <CardWrapper>
              <FormattedMessage id="no project" />
            </CardWrapper>
          )}
        </ProjectWrapper>

        {this.maybeRenderAddPopup()}
        <CallToAction />
      </>
    );
  }
}

export default injectIntl((props: ProjectsListScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProjectsListScreen {...props} form={form} />;
});
