import React from 'react';

import classNames from 'classnames';
import FloatButton from 'components/FloatButton';
import PopupMenu from 'components/PopupMenu';
// @ts-ignore
import onClickOutside from 'react-onclickoutside';

interface ListMenuProps {
  children: React.ReactNode;
  label: string;
  bottomLeft?: boolean;
  topLeft?: boolean;
  topRight?: boolean;
  iconName?: string;
  transformToIconName?: string;
  customIcon?: string;
  disabled?: boolean;
  onShow?: () => void;
  onHide?: () => void;
}

interface ListMenuState {
  visible?: boolean;
}

class ListMenu extends React.Component<ListMenuProps, ListMenuState> {
  state: ListMenuState = {};

  handleClickOutside = () => {
    this.setState({
      visible: false
    });

    this.props.onHide && this.props.onHide();
  };

  toggle() {
    const { onShow, onHide } = this.props;

    const visible = !this.state.visible ? true : false;
    this.setState({
      visible
    });

    visible ? onShow && onShow() : onHide && onHide();
  }

  hide() {
    this.setState({
      visible: false
    });
  }

  render() {
    const { props } = this;

    const ListMenuClasses = classNames('list-menu', {
      'list-menu--bottomLeft': props.bottomLeft,
      'list-menu--topLeft': props.topLeft,
      'list-menu--topRight': props.topRight
    });

    return (
      <div className={ListMenuClasses}>
        <FloatButton
          label={props.label}
          iconName={props.iconName || 'plus'}
          transformToIconName={props.transformToIconName}
          customIcon={props.customIcon}
          type="add"
          disabled={props.disabled}
          active={this.state.visible}
          onClick={() => this.toggle()}
        />
        <div
          className="list-menu__menu"
          hidden={!this.state.visible}
          onClick={() => this.hide()}
        >
          <PopupMenu
            bottomLeft={props.bottomLeft}
            topLeft={props.topLeft}
            topRight={props.topRight}
          >
            {props.children}
          </PopupMenu>
        </div>
      </div>
    );
  }
}

export default onClickOutside(ListMenu) as typeof ListMenu;
