export const sortByNumber = (fieldName: string, desc = false) => {
  return (a: any, b: any) => {
    a = a[fieldName] || 0;
    b = b[fieldName] || 0;

    if (a > b) {
      return desc ? -1 : 1;
    }
    if (a < b) {
      return desc ? 1 : -1;
    }
    return 0;
  };
};

export default sortByNumber;
