import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import SimplePopup from 'components/SimplePopup';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import Typography from 'components/Typography';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { ItemLoadingStateEnumType } from 'models/LoadingStateEnums';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import isEmail from 'utils/misc/is-email';

import OrgMemberRow from './OrgMemberRow';

// tslint:disable: jsx-wrap-multiline

interface PublicOrgMembersContainerProps extends WrappedComponentProps {
  filter?: 'pending' | 'active';
}

interface OrgMembersContainerProps extends PublicOrgMembersContainerProps {
  form: FormType;
  organizationsStore: OrganizationsStoreType;
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
}

interface OrgMembersContainerState {
  editMode?: EditEnum;
  editUserId?: number;
  inviteActive?: boolean;
  loadingState?: ItemLoadingStateEnumType;
}

type EditEnum = 'name' | 'email';

@inject('dataStore', 'organizationsStore', 'applicationStore')
@observer
class OrgMembersContainer extends React.Component<
  OrgMembersContainerProps,
  OrgMembersContainerState
> {
  state: OrgMembersContainerState = {};
  inviteInputRef: any = null;

  componentDidMount() {
    this.load();
  }

  load() {
    const { dataStore, organizationsStore } = this.props;

    if (!dataStore.currentOrganizationId) {
      return;
    }

    organizationsStore.getMembers(dataStore.currentOrganizationId);
  }

  beginEdit(type: EditEnum, userId: number) {
    const { dataStore, form } = this.props;

    const user = dataStore.users.get(userId.toString());
    if (!user) {
      return;
    }

    form.setField('first_name', user.first_name || '');
    form.setField('last_name', user.last_name || '');
    form.setField('email', user.email || '');

    this.setState({
      editMode: type,
      editUserId: userId
    });
  }

  async save(type: EditEnum) {
    const { organizationsStore, applicationStore, form } = this.props;
    const userId = this.state.editUserId;

    if (!userId) {
      return;
    }

    this.setState({
      loadingState: 'saving'
    });

    let patch: any;
    switch (type) {
      case 'name':
        patch = {
          first_name: form.values.first_name,
          last_name: form.values.last_name
        };
        break;

      case 'email':
        patch = {
          email: form.values.email
        };
        break;

      default:
    }

    try {
      await organizationsStore.updateMember(userId, patch);

      applicationStore.setFlashMessage(
        this.props.intl.formatMessage({ id: 'user updated flash' })
      );

      this.setState({
        loadingState: undefined
      });

      this.finishEdit();
    } catch (error: any) {
      if (handleFormError(form, error)) {
        this.setState({
          loadingState: undefined
        });
      } else {
        applicationStore.setFlashMessage(
          this.props.intl.formatMessage({ id: 'user update error flash' }),
          'error'
        );

        this.setState({
          loadingState: 'save_error'
        });
      }
    }
  }

  finishEdit() {
    this.setState({
      editMode: undefined,
      editUserId: undefined
    });
  }

  validateInviteEmail(): string | undefined {
    const { organizationsStore, form, intl } = this.props;

    form.resetErrors();

    const email = (form.values.invite_email || '').toString().trim();
    if (email === '') {
      return;
    }

    if (!isEmail(email)) {
      form.setError(
        'invite_email',
        intl.formatMessage({ id: 'email invalid' })
      );
      return undefined;
    }

    if (organizationsStore.doesEmailExist(email)) {
      form.setError('invite_email', intl.formatMessage({ id: 'email exists' }));
      return undefined;
    }

    return email;
  }

  async deleteUser(userId: number) {
    const { organizationsStore, applicationStore, intl } = this.props;

    if (!window.confirm(intl.formatMessage({ id: 'remove user confirm' }))) {
      return;
    }

    try {
      await organizationsStore.deleteMember(userId);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'user deleted' })
      );
    } catch (error: any) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'delete_error' }),
        'error'
      );

      this.setState({
        loadingState: 'delete_error'
      });
    }
  }

  async inviteWithoutName() {
    const email = this.validateInviteEmail();
    if (!email) {
      return;
    }

    const { organizationsStore, applicationStore, form } = this.props;

    this.setState({
      loadingState: 'saving'
    });

    try {
      await organizationsStore.createMember(
        '',
        '',
        email,
        ORG_ACCESS_LEVELS.USER
      );

      applicationStore.setFlashMessage(
        this.props.intl.formatMessage({ id: 'user invited flash' })
      );

      this.setState({
        loadingState: undefined
      });

      form.reset();

      if (this.inviteInputRef?.focus) {
        this.inviteInputRef.focus();
      }
    } catch (error: any) {
      applicationStore.setFlashMessage(
        this.props.intl.formatMessage({ id: 'user invite error flash' }),
        'error'
      );

      this.setState({
        loadingState: 'save_error'
      });
    }
  }

  isLoading() {
    return this.state.loadingState === 'saving';
  }

  // invite with modal is not enabled at the moment but kept if it will be required again someday
  // beginInvite() {
  //   const { form } = this.props;

  //   const email = this.validateInviteEmail();
  //   if (!email) {
  //     return;
  //   }

  //   form.resetErrors();
  //   form.setField('email', email);
  //   form.setField('level', 'viewer');
  //   form.setField('first_name', '');
  //   form.setField('last_name', '');

  //   this.setState({
  //     inviteActive: true
  //   });
  // }

  // async invite() {
  //   const { organizationsStore, applicationStore, form } = this.props;

  //   this.setState({
  //     loadingState: 'saving'
  //   });

  //   try {
  //     await organizationsStore.createMember(
  //       form.values.first_name || '',
  //       form.values.last_name || '',
  //       form.values.email || '',
  //       form.values.level || ''
  //     );

  //     applicationStore.setFlashMessage(
  //       this.props.intl.formatMessage({ id: 'user invited flash' })
  //     );

  //     this.setState({
  //       loadingState: undefined
  //     });

  //     this.finishInvite();
  //   } catch (error:any) {
  //     if (handleFormError(form, error)) {
  //       this.setState({
  //         loadingState: undefined
  //       });
  //     } else {
  //       applicationStore.setFlashMessage(
  //         this.props.intl.formatMessage({ id: 'user invite error flash' }),
  //         'error'
  //       );

  //       this.setState({
  //         loadingState: 'save_error'
  //       });
  //     }
  //   }
  // }

  // finishInvite() {
  //   this.props.form.reset();

  //   this.setState({
  //     inviteActive: false
  //   });
  // }

  // maybeRenderInvite() {
  //   if (!this.state.inviteActive) {
  //     return null;
  //   }

  //   const { form } = this.props;

  //   const loading = this.isLoading();
  //   return (
  //     <Form disabled={loading}>
  //       <SimplePopup
  //         isLoading={loading}
  //         onSubmit={() => this.invite()}
  //         onDestroy={() => this.finishInvite()}
  //         submitTextId="Invite"
  //       >
  //         <Input
  //           type="text"
  //           gap={true}
  //           name="email"
  //           label={<FormattedMessage id="Email address" />}
  //           {...form.bindInput('email')}
  //           disabled={true}
  //         />

  //         <Input
  //           type="text"
  //           gap={true}
  //           name="first_name"
  //           autoFocus={true}
  //           label={<FormattedMessage id="First name" />}
  //           {...form.bindInput('first_name')}
  //         />

  //         <Input
  //           type="text"
  //           gap={true}
  //           name="last_name"
  //           label={<FormattedMessage id="Last name" />}
  //           {...form.bindInput('last_name')}
  //         />

  //         <AccessLevelSelect
  //           name="level"
  //           label={<FormattedMessage id="Access level" />}
  //           {...form.bindInput('level')}
  //         />
  //       </SimplePopup>
  //     </Form>
  //   );
  // }

  maybeRenderEditName() {
    if (this.state.editMode !== 'name') {
      return null;
    }

    const { form } = this.props;

    const loading = this.isLoading();
    return (
      <Form disabled={loading}>
        <SimplePopup
          isLoading={loading}
          onSubmit={() => this.save('name')}
          onAbort={() => this.finishEdit()}
          submitTextId="Save"
        >
          <Input
            type="text"
            gap={true}
            name="first_name"
            autoFocus={true}
            label={<FormattedMessage id="First name" />}
            {...form.bindInput('first_name')}
          />

          <Input
            type="text"
            gap={true}
            name="last_name"
            label={<FormattedMessage id="Last name" />}
            {...form.bindInput('last_name')}
          />
        </SimplePopup>
      </Form>
    );
  }

  maybeRenderEditEmail() {
    if (this.state.editMode !== 'email') {
      return null;
    }

    const { form } = this.props;

    const loading = this.isLoading();
    return (
      <Form disabled={loading}>
        <SimplePopup
          isLoading={loading}
          onSubmit={() => this.save('email')}
          onAbort={() => this.finishEdit()}
          submitTextId="Save"
        >
          <Input
            type="text"
            gap={true}
            name="email"
            autoFocus={true}
            label={<FormattedMessage id="Email address" />}
            {...form.bindInput('email')}
          />

          <Typography>
            <FormattedMessage id="email update hint" />
          </Typography>
        </SimplePopup>
      </Form>
    );
  }

  renderLoading() {
    return <Loading />;
  }

  renderError() {
    return (
      <ErrorMessage
        state={this.props.organizationsStore.membersLoadingState}
        onRetry={() => this.load()}
      />
    );
  }

  renderTopNav(isPending: boolean) {
    const { organizationsStore } = this.props;

    return (
      <TabList small={true}>
        <TabListButton
          title={
            <FormattedMessage
              id="Members(count)"
              values={{
                count: organizationsStore.membersCount('active')
              }}
            />
          }
          to="members"
          active={!isPending}
        />
        <TabListButton
          title={
            <FormattedMessage
              id="Pending invitations(count)"
              values={{
                count: organizationsStore.membersCount('pending')
              }}
            />
          }
          to="members?display=pending"
          active={isPending}
        />
      </TabList>
    );
  }

  renderList() {
    const { organizationsStore, form, filter } = this.props;
    const isPending = filter === 'pending';

    const list = organizationsStore.sortedMembers(filter);
    return (
      <>
        {this.renderTopNav(isPending)}

        {/*this.maybeRenderInvite()*/}
        {this.isLoading() && <Loading />}

        {this.maybeRenderEditName()}
        {this.maybeRenderEditEmail()}

        <ColumnWrapper gap="3em">
          <Form onSubmit={() => this.inviteWithoutName()}>
            <ColumnWrapper gap="1.5em">
              <h2>
                <FormattedMessage id="add member header" />
              </h2>
              <ColumnWrapper gap="1em">
                <Input
                  label={<FormattedMessage id="Email address" />}
                  name="invite_email"
                  {...form.bindInput('invite_email')}
                  ref={(r) => (this.inviteInputRef = r)}
                  button={<FormattedMessage id="Invite" />}
                  active={!form.values.invite_email}
                />
              </ColumnWrapper>
            </ColumnWrapper>
          </Form>

          {list.length > 0 && (
            <section className="member-table member-table--higher-z-index">
              {list.map((member) => (
                <OrgMemberRow
                  key={member.id}
                  member={member}
                  pending={isPending}
                  onNameEditClick={() => this.beginEdit('name', member.id)}
                  onEmailEditClick={() => this.beginEdit('email', member.id)}
                  onDeleteClick={() => this.deleteUser(member.id)}
                />
              ))}
            </section>
          )}
        </ColumnWrapper>
      </>
    );
  }

  render() {
    const { organizationsStore } = this.props;

    if (organizationsStore.isMembersLoading) {
      return this.renderLoading();
    }

    if (organizationsStore.isMembersLoadError) {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicOrgMembersContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <OrgMembersContainer {...props} form={form} />;
});
