
import Icon from 'components/Icon';

interface DestroyButtonProps {
  label?: string;
  iconName?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// TODO intl for label
export default function DestroyButton({
  label,
  iconName,
  onClick
}: DestroyButtonProps) {
  return (
    <button
      type="button"
      className="destroy-button"
      aria-label={label || 'Close'}
      onClick={onClick}
    >
      <Icon name={iconName || 'cross'} />
    </button>
  );
}
