import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import AttachmentModel, { createAttachmentModel } from './AttachmentModel';
import BenchmarkModel from './BenchmarkModel';
import { CommentsMapModel, createCommentsMap } from './CommentModel';
import PainpointModel, { PainpointModelType } from './PainpointModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PublishStateEnum, { createPublishState } from './PublishStateEnum';
import TagListModel, { createTagList } from './TagListModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const HypothesisModel = types
  .model('HypothesisModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    author: types.maybe(FluidUserModel),
    requesting_user: types.maybe(FluidUserModel),
    painpoint: types.maybe(
      types.reference(types.late((): IAnyModelType => PainpointModel))
    ),
    benchmarks: types.maybe(
      types.array(
        types.reference(types.late((): IAnyModelType => BenchmarkModel))
      )
    ),
    headline: types.maybe(types.string),
    // subline: types.maybe(types.string),
    description: types.maybe(types.string),
    problem: types.maybe(types.string),
    added_value: types.maybe(types.string),
    publish_state: types.maybe(PublishStateEnum),
    created_at: types.maybe(types.string),
    published_at: types.maybe(types.string),
    average_target_group_relevance: types.maybe(types.number),
    average_revenue_potential: types.maybe(types.number),
    average_cost_efficiency: types.maybe(types.number),
    average_differentiation_degree: types.maybe(types.number),
    tags: types.maybe(TagListModel),
    attachment: types.maybe(AttachmentModel),
    comments_count: types.maybe(types.number),
    comments: CommentsMapModel,
    bookmark_id: types.maybe(types.number),
    bookmarks_count: types.maybe(types.number),
    created_by_ai: types.maybe(types.boolean),
    image_prompt: types.maybe(types.string)
  })
  .actions((self) => {
    const setPainpoint = (painpoint?: PainpointModelType) => {
      self.painpoint = painpoint;
    };

    const setBenchmarks = (benchmarks?: any) => {
      self.benchmarks = benchmarks;
    };

    return {
      setPainpoint,
      setBenchmarks
    };
  });

export const createHypothesisModel = (data?: any): HypothesisModelType => {
  return HypothesisModel.create({
    id: mandatoryId(data.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    author: createFluidUserModel(data?.author),
    requesting_user: createFluidUserModel(data?.requesting_user),
    painpoint: undefined,
    benchmarks: undefined,
    headline: data?.headline || undefined,
    // subline: data?.subline || undefined,
    description: data?.description || undefined,
    problem: data?.problem || undefined,
    added_value: data?.added_value || undefined,
    publish_state: createPublishState(data?.publish_state),
    created_at: data?.created_at || undefined,
    published_at: data?.published_at || data?.created_at || undefined,
    average_target_group_relevance: numberOrUndefined(
      data?.average_target_group_relevance
    ),
    average_revenue_potential: numberOrUndefined(
      data?.average_revenue_potential
    ),
    average_cost_efficiency: numberOrUndefined(data?.average_cost_efficiency),
    average_differentiation_degree: numberOrUndefined(
      data?.average_differentiation_degree
    ),
    tags: createTagList(data?.tags),
    attachment: data.attachment ? createAttachmentModel(data.attachment) : undefined,
    comments_count: numberOrUndefined(data?.ratings_count),
    comments: createCommentsMap(data?.ratings),
    bookmark_id: numberOrUndefined(data?.bookmark_id),
    bookmarks_count: numberOrUndefined(data?.bookmarks_count),
    created_by_ai: data.created_by_ai,
    image_prompt: data.image_prompt || undefined
  });
};

export type HypothesisModelType = typeof HypothesisModel.Type;
export default HypothesisModel;
