import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { FluidUserModelType, UserModelType } from 'models/UserModel';
import { ROUTE_PROFILE } from 'utils/constants/routes';
import Avatar from './Avatar';
import UserName from './UserName';

export interface UserAvatarProps {
  user?: UserModelType | FluidUserModelType;
  noName?: boolean;
  isSelf?: boolean;
  url?: string;
  displayEmpty?: boolean;
  noLink?: boolean;
  isAiRobot?: boolean;
}

export default observer(
  ({
    user,
    noName,
    url,
    isSelf,
    displayEmpty,
    noLink,
    isAiRobot
  }: UserAvatarProps) => {
    if (isAiRobot) {
      return (
        <Avatar
          initials="AI"
          name="DISROOPTIVE.ai"
          imgUrl="/images/ai-avatar.png"
        />
      );
    }

    if (!user) {
      return !displayEmpty ? null : <Avatar initials="?" />;
    }

    const avatar = (
      <Avatar
        name={!noName && <UserName user={user} />}
        initials={user.initials}
        imgUrl={user.smallAvatar}
      />
    );

    if (noLink) {
      return avatar;
    }

    return (
      <Link to={!url ? ROUTE_PROFILE.replace(':id', user.id!.toString()) : url}>
        {avatar}
      </Link>
    );
  }
);
