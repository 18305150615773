export const RaisedHand = ({
  label,
  name
}: {
  label?: string;
  name?: string;
}) => (
  <div className="raised-hand">
    <img src="/images/raised-hand.svg" alt="Hand:" />
    {label && <span className="raised-hand__label">{label}</span>}
    {name && <span className="raised-hand__name">{name}</span>}
  </div>
);

export default RaisedHand;
