import AppLayout from 'components/AppLayout';
import SimpleCardSlider, {
  smallSliderResponsiveConfig
} from 'components/CardSlider';
import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import Icon from 'components/Icon/Icon';
import Column from 'components/Layout/Column';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Columns from 'components/Layout/Columns';
import ContentWrapper from 'components/Layout/ContentWrapper';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import SectionHeadline from 'components/SectionHeadline';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FaqPagesSectionTypeEnumType } from 'models/FaqPagesSectionEnum';
import { FaqPagesStoreType } from 'models/FaqPagesStore';
import React from 'react';
import { Link } from 'react-router-dom';
import GapSizes from 'utils/constants/gap-sizes';
import { ROUTE_FAQ_PAGES } from 'utils/constants/routes';
import { iconBySection } from 'utils/faq/icon_by_section';
import { HistoryProps } from 'utils/history';
import FaqPageCardItem from './components/FaqPageCardItem';

interface FaqPagesListScreenProps {
  applicationStore: ApplicationStoreType;
  faqPagesStore: FaqPagesStoreType;
}

@inject('applicationStore', 'faqPagesStore')
@observer
class FaqPagesListScreen extends React.Component<
  FaqPagesListScreenProps & HistoryProps
> {
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { faqPagesStore } = this.props;
    await faqPagesStore.getFaqPages();
  }

  renderFaqsBySection() {
    const { faqPagesStore } = this.props;
    const sectionWithFaqs = faqPagesStore.faqsBySection;

    return (
      <div className="faq-page">
        {sectionWithFaqs.map((section, index) => {
          const icon = iconBySection(
            section.section as FaqPagesSectionTypeEnumType
          );
          if (!icon) return null;

          return (
            <React.Fragment key={index}>
              <SectionHeadline
                title={section.section}
                key={section.section}
                big={true}
                lessPaddingTop={index !== 0}
              />
              <ColumnWrapper gap="1em">
                {section.faqPages.map((faq) => (
                  <Link
                    key={faq.id}
                    to={`${ROUTE_FAQ_PAGES}/${faq.id}`}
                    className="naked-link"
                  >
                    <CardWrapper subtle={true}>
                      <Columns gap={GapSizes.L} columnTemplate="auto 1fr auto">
                        <Column>
                          <Icon name={icon} size="large" />
                        </Column>

                        <Column>{faq.title}</Column>
                        {faq.comment_count && (
                          <Column>
                            <div className="faq-page__icon-wrapper">
                              <Icon name="comment" />
                              <div className="faq-page__comments-count">
                                {faq.comment_count}
                              </div>
                            </div>
                          </Column>
                        )}
                      </Columns>
                    </CardWrapper>
                  </Link>
                ))}
              </ColumnWrapper>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  renderFaqSlider() {
    const { faqPagesStore } = this.props;
    const faqPages = faqPagesStore.faqUpdatePages;

    return this.renderPage(
      <div className="faq-slider">
        <SimpleCardSlider
          responsiveConfig={smallSliderResponsiveConfig}
          fullHeight={true}
        >
          {faqPages.map((faqPage) => {
            if (!faqPage.title) return null;
            return (
              <FaqPageCardItem
                key={faqPage.id}
                title={faqPage.title}
                description={faqPage.description}
                image={faqPage.teaser_url}
                handleClick={() => {
                  this.props.history.push(`${ROUTE_FAQ_PAGES}/${faqPage.id}`);
                }}
              />
            );
          })}
        </SimpleCardSlider>

        {this.renderFaqsBySection()}
      </div>
    );
  }

  renderPage(content: any) {
    return (
      <AppLayout active="faq-pages" reduced={true}>
        <PageHeader titleId="FAQ(titleId)" />

        <ContentWrapper>{content}</ContentWrapper>
      </AppLayout>
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  renderError() {
    const { faqPagesStore } = this.props;
    return this.renderPage(
      <ErrorMessage
        state={faqPagesStore.loadingState}
        onRetry={() => this.loadData()}
      />
    );
  }

  render() {
    const { faqPagesStore } = this.props;

    if (faqPagesStore.isLoading) {
      return this.renderLoading();
    }

    if (faqPagesStore.isError) {
      return this.renderError();
    }

    return this.renderFaqSlider();
  }
}

export default FaqPagesListScreen;
