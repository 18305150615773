import { Route, Switch } from 'react-router';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_INVITATIONS } from 'utils/constants/routes';
import BenchmarkInvitationsScreen from './BenchmarkInvitationsScreen';
import HypothesisInvitationsScreen from './HypothesisInvitationsScreen';
import PainpointInvitationsScreen from './PainpointInvitationsScreen';
import PrototypeInvitationsScreen from './PrototypeInvitationsScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_INVITATIONS + '/painpoints'}
      component={PainpointInvitationsScreen}
    />
    <Route
      path={ROUTE_INVITATIONS + '/benchmarks'}
      component={BenchmarkInvitationsScreen}
    />
    <Route
      path={ROUTE_INVITATIONS + '/hypotheses'}
      component={HypothesisInvitationsScreen}
    />
    <Route
      path={ROUTE_INVITATIONS + '/prototypes'}
      component={PrototypeInvitationsScreen}
    />

    <NotFoundRoute />
  </Switch>
);
