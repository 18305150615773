import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import CardHeader from 'components/CardHeader';
import CompanyLogo from 'components/CompanyLogo';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import MobilePreview from 'components/MobilePreview';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import RainbowCard from 'components/RainbowCard/RainbowCard';
import TagList from 'components/TagList';
import Typography from 'components/Typography';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import SharedContentDetailContainer from 'containers/SharedContentContainer/SharedContentDetailContainer';
import { SharedContentStoreType } from 'models/SharedContentStore';
import { HistoryProps, idFromMatch } from 'utils/history';

// tslint:disable: jsx-wrap-multiline

interface SharedBenchmarkScreenProps extends WrappedComponentProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedBenchmarkScreen extends React.Component<
  SharedBenchmarkScreenProps & HistoryProps
> {
  renderNotFound() {
    return (
      <SharedContentDetailContainer notFound={true} itemType="benchmarks" />
    );
  }

  render() {
    const { sharedContentStore, intl } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return this.renderNotFound();
    }

    const item = sharedContentStore.benchmarks?.get(id?.toString());
    if (!item) {
      return this.renderNotFound();
    }

    const attachment = item.attachments.firstOfType('screen');
    const logo = item.attachments.firstOfType('logo');

    const phone =
      attachment && attachment.resource_urls ? (
        <UploadedMobileImage attachment={attachment} />
      ) : (
        <MobilePreview alt="Empty attachment" />
      );

    return (
      <SharedContentDetailContainer itemType="benchmarks">
        <PageHeader
          title="Benchmarks"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
              subtitle={item.company_name || ''}
              logo={
                logo &&
                logo.resource_urls && (
                  <CompanyLogo
                    imgUrl={logo.resource_urls.small}
                    alt="Logo"
                    big={true}
                  />
                )
              }
            />
          }
        />

        <ContentWrapper>
          <TagList tags={item.tags} />

          <RainbowEditWrapper>
            <RainbowEditWrapper.Image>{phone}</RainbowEditWrapper.Image>
            <RainbowEditWrapper.Body>
              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="solution short description" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {item.description || <FormattedMessage id="no content yet" />}
                </Typography>
              </RainbowCard>

              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="will help us how" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {item.purpose || <FormattedMessage id="no content yet" />}
                </Typography>
              </RainbowCard>
            </RainbowEditWrapper.Body>
          </RainbowEditWrapper>
        </ContentWrapper>
      </SharedContentDetailContainer>
    );
  }
}

export default injectIntl(SharedBenchmarkScreen);
