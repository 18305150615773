import { flow, getEnv, types } from 'mobx-state-tree';
import { ROUTE_SHARED_BASE } from 'utils/constants/routes';
import { sortPublishedAsc } from 'utils/sort/published-at';
import createMap, { createMapWithTransform } from 'utils/store/createMap';

import BenchmarkModel, {
  BenchmarkModelType,
  createBenchmarkModel
} from './BenchmarkModel';
import HypothesisModel, {
  createHypothesisModel,
  HypothesisModelType
} from './HypothesisModel';
import LearningModel, {
  createLearningModel,
  LearningModelType
} from './LearningModel';
import PainpointModel, {
  createPainpointModel,
  PainpointModelType
} from './PainpointModel';
import PrototypeModel, {
  createPrototypeModel,
  PrototypeModelType
} from './PrototypeModel';

import { StoreEnv } from './StoreEnv';

const SharedContentStore = types
  .model('SharedContentStore', {
    currentToken: types.maybe(types.string),
    loadingState: types.maybe(
      types.enumeration([
        'loading',
        'error',
        'password_required',
        'wrong_password',
        'ok'
      ])
    ),

    benchmarks: types.maybe(types.map(BenchmarkModel)),
    hypotheses: types.maybe(types.map(HypothesisModel)),
    prototypes: types.maybe(types.map(PrototypeModel)),
    learnings: types.maybe(types.map(LearningModel)),
    painpoints: types.maybe(types.map(PainpointModel))
  })
  .actions((self) => {
    const reset = () => {
      self.currentToken = undefined;
      self.loadingState = undefined;

      self.benchmarks = undefined;
      self.hypotheses = undefined;
      self.prototypes = undefined;
      self.learnings = undefined;
      self.painpoints = undefined;
    };

    const getSharedContent = flow(function* (token: string, password?: string) {
      const { client } = getEnv<StoreEnv>(self);

      reset();
      self.loadingState = 'loading';

      try {
        const result: any = yield client.getSharedContent(token, password);

        if (!result) {
          throw new Error('No response from server.');
        }

        self.benchmarks = createMapWithTransform(
          result.benchmarks || [],
          createBenchmarkModel
        );
        self.hypotheses = createMapWithTransform(
          result.hypotheses || [],
          createHypothesisModel
        );
        self.prototypes = createMapWithTransform(
          result.prototypes || [],
          createPrototypeModel
        );
        self.learnings = createMapWithTransform(
          result.learnings || [],
          createLearningModel
        );

        self.painpoints = createMap();
        result.painpoints.forEach((painpoint: any) => {
          const newPainpoint = createPainpointModel(painpoint);
          painpoint.hypotheses.forEach((hypothesis: any) => {
            const hypo = createHypothesisModel(hypothesis);
            self.hypotheses?.put(hypo);
            newPainpoint.putHypothesis(hypo);
          });
          painpoint.prototypes.forEach((prototype: any) => {
            const proto = createPrototypeModel(prototype);
            self.prototypes?.put(proto);
            newPainpoint.putPrototype(proto);
          });
          self.painpoints?.put(newPainpoint);
        });

        self.currentToken = result.url_token || token;

        self.loadingState = 'ok';

        return result;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'SharedContentStore | getSharedContent',
            error,
            // @ts-ignore
            error.body
          );
        }

        // @ts-ignore
        if (client.isAccessDenied(error)) {
          self.loadingState = !password
            ? 'password_required'
            : 'wrong_password';
          return;
        }

        self.loadingState = 'error';
      }
    });

    return {
      getSharedContent,
      reset
    };
  })
  .views((self) => {
    return {
      get benchmarksCount(): number {
        return self.benchmarks?.size || 0;
      },
      get hypothesesCount(): number {
        return self.hypotheses?.size || 0;
      },
      get prototypesCount(): number {
        return self.prototypes?.size || 0;
      },
      get learningsCount(): number {
        return self.learnings?.size || 0;
      },
      get painpointsCount(): number {
        return self.painpoints?.size || 0;
      },

      get hasAny(): boolean {
        return this.benchmarksCount ||
          this.hypothesesCount ||
          this.prototypesCount ||
          this.learningsCount ||
          this.painpointsCount
          ? true
          : false;
      },

      get contextUri(): string {
        if (!self.currentToken) {
          return '';
        }

        return ROUTE_SHARED_BASE + '/' + self.currentToken;
      },

      get sortedBenchmarks(): BenchmarkModelType[] {
        if (!self.benchmarks?.size) {
          return [];
        }

        const list: BenchmarkModelType[] = [];

        for (const benchmark of self.benchmarks.values()) {
          list.push(benchmark);
        }

        list.sort(sortPublishedAsc);
        return list;
      },
      get sortedHypotheses(): HypothesisModelType[] {
        if (!self.hypotheses?.size) {
          return [];
        }

        const list: HypothesisModelType[] = [];

        for (const hypothesis of self.hypotheses.values()) {
          list.push(hypothesis);
        }

        list.sort(sortPublishedAsc);
        return list;
      },
      get sortedPrototypes(): PrototypeModelType[] {
        if (!self.prototypes?.size) {
          return [];
        }

        const list: PrototypeModelType[] = [];

        for (const prototype of self.prototypes.values()) {
          list.push(prototype);
        }

        list.sort(sortPublishedAsc);
        return list;
      },
      get sortedLearnings(): LearningModelType[] {
        if (!self.learnings?.size) {
          return [];
        }

        const list: LearningModelType[] = [];

        for (const learning of self.learnings.values()) {
          list.push(learning);
        }

        list.sort(sortPublishedAsc);
        return list;
      },
      get sortedPainpoints(): PainpointModelType[] {
        if (!self.painpoints?.size) {
          return [];
        }

        const list: PainpointModelType[] = [];

        for (const painpoint of self.painpoints.values()) {
          list.push(painpoint);
        }

        list.sort(sortPublishedAsc);
        return list;
      }
    };
  });

export type SharedContentStoreType = typeof SharedContentStore.Type;
export default SharedContentStore;
