import { FormattedMessage } from 'react-intl';

import SidebarMarker from '../SidebarMarker';
import SidebarTeaser from '../SidebarTeaser';
import { SidebarItemProps } from './item-types';

const LINK_TEMPLATES = '/downloads/assistant/disrooptive_sprint_templates.zip';

export const SidebarMaterial = ({ session }: SidebarItemProps) => {
  const date = session?.created_at;

  return (
    <>
      <SidebarMarker type="material" />

      <SidebarTeaser
        imageUrl="/images/assistant/post-its.jpg"
        imageExtLink={LINK_TEMPLATES}
        imageIcon="arrow-down"
        date={date}
      >
        <FormattedMessage
          id="assistant material postit"
          values={{
            print: (
              <a
                href={LINK_TEMPLATES}
                // target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="assistant material print" />
              </a>
            ),
            buy: (
              <a
                href="https://www.disrooptive.com/shop/innovation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="assistant material buy" />
              </a>
            )
          }}
        />
      </SidebarTeaser>
    </>
  );
};

export default SidebarMaterial;
