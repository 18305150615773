import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import ContentWrapper from 'components/Layout/ContentWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import { SharedContentStoreType } from 'models/SharedContentStore';
import { ROUTE_SHARED_BASE } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';
import SharedContentNavigation from './SharedContentNavigation';

type ItemType =
  | 'benchmarks'
  | 'hypotheses'
  | 'prototypes'
  | 'learnings'
  | 'painpoints';

interface PublicSharedContentDetailContainerProps
  extends WrappedComponentProps {
  itemType: ItemType;
  notFound?: boolean;
  children?: any;
}

interface SharedContentDetailContainerProps
  extends PublicSharedContentDetailContainerProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedContentDetailContainer extends React.Component<
  SharedContentDetailContainerProps & HistoryProps
> {
  renderNotFound() {
    return (
      <ContentWrapper>
        <CardWrapper>
          <FormattedMessage id="not_found" />
        </CardWrapper>
      </ContentWrapper>
    );
  }

  render() {
    const { sharedContentStore, itemType, notFound, intl, children } =
      this.props;
    const token = sharedContentStore.currentToken || '-';

    return (
      <SharedContentNavigation
        token={token}
        active={itemType}
        benchmarksCount={sharedContentStore.benchmarksCount}
        hypothesesCount={sharedContentStore.hypothesesCount}
        prototypesCount={sharedContentStore.prototypesCount}
        learningsCount={sharedContentStore.learningsCount}
        painpointsCount={sharedContentStore.painpointsCount}
      >
        {notFound ? this.renderNotFound() : children}

        <OverlayWrapper topRight={true}>
          <CloseButton
            label={intl.formatMessage({ id: 'Close' })}
            iconName="cross"
            onClick={() =>
              history.push(ROUTE_SHARED_BASE + '/' + token + '/' + itemType)
            }
          />
        </OverlayWrapper>
      </SharedContentNavigation>
    );
  }
}

export default injectIntl((props: PublicSharedContentDetailContainerProps) => (
  // @ts-ignore
  <SharedContentDetailContainer {...props} />
));
