import Icon from 'components/Icon';
import { inject } from 'mobx-react';
import { DataStoreType } from 'models/DataStore';
import { Component, FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import { HistoryProps } from 'utils/history';
import { searchQueryURL } from 'utils/history/search';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicSearchFormProps extends HistoryProps {
  query?: string;
  filter?: string;
  placeholder?: React.ReactNode | string;
  noMargin?: boolean | undefined;
  selectSize?: 's' | 'm';
  dataStore?: DataStoreType;
  hideProject?: boolean;
}

interface SearchFormProps extends PublicSearchFormProps {
  form: FormType;
}

const filterWidthLookup = {
  s: {
    network: '119px',
    organization: '175px',
    project: '160px'
  },
  m: {
    network: '143px',
    organization: '322px',
    project: '208px'
  }
};

class SearchForm extends Component<SearchFormProps> {
  private handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const searchURL = searchQueryURL({
      query: this.props.form.values.query || this.props.query,
      filter: this.props.form.values.filter || this.props.filter,
      organizationId: this.props.dataStore!.currentOrganizationId?.toString(),
      projectId: this.props.dataStore!.currentProjectId?.toString()
    });

    this.props.history.push(searchURL);
  }

  public render(): JSX.Element {
    const {
      form,
      placeholder,
      noMargin,
      location,
      dataStore,
      selectSize = 'm',
      hideProject = false
    } = this.props;

    return (
      <form
        className={
          'search-form search-screen__search-form' +
          (noMargin ? ' search-form__search-bar' : '')
        }
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <div className="search-form__fieldset">
          <div className="search-form__afterWrapper">
            <select
              name="filter"
              className="search-form__select"
              style={{
                width:
                  filterWidthLookup[selectSize][
                    form.values.filter as keyof (typeof filterWidthLookup)['s']
                  ]
              }}
              {...form.bindInput('filter')}
            >
              <option value="network">
                <FormattedMessage id="Network" />
              </option>
              {(location?.query?.organization_id ||
                dataStore?.currentOrganizationId) && (
                <option value="organization">
                  <FormattedMessage id="My Organization" />
                </option>
              )}
              {(location?.query?.project_id || dataStore?.currentProjectId) &&
                !hideProject && (
                  <option value="project">
                    <FormattedMessage id="Current Project" />
                  </option>
                )}
            </select>
          </div>
          <input
            className={'search-form__input'}
            type="text"
            name="query"
            {...form.bindInput('query')}
            placeholder={placeholder}
          />
          <span className="search-form__input-icon">
            <Icon name="magnify" />
          </span>
        </div>
      </form>
    );
  }
}

export default withRouter(
  inject('dataStore')((props: PublicSearchFormProps) => {
    const form = useForm({
      query: props.query || '',
      filter: props.filter || 'network'
    });
    // @ts-ignore
    return <SearchForm {...props} form={form} />;
  })
);
