import queryString from 'query-string';

export const transformQueryString = (location: any) => {
  if (location && location.search) {
    location.query = queryString.parse(location.search);
  }
};

export const queryStringListener = (history: any) => {
  history.listen((location: any) => {
    transformQueryString(location);
  });
};
