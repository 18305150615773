import UserAvatar from 'components/Avatar/UserAvatar';
import CardMenu from 'components/CardMenu';
import CardWrapper from 'components/CardWrapper';
import CircleChart from 'components/CircleChart/CircleChart';
import EditMenu from 'components/EditMenu';
import ErrorMessage from 'components/ErrorMessage';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import FileWrapper from 'components/Layout/FileWrapper';
import Loading from 'components/Loading';
import MenuButton from 'components/MenuButton';
import PageHeader from 'components/PageHeader';
import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import RainbowCard from 'components/RainbowCard';
import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { getProgressPercentage } from 'utils/constants/assistant-steps';
import ROLES from 'utils/constants/roles';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

// tslint:disable: jsx-wrap-multiline

interface InvitationsScreenProps {
  sessionType:
    | 'benchmarks_sprint'
    | 'painpoint_session'
    | 'hypothesis_sprint'
    | 'prototype_sprint';
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  dataStore: DataStoreType;
}

interface InvitationsScreenState {
  reinviteLoadingState?: 'loading';
}

@inject('applicationStore', 'assistantStore', 'dataStore')
@observer
class InvitationsScreen extends React.Component<
  InvitationsScreenProps & HistoryProps & WrappedComponentProps,
  InvitationsScreenState
> {
  state: InvitationsScreenState = {};

  componentDidMount() {
    this.load();
  }

  private async load() {
    this.props.assistantStore.getSentAssistantInvitations(
      this.props.applicationStore.currentProject?.id
    );
  }

  private async reinvite(sessionId: number) {
    const {
      applicationStore,
      assistantStore,
      intl: { formatMessage }
    } = this.props;

    this.setState({
      reinviteLoadingState: 'loading'
    });

    try {
      await assistantStore.reinvite(sessionId);

      applicationStore.setFlashMessage(formatMessage({ id: 'reinvite flash' }));
    } catch (error: any) {
      applicationStore.setFlashMessage(
        formatMessage({ id: 'reinvite error flash' }),
        'error'
      );
    }

    this.setState({
      reinviteLoadingState: undefined
    });
  }

  private async remove(sessionId: number, hasProgressAlready: boolean = false) {
    const {
      applicationStore,
      assistantStore,
      intl: { formatMessage }
    } = this.props;

    if (
      !window.confirm(
        formatMessage({
          id: hasProgressAlready
            ? 'remove progressed invitation confirm'
            : 'remove invitation confirm'
        })
      )
    ) {
      return;
    }

    this.setState({
      reinviteLoadingState: 'loading'
    });

    try {
      await assistantStore.deleteAssistantSession(sessionId);
    } catch (error: any) {
      applicationStore.setFlashMessage(
        formatMessage({ id: 'remove error flash' }),
        'error'
      );
    }

    this.setState({
      reinviteLoadingState: undefined
    });
  }

  private getActive(): 'open' | 'active' | 'finished' {
    const fromLocation = (this.props.location?.hash || '').substring(1);

    switch (fromLocation) {
      case 'open':
      case 'active':
      case 'finished':
        return fromLocation;

      default:
        return 'active';
    }
  }

  private renderPage(content?: any) {
    return (
      <ContextAwareProjectLayout active={this.props.sessionType}>
        <PageHeader titleId="Digital assistant(titleId)" />

        {content}

        {this.state.reinviteLoadingState === 'loading' && <Loading />}
      </ContextAwareProjectLayout>
    );
  }

  private renderList() {
    const { assistantStore, sessionType, intl } = this.props;
    const { currentUser } = this.props.dataStore;

    const active = this.getActive();
    const isActive = active === 'active';
    const isFinished = active === 'finished';
    const isOpen = active === 'open';
    const counts = assistantStore.invitationsCount(sessionType);

    const list = assistantStore.filteredInvitations(sessionType, active);

    return this.renderPage(
      <ContentWrapper>
        <TabList small={true} search={true}>
          <TabListButton
            title={
              <FormattedMessage
                id="In progress(count)"
                values={{ count: counts.active }}
              />
            }
            to="#active"
            active={active === 'active'}
          />

          <TabListButton
            title={
              <FormattedMessage
                id="Open(count)"
                values={{ count: counts.open }}
              />
            }
            to="#open"
            active={isOpen}
          />

          <TabListButton
            title={
              <FormattedMessage
                id="Finished(count)"
                values={{ count: counts.finished }}
              />
            }
            to="#finished"
            active={isFinished}
          />
        </TabList>

        <ColumnWrapper gap="3em">
          {!list.length ? (
            <CardWrapper>
              <FormattedMessage id={'no invites ' + active} />
            </CardWrapper>
          ) : (
            <FileWrapper centered={true}>
              {list.map((invite) => {
                const progress = isActive
                  ? getProgressPercentage(
                      invite.progress,
                      invite.assistant_type
                    )
                  : undefined;

                return (
                  <RainbowCard key={invite.id} shrink={true}>
                    <div className="invite-members__item">
                      <h2 className="invite-members__name">
                        {invite.author?.fullName || 'N/A'}
                      </h2>
                      <UserAvatar
                        user={invite.author}
                        noName={true}
                        noLink={true}
                      />
                    </div>

                    <CardMenu
                      small={true}
                      avatar={
                        isActive && (
                          <CircleChart
                            monochrome={true}
                            progress={progress}
                            label={progress + '%'}
                          />
                        )
                      }
                    >
                      {!isFinished && (
                        <EditMenu
                          bottomRight={true}
                          label={intl.formatMessage({ id: 'Edit' })}
                        >
                          <MenuButton
                            label={intl.formatMessage({ id: 'Reinvite' })}
                            iconName="envelope"
                            onClick={() => this.reinvite(invite.id)}
                          />
                          <MenuButton
                            label={intl.formatMessage({ id: 'Remove' })}
                            iconName="bin"
                            onClick={() => this.remove(invite.id, !isOpen)}
                          />
                          {currentUser?.role === ROLES.ADMIN && (
                            <MenuButton
                              label={intl.formatMessage({ id: 'Enter Sprint' })}
                              iconName="play"
                              onClick={() =>
                                this.props.history.push(
                                  ROUTE_ASSISTANT + '/' + invite.id
                                )
                              }
                            />
                          )}
                        </EditMenu>
                      )}
                    </CardMenu>
                  </RainbowCard>
                );
              })}
            </FileWrapper>
          )}
        </ColumnWrapper>
      </ContentWrapper>
    );
  }

  private renderLoading() {
    return this.renderPage(<Loading />);
  }

  render() {
    const { assistantStore } = this.props;

    if (assistantStore.isInvitationsLoading) {
      return this.renderLoading();
    }

    if (assistantStore.isInvitationsError) {
      return this.renderPage(
        <ErrorMessage
          state={assistantStore.invitationsLoadingState}
          onRetry={() => this.load()}
        />
      );
    }

    return this.renderList();
  }
}

export default injectIntl(InvitationsScreen);
