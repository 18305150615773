import React, { FormEvent } from 'react';

import classNames from 'classnames';
import FloatButton from 'components/FloatButton';
import Icon from 'components/Icon';
import { withRouter } from 'react-router';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import { searchQueryURL } from 'utils/history/search';

// tslint:disable-next-line: no-empty-interface
interface PublicFloatingSearchFormProps extends HistoryProps {}

interface FloatingSearchFormProps extends PublicFloatingSearchFormProps {
  form: FormType;
}

interface FloatingSearchFormState {
  visible: boolean;
}

class FloatingSearchForm extends React.Component<
  FloatingSearchFormProps,
  FloatingSearchFormState
> {
  inputRef: any = null;

  public state: FloatingSearchFormState = {
    visible: false
  };

  private handleShowForm() {
    this.setState({
      visible: true
    });

    // autoFocus={true} does not work, so do it manually
    setTimeout(() => this.inputRef?.focus && this.inputRef.focus(), 100);
  }

  private handleHideForm() {
    this.setState({
      visible: false
    });

    this.props.form.reset();
  }

  private handleToggleForm() {
    this.state.visible ? this.handleHideForm() : this.handleShowForm();
  }

  private handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const searchURL = searchQueryURL({ query: this.props.form.values.query });
    this.props.history.push(searchURL);
  }

  public render(): JSX.Element {
    const { form } = this.props;
    const { visible } = this.state;

    const formWrapperClassNames = classNames('floating-search-button__form', {
      'floating-search-button__form--shrink': !visible
    });

    return (
      <div className="floating-search-button">
        <div className={formWrapperClassNames}>
          <form className="search-form" onSubmit={(e) => this.handleSubmit(e)}>
            <div className="search-form__fieldset">
              <span className="search-form__input-icon">
                <Icon name="magnify" />
              </span>
              <input
                className={'search-form__input'}
                type="text"
                name="query"
                ref={(r) => (this.inputRef = r)}
                {...form.bindInput('query')}
              />
            </div>
          </form>
        </div>

        <FloatButton
          label="search"
          iconName="magnify"
          transformToIconName="plus"
          type="add"
          active={this.state.visible}
          onClick={() => this.handleToggleForm()}
        />
      </div>
    );
  }
}

export default withRouter((props: PublicFloatingSearchFormProps) => {
  const form = useForm();
  // @ts-ignore
  return <FloatingSearchForm {...props} form={form} />;
});
