import { types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

export const CompanyNameSuggestionModel = types.model(
  'CompanyNameSuggestionModel',
  {
    id: types.identifierNumber,
    company_name: types.string
  }
);

export const createCompanyNameSuggestionModel = (
  data?: any
): CompanyNameSuggestionModelType | undefined => {
  if (!data?.company_name) {
    return undefined;
  }

  return CompanyNameSuggestionModel.create({
    id: mandatoryId(data.id),
    company_name: data.company_name.toString()
  });
};

export type CompanyNameSuggestionModelType =
  typeof CompanyNameSuggestionModel.Type;
export default CompanyNameSuggestionModel;
