import classNames from 'classnames';
import CardHeader from 'components/CardHeader';
import ScreenItem from 'components/Layout/ScreenItem';
import MoveButton from 'components/MoveButton';
import UserFlowCard from 'components/UserFlowCard';
import { inject, observer } from 'mobx-react';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import { useEffect, useRef, useState } from 'react';

interface ICreateUserFlowItem {
  prototypesStore?: PrototypesStoreType;
  screen: PrototypeScreenModelType;
  onMoveClick: (id: number, direction: 'left' | 'right') => void;
  onRemoveClick: (id: number) => void;
  onEdit: (screenId: number, headline: string) => void;
}

function CreateUserFlowItem({
  screen,
  onMoveClick,
  onRemoveClick,
  onEdit
}: ICreateUserFlowItem) {
  const headlineElement = useRef<HTMLTextAreaElement>(null);
  const [editable, setEditable] = useState(false);

  const save = async () => {
    if (editable && onEdit) {
      onEdit(screen.id, headlineElement.current?.value || '');
    }
    return true;
  };

  const handleEditable = async () => {
    await save();
    setEditable(!editable);
  };

  useEffect(() => {
    if (editable) {
      headlineElement?.current?.select();
    }
  }, [editable]);

  const renderMoveButton = () => {
    if ((screen.isFirst && screen.isLast) || editable) return null;

    const moveClasses = classNames('move-buttons', {
      'move-buttons--multiple': !screen.isFirst && !screen.isLast
    });

    return (
      <div className={moveClasses}>
        {screen.isFirst ? (
          <MoveButton
            right={true}
            onClick={() => onMoveClick(screen.id, 'right')}
          />
        ) : screen.isLast ? (
          <MoveButton onClick={() => onMoveClick(screen.id, 'left')} />
        ) : (
          <>
            <MoveButton onClick={() => onMoveClick(screen.id, 'left')} />
            <MoveButton
              right={true}
              onClick={() => onMoveClick(screen.id, 'right')}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <ScreenItem asUserflow={true} small={true}>
      <UserFlowCard onRemoveClick={() => onRemoveClick(screen.id)}>
        <CardHeader
          title={`Schritt ${screen.order}`}
          simple={true}
          editIcon={editable ? 'check' : 'pen'}
          onEditClick={handleEditable}
        />
        <textarea
          ref={headlineElement}
          defaultValue={screen.headline}
          disabled={!editable}
          tabIndex={screen.order}
          onBlur={async () => {
            await save();
            setTimeout(() => {
              setEditable(false);
            }, 100);
          }}
          onKeyUp={(e: any) => {
            if (e.keyCode === 27) {
              e.preventDefault();
              e.stopPropagation();
              setEditable(false);
            }
          }}
          onKeyPress={async (e: any) => {
            if (e.charCode === 13) {
              e.preventDefault();
              e.stopPropagation();
              headlineElement.current?.blur();
            }
          }}
        />
      </UserFlowCard>
      {renderMoveButton()}
    </ScreenItem>
  );
}

export default inject('prototypesStore')(observer(CreateUserFlowItem));
