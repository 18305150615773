import CardWrapper from 'components/CardWrapper';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import CardStack from 'components/Layout/CardStack';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import MainButton from 'components/MainButton';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface DescribeUserFlowScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  form: FormType;
  intl: IntlShape;
}

function DescribeUserFlowScreen({
  applicationStore,
  assistantStore,
  location,
  form,
  intl
}: DescribeUserFlowScreenProps & HistoryProps) {
  const {
    currentItem,
    currentId,
    currentAssistantType,
    updateAssistantSession
  } = assistantStore;

  if (!currentId || !currentAssistantType) {
    return <>loading...</>;
  }

  useEffect(() => {
    form.setField('prototype_headline', currentItem?.prototype_headline);
    form.setField('prototype_description', currentItem?.prototype_description);

    scrollSidebar('userflow');
  }, []);

  const stepInfo = getStepInfo('step5', currentAssistantType);

  const updateModel = async () => {
    await updateAssistantSession(currentId, {
      prototype_headline: form.values['prototype_headline'],
      prototype_description: form.values['prototype_description']
    });
  };

  const continueSession = async () => {
    if (
      !form.values['prototype_headline'] ||
      !form.values['prototype_description']
    ) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'please fill out all fields' }),
        'warning'
      );
      return false;
    }
    const progress = ASSISTANTS[currentAssistantType].step5.progress;
    await assistantStore!.updateAssistantSessionProgess(currentId, progress);
    await updateModel();

    goToNextStep(currentId, progress, currentAssistantType);
  };

  return (
    <StepContainer
      progress={stepInfo?.percent}
      sidebarHeadline={stepInfo?.header}
      showHeader="hypothesis"
      location={location}
      sessionId={currentItem?.id}
      bottomSpace={false}
      minHeightZero={true}
      timerSeconds={900}
    >
      <SessionTop>
        <SessionHeadline step={stepInfo?.header}>
          <FormattedMessage id="assistant describe userflow" />
        </SessionHeadline>
      </SessionTop>

      <SessionMainText noMargin={true}>
        <FormattedMessage id="assistant describe userflow introduction" />
      </SessionMainText>

      <ColumnWrapper gap="3em" noTranslate={true}>
        <Form
          loading={form.loading}
          onSubmit={() => {
            void 0;
          }}
        >
          <CardWrapper>
            <Input
              headline={true}
              name="prototype_headline"
              {...form.bindInput('prototype_headline')}
              placeholder={intl.formatMessage({
                id: 'Insert headline'
              })}
              onBlur={updateModel}
            />
          </CardWrapper>
          <ColumnWrapper gap="1em">
            <CardStack>
              <CardWrapper>
                <ColumnWrapper gap="0.5em">
                  <Textarea
                    onBlur={updateModel}
                    label={intl.formatMessage({
                      id: 'prototype short description'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'prototype description placeholder'
                    })}
                    name="prototype_description"
                    {...form.bindInput('prototype_description')}
                  />
                </ColumnWrapper>
              </CardWrapper>
            </CardStack>
          </ColumnWrapper>
        </Form>
      </ColumnWrapper>

      <SessionBottom>
        <SessionActions>
          <MainButton onClick={continueSession}>
            <FormattedMessage id="Next" />
          </MainButton>
        </SessionActions>
      </SessionBottom>
    </StepContainer>
  );
}

const InjectedScreen = inject(
  'assistantStore',
  'applicationStore'
)(observer(injectIntl(DescribeUserFlowScreen)));

export default injectIntl((props: DescribeUserFlowScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <InjectedScreen {...props} form={form} />;
});
