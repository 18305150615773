import FilePreview from 'components/FilePreview';
import { UploadedFileContainerChildProps } from 'containers/UploadContainer/UploadedFileContainer';

interface UploadedImageProps {
  logo?: boolean;
  medium?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default ({
  attachment,
  onRemoveClick,
  state,
  logo,
  onClick,
  medium
}: UploadedFileContainerChildProps & UploadedImageProps) => (
  // TODO error
  <FilePreview
    small={logo ? true : false}
    medium={medium}
    logo={logo}
    loading={state === 'removing'}
    imgUrl={attachment.resource_urls?.large}
    alt="Attachment"
    onDestroy={onRemoveClick}
    onClick={onClick}
  />
);
