import { injectIntl, WrappedComponentProps } from 'react-intl';

import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { PrototypeModelType } from 'models/PrototypeModel';

export default injectIntl(
  ({
    prototype,
    intl,
    onClick,
    selected
  }: {
    prototype: PrototypeModelType;
    onClick: () => void;
    selected: boolean;
  } & WrappedComponentProps) => {
    const screens = prototype?.sortedScreens;
    const logo = prototype.hypothesis?.attachments?.firstOfType('logo');

    return (
      <RainbowCardCompact
        simple={true}
        checked={selected}
        onCheckboxChange={onClick}
        title={prototype.headline || intl.formatMessage({ id: 'no headline' })}
        src={
          screens[0]?.attachments?.firstOfType('screen')?.resource_urls?.small
        }
        logo={
          logo &&
          logo.resource_urls && (
            <CompanyLogo
              imgUrl={logo.resource_urls.small}
              alt={intl.formatMessage({ id: 'Logo' })}
            />
          )
        }
      />
    );
  }
);
