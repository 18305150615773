import { FormattedMessage } from 'react-intl';

import CallToAction from 'components/CallToAction';
import ContentLayout from 'components/Layout/ContentLayout';
import SubNav from 'components/SubNav';
import SubNavCategory from 'components/SubNavCategory';
import SubNavItem from 'components/SubNavItem';
import {
  ROUTE_USER_PROFILE_BENCHMARKS,
  ROUTE_USER_PROFILE_BOOKMARKS,
  ROUTE_USER_PROFILE_HYPOTHESES,
  ROUTE_USER_PROFILE_LEARNINGS,
  ROUTE_USER_PROFILE_OVERVIEW,
  ROUTE_USER_PROFILE_PAINPOINTS,
  ROUTE_USER_PROFILE_PROTOTYPES,
  ROUTE_USER_PROFILE_SETTINGS
} from 'utils/constants/routes';
import AIResponseCounter from 'components/AIResponseCounter';

export interface ProfileLayoutProps {
  active?:
    | 'overview'
    | 'painpoints'
    | 'benchmarks'
    | 'hypotheses'
    | 'prototypes'
    | 'learnings'
    | 'bookmarks'
    | 'drafts'
    | 'settings';
  children?: React.ReactNode;
  isSelf?: boolean;
  userId?: number;
  userName?: string;
}

// tslint:disable: jsx-wrap-multiline
export default ({
  active,
  children,
  userId,
  userName,
  isSelf
}: ProfileLayoutProps) => {
  if (!userId) {
    return null;
  }

  const id = userId.toString();

  const fillId = (route: string, idToUse: string) =>
    route.replace(':id', idToUse);

  return (
    <ContentLayout
      subNav={
        <SubNav title={userName}>
          <SubNavCategory title="">
            <SubNavItem
              title={<FormattedMessage id="Overview" />}
              iconName="envelope"
              active={active === 'overview'}
              to={fillId(ROUTE_USER_PROFILE_OVERVIEW, id)}
            />
            {isSelf && (
              <>
                <SubNavItem
                  title={<FormattedMessage id="Bookmarks" />}
                  iconName="flag-full"
                  active={active === 'bookmarks'}
                  to={fillId(ROUTE_USER_PROFILE_BOOKMARKS, id)}
                />
                {/* <SubNavItem
                  title={<FormattedMessage id="Drafts" />}
                  iconName="pen"
                  active={active === 'drafts'}
                  to={fillId(ROUTE_USER_PROFILE_DRAFTS, id)}
                /> */}
                <SubNavItem
                  title={<FormattedMessage id="Settings(local)" />}
                  iconName="cogwheel"
                  active={active === 'settings'}
                  to={fillId(ROUTE_USER_PROFILE_SETTINGS, id)}
                />
              </>
            )}
          </SubNavCategory>

          <SubNavCategory
            title={
              <FormattedMessage
                id={isSelf ? 'My collections' : 'Collections'}
              />
            }
          >
            <SubNavItem
              title={<FormattedMessage id="Painpoints" />}
              iconName="clipboard"
              active={active === 'painpoints'}
              to={fillId(ROUTE_USER_PROFILE_PAINPOINTS, id)}
            />
            <SubNavItem
              title={<FormattedMessage id="Benchmarks" />}
              iconName="detail"
              active={active === 'benchmarks'}
              to={fillId(ROUTE_USER_PROFILE_BENCHMARKS, id)}
            />
            <SubNavItem
              title={<FormattedMessage id="Hypotheses" />}
              iconName="bulb"
              active={active === 'hypotheses'}
              to={fillId(ROUTE_USER_PROFILE_HYPOTHESES, id)}
            />
            <SubNavItem
              title={<FormattedMessage id="Prototypes" />}
              iconName="strategy"
              active={active === 'prototypes'}
              to={fillId(ROUTE_USER_PROFILE_PROTOTYPES, id)}
            />
            <SubNavItem
              title={<FormattedMessage id="Learnings" />}
              iconName="learning"
              active={active === 'learnings'}
              to={fillId(ROUTE_USER_PROFILE_LEARNINGS, id)}
            />
          </SubNavCategory>
        </SubNav>
      }
    >
      <AIResponseCounter />
      {children}

      <CallToAction />
    </ContentLayout>
  );
};
