import { types } from 'mobx-state-tree';

export enum FaqPagesSectionType {
  briefings = 'briefings',
  painpoints = 'painpoints',
  benchmarks = 'benchmarks',
  hypotheses = 'hypotheses',
  prototypes = 'prototypes',
  learnings = 'learnings',
  sprints = 'sprints',
  updates = 'updates',
}

export const FaqPagesSectionTypeEnum = types.enumeration(Object.values(FaqPagesSectionType));

export type FaqPagesSectionTypeEnumType = typeof FaqPagesSectionTypeEnum.Type;
export default FaqPagesSectionTypeEnum;