import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import {
  createEditingState,
  EditingStateEnum
} from './AssistantSessionSolutionModel';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';

export const AssistantSessionBenchmarkModel = types.model(
  'AssistantSessionBenchmarkModel',
  {
    id: types.identifierNumber,

    headline: types.maybe(types.string),
    company_name: types.maybe(types.string),
    description: types.maybe(types.string),
    purpose: types.maybe(types.string),

    editing_state: types.maybe(EditingStateEnum),
    attachments: AttachmentsMapModel,

    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string)
  }
);

export const createAssistantSessionBenchmarkModel = (
  data: any
): AssistantSessionBenchmarkModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return AssistantSessionBenchmarkModel.create({
    id: mandatoryId(data.id),

    headline: data.headline || undefined,
    company_name: data.company_name || undefined,
    description: data.description || undefined,
    purpose: data.purpose || undefined,

    editing_state: createEditingState(data?.editing_state),
    attachments: createAttachmentsMap(data?.attachments),

    created_at: data.created_at || undefined,
    updated_at: data.updated_at || undefined
  });
};

export type AssistantSessionBenchmarkModelType =
  typeof AssistantSessionBenchmarkModel.Type;
export default AssistantSessionBenchmarkModel;
