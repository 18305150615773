
import { InputStateInterface } from './InputInterface';

interface InputStateContainerProps extends InputStateInterface {
  children?: React.ReactNode;
}

export default ({ children, error, success }: InputStateContainerProps) => (
  <div className="input-state">
    {success && <div className="input-state__sucess">{success}</div>}
    {error && <div className="input-state__error">{error}</div>}
    {children}
  </div>
);
