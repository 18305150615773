import { FormattedMessage } from 'react-intl';

import TabList from 'components/TabList';
import TabListButton from 'components/TabList/TabListButton';
import { ElementContentType } from 'containers/list/ListOverviewContainer';

interface SearchNavProps {
  baseUrl: string;
  activeType?: ElementContentType;
}

const extendUrl = (url: string, extension?: string) => {
  if (!url) {
    return undefined;
  }

  const hasQuery = url.toString().indexOf('?') > -1;

  if (!extension) {
    return url;
  }

  return url + (hasQuery ? '&' : '?') + extension;
};

export default ({ baseUrl, activeType }: SearchNavProps) => (
  <TabList search={true} small={true}>
    <TabListButton
      title={<FormattedMessage id="Overview" />}
      to={baseUrl}
      active={!activeType}
    />

    <TabListButton
      title={<FormattedMessage id="Briefings" />}
      to={extendUrl(baseUrl, 'type=briefing') || ''}
      active={activeType === 'briefing'}
    />
    <TabListButton
      title={<FormattedMessage id="Painpoints" />}
      to={extendUrl(baseUrl, 'type=painpoint') || ''}
      active={activeType === 'painpoint'}
    />
    <TabListButton
      title={<FormattedMessage id="Benchmarks" />}
      to={extendUrl(baseUrl, 'type=benchmark') || ''}
      active={activeType === 'benchmark'}
    />
    <TabListButton
      title={<FormattedMessage id="Hypotheses" />}
      to={extendUrl(baseUrl, 'type=hypothesis') || ''}
      active={activeType === 'hypothesis'}
    />
    <TabListButton
      title={<FormattedMessage id="Prototypes" />}
      to={extendUrl(baseUrl, 'type=prototype') || ''}
      active={activeType === 'prototype'}
    />
    <TabListButton
      title={<FormattedMessage id="Learnings" />}
      to={extendUrl(baseUrl, 'type=learning') || ''}
      active={activeType === 'learning'}
    />
  </TabList>
);
