import ErrorMessage from 'components/ErrorMessage';
import Headline from 'components/headline';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import SimplePopup from 'components/SimplePopup';
import Typography from 'components/Typography';
import {
  SessionFixed,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  AssistantSessionUpdateType,
  AssistantStoreType
} from 'models/AssistantStore';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { ProjectModelType } from 'models/ProjectModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import BenchmarksListItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksListItem';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT, ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

export const MIN_IN_PROJECT = 20;
export const MAX_SELECTED = 4;

interface BenchmarkSelectScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  benchmarksStore: BenchmarksStoreType;
  form: FormType;
}

interface BenchmarkSelectScreenState {
  benchmarksWarningVisible?: boolean;
  selectionWarningVisible?: 'continue' | 'draft';
}

@inject('applicationStore', 'assistantStore', 'benchmarksStore')
@observer
class BenchmarkSelectScreen extends React.Component<
  BenchmarkSelectScreenProps & HistoryProps & WrappedComponentProps,
  BenchmarkSelectScreenState
> {
  state: BenchmarkSelectScreenState = {};

  componentDidMount() {
    this.loadBenchmarks();
    this.fillForm();

    scrollSidebar('benchmarks');
  }

  private async loadBenchmarks() {
    const { benchmarksStore } = this.props;
    const { currentItem } = this.props.assistantStore;
    const project = currentItem?.project;

    if (!project?.id || !project.organization_id) {
      this.props.history.replace(ROUTE_ASSISTANT);
      return;
    }

    await benchmarksStore.getBenchmarks(project.organization_id, project.id);

    if (benchmarksStore.list('active').length < MIN_IN_PROJECT) {
      // TODO only display if not already dismissed before?
      this.setState({
        benchmarksWarningVisible: true
      });
    }
  }

  private fillForm() {
    const { currentItem } = this.props.assistantStore;

    if (!currentItem) {
      return;
    }

    const checked: number[] = [];
    currentItem.benchmarks?.map(({ id }) => checked.push(id));

    this.props.form.setField('checked', checked);
  }

  private async submit(
    continueSession: boolean = false,
    warningDismissed: boolean = false
  ) {
    const { applicationStore, assistantStore, form, intl } = this.props;
    const { currentId } = assistantStore;

    const checked: number[] = form.values.checked || [];

    if (!currentId || !checked.length) {
      return;
    }

    if (!warningDismissed && continueSession && checked.length < MAX_SELECTED) {
      this.setState({
        selectionWarningVisible: continueSession ? 'continue' : 'draft'
      });
      return;
    }

    const patch: AssistantSessionUpdateType = {
      benchmark_ids: checked,
      benchmarks_date: new Date().toJSON()
    };

    if (continueSession) {
      patch.progress = ASSISTANTS.creative_session.step2_1.progress;
    }

    let success = false;
    try {
      if (await assistantStore.updateAssistantSession(currentId, patch)) {
        success = true;
      }
    } catch (error: any) {
      // probably a form error but this should not occur here
    }

    if (success) {
      if (continueSession) {
        if (
          assistantStore.currentItem?.assistant_type === 'hypothesis_sprint'
        ) {
          goToNextStep(
            assistantStore.currentItem.id,
            assistantStore.currentItem.progress,
            'hypothesis_sprint'
          );
        } else {
          this.props.history.push(
            ROUTE_ASSISTANT + '/' + currentId + '/session2/done'
          );
        }
      } else {
        this.props.history.push(ROUTE_ASSISTANT);
      }
    } else {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'save error' }),
        'error'
      );
    }
  }

  private checkChanged(id: number, newChecked: boolean) {
    const { form } = this.props;
    const checked: number[] = form.values.checked || [];

    if (newChecked) {
      if (checked.length >= MAX_SELECTED) {
        return;
      }

      if (checked.indexOf(id) > -1) {
        return;
      }
      form.setField('checked', checked.concat([id]));
    } else {
      form.setField(
        'checked',
        checked.filter((elem) => elem !== id)
      );
    }
  }

  private dismissWarning() {
    this.setState({
      benchmarksWarningVisible: undefined,
      selectionWarningVisible: undefined
    });
  }

  private continueWithSelection() {
    const isContinue = this.state.selectionWarningVisible === 'continue';

    this.setState({
      selectionWarningVisible: undefined
    });

    this.submit(isContinue, true);
  }

  private goToBenchmarks() {
    const project: ProjectModelType | undefined =
      this.props.assistantStore.currentItem?.project;
    if (!project) {
      return;
    }

    this.props.history.push(
      ROUTE_LAB +
        '/' +
        project.organization_id +
        '/' +
        project.id +
        '/benchmarks'
    );
  }

  private maybeRenderPopup() {
    if (this.state.benchmarksWarningVisible) {
      return (
        <SimplePopup
          submitTextId="Go to benchmarks"
          onSubmit={() => this.goToBenchmarks()}
          secondaryTextId="Begin session anyway"
          onSecondaryClick={() => this.dismissWarning()}
          onAbort={() => this.dismissWarning()}
          reduced={true}
        >
          <Headline>
            <FormattedMessage id="assistant benchmarks warning header" />
          </Headline>

          <Typography>
            <FormattedMessage
              id="assistant benchmarks warning"
              values={{ count: MIN_IN_PROJECT }}
            />
          </Typography>
        </SimplePopup>
      );
    }

    if (this.state.selectionWarningVisible) {
      return (
        <SimplePopup
          submitTextId="Continue anyway"
          onSubmit={() => this.continueWithSelection()}
          onAbort={() => this.dismissWarning()}
          reduced={true}
        >
          <Headline>
            <FormattedMessage
              id="assistant benchmarks selection warning header"
              values={{ count: MAX_SELECTED }}
            />
          </Headline>

          <Typography>
            <FormattedMessage
              id="assistant benchmarks selection warning"
              values={{ count: MAX_SELECTED }}
            />
          </Typography>
        </SimplePopup>
      );
    }

    return null;
  }

  renderCounter(count: number) {
    if (!count) {
      return null;
    }

    return (
      <div className="timer timer--running timer--highlight">
        <div className="timer__body">
          <FormattedMessage
            id="assistant benchmark counter"
            values={{
              count,
              max: MAX_SELECTED
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { benchmarksStore, form, location } = this.props;
    const { currentItem, currentAssistantType, isItemSaving } =
      this.props.assistantStore;
    const { isListLoading, isListError } = benchmarksStore;

    if (!currentItem) {
      return null;
    }

    const checked: number[] = form.values.checked || [];
    const buttonsDisabled = checked.length < 1;

    const list = isListError ? [] : benchmarksStore.list('active');

    const stepInfo = getStepInfo('step2_1', currentAssistantType);
    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="benchmarks"
        location={location}
        sessionId={currentItem.id}
        bottomSpace={true}
        timerSeconds={600}
        top={this.renderCounter(checked.length)}
      >
        {this.maybeRenderPopup()}
        {(isListLoading || isItemSaving) && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant benchmarks header" />
          </SessionHeadline>
        </SessionTop>

        <SessionMainText noMargin={true}>
          <FormattedMessage id="assistant benchmarks" />
        </SessionMainText>

        <VideoPopupLink
          videoId="hCgMr0M9gGY"
          // headline={videoHeadline}
          // preview={videoPreview}
        />

        {isListError ? (
          <ErrorMessage onRetry={() => this.loadBenchmarks()} />
        ) : (
          <>
            <ColumnWrapper gap="3em">
              {list.length > 0 && (
                <div className="decoration-select-benchmark" />
              )}

              {list.map((elem) => (
                <BenchmarksListItem
                  benchmark={elem}
                  key={elem.id}
                  fromList={false}
                  noMenu={true}
                  to={{
                    // pathname: contextUri + elem.id,
                    pathname: location.pathname,
                    search: '?benchmark=' + elem.id,
                    state: {
                      canGoBack: true
                    }
                  }}
                  checked={checked.indexOf(elem.id) > -1}
                  onChange={(c) => this.checkChanged(elem.id, c)}
                />
              ))}
            </ColumnWrapper>

            <SessionFixed>
              <MainButton
                secondary={true}
                disabled={buttonsDisabled}
                onClick={() => this.submit()}
              >
                <FormattedMessage id="Save draft" />
              </MainButton>
              <MainButton
                disabled={buttonsDisabled}
                onClick={() => this.submit(true)}
              >
                <FormattedMessage id="Next" />
              </MainButton>
            </SessionFixed>
          </>
        )}
      </StepContainer>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <BenchmarkSelectScreen {...props} form={form} />;
});
