
type HtmlSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

interface SelectTagProps extends HtmlSelectProps {
  label?: string;
  children?: React.ReactNode;
}

export default (props: SelectTagProps) => {
  const selectProps = {
    ...props,
    label: undefined,
    children: undefined
  };

  return (
    <li className="tag-list__list-item tag-list__list-item">
      <div className="tag-list__select-wrapper">
        <select
          className="tag-list__tag tag-list__tag--select"
          {...selectProps}
        >
          {!props.label ? null : (
            <option value="" disabled={true}>
              {props.label}
            </option>
          )}
          {props.children}
        </select>
      </div>
    </li>
  );
};
