import { flow, getEnv, types } from 'mobx-state-tree';
import PageSwitchDescriptor from 'types/PageSwitchDescriptor';
import arrUnique from 'utils/misc/arr-unique';
import { describePageSwitch } from 'utils/misc/page-switch';
import sortDraftsFirst from 'utils/sort/drafts-first';
import sortByField from 'utils/sort/field';
import multiSort from 'utils/sort/multi-sort';
import sortByNumber from 'utils/sort/number';
import { sortPublishedAsc, sortPublishedDesc } from 'utils/sort/published-at';
import {
  reduceAuthors,
  ReducedAuthors,
  reduceTags
} from 'utils/store/filter-reducers';

import { AttachmentModelType, createAttachmentModel } from './AttachmentModel';
import { BenchmarkModelType, createBenchmarkModel } from './BenchmarkModel';
import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { AdvancedStoreEnv } from './StoreEnv';
import { createServerTagList } from './TagListModel';
import { createPainpointModel } from './PainpointModel';

const BenchmarksStore = types
  .model('BenchmarksStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const handleActionErrors = <
      T extends 'load_error' | 'save_error' | 'delete_error'
    >(
      methodName: string,
      error: any,
      setState: (state?: 'not_found' | 'access_denied' | T) => void,
      defaultError: T
    ) => {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable-next-line
        console.error('BenchmarksStore | ' + methodName, error, error.body);
      }
      if (client.isNotFound(error)) {
        setState('not_found');
      } else if (client.isAccessDenied(error)) {
        setState('access_denied');
      } else if (client.isFormError(error)) {
        setState(undefined);
        throw error;
      } else if (applicationStore.handleAppError(error)) {
        setState(undefined);
      } else {
        setState(defaultError);
      }
      return undefined;
    };
    const getBenchmarks = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.listLoadingState = 'loading';
        dataStore.setBenchmarksList(undefined);

        const result: any = yield client.getBenchmarks(
          organizationId,
          projectId
        );

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.addBenchmark(createBenchmarkModel(item));
          list.push(storeItem);
        }

        dataStore.setBenchmarksList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        return handleActionErrors(
          'getBenchmarks',
          error,
          (state) => (self.listLoadingState = state),
          'load_error'
        );
      }
    });

    const getBenchmark = flow(function* (
      benchmarkId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setBenchmarkItem(undefined);

        const result: any = yield client.getBenchmark(
          organizationId,
          projectId,
          benchmarkId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        let painpoint;
        if (result.painpoint) {
          painpoint = dataStore.addPainpoint(
            createPainpointModel(result.painpoint)
          );
        }

        const benchmark = dataStore.addBenchmark(
          createBenchmarkModel(result),
          true
        );
        if (benchmark && painpoint) {
          benchmark.setPainpoint(painpoint);
        }
        dataStore.setBenchmarkItem(benchmark);

        self.itemLoadingState = undefined;
        return benchmark;
      } catch (error: any) {
        return handleActionErrors(
          'getBenchmark',
          error,
          (state) => (self.itemLoadingState = state),
          'load_error'
        );
      }
    });

    const getBenchmarkLogoByCompanyName = flow(function* (companyName: string) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.getBenchmarkLogosByCompanyName(
          companyName
        );

        if (!result) {
          throw new Error('No response from server');
        }

        let logo;
        if (result.logos?.length) {
          logo = createAttachmentModel(result.logos[0]);
        }

        const benchmarkLogo: { id?: number; logo?: AttachmentModelType } = {
          id: result.id || undefined,
          logo
        };

        return benchmarkLogo;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'BenchmarksStore | getBenchmarkLogoByCompanyName',
            error,
            error.body
          );
        }

        // we can safely ignore this
      }
    });

    const createBenchmark = flow(function* (
      benchmark: BenchmarkModelType,
      addToList: boolean = false,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';
        dataStore.setBenchmarkItem(undefined);

        const result: any = yield client.createBenchmark(
          organizationId,
          projectId,
          {
            ...benchmark,
            tags_attributes: createServerTagList(benchmark.tags),
            tags: undefined
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newBenchmark = dataStore.addBenchmark(
          createBenchmarkModel(result),
          true
        );
        dataStore.setBenchmarkItem(newBenchmark);

        if (addToList && newBenchmark) {
          dataStore.addToBenchmarksList(newBenchmark);
        }

        self.itemLoadingState = undefined;
        return newBenchmark;
      } catch (error: any) {
        return handleActionErrors(
          'createBenchmark',
          error,
          (state) => (self.itemLoadingState = state),
          'save_error'
        );
      }
    });

    const updateBenchmark = flow(function* (
      benchmarkId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        const result: any = yield client.updateBenchmark(
          organizationId,
          projectId,
          benchmarkId,
          serverPatch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newBenchmark = dataStore.addBenchmark(
          createBenchmarkModel(result),
          true
        );
        dataStore.setBenchmarkItem(newBenchmark);

        self.itemLoadingState = undefined;

        return newBenchmark;
      } catch (error: any) {
        return handleActionErrors(
          'updateBenchmark',
          error,
          (state) => (self.itemLoadingState = state),
          'save_error'
        );
      }
    });

    const deleteBenchmark = flow(function* (
      benchmarkId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'deleting';

        yield client.deleteBenchmark(organizationId, projectId, benchmarkId);

        dataStore.deleteBenchmark(benchmarkId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('BenchmarksStore | deleteBenchmark', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deleteBenchmark(benchmarkId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const bulkDeleteBenchmarks = flow(function* (
      benchmarkIds: number[],
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      self.listLoadingState = 'deleting';
      let deleted = 0;

      for (const benchmarkId of benchmarkIds) {
        try {
          yield deleteBenchmark(benchmarkId, organizationId, projectId);

          deleted++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error(
              'BenchmarksStore | bulkDeleteBenchmarks',
              error,
              error.body
            );
          }

          if (client.isNotFound(error)) {
            dataStore.deleteBenchmark(benchmarkId);
            deleted++;
          }
        }
      }

      self.listLoadingState = undefined;
      return deleted;
    });

    const initializeItem = (data: any) => {
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const benchmark = createBenchmarkModel(data);

      dataStore.addBenchmark(benchmark, true);
      dataStore.setBenchmarkItem(
        dataStore.benchmarks.get(benchmark.id.toString())
      );
    };

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    const fetchSuggestions = flow(function* (name: string) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.getSuggestions(name);

        if (!result) {
          throw new Error('No response from server');
        }

        dataStore.setSuggestions(result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'BenchmarksStore | fetchSuggestions',
            error,
            error.body
          );
        }

        // can safely be ignored
      }
    });

    return {
      getBenchmarks,
      getBenchmark,
      getBenchmarkLogoByCompanyName,
      initializeItem,
      createBenchmark,
      updateBenchmark,
      deleteBenchmark,
      bulkDeleteBenchmarks,
      setFilter,
      fetchSuggestions
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return (
          self.itemLoadingState === 'loading' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },

      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListDeleting(): boolean {
        return self.listLoadingState === 'deleting';
      },
      get isListBusy(): boolean {
        return this.isListLoading || this.isListDeleting;
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      get hasAny(): boolean {
        return dataStore.benchmarksList && dataStore.benchmarksList.length > 0
          ? true
          : false;
      },
      list(
        authorId?: string,
        tag?: string,
        companyName?: string,
        sort?: string,
        filter?: string
      ): BenchmarkModelType[] {
        if (!dataStore.benchmarksList || !dataStore.benchmarksList.length) {
          return [];
        }

        const authorIdNumber = authorId ? parseInt(authorId, 10) : undefined;

        let noFilter = !authorIdNumber && !tag && !companyName;
        const list: BenchmarkModelType[] = [];

        const filterLowercase = filter?.toLowerCase() || undefined;

        for (const item of dataStore.benchmarksList.values()) {
          if (!item) {
            continue;
          }

          if (authorIdNumber && item.author?.id !== authorIdNumber) {
            continue;
          }

          if (companyName && item.company_name !== companyName) {
            continue;
          }

          if (tag && (!item.tags || !item.tags.includes(tag))) {
            continue;
          }

          if (filterLowercase) {
            if (
              (!item.company_name ||
                item.company_name.toLowerCase().indexOf(filterLowercase) < 0) &&
              (!item.headline ||
                item.headline.toLowerCase().indexOf(filterLowercase) < 0)
            ) {
              continue;
            }
          }

          list.push(item);
        }

        let sortFunc;
        switch (sort) {
          case 'headline':
          case 'company_name':
            sortFunc = sortByField(sort);
            break;

          case 'newest':
            sortFunc = sortPublishedDesc;
            break;

          case 'oldest':
            sortFunc = sortPublishedAsc;
            break;

          default:
            if (sort) {
              noFilter = false;
              sortFunc = sortByNumber(sort, true);
            } else {
              sortFunc = sortPublishedDesc;
            }
        }

        const sorts = [sortFunc, sortByField('company_name')];

        if (noFilter) {
          sorts.unshift(sortDraftsFirst);
        }

        list.sort(multiSort(sorts));

        return list;
      },
      get listWithCurrentFilter(): BenchmarkModelType[] {
        return this.list(
          self.filter.get('author'),
          self.filter.get('tag'),
          self.filter.get('company'),
          self.filter.get('sort')
        );
      },
      pageSwitch(current?: BenchmarkModelType): PageSwitchDescriptor {
        return describePageSwitch<BenchmarkModelType>(
          this.listWithCurrentFilter,
          current
        );
      },

      get tags(): string[] {
        return reduceTags(dataStore.benchmarksList);
      },
      get authors(): ReducedAuthors {
        return reduceAuthors(dataStore.benchmarksList);
      },
      get companies(): string[] {
        let companies: string[] = [];

        if (!dataStore.benchmarksList) {
          return companies;
        }

        for (const item of dataStore.benchmarksList.values()) {
          if (item && item.company_name) {
            companies.push(item.company_name);
          }
        }

        companies = arrUnique(companies);
        companies.sort();

        return companies;
      },

      get suggestions(): Array<{ id: number; name: string }> | undefined {
        if (!dataStore.suggestions?.size) {
          return undefined;
        }

        const list: Array<{ id: number; name: string }> = [];

        for (const suggestion of dataStore.suggestions.values()) {
          list.push({
            id: suggestion.id,
            name: suggestion.company_name
          });
        }

        return list;
      }
    };
  });

export type BenchmarksStoreType = typeof BenchmarksStore.Type;
export default BenchmarksStore;
