import { FormattedMessage } from 'react-intl';

import ContentLayout from 'components/Layout/ContentLayout';
import SubNav from 'components/SubNav';
import SubNavCategory from 'components/SubNavCategory';
import SubNavItem from 'components/SubNavItem';
import { ROUTE_SHARED_BASE } from 'utils/constants/routes';

interface SharedContentNavigationProps {
  token: string;
  benchmarksCount?: number;
  hypothesesCount?: number;
  prototypesCount?: number;
  learningsCount?: number;
  painpointsCount?: number;
  active?:
    | 'benchmarks'
    | 'hypotheses'
    | 'prototypes'
    | 'learnings'
    | 'painpoints';
  children?: any;
}

// tslint:disable: jsx-wrap-multiline

export const SharedContentNavigation = ({
  token,
  benchmarksCount,
  hypothesesCount,
  prototypesCount,
  learningsCount,
  painpointsCount,
  active,
  children
}: SharedContentNavigationProps) => {
  return (
    <ContentLayout
      noHeader={true}
      subNav={
        <SubNav>
          <SubNav.Header>
            <FormattedMessage id="Collections" />
          </SubNav.Header>

          <SubNavCategory title={false}>
            {!!benchmarksCount && (
              <SubNavItem
                title={<FormattedMessage id="Benchmarks" />}
                iconName="detail"
                active={active === 'benchmarks'}
                to={ROUTE_SHARED_BASE + '/' + token + '/benchmarks'}
                itemNumber={benchmarksCount}
              />
            )}

            {!!hypothesesCount && (
              <SubNavItem
                title={<FormattedMessage id="Hypotheses" />}
                iconName="bulb"
                active={active === 'hypotheses'}
                to={ROUTE_SHARED_BASE + '/' + token + '/hypotheses'}
                itemNumber={hypothesesCount}
              />
            )}

            {!!prototypesCount && (
              <SubNavItem
                title={<FormattedMessage id="Prototypes" />}
                iconName="strategy"
                active={active === 'prototypes'}
                to={ROUTE_SHARED_BASE + '/' + token + '/prototypes'}
                itemNumber={prototypesCount}
              />
            )}

            {!!learningsCount && (
              <SubNavItem
                title={<FormattedMessage id="Learnings" />}
                iconName="learning"
                active={active === 'learnings'}
                to={ROUTE_SHARED_BASE + '/' + token + '/learnings'}
                itemNumber={learningsCount}
              />
            )}

            {!!painpointsCount && (
              <SubNavItem
                title={<FormattedMessage id="Painpoints" />}
                iconName="clipboard"
                active={active === 'painpoints'}
                to={ROUTE_SHARED_BASE + '/' + token + '/painpoints'}
                itemNumber={painpointsCount}
              />
            )}
          </SubNavCategory>
        </SubNav>
      }
    >
      {children}
    </ContentLayout>
  );
};

export default SharedContentNavigation;
