import { types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

export const ClusterModel = types
  .model('ClusterModel', {
    id: types.identifierNumber,
    name: types.maybe(types.string),
    order: types.number,
    created_at: types.maybe(types.string),
    isBeeingFilled: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const setIsBeeingFilled = (state: boolean) => {
      self.isBeeingFilled = state;
    };

    return { setIsBeeingFilled };
  });

export const createClusterModel = (data?: any): ClusterModelType => {
  return ClusterModel.create({
    id: mandatoryId(data?.id),
    name: data?.name || undefined,
    order: numberOrUndefined(data?.order) || 0,
    created_at: data?.created_at || undefined,
    isBeeingFilled: false
  });
};

export type ClusterModelType = typeof ClusterModel.Type;

export const FluidClusterModel = types.model('FluidClusterModel', {
  id: types.number,
  name: types.maybe(types.string),
  order: types.number,
  created_at: types.maybe(types.string),
  isBeeingFilled: types.optional(types.boolean, false)
}).actions((self) => {
  const setIsBeeingFilled = (state: boolean) => {
    self.isBeeingFilled = state;
  };

  return { setIsBeeingFilled };
});

export type FluidClusterModelType = typeof FluidClusterModel.Type;

export const createFluidClusterModel = (data?: any): FluidClusterModelType => {
  return FluidClusterModel.create({
    id: mandatoryId(data?.id),
    name: data?.name || undefined,
    order: numberOrUndefined(data?.order) || 0,
    created_at: data?.created_at || undefined,
    isBeeingFilled: false
  });
};

export default ClusterModel;
