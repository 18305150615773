import { FormattedMessage } from 'react-intl';

import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import shortenString from 'utils/misc/shorten-string';
import SidebarCard from '../SidebarCard';
import SidebarItem from '../SidebarItem';
import SidebarItemContent from '../SidebarItemContent';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

export const SidebarBriefing = ({ session }: SidebarItemProps) => {
  if (!session?.progress || !session.invited) {
    return null;
  }

  const briefing = session.project?.briefing?.question;
  if (!briefing) {
    return null;
  }

  const date = session.created_at;

  return (
    <SidebarMarker type="briefing">
      <SidebarItem>
        <SidebarItemHeader date={date}>
          <FormattedMessage id="assistant sidebar briefing" />
        </SidebarItemHeader>

        <SidebarItemContent>
          <SidebarCard
            to={{
              pathname:
                ROUTE_ASSISTANT +
                (session.assistant_type === 'benchmarks_sprint'
                  ? '/benchmarks/'
                  : '/') +
                session.id +
                '/invitation',
              state: {
                canGoBack: true
              }
            }}
            header={<FormattedMessage id="assistant sidebar briefing card" />}
            text={shortenString(briefing, LIST_ITEM_TEXT_LENGTH)}
          />
        </SidebarItemContent>
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarBriefing;
