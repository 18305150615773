import { IconNameType } from "components/Icon/Icon";
import { FaqPagesSectionTypeEnumType } from "models/FaqPagesSectionEnum";

export const iconBySection = (section?: FaqPagesSectionTypeEnumType): IconNameType =>  {
  if (section === 'briefings') {
    return 'envelope';
  }
  if (section === 'painpoints') {
    return 'clipboard';
  }
  if (section === 'benchmarks') {
    return 'detail';
  }
  if (section === 'hypotheses') {
    return 'bulb';
  }
  if (section === 'prototypes') {
    return 'strategy';
  }
  if (section === 'learnings') {
    return 'learning';
  }
  if (section === 'sprints') {
    return 'assistant';
  }
  if (section === 'updates') {
    return 'update';
  }

  return 'envelope';
}