import { Route } from 'react-router-dom';

import NavbarNotFoundScreen from './NavbarNotFoundScreen';
import NotFoundScreen from './NotFoundScreen';
import UnauthorizedNotFoundScreen from './UnauthorizedNotFoundScreen';

export const NotFoundRoute = () => <Route component={NotFoundScreen} />;

export const NavbarNotFoundRoute = () => (
  <Route component={NavbarNotFoundScreen} />
);

export const UnauthorizedNotFoundRoute = () => (
  <Route component={UnauthorizedNotFoundScreen} />
);

export default NotFoundScreen;
