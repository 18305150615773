import { inject, observer } from 'mobx-react';
import React from 'react';

import { DataStoreType } from 'models/DataStore';
import OrgLayout, { OrgLayoutProps } from './OrgLayout';

interface PublicContextAwareOrgLayoutProps {
  active?: OrgLayoutProps['active'];
  children?: OrgLayoutProps['children'];
}

interface ContextAwareOrgLayoutProps {
  dataStore: DataStoreType;
}

@inject('dataStore')
@observer
class ContextAwareOrgLayout extends React.Component<
  ContextAwareOrgLayoutProps & PublicContextAwareOrgLayoutProps
> {
  render() {
    const { dataStore, active, children } = this.props;

    if (!dataStore.currentOrganization) {
      return children;
    }

    return (
      <OrgLayout
        org={dataStore.currentOrganization}
        admin={dataStore.isOrgAdmin}
        active={active}
      >
        {children}
      </OrgLayout>
    );
  }
}

export default (props: PublicContextAwareOrgLayoutProps) => (
  // @ts-ignore
  <ContextAwareOrgLayout {...props} />
);
