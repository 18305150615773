import { CSSProperties } from 'react';

interface AutoGridProps {
  children?: React.ReactNode;
  gap?: string;
  gapVertical?: string;
  minSize?: string;
}

export default function AutoGrid({
  children,
  minSize = '16rem',
  gap = '1rem',
  gapVertical = gap
}: AutoGridProps) {
  const style = {
    '--auto-grid-gap': gap,
    '--auto-grid-gap-vertical': gapVertical,
    '--auto-grid-min-size': minSize
  } as CSSProperties;

  return (
    <div className="auto-grid" style={style}>
      {children}
    </div>
  );
}
