import classNames from 'classnames';
import Icon from 'components/Icon';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface MenuButtonProps extends HtmlButtonProps {
  label: React.ReactNode;
  inline?: boolean;
  iconName: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function ActionOverlayButton({
  label,
  inline,
  iconName,
  disabled,
  onClick
}: MenuButtonProps) {
  return (
    <button disabled={disabled}
      className={classNames('action-overlay-button', {
        'action-overlay-button--inline': inline,
        'action-overlay-button--disabled': disabled
      })}
      onClick={onClick}
    >
      <Icon name={iconName} />
      {label}
    </button>
  );
}
