import React from 'react';

import AppLayoutReduced from 'components/AppLayout/AppLayoutReduced';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import { inject, observer } from 'mobx-react';
import { AssistantType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ProjectsStoreType } from 'models/ProjectsStore';

interface PublicAssistantSessionContainerProps {
  assistantSessionId: number;
  assistantType: AssistantType | AssistantType[] | 'any';
  history: HistoryProps['history'];
  children?: React.ReactNode;
}

interface AssistantSessionContainerProps {
  assistantStore: AssistantStoreType;
  dataStore: DataStoreType;
  hypothesesStore: HypothesesStoreType;
  organizationsStore: OrganizationsStoreType;
  projectsStore: ProjectsStoreType;
}

@inject(
  'assistantStore',
  'dataStore',
  'hypothesesStore',
  'organizationsStore',
  'projectsStore'
)
@observer
class AssistantSessionContainer extends React.Component<
  AssistantSessionContainerProps & PublicAssistantSessionContainerProps
> {
  convertId(id: any): number {
    if (!id) {
      return 0;
    }

    return typeof id === 'number' ? id : parseInt(id, 10);
  }

  getSessionId(): number {
    return this.convertId(this.props.assistantSessionId);
  }

  goToAssistantIndex() {
    this.props.history.replace(ROUTE_ASSISTANT);
  }

  private isTypeAllowed(type: AssistantType) {
    const { assistantType } = this.props;

    if (assistantType === 'any') {
      return true;
    }

    if (Array.isArray(assistantType) && assistantType.indexOf(type) > -1) {
      return true;
    }

    if (assistantType === type) {
      return true;
    }

    return false;
  }

  async selectSession(assistantSessionId: number) {
    const { dataStore, assistantStore } = this.props;

    if (
      dataStore.assistantSessionItem?.id === assistantSessionId &&
      dataStore.assistantSessionItem?.synced_with_api
    ) {
      // project has been loaded already
      if (!this.isTypeAllowed(dataStore.assistantSessionItem.assistant_type)) {
        this.goToAssistantIndex();
      }

      return;
    }

    const session = await assistantStore.getAssistantSession(
      assistantSessionId
    );

    if (
      !session?.assistant_type ||
      !this.isTypeAllowed(session.assistant_type)
    ) {
      this.goToAssistantIndex();
    }
  }

  componentDidMount() {
    const assistantSessionId = this.getSessionId();

    if (!assistantSessionId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error(
        'AssistantSessionContainer mounted without assistant session ID!'
      );
      return;
    }

    this.selectSession(assistantSessionId);
  }

  componentDidUpdate(
    prevProps: AssistantSessionContainerProps &
      PublicAssistantSessionContainerProps
  ) {
    const assistantSessionId = this.getSessionId();

    if (assistantSessionId === this.convertId(prevProps.assistantSessionId)) {
      return;
    }

    if (!assistantSessionId) {
      // this should not happen!
      // tslint:disable-next-line: no-console
      console.error(
        'AssistantSessionContainer updated without assistant session ID!'
      );
      return;
    }

    this.selectSession(assistantSessionId);
  }

  renderNav(content: any) {
    return <AppLayoutReduced active="assistant">{content}</AppLayoutReduced>;
  }

  renderNotPresent(assistantSessionId: number) {
    const { assistantStore } = this.props;

    if (
      !assistantSessionId ||
      assistantStore.isItemLoadError ||
      assistantStore.isItemNotFound
    ) {
      return this.renderNav(
        <ErrorMessage
          state={
            !assistantSessionId ? 'not_found' : assistantStore.itemLoadingState
          }
          onRetry={
            assistantStore.isItemNotFound
              ? undefined
              : () => this.selectSession(assistantSessionId)
          }
        />
      );
    }

    return this.renderNav(<Loading />);
  }

  render() {
    const {
      dataStore: { assistantSessionItem }
    } = this.props;
    const assistantSessionId = this.getSessionId();

    if (
      !assistantSessionId ||
      assistantSessionItem?.id !== assistantSessionId
    ) {
      return this.renderNotPresent(assistantSessionId);
    }

    return this.renderNav(this.props.children);
  }
}

export default (props: PublicAssistantSessionContainerProps) => (
  // @ts-ignore
  <AssistantSessionContainer {...props} />
);
