import { Route, Switch } from 'react-router';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import OrganizationContainer from 'containers/OrganizationContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import OrgMembersScreen from 'screens/OrgMembersScreen';
import OrgSettingsScreen from 'screens/OrgSettingsScreen/OrgSettingsScreen';
import OrgSettingsTargetGroupsScreen from 'screens/OrgTargetGroupsScreen';
import OrgSettingsServicesScreen from 'screens/OrgServicesScreen';
import CreateProjectScreen from 'screens/ProjectsListScreen/CreateProjectScreen';
import ProjectsListScreen from 'screens/ProjectsListScreen/ProjectsListScreen';
import {
  ROUTE_ORG,
  ROUTE_ORG_MEMBERS,
  ROUTE_ORG_SETTINGS,
  ROUTE_ORG_SERVICES,
  ROUTE_ORG_TARGET_GROUPS,
  ROUTE_PROJECT
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import ProjectRouter from './ProjectRouter';

const OrgErrorComponent = ({ error, onRetry }: any) => (
  <ErrorMessage state={error} onRetry={onRetry} />
);
const OrgLoaderComponent = () => <Loading />;

export default ({ match }: HistoryProps) => (
  <OrganizationContainer
    orgId={match.params?.orgId}
    errorComponent={OrgErrorComponent}
    loaderComponent={OrgLoaderComponent}
  >
    <Switch>
      <Route path={ROUTE_ORG} exact={true} component={ProjectsListScreen} />

      <Route path={ROUTE_ORG_MEMBERS} component={OrgMembersScreen} />
      <Route
        path={ROUTE_ORG_TARGET_GROUPS}
        component={OrgSettingsTargetGroupsScreen}
      />
      <Route path={ROUTE_ORG_SERVICES} component={OrgSettingsServicesScreen} />
      <Route path={ROUTE_ORG_SETTINGS} component={OrgSettingsScreen} />

      <Route path={ROUTE_ORG + '/create'} component={CreateProjectScreen} />
      <Route path={ROUTE_PROJECT} component={ProjectRouter} />

      <NotFoundRoute />
    </Switch>
  </OrganizationContainer>
);
