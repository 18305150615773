
import DestroyButton from 'components/DestroyButton';
import { FormattedDate } from 'react-intl';

interface ChildrenProps {
  children?: React.ReactNode;
}

interface CommentPopupProps extends ChildrenProps {
  title?: React.ReactNode;
  buttons?: React.ReactNode;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface CommentPopupHeaderProps extends ChildrenProps {
  date?: Date | string;
}

export const CommentPopup = ({
  title,
  buttons,
  onClose,
  children
}: CommentPopupProps) => (
  <article id="commentForm" className="comment-popup">
    <div className="comment-popup__container">
      {title && (
        <div className="comment-popup__title">
          <h3>{title}</h3>
        </div>
      )}

      <div className="comment-popup__inner">{children}</div>
    </div>

    {buttons}
    {onClose && <DestroyButton onClick={onClose} />}
  </article>
);

const CommentPopupUser = ({ children }: ChildrenProps) => (
  <div className="comment-popup__user">{children}</div>
);

const CommentPopupContent = ({ children }: ChildrenProps) => (
  <div className="comment-popup__content">{children}</div>
);

const CommentPopupHeader = ({ date, children }: CommentPopupHeaderProps) => (
  <header className="comment-popup__header">
    <h3>{children}</h3>
    {date && (
      <time dateTime={date.toString()}>
        <FormattedDate value={date} year="numeric" month="long" day="numeric" />
      </time>
    )}
  </header>
);

const CommentPopupGrid = ({ children }: ChildrenProps) => (
  <div className="comment-popup__rating-container">{children}</div>
);

const CommentPopupRatings = ({ children }: ChildrenProps) => (
  <div className="comment-popup__rating">{children}</div>
);

CommentPopup.User = CommentPopupUser;
CommentPopup.Content = CommentPopupContent;
CommentPopup.Header = CommentPopupHeader;
CommentPopup.Grid = CommentPopupGrid;
CommentPopup.Ratings = CommentPopupRatings;

export default CommentPopup;
