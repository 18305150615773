import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_LEARNINGS } from 'utils/constants/routes';
import LearningsCreateScreen from './LearningsCreateScreen';
import LearningsDetailScreen from './LearningsDetailScreen';
import LearningsEditScreen from './LearningsEditScreen';
import LearningsListScreen from './LearningsListScreen/LearningsListScreen';
import LearningsRecommendationsScreen from './LearningsRecommendationsScreen';

export default () => (
  <ContextAwareProjectLayout active="learnings">
    <Switch>
      <Route
        path={ROUTE_LEARNINGS}
        exact={true}
        component={LearningsListScreen}
      />
      <Route
        path={ROUTE_LEARNINGS + '/create'}
        exact={true}
        component={LearningsCreateScreen}
      />
      <Route
        path={ROUTE_LEARNINGS + '/inspiration'}
        exact={true}
        component={LearningsRecommendationsScreen}
      />
      <Route
        path={ROUTE_LEARNINGS + '/:id'}
        exact={true}
        component={LearningsDetailScreen}
      />
      <Route
        path={ROUTE_LEARNINGS + '/:id/edit'}
        exact={true}
        component={LearningsEditScreen}
      />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
