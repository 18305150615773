
import MobilePreview from 'components/MobilePreview';
import { UploadedFileContainerChildProps } from 'containers/UploadContainer/UploadedFileContainer';

interface UploadedMobileImageProps extends UploadedFileContainerChildProps {
  disableVideo?: boolean;
  overflow?: boolean;
}

export default ({
  attachment,
  onRemoveClick,
  state,
  disableVideo,
  overflow
}: UploadedMobileImageProps) => {
  return (
    // TODO error
    <MobilePreview
      overflow={overflow}
      loading={state === 'removing'}
      alt="Attachment"
      onDestroy={onRemoveClick}
      imgUrl={
        ((attachment.file_type === 'image' ||
          (disableVideo && attachment.file_type === 'video')) &&
          attachment.resource_urls?.screen) ||
        undefined
      }
    >
      {!disableVideo && attachment.file_type === 'video' && (
        <video controls={true} autoPlay={true}>
          <source src={attachment.resource_urls?.original} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </MobilePreview>
  );
};
