
import Icon from 'components/Icon';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface MenuButtonProps extends HtmlButtonProps {
  label: React.ReactNode;
  description?: React.ReactNode;
  iconName: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function MenuButton({
  label,
  description,
  iconName,
  onClick,
  disabled = false
}: MenuButtonProps) {
  return (
    <button className="menu-button" onClick={onClick} disabled={disabled}>
      <div className="menu-button__icon">
        <Icon name={iconName} />
      </div>
      <div className="menu-button__content">
        {label}
        {description && (
          <span className="menu-button__description">{description}</span>
        )}
      </div>
    </button>
  );
}
