
import classNames from 'classnames';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { AddButtonProps } from './AddButton';

interface AddMobileButtonProps extends AddButtonProps {
  overflow?: boolean;
}

export default function AddMobileButton({
  label,
  ariaLabel,
  iconName,
  disabled,
  loading,
  onClick,
  overflow
}: AddMobileButtonProps) {
  return (
    <button
      className={classNames('mobile-upload', {
        'mobile-upload--overflow': overflow
      })}
      aria-label={ariaLabel}
      disabled={disabled || loading}
      type="button"
      onClick={onClick}
    >
      <div className="mobile-upload__content-wrapper">
        <span className="mobile-upload__content">
          <Icon name={iconName} />
          {!label ? null : label}
        </span>
        {loading && <Loading />}
      </div>
    </button>
  );
}
