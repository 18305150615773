import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { SessionMainText } from 'domain/assistant/session-elements';
import { AssistantStoreType } from 'models/AssistantStore';
import { ASSISTANTS, getStepInfo } from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';
import ExplanationScreen from '../ExplanationScreen';

const HypothesisIntroScreen = inject('assistantStore')(
  observer(
    ({
      location,
      assistantStore: { currentAssistantType }
    }: { assistantStore: AssistantStoreType } & HistoryProps) => {
      const stepInfo = getStepInfo('step4_1', currentAssistantType);

      return (
        <ExplanationScreen
          progress={
            stepInfo?.progress || ASSISTANTS.creative_session.step4_1.progress
          }
          barProgress={stepInfo?.percent}
          header={<FormattedMessage id="assistant hypothesis intro header" />}
          headerStep={stepInfo?.header}
          sidebarHeadline={stepInfo?.header}
          videoId="hCgMr0M9gGY"
          videoPreview="/images/assistant/video/hypothesis_intro.png"
          timerSeconds={1500}
          sidebarScrollTo="hypothesis"
          showHeader="hypothesis"
          location={location}
        >
          <SessionMainText>
            <FormattedMessage id="assistant hypothesis intro" />
          </SessionMainText>
        </ExplanationScreen>
      );
    }
  )
);

export default HypothesisIntroScreen;
