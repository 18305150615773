import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import AssistantGif from 'domain/assistant/AssistantGif';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import getIntl from 'i18n/locales';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantStoreType } from 'models/AssistantStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getStepInfo } from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT, ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface FinalizePainpointScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
}

@inject('applicationStore', 'assistantStore')
@observer
class FinalizePainpointScreen extends React.Component<
  FinalizePainpointScreenProps & HistoryProps
> {
  componentDidMount() {
    scrollSidebar('painpoint_question');
  }

  private async publish() {
    const { assistantStore, history } = this.props;

    if (!assistantStore.currentId) {
      return;
    }

    try {
      const session = await assistantStore.publishAssistantSession(
        assistantStore.currentId
      );

      if (session?.project?.organization_id) {
        history.push(
          ROUTE_LAB +
            '/' +
            session.project.organization_id +
            '/' +
            session.project.id +
            '/painpoints'
        );
      } else {
        history.push(ROUTE_ASSISTANT);
      }
    } catch (error: any) {
      this.props.applicationStore.setFlashMessage(
        getIntl().formatMessage({ id: 'assistant publish error' }),
        'error'
      );
    }
  }

  private saveDraft() {
    this.props.history.push(ROUTE_ASSISTANT);
  }

  render() {
    const { assistantStore } = this.props;
    const { currentItem, currentAssistantType } = assistantStore;

    const stepInfo = getStepInfo('finalize', currentAssistantType);
    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="painpoint_question"
        location={this.props.location}
      >
        {assistantStore.isItemSaving && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant session painpoint publish header" />
          </SessionHeadline>

          <SessionMainText>
            <FormattedMessage
              id="assistant session painpoint publish"
              values={{
                projectName: currentItem?.project?.name || '',
                break: <br />
              }}
            />
          </SessionMainText>

          <AssistantGif />
        </SessionTop>

        <SessionBottom>
          <SessionActions>
            <MainButton
              onClick={() => this.publish()}
              disabled={currentItem?.published}
            >
              <FormattedMessage id="Publish results" />
            </MainButton>

            <br />

            <MainButton onClick={() => this.saveDraft()}>
              <FormattedMessage id="Save as draft only" />
            </MainButton>
          </SessionActions>
        </SessionBottom>
      </StepContainer>
    );
  }
}

export default FinalizePainpointScreen;
