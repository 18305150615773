
import LoginFormContainer from 'containers/LoginFormContainer';

export const HomeScreen: React.FC = () => (
  <>
    <LoginFormContainer />
  </>
);

export default HomeScreen;
