import { useIntl } from 'react-intl';
import ActionOverlayButton from './ActionOverlayButton';
import { DataStoreType } from 'models/DataStore';
import { ActionsStoreType } from 'models/ActionsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { inject, observer } from 'mobx-react';
import { PainpointsStoreType } from 'models/PainpointsStore';

function BenchmarksButton({
  dataStore,
  actionsStore,
  applicationStore,
  painpointsStore
}: {
  dataStore?: DataStoreType;
  actionsStore?: ActionsStoreType;
  painpointsStore?: PainpointsStoreType;
  applicationStore?: ApplicationStoreType;
}) {
  const intl = useIntl();
  let disabled = false;
  let intlId: string;
  let count: number;

  const organizationId = dataStore!.currentOrganizationId!;
  const projectId = dataStore!.currentProjectId!;
  const painpointIds = actionsStore!.selectedIds.Painpoint;

  if (
    painpointIds.length <
    dataStore!.currentProject?.aiSelectLimits.minPainpoints!
  ) {
    disabled = true;
    count = dataStore!.currentProject?.aiSelectLimits.minPainpoints!;
    intlId = 'search benchmarks not enough painpoints';
  } else if (
    painpointIds.length >
    dataStore!.currentProject?.aiSelectLimits.maxPainpoints!
  ) {
    disabled = true;
    count = dataStore!.currentProject?.aiSelectLimits.maxPainpoints!;
    intlId = 'search benchmarks too many painpoints';
  }

  return (
    <ActionOverlayButton
      label={intl.formatMessage({ id: 'Search benchmarks' })}
      iconName="ai"
      disabled={dataStore?.project?.reachedBenchmarkLimit}
      onClick={async () => {
        if (disabled) {
          applicationStore!.setFlashMessage(
            intl.formatMessage(
              {
                id: intlId
              },
              { count }
            ),
            'inlineError'
          );
          return;
        }

        if (!dataStore?.project?.briefing.completed) {
          applicationStore!.setAiUnavailable(true);
          return;
        }

        actionsStore!.selectionClear();
        try {
          const response = await painpointsStore!.findBenchmarksByAi(
            organizationId,
            projectId,
            painpointIds
          );
          if (!response.success) {
            applicationStore!.setFlashMessage(
              intl.formatMessage({ id: 'AI create error' }),
              'error'
            );
          }
        } catch (error: any) {
          applicationStore!.setFlashMessage(
            intl.formatMessage({
              id: 'benchmark search failed error'
            }),
            'error'
          );
        }
      }}
    />
  );
}

export default inject(
  'dataStore',
  'actionsStore',
  'painpointsStore',
  'applicationStore'
)(observer(BenchmarksButton));
