import { FormattedMessage } from 'react-intl';

import ScreenItem from 'components/Layout/ScreenItem';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';

// tslint:disable: no-empty

export const PrototypeScreensItemDummy = ({
  onTitleClick,
  onScreenClick
}: any) => {
  return (
    <ScreenItem>
      <em className="l-screen-item__headline" onClick={onTitleClick}>
        <FormattedMessage id="Insert title" />
      </em>

      <UploadMobileButton
        onSelectFileClick={onScreenClick}
        onUploadClick={() => {}}
        onResetClick={() => {}}
      />
    </ScreenItem>
  );
};

export default PrototypeScreensItemDummy;
