import { FormattedMessage } from 'react-intl';

import { ASSISTANTS } from 'utils/constants/assistant-steps';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import shortenString from 'utils/misc/shorten-string';
import SidebarCard from '../SidebarCard';
import SidebarItem from '../SidebarItem';
import SidebarItemContent from '../SidebarItemContent';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

export const SidebarPainpointQuestion = ({
  session,
  showHeader
}: SidebarItemProps) => {
  if (!session?.painpoint_question) {
    if (showHeader) {
      return (
        <SidebarMarker type="painpoint_question">
          <SidebarItem>
            <SidebarItemHeader>
              <FormattedMessage id="assistant sidebar question" />
            </SidebarItemHeader>
          </SidebarItem>
        </SidebarMarker>
      );
    }

    return <SidebarMarker type="painpoint_question" />;
  }

  return (
    <SidebarMarker type="painpoint_question">
      <SidebarItem>
        <SidebarItemHeader date={session.painpoint_question_date}>
          <FormattedMessage id="assistant sidebar question" />
        </SidebarItemHeader>

        <SidebarItemContent>
          <SidebarCard
            to={{
              pathname:
                ROUTE_ASSISTANT +
                '/' +
                session.id +
                ASSISTANTS.creative_session.step1_4.route,
              state: {
                canGoBack: true
              }
            }}
            header={<FormattedMessage id="painpoint question prefix" />}
            text={shortenString(
              session.painpoint_question,
              LIST_ITEM_TEXT_LENGTH
            )}
            isQuestion={true}
          />
        </SidebarItemContent>
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarPainpointQuestion;
