import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardHeader from 'components/CardHeader';
import CompanyLogo from 'components/CompanyLogo';
import ErrorMessage from 'components/ErrorMessage';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import Loading from 'components/Loading';
import MobilePreview from 'components/MobilePreview';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import RainbowCard from 'components/RainbowCard';
import TagList from 'components/TagList';
import Typography from 'components/Typography';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import { AssistantStoreType } from 'models/AssistantStore';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { DataStoreType } from 'models/DataStore';

interface AssistantBenchmarkContainerProps {
  benchmarkId?: number; // TODO make this mandatory?
  assistantStore?: AssistantStoreType;
  benchmarksStore?: BenchmarksStoreType;
  dataStore?: DataStoreType;
}

@inject('assistantStore', 'benchmarksStore', 'dataStore')
@observer
class AssistantBenchmarkContainer extends React.Component<AssistantBenchmarkContainerProps> {
  componentDidMount() {
    this.checkLoadBenchmark();
  }

  private checkLoadBenchmark() {
    const { assistantStore, benchmarksStore, dataStore, benchmarkId } =
      this.props;

    if (!benchmarkId || dataStore!.benchmarks.has(benchmarkId.toString())) {
      return;
    }

    const session = assistantStore!.currentItem;
    if (!session?.project?.organization_id) {
      return;
    }

    benchmarksStore!.getBenchmark(
      benchmarkId,
      session.project.organization_id,
      session.project.id
    );
  }

  renderBenchmark(benchmark: BenchmarkModelType) {
    const screen = benchmark.attachments.firstOfType('screen');
    const logo = benchmark.attachments.firstOfType('logo');

    const phone =
      screen && screen.resource_urls ? (
        <UploadedMobileImage attachment={screen} />
      ) : (
        <MobilePreview alt="Empty attachment" />
      );

    return (
      <>
        <PageHeader
          title="Benchmarks"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={
                benchmark.headline || <FormattedMessage id="no headline" />
              }
              subtitle={benchmark.company_name || ''}
              logo={
                logo &&
                logo.resource_urls && (
                  <CompanyLogo
                    imgUrl={logo.resource_urls.small}
                    alt="Logo"
                    big={true}
                  />
                )
              }
            />
          }
        />

        <ContentWrapper>
          <TagList tags={benchmark.tags} />

          <RainbowEditWrapper>
            <RainbowEditWrapper.Image>{phone}</RainbowEditWrapper.Image>
            <RainbowEditWrapper.Body>
              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="solution short description" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {benchmark.description || (
                    <FormattedMessage id="no content yet" />
                  )}
                </Typography>
              </RainbowCard>

              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="will help us how" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {benchmark.purpose || (
                    <FormattedMessage id="no content yet" />
                  )}
                </Typography>
              </RainbowCard>
            </RainbowEditWrapper.Body>
          </RainbowEditWrapper>
        </ContentWrapper>
      </>
    );
  }

  render() {
    const { benchmarksStore, dataStore, benchmarkId } = this.props;

    if (!benchmarkId) {
      return <ErrorMessage state="not_found" />;
    }

    const benchmark = dataStore!.benchmarks.get(benchmarkId.toString());
    if (benchmark) {
      // Benchmark already present, just display
      return this.renderBenchmark(benchmark);
    }

    // Benchmark not present, needs to be fetched through BenchmarksStore
    if (benchmarksStore!.isItemLoading) {
      return <Loading />;
    }

    if (benchmarksStore!.isItemLoadError) {
      return <ErrorMessage state={benchmarksStore!.itemLoadingState} />;
    }

    // huh!?
    return <ErrorMessage state="not_found" />;
  }
}

export default AssistantBenchmarkContainer;
