
import { FluidUserModelType, UserModelType } from 'models/UserModel';
import { FormattedMessage } from 'react-intl';

export default ({ user }: { user?: UserModelType | FluidUserModelType }) => {
  if (!user || (!user.first_name && !user.last_name)) {
    return <FormattedMessage id="Unknown user" />;
  }

  return (
    <>
      {user.first_name} {user.last_name}
    </>
  );
};
