import ColumnWrapper from 'components/Layout/ColumnWrapper';
import MainButton from 'components/MainButton';
import HypothesisDetailContainer from 'containers/HypothesisDetailContainer';
import {
  SessionActions,
  SessionBottom,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { HistoryProps } from 'utils/history';

interface HypothesisStepShowScreenProps {
  assistantStore: AssistantStoreType;
  dataStore: DataStoreType;
  hypothesesStore: HypothesesStoreType;
}

function HypothesisStepShowScreen({
  dataStore,
  assistantStore,
  hypothesesStore,
  location,
  history
}: HypothesisStepShowScreenProps & HistoryProps) {
  const { currentItem, currentId, currentAssistantType } = assistantStore;

  const stepInfo = getStepInfo('step2', currentAssistantType);

  useEffect(() => {
    scrollSidebar('hypothesis_details');
  }, []);

  if (!currentId || !currentAssistantType) {
    return <>loading...</>;
  }

  const continueSession = async () => {
    const progress = ASSISTANTS[currentAssistantType].step2.progress;
    await assistantStore!.updateAssistantSessionProgess(currentId, progress);

    goToNextStep(currentId, progress, currentAssistantType);
  };

  return (
    <StepContainer
      progress={stepInfo?.percent}
      sidebarHeadline={stepInfo?.header}
      location={location}
      sessionId={currentItem?.id}
      bottomSpace={false}
      minHeightZero={true}
      timerSeconds={480}
    >
      <SessionTop>
        <SessionHeadline step={stepInfo?.header}>
          <FormattedMessage id="assistant incorporate hypothesis" />
        </SessionHeadline>
      </SessionTop>

      <SessionMainText noMargin={true}>
        <FormattedMessage id="assistant incorporate hypothesis introduction" />
      </SessionMainText>

      <VideoPopupLink
        videoId="hCgMr0M9gGY"
        // headline={videoHeadline}
        // preview={videoPreview}
      />
      <ColumnWrapper gap="3em">
        <HypothesisDetailContainer
          simple={true}
          withoutComment={true}
          hideClose={true}
        />
      </ColumnWrapper>

      <SessionBottom>
        <SessionActions>
          <MainButton onClick={continueSession}>
            <FormattedMessage id="Next" />
          </MainButton>
        </SessionActions>
      </SessionBottom>
    </StepContainer>
  );
}

export default inject(
  'dataStore',
  'assistantStore',
  'hypothesesStore'
)(observer(HypothesisStepShowScreen));
