import { PublishStateEnumType } from 'models/PublishStateEnum';
import PageSwitchDescriptor from 'types/PageSwitchDescriptor';

interface Element {
  id: number;
  publish_state?: PublishStateEnumType;
}

export const describePageSwitch = <T extends Element>(
  list?: T[],
  current?: T
): PageSwitchDescriptor => {
  if (!current || !list || list.length < 2) {
    return {};
  }

  const position = list.indexOf(current);

  if (position < 0) {
    return {};
  }

  if (position === 0) {
    return {
      next: {
        id: list[1].id,
        isDraft: list[1].publish_state === 'draft'
      }
    };
  }

  const prev = list[position - 1];

  if (position === list.length - 1) {
    return {
      prev: {
        id: prev.id,
        isDraft: prev.publish_state === 'draft'
      }
    };
  }

  const next = list[position + 1];

  return {
    prev: {
      id: prev.id,
      isDraft: prev.publish_state === 'draft'
    },
    next: {
      id: next.id,
      isDraft: next.publish_state === 'draft'
    }
  };
};
