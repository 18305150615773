import { UserModelType } from 'models/UserModel';

export const sortUsers = (desc = false) => {
  return (a?: UserModelType, b?: UserModelType) => {
    if (!a && b) {
      return 1;
    }
    if (a && !b) {
      return -1;
    }
    if (!a && !b) {
      return 0;
    }

    const aName = a!.fullName;
    const bName = b!.fullName;

    if (!aName && bName) {
      return 1;
    }
    if (aName && !bName) {
      return -1;
    }

    const aStr = (aName || a!.email || 'zzzzzz').toLowerCase();
    const bStr = (bName || b!.email || 'zzzzzz').toLowerCase();

    if (aStr > bStr) {
      return desc ? -1 : 1;
    }
    if (aStr < bStr) {
      return desc ? 1 : -1;
    }
    return 0;
  };
};

export default sortUsers;
