import { FormattedMessage } from 'react-intl';

import CallToAction from 'components/CallToAction';
import PageLayout from 'components/Layout/PageLayout';
import MainNav from 'components/MainNav';
import MainNavItem from 'components/MainNavItem';
import SharedContentContainer from 'containers/SharedContentContainer';
import { ROUTE_HOME } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import SharedContentRouter from './SharedContentRouter';

// tslint:disable: jsx-wrap-multiline

export const SharedContentScreen = ({ match }: HistoryProps) => {
  return (
    <SharedContentContainer token={match?.params?.token}>
      <PageLayout
        reduced={true}
        mainNav={
          <MainNav>
            <MainNavItem
              label={<FormattedMessage id="Login" />}
              iconName="logout"
              active={false}
              to={ROUTE_HOME}
            />

            <span />
          </MainNav>
        }
      >
        <SharedContentRouter />

        <CallToAction showAdd={false} hideSearch={true} />
      </PageLayout>
    </SharedContentContainer>
  );
};

export default SharedContentScreen;
