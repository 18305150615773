import { useState } from 'react';

export interface FormType {
  reset: () => void;
  setField: (name: string, newValue: any) => void;
  set: (value: any) => void;
  values: any;
  bindInput: (name: string, onChange?: (event: any) => void) => any;
  bindCheckbox: (name: string, onChange?: (event: any) => void) => any;
  bindRadioButton: (
    name: string,
    radioValue: string,
    onChange?: (event: any) => void
  ) => any;
  errors: any;
  setError: (name: string, error?: any) => void;
  resetErrors: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  confirm: boolean | string | number;
  setConfirm: (confirm: boolean | string | number) => void;
  editing: string | undefined;
  setEditing: (name?: string | undefined) => void;
}

export const useForm = (
  initialValues: { [key: string]: any } = {},
  initialErrors: { [key: string]: any } = {},
  initialLoading: boolean = false,
  initialConfirm: boolean | string | number = false,
  initialEditing: string | undefined = undefined
): FormType => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(initialLoading);
  const [confirm, setConfirm] = useState(initialConfirm);
  const [editing, setEditing] = useState(initialEditing);

  return {
    reset: () => {
      setValues(initialValues);
      setErrors(initialErrors);
      setLoading(initialLoading);
      setConfirm(initialConfirm);
      setEditing(initialEditing);
    },
    setField: (name: string, newValue: any) => {
      setValues(oldValues => {
        return {
          ...oldValues,
          [name]: newValue
        };
      });
    },
    set: (newValue: any) => setValues(newValue),
    values,
    bindInput: (name: string, onChange?: (event: any) => void) => {
      return {
        value: values[name] || '',
        error: errors[name],
        onChange: (event: any) => {
          setValues({
            ...values,
            [name]: event.target.value
          });
          if (onChange) {
            onChange(event);
          }
        }
      };
    },
    bindCheckbox: (name: string, onChange?: (event: any) => void) => {
      return {
        checked: values[name] || false,
        error: errors[name],
        onChange: (event: any) => {
          setValues({
            ...values,
            [name]: event.target.checked
          });
          if (onChange) {
            onChange(event);
          }
        }
      };
    },
    bindRadioButton: (
      name: string,
      radioValue: string,
      onChange?: (event: any) => void
    ) => {
      return {
        checked: values[name] === radioValue,
        error: errors[name],
        onChange: (event: any) => {
          setValues({
            ...values,
            [name]: event.target.value
          });
          if (onChange) {
            onChange(event);
          }
        }
      };
    },
    errors,
    setError: (name: string, error?: any) => {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          [name]: error
        };
      });
    },
    resetErrors: () => {
      setErrors(initialErrors);
    },
    loading,
    setLoading,
    confirm,
    setConfirm,
    editing,
    setEditing
  };
};

export const handleFormError = (form: FormType, error?: any): boolean => {
  if (!error || !error.response || error.response.status !== 422) {
    // this is not a form error
    return false;
  }

  if (!error.body || !error.body.errors) {
    // this is not a valid error response
    return false;
  }

  const errors: { [key: string]: any } = error.body.errors;

  let hasFormError = false;
  for (const fieldName in errors) {
    if (errors.hasOwnProperty(fieldName) && errors[fieldName].length) {
      hasFormError = true;
      form.setError(
        fieldName,
        Array.isArray(errors[fieldName])
          ? errors[fieldName][0]
          : errors[fieldName]
      );
    }
  }

  return hasFormError;
};

export default useForm;
