import React from 'react';
import { FormattedMessage } from 'react-intl';

import UserAvatar from 'components/Avatar/UserAvatar';
import Headline from 'components/headline';
import Cluster from 'components/Layout/Cluster';
import Flow from 'components/Layout/Flow';
import SessionIcon from 'components/SessionIcon';
import Typography from 'components/Typography';
import { AnyUserModelType } from 'models/UserModel';

const STEP_REGEX = /^([0-9]+)\/([0-9]+)$/;

export const SessionTop = ({
  children,
  user
}: {
  children?: any;
  user?: AnyUserModelType;
}) => (
  <div className="session__top">
    <Flow flowSpace="0.125rem">
      {user ? <UserAvatar user={user} noLink={true} /> : <SessionIcon />}

      {children}
    </Flow>
  </div>
);

export const SessionBottom = ({ children }: { children?: any }) => (
  <div className="session__bottom">
    <Flow flowSpace="0.125rem">{children}</Flow>
  </div>
);

export const SessionActions = ({ children }: { children?: any }) => (
  <div className="session__actions">
    <Cluster justification="flex-end">{children}</Cluster>
  </div>
);

export const SessionFixed = ({ children }: { children?: any }) => (
  <div className="session__fixed-bottom">
    <Cluster justification="flex-end">{children}</Cluster>
  </div>
);

const pad = (num: any) => {
  if (!num) {
    return '00';
  }

  const str = num.toString();
  if (str.length < 2) {
    return '0' + str;
  }

  return str;
};

export const SessionHeadline = React.memo(
  ({ children, step }: { children?: any; step?: string | React.ReactNode }) => {
    let stepComp;

    if (step) {
      const stepMatch = typeof step === 'string' && step.match(STEP_REGEX);
      if (stepMatch) {
        stepComp = (
          <FormattedMessage
            id="assistant headline step"
            values={{
              step: pad(stepMatch[1]),
              of: pad(stepMatch[2])
            }}
          />
        );
      } else {
        stepComp = (
          <FormattedMessage
            id="assistant headline prefix"
            values={{
              prefix: step
            }}
          />
        );
      }
    }

    return (
      <Headline size="L">
        {stepComp}
        {children}
      </Headline>
    );
  }
);

export const SessionMainText = ({
  noMargin,
  breaks,
  children
}: {
  noMargin?: boolean;
  breaks?: boolean;
  children?: any;
}) => (
  <Typography size="medium" noMargin={noMargin} breaks={breaks}>
    {children}
  </Typography>
);

export const SessionSidebarStep = React.memo(
  ({ step }: { step?: string | React.ReactNode }) => {
    if (step) {
      const stepMatch = typeof step === 'string' && step.match(STEP_REGEX);
      if (stepMatch) {
        return (
          <>
            <FormattedMessage id="assistant sidebar title" />
            <br />
            <FormattedMessage
              id="assistant sidebar step"
              values={{
                step: pad(stepMatch[1]),
                of: pad(stepMatch[2])
              }}
            />
          </>
        );
      }

      // custom headline
      return <>{step}</>;
    }

    return (
      <>
        <FormattedMessage id="assistant sidebar title" />
        <br />
        &nsbp;
      </>
    );
  }
);
