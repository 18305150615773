export default (funcs: any[]) => {
  return (a: any, b: any) => {
    for (const f of funcs) {
      const result = f(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
};
