import { nullToUndefined } from './createMap';

export const createArrayWithTransform = (
  arr?: any,
  transform?: (item: any) => any
) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr.map((elem: any) => (!transform ? elem : transform(elem)));
};

export const createArray = (arr?: any, transform?: (item: any) => any) =>
  createArrayWithTransform(arr, !transform ? nullToUndefined : transform);
