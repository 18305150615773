import { inject, observer } from 'mobx-react';
import React from 'react';

import Flow from 'components/Layout/Flow';
import { AssistantSessionModelType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import SidebarBenchmarks from './items/SidebarBenchmarks';
import SidebarBriefing from './items/SidebarBriefing';
import SidebarHypothesis from './items/SidebarHypothesis';
import SidebarMaterial from './items/SidebarMaterial';
import SidebarPainpointDescription from './items/SidebarPainpointDescription';
import SidebarPainpointQuestion from './items/SidebarPainpointQuestion';
import SidebarSelectedPainpoint from './items/SidebarSelectedPainpoint';
import SidebarSessionBenchmarks from './items/SidebarSessionBenchmarks';
import SidebarSolutions from './items/SidebarSolutions';
import SidebarMarker from './SidebarMarker';
import SidebarHypothesisLinks from './items/SidebarHypothesisLinks';
import { DataStoreType } from 'models/DataStore';
import SidebarSelectedHypothesis from './items/SidebarSelectedHypothesis';
import SidebarUserflowScreens from './items/SidebarUserflowScreens';
import SidebarPrototypeDescription from './items/SidebarPrototypeDescription';
import { ASSISTANTS } from 'utils/constants/assistant-steps';

export interface AssistantSidebarProps {
  showHeader?:
    | 'painpoint_description'
    | 'painpoint_question'
    | 'benchmarks'
    | 'solutions'
    | 'hypothesis';
  currentPath?: string;
  currentPathWithSearch?: string;
  assistantStore?: AssistantStoreType;
  dataStore?: DataStoreType;
}

@inject('assistantStore', 'dataStore')
@observer
class AssistantSidebar extends React.Component<AssistantSidebarProps> {
  private renderCreativeSession(session?: AssistantSessionModelType) {
    const { showHeader, currentPathWithSearch } = this.props;

    return (
      <Flow flowSpace="2.5rem">
        <SidebarMaterial session={session} />
        <SidebarBriefing session={session} />
        <SidebarPainpointDescription
          session={session}
          showHeader={showHeader === 'painpoint_description'}
        />
        {session?.assistant_type === 'hypothesis_sprint' ? (
          <SidebarSelectedPainpoint
            session={session}
            showHeader={showHeader === 'painpoint_question'}
          />
        ) : (
          <SidebarPainpointQuestion
            session={session}
            showHeader={showHeader === 'painpoint_question'}
          />
        )}
        <SidebarBenchmarks
          session={session}
          showHeader={showHeader === 'benchmarks'}
          currentPathWithSearch={currentPathWithSearch}
        />
        <SidebarSolutions
          session={session}
          showHeader={showHeader === 'solutions'}
        />
        <SidebarHypothesis
          session={session}
          showHeader={showHeader === 'hypothesis'}
        />
        <SidebarMarker type="end" />
      </Flow>
    );
  }

  private renderBenchmarksSession(session?: AssistantSessionModelType) {
    const { showHeader, currentPathWithSearch } = this.props;

    return (
      <Flow flowSpace="2.5rem">
        <SidebarMaterial session={session} />

        <SidebarBriefing session={session} />

        <SidebarSessionBenchmarks
          session={session}
          showHeader={showHeader === 'benchmarks'}
          currentPathWithSearch={currentPathWithSearch}
        />

        <SidebarMarker type="end" />
      </Flow>
    );
  }

  private renderPrototypeSession(session?: AssistantSessionModelType) {
    const { currentPathWithSearch, currentPath } = this.props;

    if (!session) {
      return null;
    }
    const progress = session.progress;

    return (
      <Flow flowSpace="2.5rem">
        <SidebarMaterial session={session} />

        {progress >= ASSISTANTS.prototype_sprint.invitation.progress && (
          <SidebarBriefing session={session} />
        )}

        {progress >= ASSISTANTS.prototype_sprint.step1.progress && (
          <SidebarHypothesisLinks
            session={session}
            currentPath={currentPath}
            currentPathWithSearch={currentPathWithSearch}
          />
        )}

        {progress >= ASSISTANTS.prototype_sprint.step2.progress && (
          <SidebarSelectedHypothesis session={session} />
        )}

        {progress >= ASSISTANTS.prototype_sprint.step4.progress && (
          <SidebarUserflowScreens session={session} />
        )}

        {progress >= ASSISTANTS.prototype_sprint.step5.progress && (
          <SidebarPrototypeDescription session={session} />
        )}

        <SidebarMarker type="end" />
      </Flow>
    );
  }

  render() {
    const session = this.props.assistantStore!.currentItem;

    switch (session?.assistant_type) {
      // case 'creative_session':
      //   return this.renderCreativeSession(session);

      case 'prototype_sprint':
        return this.renderPrototypeSession(session);

      case 'benchmarks_sprint':
        return this.renderBenchmarksSession(session);

      default:
        return this.renderCreativeSession(session);
    }
  }
}

export default AssistantSidebar;
