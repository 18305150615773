import { injectIntl, WrappedComponentProps } from 'react-intl';

import Bookmark from 'components/Bookmark';
import { BookmarkRemovedEvent } from 'components/Bookmark/Bookmark';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import EditMenu from 'components/EditMenu';
import HashtagsList from 'components/HashtagsList';
import IconCounter from 'components/IconCounter';
import MenuButton from 'components/MenuButton';
import RainbowCard from 'components/RainbowCard';
import Typography from 'components/Typography';
import { PrototypeModelType } from 'models/PrototypeModel';
import { Link, LinkProps } from 'react-router-dom';
import { LIST_ITEM_TEXT_LENGTH } from 'utils/constants/misc';
import { isDefined } from 'utils/misc/is-defined';
import shortenString from 'utils/misc/shorten-string';
import UserAvatar from 'components/Avatar/UserAvatar';
import { ROUTE_PROFILE } from 'utils/constants/routes';

export default injectIntl(
  ({
    prototype,
    checked,
    onChange,
    mayEdit,
    onEditClick,
    onRemoveClick,
    intl,
    contextUri,
    readOnly,
    fromList,
    onBookmarkRemoved,
    top,
    noMenu
  }: {
    prototype: PrototypeModelType;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    mayEdit?: boolean;
    onEditClick?: () => void;
    onRemoveClick?: () => void;
    contextUri?: string;
    readOnly?: boolean;
    fromList?: boolean;
    onBookmarkRemoved?: BookmarkRemovedEvent;
    top?: React.ReactNode;
    noMenu?: boolean;
  } & WrappedComponentProps) => {
    const isDraft = prototype.publish_state === 'draft';

    let menu;
    if (mayEdit && !noMenu && (onEditClick || onRemoveClick)) {
      menu = (
        <EditMenu bottomLeft={true} label={intl.formatMessage({ id: 'Edit' })}>
          {onEditClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Edit' })}
              description={intl.formatMessage({
                id: 'prototype edit desc'
              })}
              iconName="pen"
              onClick={onEditClick}
            />
          ) : null}
          {onRemoveClick ? (
            <MenuButton
              label={intl.formatMessage({ id: 'Remove' })}
              description={intl.formatMessage({
                id: 'prototype remove desc'
              })}
              iconName="bin"
              onClick={onRemoveClick}
            />
          ) : null}
        </EditMenu>
      );
    }

    const src: string[] = [];
    for (const screen of prototype.sortedScreens) {
      const attachment = screen.attachments?.firstOfType('screen');
      if (attachment?.resource_urls?.small) {
        src.push(attachment.resource_urls.small);
      }
    }

    const to: LinkProps['to'] = {
      pathname: `${
        isDefined(prototype.project)
          ? `/app/lab/${prototype.project.organization_id}/${prototype.project.id}/`
          : contextUri
          ? contextUri + '/'
          : ''
      }prototypes/${prototype.id}${isDraft ? '/edit' : ''}`
    };
    if (fromList) {
      to.state = {
        switch: true
      };
    }

    return (
      <RainbowCard
        to={to}
        checked={checked}
        onCheckboxChange={onChange}
        sideLabel={isDraft ? intl.formatMessage({ id: 'Draft' }) : undefined}
        src={src}
        top={top}
      >
        <CardHeader
          title={
            prototype.headline || intl.formatMessage({ id: 'no headline' })
          }
        />

        <Typography size="small">
          {shortenString(prototype.flow_description, LIST_ITEM_TEXT_LENGTH)}
        </Typography>

        <HashtagsList tags={prototype.tags} />

        {!noMenu && (
          <CardMenu
            avatar={
              <UserAvatar
                user={prototype.author}
                isAiRobot={prototype.created_by_ai}
              />
            }
          >
            <Bookmark
              elementType="Prototype"
              elementId={prototype.id}
              count={prototype.bookmarks_count}
              id={prototype.bookmark_id}
              disabled={readOnly}
              onRemoved={onBookmarkRemoved}
            />
            <IconCounter
              value={prototype.comments_count || 0}
              label={intl.formatMessage({ id: 'Comments' })}
              iconName="comment"
            />

            {menu}
            {prototype.created_by_ai && prototype.author &&
              <span className='ai-author'>
                Generated by <Link to={ROUTE_PROFILE.replace(':id', prototype.author.id!.toString())}>
                  <span className='underline'>{prototype.author.fullName}</span>
                </Link>
              </span>
            }
          </CardMenu>
        )}
      </RainbowCard>
    );
  }
);
