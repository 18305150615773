import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import CardWrapper from 'components/CardWrapper';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import { HypothesisModelType } from 'models/HypothesisModel';
import { LearningModelType } from 'models/LearningModel';
import { PrototypeModelType } from 'models/PrototypeModel';
import { SharedContentStoreType } from 'models/SharedContentStore';
import BenchmarksListItem from 'screens/benchmarks/BenchmarksListScreen/BenchmarksListItem';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import LearningsList from 'screens/learnings/LearningsListScreen/LearningsList';
import LearningsListItem from 'screens/learnings/LearningsListScreen/LearningsListItem';
import PrototypesListItem from 'screens/prototypes/PrototypesListScreen/PrototypesListItem';
import SharedContentNavigation from './SharedContentNavigation';
import { Component } from 'react';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import { PainpointModelType } from 'models/PainpointModel';
import TrelloWrapper from 'components/Layout/TrelloWrapper';
import CardColumn from 'components/CardColumn';
import PainpointsStore, { ClusterPainpoints } from 'models/PainpointsStore';
import { ClusterModelType } from 'models/ClusterModel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import uniqBy from 'utils/misc/uniq-by';

type ListType =
  | 'benchmarks'
  | 'hypotheses'
  | 'prototypes'
  | 'learnings'
  | 'painpoints';

interface PublicSharedContentListContainerProps {
  listType?: ListType;
}

interface SharedContentListContainerProps
  extends PublicSharedContentListContainerProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedContentListContainer extends Component<SharedContentListContainerProps> {
  renderBenchmarks() {
    const { sharedContentStore } = this.props;
    const contextUri = sharedContentStore.contextUri;

    return this.renderPage(
      'benchmarks',
      <>
        <PageHeader
          titleId="Benchmarks"
          logoHeader={
            <PageLogoHeader
              title={<FormattedMessage id="benchmark list header" />}
              subtitle={<FormattedMessage id="benchmark list subheader" />}
              list={true}
            />
          }
        />

        <ContentWrapper>
          <ColumnWrapper gap="3em">
            {sharedContentStore.sortedBenchmarks.map(
              (elem: BenchmarkModelType) => (
                <BenchmarksListItem
                  benchmark={elem}
                  key={elem.id}
                  fromList={false}
                  noMenu={true}
                  contextUri={contextUri}
                  // top={<OrgCardTop org={elem.project?.organization} />}
                />
              )
            )}
          </ColumnWrapper>
        </ContentWrapper>
      </>
    );
  }

  renderHypotheses() {
    const { sharedContentStore } = this.props;
    const contextUri = sharedContentStore.contextUri;

    return this.renderPage(
      'hypotheses',
      <>
        <PageHeader
          titleId="Hypotheses"
          logoHeader={
            <PageLogoHeader
              title={<FormattedMessage id="hypothesis list header" />}
              subtitle={<FormattedMessage id="hypothesis list caption" />}
              list={true}
            />
          }
        />

        <ContentWrapper>
          <ColumnWrapper gap="3em">
            {sharedContentStore.sortedHypotheses.map(
              (elem: HypothesisModelType) => (
                <HypothesesListItem
                  hypothesis={elem}
                  key={elem.id}
                  fromList={false}
                  noMenu={true}
                  contextUri={contextUri}
                  // top={<OrgCardTop org={elem.project?.organization} />}
                />
              )
            )}
          </ColumnWrapper>
        </ContentWrapper>
      </>
    );
  }

  renderPrototypes() {
    const { sharedContentStore } = this.props;
    const contextUri = sharedContentStore.contextUri;

    return this.renderPage(
      'prototypes',
      <>
        <PageHeader
          titleId="Prototypes"
          logoHeader={
            <PageLogoHeader
              title={<FormattedMessage id="prototype list header" />}
              subtitle={<FormattedMessage id="prototype list caption" />}
              list={true}
            />
          }
        />

        <ContentWrapper>
          <ColumnWrapper gap="3em">
            {sharedContentStore.sortedPrototypes.map(
              (elem: PrototypeModelType) => (
                <PrototypesListItem
                  prototype={elem}
                  key={elem.id}
                  fromList={false}
                  noMenu={true}
                  contextUri={contextUri}
                  top={true}
                />
              )
            )}
          </ColumnWrapper>
        </ContentWrapper>
      </>
    );
  }

  renderLearnings() {
    const { sharedContentStore } = this.props;
    const contextUri = sharedContentStore.contextUri;

    return this.renderPage(
      'learnings',
      <>
        <PageHeader
          titleId="Learnings"
          logoHeader={
            <PageLogoHeader
              title={<FormattedMessage id="learning list header" />}
              subtitle={<FormattedMessage id="learning list caption" />}
              list={true}
            />
          }
        />

        <ContentWrapper>
          <ColumnWrapper gap="3em">
            <LearningsList>
              {sharedContentStore.sortedLearnings.map(
                (elem: LearningModelType) => (
                  <LearningsListItem
                    learning={elem}
                    key={elem.id}
                    fromList={false}
                    noMenu={true}
                    contextUri={contextUri}
                    // top={<OrgCardTop org={elem.project?.organization} />}
                  />
                )
              )}
            </LearningsList>
          </ColumnWrapper>
        </ContentWrapper>
      </>
    );
  }

  renderPainpoints() {
    const { sharedContentStore } = this.props;
    const contextUri = sharedContentStore.contextUri;

    const clusters: ClusterModelType[] = uniqBy(
      sharedContentStore.sortedPainpoints?.map(
        (painpoint) => painpoint.cluster!
      ),
      'name'
    );
    const painpointsStore = PainpointsStore.create();
    const grid = painpointsStore.grid(
      undefined,
      clusters,
      sharedContentStore.sortedPainpoints
    );

    return this.renderPage(
      'painpoints',
      <>
        <PageHeader
          titleId="Painpoints"
          logoHeader={
            <PageLogoHeader
              title={<FormattedMessage id="painpoint list header" />}
              subtitle={<FormattedMessage id="painpoint list caption" />}
              list={true}
            />
          }
        />

        <DndProvider backend={HTML5Backend}>
          <TrelloWrapper>
            {grid.map((column: ClusterPainpoints) => {
              const clusterId = column.cluster?.id;
              return (
                <CardColumn
                  key={clusterId || '_null'}
                  cluster={column.cluster}
                  count={column.painpoints.length}
                  nameChangeOnBlur={true}
                >
                  <ColumnWrapper gap="1em">
                    {column.painpoints.map((elem: PainpointModelType) => (
                      <PainpointsListItem
                        painpoint={elem}
                        key={elem.id}
                        contextUri={contextUri}
                      />
                    ))}
                  </ColumnWrapper>
                </CardColumn>
              );
            })}
          </TrelloWrapper>
        </DndProvider>
      </>
    );
  }

  renderPage(active: ListType, content?: any) {
    const { sharedContentStore } = this.props;

    return (
      <SharedContentNavigation
        token={sharedContentStore.currentToken || '-'}
        active={active}
        benchmarksCount={sharedContentStore.benchmarksCount}
        hypothesesCount={sharedContentStore.hypothesesCount}
        prototypesCount={sharedContentStore.prototypesCount}
        learningsCount={sharedContentStore.learningsCount}
        painpointsCount={sharedContentStore.painpointsCount}
      >
        {content}
      </SharedContentNavigation>
    );
  }

  renderDefault() {
    const { sharedContentStore } = this.props;

    if (sharedContentStore.benchmarksCount) {
      return this.renderBenchmarks();
    }

    if (sharedContentStore.hypothesesCount) {
      return this.renderHypotheses();
    }

    if (sharedContentStore.prototypesCount) {
      return this.renderPrototypes();
    }

    if (sharedContentStore.learningsCount) {
      return this.renderLearnings();
    }

    if (sharedContentStore.painpointsCount) {
      return this.renderPainpoints();
    }

    return this.renderNone();
  }

  renderNone() {
    // TODO use other message here?
    return (
      <ContentWrapper>
        <CardWrapper>
          <FormattedMessage id="not_found" />
        </CardWrapper>
      </ContentWrapper>
    );
  }

  render() {
    const { sharedContentStore, listType } = this.props;

    if (!sharedContentStore.hasAny) {
      return this.renderNone();
    }

    switch (listType) {
      case 'benchmarks':
        return this.renderBenchmarks();

      case 'hypotheses':
        return this.renderHypotheses();

      case 'prototypes':
        return this.renderPrototypes();

      case 'learnings':
        return this.renderLearnings();

      case 'painpoints':
        return this.renderPainpoints();

      default:
        return this.renderDefault();
    }
  }
}

export default (props: PublicSharedContentListContainerProps) => (
  // @ts-ignore
  <SharedContentListContainer {...props} />
);
