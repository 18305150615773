import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type ButtonType = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
interface DisconnectedButtonProps extends ButtonType {
  onClick?: ButtonType['onClick'];
  secondary?: boolean | number;
  large?: boolean | number;
}

export const DisconnectedButton = (props: DisconnectedButtonProps) => {
  const disconnectedButtonClasses = classNames(props.className, 'main-button', {
    'main-button--secondary': props.secondary,
    'main-button--large': props.large
  });

  return (
    <button
      className={disconnectedButtonClasses}
      {...props}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (props.onClick) {
          props.onClick(event);
        }
      }}
    />
  );
};

export default DisconnectedButton;
