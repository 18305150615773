import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import sortByField from 'utils/sort/field';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import createMap, { createMapWithTransform } from 'utils/store/createMap';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import AssistantSessionBenchmarkModel, {
  AssistantSessionBenchmarkModelType,
  createAssistantSessionBenchmarkModel
} from './AssistantSessionBenchmarkModel';
import {
  AssistantSessionSolutionModel,
  AssistantSessionSolutionModelType,
  createAssistantSessionSolutionModel
} from './AssistantSessionSolutionModel';
import { AttachmentsMapModel, createAttachmentsMap } from './AttachmentModel';
import BenchmarkModel, { BenchmarkModelType } from './BenchmarkModel';
import PainpointModel from './PainpointModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import PrototypeModel, { PrototypeModelType } from './PrototypeModel';
import PrototypeScreenModel, {
  createPrototypeScreenModel,
  PrototypeScreenModelType
} from './PrototypeScreenModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const AssistantTypeEnum = types.enumeration([
  'creative_session',
  'benchmarks_sprint',
  'painpoint_session',
  'hypothesis_sprint',
  'prototype_sprint'
]);
export type AssistantType = Instance<typeof AssistantTypeEnum>;

export const AssistantSessionModel = types
  .model('AssistantSessionModel', {
    id: types.identifierNumber,
    assistant_type: AssistantTypeEnum,
    author: types.maybe(FluidUserModel),
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),

    inviter: types.maybe(FluidUserModel),
    invitation_text: types.maybe(types.string),

    painpoint_description: types.maybe(types.string),
    painpoint_question: types.maybe(types.string),
    painpoint_id: types.maybe(types.number),
    painpoint: types.maybe(
      types.safeReference(types.late((): IAnyModelType => PainpointModel))
    ),

    benchmarks: types.maybe(
      types.array(
        types.safeReference(types.late((): IAnyModelType => BenchmarkModel))
      )
    ),

    hypothesis_headline: types.maybe(types.string),
    hypothesis_description: types.maybe(types.string),
    hypothesis_added_value: types.maybe(types.string),
    hypothesis_id: types.maybe(types.number),

    attachments: AttachmentsMapModel,

    prototype: types.maybe(PrototypeModel),
    prototype_screens: types.maybe(types.map(PrototypeScreenModel)),
    prototype_headline: types.maybe(types.string),
    prototype_description: types.maybe(types.string),

    solutions: types.maybe(types.map(AssistantSessionSolutionModel)),
    assistant_session_benchmarks: types.maybe(
      types.map(AssistantSessionBenchmarkModel)
    ),

    progress: types.number,
    invited: types.maybe(types.boolean),
    finished: types.maybe(types.boolean),
    published: types.maybe(types.boolean),

    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string),
    painpoint_description_date: types.maybe(types.string),
    painpoint_question_date: types.maybe(types.string),
    benchmarks_date: types.maybe(types.string),
    hypothesis_date: types.maybe(types.string),

    synced_with_api: types.optional(types.boolean, false)
  })
  .actions((self) => {
    const setBenchmarks = (benchmarks?: any) => {
      self.benchmarks = benchmarks;
    };

    const setPainpoint = (painpoint?: any) => {
      self.painpoint = painpoint;
    };

    const deleteSolution = (solutionId: number) => {
      self.solutions?.delete(solutionId.toString());
    };

    const setPrototype = (prototype?: PrototypeModelType) => {
      self.prototype = prototype;
    };

    const deleteSessionBenchmark = (benchmarkId: number) => {
      self.assistant_session_benchmarks?.delete(benchmarkId.toString());
    };

    const setPrototypeScreens = (prototypeScreens?: any) => {
      self.prototype_screens = prototypeScreens;
    };

    const putScreen = (screen: PrototypeScreenModelType) => {
      if (self.prototype_screens) {
        self.prototype_screens.put(screen);
      } else {
        delete screen.attachments;
        self.prototype_screens = createMap([screen]);
      }
    };

    const deleteScreen = (screenId: number) => {
      if (self.prototype_screens) {
        self.prototype_screens.delete(screenId.toString());
      }
    };

    const setSynced = (synced: boolean) => {
      self.synced_with_api = synced;
    };

    return {
      setBenchmarks,
      setPainpoint,
      deleteSolution,
      deleteSessionBenchmark,
      setPrototype,
      setPrototypeScreens,
      putScreen,
      deleteScreen,
      setSynced
    };
  })
  .views((self) => {
    return {
      get sortedBenchmarks(): BenchmarkModelType[] {
        if (!self.benchmarks?.length) {
          return [];
        }

        const list: BenchmarkModelType[] = [];

        for (const benchmark of self.benchmarks) {
          list.push(benchmark);
        }

        list.sort(sortByField('company_name'));
        return list;
      },
      benchmarkById(id: number): BenchmarkModelType | undefined {
        if (!self.benchmarks?.length) {
          return undefined;
        }

        for (const benchmark of self.benchmarks) {
          if (benchmark.id === id) {
            return benchmark;
          }
        }

        return undefined;
      },

      get solutionsCount(): number {
        return self.solutions?.size || 0;
      },
      get activeSolutionsCount(): number {
        if (!self.solutions?.size) {
          return 0;
        }

        let count = 0;
        for (const solution of self.solutions.values()) {
          if (solution.editing_state === 'active') {
            count++;
          }
        }

        return count;
      },
      filteredSolutions(
        onlyActive?: boolean
      ): AssistantSessionSolutionModelType[] {
        if (!self.solutions?.size) {
          return [];
        }

        const list: AssistantSessionSolutionModelType[] = [];

        for (const solution of self.solutions.values()) {
          if (onlyActive && solution.editing_state !== 'active') {
            continue;
          }

          list.push(solution);
        }

        list.sort(sortByField('id'));
        return list;
      },
      get sortedSolutions(): AssistantSessionSolutionModelType[] {
        return this.filteredSolutions();
      },
      get sortedSolutionsWithBenchmarks(): Array<{
        solution: AssistantSessionSolutionModelType;
        benchmark?: BenchmarkModelType;
      }> {
        if (!self.solutions?.size) {
          return [];
        }

        const list: Array<{
          solution: AssistantSessionSolutionModelType;
          benchmark?: BenchmarkModelType;
        }> = [];

        for (const solution of self.solutions.values()) {
          if (solution.editing_state !== 'active') {
            continue;
          }

          const benchmark = self.benchmarks?.filter(
            (item) => item.id === solution.benchmark_id
          );

          list.push({
            solution,
            benchmark: benchmark?.[0]
          });
        }

        list.sort((a, b) => {
          if (a.solution.id > b.solution.id) {
            return 1;
          }
          if (a.solution.id < b.solution.id) {
            return -1;
          }
          return 0;
        });
        return list;
      },

      get solutionsDate(): string | undefined {
        if (!self.solutions?.size) {
          return undefined;
        }

        let date = '';
        for (const solution of self.solutions.values()) {
          if (solution.updated_at && solution.updated_at > date) {
            date = solution.updated_at;
          }
        }

        return date !== '' ? date : undefined;
      },

      get sessionBenchmarksCount(): number {
        return self.assistant_session_benchmarks?.size || 0;
      },
      get activeSessionBenchmarksCount(): number {
        if (!self.assistant_session_benchmarks?.size) {
          return 0;
        }

        let count = 0;
        for (const benchmark of self.assistant_session_benchmarks.values()) {
          if (benchmark.editing_state === 'active') {
            count++;
          }
        }

        return count;
      },
      get sortedSessionBenchmarks(): AssistantSessionBenchmarkModelType[] {
        if (!self.assistant_session_benchmarks?.size) {
          return [];
        }

        const list: AssistantSessionBenchmarkModelType[] = [];

        for (const benchmark of self.assistant_session_benchmarks.values()) {
          list.push(benchmark);
        }

        list.sort(sortByField('id'));
        return list;
      },
      get sessionBenchmarksDate(): string | undefined {
        if (!self.assistant_session_benchmarks?.size) {
          return undefined;
        }

        let date = '';
        for (const benchmark of self.assistant_session_benchmarks.values()) {
          if (benchmark.updated_at && benchmark.updated_at > date) {
            date = benchmark.updated_at;
          }
        }

        return date !== '' ? date : undefined;
      },

      get lastUpdate(): string | undefined {
        let updated: string | undefined = self.updated_at || '';

        const solutionsUpdated = this.solutionsDate;
        if (solutionsUpdated && solutionsUpdated > updated) {
          updated = solutionsUpdated;
        }

        const sessionBenchmarksUpdated = this.sessionBenchmarksDate;
        if (sessionBenchmarksUpdated && sessionBenchmarksUpdated > updated) {
          updated = sessionBenchmarksUpdated;
        }

        if (!updated) {
          updated = self.created_at;
        }

        return updated || undefined;
      },

      get sortedScreens(): PrototypeScreenModelType[] {
        const screens: PrototypeScreenModelType[] = [];

        if (self.prototype_screens && self.prototype_screens.size > 0) {
          for (const screen of self.prototype_screens.values()) {
            screens.push(screen);
          }

          screens.sort(
            (a: PrototypeScreenModelType, b: PrototypeScreenModelType) => {
              const sa = a.order === undefined ? a.id : a.order;
              const sb = b.order === undefined ? b.id : b.order;

              if (sa > sb) {
                return 1;
              }
              if (sa < sb) {
                return -1;
              }
              return 0;
            }
          );
        }

        return screens;
      }
    };
  });

export const createAssistantSessionModel = (
  data: any
): AssistantSessionModelType =>
  AssistantSessionModel.create({
    id: mandatoryId(data?.id),
    assistant_type: AssistantTypeEnum.is(data?.assistant_type)
      ? data.assistant_type
      : 'creative_session',
    author: createFluidUserModel(data?.author),
    project: createFluidProjectModel(data?.project),

    inviter: createFluidUserModel(data?.inviter),
    invitation_text: data?.invitation_text || undefined,

    painpoint_description: data?.painpoint_description || undefined,
    painpoint_question: data?.painpoint_question || undefined,
    painpoint_id: numberOrUndefined(data?.painpoint_id),

    hypothesis_headline: data?.hypothesis_headline || undefined,
    hypothesis_description: data?.hypothesis_description || undefined,
    hypothesis_added_value: data?.hypothesis_added_value || undefined,
    hypothesis_id: numberOrUndefined(data?.hypothesis_id),

    attachments: createAttachmentsMap(data?.attachments),

    prototype_screens: data?.prototype_screens
      ? createMap(data.prototype_screens, createPrototypeScreenModel)
      : undefined,
    prototype_headline: data?.prototype_headline || undefined,
    prototype_description: data?.prototype_description || undefined,

    solutions: data?.assistant_session_solutions
      ? createMapWithTransform(
          data.assistant_session_solutions,
          createAssistantSessionSolutionModel
        )
      : undefined,
    assistant_session_benchmarks: data?.assistant_session_benchmarks
      ? createMapWithTransform(
          data.assistant_session_benchmarks,
          createAssistantSessionBenchmarkModel
        )
      : undefined,

    progress: data?.progress || 0,
    invited: booleanOrUndefined(data?.invited),
    finished: booleanOrUndefined(data?.finished),
    published: booleanOrUndefined(data?.published),

    created_at: data?.created_at || undefined,
    updated_at: data?.updated_at || undefined,
    painpoint_description_date: data?.painpoint_description_date || undefined,
    painpoint_question_date: data?.painpoint_question_date || undefined,
    benchmarks_date: data?.benchmarks_date || undefined,
    hypothesis_date: data?.hypothesis_date || undefined
  });

export type AssistantSessionModelType = typeof AssistantSessionModel.Type;
export default AssistantSessionModel;
