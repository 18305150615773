import { CSSProperties } from 'react';

interface SessionIconProps {
  width?: string;
}

export default function SessionIcon({ width = '4.375em' }: SessionIconProps) {
  const style = {
    '--session-icon-width': width
  } as CSSProperties;

  return (
    <svg
      className="session-icon"
      viewBox="0 0 170 170"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="85" cy="85" r="85" fill="url(#paint0_linear)" />
      <rect
        x="97"
        y="76"
        width="40"
        height="18"
        rx="9"
        fill="url(#paint1_radial)"
      />
      <rect x="119" y="76" width="18" height="18" rx="9" fill="#fff" />
      <g opacity=".3" fill="#fff">
        <path d="M50 35c0-5 4-9 9-9h22a9 9 0 110 18H59c-5 0-9-4-9-9zM50 85c0-5 4-9 9-9h22a9 9 0 110 18H59c-5 0-9-4-9-9zM59 126a9 9 0 100 18h22a9 9 0 100-18H59zM74 60c0-5 4-9 9-9h22a9 9 0 110 18H83c-5 0-9-4-9-9zM83 101a9 9 0 100 18h22a9 9 0 100-18H83z" />
      </g>
      <defs>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 33.5 -32.2361 0 128 85)"
        >
          <stop stopColor="#8761BD" />
          <stop offset="1" stopColor="#fff" />
        </radialGradient>
        <linearGradient
          id="paint0_linear"
          x1="0"
          y1="112"
          x2="170"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE206E" />
          <stop offset="1" stopColor="#5F18C4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
