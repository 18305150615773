import { observer } from 'mobx-react';
import React from 'react';

const DEFAULT_TIMEOUT = 1500;

export interface FormChangeHandlerChildProps {
  onTextChanged: () => void;
  onToggleChanged: () => void;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
}

interface FormChangeHandlerProps {
  halted?: boolean;
  timeout?: number;
  onSave: () => void;
  isSaving?: boolean;
  children: (props: FormChangeHandlerChildProps) => any;
}

// FormChangeHandler is observable so we can use dynamic lists of
// attachments inside of forms enclosed by it
@observer
class FormChangeHandler extends React.Component<FormChangeHandlerProps> {
  state = { hasChanges: false };

  timer: any = null;

  initiatedSave = false;
  saveAgain = false;
  restartTimer = false;

  componentDidUpdate(prevProps: FormChangeHandlerProps) {
    // TODO do something on change of halted prop here?

    if (prevProps.isSaving !== this.props.isSaving) {
      this.initiatedSave = false;
    }

    if (prevProps.isSaving && !this.props.isSaving) {
      // save has finished
      if (this.saveAgain) {
        this.saveAgain = false;
        this.save();
      } else if (this.restartTimer) {
        this.restartTimer = false;
        this.startTimer();
        this.hasChanges();
      } else if (this.props.halted) {
        // save finished and nothing has changed again so we do not have changes any longer
        this.setState({
          hasChanges: false
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  isSaving() {
    return this.props.isSaving || this.initiatedSave;
  }

  startTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    // tslint:disable: align
    this.timer = setTimeout(() => {
      this.checkSave();
    }, this.props.timeout || DEFAULT_TIMEOUT);
  }

  save() {
    if (this.isSaving()) {
      // we've been told to save so we need to save again to make
      // sure no changes will be lost
      this.saveAgain = true;
      return;
    }

    // We want to remember that we invoked a save process even if
    // isSaving property is not updated yet.
    // Our little reminder will be reset when isSaving is updated.
    this.initiatedSave = true;

    if (this.timer) {
      // we do need to save before a new change has been made
      clearTimeout(this.timer);
    }

    // onSave will is detached from the current flow so changed
    // form values will be visible to it during toggleChanged
    if (!this.props.halted) {
      setTimeout(() => this.props.onSave());

      this.setState({
        hasChanges: false
      });
    }
  }

  hasChanges() {
    if (!this.state.hasChanges) {
      this.setState({
        hasChanges: true
      });
    }
  }

  checkSave() {
    if (this.isSaving()) {
      // we will restart the timer after the current save proccess has finished
      // so the current changes will be saved as well
      if (!this.saveAgain) {
        this.restartTimer = true;
        this.hasChanges();
      }
      return;
    }

    this.save();
  }

  textChanged() {
    this.startTimer();
    this.hasChanges();
  }

  toggleChanged() {
    if (this.isSaving()) {
      // toogles should always be saved immediately, so save again after current
      // save process has finished
      this.saveAgain = true;
      return;
    }

    this.save();
  }

  render() {
    return this.props.children({
      onTextChanged: () => this.textChanged(),
      onToggleChanged: () => this.toggleChanged(),
      hasUnsavedChanges: this.state.hasChanges,
      isSaving: this.props.isSaving ? true : false
    });
  }
}

export default FormChangeHandler;
