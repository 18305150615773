import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { history } from 'utils/history';

interface PageLayoutProps {
  applicationStore?: ApplicationStoreType;
  mainNav: React.ReactNode;
  children: React.ReactNode;
  reduced?: boolean;
  noHeaderBar?: boolean;
}

export default inject('applicationStore')(
  observer(
    ({
      children,
      mainNav,
      reduced,
      noHeaderBar,
      applicationStore
    }: PageLayoutProps) => {
      useEffect(
        () => history.listen(() => applicationStore!.setMenuVisible(false)),
        [applicationStore]
      );

      const pageLayoutClasses = classNames('l-page', {
        'l-page--open': applicationStore!.menuVisible,
        'l-page--reduced': reduced
      });

      const secondContentClasses = classNames('l-page__second', {
        'l-page__second--noHeader': noHeaderBar
      });

      return (
        <div className={pageLayoutClasses}>
          <div className="l-page__content">
            <div className="l-page__first">{mainNav}</div>

            <div className={secondContentClasses}>{children}</div>
          </div>
        </div>
      );
    }
  )
);
