import { OrganizationModelType } from 'models/OrganizationModel';

export const simplifyOrgAccessLevel = (
  org?: OrganizationModelType
): 'admin' | 'member' | 'network' | undefined => {
  if (!org) {
    return undefined;
  }

  if (org.access_level === 'admin' || org.access_level === 'superadmin') {
    return 'admin';
  } else if (org.access_level === 'user' || org.access_level === 'viewer') {
    return 'member';
  }

  return 'network';
};

export default simplifyOrgAccessLevel;
