import { inject, observer } from 'mobx-react';
import React from 'react';

import Select from 'components/Inputs/Select';
import Loading from 'components/Loading';
import { ApplicationStoreType, LanguageEnum } from 'models/ApplicationStore';

interface LanguageSelectContainerProps {
  applicationStore?: ApplicationStoreType;
}

interface LangugeSelectContainerState {
  loading?: boolean;
}

@inject('applicationStore')
@observer
class LanguageSelectContainer extends React.Component<
  LanguageSelectContainerProps,
  LangugeSelectContainerState
> {
  state: LangugeSelectContainerState = {};

  async changeLanguage(lang: LanguageEnum) {
    const { applicationStore } = this.props;

    if (lang !== applicationStore!.getCurrentLanguage()) {
      this.setState({
        loading: true
      });

      try {
        await applicationStore!.changeLanguage(lang);
      } catch (e) {
        // TODO do something here?
        this.setState({
          loading: false
        });
      }
    }
  }

  render() {
    const { applicationStore } = this.props;

    return (
      <>
        {this.state.loading && <Loading />}

        <Select
          login={true}
          name="lang"
          onChange={(e) => this.changeLanguage(e.target.value as LanguageEnum)}
          value={applicationStore!.getCurrentLanguage()}
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
      </>
    );
  }
}

export default LanguageSelectContainer;
