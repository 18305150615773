import { types } from 'mobx-state-tree';

import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';

const values = [
  ORG_ACCESS_LEVELS.DISABLED,
  ORG_ACCESS_LEVELS.VIEWER,
  ORG_ACCESS_LEVELS.USER,
  ORG_ACCESS_LEVELS.ADMIN,
  ORG_ACCESS_LEVELS.SUPERADMIN
];

export const OrgAccessLevelEnumModel = types.enumeration(values);

export const createOrgAccessLevelEnumModel = (
  value?: any
): OrgAccessLevelEnumModelType | undefined => {
  if (!value || !values.includes(value)) {
    return undefined;
  }

  return value;
};

export const isActiveOrgLevel = (level?: OrgAccessLevelEnumModelType) => {
  if (!level || level === ORG_ACCESS_LEVELS.DISABLED) {
    return false;
  }
  return true;
};

export type OrgAccessLevelEnumModelType = typeof OrgAccessLevelEnumModel.Type;
export default OrgAccessLevelEnumModel;
