import { inject, observer } from 'mobx-react';
import React from 'react';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import { DataStoreType } from 'models/DataStore';
import { PainpointsStoreType } from 'models/PainpointsStore';

interface PublicPainpointsListContainerProps {
  itemType: any;
  itemContainer?: any;
  emptyComponent?: any;
  loading?: any;
  filter?: string;
  onItemClick?: (id: number) => void;
}

interface PainpointsListContainerProps
  extends PublicPainpointsListContainerProps {
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
}

@inject('dataStore', 'painpointsStore')
@observer
class PainpointsListContainer extends React.Component<PainpointsListContainerProps> {
  componentDidMount() {
    this.load();
  }

  load() {
    this.props.painpointsStore.getPainpoints();
  }

  renderLoading() {
    if (this.props.loading) {
      return this.props.loading;
    }

    return <Loading />;
  }

  renderError() {
    return (
      <ErrorMessage
        state={this.props.painpointsStore.listLoadingState}
        onRetry={() => this.load()}
      />
    );
  }

  render() {
    const {
      painpointsStore,
      itemType,
      itemContainer,
      emptyComponent,
      onItemClick,
      filter
    } = this.props;

    if (painpointsStore.isListLoading) {
      return this.renderLoading();
    }
    if (painpointsStore.isListError) {
      return this.renderLoading();
    }

    const Item = itemType;
    const Container = !itemContainer ? React.Fragment : itemContainer;
    const Empty = !emptyComponent ? React.Fragment : emptyComponent;

    const list = painpointsStore
      .list('active', filter)
      .map((elem) => (
        <Item
          key={elem.id}
          painpoint={elem}
          onClick={() => onItemClick && onItemClick(elem.id)}
        />
      ));

    return <Container>{!list.length ? <Empty /> : list}</Container>;
  }
}

export default (props: PublicPainpointsListContainerProps) => {
  // @ts-ignore
  return <PainpointsListContainer {...props} />;
};
