import { injectIntl } from 'react-intl';

import Select from 'components/Inputs/Select';
import ORG_ACCESS_LEVELS from 'utils/constants/org-access-levels';

const LEVELS = [
  ORG_ACCESS_LEVELS.DISABLED,
  ORG_ACCESS_LEVELS.VIEWER,
  ORG_ACCESS_LEVELS.USER,
  ORG_ACCESS_LEVELS.ADMIN
];

export default injectIntl((props: any) => {
  const selectProps = {
    ...props,
    intl: undefined
  };

  return (
    <Select {...selectProps} normalFont={true}>
      {LEVELS.map((level) => (
        <option key={level} value={level}>
          {props.intl.formatMessage({ id: 'access level ' + level })}
        </option>
      ))}
    </Select>
  );
});
