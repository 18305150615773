import CardWrapper from 'components/CardWrapper';
import ErrorMessage from 'components/ErrorMessage';
import Headline from 'components/headline';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import FileWrapper from 'components/Layout/FileWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import {
  SessionFixed,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  AssistantSessionUpdateType,
  AssistantStoreType
} from 'models/AssistantStore';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { Component, Fragment } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import {
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PainpointSelectScreenProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  painpointsStore: PainpointsStoreType;
  form: FormType;
}

@inject('applicationStore', 'assistantStore', 'painpointsStore')
@observer
class PainpointSelectScreen extends Component<
  PainpointSelectScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    this.loadPainpoints();
    this.fillForm();

    scrollSidebar('painpoint_question');
  }

  private async loadPainpoints() {
    const { painpointsStore } = this.props;
    const { currentItem } = this.props.assistantStore;
    const project = currentItem?.project;

    if (!project?.id || !project.organization_id) {
      this.props.history.replace(ROUTE_ASSISTANT);
      return;
    }

    await painpointsStore.getPainpoints(project.organization_id, project.id);
  }

  private fillForm() {
    const { currentItem } = this.props.assistantStore;

    const painpointId = currentItem?.painpoint_id || undefined;

    this.props.form.setField('painpoint_id', painpointId);
    this.props.form.setField('initialPainpointId', painpointId);
  }

  private async submit(continueSession: boolean = false) {
    const { applicationStore, assistantStore, form, intl } = this.props;
    const { currentId, currentAssistantType } = assistantStore;

    const painpointId: number = form.values.painpoint_id || undefined;

    if (!currentId || !painpointId) {
      return;
    }

    const patch: AssistantSessionUpdateType = {
      painpoint_id: painpointId,
      painpoint_question_date: new Date().toJSON()
    };

    if (continueSession) {
      patch.progress = ASSISTANTS.hypothesis_sprint.step1_1.progress;
    }

    let success = false;
    try {
      if (await assistantStore.updateAssistantSession(currentId, patch)) {
        success = true;
      }
    } catch (error: any) {
      // probably a form error but this should not occur here
    }

    if (success) {
      if (continueSession) {
        goToNextStep(
          currentId,
          ASSISTANTS.hypothesis_sprint.step1_1.progress,
          currentAssistantType || 'hypothesis_sprint'
        );
      } else {
        this.props.history.push(ROUTE_ASSISTANT);
      }
    } else {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'save error' }),
        'error'
      );
    }
  }

  private painpointClicked(id: number) {
    const { form } = this.props;

    form.setField(
      'painpoint_id',
      form.values.painpoint_id === id ? undefined : id
    );
  }

  render() {
    const { painpointsStore, form, location } = this.props;
    const { currentItem, currentAssistantType, isItemSaving } =
      this.props.assistantStore;
    const { isListLoading, isListError } = painpointsStore;

    if (!currentItem) {
      return null;
    }

    const painpointId = form.values.painpoint_id;
    const buttonsDisabled = !painpointId;

    const list = isListError
      ? []
      : painpointsStore.grid(form.values.initialPainpointId);

    const isLoading = isListLoading || isItemSaving;

    const stepInfo = getStepInfo('step1_1', currentAssistantType);
    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="painpoint_question"
        location={location}
        sessionId={currentItem.id}
        bottomSpace={true}
        // timerSeconds={600}
      >
        {isLoading && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant painpoint select header" />
          </SessionHeadline>
        </SessionTop>

        <div>
          <SessionMainText noMargin={true}>
            <FormattedMessage id="assistant painpoint select" />
          </SessionMainText>

          <div style={{ margin: 0, paddingBottom: '2em' }}>
            <VideoPopupLink videoId="hCgMr0M9gGY" />
          </div>
        </div>

        {isListError ? (
          <ErrorMessage onRetry={() => this.loadPainpoints()} />
        ) : (
          <>
            {!isLoading && !list.length ? (
              <CardWrapper>
                <FormattedMessage
                  id="assistant painpoint select empty"
                  values={{
                    projectName: currentItem.project?.name || 'N/A'
                  }}
                />
              </CardWrapper>
            ) : (
              <ColumnWrapper gap="3em">
                {list.map((cluster, idx) => (
                  <Fragment key={(cluster.cluster?.id || '') + '-' + idx}>
                    {cluster.painpoints.length ? (
                      <>
                        <Headline>
                          {cluster.cluster?.name || (
                            <FormattedMessage id="New painpoints" />
                          )}
                        </Headline>

                        <FileWrapper noMargin={true}>
                          {cluster.painpoints.map((elem) => (
                            <PainpointsListItem
                              painpoint={elem}
                              key={elem.id}
                              readOnly={true}
                              noLink={true}
                              checked={painpointId === elem.id}
                              onChange={() => this.painpointClicked(elem.id)}
                            />
                          ))}
                        </FileWrapper>
                      </>
                    ) : null}
                  </Fragment>
                ))}
              </ColumnWrapper>
            )}

            <SessionFixed>
              <MainButton
                secondary={true}
                disabled={buttonsDisabled}
                onClick={() => this.submit()}
              >
                <FormattedMessage id="Save draft" />
              </MainButton>
              <MainButton
                disabled={buttonsDisabled}
                onClick={() => this.submit(true)}
              >
                <FormattedMessage id="Next" />
              </MainButton>
            </SessionFixed>
          </>
        )}
      </StepContainer>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <PainpointSelectScreen {...props} form={form} />;
});
