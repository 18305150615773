import { IModelType, IMSTArray, IReferenceType } from 'mobx-state-tree';

import arrUnique from 'utils/misc/arr-unique';
import uniqBy from 'utils/misc/uniq-by';
import sortByField from 'utils/sort/field';

export type ReducedAuthors = Array<{ id: number; name: string }>;
export type ReducedTags = string[];

type ListTemplateType = IModelType<any, any>;
type ListType<T extends ListTemplateType> = IMSTArray<IReferenceType<T>>;

export const reduceAuthors = <T extends ListTemplateType>(
  list?: ListType<T>
): ReducedAuthors => {
  let authors: ReducedAuthors = [];

  if (!list) {
    return authors;
  }

  for (const item of list.values()) {
    const name = item.author?.id ? item.author.fullName : undefined;
    if (name) {
      authors.push({ id: item.author!.id!, name });
    }
  }

  authors = uniqBy(authors, 'name');
  authors.sort(sortByField('name'));

  return authors;
};

export const reduceTags = <T extends ListTemplateType>(
  list?: ListType<T>
): ReducedTags => {
  let tags: string[] = [];

  if (!list) {
    return tags;
  }

  for (const item of list.values()) {
    if (item && item.tags) {
      tags.push(...item.tags.strings);
    }
  }

  tags = arrUnique(tags);
  tags.sort();

  return tags;
};
