import { observer } from 'mobx-react';
import { useState } from 'react';

import DestroyButton from 'components/DestroyButton';
import ScreenItem from 'components/Layout/ScreenItem';
import MoveButton from 'components/MoveButton';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import UploadContainer from 'containers/UploadContainer';
import { UploadContainerChildProps } from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import { AttachmentModelType } from 'models/AttachmentModel';
import useForm from 'utils/hooks/useForm';
import Icon from 'components/Icon';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import { PrototypesStoreType } from 'models/PrototypesStore';
import Textarea from 'components/Inputs/Textarea';
import classNames from 'classnames';
import getIntl from 'i18n/locales';

interface PublicScribbleUserFlowItemProps {
  prototypesStore?: PrototypesStoreType;
  screen: PrototypeScreenModelType;
  onMoveClick: (id: number, direction: 'left' | 'right') => void;
  onRemoveClick: (id: number) => void;
  onEdit: (screenId: number, headline: string) => void;
}

const ScribbleUserFlowItem = ({
  screen,
  onRemoveClick,
  onEdit,
  onMoveClick
}: PublicScribbleUserFlowItemProps) => {
  const [deleting, setDeleting] = useState(false);
  const [editable, setEditable] = useState(false);

  if (!screen) {
    return null;
  }
  const form = useForm({ headline: screen.headline });

  const save = async () => {
    if (editable && onEdit) {
      form.setLoading(true);

      try {
        onEdit(screen.id, form.values.headline || '');

        setEditable(false);
      } catch (e) {
        // TODO what to do here?
      }

      form.setLoading(false);
    }
  };

  const remove = async () => {
    if (
      (screen.attachments?.hasAny || !!screen.headline) &&
      !window.confirm(
        getIntl().formatMessage({ id: 'remove prototype screen confirm' })
      )
    ) {
      return;
    }

    setDeleting(true);

    try {
      onRemoveClick(screen.id);
      setDeleting(false);
    } catch (e) {
      setDeleting(false);
      // TODO what to do here?
    }
  };

  const attachmentReceived = (attachment: AttachmentModelType) => {
    if (!screen || !attachment) {
      return;
    }

    screen.addAttachment(attachment);
  };

  const attachmentRemoved = (id: number) => {
    if (!screen) {
      return;
    }

    screen.attachments?.delete(id);
  };

  const renderMoveButton = () => {
    if ((screen.isFirst && screen.isLast) || editable) return null;

    const moveClasses = classNames('move-buttons', {
      'move-buttons--multiple': !screen.isFirst && !screen.isLast
    });

    return (
      <div className={moveClasses}>
        {screen.isFirst ? (
          <MoveButton
            right={true}
            onClick={() => onMoveClick(screen.id, 'right')}
          />
        ) : screen.isLast ? (
          <MoveButton onClick={() => onMoveClick(screen.id, 'left')} />
        ) : (
          <>
            <MoveButton onClick={() => onMoveClick(screen.id, 'left')} />
            <MoveButton
              right={true}
              onClick={() => onMoveClick(screen.id, 'right')}
            />
          </>
        )}
      </div>
    );
  };

  const handleEditable = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (editable) {
      save();
    }

    setEditable(!editable);
  };

  const attachment = screen.attachments?.firstOfType('screen');

  return (
    <ScreenItem asUserflow={true}>
      <div className="user-flow-card__headline">
        <b>Schritt {screen.order!}</b>
        <button onMouseDown={(event) => handleEditable(event)}>
          <Icon name={editable ? 'check' : 'pen'} />
        </button>
        {editable && !deleting && (
          <Textarea
            lines={2}
            name="headline"
            {...form.bindInput('headline')}
            disabled={form.loading}
            slimPadding={true}
            autoFocus={true}
            onBlur={async () => {
              await save();
              setTimeout(() => {
                setEditable(false);
              }, 100);
            }}
            onFocus={(e: any) => e.target.select()}
            onKeyUp={(e: any) => {
              if (e.keyCode === 27) {
                e.preventDefault();
                e.stopPropagation();
                e.target.blur();
              }
            }}
            onKeyPress={(e: any) => {
              if (e.charCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                save();
              }
            }}
          />
        )}
        {!editable && !deleting && (
          <>
            <span className="l-screen-item__text">{screen.headline}</span>
            {!screen.attachments?.hasAny && (
              <DestroyButton onClick={() => remove()} />
            )}
          </>
        )}
      </div>

      {attachment ? (
        <UploadedFileContainer
          attachment={attachment}
          onAttachmentRemoved={(id) => attachmentRemoved(id)}
          key={attachment.id}
        >
          {(props) => <UploadedMobileImage {...props} />}
        </UploadedFileContainer>
      ) : (
        <UploadContainer
          attachmentType="screen"
          elementType={ElementType.PrototypeScreen}
          elementId={screen.id}
          onAttachmentReceived={(a) => attachmentReceived(a)}
          acceptVideo={true}
        >
          {(props: UploadContainerChildProps) => (
            <>
              <UploadMobileButton {...props} />
            </>
          )}
        </UploadContainer>
      )}

      {renderMoveButton()}
    </ScreenItem>
  );
};
export default observer(ScribbleUserFlowItem);
