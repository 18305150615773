import classNames from 'classnames';

interface PositionWrapperProps {
  children: React.ReactNode;
  center?: boolean;
  end?: boolean;
  gap?: string;
}

export default function PositionWrapper(props: PositionWrapperProps) {
  const style = { '--position-gap': props.gap } as React.CSSProperties;

  const PositionWrapperClasses = classNames('l-position-wrapper', {
    'l-position-wrapper--center': props.center,
    'l-position-wrapper--end': props.end
  });

  return (
    <div className={PositionWrapperClasses} style={style}>
      {props.children}
    </div>
  );
}
