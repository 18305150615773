import { types } from 'mobx-state-tree';

export const ItemLoadingStateEnum = types.enumeration([
  'loading',
  'load_error',
  'saving',
  'save_error',
  'deleting',
  'delete_error',
  'access_denied',
  'feature_disabled',
  'not_found'
]);
export type ItemLoadingStateEnumType = typeof ItemLoadingStateEnum.Type;

export const ListLoadingStateEnum = types.enumeration([
  'loading',
  'deleting',
  'load_error',
  'access_denied',
  'feature_disabled',
  'not_found'
]);
export type ListLoadingStateEnumType = typeof ListLoadingStateEnum.Type;

export const isLoadError = (
  error?: ItemLoadingStateEnumType | ListLoadingStateEnumType
): boolean => {
  switch (error) {
    case 'load_error':
    case 'access_denied':
    case 'not_found':
    case 'feature_disabled':
      return true;

    default:
      return false;
  }
};

export const isSaveError = (
  error?: ItemLoadingStateEnumType | ListLoadingStateEnumType
): boolean => {
  switch (error) {
    case 'save_error':
    case 'delete_error':
      return true;

    default:
      return false;
  }
};
