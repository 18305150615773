import CardWrapper from 'components/CardWrapper';
import Headline from 'components/headline';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageLogoEdit from 'components/PageLogoEdit';
import SimplePopup from 'components/SimplePopup';
import Typography from 'components/Typography';
import UploadButton from 'components/UploadButton';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedImage from 'components/UploadedImage';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import {
  SessionActions,
  SessionHeadline,
  SessionMainText,
  SessionTop
} from 'domain/assistant/session-elements';
import StepContainer, { scrollSidebar } from 'domain/assistant/StepContainer';
import VideoPopupLink from 'domain/assistant/VideoPopupLink';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  AssistantSessionBenchmarkUpdateType,
  AssistantStoreType
} from 'models/AssistantStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import {
  ASSISTANT_BASE_ROUTES,
  ASSISTANTS,
  getStepInfo,
  goToNextStep
} from 'utils/constants/assistant-steps';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import { history, HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

export const MAX_BENCHMARKS = 3;

interface PublicBenchmarkFormComponentProps extends WrappedComponentProps {
  location: HistoryProps['location'];
  index: number;
  editMode?: boolean;
}

interface BenchmarkFormComponentProps
  extends PublicBenchmarkFormComponentProps {
  applicationStore: ApplicationStoreType;
  assistantStore: AssistantStoreType;
  form: FormType;
}

interface BenchmarkFormComponentState {
  loadingState?: 'loading' | 'error';
  confirmFinish?: boolean;
}

@inject('applicationStore', 'assistantStore')
@observer
class BenchmarkFormComponent extends React.Component<
  BenchmarkFormComponentProps,
  BenchmarkFormComponentState
> {
  state: BenchmarkFormComponentState = {};

  componentDidMount() {
    this.fillForm();

    scrollSidebar('benchmarks');
  }

  private fillForm() {
    const {
      form,
      index,
      assistantStore: { currentItem }
    } = this.props;

    form.reset();

    const benchmark = currentItem?.sortedSessionBenchmarks[index];

    if (!benchmark) {
      return;
    }

    form.setField('id', benchmark.id || undefined);
    form.setField('headline', benchmark.headline);
    form.setField('company_name', benchmark.company_name);
    form.setField('description', benchmark.description);
    form.setField('purpose', benchmark.purpose);
  }

  private pushFormRoute() {
    const { currentId } = this.props.assistantStore;
    if (!currentId) {
      return;
    }

    history.push(
      ROUTE_ASSISTANT +
        '/' +
        ASSISTANT_BASE_ROUTES.benchmarks_sprint +
        currentId +
        ASSISTANTS.benchmarks_sprint.step2
    );
  }

  private async save(mode: 'attachment' | 'draft' | 'continue' | 'finish') {
    const { assistantStore, form, index } = this.props;
    const { currentId: currentSessionId } = assistantStore;

    if (!currentSessionId) {
      return;
    }

    if (mode !== 'attachment') {
      this.setState({
        loadingState: 'loading'
      });
    }

    const benchmark =
      assistantStore.currentItem?.sortedSessionBenchmarks[index];
    const id = benchmark?.id;

    const isUpdate = !!id;
    const hasAttachment = !!benchmark?.attachments.firstOfType('screen');
    const hasLogo = !!benchmark?.attachments.firstOfType('logo');

    const hasAnyContent =
      !!form.values.headline ||
      !!form.values.company_name ||
      !!form.values.description ||
      !!form.values.purpose ||
      hasAttachment ||
      hasLogo;
    const isCompleted =
      !!form.values.headline &&
      !!form.values.company_name &&
      !!form.values.description &&
      !!form.values.purpose &&
      hasAttachment;

    const patch: AssistantSessionBenchmarkUpdateType = {
      headline: form.values.headline || '',
      company_name: form.values.company_name || '',
      description: form.values.description || '',
      purpose: form.values.purpose || ''
    };

    if (mode === 'continue' || (mode === 'finish' && isCompleted)) {
      patch.editing_state = 'active';
    }

    if (mode !== 'finish' || hasAnyContent) {
      try {
        if (isUpdate) {
          await assistantStore.updateAssistantSessionBenchmark(
            currentSessionId,
            id!,
            patch
          );
        } else {
          patch.assistant_session_id = currentSessionId;

          await assistantStore.createAssistantSessionBenchmark(
            currentSessionId,
            patch
          );
        }
      } catch (error: any) {
        if (handleFormError(form, error)) {
          this.setState({
            loadingState: undefined
          });
          return;
        }

        this.setState({
          loadingState: 'error'
        });
        return;
      }
    }

    if (mode === 'attachment') {
      return;
    }

    if (mode === 'draft') {
      history.push(ROUTE_ASSISTANT);
      return;
    }

    if (this.props.editMode) {
      this.pushFormRoute();
      return;
    }

    const isFinish = mode === 'finish' || index === MAX_BENCHMARKS - 1;
    if (!isFinish) {
      return;
    }

    try {
      await assistantStore.updateAssistantSessionProgess(
        currentSessionId,
        ASSISTANTS.benchmarks_sprint.step2.progress
      );
    } catch (error: any) {
      // TODO do something here?
    }

    goToNextStep(
      currentSessionId,
      ASSISTANTS.benchmarks_sprint.step2.progress,
      'benchmarks_sprint'
    );
  }

  private saveForAttachment() {
    this.save('attachment');
  }

  private finish(confirmed: boolean = false) {
    if (!confirmed && this.props.index < MAX_BENCHMARKS - 1) {
      this.setState({
        confirmFinish: true
      });
      return;
    }

    this.setState({
      confirmFinish: false
    });

    this.save('finish');
  }

  private async deleteSessionBenchmark() {
    const { assistantStore, intl, index } = this.props;

    const session = assistantStore.currentItem;
    const sessionId = session?.id;
    const benchmarkId = session?.sortedSessionBenchmarks[index]?.id;

    if (!sessionId || !benchmarkId) {
      return;
    }

    if (session!.sessionBenchmarksCount < 2) {
      alert(intl.formatMessage({ id: 'assistant benchmark last error' }));
      return;
    }

    if (
      !window.confirm(
        intl.formatMessage({ id: 'assistant benchmark delete confirm' })
      )
    ) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      await assistantStore.deleteAssistantSessionBenchmark(
        sessionId,
        benchmarkId
      );

      this.pushFormRoute();
    } catch (error: any) {
      this.props.applicationStore.setFlashMessage(
        intl.formatMessage({
          id: 'assistant benchmark delete error'
        }),
        'error'
      );

      this.setState({
        loadingState: undefined
      });
    }
  }

  private uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    if (!newState) {
      this.setState({
        loadingState: undefined
      });
      return;
    }

    if (newState === 'saving') {
      this.setState({
        loadingState: 'loading'
      });
    }
  }

  private attachmentReceived(attachment: AttachmentModelType) {
    const {
      assistantStore: { currentItem },
      index
    } = this.props;

    if (!attachment) {
      return;
    }

    const benchmark = currentItem?.sortedSessionBenchmarks[index];

    if (benchmark) {
      benchmark.attachments.put(attachment);
    } else if (attachment.element_id) {
      // TODO what to do without item!?
    }
  }

  private attachmentRemoved(id: number) {
    const {
      assistantStore: { currentItem },
      index
    } = this.props;

    currentItem?.sortedSessionBenchmarks[index]?.attachments.delete(id);
  }

  private maybeRenderConfirm() {
    if (!this.state.confirmFinish) {
      return null;
    }

    return (
      <SimplePopup
        onAbort={() => this.setState({ confirmFinish: undefined })}
        onSubmit={() => this.finish(true)}
        submitTextId="Continue anyway"
        reduced={true}
      >
        <Headline>
          <FormattedMessage
            id="assistant session benchmark warning header"
            values={{ count: MAX_BENCHMARKS }}
          />
        </Headline>

        <Typography>
          <FormattedMessage
            id="assistant session benchmark warning"
            values={{ count: MAX_BENCHMARKS }}
          />
        </Typography>
      </SimplePopup>
    );
  }

  render() {
    const {
      form,
      intl,
      index,
      assistantStore: { currentItem, currentAssistantType, isItemSaving }
    } = this.props;
    const { loadingState } = this.state;

    let isActive: boolean = false;
    let attachment: AttachmentModelType | undefined;
    let logo: AttachmentModelType | undefined;
    const benchmark = currentItem?.sortedSessionBenchmarks[index];
    if (benchmark) {
      attachment = benchmark.attachments.firstOfType('screen');
      logo = benchmark.attachments.firstOfType('logo');
      isActive = benchmark.editing_state === 'active';
    }

    const isContinueDisabled =
      !form.values.headline ||
      !form.values.company_name ||
      !form.values.description ||
      !form.values.purpose ||
      !attachment;
    const isDraftDisabled =
      !form.values.headline &&
      !form.values.company_name &&
      !form.values.description &&
      !form.values.purpose &&
      !attachment &&
      !logo;

    const stepInfo = getStepInfo('step2', currentAssistantType);

    return (
      <StepContainer
        progress={stepInfo?.percent}
        sidebarHeadline={stepInfo?.header}
        showHeader="benchmarks"
        location={this.props.location}
        timerSeconds={900}
      >
        {this.maybeRenderConfirm()}
        {(loadingState === 'loading' || isItemSaving) && <Loading />}

        <SessionTop>
          <SessionHeadline step={stepInfo?.header}>
            <FormattedMessage id="assistant session benchmarks header" />
          </SessionHeadline>

          <SessionMainText noMargin={true}>
            <FormattedMessage id="assistant session benchmarks" />
          </SessionMainText>

          <div style={{ margin: 0, paddingBottom: '2em' }}>
            <VideoPopupLink videoId="hCgMr0M9gGY" />
          </div>
        </SessionTop>

        <RainbowEditWrapper>
          <RainbowEditWrapper.Image>
            {attachment ? (
              <UploadedFileContainer
                attachment={attachment}
                onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                key={attachment.id}
              >
                {(props) => <UploadedMobileImage {...props} />}
              </UploadedFileContainer>
            ) : (
              <UploadContainer
                attachmentType="screen"
                elementType={ElementType.AssistantSessionBenchmark}
                elementId={benchmark?.id}
                waitForId={true}
                acceptVideo={true}
                onLoadingStateChanged={(s) => this.uploadLoadingStateChanged(s)}
                onAttachmentReceived={(a) => this.attachmentReceived(a)}
                onWaitingForId={() => this.saveForAttachment()}
              >
                {(props: UploadContainerChildProps) => (
                  <UploadMobileButton {...props} />
                )}
              </UploadContainer>
            )}
          </RainbowEditWrapper.Image>

          <RainbowEditWrapper.Body>
            <CardWrapper>
              <PageLogoEdit
                upload={
                  /*copiedLogo ? (
                      copiedLogo
                    ) :*/ logo ? (
                    <UploadedFileContainer
                      attachment={logo}
                      onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                      key={logo.id}
                    >
                      {(props) => <UploadedImage logo={true} {...props} />}
                    </UploadedFileContainer>
                  ) : (
                    <UploadContainer
                      attachmentType="logo"
                      elementType={ElementType.AssistantSessionBenchmark}
                      elementId={benchmark?.id}
                      waitForId={true}
                      onLoadingStateChanged={(s) =>
                        this.uploadLoadingStateChanged(s)
                      }
                      onAttachmentReceived={(a) => this.attachmentReceived(a)}
                      onWaitingForId={() => this.saveForAttachment()}
                    >
                      {(props: UploadContainerChildProps) => (
                        <UploadButton logo={true} {...props} />
                      )}
                    </UploadContainer>
                  )
                }
              >
                <Input
                  headline={true}
                  name="headline"
                  placeholder={intl.formatMessage({
                    id: 'Insert headline'
                  })}
                  {...form.bindInput('headline')}
                />

                <Input
                  subheadline={true}
                  name="company_name"
                  placeholder={intl.formatMessage({
                    id: 'Insert company name'
                  })}
                  {...form.bindInput('company_name')}
                />
                {/* <InputWithDropdown
                    onChange={e => {
                      const name = e.target.value || '';
                      form.setField('company_name', name);

                      this.getCompanyNameSuggestions(
                        name,
                        benchmarksStore,
                        dataStore
                      );

                      onTextChanged();
                    }}
                    suggestions={benchmarksStore.suggestions}
                    subheadline={true}
                    name="company_name"
                    placeholder={intl.formatMessage({
                      id: 'Insert company name'
                    })}
                    value={form.values.company_name}
                    onSuggestionSelected={(_id, name) => {
                      form.setField('company_name', name);
                      this.getSuggestionLogo(name);

                      onTextChanged();
                    }}
                  /> */}
              </PageLogoEdit>
            </CardWrapper>

            <CardWrapper>
              <Textarea
                label={<FormattedMessage id="solution short description" />}
                name="description"
                {...form.bindInput('description')}
              />
            </CardWrapper>

            <CardWrapper>
              <Textarea
                label={<FormattedMessage id="will help us how" />}
                name="purpose"
                {...form.bindInput('purpose')}
              />
            </CardWrapper>
          </RainbowEditWrapper.Body>
        </RainbowEditWrapper>

        {isActive ? (
          <SessionActions>
            <MainButton
              secondary={true}
              onClick={() => this.deleteSessionBenchmark()}
            >
              <FormattedMessage id="Remove" />
            </MainButton>

            <MainButton
              type="submit"
              disabled={isContinueDisabled}
              onClick={() => this.save('continue')}
            >
              <FormattedMessage id="Update benchmark" />
            </MainButton>
          </SessionActions>
        ) : (
          <>
            <SessionActions>
              <MainButton
                secondary={true}
                disabled={isDraftDisabled}
                onClick={() => this.save('draft')}
              >
                <FormattedMessage id="Save draft" />
              </MainButton>

              <MainButton
                type="submit"
                disabled={isContinueDisabled}
                onClick={() => this.save('continue')}
              >
                <FormattedMessage id="Add benchmark" />
              </MainButton>
            </SessionActions>

            <SessionActions>
              <MainButton
                type="button"
                onClick={() => this.finish()}
                disabled={isContinueDisabled && index === 0}
              >
                <FormattedMessage id="Next" />
              </MainButton>
            </SessionActions>
          </>
        )}
      </StepContainer>
    );
  }
}

export default injectIntl((props: PublicBenchmarkFormComponentProps) => {
  const form = useForm();
  // @ts-ignore
  return <BenchmarkFormComponent {...props} form={form} />;
});
