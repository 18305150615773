// support for MobX devtools extension (https://github.com/mobxjs/mobx-devtools)
import DisrooptiveApi from 'api/DisrooptiveApi';
// @ts-ignore
import makeInspectable from 'mobx-devtools-mst';
// import { addMiddleware } from 'mobx-state-tree';
import ActionsStore from 'models/ActionsStore';
import ApplicationStore from 'models/ApplicationStore';
import AssistantStore from 'models/AssistantStore';
import AttachmentsStore from 'models/AttachmentsStore';
import { createAuthStateModel } from 'models/AuthStateModel';
import BenchmarksStore from 'models/BenchmarksStore';
import BookmarksStore from 'models/BookmarksStore';
import BriefingStore from 'models/BriefingStore';
import CommentsStore from 'models/CommentsStore';
import ContentListStore from 'models/ContentListStore';
import DataStore from 'models/DataStore';
import FaqPagesStore from 'models/FaqPagesStore';
import HypothesesStore from 'models/HypothesesStore';
import LearningsStore from 'models/LearningsStore';
import OrganizationsStore from 'models/OrganizationsStore';
import PainpointsStore from 'models/PainpointsStore';
import ProjectsStore from 'models/ProjectsStore';
import PrototypesStore from 'models/PrototypesStore';
import SharedContentStore from 'models/SharedContentStore';

export default function configureStores(options: { client: DisrooptiveApi }) {
  const { client } = options;

  const dataStore = DataStore.create({}, { client });

  // @TODO: leave it here as a debugger (onAction)
  // addMiddleware(dataStore, (call, next) => {
  //   console.trace({ name: call.name, args: call.args });
  //   next(call);
  // });

  makeInspectable(dataStore);

  const applicationStore = ApplicationStore.create(
    {
      authState: createAuthStateModel()
    },
    {
      client,
      dataStore
    }
  );
  makeInspectable(applicationStore);

  const attachmentsStore = AttachmentsStore.create(
    {},
    {
      client,
      applicationStore
    }
  );
  makeInspectable(attachmentsStore);

  const organizationsStore = OrganizationsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(organizationsStore);

  const projectsStore = ProjectsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(projectsStore);

  const assistantStore = AssistantStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore,
      projectsStore
    }
  );
  makeInspectable(assistantStore);

  const commentsStore = CommentsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(commentsStore);

  const bookmarksStore = BookmarksStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(bookmarksStore);

  const briefingStore = BriefingStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(briefingStore);

  const painpointsStore = PainpointsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore,
      projectsStore
    }
  );
  makeInspectable(painpointsStore);

  const hypothesesStore = HypothesesStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore,
      projectsStore
    }
  );
  makeInspectable(hypothesesStore);

  const benchmarksStore = BenchmarksStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(benchmarksStore);

  const prototypesStore = PrototypesStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(prototypesStore);

  const learningsStore = LearningsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore
    }
  );
  makeInspectable(learningsStore);

  const actionsStore = ActionsStore.create(
    {},
    {
      client,
      dataStore,
      applicationStore,
      benchmarksStore,
      painpointsStore,
      hypothesesStore,
      prototypesStore,
      learningsStore
    }
  );
  makeInspectable(actionsStore);

  const contentListStore = ContentListStore.create(
    {},
    {
      client,
      applicationStore
    }
  );
  makeInspectable(contentListStore);

  const sharedContentStore = SharedContentStore.create(
    {},
    {
      client,
      applicationStore
    }
  );
  makeInspectable(contentListStore);

  const faqPagesStore = FaqPagesStore.create(
    {},
    {
      client,
      applicationStore
    }
  );

  return {
    dataStore,
    applicationStore,
    assistantStore,
    attachmentsStore,
    organizationsStore,
    projectsStore,
    commentsStore,
    bookmarksStore,
    briefingStore,
    painpointsStore,
    benchmarksStore,
    hypothesesStore,
    prototypesStore,
    learningsStore,
    actionsStore,
    contentListStore,
    sharedContentStore,
    faqPagesStore
  };
}
