import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import BriefingLearningsScreen from 'screens/learnings/BriefingLearningsScreen';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_BRIEFING } from 'utils/constants/routes';
import BriefingFormScreen from './BriefingFormScreen';
import BriefingScreen from './BriefingScreen';

export default () => (
  <ContextAwareProjectLayout active="briefing">
    <Switch>
      <Route path={ROUTE_BRIEFING} exact={true} component={BriefingScreen} />
      <Route path={ROUTE_BRIEFING + '/edit'} component={BriefingFormScreen} />

      <Route path={ROUTE_BRIEFING + '/inspiration'} component={BriefingLearningsScreen} />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
