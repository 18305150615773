import React from 'react';

import { ROUTE_LAB } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

class DashboardScreen extends React.Component<HistoryProps> {
  componentDidMount() {
    this.props.history.replace(ROUTE_LAB);
  }

  render() {
    return null;
  }
}

export default DashboardScreen;
