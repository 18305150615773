import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Select from 'components/Inputs/Select';
import NakedList from 'components/NakedList/NakedList';
import ProjectItem from 'components/ProjectItem';
import SimplePopup from 'components/SimplePopup';
import ProjectsListContainer from 'containers/ProjectsListContainer';
import {
  ActionsStoreType,
  countMatrixElements,
  ElementCopyMatrix,
  hasElementMatrixAny
} from 'models/ActionsStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationModelType } from 'models/OrganizationModel';
import { ProjectModelType } from 'models/ProjectModel';

interface PublicCopyToProjectContainerProps {
  onAbort?: () => void;
  onFinish?: () => void;
  elements: ElementCopyMatrix;
  forceNoCurrent?: boolean;
  reduced?: boolean;
  initiallySelectedOrganizationId?: number;
  initiallySelectedProjectId?: number;
}

interface CopyToProjectContainerProps
  extends PublicCopyToProjectContainerProps {
  actionsStore: ActionsStoreType;
  dataStore: DataStoreType;
  applicationStore: ApplicationStoreType;
}

const CopyToProjectContainer = injectIntl(
  inject(
    'actionsStore',
    'dataStore',
    'applicationStore'
  )(
    observer(
      (
        props: CopyToProjectContainerProps &
          PublicCopyToProjectContainerProps &
          WrappedComponentProps
      ) => {
        const {
          actionsStore,
          dataStore,
          applicationStore,
          intl,
          elements,
          onFinish,
          onAbort,
          forceNoCurrent,
          reduced,
          initiallySelectedOrganizationId,
          initiallySelectedProjectId
        } = props;
        const [selectedProjectId, setSelectedProjectId] = useState<
          number | undefined
        >(initiallySelectedProjectId);

        const copyToProject = async () => {
          if (!selectedProjectId || !hasElementMatrixAny(elements)) {
            return;
          }

          const count = await actionsStore.mixedBulkCopyToProject(
            elements,
            selectedProjectId
          );

          if (count === undefined) {
            return;
          }

          if (count > 0) {
            applicationStore.setFlashMessage(
              intl.formatMessage({ id: 'copy flash' }, { count })
            );
          } else {
            applicationStore.setFlashMessage(
              intl.formatMessage(
                { id: 'copy flash error' },
                { count: countMatrixElements(elements) }
              ),
              'error'
            );
          }
        };

        const onCopyClick = async () => {
          await copyToProject();
          onFinish && onFinish();
        };

        const isAdmin = dataStore.isOrgAdmin;

        // ToDo: Make more beautiful, make extract
        const projectListItem = ({
          project,
          org,
          isCurrent
        }: {
          project: ProjectModelType;
          org?: OrganizationModelType;
          isCurrent: boolean;
        }) =>
          !isAdmin && !project.access_level ? null : (
            <li key={project.id}>
              <ProjectItem
                selected={project.id === selectedProjectId}
                disabled={!forceNoCurrent && isCurrent}
                onClick={() => {
                  if (forceNoCurrent || !isCurrent) {
                    setSelectedProjectId(project.id);
                  }
                }}
                title={project.topic || ''}
                subtitle={org?.name || ''}
              />
            </li>
          );

        let targetOrg = dataStore.onlyOwnOrg;

        // users with multiple orgs may select a target org
        const orgs = dataStore.sortedOwnOrgs;

        let orgDropdown;
        if (!targetOrg && orgs.length > 0) {
          // Set initially selected org if it is in orgs
          const initialOrgIdx =
            initiallySelectedOrganizationId &&
            orgs.findIndex((org) => org.id === initiallySelectedOrganizationId);
          const [selectedOrgIdx, setSelectedOrgIdx] = useState<number>(
            initialOrgIdx && initialOrgIdx !== -1 ? initialOrgIdx : 0
          );

          orgDropdown = (
            <Select
              name="orgId"
              normalFont={true}
              value={selectedOrgIdx}
              onChange={({ target }) =>
                setSelectedOrgIdx(parseInt(target.value, 10))
              }
            >
              {orgs.map((org, idx) => (
                <option key={org.id} value={idx}>
                  {org.name}
                </option>
              ))}
            </Select>
          );

          targetOrg = orgs[selectedOrgIdx];
        }

        if (!targetOrg) {
          return null;
        }

        return (
          <SimplePopup
            reduced={reduced}
            onSubmit={onCopyClick}
            onAbort={onAbort}
            isSubmitDisabled={!selectedProjectId}
            isLoading={actionsStore.copyLoadingState === 'loading'}
            headlineTextId="Select project"
            submitTextId="multi copy"
          >
            {orgDropdown}

            <div className="simple-popup__accent-body simple-popup__scrollable-body">
              <ProjectsListContainer
                orgId={targetOrg.id}
                itemContainer={NakedList}
                renderProject={(project, isCurrent) =>
                  projectListItem({ project, org: targetOrg, isCurrent })
                }
              />
            </div>
          </SimplePopup>
        );
      }
    )
  )
);

export default (props: PublicCopyToProjectContainerProps) => (
  // @ts-ignore
  <CopyToProjectContainer {...props} />
);
