
import Icon from 'components/Icon';
import PageSwitchDescriptor, {
  DirectionDescriptor
} from 'types/PageSwitchDescriptor';

interface PageSwitchProps {
  history: any;
  pageSwitch: PageSwitchDescriptor;
  basePath: string;
}

const mkEvent = (
  history: any,
  basePath: string,
  target: DirectionDescriptor
) => {
  return () =>
    history.replace({
      pathname: basePath + target.id + (target.isDraft ? '/edit' : ''),
      state: {
        switch: true
      }
    });
};

export default function PageSwitch({
  history,
  pageSwitch,
  basePath
}: PageSwitchProps) {
  let prev;
  let next;

  if (pageSwitch.prev) {
    prev = (
      <button
        className="page-switch__control"
        onClick={mkEvent(history, basePath, pageSwitch.prev)}
        tabIndex={-1}
      >
        <Icon name="chevron-left" />
      </button>
    );
  }

  if (pageSwitch.next) {
    next = (
      <>
        {!prev && (
          <div className="page-switch__control page-switch__control--hidden" />
        )}
        <button
          className="page-switch__control"
          onClick={mkEvent(history, basePath, pageSwitch.next)}
          tabIndex={-1}
        >
          <Icon name="chevron-right" />
        </button>
      </>
    );
  }

  if (!prev && !next) {
    return null;
  }

  return (
    <div className="page-switch">
      {prev}
      {next}
    </div>
  );
}
