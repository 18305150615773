interface CounterLabelProps {
  itemNumber: React.ReactNode | number;
  name: React.ReactNode | string;
  lastItem: boolean;
}

export default function CounterLabel({
  itemNumber,
  name,
  lastItem
}: CounterLabelProps) {
  return (
    <div className="counter-label">
      {itemNumber}x {name}
      {lastItem}
    </div>
  );
}
