import { IAnyModelType, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';

import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const GPTRequestCounterModel = types
  .model('GPTRequestCounterModel', {
    id: types.identifierNumber,
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),
    author: types.maybe(FluidUserModel),
    target_amount: types.maybe(types.number),
    current_amount: types.maybe(types.number),
    status: types.maybe(types.string),
    request_type: types.maybe(types.string),
  })

export const createGPTRequestCounterModel = (data?: any): GPTRequestCounterModelType => {
  return GPTRequestCounterModel.create({
    id: mandatoryId(data?.id),
    project: data?.project ? createFluidProjectModel(data.project) : undefined,
    author: createFluidUserModel(data?.author),
    target_amount: data?.target_amount || undefined,
    current_amount: data?.current_amount || 0,
    status: data?.status || undefined,
    request_type: data?.request_type || undefined,
  });
};

export type GPTRequestCounterModelType = typeof GPTRequestCounterModel.Type;
export default GPTRequestCounterModel;
