import { flow, getEnv, types } from 'mobx-state-tree';

import { createMapWithTransform } from 'utils/store/createMap';
import FaqPageModel, {
  FaqPageModelType,
  createFaqPageModel
} from './FaqPageModel';
import { ItemLoadingStateEnum } from './LoadingStateEnums';
import { AdvancedStoreEnv } from './StoreEnv';
import sortByField from 'utils/sort/field';

const FaqPagesStore = types
  .model('FaqPagesStore', {
    loadingState: types.maybe(ItemLoadingStateEnum),
    faqPagesList: types.maybe(types.map(FaqPageModel)),
    faqPageItem: types.maybe(FaqPageModel)
  })
  .actions((self) => {
    const getFaqPages = flow(function* (orgId?: number, projId?: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.loadingState = 'loading';
        const result: any = yield client.getFaqPages();

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        console.log('result', result);
        self.faqPagesList = createMapWithTransform(result, createFaqPageModel);

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('FaqPagesStore | getFaqPages', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.loadingState = 'not_found';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          return undefined;
        }

        self.loadingState = 'load_error';
        return undefined;
      }
    });

    const getFaqPage = flow(function* (faqPage: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.loadingState = 'loading';

        const result: any = yield client.getFaqPage(faqPage);

        if (!result) {
          throw new Error('No response from server');
        }

        self.faqPageItem = createFaqPageModel(result);

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('FaqPagesStore | getFaqPage', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.loadingState = 'not_found';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return undefined;
        }

        self.loadingState = 'load_error';
        return undefined;
      }
    });

    return {
      getFaqPages,
      getFaqPage
    };
  })
  .views((self) => {
    return {
      get isLoading(): boolean {
        return self.loadingState === 'loading';
      },
      get isError(): boolean {
        return (
          self.loadingState === 'load_error' ||
          self.loadingState === 'access_denied' ||
          self.loadingState === 'not_found'
        );
      },
      get faqUpdatePages(): FaqPageModelType[] {
        const faqPages: FaqPageModelType[] = [];

        if (!self.faqPagesList || self.faqPagesList.size === 0) {
          return faqPages;
        }

        for (const faqPage of self.faqPagesList.values()) {
          if (faqPage.section === 'updates') {
            faqPages.push(faqPage);
          } else {
            continue;
          }
        }

        return faqPages.reverse().sort(sortByField('sorting'));
      },
      get faqsBySection(): {
        section?: string;
        faqPages: FaqPageModelType[];
      }[] {
        const faqBySection: {
          section?: string;
          faqPages: FaqPageModelType[];
        }[] = [];

        if (!self.faqPagesList || self.faqPagesList.size === 0) {
          return faqBySection;
        }

        for (const faqPage of self.faqPagesList.values()) {
          if (!faqPage.title) {
            continue;
          }
          if (faqPage.section === 'updates') {
            continue;
          }
          const section = faqPage.section;
          const sectionIndex = faqBySection.findIndex(
            (item) => item.section === section
          );

          if (sectionIndex === -1) {
            faqBySection.push({ section, faqPages: [faqPage] });
          } else {
            faqBySection[sectionIndex].faqPages.push(faqPage);
          }
        }

        faqBySection.forEach((section) => {
          section.faqPages.sort(sortByField('sorting'));
        });

        return faqBySection;
      }
    };
  });

export type FaqPagesStoreType = typeof FaqPagesStore.Type;
export default FaqPagesStore;
