import { Link } from 'react-router-dom';

import classNames from 'classnames';
import CardTop from 'components/CardTop';
import DestroyButton from 'components/DestroyButton';

interface RainbowCardWithTopProps {
  children?: React.ReactNode;
  logo?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  to?: string;
  checked?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
  onRemoveClick?: () => void;
  src?: string | string[];
  alt?: string;
  gray?: boolean;
  sideLabel?: string;
}

export const RainbowCardWithTop = ({
  children,
  logo,
  title,
  subtitle,
  to,
  src,
  alt = 'screenshot',
  checked,
  onCheckboxChange,
  onRemoveClick,
  sideLabel,
  gray
}: RainbowCardWithTopProps) => {
  const hasMultiple = Array.isArray(src) && src.length > 1;
  const hasOne =
    (Array.isArray(src) && src.length === 1) || typeof src === 'string';

  const rainbowCardWithTopClasses = classNames('rainbow-card', {
    'rainbow-card--with-top': title,
    'rainbow-card--gray': gray,
    'rainbow-card--with-image': hasOne && !hasMultiple,
    'rainbow-card--with-multiple-image': hasMultiple,
    'rainbow-card--draft': !!sideLabel
  });

  const renderScreenInPhone = () => {
    if (!src) {
      return null;
    }
    if (Array.isArray(src)) {
      if (!src.length) {
        return null;
      }

      if (src.length > 1) {
        return (
          <div className="rainbow-card__multiple-images">
            <div className="rainbow-card__image">
              <figure className="rainbow-card__phone">
                <img alt={alt} src={src[0]} />
              </figure>
            </div>
            <div className="rainbow-card__image rainbow-card__image--second">
              <figure className="rainbow-card__phone">
                <img alt={alt} src={src[1]} />
              </figure>
            </div>
            {src[2] && (
              <div className="rainbow-card__image rainbow-card__image--third">
                <figure className="rainbow-card__phone">
                  <img alt={alt} src={src[2]} />
                </figure>
              </div>
            )}
          </div>
        );
      }

      src = src[0];
    }

    return (
      <div className="rainbow-card__image">
        <figure className="rainbow-card__phone">
          <img alt={alt} src={src} />
        </figure>
      </div>
    );
  };

  const cardLink = to ? <Link className="rainbow-card__link" to={to} /> : null;

  const renderDraft = () => {
    if (!sideLabel) {
      return null;
    }
    return <div className="rainbow-card__draft">{sideLabel}</div>;
  };

  const renderCheckbox = () => {
    if (!onCheckboxChange) {
      return null;
    }
    return (
      <div className="card-checkbox">
        <label>
          <input
            className="card-checkbox__box"
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
            type="checkbox"
          />
        </label>
      </div>
    );
  };

  const renderTop = () => {
    if (!title) {
      return null;
    }
    return <CardTop logo={logo} title={title} subtitle={subtitle} />;
  };

  return (
    <article className={rainbowCardWithTopClasses}>
      {renderScreenInPhone()}
      {renderTop()}
      <div className="rainbow-card__body">
        {onRemoveClick && (
          <div className="rainbow-card__remove-button">
            <DestroyButton onClick={onRemoveClick} />
          </div>
        )}
        {children}
        {renderCheckbox()}
        {cardLink}
      </div>
      {renderDraft()}
    </article>
  );
};

export default RainbowCardWithTop;
