import ActionOverlaySelectButton from 'components/ActionOverlaySelectButton';
import UserAvatar from 'components/Avatar/UserAvatar';
import Bookmark from 'components/Bookmark';
import CardMenu from 'components/CardMenu';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import CompanyLogo from 'components/CompanyLogo';
import EditableCard from 'components/EditableCard/EditableCard';
import EditMenu from 'components/EditMenu';
import ErrorMessage from 'components/ErrorMessage';
import FilePreview from 'components/FilePreview';
import Form from 'components/Form';
import FormChangeHandler from 'components/FormChangeHandler/FormChangeHandler';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import InputWithDropdown from 'components/Inputs/InputWithDropdown';
import Textarea from 'components/Inputs/Textarea';
import ContentWrapper from 'components/Layout/ContentWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import MenuButton from 'components/MenuButton';
import MobilePreview from 'components/MobilePreview';
import PageHeader from 'components/PageHeader';
import PageLogoEdit from 'components/PageLogoEdit';
import PageLogoHeader from 'components/PageLogoHeader';
import PageSwitch from 'components/PageSwitch';
import SimplePopup from 'components/SimplePopup';
import TagList from 'components/TagList';
import UploadButton from 'components/UploadButton';
import UploadMobileButton from 'components/UploadButton/UploadMobileButton';
import UploadedImage from 'components/UploadedImage';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { inject, observer } from 'mobx-react';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { BenchmarksStoreType } from 'models/BenchmarksStore';
import { DataStoreType } from 'models/DataStore';
import { tagsListToArray } from 'models/TagListModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Prompt } from 'react-router';
import { Link } from 'react-router-dom';
import PainpointsListItem from 'screens/painpoints/PainpointsListScreen/PainpointsListItem';
import { ROUTE_PROFILE } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import { idFromProps } from 'utils/history/param-from-props';
import { scrollToTop } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable: jsx-wrap-multiline

interface PublicBenchmarksDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
  create?: boolean;
}

interface BenchmarksDetailContainerProps
  extends PublicBenchmarksDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  benchmarksStore: BenchmarksStoreType;
  actionsStore: ActionsStoreType;
  form: FormType;
}

interface BenchmarksDetailContainerState {
  editTitle?: boolean;
  editDescription?: boolean;
  editPurpose?: boolean;
  suggestionsFetched: boolean;
}

@inject('applicationStore', 'dataStore', 'benchmarksStore', 'actionsStore')
@observer
class BenchmarksDetailContainer extends React.Component<
  BenchmarksDetailContainerProps & HistoryProps,
  BenchmarksDetailContainerState
> {
  state: BenchmarksDetailContainerState = {
    suggestionsFetched: false
  };

  componentDidMount() {
    // Reset suggestion
    this.props.dataStore.setSuggestions(undefined);

    if (this.props.create) {
      this.props.dataStore.setBenchmarkItem(undefined);
      this.props.form.reset();
      return;
    }

    const id = idFromProps(this.props);
    if (!id) {
      return;
    }

    const {
      dataStore: { benchmarkItem }
    } = this.props;
    if (
      isPush(this.props.history) ||
      !benchmarkItem ||
      benchmarkItem.id !== id
    ) {
      this.loadBenchmark(id);
    }
  }

  componentDidUpdate(
    prevProps: PublicBenchmarksDetailContainerProps & HistoryProps
  ) {
    const id = idFromProps(this.props);
    const prevId = idFromProps(prevProps);

    // Reset suggestion
    // this.props.dataStore.setSuggestions(undefined);

    if (this.props.create && !prevProps.create) {
      this.props.dataStore.setBenchmarkItem(undefined);
      this.props.form.reset();
      return;
    }

    if (id && id !== prevId) {
      this.loadBenchmark(id);
    }
  }

  load() {
    const id = idFromProps(this.props);
    if (id) {
      this.loadBenchmark(id);
    }
  }

  async loadBenchmark(id: number) {
    const { form, benchmarksStore } = this.props;

    form.reset();

    const benchmark = await benchmarksStore.getBenchmark(id);

    if (benchmark) {
      if (this.props.edit) {
        form.setField('headline', benchmark.headline);
        form.setField('company_name', benchmark.company_name);
        form.setField('description', benchmark.description);
        form.setField('purpose', benchmark.purpose);
      } else if (benchmark.publish_state === 'draft') {
        this.props.history.replace(benchmark.id + '/edit');
      }

      form.setField('tags', tagsListToArray(benchmark.tags));
    }
  }

  async save(buttonSave = false, publish = false) {
    const { dataStore, benchmarksStore, form, create } = this.props;

    const patch: any = {
      ...form.values
    };

    delete patch.copied_logo_url;

    if (publish) {
      patch.publish_state = 'active';
    }

    try {
      let item = dataStore.benchmarkItem;
      if (create && !item) {
        item = await benchmarksStore.createBenchmark(patch, true);
      } else {
        if (!item || (!create && item.id !== idFromProps(this.props))) {
          return;
        }

        // TODO update form after update?
        item = await benchmarksStore.updateBenchmark(item.id, patch);

        this.removeSuggestedLogo();
      }

      if (buttonSave) {
        if (benchmarksStore.isItemSaveError) {
          this.displayError();
        } else {
          this.props.history.replace(dataStore.contextUri + '/benchmarks');
        }
      }
    } catch (error: any) {
      if (handleFormError(form, error)) {
        scrollToTop();
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { dataStore, benchmarksStore } = this.props;
    const item = dataStore.benchmarkItem;

    if (!item) {
      return;
    }

    try {
      await benchmarksStore.updateBenchmark(item.id, {
        tags: newTags || []
      });
    } catch (e) {
      // TODO How to handle this?
    }
  }

  async remove() {
    const { intl, benchmarksStore, dataStore, history } = this.props;

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage({ id: 'remove benchmarks confirm' }, { count: 1 })
      )
    ) {
      return;
    }

    if (!dataStore.benchmarkItem) {
      return;
    }

    try {
      await benchmarksStore.deleteBenchmark(dataStore.benchmarkItem.id);

      history.replace(dataStore.contextUri + '/benchmarks');
    } catch (error: any) {
      this.displayError('remove');
    }
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    const { form, dataStore } = this.props;

    if (!newState) {
      form.setLoading(false);
      return;
    }

    if (newState === 'saving' && !dataStore.benchmarkItem?.id) {
      // item has not been saved yet, so we need to wait for the upload before we can reenable form
      form.setLoading(true);
    }
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { benchmarksStore, dataStore } = this.props;

    if (!attachment) {
      return;
    }

    if (dataStore.benchmarkItem) {
      dataStore.benchmarkItem.attachments.put(attachment);
    } else if (attachment.element_id) {
      benchmarksStore.initializeItem({
        id: attachment.element_id,
        attachments: [attachment]
      });
    }
  }

  attachmentRemoved(id: number) {
    const {
      dataStore: { benchmarkItem }
    } = this.props;
    if (!benchmarkItem) {
      return;
    }

    benchmarkItem.attachments.delete(id);
  }

  async saveFieldUpdate(names: string[]) {
    const { form, benchmarksStore, dataStore } = this.props;

    if (!dataStore.benchmarkItem) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await benchmarksStore.updateBenchmark(dataStore.benchmarkItem.id, patch);

      if (benchmarksStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  editTitle() {
    const { form, dataStore } = this.props;
    const item = dataStore.benchmarkItem;

    if (!item) {
      return;
    }

    form.setField('headline', item.headline);
    form.setField('company_name', item.company_name);

    form.setEditing('title');
  }

  isFromEmptyState() {
    return this.props.location.query?.empty === 'yes';
  }

  links() {
    const { dataStore, intl } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: false,
        to: contextUri + '/benchmarks',
        content: intl.formatMessage({ id: 'Benchmarks' })
      },
      {
        isLink: true,
        to: contextUri + '/benchmarks/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderPageSwitch() {
    if (!this.props.location?.state?.switch) {
      return;
    }

    const { dataStore, benchmarksStore, history } = this.props;
    return (
      <PageSwitch
        history={history}
        basePath={dataStore.contextUri + '/benchmarks/'}
        pageSwitch={benchmarksStore.pageSwitch(dataStore.benchmarkItem)}
      />
    );
  }

  renderPage(content: any) {
    const {
      dataStore: {
        benchmarkItem,
        contextUri,
        currentOrganizationId,
        currentProjectId
      },
      location,
      actionsStore,
      actionsStore: { selectionSet },
      intl,
      history
    } = this.props;

    const showClose = !location?.state?.isRedirect;
    const checked: number[] = actionsStore.selectedIds.Benchmark;
    const isSelected =
      (benchmarkItem && checked.indexOf(benchmarkItem.id) > -1) || false;

    return (
      <>
        {content}

        {showClose && (
          <OverlayWrapper topRight={true} twoButtons={true}>
            <CloseButton
              label={intl.formatMessage({ id: 'Close' })}
              iconName="cross"
              onClick={() => history.push(contextUri + '/benchmarks')}
            />
            <ActionOverlaySelectButton
              onClick={() => {
                const organizationId = currentOrganizationId!;
                const projectId = currentProjectId!;

                selectionSet(
                  {
                    Benchmark: [
                      {
                        id: benchmarkItem?.id!,
                        organizationId: organizationId,
                        projectId,
                        publishState: benchmarkItem?.publish_state
                      }
                    ]
                  },
                  !isSelected
                );
              }}
              selected={isSelected}
            />
          </OverlayWrapper>
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(
      <>
        <PageHeader titleId="Benchmarks" />
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        <PageHeader titleId="Benchmarks" />
        <ErrorMessage
          state={this.props.benchmarksStore.itemLoadingState}
          onRetry={() => this.load()}
        />
      </>
    );
  }

  async getCompanyNameSuggestions(
    content: string,
    benchmarkStore: BenchmarksStoreType,
    dataStore: DataStoreType
  ) {
    if (content.trim().length < 3) {
      dataStore.setSuggestions(undefined);
      return;
    }

    if (!dataStore.suggestions) {
      await benchmarkStore.fetchSuggestions(content.substring(0, 3));
      this.setState({ suggestionsFetched: true });
    }
  }

  async getSuggestionLogo(companyName: string) {
    const { form, benchmarksStore } = this.props;

    if (!companyName) {
      return;
    }

    form.setLoading(true);

    const result = await benchmarksStore.getBenchmarkLogoByCompanyName(
      companyName
    );

    if (!result?.id || !result?.logo?.resource_urls) {
      form.setLoading(false);
      return;
    }

    form.setField('logo_benchmark_id', result.id);
    form.setField(
      'copied_logo_url',
      result.logo.resource_urls.small || result.logo.resource_urls.large
    );

    form.setLoading(false);
  }

  removeSuggestedLogo() {
    this.props.form.setField('logo_benchmark_id', undefined);
    this.props.form.setField('copied_logo_url', undefined);
  }

  renderEditMode() {
    const { benchmarksStore, dataStore, form, intl } = this.props;
    const item = dataStore.benchmarkItem;

    let attachment: AttachmentModelType | undefined;
    let logo: AttachmentModelType | undefined;
    if (item) {
      attachment = item.attachments.firstOfType('screen');
      logo = item.attachments.firstOfType('logo');
    }

    let copiedLogo: any;
    if (form.values.copied_logo_url) {
      copiedLogo = (
        <FilePreview
          small={true}
          logo={true}
          imgUrl={form.values.copied_logo_url}
          alt="Attachment"
          onDestroy={() => this.removeSuggestedLogo()}
        />
      );
    }

    const itemIsActive = item && item.publish_state === 'active';
    return this.renderPage(
      <>
        {((itemIsActive && benchmarksStore.isItemSaving) || form.loading) && (
          <Loading />
        )}

        {this.renderPageSwitch()}

        <FormChangeHandler
          halted={itemIsActive}
          onSave={() => this.save()}
          isSaving={benchmarksStore.isItemSaving}
        >
          {({ onTextChanged, hasUnsavedChanges, isSaving }) => (
            <>
              {itemIsActive && hasUnsavedChanges && (
                <Prompt
                  message={intl.formatMessage({ id: 'unsaved prompt' })}
                />
              )}
              <Form loading={form.loading} onSubmit={() => this.save(true)}>
                <PageHeader titleId="Benchmarks" />

                <ContentWrapper>
                  <FormTagList
                    form={form}
                    field="tags"
                    allowAdd={true}
                    onChanged={onTextChanged}
                  />

                  {this.isFromEmptyState() && (
                    <div style={{ marginBottom: '3em' }}>
                      <LinkRow links={this.links()} />
                    </div>
                  )}

                  <RainbowEditWrapper>
                    <RainbowEditWrapper.Image>
                      {attachment ? (
                        <UploadedFileContainer
                          attachment={attachment}
                          onAttachmentRemoved={(id) =>
                            this.attachmentRemoved(id)
                          }
                          key={attachment.id}
                        >
                          {(props) => <UploadedMobileImage {...props} />}
                        </UploadedFileContainer>
                      ) : (
                        <UploadContainer
                          attachmentType="screen"
                          elementType={ElementType.Benchmark}
                          elementId={item?.id}
                          acceptVideo={true}
                          waitForId={
                            hasUnsavedChanges || benchmarksStore.isItemSaving
                          }
                          onLoadingStateChanged={(s) =>
                            this.uploadLoadingStateChanged(s)
                          }
                          onAttachmentReceived={(a) =>
                            this.attachmentReceived(a)
                          }
                        >
                          {(props: UploadContainerChildProps) => (
                            <UploadMobileButton {...props} />
                          )}
                        </UploadContainer>
                      )}
                    </RainbowEditWrapper.Image>
                    <RainbowEditWrapper.Body>
                      <CardWrapper>
                        <PageLogoEdit
                          upload={
                            copiedLogo ? (
                              copiedLogo
                            ) : logo ? (
                              <UploadedFileContainer
                                attachment={logo}
                                onAttachmentRemoved={(id) =>
                                  this.attachmentRemoved(id)
                                }
                                key={logo.id}
                              >
                                {(props) => (
                                  <UploadedImage logo={true} {...props} />
                                )}
                              </UploadedFileContainer>
                            ) : (
                              <UploadContainer
                                attachmentType="logo"
                                elementType={ElementType.Benchmark}
                                elementId={item?.id}
                                waitForId={
                                  hasUnsavedChanges ||
                                  benchmarksStore.isItemSaving
                                }
                                onLoadingStateChanged={(s) =>
                                  this.uploadLoadingStateChanged(s)
                                }
                                onAttachmentReceived={(a) =>
                                  this.attachmentReceived(a)
                                }
                              >
                                {(props: UploadContainerChildProps) => (
                                  <UploadButton logo={true} {...props} />
                                )}
                              </UploadContainer>
                            )
                          }
                        >
                          <Input
                            headline={true}
                            name="headline"
                            placeholder={intl.formatMessage({
                              id: 'Insert headline'
                            })}
                            {...form.bindInput('headline', onTextChanged)}
                          />

                          <InputWithDropdown
                            onChange={(e) => {
                              const name = e.target.value || '';
                              form.setField('company_name', name);

                              this.getCompanyNameSuggestions(
                                name,
                                benchmarksStore,
                                dataStore
                              );

                              onTextChanged();
                            }}
                            suggestions={benchmarksStore.suggestions}
                            subheadline={true}
                            name="company_name"
                            placeholder={intl.formatMessage({
                              id: 'Insert company name'
                            })}
                            value={form.values.company_name}
                            onSuggestionSelected={(_id, name) => {
                              form.setField('company_name', name);
                              this.getSuggestionLogo(name);

                              onTextChanged();
                            }}
                          />
                        </PageLogoEdit>
                      </CardWrapper>

                      <CardWrapper>
                        <Textarea
                          label={
                            <FormattedMessage id="solution short description" />
                          }
                          name="description"
                          {...form.bindInput('description', onTextChanged)}
                        />
                      </CardWrapper>

                      <CardWrapper>
                        <Textarea
                          label={<FormattedMessage id="will help us how" />}
                          name="purpose"
                          {...form.bindInput('purpose', onTextChanged)}
                        />
                      </CardWrapper>

                      <PositionWrapper end={true}>
                        {itemIsActive ? (
                          <MainButton type="submit">
                            <FormattedMessage id="Save" />
                          </MainButton>
                        ) : (
                          <RowWrapper gap="1em" alignRight={true}>
                            <MainButton type="submit" secondary={true}>
                              {isSaving && <Loading />}
                              <FormattedMessage id="Save draft" />
                            </MainButton>
                            <MainButton
                              type="button"
                              onClick={() => this.save(true, true)}
                            >
                              <FormattedMessage id="Publish" />
                            </MainButton>
                          </RowWrapper>
                        )}
                      </PositionWrapper>
                    </RainbowEditWrapper.Body>
                  </RainbowEditWrapper>
                </ContentWrapper>
              </Form>
            </>
          )}
        </FormChangeHandler>
      </>
    );
  }

  renderViewMode() {
    const { intl, form, dataStore, benchmarksStore } = this.props;

    const item = this.props.dataStore.benchmarkItem;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author);

    const attachment = item.attachments.firstOfType('screen');
    const logo = item.attachments.firstOfType('logo');

    let phone;
    if (mayEdit) {
      phone = attachment ? (
        <>
          <UploadedFileContainer
            attachment={attachment}
            onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
            key={attachment.id}
          >
            {(props) => <UploadedMobileImage {...props} />}
          </UploadedFileContainer>
          {item.painpoint && (
            <div style={{ width: 'calc(100% + 1.1rem)' }}>
              <PainpointsListItem
                painpoint={item.painpoint}
                contextUri={dataStore.contextUri}
                readOnly={true}
              />
            </div>
          )}
        </>
      ) : (
        <UploadContainer
          attachmentType="screen"
          elementType={ElementType.Benchmark}
          elementId={item.id}
          acceptVideo={true}
          onLoadingStateChanged={(s) => this.uploadLoadingStateChanged(s)}
          onAttachmentReceived={(a) => this.attachmentReceived(a)}
        >
          {(props: UploadContainerChildProps) => (
            <UploadMobileButton {...props} />
          )}
        </UploadContainer>
      );
    } else {
      phone =
        attachment && attachment.resource_urls ? (
          <UploadedMobileImage attachment={attachment} />
        ) : (
          <MobilePreview alt="Empty attachment" />
        );
    }

    return this.renderPage(
      <>
        {benchmarksStore.isItemSaving && form.editing !== 'title' && (
          <Loading />
        )}

        <PageHeader
          title="Benchmarks"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
              subtitle={item.company_name || ''}
              logo={
                logo &&
                logo.resource_urls && (
                  <CompanyLogo
                    imgUrl={logo.resource_urls.small}
                    alt="Logo"
                    big={true}
                  />
                )
              }
              onEditClick={!mayEdit ? undefined : () => this.editTitle()}
            />
          }
        />

        {this.renderPageSwitch()}

        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            <TagList tags={item.tags} />
          )}
          <RainbowEditWrapper>
            <RainbowEditWrapper.Image>{phone}</RainbowEditWrapper.Image>
            <RainbowEditWrapper.Body>
              <EditableCard
                form={form}
                name="description"
                value={item.description}
                label={<FormattedMessage id="solution short description" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['description'])}
              />

              <EditableCard
                form={form}
                name="purpose"
                value={item.purpose}
                label={<FormattedMessage id="will help us how" />}
                editable={mayEdit}
                saveOnBlur={false}
                onSave={() => this.saveFieldUpdate(['purpose'])}
                extra={
                  <CardMenu
                    avatar={
                      <UserAvatar
                        user={item.author}
                        isAiRobot={item.created_by_ai}
                      />
                    }
                  >
                    <Bookmark
                      elementType="Benchmark"
                      elementId={item.id}
                      count={item.bookmarks_count}
                      id={item.bookmark_id}
                    />
                    {mayEdit && (
                      <EditMenu
                        bottomLeft={true}
                        label={intl.formatMessage({ id: 'Edit' })}
                      >
                        {
                          <MenuButton
                            label={intl.formatMessage({ id: 'Edit' })}
                            description={intl.formatMessage({
                              id: 'benchmark edit desc'
                            })}
                            iconName="pen"
                            onClick={() => this.editTitle()}
                          />
                        }
                        <MenuButton
                          label={intl.formatMessage({ id: 'Remove' })}
                          description={intl.formatMessage({
                            id: 'benchmark remove desc'
                          })}
                          iconName="bin"
                          onClick={() => this.remove()}
                        />
                      </EditMenu>
                    )}
                    {item.created_by_ai && item.author && (
                      <span className="ai-author">
                        Generated by{' '}
                        <Link
                          to={ROUTE_PROFILE.replace(
                            ':id',
                            item.author.id!.toString()
                          )}
                        >
                          <span className="underline">
                            {item.author.fullName}
                          </span>
                        </Link>
                      </span>
                    )}
                  </CardMenu>
                }
              />
            </RainbowEditWrapper.Body>
          </RainbowEditWrapper>
          {item.publish_state !== 'draft' && (
            <CommentsContainer
              elementType={ElementType.Benchmark}
              element={item}
              textId="comment text Benchmark"
            />
          )}
        </ContentWrapper>

        {form.editing === 'title' && (
          <Form disabled={benchmarksStore.isItemSaving}>
            <SimplePopup
              onAbort={() => form.setEditing()}
              onSubmit={() =>
                this.saveFieldUpdate(['headline', 'company_name'])
              }
              isLoading={benchmarksStore.isItemSaving}
            >
              <PageLogoEdit
                upload={
                  logo ? (
                    <UploadedFileContainer
                      attachment={logo}
                      onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                      key={logo.id}
                    >
                      {(props) => <UploadedImage logo={true} {...props} />}
                    </UploadedFileContainer>
                  ) : (
                    <UploadContainer
                      attachmentType="logo"
                      elementType={ElementType.Benchmark}
                      elementId={item.id}
                      onLoadingStateChanged={(s) =>
                        this.uploadLoadingStateChanged(s)
                      }
                      onAttachmentReceived={(a) => this.attachmentReceived(a)}
                    >
                      {(props: UploadContainerChildProps) => (
                        <UploadButton logo={true} {...props} />
                      )}
                    </UploadContainer>
                  )
                }
              >
                <Input
                  autoFocus={true}
                  headline={true}
                  name="headline"
                  placeholder={intl.formatMessage({
                    id: 'Insert headline'
                  })}
                  {...form.bindInput('headline')}
                />

                <Input
                  subheadline={true}
                  name="company_name"
                  placeholder={intl.formatMessage({
                    id: 'Insert company name'
                  })}
                  {...form.bindInput('company_name')}
                />
              </PageLogoEdit>
            </SimplePopup>
          </Form>
        )}
        {!benchmarksStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              dataStore.currentOrganizationId
            }
            copyInitiallySelectedProjectId={dataStore.currentProjectId}
            showAiBenchmark={true}
            showAiBriefing={true}
            showCopy={true}
            showDelete={
              this.props.actionsStore.selectedFromProjectIds.length === 1 &&
              this.props.actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={true}
          />
        )}
      </>
    );
  }

  render() {
    const { benchmarksStore, create, edit } = this.props;

    if (benchmarksStore.isItemLoading) {
      return this.renderLoading();
    }

    if (benchmarksStore.isItemLoadError) {
      return this.renderError();
    }

    if (create || edit) {
      return this.renderEditMode();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicBenchmarksDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <BenchmarksDetailContainer {...props} form={form} />;
});
