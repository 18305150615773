import classNames from 'classnames';

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface MenuButtonProps extends HtmlButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
}

export default function ActionOverlaySelectButton({
  onClick,
  selected
}: MenuButtonProps) {
  return (
    <button
      className={classNames('action-overlay-select-button', {
        'action-overlay-select-button--selected': selected
      })}
      onClick={onClick}
    >
      ✓
    </button>
  );
}
