import { FormattedMessage } from 'react-intl';

import CompanyLogo from 'components/CompanyLogo';
import RainbowCardCompact from 'components/RainbowCard/RainbowCardCompact';
import { ROUTE_ASSISTANT } from 'utils/constants/routes';
import SidebarItem from '../SidebarItem';
import SidebarItemContent from '../SidebarItemContent';
import SidebarItemHeader from '../SidebarItemHeader';
import SidebarMarker from '../SidebarMarker';
import { SidebarItemProps } from './item-types';

export const SidebarSessionBenchmarks = ({
  session,
  showHeader
}: SidebarItemProps & {
  currentPathWithSearch?: string;
}) => {
  const benchmarks = session?.sortedSessionBenchmarks;

  if (!benchmarks?.length) {
    if (showHeader) {
      return (
        <SidebarMarker type="benchmarks">
          <SidebarItem>
            <SidebarItemHeader>
              <FormattedMessage id="assistant sidebar benchmarks" />
            </SidebarItemHeader>
          </SidebarItem>
        </SidebarMarker>
      );
    }

    return <SidebarMarker type="benchmarks" />;
  }

  return (
    <SidebarMarker type="benchmarks">
      <SidebarItem>
        <SidebarItemHeader date={session!.lastUpdate}>
          <FormattedMessage id="assistant sidebar benchmarks" />
        </SidebarItemHeader>

        {benchmarks.map((benchmark, idx) => {
          const attachment = benchmark.attachments.firstOfType('screen');
          const logo = benchmark.attachments.firstOfType('logo');

          return (
            <SidebarItemContent key={benchmark.id}>
              <RainbowCardCompact
                to={
                  ROUTE_ASSISTANT +
                  '/benchmarks/' +
                  session!.id +
                  '/step2?sessionBenchmark=' +
                  (idx + 1)
                }
                simple={true}
                company={benchmark.company_name}
                title={benchmark.headline}
                src={attachment?.resource_urls?.small}
                logo={
                  logo?.resource_urls?.small && (
                    <CompanyLogo imgUrl={logo.resource_urls.small} alt="Logo" />
                  )
                }
              />
            </SidebarItemContent>
          );
        })}
      </SidebarItem>
    </SidebarMarker>
  );
};

export default SidebarSessionBenchmarks;
