
interface MainNavProps {
  avatar?: React.ReactNode;
  children: React.ReactNode;
}

export default function MainNav(props: MainNavProps) {
  return (
    <nav aria-label="Rubriken" className="main-nav">
      <ul className="main-nav__list">{props.children}</ul>
    </nav>
  );
}
