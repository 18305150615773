import { CSSProperties } from 'react';

interface ClusterProps {
  children: React.ReactNode;
  gap?: string;
  gapVertical?: string;
  justification?: string;
}

export default function Cluster({
  children,
  justification = 'start',
  gap = '1rem',
  gapVertical = gap
}: ClusterProps) {
  const style = {
    '--cluster-gap': gap,
    '--cluster-gap-vertical': gapVertical,
    '--cluster-justification': justification
  } as CSSProperties;

  return (
    <div className="cluster" style={style}>
      <div>{children}</div>
    </div>
  );
}
