import { types } from 'mobx-state-tree';

export enum ProjectLanguageType {
  english = 'english',
  deutsch = 'deutsch',
}

export const ProjectLanguageTypeEnum = types.enumeration(Object.values(ProjectLanguageType));

export type ProjectLanguageTypeEnumType = typeof ProjectLanguageTypeEnum.Type;
export default ProjectLanguageTypeEnum;