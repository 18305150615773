import { flow, getEnv, types } from 'mobx-state-tree';

import { ElementType } from './ApiElementTypeEnum';
import BenchmarkModel, {
  BenchmarkModelType,
  createBenchmarkModel
} from './BenchmarkModel';
import BriefingModel, {
  BriefingModelType,
  createBriefingModel
} from './BriefingModel';
import { BookmarkableEnumType } from './DataStore';
import LearningModel, {
  createLearningModel,
  LearningModelType
} from './LearningModel';
import HypothesisModel, {
  createHypothesisModel,
  HypothesisModelType
} from './HypothesisModel';
import { ListLoadingStateEnum } from './LoadingStateEnums';
import PainpointModel, {
  createPainpointModel,
  PainpointModelType
} from './PainpointModel';
import PrototypeModel, {
  createPrototypeModel,
  PrototypeModelType
} from './PrototypeModel';
import { StoreEnv } from './StoreEnv';

const LIMIT = 10;

export type SearchableElementType =
  | ElementType.Benchmark
  | ElementType.Briefing
  | ElementType.Hypothesis
  | ElementType.Painpoint
  | ElementType.Prototype
  | ElementType.Learning;

export type RecommendableElementType =
  | ElementType.Benchmark
  | ElementType.Hypothesis
  | ElementType.Prototype
  | ElementType.Learning;

const sliceWithLimit = <T>(arr?: T[], limit?: number): T[] => {
  const list: T[] = [];
  if (!arr || !arr.length) {
    return list;
  }

  let i = 0;
  for (const briefing of arr) {
    if (limit) {
      i++;
      if (i > limit) {
        break;
      }
    }

    list.push(briefing);
  }

  return list;
};

const ContentListStore = types
  .model('ContentListStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),

    listType: types.maybe(types.string),
    listQuery: types.maybe(types.string),

    briefings: types.map(BriefingModel),
    briefingsList: types.array(types.reference(BriefingModel)),
    briefingsCount: types.maybe(types.number),
    painpoints: types.map(PainpointModel),
    painpointsList: types.array(types.reference(PainpointModel)),
    painpointsCount: types.maybe(types.number),
    benchmarks: types.map(BenchmarkModel),
    benchmarksList: types.array(types.reference(BenchmarkModel)),
    benchmarksCount: types.maybe(types.number),
    hypotheses: types.map(HypothesisModel),
    hypothesesList: types.array(types.reference(HypothesisModel)),
    hypothesesCount: types.maybe(types.number),
    prototypes: types.map(PrototypeModel),
    prototypesList: types.array(types.reference(PrototypeModel)),
    prototypesCount: types.maybe(types.number),
    learnings: types.map(LearningModel),
    learningsList: types.array(types.reference(LearningModel)),
    learningsCount: types.maybe(types.number),

    briefingsPage: types.maybe(types.number),
    painpointsPage: types.maybe(types.number),
    benchmarksPage: types.maybe(types.number),
    hypothesesPage: types.maybe(types.number),
    prototypesPage: types.maybe(types.number),
    learningsPage: types.maybe(types.number),

    searchQuery: types.maybe(types.string)
  })
  .actions((self) => {
    const clear = () => {
      self.listType = undefined;
      self.listQuery = undefined;

      self.briefingsList.clear();
      self.painpointsList.clear();
      self.benchmarksList.clear();
      self.hypothesesList.clear();
      self.prototypesList.clear();
      self.learningsList.clear();

      self.briefings.clear();
      self.painpoints.clear();
      self.benchmarks.clear();
      self.hypotheses.clear();
      self.prototypes.clear();
      self.learnings.clear();

      self.briefingsPage = undefined;
      self.painpointsPage = undefined;
      self.benchmarksPage = undefined;
      self.hypothesesPage = undefined;
      self.prototypesPage = undefined;
      self.learningsPage = undefined;

      self.briefingsCount = undefined;
      self.painpointsCount = undefined;
      self.benchmarksCount = undefined;
      self.hypothesesCount = undefined;
      self.prototypesCount = undefined;
      self.learningsCount = undefined;
    };

    const setType = (
      listType: string,
      listQuery?: string,
      clearIfChanged = false
    ) => {
      if (
        clearIfChanged &&
        (self.listType !== listType || self.listQuery !== listQuery)
      ) {
        clear();
      }

      self.listType = listType;
      self.listQuery = listQuery;
    };

    const populateLists = (
      page: number,
      briefings?: any,
      painpoints?: any,
      benchmarks?: any,
      hypotheses?: any,
      prototypes?: any,
      learnings?: any
    ) => {
      if (Array.isArray(briefings?.entries) && briefings.entries.length > 0) {
        self.briefingsPage = page;
        self.briefingsCount = briefings.count || 0;

        for (let briefing of briefings.entries) {
          self.briefings.put(createBriefingModel(briefing));

          briefing = self.briefings.get(briefing.id);
          if (briefing) {
            self.briefingsList.push(briefing);
          }
        }
      }

      if (Array.isArray(painpoints?.entries) && painpoints.entries.length > 0) {
        self.painpointsPage = page;
        self.painpointsCount = painpoints.count || 0;

        for (let painpoint of painpoints.entries) {
          self.painpoints.put(createPainpointModel(painpoint));

          painpoint = self.painpoints.get(painpoint.id);
          if (painpoint) {
            self.painpointsList.push(painpoint);
          }
        }
      }

      if (Array.isArray(benchmarks?.entries) && benchmarks.entries.length > 0) {
        self.benchmarksPage = page;
        self.benchmarksCount = benchmarks.count || 0;

        for (let benchmark of benchmarks.entries) {
          self.benchmarks.put(createBenchmarkModel(benchmark));

          benchmark = self.benchmarks.get(benchmark.id);
          if (benchmark) {
            self.benchmarksList.push(benchmark);
          }
        }
      }

      if (Array.isArray(hypotheses?.entries) && hypotheses.entries.length > 0) {
        self.hypothesesPage = page;
        self.hypothesesCount = hypotheses.count || 0;

        for (let hypothesis of hypotheses.entries) {
          self.hypotheses.put(createHypothesisModel(hypothesis));

          hypothesis = self.hypotheses.get(hypothesis.id);
          if (hypothesis) {
            self.hypothesesList.push(hypothesis);
          }
        }
      }

      if (Array.isArray(prototypes?.entries) && prototypes.entries.length > 0) {
        self.prototypesPage = page;
        self.prototypesCount = prototypes.count || 0;

        for (let prototype of prototypes.entries) {
          self.prototypes.put(createPrototypeModel(prototype));

          prototype = self.prototypes.get(prototype.id);
          if (prototype) {
            self.prototypesList.push(prototype);
          }
        }
      }

      if (Array.isArray(learnings?.entries) && learnings.entries.length > 0) {
        self.learningsPage = page;
        self.learningsCount = learnings.count || 0;

        for (let learning of learnings.entries) {
          self.learnings.put(createLearningModel(learning));

          learning = self.learnings.get(learning.id);
          if (learning) {
            self.learningsList.push(learning);
          }
        }
      }
    };

    const search = flow(function* ({
      query,
      organizationId,
      projectId,
      elementType,
      page
    }: {
      query: string;
      organizationId?: number;
      projectId?: number;
      elementType?: SearchableElementType;
      page?: number;
    }) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      if (!page || page < 2) {
        clear();
      }
      setType('search', (elementType || 'all') + '::' + query, true);

      try {
        self.listLoadingState = 'loading';

        const result: any = yield client.search({
          query,
          elementTypes: elementType,
          limit: LIMIT,
          page,
          projectId,
          organizationId
        });

        if (!result) {
          throw new Error('No response from server');
        }

        if (result.results) {
          populateLists(
            result.page || page || 1,
            result.results.briefings,
            result.results.painpoints,
            result.results.benchmarks,
            result.results.hypotheses,
            result.results.prototypes,
            result.results.learnings
          );
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ContentListStore | search', error, error.body);
        }

        if (client.isNotFound(error)) {
          // handle this as 'nothing found'
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
        return;
      }
    });

    // @ts-ignore
    const getRecommendations = flow(function* (
      elementType: RecommendableElementType,
      organizationId: number,
      projectId: number
    ) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      clear();
      setType('recommendations', elementType, true);

      try {
        self.listLoadingState = 'loading';

        const result: any = yield client.getRecommendations(
          elementType,
          organizationId,
          projectId
        );

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        populateLists(
          1,
          [],
          [],
          elementType === 'Benchmark' ? { entries: result } : undefined,
          elementType === 'Hypothesis' ? { entries: result } : undefined,
          elementType === 'Prototype' ? { entries: result } : undefined,
          elementType === 'Learning' ? { entries: result } : undefined
        );

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ContentListStore | getRecommendations',
            error,
            error.body
          );
        }

        // TODO Handle 422 response by showing hint "add tags to your project on the briefing page"

        if (client.isNotFound(error)) {
          // handle this as 'nothing found'
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
      }
    });

    const getBookmarkedElements = flow(function* (
      elementType?: SearchableElementType,
      page?: number
    ) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      if (!page || page < 2) {
        clear();
      }
      setType('bookmarks', elementType || 'all', true);

      try {
        self.listLoadingState = 'loading';

        const result: any = yield client.getBookmarks(elementType, LIMIT, page);

        if (!result) {
          throw new Error('No response from server');
        }

        if (result.results) {
          populateLists(
            result.page || page || 1,
            result.results.briefings,
            result.results.painpoints,
            result.results.benchmarks,
            result.results.hypotheses,
            result.results.prototypes,
            result.results.learnings
          );
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('ContentListStore | getBookmarks', error, error.body);
        }

        if (client.isNotFound(error)) {
          // handle this as 'nothing found'
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
        return;
      }
    });

    const getUserElements = flow(function* (
      userId: number,
      elementType?: SearchableElementType,
      page?: number
    ) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      if (!page || page < 2) {
        clear();
      }
      setType('user', (elementType || 'all') + '::' + userId.toString(), true);

      try {
        self.listLoadingState = 'loading';

        const result: any = yield client.getUserElements(
          userId,
          elementType,
          LIMIT,
          page
        );

        if (!result) {
          throw new Error('No response from server');
        }

        if (result.results) {
          populateLists(
            result.page || page || 1,
            result.results.briefings,
            result.results.painpoints,
            result.results.benchmarks,
            result.results.hypotheses,
            result.results.prototypes,
            result.results.learnings
          );
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ContentListStore | getUserElements',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // handle this as 'nothing found'
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
        return;
      }
    });

    const getRecentNetworkElements = flow(function* (
      elementType?: SearchableElementType,
      page?: number
    ) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      if (!page || page < 2) {
        clear();
      }
      setType('network_recent', elementType || 'all', true);

      try {
        self.listLoadingState = 'loading';

        const result: any = yield client.getRecentNetworkElements(
          elementType,
          LIMIT,
          page
        );

        if (!result) {
          throw new Error('No response from server');
        }

        if (result.results) {
          populateLists(
            result.page || page || 1,
            result.results.briefings,
            result.results.painpoints,
            result.results.benchmarks,
            result.results.hypotheses,
            result.results.prototypes,
            result.results.learnings
          );
        }

        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error(
            'ContentListStore | getRecentNetworkElements',
            error,
            error.body
          );
        }

        if (client.isNotFound(error)) {
          // handle this as 'nothing found'
          return;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return;
        }

        self.listLoadingState = 'load_error';
        return;
      }
    });

    const setSearchQuery = (query?: string) => {
      self.searchQuery = query;
    };

    const removeBriefing = (briefingId: number) => {
      const element = self.briefings.get(briefingId.toString());
      if (!element) {
        return;
      }

      self.briefingsList.remove(element);
      self.briefings.delete(briefingId.toString());

      if (self.briefingsCount && self.briefingsCount > 0) {
        self.briefingsCount = self.briefingsCount - 1;
      }
    };

    const removePainpoint = (painpointId: number) => {
      const element = self.painpoints.get(painpointId.toString());
      if (!element) {
        return;
      }

      self.painpointsList.remove(element);
      self.painpoints.delete(painpointId.toString());

      if (self.painpointsCount && self.painpointsCount > 0) {
        self.painpointsCount = self.painpointsCount - 1;
      }
    };

    const removeBenchmark = (benchmarkId: number) => {
      const element = self.benchmarks.get(benchmarkId.toString());
      if (!element) {
        return;
      }

      self.benchmarksList.remove(element);
      self.benchmarks.delete(benchmarkId.toString());

      if (self.benchmarksCount && self.benchmarksCount > 0) {
        self.benchmarksCount = self.benchmarksCount - 1;
      }
    };

    const removeHypothesis = (hypothesisId: number) => {
      const element = self.hypotheses.get(hypothesisId.toString());
      if (!element) {
        return;
      }

      self.hypothesesList.remove(element);
      self.hypotheses.delete(hypothesisId.toString());

      if (self.hypothesesCount && self.hypothesesCount > 0) {
        self.hypothesesCount = self.hypothesesCount - 1;
      }
    };

    const removePrototype = (prototypeId: number) => {
      const element = self.prototypes.get(prototypeId.toString());
      if (!element) {
        return;
      }

      self.prototypesList.remove(element);
      self.prototypes.delete(prototypeId.toString());

      if (self.prototypesCount && self.prototypesCount > 0) {
        self.prototypesCount = self.prototypesCount - 1;
      }
    };

    const removeLearning = (learningId: number) => {
      const element = self.learnings.get(learningId.toString());
      if (!element) {
        return;
      }

      self.learningsList.remove(element);
      self.learnings.delete(learningId.toString());

      if (self.learningsCount && self.learningsCount > 0) {
        self.learningsCount = self.learningsCount - 1;
      }
    };

    const removeByBookmark = (
      elementType: BookmarkableEnumType,
      elementId: number
    ) => {
      switch (elementType) {
        case 'Briefing':
          removeBriefing(elementId);
          break;

        case 'Painpoint':
          removePainpoint(elementId);
          break;

        case 'Benchmark':
          removeBenchmark(elementId);
          break;

        case 'Hypothesis':
          removeHypothesis(elementId);
          break;

        case 'Prototype':
          removePrototype(elementId);
          break;

        case 'Learning':
          removeLearning(elementId);
          break;

        default:
      }
    };

    return {
      clear,
      setType,
      search,
      getBookmarkedElements,
      getUserElements,
      getRecentNetworkElements,
      getRecommendations,
      setSearchQuery,
      removeBriefing,
      removePainpoint,
      removeBenchmark,
      removeHypothesis,
      removePrototype,
      removeLearning,
      removeByBookmark
    };
  })
  .views((self) => {
    return {
      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      isCurrentList(listType?: string, listQuery?: string): boolean {
        return self.listType === listType && self.listQuery === listQuery;
      },
      isCurrentSearch(
        query: string,
        elementType?: SearchableElementType
      ): boolean {
        return this.isCurrentList(
          'search',
          (elementType || 'all') + '::' + query
        );
      },

      isCurrentRecommendations(elementType: RecommendableElementType): boolean {
        return (
          self.listType === 'recommendations' && self.listQuery === elementType
        );
      },

      briefingsSlice(limit?: number): BriefingModelType[] {
        return sliceWithLimit<BriefingModelType>(self.briefingsList, limit);
      },
      painpointsSlice(limit?: number): PainpointModelType[] {
        return sliceWithLimit<PainpointModelType>(self.painpointsList, limit);
      },
      benchmarksSlice(limit?: number): BenchmarkModelType[] {
        return sliceWithLimit<BenchmarkModelType>(self.benchmarksList, limit);
      },
      hypothesesSlice(limit?: number): HypothesisModelType[] {
        return sliceWithLimit<HypothesisModelType>(self.hypothesesList, limit);
      },
      prototypesSlice(limit?: number): PrototypeModelType[] {
        return sliceWithLimit<PrototypeModelType>(self.prototypesList, limit);
      },
      learningsSlice(limit?: number): LearningModelType[] {
        return sliceWithLimit<LearningModelType>(self.learningsList, limit);
      },

      hasAnyOf(elementType: SearchableElementType): boolean {
        switch (elementType) {
          case 'Briefing':
            return self.briefingsList.length > 0;

          case 'Painpoint':
            return self.painpointsList.length > 0;

          case 'Benchmark':
            return self.benchmarksList.length > 0;

          case 'Hypothesis':
            return self.hypothesesList.length > 0;

          case 'Prototype':
            return self.prototypesList.length > 0;

          case 'Learning':
            return self.learningsList.length > 0;

          default:
        }
        return false;
      },
      pageOf(elementType: SearchableElementType): number {
        switch (elementType) {
          case 'Briefing':
            return self.briefingsPage || 1;

          case 'Painpoint':
            return self.painpointsPage || 1;

          case 'Benchmark':
            return self.benchmarksPage || 1;

          case 'Hypothesis':
            return self.hypothesesPage || 1;

          case 'Prototype':
            return self.prototypesPage || 1;

          case 'Learning':
            return self.learningsPage || 1;

          default:
        }
        return 1;
      }
    };
  });

export type ContentListStoreType = typeof ContentListStore.Type;
export default ContentListStore;
