import { IAnyModelType, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

import {
  createFluidOrganizationModel,
  FluidOrganizationModel
} from './OrganizationModel';
import numberOrUndefined from 'utils/store/numberOrUndefined';

export const OrgTargetGroupModel = types.model('OrgTargetGroupModel', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  organization_id: types.maybe(types.number),
  organization: types.maybe(
    types.late((): IAnyModelType => FluidOrganizationModel)
  )
});

export const createOrgTargetGroupModel = (
  data?: any
): OrgTargetGroupModelType | undefined => {
  return OrgTargetGroupModel.create({
    id: mandatoryId(data.id),
    name: data.name,
    content: data.content,
    organization_id: numberOrUndefined(data?.organization_id),
    organization: data?.organization
      ? createFluidOrganizationModel(data.organization)
      : undefined
  });
};

export type OrgTargetGroupModelType = typeof OrgTargetGroupModel.Type;
export default OrgTargetGroupModel;
