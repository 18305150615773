import ListMenu from 'components/ListMenu';
import MenuButton from 'components/MenuButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter } from 'react-router';
import { HistoryProps } from 'utils/history';

interface SettingsNavItemProps {
  dataStore?: DataStoreType;
  projectsStore?: ProjectsStoreType;
  applicationStore?: ApplicationStoreType;
}

export default injectIntl(
  withRouter(
    inject(
      'dataStore',
      'projectsStore',
      'applicationStore'
    )(
      observer(
        ({
          dataStore,
          projectsStore,
          intl,
          history,
          applicationStore
        }: SettingsNavItemProps & WrappedComponentProps & HistoryProps) => {
          if (!dataStore?.isProjectEditor) {
            return null;
          }

          const fm = (id: string) => intl.formatMessage({ id });

          const generatePainpoints = async () => {
            const result = await projectsStore!.generatePainpointsByAi(
              dataStore!.currentOrganizationId!,
              dataStore!.currentProjectId!
            );
            if (!result.success) {
              applicationStore!.setFlashMessage(
                intl.formatMessage({ id: 'AI create error' }),
                'error'
              );
            }
          };

          // const generateBenchmarks = async () => {
          //   const result = await projectsStore!.generateBenchmarksByAi(
          //     dataStore!.currentOrganizationId!,
          //     dataStore!.currentProjectId!
          //   );
          //   if (!result.success) {
          //     applicationStore!.setFlashMessage(
          //       intl.formatMessage({ id: 'AI create error' }),
          //       'error'
          //     );
          //   }
          // };

          return (
            <ListMenu
              label={fm('AI help')}
              iconName="ai"
              transformToIconName="plus"
              disabled={!dataStore.organization!.ai_enabled}
            >
              {/* <MenuButton
                label={fm('AI CallToAction Benchmark Label')}
                description={
                  dataStore.project?.reachedBenchmarkLimit ?
                  intl.formatMessage({ id: 'Benchmark limit reached' }, { limit: dataStore.organization!.benchmark_limit }) :
                  intl.formatMessage({ id: 'AI CallToAction Benchmark Description' })}
                iconName="detail"
                disabled={!dataStore!.isAdmin && dataStore.project!.reachedBenchmarkLimit}
                onClick={() => {
                  const result = window.confirm(
                    intl.formatMessage({
                      id: 'AI confirm creation'
                    })
                  );

                  if (result) {
                    generateBenchmarks();
                  }
                }}
              /> */}
              <MenuButton
                label={fm('AI CallToAction Painpoint Label')}
                description={
                  dataStore.project?.reachedPainpointLimit
                    ? intl.formatMessage(
                        { id: 'Painpoint limit reached' },
                        { limit: dataStore.organization!.painpoint_limit }
                      )
                    : intl.formatMessage({
                        id: 'AI CallToAction Painpoint Description'
                      })
                }
                iconName="clipboard"
                disabled={
                  !dataStore!.isAdmin &&
                  dataStore.project!.reachedPainpointLimit
                }
                onClick={() => {
                  if (!dataStore.project!.briefing.completed) {
                    applicationStore!.setAiUnavailable(true);
                    return;
                  }

                  const result = window.confirm(
                    intl.formatMessage({
                      id: 'AI confirm creation'
                    })
                  );

                  if (result) {
                    generatePainpoints();
                  }
                }}
              />
            </ListMenu>
          );
        }
      )
    )
  )
);
