import { LinkProps } from 'react-router-dom';

import CardHeader from 'components/CardHeader';
import RainbowCardSmall from 'components/RainbowCard/RainbowCardSmall';
import Typography from 'components/Typography';
import shortenString from 'utils/misc/shorten-string';

interface SidebarCardProps {
  to?: LinkProps['to'];
  header?: React.ReactNode;
  text?: string;
  isQuestion?: boolean;
}

export const SidebarCard = ({
  to,
  header,
  text,
  isQuestion
}: SidebarCardProps) => {
  return (
    <RainbowCardSmall to={to}>
      {header && <CardHeader simple={true} title={header} />}

      {text && (
        <Typography size="small">
          {shortenString(text, 200, isQuestion ? '…?' : '…')}
        </Typography>
      )}
    </RainbowCardSmall>
  );
};

export default SidebarCard;
