
import { OrganizationModelType } from 'models/OrganizationModel';
import simplifyOrgAccessLevel from 'utils/misc/simplify-org-access-level';
import AppLayout, { AppLayoutProps } from './AppLayout';

export const NetworkAwareAppLayout = (
  props: AppLayoutProps & { org?: OrganizationModelType; linkOrg?: boolean }
) => {
  let active = props.active;
  if (!props.active && props.org) {
    active =
      simplifyOrgAccessLevel(props.org) === 'network' ? 'network' : 'lab';
  }

  return (
    <AppLayout
      {...props}
      active={active}
      org={props.linkOrg ? props.org : undefined}
    />
  );
};
