import { FormattedMessage } from 'react-intl';

import AddMobileButton from 'components/AddButton/AddMobileButton';
import { UploadContainerChildProps } from 'containers/UploadContainer/UploadContainer';

interface UploadMobileButtonProps extends UploadContainerChildProps {
  overflow?: boolean;
}

// TODO error
export default ({
  onSelectFileClick,
  state,
  overflow
}: UploadMobileButtonProps) => (
  <AddMobileButton
    iconName="cloud-up"
    label={<FormattedMessage id="Add file" />}
    loading={state === 'saving' || state === 'waiting'}
    onClick={onSelectFileClick}
    overflow={overflow}
  />
);
