import { Route, Switch } from 'react-router';

import AssistantSessionContainer from 'containers/AssistantSessionContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ASSISTANT_BENCHMARK_SESSION } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import AssistantInvitationScreen from './AssistantInvitationScreen';
import BenchmarkListScreen from './benchmarks/AssistantBenchmarkListScreen';
import BenchmarkScreen from './benchmarks/BenchmarkScreen';
import FinalizeBenchmarksScreen from './benchmarks/FinalizeBenchmarksScreen';

export default ({ history, match }: HistoryProps) => (
  <AssistantSessionContainer
    history={history}
    assistantSessionId={match.params?.id}
    assistantType="benchmarks_sprint"
  >
    <Switch>
      <Route
        path={ROUTE_ASSISTANT_BENCHMARK_SESSION + '/invitation'}
        component={AssistantInvitationScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_BENCHMARK_SESSION + '/step1'}
        component={BenchmarkListScreen}
      />
      <Route
        path={ROUTE_ASSISTANT_BENCHMARK_SESSION + '/step2'}
        component={BenchmarkScreen}
      />

      <Route
        path={ROUTE_ASSISTANT_BENCHMARK_SESSION + '/finalize'}
        component={FinalizeBenchmarksScreen}
      />

      <NotFoundRoute />
    </Switch>
  </AssistantSessionContainer>
);
