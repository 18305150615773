export enum GapSizes {
  // Convert to corresponding CSS Variables
  NONE = 'var(--gap-NONE)',
  XS = 'var(--gap-XS)',
  S = 'var(--gap-S)',
  M = 'var(--gap-M)',
  L = 'var(--gap-L)',
  XL = 'var(--gap-XL)'
}

export default GapSizes;
