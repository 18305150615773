import 'core-js/stable';
import 'regenerator-runtime/runtime';
// tslint:disable-next-line: ordered-imports
import 'react-app-polyfill/ie11';

import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client';
import { RawIntlProvider } from 'react-intl';

import bootstrap from 'config/bootstrap/bootstrap';
import AppRouter from 'routers/AppRouter';

bootstrap(({ stores, intl }) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <RawIntlProvider value={intl}>
      <Provider {...stores}>
        <AppRouter />
      </Provider>
    </RawIntlProvider>
  );
});
