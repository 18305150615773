
import Tag from 'components/Tag';
import { TagListModelType } from 'models/TagListModel';

interface TagListProps {
  displayWhenEmpty?: boolean;
  children?: React.ReactNode;
  tags?: TagListModelType;
}

export default function TagList({
  children,
  tags,
  displayWhenEmpty
}: TagListProps) {
  const list = tags?.sortedStrings || [];

  if (!list && !children && !displayWhenEmpty) {
    return null;
  } else {
    return (
      <ul className="tag-list">
        {list.map((tag) => (
          <Tag key={tag} value={tag} />
        ))}
        {children}
      </ul>
    );
  }
}
