import { Route, Switch } from 'react-router';

import ContextAwareProjectLayout from 'components/ProjectLayout/ContextAwareProjectLayout';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_HYPOTHESES } from 'utils/constants/routes';
import HypothesesListScreen from './HypothesesListScreen';
import HypothesesRecommendationsScreen from './HypothesesRecommendationsScreen';
import HypothesisCreateScreen from './HypothesisCreateScreen';
import HypothesisDetailScreen from './HypothesisDetailScreen';
import HypothesisEditScreen from './HypothesisEditScreen';

export default () => (
  <ContextAwareProjectLayout active="hypotheses">
    <Switch>
      <Route
        path={ROUTE_HYPOTHESES}
        exact={true}
        component={HypothesesListScreen}
      />
      <Route
        path={ROUTE_HYPOTHESES + '/create'}
        exact={true}
        component={HypothesisCreateScreen}
      />
      <Route
        path={ROUTE_HYPOTHESES + '/inspiration'}
        exact={true}
        component={HypothesesRecommendationsScreen}
      />
      <Route
        path={ROUTE_HYPOTHESES + '/:id'}
        exact={true}
        component={HypothesisDetailScreen}
      />
      <Route
        path={ROUTE_HYPOTHESES + '/:id/edit'}
        exact={true}
        component={HypothesisEditScreen}
      />

      <NotFoundRoute />
    </Switch>
  </ContextAwareProjectLayout>
);
