import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { SessionMainText } from './session-elements';
import VideoPopup from './VideoPopup';

interface VideoPopupLinkProps {
  videoId: string;
  preview?: string;
  headline?: React.ReactNode;
  text?: React.ReactNode;
}

export const VideoPopupLink = ({
  videoId,
  headline,
  text,
  preview
}: VideoPopupLinkProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const popup = !visible ? null : (
    <VideoPopup
      videoId={videoId}
      headline={headline}
      onClose={() => setVisible(false)}
    />
  );

  // TODO create proper video teaser image
  return (
    <>
      <SessionMainText>
        <a
          href={
            'https://www.youtube.com/watch?v=' + encodeURIComponent(videoId)
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          {text ? text : <FormattedMessage id="assistant video link" />}

          {preview && (
            <span className="sidebar-teaser__decoration sidebar-teaser__large-decoration">
              <img
                className="sidebar-teaser__image"
                src={preview}
                alt="Video"
              />
              <span className="sidebar-teaser__icon">
                <Icon name="play" />
              </span>
            </span>
          )}
        </a>
      </SessionMainText>

      {popup}
    </>
  );
};

export default VideoPopupLink;
