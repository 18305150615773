import ModelCommentCard from 'components/CommentCard/ModelCommentCard';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import SectionHeadline from 'components/SectionHeadline';
import { inject, observer } from 'mobx-react';
import { ApiElementTypeEnumType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  CommentModelType,
  VALUATION_TYPES,
  createCommentModel
} from 'models/CommentModel';
import { CommentsStoreeType } from 'models/CommentsStore';
import { Commentable, DataStoreType } from 'models/DataStore';
import React from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';
import { scrollToElem } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import CommentForm from './CommentForm';

interface PublicCommentsContainerProps {
  elementType: ApiElementTypeEnumType;
  element: Commentable;
  onCommentUpdated?: (comment: CommentModelType) => void;
  onCommentRemoved?: (commentId: number) => void;
  textId?: string;
}

interface CommentsContainerProps extends PublicCommentsContainerProps {
  dataStore: DataStoreType;
  commentsStore: CommentsStoreeType;
  applicationStore: ApplicationStoreType;
  addForm: FormType;
  editForm: FormType;
}

// TODO error handling: form errors, unique contraint on create, other/404 on edit
// TODO comment menu
// TODO move form to form component so we do not rerender the list on every key press?

@inject('dataStore', 'commentsStore', 'applicationStore')
@observer
class CommentsContainer extends React.Component<
  CommentsContainerProps & WrappedComponentProps
> {
  // TODO hide form in didMount as well?
  componentWillUnmount() {
    this.hideForm();
  }

  isValuation() {
    return VALUATION_TYPES.includes(this.props.elementType);
  }

  showEditForm(commentId: number) {
    // TODO refresh from server?
    const { commentsStore, element } = this.props;
    const form = this.props.editForm;

    if (!element.comments) {
      return;
    }

    const comment = element.comments.get(commentId);
    if (!comment) {
      return;
    }

    form.reset();
    form.setField('text', comment.text);
    form.setField('target_group_relevance', comment.target_group_relevance);
    form.setField('revenue_potential', comment.revenue_potential);
    form.setField('cost_efficiency', comment.cost_efficiency);
    form.setField('differentiation_degree', comment.differentiation_degree);

    commentsStore.showEditCommentForm(comment);
  }

  hideForm() {
    this.props.commentsStore.closeCommentForm();
  }

  cancelForm() {
    this.hideForm();
  }

  async submitAddComment() {
    const { commentsStore, elementType, element, onCommentUpdated } =
      this.props;
    const form = this.props.addForm;

    try {
      const comment = await commentsStore.createComment(
        elementType,
        element.id,
        createCommentModel({
          ...form.values
        })
      );

      if (comment) {
        element.comments.put(comment);

        if (onCommentUpdated) {
          onCommentUpdated(comment);
        }
      }

      if (commentsStore.isItemSaveError) {
        this.props.applicationStore.setFlashMessage(
          this.props.intl.formatMessage({
            id: this.isValuation()
              ? 'valuation error flash'
              : 'comment error flash'
          }),
          'error'
        );
      } else {
        form.reset();

        this.props.applicationStore.setFlashMessage(
          this.props.intl.formatMessage({
            id: this.isValuation() ? 'valuation flash' : 'comment flash'
          })
        );

        if (this.isValuation()) {
          scrollToElem('#commentTop');
        }
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  async submitEditComment() {
    const { commentsStore, dataStore, element, onCommentUpdated } = this.props;
    const form = this.props.editForm;

    if (!dataStore.commentItem) {
      // something went wrong
      this.hideForm();
      return;
    }

    try {
      const comment = await commentsStore.updateComment(
        this.isValuation(),
        dataStore.commentItem.id,
        { ...form.values }
      );

      if (comment) {
        // TODO put() won't trigger rerender - check what is going on
        element.comments.delete(comment.id);
        element.comments.put(comment);

        if (onCommentUpdated) {
          onCommentUpdated(comment);
        }
      }

      form.reset();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  async removeComment(commentId: number) {
    // TODO loading state? error handling? see commentsStore.removeComment()
    const { intl, element, commentsStore, onCommentRemoved } = this.props;

    // TODO create nicer confirm?
    if (!window.confirm(intl.formatMessage({ id: 'remove comment confirm' }))) {
      return;
    }

    if (await commentsStore.removeComment(this.isValuation(), commentId)) {
      element.comments.delete(commentId);

      if (onCommentRemoved) {
        onCommentRemoved(commentId);
      }
    }
  }

  renderEditForm() {
    const { commentsStore } = this.props;

    if (!commentsStore.formVisible) {
      return null;
    }

    const {
      dataStore: { commentItem, user }
    } = this.props;
    const form = this.props.editForm;

    let formUser;
    let isEditMode = false;
    if (!commentItem) {
      formUser = user;
    } else {
      formUser = commentItem.author;
      isEditMode = true;
    }

    // TODO overlay background dimmer? form layout broken in overlay?
    return (
      <OverlayWrapper disabled={true} background="dark">
        <CommentForm
          form={form}
          user={formUser}
          withRatings={this.isValuation()}
          editMode={isEditMode}
          loading={commentsStore.isItemLoading || commentsStore.isItemSaving}
          onClose={() => this.cancelForm()}
          onSubmit={() => this.submitEditComment()}
        />
      </OverlayWrapper>
    );
  }

  render() {
    const { element, dataStore, commentsStore, intl, elementType, textId } =
      this.props;
    const isValuation = this.isValuation();

    const list = element.comments.hasAny && (
      <>
        {element.comments
          .sorted(!isValuation)
          .map((comment: CommentModelType) => {
            const isEditable = dataStore.mayEditAsViewer(comment.author);
            return (
              <ModelCommentCard
                withRating={isValuation}
                comment={comment}
                onEditClick={
                  isEditable ? () => this.showEditForm(comment.id) : undefined
                }
                onDeleteClick={
                  isEditable ? () => this.removeComment(comment.id) : undefined
                }
                key={comment.id}
              />
            );
          })}
      </>
    );

    let showForm = false;
    if (dataStore.currentUserId && dataStore.isOrgViewer) {
      if (isValuation) {
        showForm = !element.comments.byUser(dataStore.currentUserId)
          ? true
          : false;
      } else {
        showForm = true;
      }
    }

    if (elementType === 'FaqPage') {
      showForm = true;
    }

    const hasComments = element.comments.count > 0;

    if (!showForm && !hasComments) {
      return null;
    }

    const header = (
      <>
        {showForm && hasComments ? (
          <SectionHeadline
            title={intl.formatMessage({ id: 'comment header ' + elementType })}
            value={element.comments.count}
            anchor="#commentForm"
            anchorText={intl.formatMessage({
              id: isValuation ? 'Add valuation' : 'Add comment'
            })}
          />
        ) : (
          <SectionHeadline
            title={intl.formatMessage({
              id: 'comment header ' + elementType
            })}
            value={element.comments.count}
            caption={textId && <FormattedMessage id={textId} />}
          />
        )}
      </>
    );

    if (showForm) {
      return (
        <div>
          {header}
          <ColumnWrapper gap="0.625em">
            <div id="commentTop" />
            {list}
            <CommentForm
              form={this.props.addForm}
              user={dataStore.user}
              withRatings={isValuation}
              editMode={false}
              loading={commentsStore.isItemSaving}
              onSubmit={() => this.submitAddComment()}
            />

            {this.renderEditForm()}
          </ColumnWrapper>
        </div>
      );
    }

    return (
      <>
        {header}

        <div id="commentTop" />
        {list}

        {this.renderEditForm()}
      </>
    );
  }
}

export default injectIntl(
  (props: PublicCommentsContainerProps & WrappedComponentProps) => {
    const addForm = useForm();
    const editForm = useForm();
    return (
      // @ts-ignore
      <CommentsContainer {...props} addForm={addForm} editForm={editForm} />
    );
  }
);
