
import Icon from 'components/Icon';
import { HtmlInputProps } from 'components/Inputs/Input';

export default (props: HtmlInputProps) => {
  return (
    <div className="search-form">
      <div className="search-form__fieldset">
        <span className="search-form__input-icon">
          <Icon name="magnify" />
        </span>
        <input className="search-form__input" type="text" {...props} />
      </div>
    </div>
  );
};
