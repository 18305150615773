import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import SimpleCardSlider, {
  smallSliderResponsiveConfig
} from 'components/CardSlider';
import { DataStoreType } from 'models/DataStore';
import { PainpointAimModelType } from 'models/PainpointAimModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PainpointQuestionModelType } from 'models/PainpointQuestionModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import useForm, { FormType } from 'utils/hooks/useForm';
import PainpointSubelementItem from './PainpointSubelementItem';

interface PublicPainpointSubelementContainerProps
  extends WrappedComponentProps {
  type: 'Aim' | 'Question';
  painpoint: PainpointModelType;
}

interface PainpointSubelementContainerProps
  extends PublicPainpointSubelementContainerProps {
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  form: FormType;
}

@inject('dataStore', 'painpointsStore')
@observer
class PainpointSubelementContainer extends React.Component<PainpointSubelementContainerProps> {
  render() {
    const { type, painpoint, dataStore } = this.props;

    const mayAdd = dataStore.isProjectEditor;

    let elements: Array<PainpointAimModelType | PainpointQuestionModelType> =
      [];
    let title;
    let caption;
    switch (type) {
      case 'Aim':
        elements = painpoint.sortedAims;
        title = (
          <FormattedMessage
            id="painpoints aims header"
            values={{
              count: painpoint.sortedAims.length
            }}
          />
        );
        caption = <FormattedMessage id="painpoints aims caption" />;
        break;

      case 'Question':
        elements = painpoint.sortedQuestions;
        title = (
          <FormattedMessage
            id="painpoints questions header"
            values={{
              count: painpoint.sortedQuestions.length
            }}
          />
        );
        caption = <FormattedMessage id="painpoints questions caption" />;
        break;

      default:
    }

    if (!elements.length && !mayAdd) {
      return null;
    }

    return (
      <SimpleCardSlider
        title={title}
        caption={caption}
        responsiveConfig={smallSliderResponsiveConfig}
      >
        {elements.map((element, idx) => (
          <PainpointSubelementItem
            key={element.id}
            type={type}
            element={element}
            painpoint={painpoint}
            index={idx + 1}
          />
        ))}

        {mayAdd && (
          <PainpointSubelementItem type={type} painpoint={painpoint} />
        )}
      </SimpleCardSlider>
    );
  }
}

export default injectIntl((props: PublicPainpointSubelementContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <PainpointSubelementContainer {...props} form={form} />;
});
