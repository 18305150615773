import classNames from 'classnames';

interface OverlayWrapperProps {
  children: React.ReactNode;
  reduced?: boolean;
  bottom?: boolean;
  bottomRight?: boolean;
  topRight?: boolean;
  top?: boolean;
  background?: 'light' | 'dark';
  disabled?: boolean;
  hidden?: boolean;
  stack?: boolean;
  center?: boolean;
  absolute?: boolean;
  twoButtons?: boolean;
  onExit?: () => void;
  noPadding?: boolean;
}

export default function OverlayWrapper(props: OverlayWrapperProps) {
  const OverlayWrapperClasses = classNames('l-overlay-wrapper', {
    'l-overlay-wrapper--bottom': props.bottom,
    'l-overlay-wrapper--bottomRight': props.bottomRight,
    'l-overlay-wrapper--topRight': props.topRight,
    'l-overlay-wrapper--top': props.top,
    'l-overlay-wrapper--background': props.background === 'light',
    'l-overlay-wrapper--background-dark': props.background === 'dark',
    'l-overlay-wrapper--disabled': props.disabled,
    'l-overlay-wrapper--hidden': props.hidden,
    'l-overlay-wrapper--reduced': props.reduced,
    'l-overlay-wrapper--center': props.center,
    'l-overlay-wrapper--absolute': props.absolute,
    'l-overlay-wrapper--twoButtons': props.twoButtons,
    'l-overlay-wrapper--noPadding': props.noPadding
  });

  const onWrapperClick = (e: React.MouseEvent) => {
    // If clicked on wrapper and not on children
    if (e.target === e.currentTarget && props.onExit) {
      props.onExit();
    }
  };

  const renderChildren = () => {
    if (!props.stack) {
      return (
        <div onClick={onWrapperClick} className={OverlayWrapperClasses}>
          {props.children}
        </div>
      );
    } else {
      return (
        <div onClick={onWrapperClick} className={OverlayWrapperClasses}>
          <div className="l-overlay-wrapper__stack">{props.children}</div>
        </div>
      );
    }
  };

  return renderChildren();
}
