import { injectIntl, WrappedComponentProps } from 'react-intl';

import EditMenu from 'components/EditMenu';
import MenuButton from 'components/MenuButton';
import { PainpointModelType } from 'models/PainpointModel';

export type DeclineActionType = 'decline' | 'retract';

export interface PainpointsListItemMenuProps {
  painpoint: PainpointModelType;

  currentUserId?: number;
  isAdmin?: boolean;
  isOrgAdmin?: boolean;
  isProjectEditor?: boolean;
  project?: any;

  onRequestClick?: () => void;
  onAssignClick?: () => void;
  onDeclineClick?: (type: DeclineActionType) => void;
  onInviteClick?: () => void;
  onEditClick?: () => void;
  onRemoveClick?: () => void;
  onSearchBenchmarksClick?: () => void;
}

const PainpointsListItemMenu = ({
  painpoint,
  currentUserId,
  isAdmin,
  isOrgAdmin,
  isProjectEditor,
  project,
  onRequestClick,
  onAssignClick,
  onDeclineClick,
  onInviteClick,
  onEditClick,
  onRemoveClick,
  onSearchBenchmarksClick,
  intl
}: PainpointsListItemMenuProps & WrappedComponentProps) => {
  const items: any[] = [];

  // use some defaults for user IDs so we do not need to check for undefined every time
  const userId = currentUserId || -1;
  const authorId = painpoint.author?.id || -2;
  const requesterId = painpoint.requesting_user?.id || undefined;

  if (painpoint.publish_state === 'active') {
    // assignments
    if (requesterId) {
      if (isOrgAdmin) {
        if (onAssignClick) {
          items.push(
            <MenuButton
              key="assign"
              label={intl.formatMessage({ id: 'Confirm' })}
              description={intl.formatMessage({
                id: 'painpoint assign desc'
              })}
              iconName="thumbs-up"
              onClick={onAssignClick}
            />
          );
        }

        if (onDeclineClick) {
          items.push(
            <MenuButton
              key="decline"
              label={intl.formatMessage({ id: 'Decline' })}
              description={intl.formatMessage({
                id: 'painpoint decline desc'
              })}
              iconName="decline"
              onClick={() => onDeclineClick('decline')}
            />
          );
        }
      } else if (requesterId === userId) {
        if (onDeclineClick) {
          items.push(
            <MenuButton
              key="retract"
              label={intl.formatMessage({ id: 'Retract' })}
              description={intl.formatMessage({
                id: 'painpoint retract desc'
              })}
              iconName="decline"
              onClick={() => onDeclineClick('retract')}
            />
          );
        }
      }
    } else if (isOrgAdmin || isProjectEditor) {
      if (onRequestClick) {
        items.push(
          <MenuButton
            key="request"
            label={intl.formatMessage({ id: 'Raise hand' })}
            description={intl.formatMessage({
              id: 'painpoint request desc'
            })}
            iconName="raise-hand"
            onClick={onRequestClick}
          />
        );
      }
    }

    // invite
    if (isOrgAdmin && onInviteClick) {
      items.push(
        <MenuButton
          key="invite"
          label={intl.formatMessage({ id: 'Invite persons' })}
          description={intl.formatMessage({
            id: 'painpoint invite desc'
          })}
          iconName="persons"
          onClick={onInviteClick}
        />
      );
    }
  }

  // edit actions
  const mayEdit = isOrgAdmin || authorId === userId;
  if (mayEdit) {
    if (onSearchBenchmarksClick && project.organization.ai_enabled) {
      items.push(
        <MenuButton
          key="benchmarks"
          label={intl.formatMessage({ id: 'Search benchmarks' })}
          description={project?.reachedBenchmarkLimit ?
            intl.formatMessage({ id: 'Benchmark limit reached' }, { limit: project.organization!.benchmark_limit }) :
            intl.formatMessage({ id: 'search benchmarks desc' })
          }
          disabled={!isAdmin && project.reachedBenchmarkLimit}
          iconName="ai"
          onClick={onSearchBenchmarksClick}
        />
      );
    }

    if (onEditClick) {
      items.push(
        <MenuButton
          key="edit"
          label={intl.formatMessage({ id: 'Edit' })}
          description={intl.formatMessage({
            id: 'painpoint edit desc'
          })}
          iconName="pen"
          onClick={onEditClick}
        />
      );
    }

    if (onRemoveClick) {
      items.push(
        <MenuButton
          key="remove"
          label={intl.formatMessage({ id: 'Remove' })}
          description={intl.formatMessage({
            id: 'painpoint remove desc'
          })}
          iconName="bin"
          onClick={onRemoveClick}
        />
      );
    }
  }

  if (!items.length) {
    return null;
  }

  return (
    <EditMenu bottomRight={true} label={intl.formatMessage({ id: 'Edit' })}>
      {items}
    </EditMenu>
  );
};

export default injectIntl(PainpointsListItemMenu);
