
import { TagListModelType } from 'models/TagListModel';

interface HashtagsListProps {
  tags?: TagListModelType | string[];
}

// TODO Remove ability to handle string[] as this is only required for styleguide?
export default function HashtagsList({ tags }: HashtagsListProps) {
  const List = () => {
    if (tags) {
      const list = !Array.isArray(tags) ? tags.sortedStrings : tags;
      return (
        <ul className="hashtags-list">
          {list.map((tag: string) => (
            <li key={tag} className="hashtags-list__item">
              {tag}
            </li>
          ))}
        </ul>
      );
    }
  };
  return <>{List()}</>;
}
