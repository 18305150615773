import { ROUTE_SEARCH } from 'utils/constants/routes';

export function searchQueryURL({
  query = '',
  filter = '',
  organizationId = '',
  projectId = ''
}: {
  query?: string;
  filter?: string;
  organizationId?: string;
  projectId?: string;
}): string {
  const baseUrl = ROUTE_SEARCH;
  const queryParams = new URLSearchParams();
  const getParams = new URLSearchParams(new URL(window.location.href).search);

  if (query) queryParams.append('query', query);

  if (filter === 'organization') {
    queryParams.append(
      'organization_id',
      organizationId || getParams.get('organization_id') || ''
    );
  }

  if (filter === 'project') {
    queryParams.append(
      'project_id',
      projectId || getParams.get('project_id') || ''
    );
  }

  queryParams.append('filter', filter || '');

  const queryString = queryParams.toString();

  return `${baseUrl}?${queryString}`;
}
