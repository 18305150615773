import { inject, observer } from 'mobx-react';
import React from 'react';

import Loading from 'components/Loading';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import NoneFoundInPopupComponent from 'components/PainpointSelectPopup/NoneFoundInPopupComponent';

interface PublicHypothesesListContainerProps {
  itemType: any;
  itemContainer?: any;
  loading?: any;
  filter?: string;
  onItemClick?: (id: number) => void;
}

interface HypothesesListContainerProps
  extends PublicHypothesesListContainerProps {
  dataStore: DataStoreType;
  hypothesesStore: HypothesesStoreType;
}

@inject('dataStore', 'hypothesesStore')
@observer
class HypothesesListContainer extends React.Component<HypothesesListContainerProps> {
  componentDidMount() {
    this.props.hypothesesStore.getHypotheses();
  }

  renderLoading() {
    if (this.props.loading) {
      return this.props.loading;
    }

    return <Loading />;
  }

  renderError() {
    // TODO
    return <em>Error!</em>;
  }

  render() {
    const { hypothesesStore, filter, itemType, itemContainer, onItemClick } =
      this.props;

    if (hypothesesStore.isListLoading) {
      return this.renderLoading();
    }
    if (hypothesesStore.isListError) {
      return this.renderLoading();
    }

    const Item = itemType;
    const Container = !itemContainer ? React.Fragment : itemContainer;

    const list = hypothesesStore
      .list('active', undefined, undefined, undefined, filter || undefined)
      .map((elem) => (
        <Item
          key={elem.id}
          hypothesis={elem}
          onClick={() => onItemClick && onItemClick(elem.id)}
        />
      ));

    return (
      <Container>
        {!list.length ? <NoneFoundInPopupComponent /> : list}
      </Container>
    );
  }
}

export default (props: PublicHypothesesListContainerProps) => {
  // @ts-ignore
  return <HypothesesListContainer {...props} />;
};
