
import Icon from 'components/Icon';

interface CloseButtonProps {
  label: string;
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function CloseButton({
  label,
  iconName,
  onClick
}: CloseButtonProps) {
  return (
    <button className="close-button" aria-label={label} onClick={onClick}>
      <Icon name={iconName} />
    </button>
  );
}
