import { Route, Switch } from 'react-router';

import { ROUTE_SHARED } from 'utils/constants/routes';
import SharedBenchmarkScreen from './SharedBenchmarkScreen';
import SharedBenchmarksListScreen from './SharedBenchmarksListScreen';
import SharedDefaultListScreen from './SharedDefaultListScreen';
import SharedHypothesesListScreen from './SharedHypothesesListScreen';
import SharedHypothesisScreen from './SharedHypothesisScreen';
import SharedLearningScreen from './SharedLearningScreen';
import SharedLearningsListScreen from './SharedLearningsListScreen';
import SharedPainpointScreen from './SharedPainpointScreen';
import SharedPainpointsListScreen from './SharedPainpointsListScreen';
import SharedPrototypeScreen from './SharedPrototypeScreen';
import SharedPrototypesListScreen from './SharedPrototypesListScreen';

export const SharedContentRouter = () => (
  <Switch>
    <Route
      path={ROUTE_SHARED}
      exact={true}
      component={SharedDefaultListScreen}
    />

    <Route
      path={ROUTE_SHARED + '/benchmarks'}
      exact={true}
      component={SharedBenchmarksListScreen}
    />
    <Route
      path={ROUTE_SHARED + '/benchmarks/:id'}
      component={SharedBenchmarkScreen}
    />

    <Route
      path={ROUTE_SHARED + '/hypotheses'}
      exact={true}
      component={SharedHypothesesListScreen}
    />
    <Route
      path={ROUTE_SHARED + '/hypotheses/:id'}
      component={SharedHypothesisScreen}
    />

    <Route
      path={ROUTE_SHARED + '/prototypes'}
      exact={true}
      component={SharedPrototypesListScreen}
    />
    <Route
      path={ROUTE_SHARED + '/prototypes/:id'}
      component={SharedPrototypeScreen}
    />

    <Route
      path={ROUTE_SHARED + '/learnings'}
      exact={true}
      component={SharedLearningsListScreen}
    />
    <Route
      path={ROUTE_SHARED + '/learnings/:id'}
      component={SharedLearningScreen}
    />

    <Route
      path={ROUTE_SHARED + '/painpoints'}
      exact={true}
      component={SharedPainpointsListScreen}
    />
    <Route
      path={ROUTE_SHARED + '/painpoints/:id'}
      component={SharedPainpointScreen}
    />
  </Switch>
);

export default SharedContentRouter;
