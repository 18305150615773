import classNames from 'classnames';
import SectionHeadline from 'components/SectionHeadline';

interface CardGridProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  caption?: React.ReactNode;
  value?: number;
  noPadding?: boolean;
  twoColumns?: boolean;
}

export default function CardGrid(props: CardGridProps) {
  return (
    <div className="card-grid">
      {props.title && (
        <SectionHeadline
          title={props.title}
          value={props.value}
          caption={props.caption}
          noPadding={props.noPadding}
        />
      )}
      <div
        className={classNames('card-grid__items', {
          'card-grid__items--twoColumns': props.twoColumns
        })}
      >
        {props.children}
      </div>
    </div>
  );
}
