import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Link } from 'react-router-dom';
import OrgCardTop from 'components/CardTop/OrgCardTop';
import CardWrapper from 'components/CardWrapper';
import DestroyButton from 'components/DestroyButton';
import ErrorMessage from 'components/ErrorMessage';
import TagSelectInput from 'components/Inputs/TagSelectInput';
import LabeledIcon from 'components/LabeledIcon';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import TagList from 'components/TagList';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ContentListStoreType } from 'models/ContentListStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesesStoreType } from 'models/HypothesesStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { HistoryProps, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import { isDefined } from 'utils/misc/is-defined';
import HypothesesListItem from './HypothesesListItem';
import { DeclineActionType } from './HypothesesListItemMenu';
import InviteFlow from 'components/CallToAction/InviteFlow';

interface PublicHypothesesListScreenProps extends WrappedComponentProps {
  showRecommendations?: boolean;
}

interface HypothesesListScreenState {
  copyToProjectOverlayActive: boolean;
  requestLoading?: boolean;
  inviteHypothesis?: number;
}

interface HypothesesListScreenProps extends PublicHypothesesListScreenProps {
  form: FormType;
  actionsStore: ActionsStoreType;
  applicationStore: ApplicationStoreType;
  contentListStore: ContentListStoreType;
  dataStore: DataStoreType;
  hypothesesStore: HypothesesStoreType;
}

@inject(
  'actionsStore',
  'applicationStore',
  'contentListStore',
  'dataStore',
  'hypothesesStore'
)
@observer
class HypothesesListScreen extends React.Component<
  HypothesesListScreenProps & HistoryProps,
  HypothesesListScreenState
> {
  state: HypothesesListScreenState = {
    copyToProjectOverlayActive: false
  };

  componentDidMount() {
    if (this.props.showRecommendations) {
      this.loadHypothesesRecommendations();
    } else {
      this.loadHypotheses(!isPush(this.props.history));
    }
  }

  async loadHypotheses(checkIfAlreadyInStore = false) {
    const { dataStore, hypothesesStore } = this.props;

    if (checkIfAlreadyInStore && dataStore.hypothesesList) {
      return;
    }

    await hypothesesStore.getHypotheses();

    if (
      !dataStore.hypothesesList?.length &&
      dataStore.isProjectEditor &&
      !dataStore.project?.ai_at_work
    ) {
      this.props.history.replace(
        dataStore.contextUri + '/hypotheses/create?empty=yes',
        {
          isRedirect: true
        }
      );
    }
  }

  async loadHypothesesRecommendations() {
    const { contentListStore, dataStore } = this.props;
    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;
    if (!organizationId || !projectId) {
      return;
    }
    await contentListStore.getRecommendations(
      ElementType.Hypothesis,
      organizationId,
      projectId
    );
  }

  checkChanged(element: HypothesisModelType, newChecked: boolean) {
    const { dataStore } = this.props;

    const organizationId = dataStore.currentOrganizationId;
    const projectId = dataStore.currentProjectId;

    if (!isDefined(organizationId) || !isDefined(projectId)) {
      return;
    }

    this.props.actionsStore.selectionSet(
      {
        Hypothesis: [
          {
            id: element.id,
            organizationId,
            projectId,
            publishState: element.publish_state
          }
        ]
      },
      newChecked
    );
  }

  async deleteHypotheses(ids: number[]) {
    const { actionsStore, intl, hypothesesStore } = this.props;

    if (!isDefined(ids) || ids.length < 1) {
      return;
    }

    // TODO create nicer confirm?
    if (
      !window.confirm(
        intl.formatMessage(
          { id: 'remove hypotheses confirm' },
          { count: ids.length }
        )
      )
    ) {
      return;
    }

    await hypothesesStore.bulkDeleteHypotheses(ids);
    actionsStore.selectionClear();
  }

  private async requestClick(id: number) {
    const { applicationStore, hypothesesStore, intl } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      if (!(await hypothesesStore.requestHypothesis(id))) {
        alert(intl.formatMessage({ id: 'hypothesis already requested error' }));
      }
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'request failed error' }),
        'error'
      );
    }

    this.setState({
      requestLoading: false
    });
  }

  private async declineClick(id: number, type: DeclineActionType) {
    const { applicationStore, hypothesesStore, intl } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      await hypothesesStore.declineHypothesis(id);
    } catch (error) {
      applicationStore.setFlashMessage(
        intl.formatMessage({
          id:
            type === 'retract' ? 'retract failed error' : 'decline failed error'
        }),
        'error'
      );
    }

    this.setState({
      requestLoading: false
    });
  }

  private async assignClick(id: number) {
    const {
      applicationStore,
      hypothesesStore,
      intl: { formatMessage }
    } = this.props;

    this.setState({
      requestLoading: true
    });

    try {
      await hypothesesStore.assignHypothesis(
        id,
        formatMessage(
          { id: 'invitation template prototype_sprint' },
          {
            project: applicationStore.currentProject?.topic || 'N/A',
            inviter: applicationStore.currentUser?.first_name || ''
          }
        )
      );

      applicationStore.setFlashMessage(
        formatMessage({
          id: 'hypothesis assigned'
        })
      );
    } catch (error: any) {
      switch (error.body?.error) {
        case 'Not an editor':
          alert(formatMessage({ id: 'not an editor error' }));
          break;

        case 'No assignee':
          alert(formatMessage({ id: 'no assignee error' }));
          break;

        default:
          applicationStore.setFlashMessage(
            formatMessage({
              id: 'assign failed error'
            }),
            'error'
          );
      }
    }

    this.setState({
      requestLoading: false
    });
  }

  private inviteClick(hypothesisId: number) {
    this.setState({
      inviteHypothesis: hypothesisId
    });
  }

  private finishInvite() {
    this.setState({
      inviteHypothesis: undefined
    });
  }

  setFilter(filter: string, value: string, changed: boolean = false) {
    this.props.hypothesesStore.setFilter(filter, value);
  }

  getFilter(filter: string) {
    return this.props.hypothesesStore.filter.get(filter);
  }

  resetFilters() {
    this.setFilter('tag', '');
    this.setFilter('state', '');
    this.setFilter('author', '');
  }

  links() {
    const { dataStore, intl, showRecommendations } = this.props;
    const contextUri = dataStore.contextUri;

    return [
      {
        isLink: showRecommendations,
        to: contextUri + '/hypotheses',
        content: intl.formatMessage({ id: 'Hypotheses' })
      },
      {
        isLink: !showRecommendations,
        to: contextUri + '/hypotheses/inspiration',
        content: intl.formatMessage({ id: 'Recommendations' })
      }
    ];
  }

  renderList() {
    const {
      actionsStore,
      contentListStore,
      hypothesesStore,
      dataStore,
      form,
      intl,
      showRecommendations
    } = this.props;

    if (showRecommendations) {
      if (!contentListStore.hasAnyOf(ElementType.Hypothesis)) {
        return this.renderPage(
          <ContentWrapper>
            <ColumnWrapper gap="3em">
              <LinkRow links={this.links()} />
              <RowWrapper gap="2em">
                <CardWrapper>
                  <FormattedMessage id={'Recommendations empty'} />
                  <div className="margin-v text-center">
                    <Link
                      className="main-button display-inline-flex"
                      to={dataStore.contextUri}
                    >
                      {intl.formatMessage({
                        id: 'Recommendations to briefing'
                      })}
                    </Link>
                  </div>
                  <img
                    alt={intl.formatMessage({
                      id: 'Recommendations add tags to briefing'
                    })}
                    className="margin-auto-h max-width-50"
                    src="/images/briefing-add-tag.png"
                  />
                </CardWrapper>
              </RowWrapper>
            </ColumnWrapper>
          </ContentWrapper>
        );
      }
    } else if (!hypothesesStore.hasAny) {
      return this.renderPage(
        <ContentWrapper>
          <ColumnWrapper gap="1em">
            <LinkRow links={this.links()} />
            <RowWrapper gap="2em">
              <CardWrapper>
                <FormattedMessage id="no hypotheses" />
              </CardWrapper>
            </RowWrapper>
          </ColumnWrapper>
        </ContentWrapper>
      );
    }

    const isOrgAdmin = dataStore.isOrgAdmin;
    const currentUserId = dataStore.currentUserId;
    const isProjectEditor = dataStore.isProjectEditor;

    const list = showRecommendations
      ? contentListStore.hypothesesSlice()
      : hypothesesStore.listWithCurrentFilter;
    const hasFilter =
      this.getFilter('state') ||
      this.getFilter('tag') ||
      this.getFilter('author');
    const checked: number[] = actionsStore.selectedIds.Hypothesis;

    return this.renderPage(
      <>
        <ContentWrapper>
          {(hypothesesStore.isListDeleting || form.loading) && <Loading />}

          {!showRecommendations && (
            <TagList>
              <TagSelectInput
                label={intl.formatMessage({ id: 'Sort by' })}
                value={this.getFilter('sort')}
                onChange={(e) => this.setFilter('sort', e.target.value, false)}
              >
                <option value="newest">
                  {intl.formatMessage({ id: 'Newest' })}
                </option>
                <option value="oldest">
                  {intl.formatMessage({ id: 'Oldest' })}
                </option>
                <option value="" disabled={true} />
                <option value="bookmarks_count">
                  {intl.formatMessage({ id: 'Most bookmarks' })}
                </option>
                <option value="comments_count">
                  {intl.formatMessage({ id: 'Most comments' })}
                </option>
                <option value="" disabled={true} />
                <option value="average_target_group_relevance">
                  {intl.formatMessage({ id: 'Best target group relevance' })}
                </option>
                <option value="average_revenue_potential">
                  {intl.formatMessage({ id: 'Best revenue potential' })}
                </option>
                <option value="average_cost_efficiency">
                  {intl.formatMessage({ id: 'Best cost efficiency' })}
                </option>
                <option value="average_differentiation_degree">
                  {intl.formatMessage({ id: 'Best differentiation degree' })}
                </option>
              </TagSelectInput>

              <TagSelectInput
                name="tag"
                value={this.getFilter('tag')}
                onChange={(e) => this.setFilter('tag', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All hashtags' })}
                </option>
                <option value="" disabled={true} />
                {hypothesesStore.tags.map((tag) => (
                  <option value={tag} key={tag}>
                    #{tag}
                  </option>
                ))}
              </TagSelectInput>

              <TagSelectInput
                name="state"
                value={this.getFilter('state')}
                onChange={(e) => this.setFilter('state', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All types' })}
                </option>
                <option value="" disabled={true} />
                <option value="active">
                  {intl.formatMessage({ id: 'Published' })}
                </option>
                <option value="draft">
                  {intl.formatMessage({ id: 'Drafts' })}
                </option>
              </TagSelectInput>

              <TagSelectInput
                name="author"
                value={this.getFilter('author')}
                onChange={(e) => this.setFilter('author', e.target.value, true)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'All persons' })}
                </option>
                <option value="" disabled={true} />
                {hypothesesStore.authors.map((author) => (
                  <option value={author.id} key={author.id}>
                    {author.name}
                  </option>
                ))}
              </TagSelectInput>
            </TagList>
          )}

          <ColumnWrapper gap="3em">
            <LinkRow links={this.links()} />

            {!showRecommendations && hasFilter && (
              <CardWrapper>
                <DestroyButton
                  label={intl.formatMessage({ id: 'Reset filters' })}
                  iconName="cross"
                  onClick={() => this.resetFilters()}
                />
                <FormattedMessage
                  id="hypothesis filter label"
                  values={{ count: list.length }}
                />
              </CardWrapper>
            )}

            {!!showRecommendations && (
              <CardWrapper>
                <RowWrapper gap="1em">
                  <LabeledIcon
                    icon="network"
                    label={intl.formatMessage({ id: 'Network' })}
                    opacity={0.5}
                  />
                  <div
                    style={
                      {
                        color: 'var(--text-color-secondary)'
                      } as React.CSSProperties
                    }
                  >
                    {intl.formatMessage({
                      id: 'Recommendations info hypotheses'
                    })}
                  </div>
                </RowWrapper>
              </CardWrapper>
            )}

            {list.map((element: HypothesisModelType) => {
              const { id } = element;
              const editPath = element.publish_state === 'draft' ? '/edit' : '';

              return (
                <HypothesesListItem
                  hypothesis={element}
                  key={element.id}
                  fromList={!showRecommendations}
                  readOnly={showRecommendations}
                  checked={checked.indexOf(id) > -1}
                  onChange={(c) => this.checkChanged(element, c)}
                  currentUserId={currentUserId}
                  isOrgAdmin={isOrgAdmin}
                  isProjectEditor={isProjectEditor}
                  onEditClick={() =>
                    this.props.history.push('hypotheses/' + id + editPath)
                  }
                  onRemoveClick={() => this.deleteHypotheses([id])}
                  onRequestClick={() => this.requestClick(id)}
                  onAssignClick={() => this.assignClick(id)}
                  onDeclineClick={(t) => this.declineClick(id, t)}
                  onInviteClick={() => this.inviteClick(id)}
                  top={
                    showRecommendations && (
                      <OrgCardTop org={element.project?.organization} />
                    )
                  }
                />
              );
            })}

            {!!showRecommendations && (
              <div className="margin-auto-h">
                <Link className="main-button" to="/app/network?type=hypothesis">
                  {intl.formatMessage({
                    id: 'More recommendations in network'
                  })}
                </Link>
              </div>
            )}
          </ColumnWrapper>
        </ContentWrapper>

        <InviteFlow
          assistantType="prototype_sprint"
          active={!!this.state.inviteHypothesis}
          hypothesisId={this.state.inviteHypothesis}
          onFinish={() => this.finishInvite()}
        />

        {!hypothesesStore.isListBusy && (
          <ActionsOverlayContainer
            copyInitiallySelectedOrganizationId={
              showRecommendations ? dataStore.currentOrganizationId : undefined
            }
            copyInitiallySelectedProjectId={
              showRecommendations ? dataStore.currentProjectId : undefined
            }
            showCopy={true}
            showAiBenchmark={true}
            showAiBriefing={true}
            showDelete={
              !showRecommendations &&
              actionsStore.selectedFromProjectIds.length === 1 &&
              actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={!showRecommendations}
          />
        )}
      </>
    );
  }

  renderLoading() {
    // TODO
    return this.renderPage(<Loading />);
  }

  renderPage(content: any) {
    return (
      <>
        <PageHeader
          titleId="Hypotheses"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={<FormattedMessage id="hypothesis list header" />}
              subtitle={<FormattedMessage id="hypothesis list caption" />}
              list={true}
            />
          }
        />
        {content}
      </>
    );
  }

  render() {
    const { contentListStore, hypothesesStore } = this.props;

    if (hypothesesStore.isListLoading || contentListStore.isListLoading) {
      return this.renderLoading();
    }

    if (hypothesesStore.isListError) {
      return this.renderPage(
        <ErrorMessage
          state={hypothesesStore.listLoadingState}
          onRetry={() => this.loadHypotheses()}
        />
      );
    }

    return this.renderList();
  }
}

export default injectIntl((props: PublicHypothesesListScreenProps) => {
  const form = useForm({ sort: 'newest' });
  // @ts-ignore
  return <HypothesesListScreen {...props} form={form} />;
});
