import { flow, getEnv, types } from 'mobx-state-tree';
import PageSwitchDescriptor from 'types/PageSwitchDescriptor';
import { describePageSwitch } from 'utils/misc/page-switch';
import sortDraftsFirst from 'utils/sort/drafts-first';
import sortByField from 'utils/sort/field';
import multiSort from 'utils/sort/multi-sort';
import sortByNumber from 'utils/sort/number';
import { sortPublishedAsc, sortPublishedDesc } from 'utils/sort/published-at';
import {
  reduceAuthors,
  ReducedAuthors,
  reduceTags
} from 'utils/store/filter-reducers';
import { AssistantSessionModelType } from './AssistantSessionModel';

import {
  ItemLoadingStateEnum,
  ListLoadingStateEnum
} from './LoadingStateEnums';
import { createPrototypeModel, PrototypeModelType } from './PrototypeModel';
import { createPrototypeScreenModel } from './PrototypeScreenModel';
import { PublishStateEnumType } from './PublishStateEnum';
import { AdvancedStoreEnv } from './StoreEnv';
import { createServerTagList } from './TagListModel';
import { PrototypeScreenModelType } from 'models/PrototypeScreenModel';
import createMap from 'utils/store/createMap';

interface IScreenBase {
  screenId: number;
  orgId?: number;
  projId?: number;
  prototype?: PrototypeModelType;
  assistantSession?: AssistantSessionModelType;
}

interface IUpdateScreen extends IScreenBase {
  patch: any;
}

interface ISwapScreens {
  screen1: PrototypeScreenModelType;
  screen2: PrototypeScreenModelType;
  orgId?: number;
  projId?: number;
  prototypeId?: number;
  assistantSessionId?: number;
}

const PrototypesStore = types
  .model('PrototypesStore', {
    listLoadingState: types.maybe(ListLoadingStateEnum),
    itemLoadingState: types.maybe(ItemLoadingStateEnum),
    filter: types.map(types.string)
  })
  .actions((self) => {
    const getPrototypes = flow(function* (orgId?: number, projId?: number) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.listLoadingState = 'loading';
        dataStore.setPrototypesList(undefined);

        const result: any = yield client.getPrototypes(
          organizationId,
          projectId
        );

        if (!result || !Array.isArray(result)) {
          throw new Error('No response from server');
        }

        const list = [];
        for (const item of result) {
          const storeItem = dataStore.convertAndAddPrototype(item);
          list.push(storeItem);
        }

        dataStore.setPrototypesList(list);
        self.listLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | getPrototypes', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.listLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.listLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.listLoadingState = undefined;
          return undefined;
        }

        self.listLoadingState = 'load_error';
        return undefined;
      }
    });

    const getPrototype = flow(function* (
      prototypeId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'loading';
        dataStore.setPrototypeItem(undefined);

        const result: any = yield client.getPrototype(
          organizationId,
          projectId,
          prototypeId
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const prototype = dataStore.convertAndAddPrototype(result, true);
        dataStore.setPrototypeItem(prototype);

        self.itemLoadingState = undefined;
        return prototype;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | getPrototype', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        self.itemLoadingState = 'load_error';
        return undefined;
      }
    });

    const createPrototype = flow(function* (
      prototype: PrototypeModelType,
      addToList: boolean = false,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';
        dataStore.setPrototypeItem(undefined);

        const result: any = yield client.createPrototype(
          organizationId,
          projectId,
          {
            ...prototype,
            tags_attributes: createServerTagList(prototype.tags),
            tags: undefined
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newPrototype = dataStore.convertAndAddPrototype(result, true);
        dataStore.setPrototypeItem(newPrototype);

        if (addToList && newPrototype) {
          dataStore.addToPrototypesList(newPrototype);
        }

        self.itemLoadingState = undefined;
        return newPrototype;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | createPrototype', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const updatePrototype = flow(function* (
      prototypeId: number,
      patch: any,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'saving';

        let serverPatch: any = patch;
        if (patch.tags) {
          serverPatch = {
            ...patch,
            tags_attributes: createServerTagList(patch.tags),
            tags: undefined
          };
        }

        const result: any = yield client.updatePrototype(
          organizationId,
          projectId,
          prototypeId,
          serverPatch
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newPrototype = dataStore.convertAndAddPrototype(result, true);
        dataStore.setPrototypeItem(newPrototype);

        self.itemLoadingState = undefined;
        return newPrototype;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | updatePrototype', error, error.body);
        }

        if (client.isNotFound(error)) {
          self.itemLoadingState = 'not_found';
          return undefined;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          return undefined;
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return undefined;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'save_error';
        return undefined;
      }
    });

    const deletePrototype = flow(function* (
      prototypeId: number,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        self.itemLoadingState = 'deleting';

        yield client.deletePrototype(organizationId, projectId, prototypeId);

        dataStore.deletePrototype(prototypeId);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | deletePrototype', error, error.body);
        }

        if (client.isNotFound(error)) {
          // this is fine
          dataStore.deletePrototype(prototypeId);
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isAccessDenied(error)) {
          self.itemLoadingState = 'access_denied';
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'delete_error';
        throw new Error('delete_error');
      }
    });

    const bulkDeletePrototypes = flow(function* (
      prototypeIds: number[],
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      self.listLoadingState = 'deleting';
      let deleted = 0;

      for (const prototypeId of prototypeIds) {
        try {
          yield deletePrototype(prototypeId, organizationId, projectId);

          deleted++;
        } catch (error: any) {
          if (process.env.NODE_ENV !== 'production') {
            // tslint:disable-next-line
            console.error(
              'PrototypesStore | bulkDeletePrototypes',
              error,
              error.body
            );
          }

          if (client.isNotFound(error)) {
            dataStore.deletePrototype(prototypeId);
            deleted++;
          }
        }
      }

      self.listLoadingState = undefined;
      return deleted;
    });

    const createScreen = flow(function* (
      screen: any,
      prototype?: PrototypeModelType,
      orgId?: number,
      projId?: number
    ) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.createPrototypeScreen(
          organizationId,
          projectId,
          prototype?.id,
          undefined,
          {
            prototype_id: prototype?.id || -1
          }
        );

        if (!result) {
          throw new Error('No response from server');
        }

        const newScreen = createPrototypeScreenModel(result);

        if (!prototype) {
          return newScreen;
        } else {
          prototype.putScreen(newScreen);
          return prototype.prototype_screens?.get(newScreen.id.toString());
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | createScreen', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const updateScreen = flow(function* ({
      screenId,
      patch,
      orgId,
      projId,
      prototype,
      assistantSession
    }: IUpdateScreen) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result: any = yield client.updatePrototypeScreen({
          organizationId,
          projectId,
          prototypeId: prototype?.id,
          assistantSessionId: assistantSession?.id,
          screenId,
          screenPatch: patch
        });

        if (!result) {
          throw new Error('No response from server');
        }

        const newScreen = createPrototypeScreenModel(result);

        if (prototype) {
          prototype.putScreen(newScreen);
        } else if (assistantSession) {
          assistantSession.putScreen(newScreen);
        } else {
          throw new Error('prototype_or_assistant_session_needed');
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | updateScreen', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const deleteScreen = flow(function* ({
      screenId,
      prototype,
      assistantSession,
      orgId,
      projId
    }: IScreenBase) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const response = yield client.deletePrototypeScreen({
          organizationId,
          projectId,
          screenId,
          prototypeId: prototype?.id,
          assistantSessionId: assistantSession?.id
        });

        if (prototype) {
          prototype.deleteScreen(screenId);
        } else if (assistantSession) {
          assistantSession.deleteScreen(screenId);

          const prototypeScreens = createMap(
            response,
            createPrototypeScreenModel
          );
          assistantSession.setPrototypeScreens(prototypeScreens);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | deleteScreen', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const swapScreens = flow(function* ({
      screen1,
      screen2,
      orgId,
      projId,
      prototypeId,
      assistantSessionId
    }: ISwapScreens) {
      const { client, dataStore, applicationStore } =
        getEnv<AdvancedStoreEnv>(self);

      const { organizationId, projectId } = dataStore.applyContext(
        orgId,
        projId
      );

      try {
        const result = yield client.swapPrototypeScreens({
          organizationId,
          projectId,
          screenId: screen1.id,
          screen2Id: screen2.id,
          prototypeId,
          assistantSessionId
        });

        if (!result) {
          throw new Error('No response from server');
        }

        if (
          typeof result.screen1_order === 'number' &&
          typeof result.screen2_order === 'number'
        ) {
          screen1.setOrder(result.screen1_order);
          screen2.setOrder(result.screen2_order);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('PrototypesStore | swapScreens', error, error.body);
        }

        if (client.isNotFound(error)) {
          throw new Error('not_found');
        }

        if (client.isAccessDenied(error)) {
          throw new Error('access_denied');
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    const moveScreen = flow(function* (
      screenId: number,
      direction: 'left' | 'right',
      prototype?: PrototypeModelType,
      assistantSession?: AssistantSessionModelType,
      orgId?: number,
      projId?: number
    ) {
      if (!prototype && !assistantSession) {
        throw new Error('prototype or assistantSession needs to be set');
      }

      let sorted;
      let screen: PrototypeScreenModelType;

      if (prototype) {
        sorted = prototype?.sortedScreens;
      } else {
        sorted = assistantSession?.sortedScreens;
      }

      if (sorted!.length < 2) {
        return;
      }

      let pos = -1;
      for (let i = 0; i < sorted!.length; i++) {
        if (sorted![i].id === screenId) {
          pos = i;
          screen = sorted![i];
          break;
        }
      }

      if (pos < 0) {
        return;
      }

      let otherScreen: PrototypeScreenModelType;

      if (direction === 'left') {
        if (pos === 0) {
          return;
        }
        otherScreen = sorted![pos - 1];
      } else {
        if (pos === sorted!.length - 1) {
          return;
        }
        otherScreen = sorted![pos + 1];
      }

      return yield swapScreens({
        screen1: screen!,
        screen2: otherScreen,
        orgId,
        projId,
        prototypeId: prototype?.id,
        assistantSessionId: assistantSession?.id
      });
    });

    const initializeItem = (data: any) => {
      // TODO how to handle hypothesis here?
      const { dataStore } = getEnv<AdvancedStoreEnv>(self);
      const prototype = createPrototypeModel(data);

      dataStore.addPrototype(prototype, true);
      dataStore.setPrototypeItem(
        dataStore.prototypes.get(prototype.id.toString())
      );
    };

    const setFilter = (key: string, value: string) =>
      self.filter.set(key, value);

    return {
      getPrototypes,
      getPrototype,
      initializeItem,
      createPrototype,
      updatePrototype,
      deletePrototype,
      bulkDeletePrototypes,
      createScreen,
      updateScreen,
      deleteScreen,
      swapScreens,
      moveScreen,
      setFilter
    };
  })
  .views((self) => {
    const { dataStore } = getEnv<AdvancedStoreEnv>(self);
    return {
      get isItemLoading(): boolean {
        return (
          self.itemLoadingState === 'loading' ||
          self.itemLoadingState === 'deleting'
        );
      },
      get isItemSaving(): boolean {
        return self.itemLoadingState === 'saving';
      },
      get isItemNotFound(): boolean {
        return self.itemLoadingState === 'not_found';
      },
      get isItemSaveError(): boolean {
        return (
          self.itemLoadingState === 'save_error' ||
          self.itemLoadingState === 'delete_error'
        );
      },
      get isItemLoadError(): boolean {
        return (
          self.itemLoadingState === 'load_error' ||
          self.itemLoadingState === 'access_denied' ||
          self.itemLoadingState === 'feature_disabled'
        );
      },

      get isListLoading(): boolean {
        return self.listLoadingState === 'loading';
      },
      get isListDeleting(): boolean {
        return self.listLoadingState === 'deleting';
      },
      get isListBusy(): boolean {
        return this.isListLoading || this.isListDeleting;
      },
      get isListError(): boolean {
        return (
          self.listLoadingState === 'load_error' ||
          self.listLoadingState === 'access_denied' ||
          self.listLoadingState === 'feature_disabled'
        );
      },

      get hasAny(): boolean {
        return dataStore.prototypesList && dataStore.prototypesList.length > 0
          ? true
          : false;
      },
      list(
        state?: PublishStateEnumType,
        authorId?: string,
        tag?: string,
        sort?: string
      ): PrototypeModelType[] {
        if (!dataStore.prototypesList || !dataStore.prototypesList.length) {
          return [];
        }

        const authorIdNumber = authorId ? parseInt(authorId, 10) : undefined;

        let noFilter = !authorIdNumber && !state && !tag;
        const list: PrototypeModelType[] = [];

        for (const item of dataStore.prototypesList.values()) {
          if (!item) {
            continue;
          }

          if (authorIdNumber && item.author?.id !== authorIdNumber) {
            continue;
          }

          if (state && item.publish_state !== state) {
            continue;
          }

          if (tag && (!item.tags || !item.tags.includes(tag))) {
            continue;
          }

          list.push(item);
        }

        let sortFunc;
        switch (sort) {
          case 'newest':
            sortFunc = sortPublishedDesc;
            break;

          case 'oldest':
            sortFunc = sortPublishedAsc;
            break;

          default:
            if (sort) {
              noFilter = false;
              sortFunc = sortByNumber(sort, true);
            } else {
              sortFunc = sortPublishedDesc;
            }
        }

        const sorts = [sortFunc, sortByField('headline')];

        if (noFilter) {
          sorts.unshift(sortDraftsFirst);
        }

        list.sort(multiSort(sorts));

        return list;
      },
      get listWithCurrentFilter(): PrototypeModelType[] {
        return this.list(
          self.filter.get('state'),
          self.filter.get('author'),
          self.filter.get('tag'),
          self.filter.get('sort')
        );
      },
      pageSwitch(current?: PrototypeModelType): PageSwitchDescriptor {
        return describePageSwitch<PrototypeModelType>(
          this.listWithCurrentFilter,
          current
        );
      },

      get tags(): string[] {
        return reduceTags(dataStore.prototypesList);
      },
      get authors(): ReducedAuthors {
        return reduceAuthors(dataStore.prototypesList);
      }
    };
  });

export type PrototypesStoreType = typeof PrototypesStore.Type;
export default PrototypesStore;
