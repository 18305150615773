import AppLayout from 'components/AppLayout';
import CardHeader from 'components/CardHeader';
import CardWrapper from 'components/CardWrapper';
import CompanyLogo from 'components/CompanyLogo';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import ContextAwareOrgLayout from 'components/OrgSettingsLayout/ContextAwareOrgLayout';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import SettingsTable from 'components/SettingsTable';
import ToggleSwitch from 'components/ToggleSwitch';
import Typography from 'components/Typography';
import UploadButton from 'components/UploadButton';
import UploadedImage from 'components/UploadedImage';
import UploadContainer from 'containers/UploadContainer';
import { UploadContainerChildProps } from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { inject, observer } from 'mobx-react';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { ProjectLanguageType } from 'models/ProjectLanguageEnum';
import React from 'react';
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl
} from 'react-intl';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable-next-line: no-empty-interface
interface PublicOrgSettingsScreenProps extends WrappedComponentProps {}

interface OrgSettingsScreenProps extends PublicOrgSettingsScreenProps {
  dataStore: DataStoreType;
  organizationsStore: OrganizationsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('dataStore', 'organizationsStore', 'applicationStore')
@observer
class OrgSettingsScreen extends React.Component<
  OrgSettingsScreenProps & HistoryProps
> {
  componentDidMount() {
    this.load();
  }

  load() {
    // TODO reload from server every time this page is displayed?
    //      if yes, avoid loading twice (container and page) somehow?

    const { form, dataStore } = this.props;

    form.reset();

    const org = dataStore.currentOrganization;
    if (!org) {
      return;
    }

    form.setField('name', org.name || '');
    form.setField('sector', org.sector || '');
    form.setField('description', org.description || '');
    form.setField('default_language', org.default_language);
    form.setField('network_enabled', org.network_enabled);

    this.getNetworkSettings().forEach((setting) =>
      this.props.form.setField(setting.propertyName, setting.state)
    );
  }

  async save() {
    const { form, dataStore, organizationsStore } = this.props;

    if (!dataStore.currentOrganizationId) {
      return;
    }

    form.resetErrors();

    try {
      await organizationsStore.updateOrganization(
        dataStore.currentOrganizationId,
        {
          name: form.values.name || '',
          sector: form.values.sector || '',
          description: form.values.description || '',
          default_language: form.values.default_language,
          network_enabled: form.values.network_enabled,

          default_net_project_state: form.values.default_net_project_state
            ? 'on'
            : 'off',
          default_net_painpoints_state: form.values.default_net_painpoints_state
            ? 'on'
            : 'off',
          default_net_benchmarks_state: form.values.default_net_benchmarks_state
            ? 'on'
            : 'off',
          default_net_hypotheses_state: form.values.default_net_hypotheses_state
            ? 'on'
            : 'off',
          default_net_prototypes_state: form.values.default_net_prototypes_state
            ? 'on'
            : 'off',
          default_net_learnings_state: form.values.default_net_learnings_state
            ? 'on'
            : 'off'
        }
      );

      if (!organizationsStore.isItemSaveError) {
        this.props.applicationStore.setFlashMessage(
          this.props.intl.formatMessage({ id: 'save success flash' })
        );
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { dataStore } = this.props;

    if (!attachment || !dataStore.currentOrganization) {
      return;
    }

    dataStore.currentOrganization.attachments.put(attachment);
  }

  attachmentRemoved(id: number) {
    const { dataStore } = this.props;

    if (!dataStore.currentOrganization) {
      return;
    }

    dataStore.currentOrganization.attachments.delete(id);
  }

  getNetworkSettings() {
    const { currentOrganization } = this.props.dataStore;
    if (!currentOrganization) {
      return [];
    }

    const fm = (id: string) => this.props.intl.formatMessage({ id });

    return [
      {
        description: fm('project and briefing'),
        propertyName: 'default_net_project_state',
        state: currentOrganization.default_net_project_state === 'on'
      },
      {
        description: fm('Painpoints'),
        propertyName: 'default_net_painpoints_state',
        state: currentOrganization.default_net_painpoints_state === 'on'
      },
      {
        description: fm('Benchmarks'),
        propertyName: 'default_net_benchmarks_state',
        state: currentOrganization.default_net_benchmarks_state === 'on'
      },
      {
        description: fm('Hypotheses'),
        propertyName: 'default_net_hypotheses_state',
        state: currentOrganization.default_net_hypotheses_state === 'on'
      },
      {
        description: fm('Prototypes'),
        propertyName: 'default_net_prototypes_state',
        state: currentOrganization.default_net_prototypes_state === 'on'
      },
      {
        description: fm('Learnings'),
        propertyName: 'default_net_learnings_state',
        state: currentOrganization.default_net_learnings_state === 'on'
      }
    ];
  }

  renderPage(content: any) {
    const org = this.props.dataStore.currentOrganization;

    let header;
    if (org) {
      const logo = org.attachments.firstOfType('logo');
      header = (
        <PageLogoHeader
          big={true}
          title={org.name}
          subtitle={org.sector}
          logo={
            logo &&
            logo.resource_urls && (
              <CompanyLogo
                imgUrl={logo.resource_urls.small}
                alt="Logo"
                big={true}
              />
            )
          }
        />
      );
    }

    return (
      <AppLayout active="settings" withSettings={true}>
        <ContextAwareOrgLayout active="settings">
          <PageHeader titleId="Settings" logoHeader={header} center={true} />

          <ContentWrapper>{content}</ContentWrapper>
        </ContextAwareOrgLayout>
      </AppLayout>
    );
  }

  renderError() {
    return this.renderPage(
      <ErrorMessage state={this.props.organizationsStore.itemLoadingState} />
    );
  }

  renderLoading() {
    return this.renderPage(<Loading />);
  }

  renderForm() {
    const { dataStore, organizationsStore, form, intl } = this.props;

    const org = dataStore.currentOrganization;
    if (!org) {
      return null;
    }

    const attachment = org.attachments.firstOfType('logo');

    // TODO
    const loading = false;

    return this.renderPage(
      <>
        {organizationsStore.isItemSaveError && (
          <ErrorMessage
            state={organizationsStore.itemLoadingState}
            onRetry={() => this.save()}
          />
        )}

        <Form loading={loading} onSubmit={() => this.save()} space={true}>
          <ColumnWrapper gap="3em">
            <RainbowEditWrapper settings={true}>
              {attachment && (
                <UploadedFileContainer
                  attachment={attachment}
                  onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                  key={attachment.id}
                >
                  {(props) => <UploadedImage medium={true} {...props} />}
                </UploadedFileContainer>
              )}
              {!attachment && (
                <UploadContainer
                  attachmentType="logo"
                  elementType={ElementType.Organization}
                  elementId={org.id}
                  onAttachmentReceived={(a) => this.attachmentReceived(a)}
                >
                  {(props: UploadContainerChildProps) => (
                    <UploadButton {...props} />
                  )}
                </UploadContainer>
              )}

              <RainbowEditWrapper.Body>
                <CardWrapper>
                  <Input
                    gap={true}
                    normalFont={true}
                    name="name"
                    label={<FormattedMessage id="Name" />}
                    {...form.bindInput('name')}
                  />
                  <Input
                    gap={true}
                    normalFont={true}
                    name="sector"
                    label={<FormattedMessage id="Sector" />}
                    {...form.bindInput('sector')}
                  />
                  <Textarea
                    gap={true}
                    normalFont={true}
                    name="description"
                    label={<FormattedMessage id="Description" />}
                    lines={5}
                    largeFont={true}
                    {...form.bindInput('description')}
                  />
                  <CardHeader
                    title={<FormattedMessage id="Language" />}
                    single={true}
                  />

                  <Select
                    name="default_language"
                    normalFont={true}
                    value={org.default_language || 'deutsch'}
                    {...form.bindInput('default_language')}
                  >
                    {Object.values(ProjectLanguageType).map((lang) => (
                      <option key={lang} value={lang}>
                        {lang[0].toLocaleUpperCase() + lang.slice(1)}
                      </option>
                    ))}
                  </Select>
                </CardWrapper>
              </RainbowEditWrapper.Body>
            </RainbowEditWrapper>

            {(((org.is_agency || !org.agency_id) && dataStore.isOrgAdmin) ||
              (org.agency_id && dataStore.isOrgAgency)) && (
              <>
                <div>
                  <h2>
                    <FormattedMessage id="feature network enabled header" />
                  </h2>

                  <Typography>
                    <FormattedMessage id="feature network enabled info" />
                  </Typography>
                  <SettingsTable
                    headers={[intl.formatMessage({ id: 'Enabled' })]}
                    rows={[
                      {
                        description: intl.formatMessage({
                          id: 'Network enabled'
                        }),
                        data: (
                          <ToggleSwitch
                            name="network_enabled"
                            label={<FormattedMessage id="Network enabled" />}
                            {...form.bindCheckbox('network_enabled')}
                          />
                        )
                      }
                    ]}
                  />
                </div>

                {form.values.network_enabled && (
                  <div>
                    <h2>
                      <FormattedMessage id="feature network visibility preset header" />
                    </h2>

                    <Typography>
                      <FormattedMessage id="feature network visibility preset info" />
                    </Typography>

                    <SettingsTable
                      headers={[intl.formatMessage({ id: 'Visibility' })]}
                      rows={this.getNetworkSettings().map(
                        ({ description, propertyName }) => ({
                          description,
                          data: (
                            <ToggleSwitch
                              name={propertyName}
                              label={description}
                              disabled={
                                !form.values.network_enabled ||
                                (propertyName !== 'default_net_project_state' &&
                                  !form.values.default_net_project_state)
                              }
                              {...form.bindCheckbox(propertyName)}
                            />
                          )
                        })
                      )}
                    />
                  </div>
                )}
              </>
            )}

            <PositionWrapper center={true}>
              <MainButton type="submit" disabled={loading}>
                <FormattedMessage id="Save" />
              </MainButton>
            </PositionWrapper>
          </ColumnWrapper>
        </Form>
      </>
    );
  }

  render() {
    const { organizationsStore } = this.props;

    if (organizationsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (organizationsStore.isItemLoadError) {
      return this.renderError();
    }

    return this.renderForm();
  }
}

export default injectIntl((props: PublicOrgSettingsScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <OrgSettingsScreen {...props} form={form} />;
});
