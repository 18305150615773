import { useEffect, useState } from 'react';

// tslint:disable: align

const MAX = 17;

// TODO format properly
export const AssistantGif = () => {
  const [num, setNum] = useState<number>(-1);
  useEffect(() => {
    setNum(Math.floor(Math.random() * (MAX + 1)));
  }, []);

  if (num < 0) {
    return null;
  }

  return (
    <img
      src={'/images/assistant/gif/source' + (num > MAX ? MAX : num) + '.gif'}
      alt=""
      style={{
        marginTop: '1.5rem',
        borderRadius: '0.625rem',
        width: '50%',
        height: 'auto'
      }}
    />
  );
};

export default AssistantGif;
