import { FormattedMessage } from 'react-intl';

import AddButton from 'components/AddButton';
import { UploadContainerChildProps } from 'containers/UploadContainer/UploadContainer';

interface UploadButtonProps {
  logo?: boolean;
}

// TODO error
export default ({
  onSelectFileClick,
  state,
  logo
}: UploadContainerChildProps & UploadButtonProps) => (
  <AddButton
    iconName="cloud-up"
    logo={logo}
    label={<FormattedMessage id="Add file" />}
    loading={state === 'saving' || state === 'waiting'}
    onClick={onSelectFileClick}
  />
);
