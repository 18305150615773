import { Route, Switch } from 'react-router';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import OrganizationContainer from 'containers/OrganizationContainer';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_CUSTOMERS_AGENCY
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import CreateCustomerScreen from 'screens/CustomersListScreen/CreateCustomerScreen';
import CustomersListScreen from 'screens/CustomersListScreen/CustomersListScreen';

const OrgErrorComponent = ({ error, onRetry }: any) => (
  <ErrorMessage state={error} onRetry={onRetry} />
);
const OrgLoaderComponent = () => <Loading />;

export default ({ match }: HistoryProps) => (
  <OrganizationContainer
    orgId={match.params?.agencyId}
    errorComponent={OrgErrorComponent}
    loaderComponent={OrgLoaderComponent}
  >
    <Switch>
      <Route path={ROUTE_CUSTOMERS_AGENCY} exact={true} component={CustomersListScreen} />
      <Route path={ROUTE_CUSTOMERS_AGENCY + '/create'} exact={true} component={CreateCustomerScreen} />

      <NotFoundRoute />
    </Switch>
  </OrganizationContainer>
);
