import classNames from 'classnames';

interface SuggestedSearchItemProps {
  value?: React.ReactNode;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function SuggestedSearchItem({
  value,
  active,
  onClick
}: SuggestedSearchItemProps) {
  const buttonClasses = classNames('suggested-search__button', {
    'suggested-search__button--active': active
  });

  return (
    <li className="suggested-search__item">
      <button
        className={buttonClasses}
        onClick={onClick}
        value={value?.toString()}
      >
        {value}
      </button>
    </li>
  );
}
