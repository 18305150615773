import { Route, Switch } from 'react-router';

import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import ProjectContainer from 'containers/ProjectContainer';
import BenchmarksRouter from 'screens/benchmarks/BenchmarksRouter';
import BriefingRouter from 'screens/briefing/BriefingRouter';
import HypothesesRouter from 'screens/hypotheses/HypothesesRouter';
import InvitationsRouter from 'screens/invitations/InvitationsRouter';
import LearningsRouter from 'screens/learnings/LearningsRouter';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import PainpointsRouter from 'screens/painpoints/PainpointsRouter';
import ProjectMembersScreen from 'screens/ProjectMembersScreen/ProjectMembersScreen';
import ProjectSettingsScreen from 'screens/ProjectSettingsScreen/ProjectSettingsScreen';
import PrototypesRouter from 'screens/prototypes/PrototypesRouter';
import {
  ROUTE_BENCHMARKS,
  ROUTE_BRIEFING,
  ROUTE_HYPOTHESES,
  ROUTE_INVITATIONS,
  ROUTE_LEARNINGS,
  ROUTE_PAINPOINTS,
  ROUTE_PROJECT_MEMBERS,
  ROUTE_PROJECT_SETTINGS,
  ROUTE_PROTOTYPES
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

const ProjectErrorComponent = ({ error, onRetry }: any) => (
  <ErrorMessage state={error} onRetry={onRetry} />
);
const ProjectLoaderComponent = () => <Loading />;

// AppLayout is rendered by Project container
export default ({ match }: HistoryProps) => (
  <ProjectContainer
    projectId={match.params?.projectId}
    errorComponent={ProjectErrorComponent}
    loaderComponent={ProjectLoaderComponent}
  >
    <Switch>
      <Route path={ROUTE_PAINPOINTS} component={PainpointsRouter} />
      <Route path={ROUTE_BENCHMARKS} component={BenchmarksRouter} />
      <Route path={ROUTE_HYPOTHESES} component={HypothesesRouter} />
      <Route path={ROUTE_PROTOTYPES} component={PrototypesRouter} />
      <Route path={ROUTE_LEARNINGS} component={LearningsRouter} />

      <Route path={ROUTE_INVITATIONS} component={InvitationsRouter} />

      <Route path={ROUTE_PROJECT_SETTINGS} component={ProjectSettingsScreen} />
      <Route path={ROUTE_PROJECT_MEMBERS} component={ProjectMembersScreen} />

      <Route path={ROUTE_BRIEFING} component={BriefingRouter} />

      <NotFoundRoute />
    </Switch>
  </ProjectContainer>
);
