import CompanyLogo from 'components/CompanyLogo';
import Headline from 'components/headline';
import Column from 'components/Layout/Column';
import Columns from 'components/Layout/Columns';
import Typography from 'components/Typography';
import { AssistantSessionSolutionModelType } from 'models/AssistantSessionSolutionModel';
import { BenchmarkModelType } from 'models/BenchmarkModel';
import GapSizes from 'utils/constants/gap-sizes';

export const AssistantItemSolution = ({
  solution,
  benchmark
}: {
  solution: AssistantSessionSolutionModelType;
  benchmark?: BenchmarkModelType;
}) => {
  const screen =
    solution?.attachments.firstOfType('screen')?.resource_urls?.small;
  const logo = benchmark?.attachments.firstOfType('logo')?.resource_urls?.small;

  return (
    <div className="success-principle-item">
      <Columns gap={GapSizes.NONE} columnTemplate="auto 1fr">
        <Column>
          <div className="logo-with-thumbnail">
            <div className="logo-with-thumbnail__thumbnail">
              {screen ? (
                <div className="phone-thumbnail">
                  <img src={screen} alt="Screen" />
                </div>
              ) : (
                <div className="phone-thumbnail-empty" />
              )}
            </div>
            <div className="logo-with-thumbnail__logo">
              {logo && <CompanyLogo alt="Logo" imgUrl={logo} />}
            </div>
          </div>
        </Column>
        <Column>
          <Headline tag="h4" size="XS" opaque={true}>
            {benchmark?.company_name || benchmark?.headline}
          </Headline>
          <Typography size="smaller">{solution.description}</Typography>
        </Column>
      </Columns>
    </div>
  );
};
