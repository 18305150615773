import { flow, getEnv, types } from 'mobx-state-tree';

import { BookmarkableEnumType } from './DataStore';
import { AdvancedStoreEnv } from './StoreEnv';

const BookmarksStore = types
  .model('BookmarksStore', {})
  .actions(self => {
    const createBookmark = flow(function*(
      elementType: BookmarkableEnumType,
      elementId: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const result: any = yield client.createBookmark(
          elementType,
          elementId
        );

        if (!result || !result.id) {
          throw new Error('No valid response from server');
        }

        dataStore.setBookmarkId(elementType, elementId, result.id);

        return result.bookmark_id;
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('BookmarksStore | createBookmark', error, error.body);
        }

        throw new Error('bookmark_error');
      }
    });

    const removeBookmark = flow(function*(
      bookmarkId: number,
      elementType?: BookmarkableEnumType,
      elementId?: number
    ) {
      const { client, dataStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.deleteBookmark(bookmarkId);
      } catch (error:any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line
          console.error('BookmarksStore | removeBookmark', error, error.body);
        }

        if (!client.isNotFound(error)) {
          throw new Error('bookmark_error');
        }
      }

      if (elementType && elementId) {
        dataStore.setBookmarkId(elementType, elementId, null);
      }
    });

    return {
      createBookmark,
      removeBookmark
    };
  })
  .views(self => {
    return {};
  });

export type BookmarksStoreType = typeof BookmarksStore.Type;
export default BookmarksStore;
