import { IAnyModelType, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

import {
  createFluidOrganizationModel,
  FluidOrganizationModel
} from './OrganizationModel';
import numberOrUndefined from 'utils/store/numberOrUndefined';

export const OrgServiceModel = types.model('OrgServiceModel', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  organization_id: types.maybe(types.number),
  organization: types.maybe(
    types.late((): IAnyModelType => FluidOrganizationModel)
  )
});

export const createOrgServiceModel = (
  data?: any
): OrgServiceModelType | undefined => {
  return OrgServiceModel.create({
    id: mandatoryId(data.id),
    name: data.name,
    content: data.content,
    organization_id: numberOrUndefined(data?.organization_id),
    organization: data?.organization
      ? createFluidOrganizationModel(data.organization)
      : undefined
  });
};

export type OrgServiceModelType = typeof OrgServiceModel.Type;
export default OrgServiceModel;
