import { Link } from 'react-router-dom';

import classNames from 'classnames';

import Icon from 'components/Icon';

interface MainNavItemProps {
  label: React.ReactNode | string;
  iconName: string;
  bubble?: React.ReactNode | string;
  active?: boolean;
  to: string;
}

export default function MainNavItem({
  label,
  iconName,
  bubble,
  active,
  to
}: MainNavItemProps) {
  const mainNavItemClasses = classNames('main-nav__link', {
    'main-nav__link--active': active
  });

  return (
    <li className="main-nav__item">
      <Link to={to} className={mainNavItemClasses}>
        {bubble && <div className="nav-bubble">{bubble}</div>}

        <span className="main-nav__icon">
          <Icon name={iconName} />
        </span>
        <span className="main-nav__label">{label}</span>
      </Link>
    </li>
  );
}
