import ActionOverlaySelectButton from 'components/ActionOverlaySelectButton';
import UserAvatar from 'components/Avatar/UserAvatar';
import CardSlider from 'components/CardSlider';
import CardWrapper from 'components/CardWrapper';
import CloseButton from 'components/CloseButton';
import ErrorMessage from 'components/ErrorMessage';
import Form from 'components/Form';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import Select from 'components/Inputs/Select';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import ObjectWrapper from 'components/Layout/ObjectWrapper';
import OverlayWrapper from 'components/Layout/OverlayWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import SimplePopup from 'components/SimplePopup';
import TabContainer from 'components/TabContainer';
import TabItem from 'components/TabContainer/TabItem';
import TagList from 'components/TagList';
import ActionsOverlayContainer from 'containers/ActionsOverlayContainer';
import ClustersContainer from 'containers/ClustersContainer';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import PainpointSubelementContainer from 'containers/PainpointSubelementContainer';
import { inject, observer } from 'mobx-react';
import { ActionsStoreType } from 'models/ActionsStore';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { HypothesisModelType } from 'models/HypothesisModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import { PrototypeModelType } from 'models/PrototypeModel';
import { tagsListToArray } from 'models/TagListModel';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import HypothesesListItem from 'screens/hypotheses/HypothesesListScreen/HypothesesListItem';
import PrototypesListItem from 'screens/prototypes/PrototypesListScreen/PrototypesListItem';
import { HistoryProps, isPush } from 'utils/history';
import { idFromProps } from 'utils/history/param-from-props';
import { scrollToTop } from 'utils/history/scroll-to';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

// tslint:disable: jsx-wrap-multiline

interface PublicPainpointDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
  create?: boolean;
  painpointId?: number;
  hideClose?: boolean;
}

interface PainpointDetailContainerProps
  extends PublicPainpointDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  actionsStore: ActionsStoreType;
  form: FormType;
}

interface PainpointDetailContainerState {}

@inject('applicationStore', 'dataStore', 'painpointsStore', 'actionsStore')
@observer
class PainpointDetailContainer extends React.Component<
  PainpointDetailContainerProps & HistoryProps,
  PainpointDetailContainerState
> {
  state: PainpointDetailContainerState = {};

  componentDidMount() {
    if (this.props.create) {
      this.props.dataStore.setPainpointItem(undefined);
      this.props.form.reset();

      const clusterId = parseInt(this.props.location.query?.cluster || 0, 10);
      if (!!clusterId) {
        this.props.form.setField('cluster_id', clusterId);
      }

      return;
    }

    const id = idFromProps(this.props) || this.props.painpointId;
    if (!id) {
      return;
    }

    const {
      dataStore: { painpointItem }
    } = this.props;
    if (
      isPush(this.props.history) ||
      !painpointItem ||
      painpointItem.id !== id
    ) {
      this.loadPainpoint(id);
    }
  }

  componentDidUpdate(
    prevProps: PublicPainpointDetailContainerProps & HistoryProps
  ) {
    const id = idFromProps(this.props) || this.props.painpointId;
    const prevId = idFromProps(prevProps) || prevProps.painpointId;

    if (this.props.create && !prevProps.create) {
      this.props.dataStore.setPainpointItem(undefined);
      this.props.form.reset();
      return;
    }

    if (id && id !== prevId) {
      this.loadPainpoint(id);
    }
  }

  load() {
    const id = idFromProps(this.props) || this.props.painpointId;

    if (id) {
      this.loadPainpoint(id);
    }
  }

  async loadPainpoint(id: number) {
    const { form, painpointsStore, dataStore } = this.props;

    form.reset();

    const painpoint = await painpointsStore.getPainpoint(
      id,
      dataStore.assistantSessionItem?.project.organization_id,
      dataStore.assistantSessionItem?.project.id
    );

    if (painpoint) {
      if (this.props.edit) {
        form.setField('question', painpoint.question);
        form.setField('cluster_id', painpoint.cluster?.id || -1);
      }
      form.setField('tags', tagsListToArray(painpoint.tags));
    }
  }

  async save() {
    const { dataStore, painpointsStore, form, create } = this.props;

    const patch: any = {
      ...form.values,
      publish_state: 'active'
    };

    try {
      if (!patch.cluster_id || patch.cluster_id === -1) {
        const clusters = painpointsStore.clustersByName;
        if (clusters.length > 0) {
          patch.cluster_id = clusters[0].id;
        }
      }

      let item = dataStore.painpointItem;
      if (create && !item) {
        item = await painpointsStore.createPainpoint(patch, true);
      } else {
        if (!item || (!create && item.id !== idFromProps(this.props))) {
          return;
        }

        // TODO update form after update?
        item = await painpointsStore.updatePainpoint(item.id, patch);
      }

      if (painpointsStore.isItemSaveError) {
        this.displayError();
      } else if (item) {
        this.props.history.replace(
          dataStore.contextUri + '/painpoints/' + item.id
        );
      }
    } catch (error: any) {
      if (handleFormError(form, error)) {
        scrollToTop();
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { dataStore, painpointsStore } = this.props;
    const item = dataStore.painpointItem;

    if (!item) {
      return;
    }

    try {
      await painpointsStore.updatePainpoint(item.id, {
        tags: newTags || []
      });
    } catch (e) {
      // TODO How to handle this?
    }
  }

  async remove() {
    const { intl, painpointsStore, dataStore, history } = this.props;

    if (
      !window.confirm(
        intl.formatMessage({ id: 'remove painpoints confirm' }, { count: 1 })
      )
    ) {
      return;
    }

    if (!dataStore.painpointItem) {
      return;
    }

    try {
      await painpointsStore.deletePainpoint(dataStore.painpointItem.id);

      // TODO use absolute path here?
      history.replace(dataStore.contextUri + '/painpoints');
    } catch (error: any) {
      this.displayError('remove');
    }
  }

  async saveFieldUpdate(names: string[]) {
    const { form, painpointsStore, dataStore } = this.props;

    if (!dataStore.painpointItem) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await painpointsStore.updatePainpoint(dataStore.painpointItem.id, patch);

      if (painpointsStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  editTitle() {
    const { form, dataStore } = this.props;
    const item = dataStore.painpointItem;

    if (!item) {
      return;
    }

    form.setField('question', item.question);
    form.setField('cluster_id', item.cluster?.id || '');

    form.setEditing('title');
  }

  renderPage(content: any) {
    const {
      dataStore: {
        painpointItem,
        contextUri,
        currentOrganizationId,
        currentProjectId
      },
      actionsStore,
      actionsStore: { selectionSet },
      intl,
      history,
      hideClose
    } = this.props;

    const checked: number[] = actionsStore.selectedIds.Painpoint;
    const isSelected =
      (painpointItem && checked.indexOf(painpointItem.id) > -1) || false;

    return (
      <>
        {content}

        {!hideClose && (
          <OverlayWrapper topRight={true} twoButtons={true}>
            <CloseButton
              label={intl.formatMessage({ id: 'Close' })}
              iconName="cross"
              onClick={() => history.push(contextUri + '/painpoints')}
            />
            <ActionOverlaySelectButton
              onClick={() => {
                const organizationId = currentOrganizationId!;
                const projectId = currentProjectId!;

                selectionSet(
                  {
                    Painpoint: [
                      {
                        id: painpointItem?.id!,
                        organizationId: organizationId,
                        projectId,
                        publishState: painpointItem?.publish_state
                      }
                    ]
                  },
                  !isSelected
                );
              }}
              selected={isSelected}
            />
          </OverlayWrapper>
        )}
      </>
    );
  }

  renderEditMode() {
    const { painpointsStore, form } = this.props;

    return this.renderPage(
      <>
        {painpointsStore.isItemSaving && <Loading />}

        <Form loading={form.loading} onSubmit={() => this.save()}>
          <PageHeader titleId="Painpoints" />
          <ContentWrapper>
            <FormTagList form={form} field="tags" allowAdd={true} />

            <ColumnWrapper gap="1em">
              {painpointsStore.isItemSaveError && (
                <CardWrapper>
                  <RowWrapper>
                    <FormattedMessage id="save error" />
                    <PositionWrapper end={true}>
                      <MainButton type="button" onClick={() => this.save()}>
                        <FormattedMessage id="Try again" />
                      </MainButton>
                    </PositionWrapper>
                  </RowWrapper>
                </CardWrapper>
              )}

              <CardWrapper>
                <ClustersContainer
                  containerType={(props: any) => (
                    <Select
                      name="cluster_id"
                      label={<FormattedMessage id="Cluster" />}
                      {...form.bindInput('cluster_id')}
                    >
                      {props.children}
                    </Select>
                  )}
                  itemType={(props: any) =>
                    !props.cluster ? null : (
                      <option value={props.cluster.id}>
                        {props.cluster.name}
                      </option>
                    )
                  }
                />
                <p>&nbsp;</p>

                <Input
                  label={<FormattedMessage id="painpoint question prefix" />}
                  name="question"
                  {...form.bindInput('question')}
                />
              </CardWrapper>

              <RowWrapper gap="1em" alignRight={true}>
                <MainButton type="submit">
                  <FormattedMessage id="Save" />
                </MainButton>
              </RowWrapper>
            </ColumnWrapper>
          </ContentWrapper>
        </Form>
      </>
    );
  }

  renderViewMode() {
    const { dataStore, painpointsStore, intl, form } = this.props;
    const item = dataStore.painpointItem;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author);

    const prototypes: PrototypeModelType[] = item.sortedPrototypes;
    const hypotheses: HypothesisModelType[] = item.sortedHypotheses;

    return this.renderPage(
      <>
        {painpointsStore.isItemSaving && form.editing !== 'title' && (
          <Loading />
        )}

        <PageHeader
          titleId="Painpoints"
          headline={
            <FormattedMessage
              id="painpoint question full"
              values={{ question: item.question }}
            />
          }
          avatar={item.author && <UserAvatar user={item.author} />}
          onEditClick={!mayEdit ? undefined : () => this.editTitle()}
        />

        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            <TagList tags={item.tags} />
          )}

          <ColumnWrapper gap="1em">
            <ObjectWrapper>
              <PainpointSubelementContainer type="Aim" painpoint={item} />
              <PainpointSubelementContainer type="Question" painpoint={item} />

              {(hypotheses.length > 0 || prototypes.length > 0) && (
                <TabContainer>
                  {hypotheses.length > 0 && (
                    <TabItem
                      key="hypotheses"
                      title={`${intl.formatMessage({ id: 'Hypotheses' })} (${
                        hypotheses.length
                      })`}
                    >
                      <CardSlider>
                        {hypotheses.map((hypothesis) => (
                          <HypothesesListItem
                            key={hypothesis.id}
                            hypothesis={hypothesis}
                            contextUri={dataStore.contextUri}
                            readOnly={true}
                          />
                        ))}
                      </CardSlider>
                    </TabItem>
                  )}

                  {prototypes.length > 0 && (
                    <TabItem
                      key="prototypes"
                      title={`${intl.formatMessage({ id: 'Prototypes' })} (${
                        prototypes.length
                      })`}
                    >
                      <CardSlider value={prototypes.length}>
                        {prototypes.map((prototype) => (
                          <PrototypesListItem
                            key={prototype.id}
                            prototype={prototype}
                            contextUri={dataStore.contextUri}
                            readOnly={true}
                          />
                        ))}
                      </CardSlider>
                    </TabItem>
                  )}
                </TabContainer>
              )}
            </ObjectWrapper>
            {item.publish_state !== 'draft' && (
              <CommentsContainer
                elementType={ElementType.Painpoint}
                element={item}
              />
            )}
          </ColumnWrapper>
        </ContentWrapper>

        {form.editing === 'title' && (
          <Form disabled={painpointsStore.isItemSaving}>
            <SimplePopup
              onAbort={() => form.setEditing()}
              onSubmit={() => this.saveFieldUpdate(['question', 'cluster_id'])}
              isLoading={painpointsStore.isItemSaving}
            >
              <ClustersContainer
                containerType={(props: any) => (
                  <Select
                    name="cluster_id"
                    label={<FormattedMessage id="Cluster" />}
                    {...form.bindInput('cluster_id')}
                  >
                    {props.children}
                  </Select>
                )}
                itemType={(props: any) =>
                  !props.cluster ? null : (
                    <option value={props.cluster.id}>
                      {props.cluster.name?.toString().toUpperCase() || '-'}
                    </option>
                  )
                }
              />
              <p>&nbsp;</p>

              <Textarea
                autoFocus={true}
                lines={3}
                label={<FormattedMessage id="painpoint question" />}
                predefined_text={
                  <FormattedMessage id="painpoint question prefix" />
                }
                name="question"
                {...form.bindInput('question')}
                onKeyPress={(e: any) => {
                  if (e.charCode === 13) {
                    e.preventDefault();
                    this.saveFieldUpdate(['question', 'cluster_id']);
                  }
                }}
              />
            </SimplePopup>
          </Form>
        )}
        {!painpointsStore.isListBusy && (
          <ActionsOverlayContainer
            showCopy={true}
            showAiBenchmark={true}
            showAiBriefing={true}
            showDelete={
              this.props.actionsStore.selectedFromProjectIds.length === 1 &&
              this.props.actionsStore.selectedFromProjectIds[0] ===
                dataStore.currentProjectId
            }
            showShare={true}
          />
        )}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(
      <>
        <PageHeader titleId="Painpoints" />
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        <PageHeader titleId="Painpoints" />
        <ErrorMessage
          state={this.props.painpointsStore.itemLoadingState}
          onRetry={() => this.load()}
        />
      </>
    );
  }

  render() {
    const { painpointsStore, create, edit } = this.props;

    if (painpointsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (painpointsStore.isItemLoadError) {
      return this.renderError();
    }

    if (create || edit) {
      return this.renderEditMode();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicPainpointDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <PainpointDetailContainer {...props} form={form} />;
});
