import { Link, LinkProps } from 'react-router-dom';

import classNames from 'classnames';

import CircleChart from 'components/CircleChart/CircleChart';
import Icon from 'components/Icon';
import React from 'react';

interface SubNavItemProps {
  title: React.ReactNode | string;
  itemNumber?: React.ReactNode | number;
  itemLimit?: number;
  iconName: string;
  active?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  progress?: number;
  to: LinkProps['to'];
  onClick?: () => void;
}

export default function SubNavItem({
  title,
  itemNumber,
  itemLimit,
  iconName,
  active,
  to,
  inactive,
  disabled,
  progress,
  onClick
}: SubNavItemProps) {
  const subNavItemClasses = classNames('sub-nav__link', {
    'sub-nav__link--active': active,
    'sub-nav__link--inactive': disabled || (inactive && !active)
  });

  if (disabled) {
    return (
      <li className="sub-nav__item">
        <div className={subNavItemClasses}>
          {progress !== undefined && (
            <span className="sub-nav__progress">
              <CircleChart progress={progress} />
            </span>
          )}
          <span className="sub-nav__icon">
            <Icon name={iconName} />
          </span>
          <span className="sub-nav__label">
            {title}
            {/*!!itemNumber && <span className="sub-nav__number">&nbsp;({itemNumber})</span>*/}
          </span>
        </div>
      </li>
    );
  }

  const ComponentElement = ({ children }: any) =>
    onClick ? (
      <span className={subNavItemClasses} onClick={onClick}>
        {children}
      </span>
    ) : (
      <Link to={to} className={subNavItemClasses}>
        {children}
      </Link>
    );

  return (
    <li className="sub-nav__item">
      <ComponentElement>
        {progress !== undefined && (
          <span className="sub-nav__progress">
            <CircleChart progress={progress} />
          </span>
        )}
        <span className="sub-nav__icon">
          <Icon name={iconName} />
        </span>
        <span className="sub-nav__label">
          {title}
          {!!itemNumber && (
            <span className="sub-nav__number">&nbsp;({itemNumber}{!!itemLimit ? `/${itemLimit}` : '' })</span>
          )}
        </span>
      </ComponentElement>
    </li>
  );
}
