import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface CompanyLogoProps {
  alt: string;
  imgUrl?: string;
  linkTo?: string;
  big?: boolean;
}

export default function CompanyLogo({
  alt,
  imgUrl,
  linkTo,
  big
}: CompanyLogoProps) {
  const companyLogoClasses = classNames('company-logo', {
    'company-logo--big': big
  });

  const figure = () => {
    if (imgUrl) {
      return (
        <figure className={companyLogoClasses}>
          <img className="company-logo__image" src={imgUrl} alt={alt} />
        </figure>
      );
    }
  };

  if (linkTo) {
    return (
      <figure className={companyLogoClasses} tabIndex={0}>
        <Link to={linkTo}>
          <img className="company-logo__image" src={imgUrl} alt={alt} />
        </Link>
      </figure>
    );
  } else {
    return <>{figure()}</>;
  }
}
