import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import CardHeader from 'components/CardHeader';
import ContentWrapper from 'components/Layout/ContentWrapper';
import RainbowEditWrapper from 'components/Layout/RainbowEditWrapper';
import MobilePreview from 'components/MobilePreview';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import RainbowCard from 'components/RainbowCard/RainbowCard';
import TagList from 'components/TagList';
import Typography from 'components/Typography';
import UploadedMobileImage from 'components/UploadedImage/UploadedMobileImage';
import SharedContentDetailContainer from 'containers/SharedContentContainer/SharedContentDetailContainer';
import { SharedContentStoreType } from 'models/SharedContentStore';
import { HistoryProps, idFromMatch } from 'utils/history';

// tslint:disable: jsx-wrap-multiline

interface SharedHypothesisScreenProps extends WrappedComponentProps {
  sharedContentStore: SharedContentStoreType;
}

@inject('sharedContentStore')
@observer
class SharedHypothesisScreen extends React.Component<
  SharedHypothesisScreenProps & HistoryProps
> {
  renderNotFound() {
    return (
      <SharedContentDetailContainer notFound={true} itemType="hypotheses" />
    );
  }

  render() {
    const { sharedContentStore, intl } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return this.renderNotFound();
    }

    const item = sharedContentStore.hypotheses?.get(id?.toString());
    if (!item) {
      return this.renderNotFound();
    }

    const attachment = item.attachment;

    const phone =
      attachment && attachment.resource_urls ? (
        <UploadedMobileImage attachment={attachment} overflow={true} />
      ) : (
        <MobilePreview alt="Empty attachment" />
      );

    return (
      <SharedContentDetailContainer itemType="hypotheses">
        <PageHeader
          titleId="Hypotheses"
          logoHeader={
            <PageLogoHeader
              title={item.headline || intl.formatMessage({ id: 'no headline' })}
            />
          }
        />

        <ContentWrapper>
          <TagList tags={item.tags} />

          <RainbowEditWrapper>
            <RainbowEditWrapper.Image>{phone}</RainbowEditWrapper.Image>
            <RainbowEditWrapper.Body>
              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="solution description" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {item.description || <FormattedMessage id="no content yet" />}
                </Typography>
              </RainbowCard>

              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="problem description" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {item.problem || <FormattedMessage id="no content yet" />}
                </Typography>
              </RainbowCard>

              <RainbowCard>
                <CardHeader
                  title={<FormattedMessage id="added value description" />}
                  single={true}
                />

                <Typography size="small" breaks={true}>
                  {item.added_value || <FormattedMessage id="no content yet" />}
                </Typography>
              </RainbowCard>
            </RainbowEditWrapper.Body>
          </RainbowEditWrapper>
        </ContentWrapper>
      </SharedContentDetailContainer>
    );
  }
}

export default injectIntl(SharedHypothesisScreen);
