import { inject, observer } from 'mobx-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Loading from 'components/Loading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import DashboardScreen from 'screens/DashboardScreen/DashboardScreen';
import LogoutScreen from 'screens/HomeScreen/LogoutScreen';
import LabScreen from 'screens/LabScreen';
import NetworkScreen from 'screens/NetworkScreen';
import { NavbarNotFoundRoute } from 'screens/NotFoundScreen';
import SearchScreen from 'screens/SearchScreen';
import AssistantBenchmarkSessionRouter from 'screens/assistant/AssistantBenchmarkSessionRouter';
import AssistantListScreen from 'screens/assistant/AssistantListScreen';
import AssistantPrototypSessionRouter from 'screens/assistant/AssistantPrototypeSessionRouter';
import AssistantSessionRouter from 'screens/assistant/AssistantSessionRouter';
import EnterSessionScreen from 'screens/assistant/EnterSessionScreen';
import {
  ROUTE_ASSISTANT,
  ROUTE_ASSISTANT_BENCHMARK_SESSION,
  ROUTE_ASSISTANT_PROTOTYP_SESSION,
  ROUTE_ASSISTANT_SESSION,
  ROUTE_AUTH,
  ROUTE_CUSTOMERS_AGENCY,
  ROUTE_FAQ_PAGES,
  ROUTE_LAB,
  ROUTE_NETWORK,
  ROUTE_ORG,
  ROUTE_PERFORM_LOGOUT,
  ROUTE_PROFILE,
  ROUTE_SEARCH
} from 'utils/constants/routes';
import CustomerRouter from './CustomerRouter';
import FaqPagesRouter from './FaqPagesRouter';
import OrganizationRouter from './OrganizationRouter';
import ProfileRouter from './ProfileRouter';

interface AuthenticationRouterProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class AuthenticationRouter extends React.Component<AuthenticationRouterProps> {
  componentDidMount() {
    const store = this.props.applicationStore;
    if (!store.isAuthenticated && !store.isAuthenticating) {
      // make request to check if user is logged in
      store.checkAuthenticated(true);
    }
  }

  renderLoader() {
    return <Loading />;
  }

  render() {
    if (this.props.applicationStore.isAuthenticating) {
      return this.renderLoader();
    }

    if (!this.props.applicationStore.isAuthenticated) {
      // this should not happen as ApplicationStore redirects to login
      // but just to be sure
      return this.renderLoader();
    }

    return (
      <Switch>
        <Route path={ROUTE_PROFILE} component={ProfileRouter} />

        <Route path={ROUTE_AUTH} exact={true} component={DashboardScreen} />
        <Route path={ROUTE_PERFORM_LOGOUT} component={LogoutScreen} />

        <Route path={ROUTE_LAB} exact={true} component={LabScreen} />

        <Route path={ROUTE_CUSTOMERS_AGENCY} component={CustomerRouter} />

        <Route path={ROUTE_ORG} component={OrganizationRouter} />

        <Route
          path={ROUTE_ASSISTANT}
          exact={true}
          component={AssistantListScreen}
        />

        <Route
          path={ROUTE_ASSISTANT_BENCHMARK_SESSION}
          exact={true}
          component={EnterSessionScreen}
        />
        <Route
          path={ROUTE_ASSISTANT_BENCHMARK_SESSION}
          component={AssistantBenchmarkSessionRouter}
        />
        <Route
          path={ROUTE_ASSISTANT_PROTOTYP_SESSION}
          component={AssistantPrototypSessionRouter}
        />

        <Route
          path={ROUTE_ASSISTANT_SESSION}
          exact={true}
          component={EnterSessionScreen}
        />
        <Route
          path={ROUTE_ASSISTANT_SESSION}
          component={AssistantSessionRouter}
        />

        <Route path={ROUTE_NETWORK} component={NetworkScreen} />

        <Route path={ROUTE_SEARCH} component={SearchScreen} />

        <Route path={ROUTE_FAQ_PAGES} component={FaqPagesRouter} />

        <NavbarNotFoundRoute />
      </Switch>
    );
  }
}

export default AuthenticationRouter;
