import AddButton from 'components/AddButton';
import UserAvatar from 'components/Avatar/UserAvatar';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import EditMenu from 'components/EditMenu';
import Textarea from 'components/Inputs/Textarea';
import MenuButton from 'components/MenuButton';
import RainbowCardSmall from 'components/RainbowCard/RainbowCardSmall';
import Typography from 'components/Typography';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { PainpointAimModelType } from 'models/PainpointAimModel';
import { PainpointModelType } from 'models/PainpointModel';
import { PainpointQuestionModelType } from 'models/PainpointQuestionModel';
import { PainpointsStoreType } from 'models/PainpointsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

interface PublicPainpointSubelementItemProps extends WrappedComponentProps {
  type: 'Aim' | 'Question';
  painpoint: PainpointModelType;
  element?: PainpointAimModelType | PainpointQuestionModelType;
  index?: number;
}

interface PainpointSubelementItemProps
  extends PublicPainpointSubelementItemProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  painpointsStore: PainpointsStoreType;
  form: FormType;
}

// tslint:disable: jsx-wrap-multiline

@inject('applicationStore', 'dataStore', 'painpointsStore')
@observer
class PainpointSubelementItem extends React.Component<PainpointSubelementItemProps> {
  async save() {
    const { form, painpointsStore, type, painpoint, element } = this.props;

    const text = (form.values.text || '').toString().trim();
    if (text === '') {
      this.finishEdit();
      return;
    }

    form.setLoading(true);

    const patch =
      type === 'Aim'
        ? {
            aim: text
          }
        : {
            question: text
          };

    try {
      if (!element) {
        await painpointsStore.createSubelement(painpoint, type, patch);
      } else {
        await painpointsStore.updateSubelement(
          painpoint,
          type,
          element.id,
          patch
        );
      }
    } catch (error: any) {
      this.displayError();
      form.setLoading(false);
      return;
    }

    this.finishEdit();
    form.setLoading(false);
  }

  async remove() {
    const { intl, form, painpointsStore, type, painpoint, element } =
      this.props;
    if (!element) {
      return;
    }

    if (
      !window.confirm(
        intl.formatMessage({
          id:
            type === 'Aim'
              ? 'painpoint remove aim confirm'
              : 'painpoint remove question confirm'
        })
      )
    ) {
      return;
    }

    form.setLoading(true);

    try {
      await painpointsStore.deleteSubelement(painpoint, type, element.id);
    } catch (error: any) {
      this.displayError('remove');
      form.setLoading(false);
    }
  }

  edit(text?: string) {
    const { form } = this.props;

    form.reset();
    form.setField('text', text || '');

    form.setEditing('text');
  }

  finishEdit() {
    this.props.form.setEditing();
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  formatIndex(index?: number): string {
    if (!index) {
      return '';
    }

    let str = index.toString();
    if (str.length < 2) {
      str = '0' + str;
    }

    return str;
  }

  renderEdit() {
    const { form } = this.props;

    // TODO: Prevent the layout from being rearranged when the loading text is displayed.

    return (
      <>
        {/* {form.loading && <em>loading...</em>} */}
        <Textarea
          name="text"
          slider={true}
          {...form.bindInput('text')}
          disabled={form.loading}
          autoFocus={true}
          onFocus={(e: any) => e.target.select()}
          onBlur={(e) => {
            if (!form.loading) {
              this.save();
            }
          }}
          onKeyUp={(e: any) => {
            if (e.keyCode === 27) {
              this.finishEdit();
            }
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && !form.loading) {
              e.preventDefault();
              e.stopPropagation();
              this.save();
            }
          }}
        />
      </>
    );
  }

  renderView() {
    const { type, element, form, dataStore, intl, index } = this.props;
    if (!element) {
      // satisfy ts
      return null;
    }

    let text: string | undefined;
    switch (type) {
      case 'Aim':
        text = (element as PainpointAimModelType).aim;
        break;

      case 'Question':
        text = (element as PainpointQuestionModelType).question;
        break;

      default:
    }

    const mayEdit = dataStore.mayEdit(element.author);

    let menu;
    if (mayEdit) {
      menu = (
        <EditMenu bottomRight={true} label={intl.formatMessage({ id: 'Edit' })}>
          <MenuButton
            label={intl.formatMessage({ id: 'Edit' })}
            iconName="pen"
            onClick={() => this.edit(text)}
          />
          <MenuButton
            label={intl.formatMessage({ id: 'Remove' })}
            iconName="bin"
            onClick={() => this.remove()}
          />
        </EditMenu>
      );
    }

    return (
      <RainbowCardSmall gray={true}>
        <CardHeader
          title={
            <FormattedMessage
              id={type === 'Aim' ? 'Aim' : 'Detail question'}
              values={{
                index: this.formatIndex(index)
              }}
            />
          }
          simple={true}
          onEditClick={mayEdit ? () => this.edit(text) : undefined}
        />

        {form.loading && <em>loading...</em>}

        <Typography size="small">{text}</Typography>

        {(menu || element.author) && (
          <CardMenu
            avatar={element.author && <UserAvatar user={element.author} />}
            small={true}
          >
            {menu}
          </CardMenu>
        )}
      </RainbowCardSmall>
    );
  }

  renderButton() {
    const { type } = this.props;

    return (
      <AddButton
        iconName="plus"
        label={
          <FormattedMessage
            id={type === 'Aim' ? 'Add aim' : 'Add detail question'}
          />
        }
        onClick={() => this.edit()}
      />
    );
  }

  render() {
    if (this.props.form.editing === 'text') {
      return this.renderEdit();
    }

    if (!this.props.element) {
      return this.renderButton();
    }

    return this.renderView();
  }
}

export default injectIntl((props: PublicPainpointSubelementItemProps) => {
  const form = useForm();
  // @ts-ignore
  return <PainpointSubelementItem {...props} form={form} />;
});
