import { IAnyModelType, types } from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';

import { AssistantTypeEnum } from './AssistantSessionModel';
import { createFluidProjectModel, FluidProjectModel } from './ProjectModel';
import { createFluidUserModel, FluidUserModel } from './UserModel';

export const AssistantInvitationModel = types.model(
  'AssistantInvitationModel',
  {
    id: types.identifierNumber,
    assistant_type: AssistantTypeEnum,
    author: types.maybe(FluidUserModel),
    project: types.maybe(types.late((): IAnyModelType => FluidProjectModel)),

    invitation_text: types.maybe(types.string),

    progress: types.number,
    invited: types.maybe(types.boolean),
    finished: types.maybe(types.boolean),
    published: types.maybe(types.boolean),

    created_at: types.maybe(types.string),
    updated_at: types.maybe(types.string)
  }
);

export const createAssistantInvitationModel = (
  data: any
): AssistantInvitationModelType =>
  AssistantInvitationModel.create({
    id: mandatoryId(data?.id),
    assistant_type: AssistantTypeEnum.is(data?.assistant_type)
      ? data.assistant_type
      : 'creative_session',
    author: createFluidUserModel(data?.author),
    project: createFluidProjectModel(data?.project),

    invitation_text: data?.invitation_text || undefined,

    progress: data?.progress || 0,
    invited: booleanOrUndefined(data?.invited),
    finished: booleanOrUndefined(data?.finished),
    published: booleanOrUndefined(data?.published),

    created_at: data?.created_at || undefined,
    updated_at: data?.updated_at || undefined
  });

export type AssistantInvitationModelType = typeof AssistantInvitationModel.Type;
export default AssistantInvitationModel;
