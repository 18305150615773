import { injectIntl, WrappedComponentProps } from 'react-intl';

import UserAvatar from 'components/Avatar/UserAvatar';
import Bookmark from 'components/Bookmark';
import { BookmarkRemovedEvent } from 'components/Bookmark/Bookmark';
import CardHeader from 'components/CardHeader';
import CardMenu from 'components/CardMenu';
import IconCounter from 'components/IconCounter';
import RainbowCardSmall from 'components/RainbowCard/RainbowCardSmall';
import RaisedHand from 'components/RaisedHand/RaisedHand';
import Typography from 'components/Typography';
import { PainpointModelType } from 'models/PainpointModel';
import PainpointsListItemMenu, {
  PainpointsListItemMenuProps
} from './PainpointsListItemMenu';

export interface PainpointsListItemProps extends PainpointsListItemMenuProps {
  painpoint: PainpointModelType;
  readOnly?: boolean;
  noLink?: boolean;
  contextUri?: string;
  top?: React.ReactNode;

  checked?: boolean;
  onChange?: (checked: boolean) => void;

  onClick?: (e: any) => void;
  onBigRemoveClick?: () => void;
  onBookmarkRemoved?: BookmarkRemovedEvent;
}

// TODO display top
export default injectIntl(
  ({
    intl,

    painpoint,
    readOnly,
    noLink,
    contextUri,
    top,

    checked,
    onChange,

    onClick,
    onBigRemoveClick,
    onBookmarkRemoved,

    ...menuProps
  }: PainpointsListItemProps & WrappedComponentProps) => {
    return (
      <RainbowCardSmall
        to={
          noLink
            ? undefined
            : (contextUri ? contextUri + '/' : '') +
              'painpoints/' +
              painpoint.id
        }
        checked={checked}
        onCheckboxChange={onChange}
        sideLabel={
          painpoint.publish_state === 'draft'
            ? intl.formatMessage({ id: 'Draft' })
            : undefined
        }
        onRemoveClick={onBigRemoveClick}
        onClick={onClick}
      >
        <CardHeader
          simple={true}
          title={intl.formatMessage({ id: 'painpoint question prefix' })}
        />

        <Typography size="small">{painpoint.question}</Typography>

        {painpoint.requesting_user && (
          <RaisedHand
            label={painpoint.requesting_user.initials}
            name={painpoint.requesting_user.fullName}
          />
        )}

        <CardMenu
          small={true}
          avatar={
            <UserAvatar
              user={painpoint.author}
              isAiRobot={painpoint.created_by_ai}
            />
          }
        >
          <Bookmark
            elementType="Painpoint"
            elementId={painpoint.id}
            count={painpoint.bookmarks_count}
            id={painpoint.bookmark_id}
            disabled={readOnly}
            onRemoved={onBookmarkRemoved}
          />
          <IconCounter
            value={painpoint.comments_count || 0}
            label={intl.formatMessage({ id: 'Comments' })}
            iconName="comment"
          />

          <PainpointsListItemMenu painpoint={painpoint} {...menuProps} />
        </CardMenu>
      </RainbowCardSmall>
    );
  }
);
