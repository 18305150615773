import { injectIntl, WrappedComponentProps } from 'react-intl';

import AutoGrid from 'components/Layout/AutoGrid';
import NoneFoundInPopupComponent from 'components/PainpointSelectPopup/NoneFoundInPopupComponent';
import SearchForm from 'components/SearchForm/SelectPopupSearchForm';
import SimplePopup from 'components/SimplePopup';
import BenchmarksListContainer from 'containers/BenchmarksListContainer';
import useForm, { FormType } from 'utils/hooks/useForm';

import BenchmarkSelectPopupItem from './BenchmarkSelectPopupItem';

const toggle = (form: FormType, id: number) => {
  if (form.values.selected && form.values.selected.indexOf(id) > -1) {
    form.setField(
      'selected',
      form.values.selected.filter((elem: number) => elem !== id)
    );
  } else {
    const old = form.values.selected || [];
    form.setField('selected', old.concat([id]));
  }
};

export default injectIntl(
  ({
    selected,
    onAbort,
    onSelect,
    intl
  }: {
    selected?: number[];
    onAbort: () => void;
    onSelect: (id: number | number[]) => void;
  } & WrappedComponentProps) => {
    const form = useForm({ filter: '', selected: selected || [] });

    return (
      <SimplePopup
        onSubmit={() => onSelect(form.values.selected || [])}
        onAbort={onAbort}
        headlineTextId="Select benchmark"
        submitTextId="Select"
      >
        <SearchForm
          name="filter"
          placeholder={intl.formatMessage({ id: 'Benchmarks search' })}
          {...form.bindInput('filter')}
        />
        <div className="simple-popup__accent-body simple-popup__scrollable-body">
          <BenchmarksListContainer
            itemType={BenchmarkSelectPopupItem}
            itemContainer={AutoGrid}
            emptyComponent={NoneFoundInPopupComponent}
            selected={form.values.selected}
            onItemClick={(id) => toggle(form, id)}
            filter={(form.values.filter || '').toString().trim()}
          />
        </div>
      </SimplePopup>
    );
  }
);
