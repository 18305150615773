import Loading from 'components/Loading';
import ToggleSwitch from 'components/ToggleSwitch';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { ItemLoadingStateEnumType } from 'models/LoadingStateEnums';
import {
  EditorType,
  ProjectsStoreType,
  ViewerType
} from 'models/ProjectsStore';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import PROJECT_ACCESS_LEVELS from 'utils/constants/project-access-levels';

// tslint:disable-next-line: no-empty-interface
interface PublicProjectMemberRowProps extends WrappedComponentProps {
  type: 'viewer' | 'editor';
  member: ViewerType | EditorType;
}

interface ProjectMemberRowProps extends PublicProjectMemberRowProps {
  projectsStore: ProjectsStoreType;
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType
}

interface ProjectMemberRowState {
  loadingState?: ItemLoadingStateEnumType;
  newState?: boolean;
}

@inject('projectsStore', 'applicationStore', 'dataStore')
@observer
class ProjectMemberRow extends React.Component<
  ProjectMemberRowProps,
  ProjectMemberRowState
> {
  state: ProjectMemberRowState = {};

  async update(shouldBeMember: boolean) {
    const { projectsStore, member, applicationStore, intl } = this.props;

    if (!member?.user?.id) {
      return;
    }

    this.setState({
      loadingState: 'saving',
      newState: shouldBeMember
    });

    try {
      if (shouldBeMember) {
        await projectsStore.createOrUpdateMembership(
          member.user.id,
          PROJECT_ACCESS_LEVELS.MEMBER
        );
      } else {
        await projectsStore.deleteMembership(member.user.id);
      }

      this.setState({
        loadingState: undefined,
        newState: undefined
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'project member flash' })
      );
    } catch (error: any) {
      this.setState({
        loadingState: 'save_error',
        newState: undefined
      });

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'project member error flash' }),
        'error'
      );
    }
  }

  render() {
    const { intl, member, type, dataStore } = this.props;
    const { newState, loadingState } = this.state;

    const isAdmin = (member as EditorType).orgAccessLevel === 'admin';
    const isPrivate = dataStore!.project!.private;
    const checked =
      newState === true || newState === false
        ? newState
        : member.isProjectMember;

    const name = member.user.fullName || member.user.email;

    // TODO loading state should be inline in row
    // TODO disabled state for admins needs layout
    return (
      <tr key={member.user.id}>
        <td className="table__row-description">
          {isAdmin ? intl.formatMessage({ id: 'admin label' }, { name }) : name}
        </td>
        <td className="table__row-data">
          <ToggleSwitch
            name={type + '_' + member.user.id}
            disabled={!isPrivate && isAdmin}
            checked={(!isPrivate && isAdmin) || checked ? true : false}
            onChange={({ target }) => this.update(target.checked)}
            label={intl.formatMessage({
              id: type === 'viewer' ? 'may view' : 'may edit'
            })}
          />
        </td>
        {loadingState === 'saving' && <Loading />}
      </tr>
    );
  }
}

export default injectIntl((props: PublicProjectMemberRowProps) => {
  // const form = useForm();
  // @ts-ignore
  return <ProjectMemberRow {...props} />;
});
