import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import UserAvatar from 'components/Avatar/UserAvatar';
import BenchmarkSelectPopupItem from 'components/BenchmarkSelectPopup/BenchmarkSelectPopupItem';
import Textarea from 'components/Inputs/Textarea';
import AutoGrid from 'components/Layout/AutoGrid';
import Loading from 'components/Loading';
import RainbowCard from 'components/RainbowCard';
import SelectPopupSearchForm from 'components/SearchForm/SelectPopupSearchForm';
import SelectCounter from 'components/SelectCounter';
import SimplePopup from 'components/SimplePopup';
import BenchmarksListContainer from 'containers/BenchmarksListContainer';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AssistantType } from 'models/AssistantSessionModel';
import { AssistantStoreType } from 'models/AssistantStore';
import { DataStoreType } from 'models/DataStore';
import { OrganizationsStoreType } from 'models/OrganizationsStore';
import { OrgMemberModelType } from 'models/OrgMemberModel';
import useForm from 'utils/hooks/useForm';
import NoBenchmarksForInviteComponent from './NoBenchmarksForInviteComponent';
import { useIntl } from 'react-intl';

interface InviteFlowProps {
  active?: boolean;
  assistantType?: AssistantType;
  onFinish: () => void;

  painpointId?: number;
  hypothesisId?: number;

  dataStore?: DataStoreType;
  organizationsStore?: OrganizationsStoreType;
  assistantStore?: AssistantStoreType;
  applicationStore?: ApplicationStoreType;
}

export default inject(
  'dataStore',
  'organizationsStore',
  'assistantStore',
  'applicationStore'
)(
  observer(
    ({
      active,
      assistantType,
      onFinish,
      painpointId,
      hypothesisId,
      dataStore,
      organizationsStore,
      assistantStore,
      applicationStore
    }: InviteFlowProps) => {
      const intl = useIntl();

      const [mode, setMode] = useState<
        'members' | 'text' | 'benchmarks' | undefined
      >();
      const form = useForm({
        selectedMembers: [],
        invitationText: '',
        membersFilter: '',
        benchmarksFilter: '',
        selectedBenchmarks: []
      });

      useEffect(() => {
        if (!active || !assistantType) {
          return;
        }

        const loadMembers = () => {
          if (!dataStore!.currentOrganizationId) {
            return;
          }
          organizationsStore!.getMembers(
            dataStore!.currentOrganizationId,
            true,
            true
          );
        };

        form.reset();
        form.setField(
          'invitationText',
          intl.formatMessage(
            { id: 'invitation template ' + assistantType },
            {
              project: dataStore!.currentProject?.topic || 'N/A',
              inviter: dataStore!.currentUser?.first_name || ''
            }
          )
        );

        setMode('members');
        loadMembers();
        // tslint:disable-next-line: align
      }, [active, assistantType]);

      if (!active || !assistantType) {
        return null;
      }

      const { selectedMembers, selectedBenchmarks } = form.values;
      const { loading: isLoading, setLoading } = form;

      if (!dataStore!.isProjectEditor) {
        return null;
      }

      const { currentOrganizationId, currentProjectId } = dataStore!;

      const onCheckboxChange = (
        member: OrgMemberModelType,
        checked: boolean
      ) => {
        form.setField(
          'selectedMembers',
          checked
            ? selectedMembers.concat(member.id)
            : selectedMembers.filter((otherId: number) => member.id !== otherId)
        );
      };
      const onBenchmarkChange = (id: number) => {
        const check = selectedBenchmarks.indexOf(id) < 0;
        if (check && selectedBenchmarks.length >= 4) {
          return;
        }

        form.setField(
          'selectedBenchmarks',
          check
            ? selectedBenchmarks.concat(id)
            : selectedBenchmarks.filter((otherId: number) => id !== otherId)
        );
      };

      const selectMembers = () => {
        setMode(assistantType === 'benchmarks_sprint' ? 'benchmarks' : 'text');
      };

      const selectBenchmarks = () => {
        setMode('text');
      };

      const abort = () => {
        setMode(undefined);
        form.reset();

        onFinish();
      };

      const invite = async () => {
        if (!dataStore!.currentProjectId || !selectedMembers.length) {
          abort();
          return;
        }

        setLoading(true);

        let hasError = false;
        let hasSuccess = false;
        for (const userId of selectedMembers) {
          try {
            await assistantStore!.createAssistantSessionInvitation(
              dataStore!.currentProjectId!,
              userId,
              assistantType,
              form.values.invitationText,
              selectedBenchmarks,
              painpointId,
              hypothesisId
            );

            hasSuccess = true;
          } catch (e) {
            hasError = true;
          }
        }

        if (hasError) {
          applicationStore!.setFlashMessage(
            intl.formatMessage({
              id: hasSuccess
                ? 'assistant invite partial'
                : 'assistant invite error'
            }),
            'error'
          );

          setLoading(false);

          if (hasSuccess) {
            abort();
          }
          return;
        }

        applicationStore!.setFlashMessage(
          intl.formatMessage({ id: 'assistant invite success' })
        );

        setLoading(false);
        abort();
      };

      const { currentUserId } = dataStore!;

      return (
        <>
          {assistantStore!.isItemSaving && <Loading />}

          {mode === 'members' && (
            <SimplePopup
              submitTextId="Next"
              isLoading={organizationsStore!.isMembersLoading || isLoading}
              isSubmitDisabled={!selectedMembers.length}
              onAbort={abort}
              onSubmit={selectMembers}
              headlineTextId="assistant invite select header"
            >
              {selectedMembers.length > 0 && (
                <SelectCounter value={selectedMembers.length} />
              )}

              <SelectPopupSearchForm
                name="membersFilter"
                placeholder={intl.formatMessage({
                  id: 'Members search'
                })}
                {...form.bindInput('membersFilter')}
              />

              <div className="simple-popup__accent-body simple-popup__scrollable-body invite-members">
                <AutoGrid minSize="12rem">
                  {organizationsStore!
                    .sortedMembers('fully_active')
                    .filter((member) => {
                      if (
                        !member.user ||
                        (member.id === currentUserId &&
                          member.access_level !== 'admin') ||
                        member.access_level === 'viewer'
                      ) {
                        return false;
                      }

                      if (!!form.values.membersFilter) {
                        const searchFor =
                          form.values.membersFilter.toLocaleLowerCase();
                        const searchIn =
                          member.user.fullName?.toLocaleLowerCase() || '';

                        return searchIn.indexOf(searchFor) > -1;
                      }

                      return true;
                    })
                    .map((member) => (
                      <RainbowCard
                        key={member.id}
                        shrink={true}
                        clickable={true}
                        checked={selectedMembers.includes(member.id)}
                        onCheckboxChange={(checked) =>
                          onCheckboxChange(member, checked)
                        }
                      >
                        <div className="invite-members__item">
                          <h2 className="invite-members__name invite-members__checkable">
                            {member.user!.fullName}
                          </h2>
                          <UserAvatar
                            user={member.user}
                            noName={true}
                            noLink={true}
                          />
                        </div>
                      </RainbowCard>
                    ))}
                </AutoGrid>
              </div>
            </SimplePopup>
          )}

          {mode === 'text' && (
            <SimplePopup
              isLoading={organizationsStore!.isMembersLoading || isLoading}
              isSubmitDisabled={!form.values.invitationText}
              onAbort={abort}
              onSubmit={invite}
              headlineTextId="assistant invite text header"
            >
              {/*selectedMembers.length > 0 && (
                  <SelectCounter value={selectedMembers.length} />
                )*/}

              <Textarea
                name="invitationText"
                lines={10}
                autoFocus={true}
                {...form.bindInput('invitationText')}
              />
            </SimplePopup>
          )}

          {mode === 'benchmarks' && (
            <SimplePopup
              onAbort={abort}
              onSubmit={selectBenchmarks}
              headlineTextId="assistant invite benchmarks header"
              submitTextId="Next"
            >
              {selectedBenchmarks.length > 0 && (
                <SelectCounter value={selectedBenchmarks.length} />
              )}

              <SelectPopupSearchForm
                name="benchmarksFilter"
                placeholder={intl.formatMessage({
                  id: 'Benchmarks search'
                })}
                {...form.bindInput('benchmarksFilter')}
              />

              <div className="simple-popup__accent-body simple-popup__scrollable-body">
                <BenchmarksListContainer
                  itemType={BenchmarkSelectPopupItem}
                  itemContainer={AutoGrid}
                  emptyComponent={() => (
                    <NoBenchmarksForInviteComponent
                      organizationId={currentOrganizationId}
                      projectId={currentProjectId}
                      onAbort={abort}
                    />
                  )}
                  selected={form.values.selectedBenchmarks}
                  onItemClick={(id) => onBenchmarkChange(id)}
                  filter={(form.values.benchmarksFilter || '')
                    .toString()
                    .trim()}
                />
              </div>
            </SimplePopup>
          )}
        </>
      );
    }
  )
);
