import { Link } from 'react-router-dom';

interface AvatarProps {
  initials?: string;
  name?: React.ReactNode;
  imgUrl?: string;
  linkTo?: string;
}

export default function Avatar({
  initials,
  name,
  imgUrl,
  linkTo
}: AvatarProps) {
  const figure = () => {
    if (imgUrl) {
      return (
        <figure className="avatar__portrait" aria-hidden="true">
          <img className="avatar__image" src={imgUrl} alt={initials} />
        </figure>
      );
    }
    return <div className="avatar__copy">{initials}</div>;
  };

  if (linkTo) {
    return (
      <Link to={linkTo} className="avatar">
        <div className="avatar__square">
          {figure()}
          {name && <span className="avatar__name">{name}</span>}
        </div>
      </Link>
    );
  } else {
    return (
      <div className="avatar" tabIndex={!name ? undefined : 0}>
        <div className="avatar__square">
          {figure()}
          {name && <span className="avatar__name">{name}</span>}
        </div>
      </div>
    );
  }
}
