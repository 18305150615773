import DisrooptiveApi from 'api/DisrooptiveApi';
import config from 'config/config';
import { createIntl } from 'i18n/locales';
// @ts-ignore
import configureLocaleDe from 'i18n/locales/de';
// @ts-ignore
import configureLocaleEn from 'i18n/locales/en';
import Cookies from 'js-cookie';
import { IntlShape } from 'react-intl';

import configureStores from './configureStores';

export default function bootstrap(callback: (config: {intl: IntlShape, stores: ReturnType<typeof configureStores>}) => void) {
  const configureLocale =
    Cookies.get(config.langCookieName) === 'en'
      ? configureLocaleEn
      : configureLocaleDe;

  const intl = createIntl(configureLocale());

  const client = new DisrooptiveApi();
  const stores = configureStores({
    client
  });

  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    window.stores = stores;
  }

  callback({
    stores,
    intl
  });
}
