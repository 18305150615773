import { HistoryProps } from './history';

export const paramFromProps = (name: string, props: HistoryProps) => {
  if (!props.match?.params) {
    return undefined;
  }
  return props.match.params[name] || undefined;
};

export const idFromProps = (props: HistoryProps): number | undefined => {
  return parseInt(paramFromProps('id', props), 10);
};

export default paramFromProps;
