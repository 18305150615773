
import classNames from 'classnames';
import OverlayWrapper from 'components/Layout/OverlayWrapper';

interface LoadingProps {
  inline?: boolean;
}

export default function Loading(props: LoadingProps) {
  const LoadingClasses = classNames('loading', {
    'loading--inline': props.inline
  });

  const spinner = (
    <span className={LoadingClasses}>
      <svg
        className="loading__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 138 58"
      >
        <path
          className="loading__path"
          strokeLinecap="round"
          pathLength="1"
          fill="none"
          strokeWidth="15"
          d="M44 9a25 25 0 100 40c2.8-2 19.5-15.4 50-40a25 25 0 110 40c-2.8-2-32.2-26.7-50-40z"
        />
      </svg>
    </span>
  );

  const renderLoading = () => {
    if (!props.inline) {
      return (
        <OverlayWrapper background="light" disabled={true} center={true}>
          {spinner}
        </OverlayWrapper>
      );
    } else {
      return <>{spinner}</>;
    }
  };

  return renderLoading();
}
