import { Link } from 'react-router-dom';

import Icon, { IconNameType } from 'components/Icon/Icon';
import SidebarItem from './SidebarItem';
import SidebarItemContent from './SidebarItemContent';
import SidebarItemHeader from './SidebarItemHeader';

interface SidebarTeaserProps {
  imageUrl?: string;
  imageAlt?: string;
  imageIcon?: IconNameType;
  imageAppLink?: string;
  imageExtLink?: string;
  extLinkNewWindow?: boolean;
  date?: string;
  children?: any;
}

export const SidebarTeaser = ({
  imageUrl,
  imageAlt,
  imageIcon,
  imageAppLink,
  imageExtLink,
  extLinkNewWindow,
  date,
  children
}: SidebarTeaserProps) => {
  let image;
  if (imageUrl) {
    image = (
      <SidebarItemContent>
        <img
          className="sidebar-teaser__image"
          src={imageUrl}
          alt={imageAlt || ''}
        />
        {imageIcon && (
          <div className="sidebar-teaser__icon">
            <Icon name={imageIcon} />
          </div>
        )}
      </SidebarItemContent>
    );

    if (imageAppLink) {
      image = <Link to={imageAppLink}>{image}</Link>;
    } else if (imageExtLink) {
      image = (
        <a
          href={imageExtLink}
          target={extLinkNewWindow ? '_blank' : undefined}
          rel="noopener noreferrer"
        >
          {image}
        </a>
      );
    }
  }

  return (
    <SidebarItem>
      <SidebarItemHeader date={date}>{children}</SidebarItemHeader>

      {image}
    </SidebarItem>
  );
};

export default SidebarTeaser;
