import InviteFlow from 'components/CallToAction/InviteFlow';
import NakedList from 'components/NakedList/NakedList';
import ProjectItem from 'components/ProjectItem';
import SimplePopup from 'components/SimplePopup';

import { AssistantType } from 'models/AssistantSessionModel';
import { useState } from 'react';

interface INewAssistantPopuProps {
  onCancel: () => void;
  onComplete: () => void;
}

function NewAssistantPopup({ onCancel, onComplete }: INewAssistantPopuProps) {
  const [sprintType, setSprintType] = useState<AssistantType | undefined>();
  const [canContinue, setCanContinue] = useState(false);

  const handleFlowComplete = () => {
    setSprintType(undefined);
    onComplete();
  };

  const handleCancel = () => {
    setSprintType(undefined);
    onCancel();
  };

  return (
    <>
      {!canContinue ? (
        <SimplePopup
          reduced={true}
          onSubmit={() => setCanContinue(true)}
          onAbort={handleCancel}
          isSubmitDisabled={!sprintType}
          headlineTextId="Select sprint type"
          submitTextId="Next"
        >
          <div className="simple-popup__accent-body simple-popup__scrollable-body">
            <NakedList>
              <li key="painpoint">
                <ProjectItem
                  selected={sprintType === 'painpoint_session'}
                  onClick={() => setSprintType('painpoint_session')}
                  title={'Painpoint Sprint'}
                  subtitle="Personen einladen, um die größten Kunden-Painpoints hinzuzufügen"
                />
              </li>
              <li key="benchmark">
                <ProjectItem
                  selected={sprintType === 'benchmarks_sprint'}
                  onClick={() => setSprintType('benchmarks_sprint')}
                  title={'Benchmark Sprint'}
                  subtitle="Personen einladen, um Benchmarks aus anderen Branchen hinzuzufügen"
                />
              </li>
              <li key="hypothesen">
                <ProjectItem
                  selected={sprintType === 'hypothesis_sprint'}
                  onClick={() => setSprintType('hypothesis_sprint')}
                  title={'Hypothesen Sprint'}
                  subtitle="Personen einladen, um neue Lösungsideen hinzuzufügen"
                />
              </li>
              <li key="userflow">
                <ProjectItem
                  selected={sprintType === 'prototype_sprint'}
                  onClick={() => setSprintType('prototype_sprint')}
                  title={'Prototypen Sprint'}
                  subtitle="Personen einladen, um neue User-Flows zu erarbeiten"
                />
              </li>
            </NakedList>
          </div>
        </SimplePopup>
      ) : (
        <InviteFlow
          active={!!sprintType}
          assistantType={sprintType}
          onFinish={handleFlowComplete}
        />
      )}
    </>
  );
}

export default NewAssistantPopup;
