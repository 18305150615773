import classNames from 'classnames';

import Icon from 'components/Icon';
import Loading from 'components/Loading';

export interface AddButtonProps {
  label?: React.ReactNode;
  ariaLabel?: string;
  iconName: string;
  disabled?: boolean;
  loading?: boolean;
  logo?: boolean;
  screen?: boolean;
  userFlow?: boolean;
  fillContainer?: boolean;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function AddButton({
  label,
  ariaLabel,
  iconName,
  disabled,
  loading,
  logo,
  screen,
  userFlow,
  onClick,
  fillContainer,
  style
}: AddButtonProps) {
  const addButtonClasses = classNames('add-button', {
    'add-button--screen': screen,
    'add-button--fill-container': fillContainer,
    'add-button--user-flow': userFlow
  });

  if (logo) {
    return (
      <button
        className="file-upload"
        aria-label={ariaLabel}
        disabled={disabled || loading}
        type="button"
        onClick={onClick}
      >
        <Icon name={iconName} />
      </button>
    );
  }

  return (
    <button
      className={addButtonClasses}
      aria-label={ariaLabel}
      disabled={disabled || loading}
      type="button"
      onClick={onClick}
      style={style}
    >
      <span className="add-button__inner">
        <Icon name={iconName} />
        {label && <span className="add-button__text">{label}</span>}
      </span>
      {loading && <Loading />}
    </button>
  );
}
