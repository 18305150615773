export interface InputStateInterface {
  label?: string | React.ReactChild;
  error?: string | React.ReactChild;
  success?: string | React.ReactChild;
}

export interface InputInterface extends InputStateInterface {
  name: string;
  gap?: boolean;
  slider?: boolean;
}

export const cleanupInputProps = (props: any) => {
  const p = { ...props };

  delete p.label;
  delete p.error;
  delete p.success;
  delete p.gap;
  delete p.slider;
  delete p.headline;
  delete p.subheadline;
  delete p.largeFont;
  delete p.predefinedText;
  delete p.login;
  delete p.onNameChange;
  delete p.onSuggestionClick;
  delete p.suggested;
  delete p.onSuggestionSelected;
  delete p.active;
  delete p.noAutoHeight;
  delete p.slim;
  delete p.slimPadding;
  delete p.normalFont;

  return p;
};

export default InputInterface;
