import { inject, observer } from 'mobx-react';
import React from 'react';

import Loading from 'components/Loading';
import { ApplicationStoreType } from 'models/ApplicationStore';

interface LogoutScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class LogoutScreen extends React.Component<LogoutScreenProps> {
  componentDidMount() {
    this.props.applicationStore.logout(true);
  }

  render() {
    if (this.props.applicationStore.isLoggingOut) {
      return <Loading />;
    }

    return null;
  }
}

export default LogoutScreen;
